import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Ready from "../../../components/Ready";
import Footer from "../Home/Component/Footer";
import moment from 'moment';
class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBlog: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let data = {
      keyWord: "",
      pageNo: 1,
      size: 100000,
    }
    this.props.dispatch(assessmentActions.getTalentBlogList(data));

    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.assessment.getTalentBlogListSuccess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.assessment.getTalentBlogListFailed) {
      return {
        ...nextProps,
        notFoundText: "Failure!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  // handleReadMore = (itemId) => {
  //     const { history } = this.props;
  //     history.push(`/assessment/blogdetail/${itemId}`);
  //     console.log('itemIditemIditemIditemIditemIditemIditemIditemIditemId', itemId);
  // };

  handleReadMore = blog => {
    this.setState({ selectedBlog: blog });
    if (blog && blog.id) {
      let namePath = blog.id;
      this.props.history.push({
        pathname: "/assessment/blogdetails/" + namePath,
        state: { selectedBlog: blog }
      });
    }

  };

  handleRedirect = (path) => {
    this.props.history.push(path);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  render() {

    let { assessment } = this.props;
    let { userAssessmentUserProfileData, getAssessmentCartCountData, getAssessmentPackagesShortListData, getTalentBlogData, getTalentBlogTotal } = assessment;


    return (
      <div>
        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{
            backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10 px-4 py-6 mx-auto md:pt-10 md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
              <div className="flex flex-col justify-center w-full h-full pt-10 pb-10 mx-auto my-auto space-y-4 text-center md:pb-10 md:pt-0 md:text-center ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1
                  data-aos="fade-up"
                  className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize"
                >Our Blogs </h1>
                {/* <div data-aos="fade-up" className="text-base font-light md:text-lg">
                                    <p className="text-[#212121]">
                                        {' Home > Blogs'}
                                    </p>
                                </div> */}

                <div data-aos="fade-up" className="space-x-3 text-base font-light md:text-lg flex text-[#212121] items-center justify-center">
                  <p className="cursor-pointer" onClick={() => this.handleRedirect('/assessment/home')}>    {' Home >'}</p>
                  <p className="cursor-pointer" onClick={() => this.handleRedirect('/assessment/blog')}>    {' Blogs '}</p>
                </div>

              </div>

            </div>
          </div>
        </section>

        {/* category section */}
        {/* <div className="flex flex-col items-center justify-start w-full h-full gap-6 px-4 py-6 mx-auto lg:flex-row md:py-10 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
                    <button className="font-medium border-b-2 pb-4 border-[#2196F3] text-lg px-4 text-[#212121]">
                        All Categories
                    </button>
                    <button className="font-medium border-b-2 pb-4 border-transparent  text-lg px-4 text-[#212121]/70">
                        Articles
                    </button>
                    <button className="font-medium border-b-2 pb-4 border-transparent  text-lg px-4 text-[#212121]/70">
                        Technology
                    </button>
                    <button className="font-medium border-b-2 pb-4 border-transparent  text-lg px-4 text-[#212121]/70">
                        News & Press
                    </button>
                </div> */}

        <div className="grid w-full h-full grid-cols-1 gap-4 px-4 py-10 mx-auto 2xl:gap-10 lg:gap-6 md:py-20 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:grid-cols-3 md:grid-cols-2">

          {getTalentBlogData && getTalentBlogData.length > 0 ?
            getTalentBlogData.map(item => (
              <div key={moment(item.createdAt).format("DD MMMM, YYYY")} onClick={() => this.handleReadMore(item)} className="flex flex-col  cursor-pointer  overflow-hidden border hover:border-transparent rounded-xl hover:shadow-[0px_10px_30px_rgba(0,0,0,0.10)]">
                <img
                  // src="https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxvZ3xlbnwwfHwwfHx8MA%3D%3D" 
                  src={item && item.imageLinkV2}
                  className="w-full" />
                <div className="p-6 space-y-4">
                  <div className="flex items-center space-x-2 text-[#212121]/70 text-base">
                    <span>By {item && item.author}</span><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point-filled text-[#3D405B]/30" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" strokeWidth="0" fill="currentColor" /></svg>
                    <span>  {moment(item.createdAt).format("DD MMMM, YYYY")}</span>
                  </div>

                  <h1
                    className="xl:text-2xl text-xl font-semibold text-[#212121] capitalize"
                  >
                    {item && item.title}
                    {/* If you judge a fish by its ability to climb... */}

                  </h1>
                  <div className="text-base font-light md:text-lg">

                    <p className="text-[#212121] line-clamp-3 capitalize" dangerouslySetInnerHTML={{ __html: item && item.desc ? item.desc : "" }} />


                  </div>
                  <button className="text-[#2196F3] flex font-medium pt-6 space-x-2 hover:text-[#212121]" onClick={() => this.handleReadMore(item)}>
                    <span> Read More  </span>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>
                  </button>
                </div>

              </div>
            )) : null}


        </div>
        {/* pagination */}
        {/* <div className="w-full px-4 mx-auto md:pt-10 2xl:w-9/12 xl:w-11/12 xl:px-0">
                    <div className="flex flex-wrap justify-center items-center gap-4 text-[#3D405B] text-sm font-medium p-6 ">
                        <p className="">Pages</p>
                        <div className="flex border divide-x rounded-md">
                            <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Previous</button>
                            <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">1</button>
                            <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">2</button>
                            <button className="p-4 py-2 hover.bg-[#F9FAFB] hover.text-[#2196F3] transition ">3</button>
                            <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">4</button>
                            <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">5</button>
                            <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Next</button>
                        </div>
                        <p>1 of 26</p>
                    </div>
                </div> */}

        <Ready />
        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

      </div>
    );
  }
}



function mapStateToProps(state) {
  //console.log("state  ", state);
  const { assessment } = state;
  return {
    assessment
  };
}

export default connect(mapStateToProps)(Blog);