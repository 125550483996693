import React from "react";
import LanguageAssessmentComponent from "./LanguageAssessmentComponent";
export default function LogoAssessmentHeader(props) {
  const {
    surveyScreenData,
    template,
    details,
    logout,
    LanguageList,
    handleLanguage,
    surveyLanguageName } = props;

  let [scrollPosition, HandlescrollPosition] = React.useState(0);

  let lastKnownScrollPosition = 0;
  document.addEventListener("scroll", () => {
    lastKnownScrollPosition = window.scrollY;
    HandlescrollPosition(lastKnownScrollPosition);
  });

  // console.log("surveyScreenData",surveyScreenData)



  return (
    <div className="sticky top-0 z-40 left-0 bg-white  ">
      {(scrollPosition < 50 && template === "v4") || true ? (
        <div>

          <div className="flex items-center w-full h-16  border-b ">
            <div className="flex items-center justify-between w-full px-4 mx-auto xl:w-11/12 xl:px-0 ">
              <img
                src="/img/TalentPlusStrengths_logo.svg"
                className="bg-cover md:w-36 w-28"
              />

              <div className="flex items-center space-x-2">
                {LanguageList && LanguageList.length > 0 ? (
                  LanguageList.length > 0 ? (
                    <div className="relative cursor-pointer group">
                      <LanguageAssessmentComponent
                        langList={LanguageList}
                        handleLanguage={handleLanguage}
                        surveyLanguageName={surveyLanguageName}
                        template={"v4"}
                      />
                    </div>
                  ) : (
                    <div className="" />
                  )
                ) : (
                  <div className="" />
                )}

                <div className="relative flex items-center space-x-2 cursor-pointer group ">
                  <div className="flex items-center space-x-2">
                    <div className="md:text-lg text-base font-semibold text-[#212121]  h-12  w-12 bg-slate-100 rounded-full flex items-center justify-center uppercase">

                      {details && details.name
                        ? details.name.split(' ').map(name => name[0]).join('')
                        : ""}
                    </div>
                    <p className="md:text-base text-sm font-semibold text-[#212121] md:block hidden capitalize ">
                      {details && details.name ? details.name : "User"}
                    </p>
                  </div>
                  <span className="cursor-pointer material-icons text-[2rem] md:block hidden">
                    arrow_drop_down
                  </span>

                  <div className="absolute right-0 z-40 hidden w-40 py-2 overflow-hidden bg-white rounded-xl border shadow-xl top-12 md:w-full group-hover:block">

                    <button
                      onClick={() => logout()}
                      className="flex items-center w-full px-4 py-2 text-sm font-semibold text-red-500 cursor-pointer md:text-base hover:bg-slate-100 "
                    >
                      <span className="material-symbols-outlined mr-2 text-red-500">
                        logout
                      </span>{" "}
                      {"Logout"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* new element */}
          {/* <div className="w-full px-4 mx-auto xl:w-11/12 xl:px-0">
            {!backSummary ?
              <div className="flex items-center justify-between py-2">
                <div className="flex items-center space-x-4">
                  <img src="https://i.pinimg.com/474x/98/51/1e/98511ee98a1930b8938e42caf0904d2d.jpg" className="flex-shrink-0 object-cover rounded-full w-14 h-14 " />
                  <h1 className="text-xl font-semibold text-[#212121]">

                    {crrSelectedEmp && crrSelectedEmp.name
                      ? crrSelectedEmp.name
                      : "Employee Name"}
                  </h1>
                </div>
                <div className="text-lg font-medium"><span className="opacity-70 ">Survey Taken on:</span> <span>{createdAt}</span></div>
              </div>
              : null}
          </div> */}


        </div>


      ) : null}
    </div>
  );
}
