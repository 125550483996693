 import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import ProfileHeader from '../Header/ProfileHeader';
import moment from "moment";
import { CONST } from "../../../_config";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1
    };
  }

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.userAssessmentOrders());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentSignInSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }


  logout = () => {

    this.props.dispatch(assessmentActions.userAssessmentLogout(this.props));
  }

  changeQuantity = (type) => {
    let { quantity } = this.state;
    if (type === "add") {
      quantity += 1
    } else if (type === "sub" && quantity > 1) {
      quantity -= 1
    }
    this.setState({ quantity })

  }

  onPurchase = (packageType) => {
    let { quantity } = this.state;
    let data = {
      "quantity": quantity,
      "type": packageType
    }
    this.props.dispatch(assessmentActions.userAssessmentPackageBuy(data));
  }


  render() {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData, userAssessmentOrdersData } = assessment;
    let { quantity } = this.state;
    console.log("quantity-->", quantity)



    return (
      <>




        <div
          className="w-full h-screen overflow-hidden bg-center bg-cover"
          style={{ backgroundImage: 'url("/img/survey-bg.jpg")' }}
        >
          <ProfileHeader
            details={userAssessmentUserProfileData}
            logout={this.logout}
          />



          <section className="w-full px-4 py-2 mx-auto xl:w-11/12 xl:px-0">
            <div className="w-full mx-auto space-y-6 ">
              <div className="mt-6 space-y-2 text-base font-normal text-left md:space-y-6 text-white/80">
                <h1 className="py-2 text-2xl font-medium md:text-4xl text-white/100">
                  TalentPlusStrengths  Assessment Orders
                </h1>


                <div className="p-4 m-4 bg-white rounded-lg">
                  <div className="flex">
                    <div className='w-[7%] bg-white rounded-md flex justify-between items-center '>
                      <h1 className="font-medium text-gray-500 text-md">SurveyLink</h1>
                    </div>
                    <div className='w-[90%] bg-white border border-blue-500 rounded-md flex justify-between items-center '>
                      <input value={CONST.SURVEY_ASSESSMENT} disabled={true} className="w-full p-2 text-blue-500 select-all cursor-text" />
                    </div>
                  </div>

                </div>


                <h1 className="text-2xl font-medium md:text-4xl text-white/100 ">
                  Last Orders
                </h1>

                <div className="h-screen overflow-y-auto" style={{ height: "500px" }}>
                  <div className="grid grid-cols-3 my-2" >

                    {userAssessmentOrdersData && userAssessmentOrdersData.length > 0 ?
                      userAssessmentOrdersData.map((order) =>
                        <div className="p-4 m-4 bg-white rounded-lg">
                          <h1 className="text-gray-500 text-md"><span className="px-2 font-bold text-gray-500">Name:</span>{order.name}</h1>
                          <h1 className="text-gray-500 text-md"><span className="px-2 font-bold text-gray-500">Order Type:</span>{order.type}</h1>
                          {/* <h1 className="text-gray-500 text-md"><span className="px-2 font-bold text-gray-500">Order Id:</span>{order.name}</h1> */}
                          <h1 className="text-gray-500 text-md"><span className="px-2 font-bold text-gray-500">Created At:</span>{moment(new Date(parseInt(order.createdAt)))
                            .utcOffset("+05:30")
                            .format("DD MMM, YYYY")}</h1>


                          {order && order.otpCode ?
                            <div className="flex">
                              <div className='flex items-center justify-between bg-white border border-green-500 rounded-md w-72 '>
                                <input value={order.otpCode.toString()} disabled={true} className="p-2 text-green-500 select-all cursor-text w-72" />
                              </div>
                            </div>
                            : null}

                        </div>
                      ) : null}

                  </div>

                </div>


              </div>
            </div>
          </section>
        </div>




      </>
    );
  }
}
SignIn.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment
  };
}

export default connect(mapStateToProps)(SignIn);
