import React from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { LuUpload } from "react-icons/lu";


export default function DeleteUserModal(props) {

  let { deleteUser, handleCloseDeleteUserModal } = props;

  return (
    <>
      <div className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full bg-black/60">
        <div className="p-4 max-w-xl mx-auto relative left-0 right-0 overflow-hidden mt-36">

          <div className="shadow w-full rounded-lg bg-white p-8 overflow-hidden block">

            {/* <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">Edit School</h2>
              <div  className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div> */}

            <div className="py-4 p-6 space-y-4 flex items-center justify-center w-full">
              <div className="text-center space-y-4 w-full mx-auto">
                <div className=" rounded-full p-4 bg-[#F44336]/15 text-[#F44336] hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                  <RiDeleteBin6Fill className="h-7 w-7" />
                </div>
                <h2 className="text-lg md:text-xl font-semibold tracking-wide text-[#212121]">Are you sure?</h2>
                <p className="text-base font-medium text-[#212121]/70">Once you delete this user, You won't be able to revert this!</p>
                <div className="flex items-center justify-center pt-4 space-x-4">
                  <button type="button" onClick={deleteUser} className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase">Yes, delete it</button>
                  <button onClick={() => handleCloseDeleteUserModal(false)} type="button" className="w-full bg-[#F44336] hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase">No, cancel</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
