import React from "react";
import moment from "moment";
import AssessmentBottom5 from "./AssessmentBottom5";

export default function AssessmentDetails(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, EditDetailsFn, EditButtonFn } = props;

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      //console.log("descX",descX)
      //console.log("temp",temp)
      descNew = temp.split("@").filter((prev) => prev !== "");
      // //console.log("descNew",descNew)
    }
    return descNew;
  }

  function getTitle(element, type) {
    let itemName = "How to manage someone who has - as a talent ?";
    let final = itemName.replace("-", element.dimension.trim());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function chunkList(array) {
    const chunkSize = 5;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  function checkForGeneralship(bucket, dimension) {
    let text = "";
    if (bucket && dimension) {
      if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }

  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  return (
    <>
      {getAssessmentReportByEmpIDData &&
        getAssessmentReportByEmpIDData.length > 0
        ? getAssessmentReportByEmpIDData.map((element, index1) => (
          <>


            {(index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) ? (
              <>
                <main
                  className="bg-white p-10 px-20 space-y-10 page-break bg-no-repeat relative h-full"
                >
                  <div className="flex items-center w-full justify-between border-b py-4">
                    <h1 className="text-3xl font-medium text-slate-900">
                      {EditButtonFn('TalentAssessmentReport', 'TalentPlusStrengths  Assessment Report')}
                    </h1>
                    <p className="text-base font-medium">
                      {crrSelectedEmp && crrSelectedEmp.name
                        ? crrSelectedEmp.name
                        : ""}
                    </p>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex space-x-2 border p-2 rounded-full">
                      {getAssessmentReportByEmpIDData &&
                        getAssessmentReportByEmpIDData.length > 0
                        ? getAssessmentReportByEmpIDData.map((element2, index2) =>
                          index2 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                            index1 === index2 ? (
                              cirleColor(element2)
                            ) : (
                              <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                            )
                          ) : null
                        )
                        : null}
                    </div>

                    <div className="flex space-x-2 border p-2 rounded-full">
                      {getAssessmentReportByEmpIDData &&
                        getAssessmentReportByEmpIDData.length > 0
                        ? getAssessmentReportByEmpIDData.map((element2, index2) =>
                          getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index2 + 1 && index2 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (
                            index1 === index2 ? (
                              cirleColor(element2)
                            ) : (
                              <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                            )
                          ) : null
                        )
                        : null}
                    </div>

                    <div className="flex space-x-2 border p-2 rounded-full">
                      {getAssessmentReportByEmpIDData &&
                        getAssessmentReportByEmpIDData.length > 0
                        ? getAssessmentReportByEmpIDData.map((element2, index2) =>
                          getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index2 + 1 ? (
                            index1 === index2 ? (
                              cirleColor(element2)
                            ) : (
                              <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                            )
                          ) : null
                        )
                        : null}
                    </div>
                  </div>

                  <div
                    style={{
                      background: hexToRgbA(
                        element && element.colorCode
                          ? element.colorCode
                          : "#DDDDDD",
                        "0.20"
                      ),
                    }}
                    className={
                      "rounded-2xl p-6 flex justify-between  items-center  "
                    }
                  >
                    <div className="space-y-4">
                      <button
                        style={{
                          background:
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                        }}
                        className={
                          " px-8 py-2 text-xl flex text-white uppercase tracking-wide rounded-full "
                        }
                      >
                        {/* {element.bucket} */}
                        {EditDetailsFn(element, 'bucket', element.bucket)}
                      </button>
                      <h1 className="text-2xl font-medium text-slate-900 ">
                        {/* {((index1 + 1)>16?(index1 + 1 - 16).toString()   :(index1 + 1).toString()) + ". " + element.dimension} */}
                        {(index1 + 1 > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                          ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString()
                          : (index1 + 1).toString()) + ". "}
                        {EditDetailsFn(element, 'dimension', element.dimension)}
                      </h1>
                    </div>

                    <img
                      src={
                        "/img/assessment/circleicons/" +(checkForGeneralship(element.bucket, element.dimension)) +
                        element.dimension.toLowerCase() +
                        ".svg"
                      }
                      className="w-28 h-28"
                    />
                  </div>


                  <img src={`/img/assessment/idea/lightwatermark/`  + (checkForGeneralship(element.bucket, element.dimension)) +
                  element.dimension.toLowerCase() + `_watermark.svg`} className="w-[300px] h-auto absolute bottom-0 right-0" />
                </main>


                {getBulletPoints(element, "desc6") &&
                  getBulletPoints(element, "desc6").length > 0
                  ? chunkList(getBulletPoints(element, "desc6")).map(
                    (textList, index2) => (
                      <>
                        <main className={index2 ? "bg-white p-10 px-20 space-y-10 relative page-break" : "bg-white p-10 px-20 space-y-10 relative "}

                        // style={{ backgroundImage: `url("/img/assessment/idea/watermark/`+element.bucket.toLowerCase()+`_watermark.svg")`, backgroundPosition: 'bottom right 100px' }}
                        >
                          {element &&
                            element.details &&
                            element.details.desc6 ? (
                            <>
                              <div className="w-full space-y-1">
                                <h1 className="text-3xl font-light text-[#3D405B]">
                                  {/* <b>{getTitle(element, 1)}</b> */}
                                  <b>{EditButtonFn(getTitle(element, 1), getTitle(element, 1))}</b>
                                </h1>
                              </div>

                              <div className="text-base space-y-2">
                                {textList && textList.length > 0
                                  ? textList.map((text, index6) => (
                                    <>
                                      <div
                                        style={{
                                          background: hexToRgbA(
                                            element && element.colorCode
                                              ? element.colorCode
                                              : "#DDDDDD",
                                            "0.08"
                                          ),
                                        }}
                                        className={
                                          "rounded-[3rem] rounded-bl-none p-6 flex space-x-4 justify-left  items-start  "
                                        }
                                      >
                                        <img
                                          src={
                                            "/img/assessment/circleicons/"  +(checkForGeneralship(element.bucket, element.dimension)) +
                                            element.dimension.toLowerCase() +
                                            ".svg"
                                          }
                                          className="w-20 h-20"
                                        />
                                        {/* <p className="mx-2 text-gray-900">
                                            {text}
                                          </p> */}
                                        <div>
                                          {EditDetailsFn(element, 'desc6' + (index6 + 1), text)}
                                        </div>
                                      </div>
                                    </>
                                  ))
                                  : null}
                              </div>
                            </>
                          ) : null}
                          {/* <p>Copyright © 2023, HappyPlus, Inc. All rights reserved</p> */}
                          <img src={`/img/assessment/idea/lightwatermark/` +(checkForGeneralship(element.bucket, element.dimension)) + 
                          element.dimension.toLowerCase() + `_watermark.svg`} className="w-[300px] h-auto absolute bottom-0 right-0" />

                        </main>
                      </>
                    )
                  )
                  : null}

              </>
            ) : null}


          </>

        ))
        : null}
    </>
  );
}
