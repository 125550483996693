 import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import Footer from "./Footer"; 
import CartHeader from "../MyCart/CartHeader"; 

class Cancelled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
    };
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.user.userAssessmentMyCartSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  gotoPage = (field) => {
    this.props.history.push(`/assessment/` + field);
  };
  handleSignInPage = () => {
    localStorage.removeItem('route')
    localStorage.setItem('route', this.props.history.location.pathname)
    this.props.history.push(`/assessment/sign-in`);
  };

  render() {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;


 


    return (
      <div className="">
        <CartHeader
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          stage={3}
        />

        <div className="flex items-center justify-center mx-auto mt-10 ">
          <div className="w-full px-4 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <div className="flex flex-col-reverse md:flex-row justify-between items-center   bg-[#F6CECE] md:p-10 p-6 w-full">
              <div className="w-full space-y-6">
                <div>
                <h1 className="md:text-4xl text-3xl font-medium text-[#3D405B] md:text-left text-center">
                  Order Cancelled
                </h1>
                <p className="m-1 mt-2">You order has been Cancelled. Please try again.</p>
                </div>
                <div className="flex flex-col w-full gap-4 md:flex-row">
                  <button
                    onClick={() => this.gotoPage("products")}
                    className="bg-white text-[#3D405D] font-medium w-full lg:w-[270px] justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center  cursor-pointer text-lg"
                  >
                    <span>purchase again</span>
                  </button>
                </div>
              </div>
              <div className="flex justify-center ">

                <img src="./img/failed.png" style={{width:"250px", height:"200px"}}/>
              </div>
            </div>
          </div>
        </div>

      

        <Footer />
      </div>
    );
  }
}
Cancelled.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(Cancelled);
