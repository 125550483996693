export const assessmentConstants = {

    GET_STUDENT_REPORT_REQUEST: 'GET_STUDENT_REPORT_REQUEST',
    GET_STUDENT_REPORT_SUCCESS: 'GET_STUDENT_REPORT_SUCCESS',
    GET_STUDENT_REPORT_FAILURE: 'GET_STUDENT_REPORT_FAILURE',

    GET_DASHBOARD_ANALYTICS_REQUEST: 'GET_DASHBOARD_ANALYTICS_REQUEST',
    GET_DASHBOARD_ANALYTICS_SUCCESS: 'GET_DASHBOARD_ANALYTICS_SUCCESS',
    GET_DASHBOARD_ANALYTICS_FAILURE: 'GET_DASHBOARD_ANALYTICS_FAILURE',

    AUTO_ASSIGN_ASSESSMENT_CODE_REQUEST: 'AUTO_ASSIGN_ASSESSMENT_CODE_REQUEST',
    AUTO_ASSIGN_ASSESSMENT_CODE_SUCCESS: 'AUTO_ASSIGN_ASSESSMENT_CODE_SUCCESS',
    AUTO_ASSIGN_ASSESSMENT_CODE_FAILURE: 'AUTO_ASSIGN_ASSESSMENT_CODE_FAILURE',

    ASSIGN_ASSESSMENT_CODE_REQUEST: 'ASSIGN_ASSESSMENT_CODE_REQUEST',
    ASSIGN_ASSESSMENT_CODE_SUCCESS: 'ASSIGN_ASSESSMENT_CODE_SUCCESS',
    ASSIGN_ASSESSMENT_CODE_FAILURE: 'ASSIGN_ASSESSMENT_CODE_FAILURE',

    DELETE_STUDENT_REQUEST: 'DELETE_STUDENT_REQUEST',
    DELETE_STUDENT_SUCCESS: 'DELETE_STUDENT_SUCCESS',
    DELETE_STUDENT_FAILURE: 'DELETE_STUDENT_FAILURE',

    DISABLE_STUDENT_REQUEST: 'DISABLE_STUDENT_REQUEST',
    DISABLE_STUDENT_SUCCESS: 'DISABLE_STUDENT_SUCCESS',
    DISABLE_STUDENT_FAILURE: 'DISABLE_STUDENT_FAILURE',

    UPDATE_STUDENT_REQUEST: 'UPDATE_STUDENT_REQUEST',
    UPDATE_STUDENT_SUCCESS: 'UPDATE_STUDENT_SUCCESS',
    UPDATE_STUDENT_FAILURE: 'UPDATE_STUDENT_FAILURE',

    ADD_STUDENT_REQUEST: 'ADD_STUDENT_REQUEST',
    ADD_STUDENT_SUCCESS: 'ADD_STUDENT_SUCCESS',
    ADD_STUDENT_FAILURE: 'ADD_STUDENT_FAILURE',

    GET_STUDENT_LIST_REQUEST: 'GET_STUDENT_LIST_REQUEST',
    GET_STUDENT_LIST_SUCCESS: 'GET_STUDENT_LIST_SUCCESS',
    GET_STUDENT_LIST_FAILURE: 'GET_STUDENT_LIST_FAILURE',


    UPLOAD_STUDENT_LIST_REQUEST: 'UPLOAD_STUDENT_LIST_REQUEST',
    UPLOAD_STUDENT_LIST_SUCCESS: 'UPLOAD_STUDENT_LIST_SUCCESS',
    UPLOAD_STUDENT_LIST_FAILURE: 'UPLOAD_STUDENT_LIST_FAILURE',

    CREATE_SCHOOL_REQUEST: 'CREATE_SCHOOL_REQUEST',
    CREATE_SCHOOL_SUCCESS: 'CREATE_SCHOOL_SUCCESS',
    CREATE_SCHOOL_FAILURE: 'CREATE_SCHOOL_FAILURE',

    GET_SCHOOL_DETAILS_REQUEST: 'GET_SCHOOL_DETAILS_REQUEST',
    GET_SCHOOL_DETAILS_SUCCESS: 'GET_SCHOOL_DETAILS_SUCCESS',
    GET_SCHOOL_DETAILS_FAILURE: 'GET_SCHOOL_DETAILS_FAILURE',

    UPDATE_SCHOOL_REQUEST: 'UPDATE_SCHOOL_REQUEST',
    UPDATE_SCHOOL_SUCCESS: 'UPDATE_SCHOOL_SUCCESS',
    UPDATE_SCHOOL_FAILURE: 'UPDATE_SCHOOL_FAILURE',

    GET_SCHOOL_LIST_REQUEST: 'GET_SCHOOL_LIST_REQUEST',
    GET_SCHOOL_LIST_SUCCESS: 'GET_SCHOOL_LIST_SUCCESS',
    GET_SCHOOL_LIST_FAILURE: 'GET_SCHOOL_LIST_FAILURE',

    CREATE_ROLE_USERS_REQUEST: 'CREATE_ROLE_USERS_REQUEST',
    CREATE_ROLE_USERS_SUCCESS: 'CREATE_ROLE_USERS_SUCCESS',
    CREATE_ROLE_USERS_FAILURE: 'CREATE_ROLE_USERS_FAILURE',

    UPDATE_ROLE_USERS_REQUEST: 'UPDATE_ROLE_USERS_REQUEST',
    UPDATE_ROLE_USERS_SUCCESS: 'UPDATE_ROLE_USERS_SUCCESS',
    UPDATE_ROLE_USERS_FAILURE: 'UPDATE_ROLE_USERS_FAILURE',

    DELETE_ROLE_USERS_REQUEST: 'DELETE_ROLE_USERS_REQUEST',
    DELETE_ROLE_USERS_SUCCESS: 'DELETE_ROLE_USERS_SUCCESS',
    DELETE_ROLE_USERS_FAILURE: 'DELETE_ROLE_USERS_FAILURE',

    GET_ROLE_USERS_LIST_REQUEST: 'GET_ROLE_USERS_LIST_REQUEST',
    GET_ROLE_USERS_LIST_SUCCESS: 'GET_ROLE_USERS_LIST_SUCCESS',
    GET_ROLE_USERS_LIST_FAILURE: 'GET_ROLE_USERS_LIST_FAILURE',

    GET_USERS_ASSESSMENT_CODE_REQUEST: 'GET_USERS_ASSESSMENT_CODE_REQUEST',
    GET_USERS_ASSESSMENT_CODE_SUCCESS: 'GET_USERS_ASSESSMENT_CODE_SUCCESS',
    GET_USERS_ASSESSMENT_CODE_FAILURE: 'GET_USERS_ASSESSMENT_CODE_FAILURE',

    ADD_USERS_ASSESSMENT_CODE_REQUEST: 'ADD_USERS_ASSESSMENT_CODE_REQUEST',
    ADD_USERS_ASSESSMENT_CODE_SUCCESS: 'ADD_USERS_ASSESSMENT_CODE_SUCCESS',
    ADD_USERS_ASSESSMENT_CODE_FAILURE: 'ADD_USERS_ASSESSMENT_CODE_FAILURE',

    USER_ASSESSMENT_USER_PROFILE_REQUEST: 'USER_ASSESSMENT_USER_PROFILE_REQUEST',
    USER_ASSESSMENT_USER_PROFILE_SUCCESS: 'USER_ASSESSMENT_USER_PROFILE_SUCCESS',
    USER_ASSESSMENT_USER_PROFILE_FAILURE: 'USER_ASSESSMENT_USER_PROFILE_FAILURE',

    USER_ASSESSMENT_PACKAGE_BUY_REQUEST: 'USER_ASSESSMENT_PACKAGE_BUY_REQUEST',
    USER_ASSESSMENT_PACKAGE_BUY_SUCCESS: 'USER_ASSESSMENT_PACKAGE_BUY_SUCCESS',
    USER_ASSESSMENT_PACKAGE_BUY_FAILURE: 'USER_ASSESSMENT_PACKAGE_BUY_FAILURE',

    USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST: 'USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST',
    USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS: 'USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS',
    USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE: 'USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE',

    USER_ASSESSMENT_SURVEY_LOGIN_REQUEST: 'USER_ASSESSMENT_SURVEY_LOGIN_REQUEST',
    USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS: 'USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS',
    USER_ASSESSMENT_SURVEY_LOGIN_FAILURE: 'USER_ASSESSMENT_SURVEY_LOGIN_FAILURE',

    USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST: 'USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST',
    USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS: 'USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS',
    USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE: 'USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE',

    GET_ASSESSMENT_PACKAGE_LIST_REQUEST: 'GET_ASSESSMENT_PACKAGE_LIST_REQUEST',
    GET_ASSESSMENT_PACKAGE_LIST_SUCCESS: 'GET_ASSESSMENT_PACKAGE_LIST_SUCCESS',
    GET_ASSESSMENT_PACKAGE_LIST_FAILURE: 'GET_ASSESSMENT_PACKAGE_LIST_FAILURE',

    GET_FILAMENT_SCORE_REQUEST: 'GET_FILAMENT_SCORE_REQUEST',
    GET_FILAMENT_SCORE_SUCCESS: 'GET_FILAMENT_SCORE_SUCCESS',
    GET_FILAMENT_SCORE_FAILURE: 'GET_FILAMENT_SCORE_FAILURE',

    GET_EXPERT_DETAILS_LIST_REQUEST: 'GET_EXPERT_DETAILS_LIST_REQUEST',
    GET_EXPERT_DETAILS_LIST_SUCCESS: 'GET_EXPERT_DETAILS_LIST_SUCCESS',
    GET_EXPERT_DETAILS_LIST_FAILURE: 'GET_EXPERT_DETAILS_LIST_FAILURE',

    GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST: 'GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST',
    GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS: 'GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS',
    GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE: 'GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE',


    ASSESSMENT_ADD_TO_CART_REQUEST: 'ASSESSMENT_ADD_TO_CART_REQUEST',
    ASSESSMENT_ADD_TO_CART_SUCCESS: 'ASSESSMENT_ADD_TO_CART_SUCCESS',
    ASSESSMENT_ADD_TO_CART_FAILURE: 'ASSESSMENT_ADD_TO_CART_FAILURE',


    GET_ASSESSMENT_CART_ITEMS_REQUEST: 'GET_ASSESSMENT_CART_ITEMS_REQUEST',
    GET_ASSESSMENT_CART_ITEMS_SUCCESS: 'GET_ASSESSMENT_CART_ITEMS_SUCCESS',
    GET_ASSESSMENT_CART_ITEMS_FAILURE: 'GET_ASSESSMENT_CART_ITEMS_FAILURE',

    UPDATE_ASSESSMENT_CART_ITEMS_REQUEST: 'UPDATE_ASSESSMENT_CART_ITEMS_REQUEST',
    UPDATE_ASSESSMENT_CART_ITEMS_SUCCESS: 'UPDATE_ASSESSMENT_CART_ITEMS_SUCCESS',
    UPDATE_ASSESSMENT_CART_ITEMS_FAILURE: 'UPDATE_ASSESSMENT_CART_ITEMS_FAILURE',


    GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST: 'GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST',
    GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS: 'GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS',
    GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE: 'GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE',

    DELETE_ASSESSMENT_CART_ITEM_REQUEST: 'DELETE_ASSESSMENT_CART_ITEM_REQUEST',
    DELETE_ASSESSMENT_CART_ITEM_SUCCESS: 'DELETE_ASSESSMENT_CART_ITEM_SUCCESS',
    DELETE_ASSESSMENT_CART_ITEM_FAILURE: 'DELETE_ASSESSMENT_CART_ITEM_FAILURE',


    GET_ORDER_BOOK_LIST_REQUEST: 'GET_ORDER_BOOK_LIST_REQUEST',
    GET_ORDER_BOOK_LIST_SUCCESS: 'GET_ORDER_BOOK_LIST_SUCCESS',
    GET_ORDER_BOOK_LIST_FAILURE: 'GET_ORDER_BOOK_LIST_FAILURE',

    USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST: 'USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST',
    USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS: 'USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS',
    USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE: 'USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE',

    USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST: 'USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST',
    USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS: 'USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS',
    USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE: 'USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE',

    GET_ASSESSMENT_CART_COUNT_REQUEST: 'GET_ASSESSMENT_CART_COUNT_REQUEST',
    GET_ASSESSMENT_CART_COUNT_SUCCESS: 'GET_ASSESSMENT_CART_COUNT_SUCCESS',
    GET_ASSESSMENT_CART_COUNT_FAILURE: 'GET_ASSESSMENT_CART_COUNT_FAILURE',

    SAVE_ASSESSMENT_CONTACT_US_REQUEST: 'SAVE_ASSESSMENT_CONTACT_US_REQUEST',
    SAVE_ASSESSMENT_CONTACT_US_SUCCESS: 'SAVE_ASSESSMENT_CONTACT_US_SUCCESS',
    SAVE_ASSESSMENT_CONTACT_US_FAILURE: 'SAVE_ASSESSMENT_CONTACT_US_FAILURE',

    UPDATE_ASSESSMENT_USER_PROFILE_REQUEST: 'UPDATE_ASSESSMENT_USER_PROFILE_REQUEST',
    UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS: 'UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS',
    UPDATE_ASSESSMENT_USER_PROFILE_FAILURE: 'UPDATE_ASSESSMENT_USER_PROFILE_FAILURE',


    ASSESSMENT_DOWNLOAD_STATUS_REQUEST: 'ASSESSMENT_DOWNLOAD_STATUS_REQUEST',
    ASSESSMENT_DOWNLOAD_STATUS_SUCCESS: 'ASSESSMENT_DOWNLOAD_STATUS_SUCCESS',
    ASSESSMENT_DOWNLOAD_STATUS_FAILURE: 'ASSESSMENT_DOWNLOAD_STATUS_FAILURE',


    ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST: 'ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST',
    ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS: 'ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS',
    ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE: 'ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE',


    USER_ASSESSMENT_LOGOUT_REQUEST: 'USER_ASSESSMENT_LOGOUT_REQUEST',
    USER_ASSESSMENT_LOGOUT_SUCCESS: 'USER_ASSESSMENT_LOGOUT_SUCCESS',
    USER_ASSESSMENT_LOGOUT_FAILURE: 'USER_ASSESSMENT_LOGOUT_FAILURE',

    USER_PLACE_PAYMENT_ORDER_REQUEST: 'USER_PLACE_PAYMENT_ORDER_REQUEST',
    USER_PLACE_PAYMENT_ORDER_SUCCESS: 'USER_PLACE_PAYMENT_ORDER_SUCCESS',
    USER_PLACE_PAYMENT_ORDER_FAILURE: 'USER_PLACE_PAYMENT_ORDER_FAILURE',

    USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST: 'USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST',
    USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS: 'USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS',
    USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE: 'USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE',

    ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST: 'ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST',
    ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS: 'ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS',
    ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE: 'ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE',

    GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST: 'GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST',
    GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS: 'GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS',
    GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE: 'GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE',

    CREATE_SUCCESS_ORDER_BOOK_REQUEST: 'CREATE_SUCCESS_ORDER_BOOK_REQUEST',
    CREATE_SUCCESS_ORDER_BOOK_SUCCESS: 'CREATE_SUCCESS_ORDER_BOOK_SUCCESS',
    CREATE_SUCCESS_ORDER_BOOK_FAILURE: 'CREATE_SUCCESS_ORDER_BOOK_FAILURE',

    SAVE_SUCCESS_ORDER_BOOK_REQUEST: 'SAVE_SUCCESS_ORDER_BOOK_REQUEST',
    SAVE_SUCCESS_ORDER_BOOK_SUCCESS: 'SAVE_SUCCESS_ORDER_BOOK_SUCCESS',
    SAVE_SUCCESS_ORDER_BOOK_FAILURE: 'SAVE_SUCCESS_ORDER_BOOK_FAILURE',

    USER_EXPERT_ORDER_PLACE_REQUEST: 'USER_EXPERT_ORDER_PLACE_REQUEST',
    USER_EXPERT_ORDER_PLACE_SUCCESS: 'USER_EXPERT_ORDER_PLACE_SUCCESS',
    USER_EXPERT_ORDER_PLACE_FAILURE: 'USER_EXPERT_ORDER_PLACE_FAILURE',

    EXPERT_BOOKING_SUCCESS_REQUEST: 'EXPERT_BOOKING_SUCCESS_REQUEST',
    EXPERT_BOOKING_SUCCESS_SUCCESS: 'EXPERT_BOOKING_SUCCESS_SUCCESS',
    EXPERT_BOOKING_SUCCESS_FAILURE: 'EXPERT_BOOKING_SUCCESS_FAILURE',

    EXPERT_ORDER_BOOKID_REQUEST: 'EXPERT_ORDER_BOOKID_REQUEST',
    EXPERT_ORDER_BOOKID_SUCCESS: 'EXPERT_ORDER_BOOKID_SUCCESS',
    EXPERT_ORDER_BOOKID_FAILURE: 'EXPERT_ORDER_BOOKID_FAILURE',

    EXPERT_ORDER_BOOK_REQUEST: 'EXPERT_ORDER_BOOK_REQUEST',
    EXPERT_ORDER_BOOK_SUCCESS: 'EXPERT_ORDER_BOOK_SUCCESS',
    EXPERT_ORDER_BOOK_FAILURE: 'EXPERT_ORDER_BOOK_FAILURE',


    SUBMIT_RATING_EXPERT_REQUEST: 'SUBMIT_RATING_EXPERT_REQUEST',
    SUBMIT_RATING_EXPERT_SUCCESS: 'SUBMIT_RATING_EXPERT_SUCCESS',
    SUBMIT_RATING_EXPERT_FAILURE: 'SUBMIT_RATING_EXPERT_FAILURE',

    GET_TALENT_RESOURCES_REQUEST: 'GET_TALENT_RESOURCES_REQUEST',
    GET_TALENT_RESOURCES_SUCCESS: 'GET_TALENT_RESOURCES_SUCCESS',
    GET_TALENT_RESOURCES_FAILURE: 'GET_TALENT_RESOURCES_FAILURE',

    GET_TALENT_BLOG_REQUEST: 'GET_TALENT_BLOG_REQUEST',
    GET_TALENT_BLOG_SUCCESS: 'GET_TALENT_BLOG_SUCCESS',
    GET_TALENT_BLOG_FAILURE: 'GET_TALENT_BLOG_FAILURE',

    GET_TEST_MODULE_REQUEST: 'GET_TEST_MODULE_REQUEST',
    GET_TEST_MODULE_SUCCESS: 'GET_TEST_MODULE_SUCCESS',
    GET_TEST_MODULE_FAILURE: 'GET_TEST_MODULE_FAILURE',

    GET_CAREER_SURVEY_LIST_REQUEST: 'GET_CAREER_SURVEY_LIST_REQUEST',
    GET_CAREER_SURVEY_LIST_SUCCESS: 'GET_CAREER_SURVEY_LIST_SUCCESS',
    GET_CAREER_SURVEY_LIST_FAILURE: 'GET_CAREER_SURVEY_LIST_FAILURE',

    GET_PERSONALITY_INDEX_REQUEST: 'GET_PERSONALITY_INDEX_REQUEST',
    GET_PERSONALITY_INDEX_SUCCESS: 'GET_PERSONALITY_INDEX_SUCCESS',
    GET_PERSONALITY_INDEX_FAILURE: 'GET_PERSONALITY_INDEX_FAILURE',

    GET_APTITUDE_TEST_REQUEST: 'GET_APTITUDE_TEST_REQUEST',
    GET_APTITUDE_TEST_SUCCESS: 'GET_APTITUDE_TEST_SUCCESS',
    GET_APTITUDE_TEST_FAILURE: 'GET_APTITUDE_TEST_FAILURE',

    GET_WORKER_VALUE_DSCORE_REQUEST: 'GET_WORKER_VALUE_DSCORE_REQUEST',
    GET_WORKER_VALUE_DSCORE_SUCCESS: 'GET_WORKER_VALUE_DSCORE_SUCCESS',
    GET_WORKER_VALUE_DSCORE_FAILURE: 'GET_WORKER_VALUE_DSCORE_FAILURE',

    GET_INTEREST_DSCORE_REQUEST: 'GET_INTEREST_DSCORE_REQUEST',
    GET_INTEREST_DSCORE_SUCCESS: 'GET_INTEREST_DSCORE_SUCCESS',
    GET_INTEREST_DSCORE_FAILURE: 'GET_INTEREST_DSCORE_FAILURE',

    GET_TEST_SURVEY_QUESTION_REQUEST: 'GET_TEST_SURVEY_QUESTION_REQUEST',
    GET_TEST_SURVEY_QUESTION_SUCCESS: 'GET_TEST_SURVEY_QUESTION_SUCCESS',
    GET_TEST_SURVEY_QUESTION_FAILURE: 'GET_TEST_SURVEY_QUESTION_FAILURE',

    GENERATE_CAREER_REPORT_REQUEST: 'GENERATE_CAREER_REPORT_REQUEST',
    GENERATE_CAREER_REPORT_SUCCESS: 'GENERATE_CAREER_REPORT_SUCCESS',
    GENERATE_CAREER_REPORT_FAILURE: 'GENERATE_CAREER_REPORT_FAILURE',

    GET_TALENT_EMP_SUMMARYU_REQUEST: 'GET_TALENT_EMP_SUMMARYU_REQUEST',
    GET_TALENT_EMP_SUMMARYU_SUCCESS: 'GET_TALENT_EMP_SUMMARYU_SUCCESS',
    GET_TALENT_EMP_SUMMARYU_FAILURE: 'GET_TALENT_EMP_SUMMARYU_FAILURE',

    GET_TALENT_PLUS_EMP_STATUS_REQUEST: 'GET_TALENT_PLUS_EMP_STATUS_REQUEST',
    GET_TALENT_PLUS_EMP_STATUS_SUCCESS: 'GET_TALENT_PLUS_EMP_STATUS_SUCCESS',
    GET_TALENT_PLUS_EMP_STATUS_FAILURE: 'GET_TALENT_PLUS_EMP_STATUS_FAILURE',

    GET_TALENT_PLUS_EMP_ADMIN_STATUS_REQUEST: 'GET_TALENT_PLUS_EMP_ADMIN_STATUS_REQUEST',
    GET_TALENT_PLUS_EMP_ADMIN_STATUS_SUCCESS: 'GET_TALENT_PLUS_EMP_ADMIN_STATUS_SUCCESS',
    GET_TALENT_PLUS_EMP_ADMIN_STATUS_FAILURE: 'GET_TALENT_PLUS_EMP_ADMIN_STATUS_FAILURE',

    GET_ASSESSENT_CODE_BY_TIMESTAMP_REQUEST: 'GET_ASSESSENT_CODE_BY_TIMESTAMP_REQUEST',
    GET_ASSESSENT_CODE_BY_TIMESTAMP_SUCCESS: 'GET_ASSESSENT_CODE_BY_TIMESTAMP_SUCCESS',
    GET_ASSESSENT_CODE_BY_TIMESTAMP_FAILURE: 'GET_ASSESSENT_CODE_BY_TIMESTAMP_FAILURE',

};


