import { dataTool } from "echarts";
import React from "react";
export default function CompleteModal(props) {
  let { handleCloseUpdateUserModal, handleUpdateNewUserModal, updateNewEmpFormData, updateNewEmpFormDataError, handleSelectUpdate, handleUserUpdateSubmit, handleUpdateFormData, } = props;
  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative   left-0 right-0 overflow-hidden mt-24">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden block">

            <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">Update New User</h2>
              <div onClick={() => handleCloseUpdateUserModal(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

            <div className="py-4 p-6 space-y-4">

              <div className="text-sm space-y-1">
                <label>Full Name:</label>
                <div className="mb-2 text-xs">
                  <input type="text" name="name" value={updateNewEmpFormData && updateNewEmpFormData["name"] ? updateNewEmpFormData["name"] : ""} onChange={handleUpdateFormData} placeholder="Enter Name" className="p-2 w-full border border-gray-300 outline-none rounded-md" />
                  {updateNewEmpFormDataError && updateNewEmpFormDataError["name"] ?
                    <div className="flex justify-start">
                      <div className="text-red-500 text-xs lg:w-4/5 w-full">
                        {updateNewEmpFormDataError["name"]}
                      </div>
                    </div>
                    : null}
                </div>
              </div>

              <div className="text-sm space-y-1">
                <label>Email:</label>
                <div className="mb-2 text-xs">
                  <input type="email" name="email" value={updateNewEmpFormData && updateNewEmpFormData["email"] ? updateNewEmpFormData["email"] : ""} onChange={handleUpdateFormData} placeholder="Enter Email" className="p-2 w-full border border-gray-300 outline-none rounded-md" />
                  {updateNewEmpFormDataError && updateNewEmpFormDataError["email"] ?
                    <div className="flex justify-start">
                      <div className="text-red-500 text-xs lg:w-4/5 w-full">
                        {updateNewEmpFormDataError["email"]}
                      </div>
                    </div>
                    : null}
                </div>
              </div>

              <div className="text-sm space-y-1">
                <label>Mobile Number:</label>
                <div className="mb-2 text-xs">
                  <input type="text" name="mobile" value={updateNewEmpFormData && updateNewEmpFormData["mobile"] ? updateNewEmpFormData["mobile"] : "+91"} onChange={handleUpdateFormData} placeholder="Employee Mobile Number.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
                  {updateNewEmpFormDataError && updateNewEmpFormDataError["mobile"] ?
                    <div className="flex justify-start">
                      <div className="text-red-500 text-xs lg:w-4/5 w-full">
                        {updateNewEmpFormDataError["mobile"]}
                      </div>
                    </div>
                    : null}
                </div>
              </div>

              <div className="text-sm space-y-1">
                <label>Country:</label>
                <div className="mb-2 text-xs">
                  <input type="text" name="country" value={updateNewEmpFormData && updateNewEmpFormData["country"] ? updateNewEmpFormData["country"] : ""} onChange={handleUpdateFormData} placeholder="Enter Name" className="p-2 w-full border border-gray-300 outline-none rounded-md" />
                  {updateNewEmpFormDataError && updateNewEmpFormDataError["country"] ?
                    <div className="flex justify-start">
                      <div className="text-red-500 text-xs lg:w-4/5 w-full">
                        {updateNewEmpFormDataError["country"]}
                      </div>
                    </div>
                    : null}
                </div>
              </div>

              <div className="text-sm space-y-1">
                <label>Postcode:</label>
                <div className="mb-2 text-xs">
                  <input type="text" name="postcode" value={updateNewEmpFormData && updateNewEmpFormData["postcode"] ? updateNewEmpFormData["postcode"] : ""} onChange={handleUpdateFormData} placeholder="Enter Name" className="p-2 w-full border border-gray-300 outline-none rounded-md" />
                  {updateNewEmpFormDataError && updateNewEmpFormDataError["postcode"] ?
                    <div className="flex justify-start">
                      <div className="text-red-500 text-xs lg:w-4/5 w-full">
                        {updateNewEmpFormDataError["postcode"]}
                      </div>
                    </div>
                    : null}
                </div>
              </div>


              <div className="text-sm space-y-1">
                <label>Role:</label>
                <select id="entries" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full px-2 py-1.5 focus:outline-none"
                  // onChange={handleUpdateFormData}
                  value={updateNewEmpFormData && updateNewEmpFormData["role"] ? updateNewEmpFormData["role"] : ""}
                  onChange={handleSelectUpdate}
                >
                  <option value="">Select</option>
                  <option name="role" value="Counsellor">Counsellor</option>
                  <option name="role" value="Trainer">Trainer</option>
                </select>
                {updateNewEmpFormDataError && updateNewEmpFormDataError["role"] ?
                  <div className="flex justify-start">
                    <div className="text-red-500 text-xs lg:w-4/5 w-full">
                      {updateNewEmpFormDataError["role"]}
                    </div>
                  </div>
                  : null}
              </div>


              <div className=" text-center pt-4">
                <button onClick={() => handleUserUpdateSubmit()} type="button" className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase" >
                  Save
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
