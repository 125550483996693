import React from "react";
import moment from "moment";
import AssessmentBottom5 from "./AssessmentBottom5";

export default function AssessmentDetails(props) {
  let {
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    createdAt
  } = props;

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      //console.log("descX",descX)
      //console.log("temp",temp)
      descNew = temp.split("@").filter((prev) => prev !== "");
      // //console.log("descNew",descNew)
    }
    return descNew;
  }

  function getSummaryBulletPoints(element, field) {
    let descX =
      element && element[field]
        ? element[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      //console.log("descX",descX)
      //console.log("temp",temp)
      descNew = temp.split("@").filter((prev) => prev !== "");
      // //console.log("descNew",descNew)
    }
    return descNew;
  }


  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - talent every day?"
        : "How to deal with a person who has - talent?";
    let final = itemName.replace("-", element.dimension.trim().toLowerCase());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }


  function chunkList15(array) {
    const chunkSize = 15;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }


  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  function BoldText(text) {
    return text ? '<b>' + text + '</b>' : text
  }

  function checkForGeneralship(bucket, dimension) {
    let text = "";
    if (bucket && dimension) {
      if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }

  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  let sentenceList = []
  let sentenceBlindSpotList = []
  if (getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0) {
    getAssessmentReportByEmpIDData.forEach((element, index) => {
      if (index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) {
        let summary = element && element["summary"] ? element["summary"] : {};

        let tempList = []
        for (var i = 0; i < 7; i++) {
          if (summary && summary["title" + (i + 1).toString()]) {
            tempList.push({
              "dimension": element && element["dimension"] ? element["dimension"] : "-",
              "title": summary && summary["title" + (i + 1).toString()] ? summary["title" + (i + 1).toString()] : "-",
              "desc": summary && summary["desc" + (i + 1).toString()] ? summary["desc" + (i + 1).toString()] : "-"
            })
          }
        }
        console.log("tempList---->", tempList);

        if (tempList && tempList.length > 0) {
          let shuffleList = shuffleArray(tempList);
          if (shuffleList && shuffleList.length > 0) {
            for (var t = 0; t < (5 - index); t++) {
              let eleX = shuffleList[t];
              sentenceList.push({
                "dimension": eleX && eleX["dimension"] ? eleX["dimension"] : "-",
                "title": eleX && eleX["title"] ? eleX["title"] : "-",
                "desc": eleX && eleX["desc"] ? eleX["desc"] : "-"
              });

            }
          }
        }
      }


      if (getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1) {
        let summary = element && element["blindspot"] ? element["blindspot"] : {};

        let tempList = []
        for (var i = 0; i < 7; i++) {
          if (summary && summary["title" + (i + 1).toString()]) {
            tempList.push({
              "dimension": element && element["dimension"] ? element["dimension"] : "-",
              "title": summary && summary["title" + (i + 1).toString()] ? summary["title" + (i + 1).toString()] : "-",
              "desc": summary && summary["desc" + (i + 1).toString()] ? summary["desc" + (i + 1).toString()] : "-"
            })
          }
        }
        console.log("tempList---->", tempList);

        if (tempList && tempList.length > 0) {
          let shuffleList = shuffleArray(tempList);
          if (shuffleList && shuffleList.length > 0) {
            for (var t = 0; t < 2; t++) {
              let eleX = shuffleList[t];
              sentenceBlindSpotList.push({
                "dimension": eleX && eleX["dimension"] ? eleX["dimension"] : "-",
                "title": eleX && eleX["title"] ? eleX["title"] : "-",
                "desc": eleX && eleX["desc"] ? eleX["desc"] : "-"
              });

            }
          }
        }
      }


    })
  }

  console.log("sentenceList---->", sentenceList);

  return (
    <>

      {/* Hard Coded design 1*/}
      <div className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="flex items-center justify-between w-full bg-white">
          <div className="w-full px-12 space-y-2">
            <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
              <h1 className="text-lg font-semibold">
                {EditButtonFn(
                  "TalentAssessmentReport",
                  "TalentPlusStrengths Assessment"
                )}
              </h1>
              <p className="text-lg font-semibold">
                {(crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "")} | {createdAt}
              </p>
            </div>

            <div className="flex items-center justify-start space-x-6">
              <div className="flex p-2 space-x-2 border rounded-full">
                {getAssessmentReportByEmpIDData &&
                  getAssessmentReportByEmpIDData.length > 0
                  ? getAssessmentReportByEmpIDData.map((element, index) =>
                    index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? cirleColor(element) : null
                  )
                  : null}
              </div>

              <div className="flex p-2 space-x-2 border rounded-full ">
                {getAssessmentReportByEmpIDData &&
                  getAssessmentReportByEmpIDData.length > 0
                  ? getAssessmentReportByEmpIDData.map((element, index) =>
                    getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                      ? cirleColor(element)
                      : null
                  )
                  : null}
              </div>

              <div className="flex p-2 space-x-2 border rounded-full ">
                {getAssessmentReportByEmpIDData &&
                  getAssessmentReportByEmpIDData.length > 0
                  ? getAssessmentReportByEmpIDData.map((element, index) =>
                    getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? cirleColor(element) : null
                  )
                  : null}
              </div>
            </div>

            <div className="space-y-1">
              <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">Dominant and Non-Dominant TalentPlus Strengths Themes</h1>
              <p className="text-base leading-tight">Your <b>Dominant</b> themes are the core qualities that define who you are and contribute to your success.Understanding, nurturing, and regularly using these strengths are key to experiencing a thriving life.</p>
            </div>

            {/* Use this code for dynamic start */}

            {getAssessmentReportByEmpIDData &&
              getAssessmentReportByEmpIDData.length > 0
              ? getAssessmentReportByEmpIDData.map((element, index) =>
                index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                  <>
                    <div style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.20") }} className="cursor-pointer bg-[#ffc107]/20 rounded-lg 2xl:px-8 md:py-2 p-4 md:flex justify-between items-center md:space-x-6 md:space-y-0 space-y-3">
                      <div className="space-y-1 md:w-[30%]">
                        <button className="px-4 py-1 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest rounded-full bg-[#ffc107] " style={{
                          background:
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                        }}>{EditDetailsFn(element, "bucket", element.bucket)}</button>
                        <h1 className="text-lg font-medium text-slate-900 md:px-4">
                          {(index + 1 > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                            ? (index + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString()
                            : (index + 1).toString()) + ". "}

                          {EditDetailsFn(
                            element,
                            "dimension",
                            element.dimension
                          )}
                        </h1>
                      </div>
                      <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end">
                        <p className="flex-1 text-sm font-normal md:text-base text-slate-600">{EditDetailsFn(element, "desc", element.details.desc)}</p>
                        <div className="w-16 md:w-[4rem]">
                          <img src={
                            "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension)) +
                            element.dimension.toLowerCase() +
                            ".svg"
                          } alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null
              )
              : null}

          </div>
        </div>

        <div className="flex items-center justify-between w-full bg-white">
          <div className="w-full px-12 space-y-2">
            <div className="space-y-2">
              {/* <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">Dominant and Non-Dominant TalentPlus Strengths Themes</h1> */}
              <p className="text-base leading-tight">Your <b>Non-Dominant</b> themes are the core qualities that define who you are and contribute to your success.Understanding, nurturing, and regularly using these strengths are key to experiencing a thriving life.</p>
            </div>

            {getAssessmentReportByEmpIDData &&
              getAssessmentReportByEmpIDData.length > 0
              ? getAssessmentReportByEmpIDData.map((element, index) =>
                getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? (
                  <>
                    <div style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.20") }} className="cursor-pointer bg-[#ffc107]/20 rounded-lg 2xl:px-8 md:py-2 p-4 md:flex justify-between items-center md:space-x-6 md:space-y-0 space-y-4">
                      <div className="space-y-1 md:w-[30%]">
                        <button className="px-4 py-1 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest rounded-full bg-[#ffc107] " style={{
                          background:
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                        }}>{EditDetailsFn(element, "bucket", element.bucket)}</button>
                        <h1 className="text-lg font-medium text-slate-900 md:px-4">
                          {(index + 1 > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                            ? (index + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString()
                            : (index + 1).toString()) + ". "}

                          {EditDetailsFn(
                            element,
                            "dimension",
                            element.dimension
                          )}
                        </h1>
                      </div>
                      <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end">
                        <p className="flex-1 text-sm font-normal md:text-base text-slate-600">{EditDetailsFn(element, "desc", element.details.desc)}</p>
                        <div className="w-16 md:w-[4rem]">
                          <img src={
                            "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension)) +
                            element.dimension.toLowerCase() +
                            ".svg"
                          } alt="" />
                        </div>
                      </div>
                    </div>

                  </>
                ) : null
              )
              : null}

          </div>
        </div>
      </div>

      {/* Hard Coded design 2*/}
      <div className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="flex items-center justify-between w-full bg-white">
          <div className="w-full px-12 space-y-2">
            <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
              <h1 className="text-lg font-semibold">
                {EditButtonFn(
                  "TalentAssessmentReport",
                  "TalentPlusStrengths Assessment"
                )}
              </h1>
              <p className="text-lg font-semibold">
                {(crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "")} | {createdAt}
              </p>
            </div>

            <div className="flex items-center justify-start space-x-6">
              <div className="flex p-2 space-x-2 border rounded-full">
                {getAssessmentReportByEmpIDData &&
                  getAssessmentReportByEmpIDData.length > 0
                  ? getAssessmentReportByEmpIDData.map((element, index) =>
                    index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? cirleColor(element) : null
                  )
                  : null}
              </div>

              <div className="flex p-2 space-x-2 border rounded-full ">
                {getAssessmentReportByEmpIDData &&
                  getAssessmentReportByEmpIDData.length > 0
                  ? getAssessmentReportByEmpIDData.map((element, index) =>
                    getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                      ? cirleColor(element)
                      : null
                  )
                  : null}
              </div>

              <div className="flex p-2 space-x-2 border rounded-full ">
                {getAssessmentReportByEmpIDData &&
                  getAssessmentReportByEmpIDData.length > 0
                  ? getAssessmentReportByEmpIDData.map((element, index) =>
                    getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? cirleColor(element) : null
                  )
                  : null}
              </div>
            </div>

            <div className="space-y-2">
              <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">Moderate TalentPlus Strengths Themes</h1>
              <p className="text-base leading-tight">Your <b>Moderate</b> themes are talents that you possess and can draw upon when needed. Mastering thesetalents allows for further development and growth.</p>
            </div>

            {getAssessmentReportByEmpIDData &&
              getAssessmentReportByEmpIDData.length > 0
              ? getAssessmentReportByEmpIDData.map((element, index) =>
                getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (
                  <>

                    <div style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.20") }} className="cursor-pointer bg-[#ffc107]/20 rounded-lg 2xl:px-8 md:py-2 p-4 md:flex justify-between items-center md:space-x-6 md:space-y-0 space-y-3">
                      <div className="space-y-1 md:w-[30%]">
                        <button className="px-4 py-1 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest rounded-full bg-[#ffc107] " style={{
                          background:
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                        }}>{EditDetailsFn(element, "bucket", element.bucket)}</button>
                        <h1 className="text-lg font-medium text-slate-900 md:px-4">
                          {(index + 1 > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                            ? (index + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString()
                            : (index + 1).toString()) + ". "}

                          {EditDetailsFn(
                            element,
                            "dimension",
                            element.dimension
                          )}

                        </h1>
                      </div>
                      <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end">
                        <p className="flex-1 text-sm font-normal md:text-base text-slate-600">{EditDetailsFn(element, "desc", element.details.desc)}</p>
                        <div className="w-16 md:w-[4rem]">
                          <img src={
                            "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension)) +
                            element.dimension.toLowerCase() +
                            ".svg"
                          } alt="" />
                        </div>
                      </div>
                    </div>

                  </>
                ) : null
              )
              : null}

          </div>
        </div>

      </div>

      {/* Page 3 Bright Spot*/}
      <div className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="flex items-center justify-between w-full bg-white">
          <div className="w-full px-12 space-y-4">
            <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
              <h1 className="text-lg font-semibold">
                {EditButtonFn(
                  "TalentAssessmentReport",
                  "TalentPlusStrengths Assessment"
                )}
              </h1>
              <p className="text-lg font-semibold">
                {(crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "")} | {createdAt}
              </p>
            </div>

            <div class="">
              <h2 class="text-2xl font-bold text-gray-800 mb-6">Bright Spot:</h2>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* <!-- Left Column --> */}
                <div class="space-y-4">
                  {sentenceList && sentenceList.length > 0 &&
                    sentenceList.map((ele, index) =>
                      (index >= 0 && index < 8) &&
                      <div>
                        <h3 class="font-semibold text-gray-900">{(index + 1).toString() + ". " + ele.title}</h3>
                        <ul class="list-disc pl-6 mt-1 text-sm text-gray-700">

                          {getSummaryBulletPoints(ele, "desc") &&
                            getSummaryBulletPoints(ele, "desc").length > 0
                            ? getSummaryBulletPoints(ele, "desc").map(
                              (text, index) =>
                                <li>{text}</li>
                            )
                            : null}
                        </ul>
                      </div>
                    )}

                </div>

                {/* <!-- Right Column --> */}
                <div class="space-y-4">
                  {sentenceList && sentenceList.length > 0 &&
                    sentenceList.map((ele, index) =>
                      (index >= 8 && index < 15) &&
                      <div>
                        <h3 class="font-semibold text-gray-900">{(index + 1).toString() + ". " + ele.title}</h3>
                        <ul class="list-disc pl-6 mt-1 text-sm text-gray-700">

                          {getSummaryBulletPoints(ele, "desc") &&
                            getSummaryBulletPoints(ele, "desc").length > 0
                            ? getSummaryBulletPoints(ele, "desc").map(
                              (text, index) =>
                                <li>{text}</li>
                            )
                            : null}
                        </ul>
                      </div>
                    )}

                </div>

              </div>
            </div>

          </div>
        </div>

      </div>


      {/* Page 4 Blind Spot*/}
      <div className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="flex items-center justify-between w-full bg-white">
          <div className="w-full px-12 space-y-4">
            <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
              <h1 className="text-lg font-semibold">
                {EditButtonFn(
                  "TalentAssessmentReport",
                  "TalentPlusStrengths Assessment"
                )}
              </h1>
              <p className="text-lg font-semibold">
                {(crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "")} | {createdAt}
              </p>
            </div>

            <div class="">
              <h2 class="text-2xl font-bold text-gray-800 mb-6">Blind Spot:</h2>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* <!-- Left Column --> */}
                <div class="space-y-4">
                  {sentenceBlindSpotList && sentenceBlindSpotList.length > 0 &&
                    sentenceBlindSpotList.map((ele, index) =>
                      (index >= 0 && index < 5) &&
                      <div>
                        <h3 class="font-semibold text-gray-900">{(index + 1).toString() + ". " + ele.title}</h3>
                        <ul class="list-disc pl-6 mt-1 text-sm text-gray-700">

                          {getSummaryBulletPoints(ele, "desc") &&
                            getSummaryBulletPoints(ele, "desc").length > 0
                            ? getSummaryBulletPoints(ele, "desc").map(
                              (text, index) =>
                                <li>{text}</li>
                            )
                            : null}
                        </ul>
                      </div>
                    )}

                </div>

                {/* <!-- Right Column --> */}
                <div class="space-y-4">
                  {sentenceBlindSpotList && sentenceBlindSpotList.length > 0 &&
                    sentenceBlindSpotList.map((ele, index) =>
                      (index >= 5 && index < 10) &&
                      <div>
                        <h3 class="font-semibold text-gray-900">{(index + 1).toString() + ". " + ele.title}</h3>
                        <ul class="list-disc pl-6 mt-1 text-sm text-gray-700">

                          {getSummaryBulletPoints(ele, "desc") &&
                            getSummaryBulletPoints(ele, "desc").length > 0
                            ? getSummaryBulletPoints(ele, "desc").map(
                              (text, index) =>
                                <li>{text}</li>
                            )
                            : null}
                        </ul>
                      </div>
                    )}

                </div>

              </div>
            </div>

          </div>
        </div>

      </div>


    </>
  );
}