import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { MdOutlineArrowDropDown } from 'react-icons/md';

import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay, Scrollbar, A11y, loop } from 'swiper/modules';

const ImpackSwiper = (props) => {
    let { getDetailsList } = props;
    const sliderRef = useRef(null);

    const handlePrev = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slideNext();
        }
    };

    return (
        <div className="relative w-full py-10 space-y-10 md:py-16 ">
            <section className="text-center 2xl:w-9/12 xl:w-11/12 w-full  mx-auto xl:px-0 px-4  flex items-center justify-between  ">
                <h1 data-aos="slide-up" className="  md:text-4xl text-2xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-left">Be The Change</h1>
                <div className='flex flex-row gap-4 items-center'>
                    <div className="prev-arrow md:w-12 w-10 md:h-12 h-10 bg-gray-100 rounded-full cursor-pointer flex justify-center items-center hover:bg-[#1976D2] transition-all duration-150 text-gray-600 hover:text-white" onClick={handlePrev}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M15 6l-6 6l6 6"></path>
                        </svg>
                    </div>
                    <div className="next-arrow md:w-12 w-10 md:h-12 h-10 bg-gray-100 rounded-full cursor-pointer flex justify-center items-center hover:bg-[#1976D2] transition-all duration-150 text-gray-600 hover:text-white" onClick={handleNext}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </div>
                </div>
            </section>

            <Swiper
                ref={sliderRef}
                navigation={false}
                mousewheel={true}
                keyboard={true} 
                loop={true}
                autoplay={true}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]} 
                spaceBetween={40}  
                className="2xl:w-9/12 xl:w-11/12  w-11/12 mx-auto mySwiper mb-20 2xl:min-h-[54rem] xl:min-h-[62rem] lg:min-h-[62rem] md:min-h-[46rem] min-h-[50rem] 2xl:max-h-[60rem] xl:max-h-[68rem]">

                <SwiperSlide className="bg-[#edf6fe] rounded-3xl h-auto">
                    <div className="relative flex flex-col w-full mx-auto mb-6 overflow-hidden tooltip-asisment lg:flex-col rounded-3xl ">
                        <div className="  w-full bg-[#2b97f1]   xl:p-20 xl:px-10 md:p-10 p-4 flex items-center lg:h-96 h-[20rem]    relative ">
                            <p className="text-2xl text-center text-white md:text-3xl xl:text-4xl">
                                {" "}
                                An engaging and profound dialogue that broadens cognitive
                                horizons, fostering heightened selfawareness and facilitating
                                enhanced clarity.
                            </p>
                            <MdOutlineArrowDropDown className="text-[#2b97f1] text-[8rem] absolute inset-x-[calc(50%-60px)] top-[calc(100%-54px)]  " />


                        </div>
                        <div className="flex flex-col justify-center w-full p-6 pt-10 space-y-4 text-center lg:pt-20 md:p-10 xl:px-20 md:space-y-6">
                            <h1 className="md:text-3xl text-2xl font-semibold text-[#3D405B] transition-all duration-200 capitalize">
                                The Outcome:
                            </h1>
                            <p className="md:text-lg text-base text-[#3D405B]">
                                A heightened comprehension of essential priorities and genuine
                                alignment with personal intentions. Equipped with this newfound
                                awareness, individuals forge a distinct trajectory and cultivate
                                the capacity to uphold personal accountability towards their
                                desired accomplishments.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className="bg-[#673AB7]/10 rounded-3xl h-auto">
                    <div className="relative flex flex-col w-full mx-auto mb-6 overflow-hidden tooltip-asisment lg:flex-col rounded-3xl ">
                        <div className="  w-full bg-[#673AB7]   xl:p-20 xl:px-10 md:p-10 p-4 flex items-center lg:h-96 h-[20rem]    relative ">
                            <p className="text-2xl text-center text-white md:text-3xl xl:text-4xl">

                                {" "}
                                Inside-Out Principle At Work. Unveiling the Unconscious &
                                Overcoming Repressive Barriers
                            </p>
                            <MdOutlineArrowDropDown className="text-[#673AB7] text-[8rem] absolute inset-x-[calc(50%-60px)] top-[calc(100%-54px)]  " />
                        </div>

                        <div className="flex flex-col justify-center w-full p-6 pt-10 space-y-4 text-center lg:pt-20 md:p-10 xl:px-20 md:space-y-6">
                            <h1 className="md:text-3xl text-2xl font-semibold text-[#3D405B] transition-all duration-200 capitalize">
                                The Outcome:
                            </h1>

                            <p className="md:text-lg text-base text-[#3D405B]">
                                The Inside-Out Principle exemplifies the transformative force of
                                harnessing our inner talent to drive change and shape our
                                external realities. By tapping into our core strengths, values,
                                and beliefs, we activate a powerful force that propels us
                                towards growth and fulfillment. This principle emphasizes that
                                true transformation and lasting impact originate from within,
                                guiding our actions and choices with authenticity and purpose.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className=" bg-[#673AB7]/10 rounded-3xl h-auto">
                    <div className="relative flex flex-col w-full mx-auto mb-6 overflow-hidden tooltip-asisment lg:flex-col rounded-3xl">
                        <div className="  w-full bg-[#4CAF50]   xl:p-20 xl:px-10 md:p-10 p-4 flex items-center lg:h-96 h-[20rem]    relative ">

                            <p className="text-2xl text-center text-white md:text-3xl xl:text-4xl">


                                {" "}
                                Make a difference - Take the Lead in Cultivating a Growth
                                Mindset Culture: Empowering Talent and Strengths
                            </p>
                            <MdOutlineArrowDropDown className="text-[#4CAF50] text-[8rem] absolute inset-x-[calc(50%-60px)] top-[calc(100%-54px)]  " />
                        </div>

                        <div className="flex flex-col justify-center w-full p-6 pt-10 space-y-4 text-center lg:pt-20 md:p-10 xl:px-20 md:space-y-6">
                            <h1 className="md:text-3xl text-2xl font-semibold text-[#3D405B] transition-all duration-200 capitalize">
                                The Outcome:
                            </h1>

                            <p className="md:text-lg text-base text-[#3D405B]">
                                Cultivate a growth mindset culture that develops individual
                                talents and strengths. Utilize structural coaching to support
                                employees in recognizing their potential and fostering
                                continuous growth. By doing so, organizations unlock the full
                                potential of their workforce, leading to increased engagement,
                                productivity, and overall success.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="bg-[#673AB7]/10 rounded-3xl h-auto">
                    <div className="relative flex flex-col w-full mx-auto mb-6 overflow-hidden tooltip-asisment lg:flex-col rounded-3xl ">
                        <div className="  w-full bg-[#673AB7]   xl:p-20 xl:px-10 md:p-10 p-4 flex items-center lg:h-96 h-[20rem]    relative ">
                            <p className="text-2xl text-center text-white md:text-3xl xl:text-4xl">

                                {" "}
                                Inside-Out Principle At Work. Unveiling the Unconscious &
                                Overcoming Repressive Barriers
                            </p>
                            <MdOutlineArrowDropDown className="text-[#673AB7] text-[8rem] absolute inset-x-[calc(50%-60px)] top-[calc(100%-54px)]  " />
                        </div>

                        <div className="flex flex-col justify-center w-full p-6 pt-10 space-y-4 text-center lg:pt-20 md:p-10 xl:px-20 md:space-y-6">
                            <h1 className="md:text-3xl text-2xl font-semibold text-[#3D405B] transition-all duration-200 capitalize">
                                The Outcome:
                            </h1>

                            <p className="md:text-lg text-base text-[#3D405B]">
                                The Inside-Out Principle exemplifies the transformative force of
                                harnessing our inner talent to drive change and shape our
                                external realities. By tapping into our core strengths, values,
                                and beliefs, we activate a powerful force that propels us
                                towards growth and fulfillment. This principle emphasizes that
                                true transformation and lasting impact originate from within,
                                guiding our actions and choices with authenticity and purpose.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                
          
              
            </Swiper>

        </div>
    );
};

export default ImpackSwiper;
