 

import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogoHeader from '../Surveylink/Component/LogoHeader';
import { userActions } from '../../_actions';


class Ssologin extends Component {
  constructor(props) {
    super(props);


    this.state = {
    }
  }

  componentDidMount() {
    let url = this.props.location.search;
    let secretCode = url.split('?data=').pop();
    
    let data={
      "cipher":secretCode
    }
    this.props.dispatch(userActions.darwinboxVerify(data,this.props));

  }


  
  render() {
    let { user } = this.props;
    let { darwinboxVerifyEmailMSG } = user;

    console.log("darwinboxVerifyEmailMSG: ",darwinboxVerifyEmailMSG)

    let surveyScreenData = {
      AlreadyText: "Survey Is Already Submitted !",
      ContinueButtonText: "Continue",
      ExpiryText: "This Survey is Expired!",
      OptionColor1: "#ffffff",
      OptionColor2: "#000000",
      OptionColor3: "#0770e9",
      StartButtonText: "Start Survey",
      SubmitButtonText: "Submit",
      WriteHereText: "Write here",
      bgColor: "#ffffff",
      bgImage: "",
      bgType: 1,
      buttonColor1: "#0069e4",
      buttonColor2: "#ffffff",
      designTemplate: "v4",
      langList:[],
      name: "Employee Engagement Survey",
      okButtonText: "OK",
      textColor: "#262626",
      transl: {}
    }

    const isMobile = window.innerWidth <= 700;
    
    if(isMobile){
      surveyScreenData["logo2"] = "img/companylogo/kotaklogo2.png";
    }
    else{
      surveyScreenData["logo2"] = "img/companylogo/kotakbanner.png";
    }
    surveyScreenData["logo"] = "img/companylogo/umatters.png";
    


  return (
      <>

    <LogoHeader
        surveyScreenData={surveyScreenData}
        template={'v4'}
      />

      <div className="mt-24 flex justify-center items-center text-lg font-semibold py-4 cursor-default">
        {darwinboxVerifyEmailMSG?darwinboxVerifyEmailMSG:"Redirecting to your survey link ..."}
      </div>


      </>
      );
  }
}

function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user } = state;
  return {
    loggingIn,
    questions,
    user

  };
}

export default connect(mapStateToProps)(Ssologin);
