 

import React, { Component } from 'react';

import { connect } from 'react-redux';

import LogoHeader from '../Surveylink/Component/LogoHeader';

import { questionsActions, userActions } from '../../_actions';


class EmployeeLogin extends Component {
  constructor(props) {
    super(props);

    this.inputChangeEmpId = this.inputChangeEmpId.bind(this);
    this.inputChangeOTP = this.inputChangeOTP.bind(this);

    this.state = {
      emp_id: "",
      otp_code: '',
      notFoundText:"",
      showLogin:true,
      failureMSG:'',
      failureOTPMSG:''
    }
  }

  componentDidMount() {

    this.props.dispatch(questionsActions.getSurveyDetailsBySurveyCode({ code: this.props.match.params.id }));

    

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.UserLoginEmailSuccess) {
      return {
        ...nextProps,
        showLogin:false

      }
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!"

      }
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!"

      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  // handleEmpInput=(e)=>{
  //   this.setState({emp_id: e.target.value});
  // }

  inputChangeEmpId(e) {
    this.setState({ emp_id: e.target.value });
  }

  inputChangeOTP(e) {
    this.setState({ otp_code: e.target.value });
  }

  // submitEmpId=()=>{
  //   let { emp_id } = this.state;
  //   if(emp_id){
  //     this.props.dispatch(userActions.userSurveyLogin({emp_id:emp_id}, this.props));
  //   }
  // }


  submitEmpId=()=> {
    let {emp_id}=this.state;
    if(emp_id){
      this.props.dispatch(userActions.userlogin({ emp_id:emp_id, code: this.props.match.params.id },this.props));
      this.setState({ failureMSG: '' });
    }
  }


  OTPSubmit=()=> {
    const { user } = this.props;
    const { UserEmailToken } = user;
    // console.log("UserEmailToken: ",UserEmailToken)
    let {otp_code}=this.state;
    let data = {
        token:UserEmailToken, 
        otp:otp_code,
        code: this.props.match.params.id
    }
    // console.log("data: ",data)
    this.setState({ failureOTPMSG: '' });

    this.props.dispatch(userActions.validateOtp(data,this.props));
  }



  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitEmpId()
    }
  }

  
  render() {

    let { questions, user } = this.props;
    let { getEmployeeStatusData, employeeMessage, getSurveyDetailsBySurveyCodeData } = questions;
    let { emp_id , notFoundText, otp_code} = this.state;
    let { UserLoginErrorMSG } = user

    console.log("emp_id: ",emp_id);
    console.log("otp_code: ",otp_code);

    let surveyScreenData = getSurveyDetailsBySurveyCodeData;

    let errorMsh = "";
    if(UserLoginErrorMSG){
      errorMsh = UserLoginErrorMSG;
    }


    // let surveyScreenData = {
    //   AlreadyText: "Survey Is Already Submitted !",
    //   ContinueButtonText: "Continue",
    //   ExpiryText: "This Survey is Expired!",
    //   OptionColor1: "#ffffff",
    //   OptionColor2: "#000000",
    //   OptionColor3: "#0770e9",
    //   StartButtonText: "Start Survey",
    //   SubmitButtonText: "Submit",
    //   WriteHereText: "Write here",
    //   bgColor: "#ffffff",
    //   bgImage: "",
    //   bgType: 1,
    //   buttonColor1: "#0069e4",
    //   buttonColor2: "#ffffff",
    //   designTemplate: "v4",
    //   langList:[],
    //   name: "Employee Engagement Survey",
    //   okButtonText: "OK",
    //   textColor: "#262626",
    //   transl: {}
    // }

    const isMobile = window.innerWidth <= 700;
    
    // if(isMobile){
    //   surveyScreenData["logo2"] = "img/companylogo/kotaklogo2.png";
    // }
    // else{
    //   surveyScreenData["logo2"] = "img/companylogo/kotakbanner.png";
    // }
    // surveyScreenData["logo"] = "img/companylogo/umatters.png";
    
    // console.log("getSurveyDetailsBySurveyCodeData--------->",getSurveyDetailsBySurveyCodeData);

  console.log("getSurveyDetailsBySurveyCodeData--------->",getSurveyDetailsBySurveyCodeData);

  return (
      <>

      <LogoHeader
        surveyScreenData={surveyScreenData}
        template={'v4'}
      />

    <div className='flex h-screen' >
{/* right section */}
    <div className=' bg-[#2196f3] w-4/12'>


    </div>

      <div className="mt-24 w-8/12">
        <img src={surveyScreenData && surveyScreenData.logo?surveyScreenData.logo:""} style={{height:"70px"}} className="mx-auto lg:my-8 my-4" />
        <h1 className="text-center md:text-xl text-lg font-bold capitalize px-6">
          {"Welcome to the "}
          {(surveyScreenData && surveyScreenData.name)? surveyScreenData.name:""
          }
        </h1>


        {this.state.showLogin || true?
          <>
            <div className="bg-white shadow-xl md:max-w-md  md:mx-auto mx-6 md:my-6 my-4 p-10  ">
              <label className>Employee ID:</label>  <br />
              <input onKeyDown={this._handleKeyDown} autocomplete="off" type="text" value={emp_id} name="emp_id" onChange={this.inputChangeEmpId} 
              placeholder="Enter here..." className="border w-full p-2 text-sm focus:outline-none ring-blue-500 focus:ring-1 mt-4" />
              {errorMsh?
              <>
              <p className="pt-2 text-red-500">{errorMsh}</p>
              <button onClick={()=>this.submitEmpId()} className="bg-blue-500 text-white px-4 py-2 w-full mt-3 hover:bg-blue-600 ">Submit</button>
              </>
              :
              <>
              <button onClick={()=>this.submitEmpId()} className="bg-blue-500 text-white px-4 py-2 w-full mt-6 hover:bg-blue-600 ">Submit</button>
              </>}
            </div>
          </>
        :
          <>
            <div className="bg-white shadow-xl md:max-w-md  md:mx-auto mx-6 md:my-6 my-4 p-10  ">
            <label className>Verify OTP:</label>  <br />
            <input autocomplete="off" type="text" value={otp_code} name="otp_code" onChange={this.inputChangeOTP} 
            placeholder="Enter otp..." className="border w-full p-2 text-sm focus:outline-none ring-blue-500 focus:ring-1 mt-4" />
            {notFoundText==="Employee not found!"?
            <>
            <p className="pt-2 text-red-500">{employeeMessage}</p>
            <button onClick={()=>this.OTPSubmit()} className="bg-blue-500 text-white px-4 py-2 w-full mt-3 hover:bg-blue-600 ">Submit</button>
            </>
            :
            <>
            <button onClick={()=>this.OTPSubmit()} className="bg-blue-500 text-white px-4 py-2 w-full mt-6 hover:bg-blue-600 ">Submit</button>
            </>}
          </div>
        </>
      }




        <div className="text-center text-gray-500 mt-8">
          <p className="xl:max-w-md max-w-lg mx-auto text-sm px-4 pb-4">
            {"The survey is CONFIDENTIAL. All data will be directly collected & processed by HappyPlus. No one in the company will have access to individual inputs/feedback."}
          </p>
        </div>
        
        
        <div className="fixed bottom-0  left-0 w-full flex  justify-center bg-white bg-opacity-100">
          <div>
            {/* btn */}

            <p 
            style={{
              backgroundColor:"#fff",
              // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
            }}
            className="text-sm py-4"> © Powered by <b className="font-semibold">happyPlus</b></p>
          </div>
      </div>


      </div>

    </div>






      </>
      );
  }
}
EmployeeLogin.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user } = state;
  return {
    loggingIn,
    questions,
    user

  };
}

export default connect(mapStateToProps)(EmployeeLogin);
