import React from 'react';

export default function Component(props) {

const { question, answersList, ansNPSInput, surveyLanguageCode, SurveyAttributes, isMobile, getTheme,

  questionsList, CurrQuesIndex, nextQues


} = props;
  // let [showValue, handleValue ] = React.useState(0)

// let showValue = false;
// function handleValue(){}


let [showValue2, handleValue2 ] = React.useState(111)

let showValue = false;
function handleValue(){}

function CustomLabels(optionID,optionName, question){
  let surveyLanguageCode = "customNPS";
  return  (surveyLanguageCode!=='')?
  (question && question.transl &&
    question.transl[surveyLanguageCode] &&
        question.transl[surveyLanguageCode][optionID])?
          question.transl[surveyLanguageCode][optionID]:optionName
  :optionName
}


function NPSName(optionID, optionName, surveyLanguageCode, question){

  return  (surveyLanguageCode!=='')?
  (question && question.transl &&
    question.transl[surveyLanguageCode] &&
      question.transl[surveyLanguageCode].optionsList &&
        question.transl[surveyLanguageCode].optionsList[optionID])?
          question.transl[surveyLanguageCode].optionsList[optionID]:optionName
  :optionName
}



function NPSColor(question){



  let Color = "#a3a3a3";
  let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
  let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
  if(showValue){
    answer = showValue;
  }


  if(answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 ){
    Color = "#2074e3";
    if(answer === 1 || answer === 2){
      Name = NPSName("Extremelydissatisfied", CustomLabels("Extremelydissatisfied", "Extremely dissatisfied",question), surveyLanguageCode, question)
    }
    else if(answer === 3 || answer === 4){
      Name = NPSName("Dissatisfied", CustomLabels("Dissatisfied", "Dissatisfied",question), surveyLanguageCode, question)
      console.log('Dissatisfied Name: ',Name)
    }
    else if(answer === 5 || answer === 6){
      Name = NPSName("Neither",  CustomLabels("Neither", "Neither",question), surveyLanguageCode, question)
    }
  }
  else if(answer === 7 || answer === 8){
    Color = "#2074e3";
    Name = NPSName("Satisfied", CustomLabels("Satisfied", "Satisfied",question), surveyLanguageCode, question)
  }
  else if(answer === 9 || answer === 10){
    Color = "#2074e3";
    Name = NPSName("Extremelysatisfied", CustomLabels("Extremelysatisfied", "Extremely Satisfied",question), surveyLanguageCode, question)
  }

  return {
    color:'rgba(255, 255, 255, 0.3)',
    name:Name
  }
}


function NPSColor2(question){



  let Color = "#a3a3a3";
  let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
  let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
  if(showValue2 || showValue2===0){
    answer = showValue2;
  }


  if(answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 ){
    Color = "#2074e3";
    if(answer === 1 || answer === 2){
      Name = NPSName("Extremelydissatisfied", CustomLabels("Extremelydissatisfied", "Extremely dissatisfied",question), surveyLanguageCode, question)
    }
    else if(answer === 3 || answer === 4){
      Name = NPSName("Dissatisfied", CustomLabels("Dissatisfied", "Dissatisfied",question), surveyLanguageCode, question)
      console.log('Dissatisfied Name: ',Name)
    }
    else if(answer === 5 || answer === 6){
      Name = NPSName("Neither", CustomLabels("Neither", "Neither",question), surveyLanguageCode, question)
    }
  }
  else if(answer === 7 || answer === 8){
    Color = "#2074e3";
    Name = NPSName("Satisfied", CustomLabels("Satisfied", "Satisfied",question), surveyLanguageCode, question)
  }
  else if(answer === 9 || answer === 10){
    Color = "#2074e3";
    Name = NPSName("Extremelysatisfied", CustomLabels("Extremelysatisfied", "Extremely Satisfied",question), surveyLanguageCode, question)
  }

  return {
    color:Color,
    name:Name
  }
}

function matchAnswer(answersList1,question1){
  let value = "";
  if(answersList1 && answersList1.length>0){
    let getIndex1 = answersList1.findIndex(item => item.questionId === question1.id);
    if(getIndex1!==-1){
      value = answersList1 && answersList1[getIndex1] && answersList1[getIndex1].answer?answersList1[getIndex1].answer:"";
    }
  }
  return value
}

console.log("matchAnswer(answersList,question)--->",matchAnswer(answersList,question))







return(
    <>
        {/* <span className="italic text-gray-500 text-sm px-4">10 = great place to work, 1 = not so great place to work *</span> */}
        <span className={(isMobile?"text-sm ":"text-base ")+ " text-white pt-4 px-4 md:hidden block italic font-medium"} style={{color:getTheme("text","#FFFFFF")}} >
          {SurveyAttributes("I am not like this")?SurveyAttributes("I am not like this"):"I am not like this"}
          </span>

          <div className="grid md:grid-cols-10 grid-cols-5 gap-4 md:py-6 py-2  lg:text-lg text-sm mx-4">
            {/* 1 */}
            {[...Array(10).keys()].map((value,index)=>
            <>
            {((value+1).toString()===matchAnswer(answersList,question))?
              <>
              <span onMouseEnter={()=>handleValue2((value+1))}  onClick={()=>ansNPSInput((value+1).toString(),question)} key={index}
              style={{background:getTheme("lightblue",NPSColor(question,surveyLanguageCode).color), color:getTheme("textblue","#FFFFFF"), borderColor:getTheme("textblue","#FFFFFF")}}
              className="my-2 bg-red-500 rounded-md md:p-4 md:py-4 p-4 py-2 text-white border border-white  flex items-center justify-center  hover:bg-red-400  font-medium  cursor-pointer px-3">
              {value+1}
              </span>
              </>
              :
            <>
            {(value+1) <= (showValue || parseInt(matchAnswer(answersList,question)))?
            <>
              <span onMouseEnter={()=>handleValue2((value+1))}  onClick={()=>ansNPSInput((value+1).toString(),question)} key={index}
                style={{background:getTheme("lightblue",NPSColor(question,surveyLanguageCode).color), color:getTheme("textblue","#FFFFFF"), borderColor:getTheme("textblue","#FFFFFF")}}
                className="my-2 bg-red-500  rounded-md md:p-4 md:py-4 p-4  text-white border border-white  flex items-center justify-center  hover:bg-red-400  font-medium  cursor-pointer   px-3">
                {value+1}
              </span>
              </>
            :
            <>
            <span onMouseEnter={()=>handleValue2((value+1))}  onClick={()=>ansNPSInput((value+1).toString(),question)} key={index}
                className={(getTheme("hoverActive"," hover:bg-white/40 hover:text-white "))+(getTheme("textOption"," border-white bg-white/10 "))+" border my-2  rounded-md md:p-4 md:py-4 p-4  flex items-center  justify-center text-[#212121]   font-medium  cursor-pointer  px-3 transition-all duration-200"}>
              {value+1}
              </span>
            </>
            }
            </>
            }
            </>
            )}

          </div>

          <div className="flex md:justify-between justify-end text-white italic" style={{color:getTheme("text","#FFFFFF"), borderColor:getTheme("text","#FFFFFF")}}>
            <span className={(isMobile?"text-sm ":"text-base ")+" px-4 font-medium md:block hidden"}>{SurveyAttributes("I am not like this")?SurveyAttributes("I am not like this"):"I am not like this"}</span>
            <span className={(isMobile?"text-sm ":"text-base ")+" px-4 font-medium"}>{SurveyAttributes("I am like this")?SurveyAttributes("I am like this"):"I am like this"}</span>
          </div>
         
       




{/*       <div
          className="text-blue-500 font-semibold text-center mb-2 rounded-sm py-1 mx-2">{NPSColor2(question,surveyLanguageCode).name}</div> */}
          {/* <div style={{background:NPSColor(question,surveyLanguageCode).color}}
          className="bg-red-500 text-white text-center mb-2 rounded-sm py-1 mx-2">{NPSColor(question,surveyLanguageCode).name}</div> */}





    <div className="flex justify-center mt-6">
      {questionsList && questionsList.length > 0 ? (
        questionsList.length !== CurrQuesIndex ? (
          <button
            onClick={() => nextQues(2)}
            className="md:w-80 w-60  uppercase md:text-xl text-base font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-4 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden "
          >
            <span className=" bg-white rounded-full opacity-10"></span>
            Next
          </button>
        ) : null
      ) : null}
    </div>


    </>
  );}