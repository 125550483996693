export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_ASSESSMENT_REQUEST: 'USERS_LOGIN_ASSESSMENT_REQUEST',
    LOGIN_ASSESSMENT_SUCCESS: 'USERS_LOGIN_ASSESSMENT_SUCCESS',
    LOGIN_ASSESSMENT_FAILURE: 'USERS_LOGIN_ASSESSMENT_FAILURE',

    LOGIN_OTP_REQUEST: 'USERS_LOGIN_OTP_REQUEST',
    LOGIN_OTP_SUCCESS: 'USERS_LOGIN_OTP_SUCCESS',
    LOGIN_OTP_FAILURE: 'USERS_LOGIN_OTP_FAILURE',

    LOGIN_ASSESSMENT_OTP_REQUEST: 'USERS_LOGIN_ASSESSMENT_OTP_REQUEST',
    LOGIN_ASSESSMENT_OTP_SUCCESS: 'USERS_LOGIN_ASSESSMENT_OTP_SUCCESS',
    LOGIN_ASSESSMENT_OTP_FAILURE: 'USERS_LOGIN_ASSESSMENT_OTP_FAILURE',

    DARWIN_OTP_REQUEST: 'DARWIN_OTP_REQUEST',
    DARWIN_OTP_SUCCESS: 'DARWIN_OTP_SUCCESS',
    DARWIN_OTP_FAILURE: 'DARWIN_OTP_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    USER_CREATE_CONTACTUS_REQUEST: 'USER_CREATE_CONTACTUS_REQUEST',
    USER_CREATE_CONTACTUS_SUCCESS: 'USER_CREATE_CONTACTUS_SUCCESS',
    USER_CREATE_CONTACTUS_FAILURE: 'USER_CREATE_CONTACTUS_FAILURE',

    USER_ASSESSMENT_REGISTRATION_REQUEST: 'USERS_USER_ASSESSMENT_REGISTRATION_REQUEST',
    USER_ASSESSMENT_REGISTRATION_SUCCESS: 'USERS_USER_ASSESSMENT_REGISTRATION_SUCCESS',
    USER_ASSESSMENT_REGISTRATION_FAILURE: 'USERS_USER_ASSESSMENT_REGISTRATION_FAILURE',

    USER_ASSESSMENT_VERIFY_REQUEST: 'USERS_USER_ASSESSMENT_VERIFY_REQUEST',
    USER_ASSESSMENT_VERIFY_SUCCESS: 'USERS_USER_ASSESSMENT_VERIFY_SUCCESS',
    USER_ASSESSMENT_VERIFY_FAILURE: 'USERS_USER_ASSESSMENT_VERIFY_FAILURE',

    SIGNIN_ASSESSMENT_REQUEST: 'USERS_SIGNIN_ASSESSMENT_REQUEST',
    SIGNIN_ASSESSMENT_SUCCESS: 'USERS_SIGNIN_ASSESSMENT_SUCCESS',
    SIGNIN_ASSESSMENT_FAILURE: 'USERS_SIGNIN_ASSESSMENT_FAILURE',

    SIGNIN_ASSESSMENT_VALIDATE_OTP_REQUEST: 'SIGNIN_ASSESSMENT_VALIDATE_OTP_REQUEST',
    SIGNIN_ASSESSMENT_VALIDATE_OTP_SUCCESS: 'SIGNIN_ASSESSMENT_VALIDATE_OTP_SUCCESS',
    SIGNIN_ASSESSMENT_VALIDATE_OTP_FAILURE: 'SIGNIN_ASSESSMENT_VALIDATE_OTP_FAILURE',

};
