
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
import CryptoJS from 'crypto-js';

export const userService = {
    userlogin,
    userAssessmentLogin,
    logout,
    validateOtp,
    validateAssessmentOtp,
    darwinboxVerify,

    userAssessmentRegistration,
    userAssessmentEmailVerify,
    userAssessmentSignIn,
    userAssessmentValidateOtp,

    contactUs,
    assessmentLogout,
    surveyLogout,

};
function logout() {
    localStorage.removeItem('adminuser');
    // window.location.href = "#/login";
}


function assessmentLogout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/assessment/sign-in";
    window.location.reload();
}

function surveyLogout() {
    localStorage.removeItem('surveyuser');
    window.location.href = "#/assessment/surveylogin";
    window.location.reload();
}



function userlogin(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/userSurveyLogin`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            let userObj = {
                userinfoToken: user.data
            }

            return userObj;
        });
}


function userAssessmentLogin(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentLogin`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            let userObj = {
                userinfoToken: user.data
            }

            return userObj;
        });
}

function validateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateSurveyOtp`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            // if (user.data) {
            //     localStorage.setItem('adminuser', JSON.stringify(user.data));
            // }

            return userObj;
        });
}

function validateAssessmentOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/validateAssessmentOtp`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {

            let userObj = {
                userinfo: user.data
            }

            if (user.data) {

                if(user && user.data){
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(user.data), CONST.CRYPTO_KEY).toString();
                    localStorage.setItem('adminuser',  JSON.stringify(cryptedText));
                }

                // localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}


function contactUs(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/contactUs`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {

            let userObj = {
                contactUsData: data.data
            }
            return userObj;
        });
}


function darwinboxVerify(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/darwinboxVerify`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            let userObj = {
                darwinboxVerify: user.data
            }

            return userObj;
        });
}




function userAssessmentRegistration(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentRegistration`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            let userObj = {
                userAssessmentRegistrationData: user.data
            }

            return userObj;
        });
}





function userAssessmentEmailVerify(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentEmailVerify`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            if (user.data) {

                if(user && user.data){
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(user.data), CONST.CRYPTO_KEY).toString();
                    localStorage.setItem('adminuser',  JSON.stringify(cryptedText));
                }

                // localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            let userObj = {
                userAssessmentEmailVerifyData: user.data
            }
            return userObj;
        });
}


function userAssessmentSignIn(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentSignIn`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {
            let userObj = {
                userAssessmentinfoToken: user.data
            }

            if(!(userObj && userObj.userAssessmentinfoToken && userObj.userAssessmentinfoToken.token)){
                userObj = {
                    userAssessmentinfoToken: {
                        token:''
                    }
                }
            }

            return userObj;
        });
}


function userAssessmentValidateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentValidateOtp`, requestOptions)
        .then(handleResponseAdmin)
        .then(user => {

            if (user.data) {

                if(user && user.data){
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(user.data), CONST.CRYPTO_KEY).toString();
                    localStorage.setItem('adminuser',  JSON.stringify(cryptedText));
                }

                // localStorage.setItem('adminuser', JSON.stringify(user.data));
            }



            let userObj = {
                userAssessmentinfoToken: user.data
            }
            return userObj;
        });
}


function handleResponseAdmin(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}