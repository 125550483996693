import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { Helmet } from "react-helmet";
class TeenCareerFitment extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      saveSuccess: false,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,

      fieldslogin: {},
      errorslogin: {},

      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(assessmentActions.userAssessmentUserProfile('getAssessmentCartCount'));
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  handleTabClick = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };


  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleDownload = () => {
    let code = "TalentPlusStrengths21";
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    link.click();
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/how-talentplus-strength-works");
    window.scrollTo(0, 0);
  };

  closeOrgTypeModal = () => {
    this.setState({ showOrgTypeModal: false })
  }

  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["type"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;
    if (formData["type"] === "Individual") {
      this.props.history.push(`/assessment/` + "surveylogin");
    } else if (formData["type"] === "Organization") {
      window.location.href = 'https://plusinsight.happyplus.in/surveylink/#/assessment/signin';
    }
  };

  handleTakeAssessmentRedirect = () => {
    this.setState({ showOrgTypeModal: false }, () => {
      this.handleOptionSubmit();
    })
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  loginSubmit = () => {
    console.log(
      "this.handleValidationLogin()---->",
      this.handleValidationLogin()
    );

    if (this.handleValidationLogin()) {
      let { userName, email, phone, message } =
        this.state.fieldslogin;
      this.setState({ saveSuccess: true });
      this.props.dispatch(
        assessmentActions.saveAssessmentContactUs(
          {
            name: userName,
            email: email,
            phone: phone,
            message: message,
          },
          this.props
        )
      );
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  handleValidationLogin = () => {
    let { fieldslogin } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    //userName
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid += 1;
      console.log("1 userName !");
      errorslogin["userName"] = "Full name is required";
    }
    //email
    if (!fieldslogin["email"]) {
      console.log("1 email !");

      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(fieldslogin["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }

    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };

  scrollToElement = () => {
    if (this.myRef.current) {
      const offset = 180; // Adjust the offset value as needed
      const elementPosition = this.myRef.current.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  };

  render() {

    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    // console.log("quantity-->", quantity);
    const cards = [

      {
        id: 1,
        title: 'Celebrating Uniqueness',
        dics: `Recognizes and celebrates the unique talents and abilities of each teenager, valuing their individuality.`,
        icon: '/img/assessment/teentalent/celebrating-uniqueness.svg',
      },
      {
        id: 2,
        title: 'Holistic Evaluation',
        dics: `Challenges traditional success metrics, offering a comprehensive evaluation that goes beyond narrow criteria.`,
        icon: '/img/assessment/teentalent/holistic-evaluation.svg',
      },
      {
        id: 3,
        title: 'Navigating Expectations',
        dics: `Guides teenagers through societal pressures, empowering them to resist predefined expectations and find their authentic path.`,
        icon: '/img/assessment/teentalent/navigating-expectations.svg',
      },
      {
        id: 4,
        title: 'Avoiding Mismatch',
        dics: `Raises awareness about the consequences of pursuing roles misaligned with natural talents, preventing potential wasted potential and struggles.`,
        icon: '/img/assessment/teentalent/avoiding-mismatch.svg',
      },
      {
        id: 5,
        title: 'Purposeful Living',
        dics: `Emphasizes the importance of aligning work with passions, talents, and purpose for a fulfilling and purpose-driven life.`,
        icon: '/img/assessment/teentalent/purposeful-living.svg',
      },
      {
        id: 6,
        title: 'Guiding Alignment',
        dics: `Serves as a compass, guiding teenagers to align their unique talents with both their work and life.`,
        icon: '/img/assessment/teentalent/guiding-alignment.svg',
      },
      {
        id: 7,
        title: 'Empowering Contribution',
        dics: `Enables teenagers to make meaningful contributions by aligning their work with their inherent talents.`,
        icon: '/img/assessment/teentalent/empowering-contribution.svg',
      },
      {
        id: 8,
        title: 'Self-Trust and Confidence',
        dics: `Encourages self-trust, fostering confidence in teenagers to create a life that resonates authentically with their true essence.`,
        icon: '/img/assessment/teentalent/self-trust-and-confidence.svg',
      },
    ]


    return (
      <div className="overflow-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Empowering Teen Talent Potential for Meaningful Growth</title>
          <meta name="description" content="Elevate teen potential with Teenager Talent assessment, fostering confidence, purposeful living, and transformative growth." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/home" />
        </Helmet>


        {/* <TestHeader
          handleTakeAssessmentRedirect={this.handleTakeAssessmentRedirect}
        /> */}

        <section
          className="bg-gradient-to-b from-[#673AB7]/20 w-full h-auto bg-center  bg-cover bg-no-repeat  "
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          {/* Hero Section */}
          <div className="w-full h-full border-t border-black/10">
            <div data-aos="fade-right" className="text-base text-[#212121] font-normal  pt-4 
                left-0 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto ">
              <span className="cursor-pointer" onClick={() => this.gotoPage("home")}>Home</span>  {'>'}  <span className="cursor-pointer" onClick={() => this.gotoPage("teen-career-guidance")}>Career Guidance Program</span>  {'>'}  <span className="text-[#2196F3]">Fitment Analysis</span>
            </div>
            <div className="flex flex-col items-center w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto   ">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto   space-y-8 text-center md:pb-10 md:pt-0 md:text-left relative  ">

                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Teen Career Fitment Analysis: Finding Your Best Career Alignment</h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-[#212121]">Choosing a career can be a complex decision for teens, but understanding your unique strengths, interests, and values can make this process easier. A comprehensive career fitment analysis combines insights from Aptitude Test, Personality Test, Interest Test, and Work Values Test to guide you towards a career that best suits you.
                    <br />
                    <br />
                    By combining the insights from the Aptitude Test, Personality Test, Interest Test, and Work Values Test, you gain a comprehensive understanding of yourself. </p>
                </div>
              </div>
              <div className="flex items-end justify-center w-full lg:justify-end">
                <img data-aos="fade-left" src="/img/assessment/career-fitment-analysis.webp" alt="career-fitment-analysis" className="z-0 w-full" />
              </div>
            </div>
          </div>
        </section>

        <section className="w-full px-4 mx-auto bg-white 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="pt-10 mx-auto space-y-4 text-center">
            <h1
              data-aos="fade-up"
              className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
            >Career Fitment Analysis   integrated approach provides</h1>

          </div>
        </section>
        {/* Learning section 1*/}
        <section className="py-20">
          <div className="items-center w-full gap-10 px-4   mx-auto   2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-6 gap-10 w-full  ">
              <div data-aos="fade-up" className=" w-full bg-gradient-to-b from-[#2196F3]/10  p-6 gap-6 flex flex-col items-center">
                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-[#2196F3] -mt-14">
                  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 96 96" fill="none">
                    <path d="M14.6442 58.1364H0.0078125V95.9773H14.6442V58.1364Z" fill="white" />
                    <path d="M41.7848 49.8863H27.1484V96H41.7848V49.8863Z" fill="white" />
                    <path d="M68.1012 40.8182H54.4648V95.3637H68.1012V40.8182Z" fill="white" />
                    <path d="M95.3747 22.6363H81.7383V95.3637H95.3747V22.6363Z" fill="white" />
                    <path d="M7.32739 53.7727C5.88801 53.7727 4.48101 53.3456 3.28452 52.5454C2.08804 51.7453 1.15589 50.6081 0.606113 49.2779C0.0563308 47.9476 -0.086361 46.4841 0.196106 45.0728C0.478573 43.6614 1.17349 42.3655 2.19288 41.3493C3.21226 40.3331 4.51027 39.6423 5.92254 39.3642C7.3348 39.0862 8.79781 39.2334 10.1263 39.7874C11.4548 40.3413 12.5891 41.277 13.3855 42.476C14.1819 43.675 14.6046 45.0833 14.6001 46.5227C14.5941 48.4476 13.8252 50.2916 12.462 51.6506C11.0987 53.0096 9.25231 53.7727 7.32739 53.7727ZM7.32739 43.7954C6.78798 43.7954 6.26069 43.9554 5.8122 44.2551C5.3637 44.5547 5.01414 44.9807 4.80772 45.479C4.6013 45.9774 4.54729 46.5257 4.65252 47.0548C4.75775 47.5838 5.0175 48.0698 5.39892 48.4512C5.78033 48.8326 6.26628 49.0923 6.79532 49.1976C7.32436 49.3028 7.87273 49.2488 8.37107 49.0424C8.86942 48.836 9.29536 48.4864 9.59504 48.0379C9.89471 47.5894 10.0547 47.0621 10.0547 46.5227C10.0547 46.1645 9.98413 45.8099 9.84707 45.479C9.71001 45.1481 9.50912 44.8475 9.25587 44.5942C9.00261 44.341 8.70196 44.1401 8.37107 44.003C8.04018 43.866 7.68554 43.7954 7.32739 43.7954Z" fill="white" />
                    <path d="M34.4641 45.5681C33.0247 45.5681 31.6177 45.141 30.4212 44.3408C29.2248 43.5407 28.2926 42.4035 27.7428 41.0733C27.1931 39.743 27.0504 38.2796 27.3328 36.8682C27.6153 35.4568 28.3102 34.1609 29.3296 33.1447C30.349 32.1285 31.647 31.4377 33.0593 31.1596C34.4715 30.8816 35.9345 31.0289 37.2631 31.5828C38.5916 32.1367 39.7258 33.0724 40.5222 34.2714C41.3186 35.4704 41.7413 36.8787 41.7369 38.3181C41.7369 39.2721 41.5486 40.2167 41.1828 41.0978C40.817 41.9789 40.281 42.7792 39.6053 43.4527C38.9297 44.1262 38.1278 44.6598 37.2456 45.0228C36.3633 45.3858 35.4181 45.5711 34.4641 45.5681ZM34.4641 35.5908C33.9247 35.5908 33.3974 35.7508 32.9489 36.0505C32.5004 36.3501 32.1509 36.7761 31.9444 37.2744C31.738 37.7728 31.684 38.3211 31.7892 38.8502C31.8945 39.3792 32.1542 39.8652 32.5356 40.2466C32.9171 40.628 33.403 40.8878 33.932 40.993C34.4611 41.0982 35.0095 41.0442 35.5078 40.8378C36.0061 40.6314 36.4321 40.2818 36.7318 39.8333C37.0314 39.3848 37.1914 38.8575 37.1914 38.3181C37.1914 37.96 37.1208 37.6053 36.9838 37.2744C36.8467 36.9435 36.6458 36.6429 36.3926 36.3896C36.1393 36.1364 35.8387 35.9355 35.5078 35.7984C35.1769 35.6614 34.8223 35.5908 34.4641 35.5908Z" fill="white" />
                    <path d="M61.5774 17.1818C60.139 17.1818 58.7329 17.6084 57.5369 18.4075C56.3409 19.2066 55.4088 20.3425 54.8583 21.6714C54.3078 23.0003 54.1638 24.4626 54.4444 25.8734C54.7251 27.2842 55.4177 28.58 56.4348 29.5971C57.4519 30.6143 58.7478 31.3069 60.1586 31.5875C61.5694 31.8682 63.0317 31.7241 64.3606 31.1737C65.6895 30.6232 66.8254 29.6911 67.6245 28.4951C68.4236 27.2991 68.8502 25.893 68.8502 24.4545C68.8532 23.4986 68.6671 22.5516 68.3027 21.6678C67.9382 20.7841 67.4027 19.9812 66.7267 19.3053C66.0508 18.6293 65.2479 18.0937 64.3641 17.7293C63.4804 17.3649 62.5333 17.1788 61.5774 17.1818ZM61.5774 27.1591C61.0333 27.1586 60.5017 26.9954 60.0511 26.6904C59.6005 26.3854 59.2514 25.9525 59.0487 25.4476C58.8461 24.9426 58.7991 24.3885 58.9139 23.8566C59.0287 23.3247 59.2999 22.8393 59.6928 22.4628C60.0856 22.0863 60.5822 21.8359 61.1185 21.7438C61.6548 21.6518 62.2063 21.7223 62.7023 21.9462C63.1982 22.1702 63.6158 22.5374 63.9013 23.0006C64.1869 23.4638 64.3274 24.0018 64.3047 24.5455C64.281 25.2508 63.9825 25.919 63.473 26.4073C62.9634 26.8956 62.2832 27.1654 61.5774 27.1591Z" fill="white" />
                    <path d="M88.718 14.5455C87.2796 14.5455 85.8735 14.1189 84.6775 13.3198C83.4815 12.5207 82.5494 11.3848 81.9989 10.0559C81.4485 8.72697 81.3044 7.26467 81.5851 5.8539C81.8657 4.44313 82.5583 3.14725 83.5754 2.13014C84.5926 1.11303 85.8884 0.420367 87.2992 0.139747C88.71 -0.140873 90.1723 0.00315116 91.5012 0.553607C92.8301 1.10406 93.966 2.03623 94.7651 3.23222C95.5643 4.42822 95.9908 5.83433 95.9908 7.27274C95.9908 9.20159 95.2246 11.0514 93.8606 12.4153C92.4967 13.7792 90.6469 14.5455 88.718 14.5455ZM88.718 4.54546C88.1786 4.54546 87.6513 4.70541 87.2028 5.00509C86.7544 5.30476 86.4048 5.73071 86.1984 6.22905C85.9919 6.7274 85.9379 7.27576 86.0432 7.8048C86.1484 8.33384 86.4082 8.8198 86.7896 9.20122C87.171 9.58263 87.6569 9.84238 88.186 9.94761C88.715 10.0528 89.2634 9.99884 89.7617 9.79242C90.2601 9.586 90.686 9.23643 90.9857 8.78793C91.2854 8.33943 91.4453 7.81214 91.4453 7.27274C91.4275 6.5613 91.1324 5.885 90.6228 5.38819C90.1133 4.89138 89.4297 4.61342 88.718 4.61365V4.54546Z" fill="white" />
                    <path d="M12.6215 46.7045C12.0992 46.7185 11.5881 46.5521 11.1742 46.2333C10.7602 45.9146 10.4687 45.463 10.3488 44.9545C10.2121 44.3699 10.3122 43.755 10.6273 43.2441C10.9424 42.7331 11.4469 42.3675 12.0306 42.2272L28.8942 38.3181C29.497 38.1825 30.129 38.2919 30.6511 38.6222C31.1732 38.9525 31.5427 39.4767 31.6783 40.0795C31.814 40.6822 31.7046 41.3142 31.3743 41.8363C31.0439 42.3584 30.5197 42.728 29.917 42.8636L13.076 46.7727C12.9225 46.7665 12.7701 46.7437 12.6215 46.7045Z" fill="white" />
                    <path d="M56.735 25.9319C56.3543 25.93 55.9792 26.0237 55.6441 26.2046L39.0532 35.1364C38.5337 35.4245 38.1477 35.9046 37.9778 36.4738C37.8079 37.043 37.8676 37.6561 38.1441 38.1819C38.2849 38.447 38.4768 38.6815 38.7087 38.8721C38.9406 39.0626 39.208 39.2053 39.4953 39.2919C39.7827 39.3786 40.0844 39.4074 40.383 39.3768C40.6816 39.3462 40.9712 39.2568 41.235 39.1137L57.8259 30.1819C58.2604 29.9357 58.6018 29.5533 58.7972 29.0937C58.9926 28.6342 59.0312 28.123 58.9071 27.6393C58.7829 27.1556 58.5029 26.7263 58.1103 26.4177C57.7177 26.109 57.2344 25.9383 56.735 25.9319Z" fill="white" />
                    <path d="M66.015 23.1818C65.5513 23.1507 65.1083 22.9781 64.7457 22.6875C64.3831 22.3968 64.1183 22.0019 63.9871 21.5561C63.8558 21.1103 63.8644 20.635 64.0116 20.1942C64.1588 19.7534 64.4376 19.3683 64.8105 19.0909L82.8105 8.04543C83.0635 7.83303 83.3598 7.67835 83.6787 7.59215C83.9976 7.50596 84.3315 7.49032 84.657 7.54633C84.9826 7.60235 85.2921 7.72866 85.5638 7.91647C85.8356 8.10429 86.0631 8.34909 86.2306 8.63384C86.3981 8.91858 86.5016 9.23642 86.5337 9.5652C86.5658 9.89399 86.5258 10.2258 86.4166 10.5376C86.3074 10.8494 86.1315 11.1336 85.9012 11.3704C85.6709 11.6073 85.3918 11.7911 85.0832 11.9091L67.1968 22.8409C66.8417 23.061 66.4328 23.179 66.015 23.1818Z" fill="white" />
                  </svg>
                </div>
                <div className="text-center space-y-2">
                  <h2 className="text-xl font-semibold">Holistic Insight</h2>
                  <p className="text-center text-lg font-medium">
                    A detailed view of your personality, interests, and values.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" className=" w-full bg-gradient-to-b from-[#2196F3]/10  p-6 gap-6 flex flex-col items-center">
                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-[#2196F3] -mt-14">
                  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 96 96" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M63.2539 26.5175H50.1257C48.9322 26.5175 48.0371 27.4126 48.0371 28.606V42.6294C57.8833 38.1538 57.8833 57.5478 48.0371 53.0722V67.0956C48.0371 68.289 48.9322 69.1841 50.1257 69.1841H63.2539C60.5686 59.338 76.3821 59.338 73.3984 69.1841H86.5266C87.7201 69.1841 88.6152 68.289 88.6152 67.0956V53.3706C98.4613 56.951 98.4613 39.0489 88.6152 42.6294V28.606C88.6152 27.4126 87.7201 26.5175 86.5266 26.5175H73.6968C75.7854 16.9697 61.1653 16.9697 63.2539 26.5175ZM15.2168 69.4825H2.08858C0.895105 69.4825 0 68.5874 0 67.3939V53.3706C9.84615 57.8461 9.84615 38.4522 0 42.9277V28.9044C0 27.7109 0.895105 26.8158 2.08858 26.8158H15.2168C12.5315 36.662 28.345 36.662 25.6597 26.8158H38.7879C39.683 26.8158 40.8765 27.7109 40.8765 28.9044V42.6294C50.4242 39.0489 50.4242 56.951 40.8765 53.3706V67.3939C40.8765 68.5874 39.683 69.4825 38.7879 69.4825H25.6597C27.7483 79.0303 13.1282 79.0303 15.2168 69.4825Z" fill="white" />
                  </svg>
                </div>
                <div className="text-center space-y-2">
                  <h2 className="text-xl font-semibold">Career Matching</h2>
                  <p className="text-center text-lg font-medium">
                    Identification of careers that fit your unique profile.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" className=" w-full bg-gradient-to-b from-[#2196F3]/10  p-6 gap-6 flex flex-col items-center">
                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-[#2196F3] -mt-14">
                  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 96 96" fill="none">
                    <g clip-path="url(#clip0_5939_4650)">
                      <path d="M22.9688 62.9531C22.1875 62.9531 21.4375 62.7969 20.7188 62.4844C20 62.1719 19.3438 61.7344 18.75 61.1719L1.73438 44.1562C0.578125 43 0 41.5938 0 39.9375C0 39.0938 0.15625 38.3125 0.46875 37.5938C0.78125 36.875 1.20312 36.25 1.73438 35.7188C2.26562 35.1875 2.90625 34.7656 3.65625 34.4531C4.40625 34.1406 5.1875 33.9844 6 33.9844C6.8125 33.9844 7.57812 34.125 8.29688 34.4062C9.01562 34.6875 9.67188 35.125 10.2656 35.7188L27.2344 52.6875C27.7969 53.25 28.2188 53.8906 28.5 54.6094C28.7812 55.3281 28.9375 56.1094 28.9688 56.9531C28.9688 57.7656 28.8125 58.5313 28.5 59.25C28.1875 59.9688 27.7656 60.6094 27.2344 61.1719C26.7031 61.7344 26.0625 62.1719 25.3125 62.4844C24.5625 62.7969 23.7812 62.9531 22.9688 62.9531ZM56.9062 29.0156C56.125 29.0156 55.375 28.8594 54.6562 28.5469C53.9375 28.2344 53.2812 27.7969 52.6875 27.2344L35.7188 10.2656C34.5312 9.07812 33.9375 7.65625 33.9375 6C33.9375 5.15625 34.0938 4.375 34.4062 3.65625C34.7188 2.9375 35.1406 2.3125 35.6719 1.78125C36.2031 1.25 36.8438 0.8125 37.5938 0.46875C38.3438 0.125 39.125 -0.03125 39.9375 4.65661e-10C41.5938 4.65661e-10 43 0.578125 44.1562 1.73438L61.1719 18.75C61.7344 19.3125 62.1562 19.9531 62.4375 20.6719C62.7188 21.3906 62.875 22.1719 62.9062 23.0156C62.9062 23.8281 62.75 24.5938 62.4375 25.3125C62.125 26.0312 61.7031 26.6719 61.1719 27.2344C60.6406 27.7969 60 28.2344 59.25 28.5469C58.5 28.8594 57.7188 29.0156 56.9062 29.0156ZM32.5312 47.3438L15.5625 30.375L30.375 15.5625L47.3438 32.5312L32.5312 47.3438ZM90.8438 86.625C91.4375 87.2188 91.7344 87.9219 91.7344 88.7344C91.7344 89.5469 91.4375 90.25 90.8438 90.8438C90.25 91.4375 89.5469 91.7344 88.7344 91.7344C87.9219 91.7344 87.2188 91.4375 86.625 90.8438L42.0469 46.3125L46.3125 42.0469L90.8438 86.625ZM0 90H48V96H0V90ZM6 78H42V84H6V78Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_5939_4650">
                        <rect width="96" height="96" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="text-center space-y-2">
                  <h2 className="text-xl font-semibold">Informed Decision-Making</h2>
                  <p className="text-center text-lg font-medium">
                    The ability to make educated choices about your educational and career paths, leading to greater satisfaction and success.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className="bg-gradient-to-b from-[#2196F3]/10  w-full h-auto bg-center  bg-cover bg-no-repeat py-10 md:py-20">
          <div className=" w-full gap-10 px-4 mx-auto   2xl:w-9/12 xl:w-11/12 xl:px-0  space-y-10  ">
            <div className="space-y-4 text-center ">
              <h1 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[4rem]  capitalize font-bold text-[#212121]">Career Fitment Analysis Flow</h1>
              <p data-aos="fade-up" class="md:text-lg text-base text-[#212121] aos-init aos-animate">Experience a seamless Career Fitment Analysis process, guiding you step-by-step to identify and align your strengths with the perfect career path.</p>
            </div>

            {/* component */}
            <div className=" md:flex flex-col justify-center hidden ">
              <div className="py-3 w-full sm:mx-auto  px-2 sm:px-0">
                <div className="relative text-gray-700 antialiased text-sm font-semibold">
                  {/* Vertical bar running through middle */}
                  <div className="hidden sm:block rounded-full w-2 bg-[#1976D2] absolute h-full  left-1/2  transform -translate-x-1/2" />
                  {/* Right section, set by justify-end and sm:pl-8 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-col sm:flex-row items-center">
                      <div className="flex justify-end w-full mx-auto items-center">
                        <div data-aos="fade-right" className=" sm:w-2/2 sm:pr-8">
                          <img src="./assessment_images/aptitude-test.svg" className="w-36 flex-shrink-0" />
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-left" className="w-full lg:w-9/12 sm:w-12/12 sm:pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>
                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-4 text-[#212121]  ">
                            <h2 className="text-2xl font-semibold">Aptitude Test</h2>
                            <p className="text-base font-normal">Aptitude Test measures a teenager's ability to handle numerical data, perform calculations, reasoning  and understand verbal information. This helps identify strengths in math and language skills, guiding suitable career paths.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Left section, set by justify-start and sm:pr-8 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-col sm:flex-row items-center w-full">
                      <div className="flex justify-end w-full mx-auto items-center">
                        <div data-aos="fade-right" className="w-full lg:w-9/12 sm:w-12/12 sm:pr-8 flex items-center">
                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-right space-y-4 text-[#212121]">
                            <h2 className="text-2xl font-semibold">Personality Test</h2>
                            <p className="text-base font-normal">  The Personality Test assesses a person's character, preferences, behaviors, and emotional responses, helping teens find careers that match their natural traits.</p>
                          </div>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M24 48L0 0L0 96L24 48Z" fill="#2196F3" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-left" className="w-full sm:w-2/2 sm:pl-8">
                          <img src="./assessment_images/personality-test.svg" className="w-36 flex-shrink-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right section, set by justify-end and sm:pl-8 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-col sm:flex-row items-center">
                      <div className="flex justify-end w-full mx-auto items-center">
                        <div data-aos="fade-right" className=" sm:w-2/2 sm:pr-8">
                          <img src="./assessment_images/holland's-interest-test.svg" className="w-36 flex-shrink-0" />
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-left" className="w-full lg:w-9/12 sm:w-12/12 sm:pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>
                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-4 text-[#212121]  ">
                            <h2 className="text-2xl font-semibold">Interest Test</h2>
                            <p className="text-base font-normal">The Interest Test, also known as the Interest or RIASEC Test, categorizes individuals into six types: Realistic, Investigative, Artistic, Social, Enterprising, and Conventional, suggesting careers that align with a teen's interests and preferred activities.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Left section, set by justify-start and sm:pr-8 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-col sm:flex-row items-center w-full">
                      <div className="flex justify-end w-full mx-auto items-center">
                        <div data-aos="fade-right" className="w-full lg:w-9/12 sm:w-12/12 sm:pr-8 flex items-center">
                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-right space-y-4 text-[#212121]">
                            <h2 className="text-2xl font-semibold">Work Values Test
                            </h2>
                            <p className="text-base font-normal"> The Work Values Test identifies what a person values in their work environment, such as job security, work-life balance, achievement, and recognition. It helps teens choose careers that align with their personal values and fulfillment.</p>
                          </div>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M24 48L0 0L0 96L24 48Z" fill="#2196F3" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-left" className="w-full sm:w-2/2 sm:pl-8">
                          <img src="./assessment_images/work-value.svg" className="w-36 flex-shrink-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right section, set by justify-end and sm:pl-8 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-col sm:flex-row items-center">
                      <div className="flex justify-end w-full mx-auto items-center">
                        <div data-aos="fade-right" className=" sm:w-2/2 sm:pr-8">
                          <img src="./assessment_images/fitment-analysis.svg" className="w-36 flex-shrink-0" />
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">

                      </div>
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-left" className="w-full lg:w-9/12 sm:w-12/12 sm:pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>
                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-4 text-[#212121]">
                            <h2 className="text-2xl font-semibold">Fitment Analysis</h2>
                            <p className="text-base font-normal">  Choosing a career can be complex for teens, but understanding your strengths, interests, and values helps. A career fitment analysis using the Aptitude Test, Personality Test, Interest Test, and Work Values Test guides you to a suitable career.</p>
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>
                  {/* Left section, set by justify-start and sm:pr-8 */}
                  <div className="mt-6 sm:mt-0 sm:mb-12 w-full">
                    <div className="flex flex-col sm:flex-row items-center w-full">
                      <div className="flex justify-end w-full mx-auto items-center">
                        <div data-aos="fade-right" className="w-full lg:w-9/12 sm:w-12/12 sm:pr-8 flex items-center">
                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-right space-y-4 text-[#212121]">
                            <h2 className="text-2xl font-semibold">Career Recommendations</h2>
                            <p className="text-base font-normal">Career recommendations based on an overall fitment analysis score, combine insights from the Aptitude Test, Personality Test, Interest Test, and Work Values Test. This integration offers personalized career guidance aligned with a teen's unique strengths, interests, and values.</p>
                          </div>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M24 48L0 0L0 96L24 48Z" fill="#2196F3" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">

                      </div>
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-left" className="w-full sm:w-2/2 sm:pl-8">
                          <img src="./assessment_images/career-recommendations.svg" className="w-36 flex-shrink-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Mobile component */}
            <div className="  flex flex-col justify-center md:hidden  ">
              <div className="py-3 w-full sm:mx-auto  px-2 sm:px-0">
                <div className="relative text-gray-700 antialiased text-sm font-semibold">
                  {/* Vertical bar running through middle */}
                  <div className="rounded-full w-1 bg-[#1976D2] absolute h-full  left-2/2  transform -translate-x-1/2" />
                  {/* 1 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-row items-center w-full">
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-up" className="w-full pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>

                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-3 text-[#212121]">
                            <img src="./assessment_images/aptitude-test.svg" className="w-24 flex-shrink-0" />
                            <h2 className="text-xl font-semibold">Aptitude Test</h2>
                            <p className="text-base font-normal">Aptitude Test measures a teenager's ability to handle numerical data, perform calculations, reasoning and understand verbal information. This helps identify strengths in math and language skills, guiding suitable career paths.</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-2/2  translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>

                    </div>
                  </div>
                  {/* 2 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-row items-center w-full">
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-up" className="w-full pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>

                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-3 text-[#212121]">
                            <img src="./assessment_images/personality-test.svg" className="w-24 flex-shrink-0" />
                            <h2 className="text-xl font-semibold"> Personality Test</h2>
                            <p className="text-base font-normal">The Personality Test assesses a person's character, preferences, behaviors, and emotional responses, helping teens find careers that match their natural traits.</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-2/2  translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>

                    </div>
                  </div>
                  {/* 3 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-row items-center w-full">
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-up" className="w-full pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>

                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-3 text-[#212121]">
                            <img src="./assessment_images/holland's-interest-test.svg" className="w-24 flex-shrink-0" />
                            <h2 className="text-xl font-semibold">Interest Test</h2>
                            <p className="text-base font-normal">The Interest Test, also known as the Interest or RIASEC Test, categorizes individuals into six types: Realistic, Investigative, Artistic, Social, Enterprising, and Conventional, suggesting careers that align with a teen's interests and preferred activities.</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-2/2  translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>

                    </div>
                  </div>

                  {/* 4 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-row items-center w-full">
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-up" className="w-full pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>

                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-3 text-[#212121]">
                            <img src="./assessment_images/work-value.svg" className="w-24 flex-shrink-0" />
                            <h2 className="text-xl font-semibold">Work Values Test</h2>
                            <p className="text-base font-normal">The Work Values Test identifies what a person values in their work environment, such as job security, work-life balance, achievement, and recognition. It helps teens choose careers that align with their personal values and fulfillment.</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-2/2  translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>

                    </div>
                  </div>

                  {/* 5 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-row items-center w-full">
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-up" className="w-full pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>

                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-3 text-[#212121]">
                            <img src="./assessment_images/fitment-analysis.svg" className="w-24 flex-shrink-0" />
                            <h2 className="text-xl font-semibold">Fitment Analysis</h2>
                            <p className="text-base font-normal">Choosing a career can be complex for teens, but understanding your strengths, interests, and values helps. A career fitment analysis using the Aptitude Test, Personality Test, Interest Test, and Work Values Test guides you to a suitable career.</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-2/2  translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>

                    </div>
                  </div>
                
                  {/* 6 */}
                  <div className="mt-6 sm:mt-0 sm:mb-20 w-full">
                    <div className="flex flex-row items-center w-full">
                      <div className="flex justify-start w-full mx-auto items-center">
                        <div data-aos="fade-up" className="w-full pl-8 flex items-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="70" viewBox="0 0 24 96" fill="none">
                              <path d="M0 48L24 0L24 96L0 48Z" fill="#2196F3" />
                            </svg>
                          </div>

                          <div className="p-6 bg-white rounded-2xl border border-[#2196F3]  text-left space-y-3 text-[#212121]">
                            <img src="./assessment_images/career-recommendations.svg" className="w-24 flex-shrink-0" />
                            <h2 className="text-xl font-semibold">Career Recommendations</h2>
                            <p className="text-base font-normal">Career recommendations based on an overall fitment analysis score, combine insights from the Aptitude Test, Personality Test, Interest Test, and Work Values Test. This integration offers personalized career guidance aligned with a teen's unique strengths, interests, and values.</p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-full bg-[#1976D2] border-white border-4 w-8 h-8 absolute left-2/2  translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                      </div>

                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div>
        </section>






        {/* Contact Us */}
        <section className="py-20 bg-[#F9F9FC] space-y-10" >
          {/* <h2 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center aos-init aos-animate capitalize">Get in Touch</h2> */}
          <div className="grid w-full grid-cols-1 gap-8 px-4 mx-auto text-gray-100 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:grid-cols-2">

            <div data-aos="fade-right" className="space-y-4 ">
              <div data-aos="fade-up" className="space-y-2 text-[#212121]">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#212121]  capitalize">
                  Get in touch
                </h2>
                <p className="lg:text-lg text-base text-[#212121]">
                  For customer support and query, get in touch with us.
                </p>
              </div>

              <div className="space-y-0 ">
                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 border-b">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#212121] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-mail"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="3" y="5" width="18" height="14" rx="2" />
                      <polyline points="3 7 12 13 21 7" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Email
                    </h1>
                    <p className="text-base ">connect@happyplus.in</p>
                  </div>
                </div>

                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 ">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#3D405B] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="3" y1="21" x2="21" y2="21" />
                      <line x1="9" y1="8" x2="10" y2="8" />
                      <line x1="9" y1="12" x2="10" y2="12" />
                      <line x1="9" y1="16" x2="10" y2="16" />
                      <line x1="14" y1="8" x2="15" y2="8" />
                      <line x1="14" y1="12" x2="15" y2="12" />
                      <line x1="14" y1="16" x2="15" y2="16" />
                      <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#3D405B] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Office Address:
                    </h1>
                    <p className="text-base ">
                      HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai
                      400065
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <img
                  data-aos="fade-right"
                  src="/img/domaim/contact-us.svg"
                  alt="contact-us"
                  className="flex items-center justify-center p-6 w-6/6 "
                  width={490}
                  height={360}
                />
              </div>
            </div>

            <div data-aos="fade-left" className="space-y-6 ">
              <div data-aos="fade-up" className="space-y-2 ">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                  Submit Details
                </h2>
                <p className="lg:text-lg text-base text-[#3D405B]">
                  Fill in your details below & we will get back to you soon!
                </p>
              </div>

              <div noValidate action className="grid grid-cols-1 gap-6 pt-2">
                <div className="space-y-2 ">
                  <label
                    htmlFor="name"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678] capitalize"
                  >
                    Full name
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["userName"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="userName"
                    name="userName"
                    placeholder="Enter Name"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["userName"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["userName"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="email"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678]"
                  >
                    Email
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["email"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["email"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["email"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="phone"
                    className="text-base font-medium text-[#212121]"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md"
                    id="phone"
                    name="phone"
                    placeholder="+91 "
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="message"
                    className="text-base font-medium text-[#212121]"
                  >
                    {" "}
                    Message (If Any)
                  </label>
                  <textarea
                    rows={3}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md resize-none"
                    id="message"
                    name="message"
                    placeholder=""
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <button
                  type="button"
                  className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150"
                  onClick={this.loginSubmit}
                >
                  {" "}
                  Send Message
                </button>

                {this.state.saveSuccess ? (
                  <button
                    type="button"
                    className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <span>Your query has been successfully sent!</span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
TeenCareerFitment.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(TeenCareerFitment);
