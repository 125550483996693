import { CONST } from '../_config';
import { authHeader } from '../_helpers';
import { authHeaderUser } from '../_helpers';

import CryptoJS from 'crypto-js';

export const questionsService = {
    logout,
    saveAnswers,
    getQuestionList,
    getLanguageLabel,
    getSurveyTemplateByCode,
    getEmployeeStatus,
    getSurveyDetailsBySurveyCode,
    getRespondantName,

    getAssessmentSurveyTemplateByCode,
    getAssessmenrEmployeeDetails,
    getAssessmentQuestionList,
    saveAssessmentAnswers,
    assessmentLogout,
    surveyLogout,

    getAssessmentReportAdmin,
    getAssessmentReportRTL,
    getAssessmentReport,
    getAssessmentStatus,
    getTranslateElementAll,
    getAssessmenrEmployeeProduct,
    updateAssessmentUserDetails,
    getSurveyTakenDate,
    getSurveyTakenDateAdmin
};
function logout() {
    localStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    // window.location.reload();

}
function assessmentLogout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/assessment/sign-in";
    window.location.reload();
}

function surveyLogout() {
    localStorage.removeItem('surveyuser');
    window.location.href = "#/assessment/surveylogin";
    window.location.reload();
}


function handleResponseAdmin(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                console.log("(((((((((((-----------//////////assessmentLogout///---------------)))))))))))))")
                assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                console.log("(((((((((((-----------//////////assessmentLogout///---------------)))))))))))))")
                assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}





function handleResponseSurvey(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                surveyLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                surveyLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}




function getQuestionList(data, Template) {
    let header = new Headers({
        'Content-Type': 'application/json'
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }


    if (Template === "v4") {
        return fetch(CONST.BACKEND_URL + `/getQuestionList`, requestOptions)
            .then(handleResponseAdmin)
            .then(data => {

                let SortedQuestionList = []
                let GroupQuestionOnlyList = []

                if (data.data.questionList && data.data.questionList.length > 0) {
                    let DataQuestionList = data.data.questionList;
                    console.log('data.data', data.data)

                    if (data && data.data && data.data.survey && data.data.survey.isRandomize) {
                        console.log('isRandomize!')



                        let ShuffledQuestionList = shuffle(DataQuestionList);
                        if (ShuffledQuestionList && ShuffledQuestionList.length > 0) {
                            ShuffledQuestionList.forEach((element, indexS) => {
                                element["rank"] = indexS;
                                SortedQuestionList.push(element)
                            })
                        }



                    } else {
                        SortedQuestionList = DataQuestionList.sort((a, b) => a.rank - b.rank);
                    }
                    // console.log('DataQuestionList : ',DataQuestionList)
                    // console.log('SortedQuestionList : ',SortedQuestionList)

                    GroupQuestionOnlyList = DataQuestionList.filter(prev => prev.parentId === null);

                }

                var QuesAns = [];
                // console.log("data data: ",data)

                let QuestionMain = [];
                let MatrixQuestion = [];
                let GroupQuestion = [];

                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.forEach((ques) => {
                        if (ques.templateId === 13) {
                            if (ques.parentId) {
                                MatrixQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push({
                                    ...ques,
                                    SubQuestionList: []
                                });
                            }
                        }
                        else if (ques.templateId === 14) {

                            // QuestionMain.push({
                            //     ...ques,
                            //     SubQuestionList:[]
                            // });

                            QuestionMain.push(ques);

                        }
                        else if (ques.templateId !== 13 && ques.templateId !== 14) {


                            if (ques.parentId) {
                                GroupQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push(ques);
                            }
                        }
                        else {


                            QuestionMain.push(ques);
                        }

                    });
                }



                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.map((quesData, quesDataIndex) => {


                        if (quesData.templateId === 2) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                multiAnswer: [],
                                milliseconds: 0,
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }
                        else if (quesData.templateId === 4) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                answer1: "",
                                answer2: "",
                                milliseconds: 0,
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else if (quesData.templateId === 13) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                milliseconds: 0,
                                multiAnswer: [],
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else {
                            return QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                feedback: "",
                                milliseconds: 0,
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                questionTemplateId: quesData.templateId,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            })
                        }

                    })
                }



                if (MatrixQuestion && MatrixQuestion.length > 0) {
                    MatrixQuestion.forEach((ques) => {
                        let getIndex = QuestionMain.findIndex(prev => prev.id === ques.parentId);
                        if (getIndex !== -1) {
                            QuestionMain[getIndex]["SubQuestionList"].push(ques);
                        }

                    });
                }

                let QuestionMainFinal = [];

                //console.log("x Template2===========>",Template,GroupQuestion)

                if (Template === "v4") {
                    if (QuestionMain && QuestionMain.length > 0) {
                        QuestionMain.forEach((Mainques) => {

                            if (Mainques.templateId !== 14) {
                                QuestionMainFinal.push(Mainques);
                            }

                            // console.log("x Mainques===========>",Mainques)
                            //console.log("x GroupQuestion===========>",GroupQuestion)

                            if (GroupQuestion && GroupQuestion.length > 0) {
                                GroupQuestion.forEach((ques) => {
                                    if (ques.parentId === Mainques.id) {
                                        let tempData = {
                                            ...ques,
                                            QuesMain: Mainques
                                        }
                                        QuestionMainFinal.push(tempData);
                                    }
                                });
                            }

                        });
                    }
                }
                else {
                    // if(GroupQuestion && GroupQuestion.length>0){
                    //     GroupQuestion.forEach((ques)=>{
                    //         let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    //         if(getIndex!==-1){
                    //             QuestionMain[getIndex]["SubQuestionList"].push(ques);
                    //         }
                    //     });
                    // }
                    // QuestionMainFinal = QuestionMain;
                }




                // console.log('{{{{{{{----------------> QuestionMainFinal',QuestionMainFinal)

                // let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(question), "secretkey1345100237401").toString();
                // localStorage.setItem('tempQuestion', JSON.stringify(cryptedText));


                // let storedAnswers = JSON.parse(localStorage.getItem('myAnswers'));


                let storedAnswers = "";

                try {

                    let localData = localStorage.getItem('myAnswers');
                    let cryptedText = localData ? JSON.parse(localData) : "";
                    let bytes = cryptedText ? CryptoJS.AES.decrypt(cryptedText, "secretkey1345100237401") : "";

                    storedAnswers = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : "";
                } catch (error) {
                    storedAnswers = ""
                }




                // console.log('----------{{{{{{{----------------> storedAnswers',storedAnswers)
                //console.log('----------{{{{{{{----------------> QuesAns',QuesAns)

                let NewAnswerList = []
                if (storedAnswers && storedAnswers.length > 0 && QuesAns && QuesAns.length > 0 && (storedAnswers.length === QuesAns.length)) {
                    storedAnswers.forEach((item) => {

                        let tempData = {
                            ...item
                        }
                        let getIndex = QuesAns.findIndex(prev => prev.questionId === item.questionId);
                        if (getIndex !== -1) {


                            // if(item.template===13){
                            //     tempData['multiAnswer'] =  QuesAns && QuesAns[getIndex] && QuesAns[getIndex].multiAnswer && QuesAns[getIndex].multiAnswer.length>0?QuesAns[getIndex].multiAnswer:[];
                            // }


                            tempData['isMandatory'] = QuesAns && QuesAns[getIndex] && QuesAns[getIndex].isMandatory ? true : false;
                        }
                        NewAnswerList.push(tempData);
                    });
                }
                else {
                    NewAnswerList = QuesAns
                }

                //console.log('----------{{{{{{{----------------> QuesAns',QuesAns)

                //console.log('----------{{{{{{{----------------> GroupQuestionOnlyList',GroupQuestionOnlyList)

                let questionObj = {
                    questionsList: QuestionMainFinal,
                    answersList: NewAnswerList,
                    surveyScreenData: data.data.survey,
                    surveyStatus: data.data.status,
                    langList: data.data.langList,
                    GroupQuestionOnlyList: GroupQuestionOnlyList
                }
                // console.log("questionObj  ", questionObj);

                return questionObj;
            });
    }
    else {
        return fetch(CONST.BACKEND_URL + `/getQuestionList`, requestOptions)
            .then(handleResponseAdmin)
            .then(data => {

                let SortedQuestionList = []
                if (data.data.questionList && data.data.questionList.length > 0) {
                    SortedQuestionList = data.data.questionList.sort((a, b) => a.rank - b.rank);
                    // console.log('questionsList V : ',SortedQuestionList)
                }

                var QuesAns = [];
                // console.log("data data: ",data)

                let QuestionMain = [];
                let MatrixQuestion = [];
                let GroupQuestion = [];

                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.forEach((ques) => {
                        if (ques.templateId === 13) {
                            if (ques.parentId) {
                                MatrixQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push({
                                    ...ques,
                                    SubQuestionList: []
                                });
                            }
                        }
                        else if (ques.templateId === 14) {

                            QuestionMain.push({
                                ...ques,
                                SubQuestionList: []
                            });
                        }
                        else if (ques.templateId !== 13 && ques.templateId !== 14) {


                            if (ques.parentId) {
                                GroupQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push(ques);
                            }
                        }
                        else {


                            QuestionMain.push(ques);
                        }

                    });
                }



                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.map((quesData, quesDataIndex) => {


                        if (quesData.templateId === 2) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                multiAnswer: [],
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }
                        else if (quesData.templateId === 4) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                answer1: "",
                                answer2: "",
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else if (quesData.templateId === 13) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                multiAnswer: [],
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else {
                            return QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId


                            })
                        }

                    })
                }



                if (MatrixQuestion && MatrixQuestion.length > 0) {
                    MatrixQuestion.forEach((ques) => {
                        let getIndex = QuestionMain.findIndex(prev => prev.id === ques.parentId);
                        if (getIndex !== -1) {
                            QuestionMain[getIndex]["SubQuestionList"].push(ques);
                        }

                    });
                }


                if (GroupQuestion && GroupQuestion.length > 0) {
                    GroupQuestion.forEach((ques) => {
                        let getIndex = QuestionMain.findIndex(prev => prev.id === ques.parentId);
                        if (getIndex !== -1) {
                            QuestionMain[getIndex]["SubQuestionList"].push(ques);
                        }
                    });
                }



                //console.log('{{{{{{{----------------> QuestionMain',QuestionMain)



                let questionObj = {
                    questionsList: QuestionMain,
                    answersList: QuesAns,
                    surveyScreenData: data.data.survey,
                    surveyStatus: data.data.status,
                    langList: data.data.langList
                }
                // console.log("questionObj  ", questionObj);

                return questionObj;
            });
    }


}


function getAssessmentQuestionList(data, Template) {
    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }


    if (Template === "v4") {
        return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentQuestionList`, requestOptions)
            .then(handleResponseSurvey)
            .then(data => {

                let SortedQuestionList = []
                let GroupQuestionOnlyList = []

                if (data.data.questionList && data.data.questionList.length > 0) {
                    let DataQuestionList = data.data.questionList;
                    console.log('data.data', data.data)

                    if (data && data.data && data.data.survey && data.data.survey.isRandomize) {
                        console.log('isRandomize!')



                        let ShuffledQuestionList = shuffle(DataQuestionList);
                        if (ShuffledQuestionList && ShuffledQuestionList.length > 0) {
                            ShuffledQuestionList.forEach((element, indexS) => {
                                element["rank"] = indexS;
                                SortedQuestionList.push(element)
                            })
                        }



                    } else {
                        SortedQuestionList = DataQuestionList.sort((a, b) => a.rank - b.rank);
                    }
                    // console.log('DataQuestionList : ',DataQuestionList)
                    // console.log('SortedQuestionList : ',SortedQuestionList)

                    GroupQuestionOnlyList = DataQuestionList.filter(prev => prev.parentId === null);

                }

                var QuesAns = [];
                // console.log("data data: ",data)

                let QuestionMain = [];
                let MatrixQuestion = [];
                let GroupQuestion = [];

                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.forEach((ques) => {
                        if (ques.templateId === 13) {
                            if (ques.parentId) {
                                MatrixQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push({
                                    ...ques,
                                    SubQuestionList: []
                                });
                            }
                        }
                        else if (ques.templateId === 14) {

                            // QuestionMain.push({
                            //     ...ques,
                            //     SubQuestionList:[]
                            // });

                            QuestionMain.push(ques);

                        }
                        else if (ques.templateId !== 13 && ques.templateId !== 14) {


                            if (ques.parentId) {
                                GroupQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push(ques);
                            }
                        }
                        else {


                            QuestionMain.push(ques);
                        }

                    });
                }



                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.map((quesData, quesDataIndex) => {


                        if (quesData.templateId === 2) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                multiAnswer: [],
                                milliseconds: 0,
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }
                        else if (quesData.templateId === 4) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                answer1: "",
                                answer2: "",
                                milliseconds: 0,
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else if (quesData.templateId === 13) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                milliseconds: 0,
                                multiAnswer: [],
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else {
                            return QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                feedback: "",
                                milliseconds: 0,
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                questionTemplateId: quesData.templateId,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            })
                        }

                    })
                }



                if (MatrixQuestion && MatrixQuestion.length > 0) {
                    MatrixQuestion.forEach((ques) => {
                        let getIndex = QuestionMain.findIndex(prev => prev.id === ques.parentId);
                        if (getIndex !== -1) {
                            QuestionMain[getIndex]["SubQuestionList"].push(ques);
                        }

                    });
                }

                let QuestionMainFinal = [];

                //console.log("x Template2===========>",Template,GroupQuestion)

                if (Template === "v4") {
                    if (QuestionMain && QuestionMain.length > 0) {
                        QuestionMain.forEach((Mainques) => {

                            if (Mainques.templateId !== 14) {
                                QuestionMainFinal.push(Mainques);
                            }

                            // console.log("x Mainques===========>",Mainques)
                            //console.log("x GroupQuestion===========>",GroupQuestion)

                            if (GroupQuestion && GroupQuestion.length > 0) {
                                GroupQuestion.forEach((ques) => {
                                    if (ques.parentId === Mainques.id) {
                                        let tempData = {
                                            ...ques,
                                            QuesMain: Mainques
                                        }
                                        QuestionMainFinal.push(tempData);
                                    }
                                });
                            }

                        });
                    }
                }
                else {
                    // if(GroupQuestion && GroupQuestion.length>0){
                    //     GroupQuestion.forEach((ques)=>{
                    //         let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    //         if(getIndex!==-1){
                    //             QuestionMain[getIndex]["SubQuestionList"].push(ques);
                    //         }
                    //     });
                    // }
                    // QuestionMainFinal = QuestionMain;
                }




                // console.log('{{{{{{{----------------> QuestionMainFinal',QuestionMainFinal)

                // let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(question), "secretkey1345100237401").toString();
                // localStorage.setItem('tempQuestion', JSON.stringify(cryptedText));


                // let storedAnswers = JSON.parse(localStorage.getItem('myAnswers'));


                let storedAnswers = "";

                try {

                    let localData = localStorage.getItem('myAnswers');
                    let cryptedText = localData ? JSON.parse(localData) : "";
                    let bytes = cryptedText ? CryptoJS.AES.decrypt(cryptedText, "secretkey1345100237401") : "";

                    storedAnswers = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : "";
                } catch (error) {
                    storedAnswers = ""
                }




                // console.log('----------{{{{{{{----------------> storedAnswers',storedAnswers)
                //console.log('----------{{{{{{{----------------> QuesAns',QuesAns)

                let NewAnswerList = []
                if (storedAnswers && storedAnswers.length > 0 && QuesAns && QuesAns.length > 0 && (storedAnswers.length === QuesAns.length)) {
                    storedAnswers.forEach((item) => {

                        let tempData = {
                            ...item
                        }
                        let getIndex = QuesAns.findIndex(prev => prev.questionId === item.questionId);
                        if (getIndex !== -1) {


                            // if(item.template===13){
                            //     tempData['multiAnswer'] =  QuesAns && QuesAns[getIndex] && QuesAns[getIndex].multiAnswer && QuesAns[getIndex].multiAnswer.length>0?QuesAns[getIndex].multiAnswer:[];
                            // }


                            tempData['isMandatory'] = QuesAns && QuesAns[getIndex] && QuesAns[getIndex].isMandatory ? true : false;
                        }
                        NewAnswerList.push(tempData);
                    });
                }
                else {
                    NewAnswerList = QuesAns
                }

                //console.log('----------{{{{{{{----------------> QuesAns',QuesAns)

                //console.log('----------{{{{{{{----------------> GroupQuestionOnlyList',GroupQuestionOnlyList)

                let questionObj = {
                    questionsList: QuestionMainFinal,
                    answersList: NewAnswerList,
                    surveyScreenData: data.data.survey,
                    surveyStatus: data.data.status,
                    langList: data.data.langList,
                    GroupQuestionOnlyList: GroupQuestionOnlyList
                }
                // console.log("questionObj  ", questionObj);

                return questionObj;
            });
    }
    else {
        return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentQuestionList`, requestOptions)
            .then(handleResponseSurvey)
            .then(data => {

                let SortedQuestionList = []
                if (data.data.questionList && data.data.questionList.length > 0) {
                    SortedQuestionList = data.data.questionList.sort((a, b) => a.rank - b.rank);
                    // console.log('questionsList V : ',SortedQuestionList)
                }

                var QuesAns = [];
                // console.log("data data: ",data)

                let QuestionMain = [];
                let MatrixQuestion = [];
                let GroupQuestion = [];

                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.forEach((ques) => {
                        if (ques.templateId === 13) {
                            if (ques.parentId) {
                                MatrixQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push({
                                    ...ques,
                                    SubQuestionList: []
                                });
                            }
                        }
                        else if (ques.templateId === 14) {

                            QuestionMain.push({
                                ...ques,
                                SubQuestionList: []
                            });
                        }
                        else if (ques.templateId !== 13 && ques.templateId !== 14) {


                            if (ques.parentId) {
                                GroupQuestion.push(ques);
                            }
                            else {
                                QuestionMain.push(ques);
                            }
                        }
                        else {


                            QuestionMain.push(ques);
                        }

                    });
                }



                if (SortedQuestionList && SortedQuestionList.length > 0) {
                    SortedQuestionList.map((quesData, quesDataIndex) => {


                        if (quesData.templateId === 2) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                multiAnswer: [],
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }
                        else if (quesData.templateId === 4) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                answer1: "",
                                answer2: "",
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else if (quesData.templateId === 13) {
                            QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                multiAnswer: [],
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId

                            });
                            return QuesAns;
                        }

                        else {
                            return QuesAns.push({
                                questionId: quesData.id,
                                answer: "",
                                questionOptionId: "",
                                isMandatory: quesData.isMandatory,
                                parentId: quesData.parentId,
                                template: quesData.templateId


                            })
                        }

                    })
                }



                if (MatrixQuestion && MatrixQuestion.length > 0) {
                    MatrixQuestion.forEach((ques) => {
                        let getIndex = QuestionMain.findIndex(prev => prev.id === ques.parentId);
                        if (getIndex !== -1) {
                            QuestionMain[getIndex]["SubQuestionList"].push(ques);
                        }

                    });
                }


                if (GroupQuestion && GroupQuestion.length > 0) {
                    GroupQuestion.forEach((ques) => {
                        let getIndex = QuestionMain.findIndex(prev => prev.id === ques.parentId);
                        if (getIndex !== -1) {
                            QuestionMain[getIndex]["SubQuestionList"].push(ques);
                        }
                    });
                }



                //console.log('{{{{{{{----------------> QuestionMain',QuestionMain)



                let questionObj = {
                    questionsList: QuestionMain,
                    answersList: QuesAns,
                    surveyScreenData: data.data.survey,
                    surveyStatus: data.data.status,
                    langList: data.data.langList
                }
                // console.log("questionObj  ", questionObj);

                return questionObj;
            });
    }


}

function saveAnswers(data) {

    // console.log("---------- SAVE ANSWERS ----------------")
    // console.log(data)

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveAnswers`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            localStorage.removeItem('myAnswers');
            // return data.data;
            let questionObj = {}
            return questionObj


        });
}




function getAssessmenrEmployeeDetails(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmenrEmployeeDetails`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getAssessmenrEmployeeDetailsData: data.data
            }
            return questionObj


        });
}


function updateAssessmentUserDetails(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/updateAssessmentUserDetails`, requestOptions)
        .then(handleResponseSurvey)
        .then(assessment => {
            let questionObj = {
                updateAssessmentUserDetailsData: assessment.data
            }
            return questionObj;
        });
}




function saveAssessmentAnswers(data) {

    // console.log("---------- SAVE ANSWERS ----------------")
    // console.log(data)

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/saveAssessmentAnswers`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            localStorage.removeItem('myAnswers');
            // return data.data;
            let questionObj = {}
            return questionObj


        });
}

function getLanguageLabel(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLanguageLabel`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getLanguageLabelData: data.data
            }
            return questionObj;
        });
}


function getSurveyTemplateByCode(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSurveyTemplateByCode`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getSurveyTemplateByCodeData: data.data
            }
            return questionObj;
        });
}

function getAssessmentSurveyTemplateByCode(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentSurveyTemplateByCode`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getSurveyTemplateByCodeData: data.data
            }
            return questionObj;
        });
}


function getSurveyDetailsBySurveyCode(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSurveyDetailsBySurveyCode`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getSurveyDetailsBySurveyCodeData: data.data
            }
            return questionObj;
        });
}



function getEmployeeStatus(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEmpSurveyCode`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getEmployeeStatusData: data.data
            }
            return questionObj;
        });
}



function getRespondantName(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getRespondantName`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getRespondantNameData: data.data
            }
            return questionObj;
        });
}


function getAssessmentReportAdmin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentReportAdmin`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getAssessmentReportData: data.data
            }
            return questionObj;
        });
}



async function getAssessmentReportRTL(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    const response = fetch(CONST.BACKEND_URL + `/emprole/getAssessmentReportRTL`, requestOptions);

    if (!response.ok) {
        throw new Error("Failed to fetch PDF");
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Assessment.pdf";
    link.click();

}



function getAssessmentReport(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentReport`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getAssessmentReportData: data.data
            }
            return questionObj;
        });
}

function getAssessmentStatus(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentStatus`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getAssessmentStatusData: data.data
            }
            return questionObj;
        });
}


function getTranslateElementAll(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTranslateElementAll`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getTranslateElementAllData: data.data
            }
            return questionObj;
        });
}



function getAssessmenrEmployeeProduct(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmenrEmployeeProduct`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getAssessmenrEmployeeProductData: data.data
            }
            return questionObj;
        });
}

function getSurveyTakenDate(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getSurveyTakenDate`, requestOptions)
        .then(handleResponseSurvey)
        .then(data => {
            let questionObj = {
                getSurveyTakenDateData: data.data
            }
            return questionObj;
        });
}

function getSurveyTakenDateAdmin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getSurveyTakenDateAdmin`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getSurveyTakenDateData: data.data
            }
            return questionObj;
        });
}