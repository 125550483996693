import React from "react";
export default function OptionType(props) {
  let { closeModal, handleOptionSubmit, formData, handleOptionInput } = props;

  console.log('formData____???????', formData['licensee']);

  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full" >
        <div className="absolute left-0 right-0 max-w-xl p-4 mx-auto mt-24 overflow-hidden md:relative">
          <div onClick={() => closeModal()} className="absolute top-0 right-0 inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-white rounded-full shadow cursor-pointer hover:text-gray-800" >
            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>



          <div className="block w-full overflow-hidden bg-white rounded-lg shadow">
            <div className="p-12">
              <div className="flex items-center justify-center px-2 cursor-default">
                <div className="pb-4 text-3xl font-medium text-center text-gray-700">I'm Interested For</div>
              </div>
              <div className="items-center justify-between mt-2 md:flex">
                <div className="items-center w-full space-y-4 overflow-x-auto whitespace-nowrap">


                  <div
                    onClick={() => handleOptionInput('self')}
                    className={
                      formData['licensee'] === 'self'
                        ? "cursor-pointer py-6 px-6 bg-[#2196F312] text-slate-600 border border-[#2196F3] m-2 rounded-md"
                        : "cursor-pointer py-6 px-6 bg-[#fff] text-slate-600 border m-2 rounded-md"
                    }
                  >
                    <div className="flex justify-between">
                      <div>
                        <div className={"flex w-full text-xl font-semibold" + (formData['licensee'] === 'self' ? "text-[#118bee] text-xl font-semibold" : "")}>
                          <>
                            {
                              formData['licensee'] === 'self' ?
                                <>
                                  <img src="img/domaim/modeliocn.svg" alt="modeliocn" className="w-12 h-12 pr-4" />
                                  <span className="pt-3 text-[#118bee] text-xl font-semibold">{"For Myself"}</span>
                                </>
                                :
                                <>
                                  <img src="img/domaim/modeliocnactive.svg" alt="modeliocn" className="w-12 h-12 pr-4" />
                                  <span className="pt-3">{"For Myself"}</span>
                                </>
                            }
                          </>
                        </div>
                        <div className="text-base">{"I am taking assessment for myself"}</div>
                      </div>
                      <>
                        {
                          formData['licensee'] === 'self' ?
                            <>
                              <div className="flex items-center justify-center">
                                <img src="img/domaim/ok.svg" alt="modeliocn" className="w-12 h-12 pr-4" />
                              </div>
                            </>
                            :
                            null
                        }
                      </>
                    </div>
                  </div>


                  <div
                    onClick={() => handleOptionInput('team')}
                    className={
                      formData['licensee'] === 'team'
                        ? "cursor-pointer py-6 px-6 bg-[#2196F312] text-slate-600 border border-[#2196F3] m-2 rounded-md"
                        : "cursor-pointer py-6 px-6 bg-[#fff] text-slate-600 border m-2 rounded-md"
                    }
                  >
                    <div className="flex justify-between">
                      <div>
                        <div className={"flex w-full text-xl font-semibold" + (formData['licensee'] === 'team' ? "text-[#118bee] text-xl font-semibold" : "")}>
                          <>
                            {
                              formData['licensee'] === 'team' ?
                                <>
                                  <img src="img/domaim/Group.svg" alt="modeliocn" className="w-12 h-12 pr-4" />
                                  <span className="pt-3 text-[#118bee] text-xl font-semibold">{"For My Team"}</span>
                                </>
                                :
                                <>
                                  <img src="img/domaim/Groupactive.svg" alt="modeliocn" className="w-12 h-12 pr-4" />
                                  <span className="pt-3">{"For My Team"}</span>
                                </>
                            }
                          </>
                        </div>
                        <div lassName="text-base">{"I am taking assessment for my team"}</div>
                      </div>
                      <>
                        {
                          formData['licensee'] === 'team' ?
                            <>
                              <div className="flex items-center justify-center">
                                <img src="img/domaim/ok.svg" alt="modeliocn" className="w-12 h-12 pr-4" />
                              </div>
                            </>
                            :
                            null
                        }
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center pt-12">
                <button onClick={() => handleOptionSubmit()} className="cursor-pointer bg-[#2196f3] text-lg font-semibold uppercase rounded-md text-white py-2 px-4 mx-2 w-full">Continue</button>
              </div>
            </div>
          </div>



        </div>
      </div>
    </>
  );
}
