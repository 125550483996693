import React from 'react';
// import './styles.css';

export default function Component(props) {

  const { question, answersList, nextQues, prevQues, surveyLanguageCode, handleRadio, clicked, surveyScreenData } = props;


  function isActive(option) {
    return ((answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id))
  }


  console.log("question--->", question)
  return (
    <>
      {/* <div className="grid grid-cols-1 gap-3">
        {question && question.optionsList && question.optionsList.length > 0 ?
          question.optionsList.map((option, optionindex) =>
            <>
              <div key={optionindex} onClick={() => handleRadio(optionindex, question, option)}

                className={(((answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id)) ? "border border-[#2196F3] bg-[#2196F3]/10 text-[#2196F3]" : "border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 text-[#212121] hover:text-[#2196F3]") + "   p-4 py-2 rounded-md flex justify-between items-center cursor-pointer  transition-all duration-100 relative"}>
                <span className="font-medium">{(surveyLanguageCode !== '') ?
                  (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ? question.transl[surveyLanguageCode].optionsList[option.id] : option.name : option.name}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" className="opacity-0 pointer-events-none svgone icon icon-tabler icons-tabler-outline icon-tabler-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
              </div>


            </>
          ) : null}
      </div> */}


      <div>
        <div className='space-y-6 '>
          {question && question.img ?
            <div className="grid md:grid-cols-3 grid-cols-1 gap-4   ">
              <div className='border border-[#212121]/70 bg-white'>
                <img src={question.img} className='md:w-full w-3/6 mx-auto' />
              </div>
            </div>
            : null}

          <div className="grid md:grid-cols-4 grid-cols-4 md:gap-4 gap-2   ">

            {question && question.optionsList && question.optionsList.length > 0 ?
              question.optionsList.map((option, optionindex) =>
                <>


                  {option && option.img ?
                    <>
                      {isActive(option) ?
                        <div onClick={() => handleRadio(optionindex, question, option)} className=" bg-[#2196f3]/10 p-1.5 space-y-2 cursor-pointer">
                          <div className=' flex items-center justify-between '>
                            <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm px-2'>{option.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                          </div>
                          <img src={option.img} className='border border-[#212121]/50' />

                        </div>
                        :
                        <div onClick={() => handleRadio(optionindex, question, option)} className=" bg-[#212121]/10 p-1.5 space-y-2 cursor-pointer">
                          <div className='flex items-center justify-between '>
                            <span className='md:size-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm px-2'>{option.name}</span>
                          </div>
                          <img src={option.img} className='border border-[#212121]/50' />
                        </div>
                      }
                    </>
                    :

                    <>


                      <div key={optionindex} onClick={() => handleRadio(optionindex, question, option)}

                        className={(isActive(option) ? "border border-[#2196F3] bg-[#2196F3]/10 text-[#2196F3]" : "border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 text-[#212121] hover:text-[#2196F3]") + "   p-4 py-2 rounded-md flex justify-between items-center cursor-pointer  transition-all duration-100 relative"}>
                        <span className="font-medium">{(surveyLanguageCode !== '') ?
                          (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ? question.transl[surveyLanguageCode].optionsList[option.id] : option.name : option.name}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" className="opacity-0 pointer-events-none svgone icon icon-tabler icons-tabler-outline icon-tabler-check">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 12l5 5l10 -10" />
                        </svg>
                      </div>


                    </>}





                </>
              ) : null}


            {/* <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-3d-shapes-sides.webp' className='border border-[#212121]/50' />
            </div>

            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/02-3d-shapes-sides.webp' className='border border-[#212121]/50' />
            </div>

            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/03-3d-shapes-sides.webp' className='border border-[#212121]/50' />
            </div>

            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/04-3d-shapes-sides.webp' className='border border-[#212121]/50' />
            </div> */}

          </div>
        </div>

        {/* 
        <div className='space-y-6 '>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4   ">
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-unfolded-cube-lg.webp' className='md:w-full w-3/6 mx-auto' />
            </div>

          </div>
          <div className="grid md:grid-cols-4 grid-cols-4 md:gap-4 gap-2   ">
            <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-unfolded-cube.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/01-unfolded-cube.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/03-unfolded-cube.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/04-unfolded-cube.webp' className='border border-[#212121]/50' />
            </div>
          </div>
        </div>

        <div className='space-y-6 '>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4   ">
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-square-lg.webp' className='md:w-full w-3/6 mx-auto' />
            </div>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-4 md:gap-4 gap-2   ">
            <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-square.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/02-square.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/03-square.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/04-square.webp' className='border border-[#212121]/50' />
            </div>
          </div>
        </div>

        <div className='space-y-6 '>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4   ">
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-rotation-lg.webp' className='md:w-full w-3/6 mx-auto' />
            </div>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-4 md:gap-4 gap-2   ">
            <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-rotation.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/02-rotation.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/03-rotation.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/04-rotation.webp' className='border border-[#212121]/50' />
            </div>


          </div>


        </div>

        <div className='space-y-6 '>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4   ">
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-3d-shape-lg.webp' className='md:w-full w-3/6 mx-auto' />
            </div>
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-puzzle-pieces.webp' className='md:w-full w-3/6 mx-auto' />
            </div>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-4 md:gap-4 gap-2   ">
            <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-3d-shape.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/02-3d-shape.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/03-3d-shape.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/04-3d-shape.webp' className='border border-[#212121]/50' />
            </div>


          </div>


        </div>
        <div className='space-y-6 '>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4   ">
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-puzzle-pieces-lg.webp' className='md:w-full w-3/6 mx-auto' />
            </div>

          </div>
          <div className="grid md:grid-cols-5 grid-cols-3 gap-4   ">
            <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-puzzle-pieces.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/02-puzzle-pieces.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/03-puzzle-pieces.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/03-puzzle-pieces.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>E</span>
              </div>
              <img src='./img/surveylink/05-puzzle-pieces.webp' className='border border-[#212121]/50' />
            </div>
          </div>


        </div>
        <div className='space-y-6 '>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4   ">
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-puzzle-pieces.webp' className='md:w-full w-3/6 mx-auto' />
            </div>
            <div className='border border-[#212121]/70 bg-white'>
              <img src='./img/surveylink/01-puzzle-pieces.webp' className='md:w-full w-3/6 mx-auto' />
            </div>

          </div>
          <div className="grid md:grid-cols-4 grid-cols-4 md:gap-4 gap-2   ">
            <div className=" bg-[#2196f3]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='md:size-8 size-6  flex items-center justify-center bg-[#2196f3] text-white rounded-sm font-medium md:text-base text-sm'>A</span>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-[#2196f3]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <img src='./img/surveylink/01-bubble-shabe.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>B</span>
              </div>
              <img src='./img/surveylink/01-bubble-shabe.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>C</span>
              </div>
              <img src='./img/surveylink/01-bubble-shabe.webp' className='border border-[#212121]/50' />
            </div>
            <div className=" bg-[#212121]/10 p-1.5 space-y-2">
              <div className='flex items-center justify-between '>
                <span className='smd:ize-8  size-6 flex items-center justify-center bg-[#fff] text-[#212121] rounded-sm font-medium md:text-base text-sm'>D</span>
              </div>
              <img src='./img/surveylink/01-bubble-shabe.webp' className='border border-[#212121]/50' />
            </div>


          </div>


        </div> */}
      </div>

    </>
  );
}