import React from 'react';
import ReactEcharts from "echarts-for-react";

export default function PieChart(props) {

  const { listX, getInterestDetails, sortX } = props;



  let colorList = [
    "#64B5F6",
    "#4DD0E1",
    "#B388FF",
    "#4DB6AC",
    "#FFB74D",
    "#FF8A65",
  ]

  let dataNew = []
  if (listX && listX.length > 0) {
    listX.forEach((ele, index) => {
      dataNew.push({
        children: [
          {
            name: getInterestDetails(ele, "label"),
            itemStyle: {
              color: colorList[index]
            },
            children: [
              {
                name: getInterestDetails(ele, "score").toString(),
                value: getInterestDetails(ele, "score"),
                itemStyle: {
                  color: colorList[index]
                }
              }
            ]
          },
        ]
      })

      dataNew.sort((b, a) => sortX(a, "value") - sortX(b, "value"));


    })
  }
  // console.log('listX--///--listX----------->', listX)
  // console.log('list--///--list----------->', dataNew)


  return (
    <>

      <div className="pt-2 flex justify-center">

        <ReactEcharts style={{ width: '720px', height: '720px' }}
          option={{
            tooltip: {
              trigger: 'item'
            },
            series: {
              type: 'sunburst',
              data: dataNew,
              radius: [0, '98%'],
              sort: undefined,
              emphasis: {
                focus: 'ancestor'
              },
              levels: [
                {},
                {
                  r0: '1%',
                  r: '%',
                  itemStyle: {
                    borderWidth: 2
                  },
                  label: {
                    rotate: 'tangential',
                    color: 'black',
                    fontSize: 18
                  }
                },
                {
                  r0: '0%',
                  r: '80%',
                  label: {
                    align: 'right',
                    color: 'black',
                    fontSize: 18
                  }
                },
                {
                  r0: '80%',
                  r: '82%',
                  label: {
                    position: 'outside',
                    padding: 3,
                    silent: false,
                    color: 'black',
                    fontSize: 18
                  },
                  itemStyle: {
                    borderWidth: 3
                  }
                }
              ]
            }
          }}
        />
        {/* <div>{renderData()}</div> */}

      </div>

      <h2 className="text-xl font-bold text-center uppercase">Your Top 3 Interest</h2>
      <div className="w-full mx-auto lg:w-9/12">
        <div className="grid grid-cols-3 gap-2">
          {dataNew && dataNew.length > 0 ?
            dataNew.map((ele, index) =>
              (index<3)?
              <div className="bg-[#757575] p-2 text-center font-medium" style={{ background: sortX(ele, "itemStyle").color }}>
                <p>{ele["children"][0]["name"]} ({ele["children"][0]["children"][0]["value"]})</p>
              </div>
              :null
            ) : null}
        </div>
      </div>


    </>
  );
}

