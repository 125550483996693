import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assessmentActions } from "../../../../../_actions";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {

    }
  }

  componentDidMount() {


  }


  render() {

    let { } = this.props;
    let { loading, EditDetailsFn, EditButtonFn, createdAt, getAssessmenrEmployeeDetailsData,
      getCareerFilamentScoreData, getPersonalityIndexData,
      getWorkValueDimensionScoreData, getInterestDimensionScoreData,
      getAptitudeTestScoreData
    } = this.props;


    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    let colorList = ["#BA68C8", "#7986CB", "#FFB74D"]
    let colorList2 = ["#64B5F6", "#BA68C8", "#7986CB", "#FFB74D", "#E57373"]

    function getDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {
        let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
        value = score.toFixed(1);
      } else if (field === "scorePerc") {
        let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
        value = (parseInt((parseFloat(score) * 85) / 6)).toString();
        console.log('score--///--value----------->', score, " - ", value)
      }
      return value
    }

    function getInterestDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "label2") {
        value = item && item["children"] && item["children"][0] && item["children"][0]["name"] ? item["children"][0]["name"] : "";
      } else if (field === "desc") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {
        let activeCount = item && item["dimension"] && item["dimension"][0] && item["dimension"][0]["activeCount"] && item["dimension"][0]["activeCount"][0] && item["dimension"][0]["activeCount"][0]["total"] ? item["dimension"][0]["activeCount"][0]["total"] : 0;
        let totalCount = item && item["dimension"] && item["dimension"][0] && item["dimension"][0]["totalCount"] && item["dimension"][0]["totalCount"][0] && item["dimension"][0]["totalCount"][0]["total"] ? item["dimension"][0]["totalCount"][0]["total"] : 0;
        // let score = (activeCount*100)/totalCount;
        let score = activeCount;
        value = parseFloat(score.toFixed(1));
      }
      return value
    }

    function getWorkValueDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "desc") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {
        let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
        value = parseFloat(score.toFixed(1));
      }
      return value
    }

    function getAptitudeDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "desc") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {


        let totalCount = 0;
        let correctCount = 0;

        if (item && item["response"] && item["response"].length > 0) {
          item["response"].forEach((element) => {
            totalCount++;
            if (element && element["options"].length > 0) {
              let getIndex = element["options"].findIndex(prev => prev.name === element.answer);
              if (element && element["options"] && element["options"][getIndex] && element["options"][getIndex]["isCorrectAnswer"]) {
                correctCount++
              }
            }

          })
        }


        let score = (correctCount * 100) / totalCount;
        value = parseFloat(score.toFixed(1));

      }
      return value
    }



    function sortX(item, field) {
      let ele = item && item["children"] && item["children"][0] && item["children"][0]["children"] && item["children"][0]["children"][0] && item["children"][0]["children"][0][field] ? item["children"][0]["children"][0][field] : 0
      return ele
    }

    let dataXlist = []
    if (getPersonalityIndexData && getPersonalityIndexData.length > 0) {
      getPersonalityIndexData.forEach((ele) => {
        let temp = {
          ...ele
        }
        dataXlist.push(temp);
      })
      dataXlist.sort((b, a) => getDetails(a, "score") - getDetails(b, "score"))
    }

    let dataNew2 = []
    if (getInterestDimensionScoreData && getInterestDimensionScoreData.length > 0) {
      getInterestDimensionScoreData.forEach((ele) => {
        let temp = {
          ...ele
        }
        dataNew2.push(temp);
      })
      dataNew2.sort((b, a) => getInterestDetails(a, "score") - getInterestDetails(b, "score"))
    }

    let dataNew3 = []
    if (getWorkValueDimensionScoreData && getWorkValueDimensionScoreData.length > 0) {
      getWorkValueDimensionScoreData.forEach((ele) => {
        let temp = {
          ...ele
        }
        dataNew3.push(temp);
      })
      dataNew3.sort((b, a) => getWorkValueDetails(a, "score") - getWorkValueDetails(b, "score"))
    }



    let dataNew4 = []
    // if (getAptitudeTestScoreData && getAptitudeTestScoreData.length > 0) {
    //   getAptitudeTestScoreData.forEach((ele) => {
    //     let temp = {
    //       ...ele,
    //       label: getAptitudeDetails(ele, "label"),
    //     }
    //     dataNew4.push(temp);
    //   })

    //   dataNew4.sort((b, a) => getAptitudeDetails(a, "score") - getAptitudeDetails(b, "score"))

    // }

    if (getAptitudeTestScoreData && getAptitudeTestScoreData.length > 0) {
      getAptitudeTestScoreData.forEach((ele) => {
        let temp = {
          ...ele,
          label: getAptitudeDetails(ele, "label"),
        }
        dataNew4.push(temp);
      })

      dataNew4.sort((b, a) => a.value - b.value)

    }


    console.log("dataNew4---->", dataNew4)


    let mainList = [
      ,
      {
        "title": "Aptitutde",
        "list": dataNew4,
        "type": 4
      },
      {
        "title": "Personality",
        "list": dataXlist,
        "type": 1
      },
      {
        "title": "Interest",
        "list": dataNew2,
        "type": 2
      },
      {
        "title": "Work Value",
        "list": dataNew3,
        "type": 3
      }
    ]

    let careerFitment = []
    if (getCareerFilamentScoreData && getCareerFilamentScoreData.length > 0) {
      careerFitment = getCareerFilamentScoreData.map((item) => ({
        category: item.name,
        percentage: Number(item.score).toFixed(2) + "%",
        score: Number(item.score).toFixed(2)
      }))
      careerFitment.sort((b, a) => (a.score - b.score))
    }


    return (
      <>
        <div className="p-6 space-y-2 text-base font-normal text-left bg-white border rounded-lg md:space-y-6">
          <div className="flex items-start lg:space-x-4">

            <p className="text-base  text-[#212121]">This is your personalized Insights Card, which is unique to you. The results are based on the assessment of your Aptitude Test, Interest Test, Work Values Test and Personality Test to provide a best career fitment identified in the assessment given by you on {createdAt}.</p>
          </div>
        </div>


        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 ">

          <div className="w-full p-4 space-y-6 bg-white border rounded-lg md:p-6">
            <div className="space-y-2">
              <h1 className="text-lg font-bold text-gray-800 md:font-semibold md:text-xl"> Top Recommendations </h1>
              <p>Roles where you have high fitment score are indicated below.</p>
            </div>

            <div className="space-y-0.5 text-md ">
              {careerFitment && careerFitment.length > 0 ?
                careerFitment.map((ele, index) =>
                  (index < 5) ?
                    <>
                      <div className={"lg:flex hidden items-stretch " + (index > 0 ? "ml-" + (index * 12).toString() : "")}>
                        <div style={{ background: colorList2[index > 4 ? index % 4 : index] }} className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#64B5F6] flex-shrink-0 ">  {index + 1}</div>
                        <div style={{ background: hexToRgbA(colorList2[index > 4 ? index % 4 : index], "0.10") }} className="text-sm bg-[#64B5F6]/10 w-full h-12 px-4 flex items-center">
                          <p>{ele.category}</p>
                        </div>
                      </div>
                      <div className="flex items-stretch md:hidden ">
                        <div style={{ background: colorList2[index > 4 ? index % 4 : index] }} className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#64B5F6] flex-shrink-0 ">  {index + 1}</div>
                        <div style={{ background: hexToRgbA(colorList2[index > 4 ? index % 4 : index], "0.10") }} className="text-sm bg-[#64B5F6]/10 w-full h-12 px-4 flex items-center">
                          <p>{ele.category}</p>
                        </div>
                      </div>
                    </>
                    : null
                ) : null}
            </div>

          </div>
          <div className="w-full p-4 space-y-6 bg-white border rounded-lg md:p-6">
            <div className="space-y-2  ">
              <h1 className="text-lg font-bold text-gray-800 md:font-semibold md:text-xl">Fitment Score</h1>
              <p>Top Fitment Score based on your given responses in Psychometric assessment.</p>
            </div>


            <div className="md:space-y-0.5 space-y-4 text-md ">
              {careerFitment && careerFitment.length > 0 ?
                careerFitment.map((ele, index) =>
                  (index < 5) ?
                    <div className="flex md:flex-row flex-col items-center md:gap-4 gap-2 md:h-12">
                      <p className="md:w-[40%] w-full flex-shrink-0 text-sm md:text-right text-left">{ele.category}</p>

                      <div className="text-base w-full flex items-center space-x-2" >
                        <div className="md:w-[80%] w-full h-8 flex items-center justify-center font-bold text-base bg-[#64B5F6] flex-shrink-0"
                          style={{
                            width: ele.percentage,
                            background: colorList2[index > 4 ? index % 4 : index]
                          }}>
                        </div>
                        <span className="text-sm">{ele.percentage}</span>
                      </div>
                    </div>
                    : null
                ) : null}

            </div>


          </div>


        </div>
        <div className="w-full p-4 space-y-8 bg-white border rounded-lg md:p-6">
          <div className="space-y-2">
            <h1 className="text-lg font-bold text-gray-800 md:font-semibold md:text-xl"> Psychometric Highlights </h1>
            <p>Your top 3 psychometric highlights of Aptitude, Interest, Personality traits, and Work Values are as below.</p>
          </div>


          <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-2">
            {mainList && mainList.length > 0 ?
              mainList.map((element) =>
                <div className="space-y-0.5 md:p-6 p-2 border">
                  <p className="text-center font-semibold text-base pb-4">{element.title}</p>
                  {element && element.list && element.list.length > 0 ?
                    element.list.map((item, index) =>
                      (index < 3) ?
                        <div className={"flex items-stretch " + (index === 2 ? "ml-24" : (index === 1 ? "ml-12" : ""))}>
                          <div style={{ background: colorList[index] }} className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#BA68C8] flex-shrink-0 ">  {index + 1}</div>
                          <div style={{ background: hexToRgbA(colorList[index], "0.10") }} className="text-sm bg-[#BA68C8]/10 w-full h-12 px-4 flex items-center">
                            <p>{getDetails(item, "label")}</p>
                          </div>
                        </div>
                        : null
                    ) : null}
                </div>
              ) : null}
          </div>


          <p className="text-base">To deep dive into your abilities and strengths, read your full Career Fitment Report which includes your top 5 most suitable roles, fitment in different industry recommendation and your detail Psychometric test analysis.</p>

        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, assessment } = state;
  return {
    loggingIn,
    users,
    dashboard,
    assessment
  };
}
export default connect(mapStateToProps)(Summary);