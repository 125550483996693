import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "./Component/HeaderTransprent";
import Footer from "./Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { Helmet } from "react-helmet";
import OrgType from "./Component/OrgType";
import { FiArrowUpRight } from "react-icons/fi";
import Marquee from "react-fast-marquee";
import TestHeader from "../../../components/TestHeader";


class HomeTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      formData: {
        'type': 'Individual'
      },
      showOrgTypeModal: false,
      hoveredElement: 1,
      hoveredElementtwo: 1,
      hoveredElementthree: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(assessmentActions.userAssessmentUserProfile('getAssessmentCartCount'));
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }
  handleHover = (element) => {
    this.setState({ hoveredElement: element });
  };
  handleHovertwo = (element) => {
    this.setState({ hoveredElementtwo: element });
  };

  handleHoverthree = (element) => {
    this.setState({ hoveredElementthree: element });
  };


  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleDownload = () => {
    let code = "TalentPlusStrengths21";
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    link.click();
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/how-talentplus-strength-works");
    window.scrollTo(0, 0);
  };

  closeOrgTypeModal = () => {
    this.setState({ showOrgTypeModal: false })
  }


  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["type"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;
    if (formData["type"] === "Individual") {
      this.props.history.push(`/assessment/` + "surveylogin");
    } else if (formData["type"] === "Organization") {
      window.location.href = 'https://plusinsight.happyplus.in/surveylink/#/assessment/signin';
    }
  };


  handleTakeAssessmentRedirect = () => {
    this.setState({ showOrgTypeModal: false }, () => {
      this.handleOptionSubmit();
    })
  }

  handleScrollToSection = () => {
    const section = document.getElementById("section2");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  render() {
    const { hoveredElement } = this.state;
    const { hoveredElementtwo } = this.state;
    const { hoveredElementthree } = this.state;

    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    let { showOrgTypeModal, formData } = this.state;
    // console.log("quantity-->", quantity);


    return (
      <div className="overflow-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Unlock your workforce potential with our skills-based talent intelligence platform and psychometric assessments for smarter hiring.</title>
          <meta name="description" content="Unlock talent potential with our psychometric assessments backed platform for employee Personality assessment. Optimize your workforce potential now!" />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/home" />
        </Helmet>

        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{
            backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
          <TestHeader
            handleTakeAssessmentRedirect={this.handleTakeAssessmentRedirect}
          />
          {/* Hero Section */}
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-8 text-center md:pb-10 md:pt-0 md:text-left ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Re-Discover Yourself Using Your TalentPlusStrengths </h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-[#212121]">Have you discover your true strengths or you are still in dilemma? Choose TalentPlusStrengths assessment today.</p>
                </div>
                <div className="flex flex-row items-center justify-center gap-4 md:justify-start md:items-center">
                  <button data-aos="fade-right" onClick={() => this.gotoPage("employer")} className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center">  Employers</button>
                  <button data-aos="fade-right" onClick={() => this.gotoPage("individual")} className="flex space-x-2 items-center  border border-black  hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-200  text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center"> <span> Individual</span>{/* <TbChevronRight className="text-[1.5rem]" /> */}</button>
                  <button data-aos="fade-right" onClick={() => this.gotoPage("teen-talent")} className="flex space-x-2 items-center  border border-black  hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-200  text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center"> <span>
                    teen</span>{/* <TbChevronRight className="text-[1.5rem]" /> */}</button>
                </div>
              </div>

              <div className="flex items-end justify-center w-full lg:justify-end">
                <img data-aos="fade-left" src="/img/assessment/re-discover-yourself-using.webp" alt="re-discover-yourself-using" className="z-0 w-full" />
              </div>
            </div>
          </div>
        </section>

        {/* Marquee section */}
        <section className="bg-[#DBEEFD]">
          <section className="w-full py-10 mx-auto space-y-6 text-center ">
            <h1 data-aos="slide-up" className="xl:text-2xl text-lg  font-semibold text-[#212121] capitalize flex flex-wrap justify-center px-4">
              <p> Trusted by leading global enterprises and humans everywhere <span onClick={this.handleScrollToSection} className="text-[#2196F3] underline pl-3 cursor-pointer uppercase">See All</span></p>
            </h1>

            <Marquee className="flex flex-row w-full gap-10">
              <div className="flex flex-row justify-between w-full gap-10 ">
                {/* <img src="/img/assessment/icici-lombard.png" alt="icici-lombard.png" className="h-16 " />
                <img src="/img/assessment/green-wave.png" alt="green-wave.png" className="h-16 " />
                <img src="/img/assessment/indian-bank.png" alt="indian-bank.png" className="h-16 " />
                <img src="/img/assessment/toshiba.png" alt="toshiba.png" className="h-16 " />
                <img src="/img/assessment/itc.png" alt="itc.png" className="h-16" />
                <img src="/img/assessment/Gennova.png" alt="Gennova.png" className="h-16 " />
                <img src="/img/assessment/exeltis.png" alt="exeltis.png" className="h-16 " />
                <img src="/img/assessment/icici-lombard.png" alt="icici-lombard.png" className="h-16 " />
                <img src="/img/assessment/green-wave.png" alt="green-wave.png" className="h-16 " />
                <img src="/img/assessment/indian-bank.png" alt="indian-bank.png" className="h-16 " />
                <img src="/img/assessment/toshiba.png" alt="toshiba.png" className="h-16 " />
                <img src="/img/assessment/itc.png" alt="itc.png" className="h-16" />
                <img src="/img/assessment/Gennova.png" alt="Gennova.png" className="h-16 " />
                <img src="/img/assessment/exeltis.png" alt="exeltis.png" className="h-16 " /> */}
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/zee-logo.webp" alt="zee-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/axis-securities-logo.webp" alt="axis-securities-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/exeltis-logo.webp" alt="exeltis-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/fortune-logo.webp" alt="fortune-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/gennova-logo'.webp" alt="gennova-logo'.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/green-wave-logo.webp" alt="green-wave-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/hitachi-logo.webp" alt="hitachi-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/icici-lombard-logo.webp" alt="icici-lombard-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/indian-bank-logo.webp" alt="indian-bank-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/itc-hotels-logo.webp" alt="itc-hotels-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/itc-logo.webp" alt="itc-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/raheja-logo.webp" alt="raheja-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/srf-logo.webp" alt="srf-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/suzlon-logo.webp" alt="suzlon-logo.webp" />
                <img className="border border-[#FFFFFF] bg-white h-20" src="/clients/toshiba-logo.webp" alt="toshiba-logo.webp" />
              </div>
            </Marquee>
          </section>
        </section>

        <div className="py-10 space-y-10 md:py-20 md:space-y-16">
          {/* section 1 */}
          <section className="w-full space-y-6">
            <section className="flex flex-col items-start w-full gap-10 py-10 lg:flex-row 2xl:gap-20 lg:gap-10 md:gap-20 img-changer">

              {/* img */}
              <div className="lg:w-[60%] w-full flex flex-col justify-end lg:pl-10 px-4">
                <img className="w-full mx-auto hometwo-hover-img-1 " src="/img/assessment/Empower-Workforce.webp" alt="Empower-Workforce" />
              </div>
              {/* text */}
              <div data-aos="fade-right" className="lg:w-[40%] w-full text-left grid grid-cols-1 gap-0 2xl:mr-[8%] xl:mr-[4%] xl:px-0 px-4 mx-auto ">
                <div className="mb-6 space-y-4 text-center lg:text-left">
                  <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">Empower your workforce with TalentPlusStrengths</h1>
                  <p className="text-lg text-[#3D405B]"> Elevating efficiency, boosting productivity, and reshaping team dynamics for unparalleled success</p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Streamlined Talent Management</h2>
                  <p className="text-base text-[#212121]/70">TalentPlusStrengths enhances organizational efficiency by providing a comprehensive platform for talent identification, development, and retention.</p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Increased Productivity</h2>
                  <p className="text-base text-[#212121]/70"> Harness the strengths of individuals within your team, leading to improved collaboration and productivity.
                  </p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Enhanced Employee Satisfaction</h2>
                  <p className="text-base text-[#212121]/70">TalentPlusStrengths contributes to a positive work environment by helping employees recognize and leverage their strengths, fostering a sense of fulfillment.</p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Talent Development Programs</h2>
                  <p className="text-base text-[#212121]/70">Tailor-made solutions for organizational growth, including training programs and strategies that align with business objectives.</p>
                </div>

                <div className="flex flex-row justify-start gap-4 px-6 py-4 space-y-2">
                  <button data-aos="fade-right" onClick={() => this.gotoPage("employer")} className="flex space-x-2 items-center  border border-black  hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-200  text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center"> <span>  Learn More</span><FiArrowUpRight className="text-[1.5rem]" /></button>
                </div>

              </div>
            </section>
          </section>


          {/*section 2 */}
          <section className="w-full space-y-6 ">
            <section className="flex flex-col-reverse items-start w-full gap-10 py-10 lg:flex-row 2xl:gap-20 lg:gap-10 md:gap-20 img-changer ">
              {/* text */}
              <div data-aos="fade-right" className="lg:w-[40%] w-full text-left  grid grid-cols-1 gap-0 2xl:ml-[8%] xl:ml-[4%] xl:px-0 px-4 mx-auto ">
                <div className="mb-6 space-y-4 text-center lg:text-left">
                  <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">Unlock Your Potential with TalentPlusStrengths for Personalized Growth  </h1>
                  <p className="text-xl text-[#3D405B] ">Career Excellence, Work-Life Harmony, Enhanced Self-Awareness, and Stronger Family Bonds.
                  </p>
                </div>
                <div className="rounded-md cursor-pointer transition-all duration-150 py-6 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Personalized Growth Plans</h2>
                  <p className="text-base text-[#212121]/70 ">Identify and nurture your unique talents, paving the way for personal and professional development.</p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Career Advancement Opportunities</h2>
                  <p className="text-base text-[#212121]/70 ">  Leverage your strengths to stand out in your career, opening doors to new opportunities and advancements.
                  </p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Work-Life Balance</h2>
                  <p className="text-base text-[#212121]/70 ">TalentPlusStrengths assists in recognizing and managing personal strengths, contributing to a balanced and fulfilling life both at work and home.</p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Improved Self-Awareness</h2>
                  <p className="text-base text-[#212121]/70 ">Gain insights into your abilities and preferences, fostering a deeper understanding of your potential and guiding life choices.</p>
                </div>

                <div className="rounded-md cursor-pointer transition-all duration-150 py-4 px-6 space-y-2 hover:bg-gradient-to-b from-[#2196f3]/10 to-transparent">
                  <h2 className="text-xl font-bold text-[#212121]">Strengthened Family Bonds</h2>
                  <p className="text-base text-[#212121]/70 ">Extend the benefits of self-awareness to your family life, creating a positive impact on relationships and overall well-being.</p>
                </div>

                <div className="flex flex-row justify-start gap-4 px-6 py-4 space-y-2">
                  <button data-aos="fade-right" onClick={() => this.gotoPage("individual")} className="flex space-x-2 items-center  border border-black  hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-200  text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center"> <span>  Learn More</span><FiArrowUpRight className="text-[1.5rem]" /></button>
                </div>

              </div>
              {/* img */}
              <div className="lg:w-[60%] w-full flex flex-col justify-end lg:pr-10 px-4">
                <img
                  className="w-full mx-auto hometwo-hover-img-1"
                  src="/img/assessment/unlock-your-potential-with-talentplus.webp"
                  alt="unlock-your-potential-with-TalentPlusStrengths"
                />
              </div>
            </section>
          </section>

        </div>

        {/* section 3*/}
        <section className="w-full py-16 space-y-6 bg-cover" style={{ backgroundImage: `url('/img/assessment/bg-gradient.webp')`, }}>
          <div className="px-4 mx-auto mb-6 space-y-4 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">How TalentPlusStrengths works</h1>
            <p className="text-xl text-[#3D405B]">Career Excellence, Work-Life Harmony, Enhanced Self-Awareness, and Stronger Family Bonds.
            </p>
          </div>
          <section className="flex flex-col-reverse items-start w-full gap-10 py-10 lg:flex-row lg:gap-10 md:gap-20">
            {/* text */}
            <div data-aos="fade-right" className="lg:w-[40%] w-full text-left  grid grid-cols-1 gap-0 2xl:ml-[8%] xl:ml-[4%] xl:px-0 px-4 mx-auto ">

              <div
                onMouseEnter={() => this.handleHoverthree(1)}
                className={`rounded-md cursor-pointer transition-all duration-150 py-6 px-6    hover:border-[#2196F3] space-y-2 ${hoveredElementthree === 1 ? '  bg-gradient-to-b from-[#2196f3]/10 to-transparent  ' : ''
                  }`}
              >
                <h2 className="text-xl font-bold text-[#212121]">1. Discover Your Strengths: A Comprehensive 25-Minute Assessment Journey</h2>
                <p className="text-base text-[#212121]/70 ">In this 25-minute assessment, you will interact with 110 sets of statements that encompass 22 themes within 4 domains. Each statement set aims to assess your talents, elucidate your patterns of emotion and behavior, prompting you to select those that most accurately mirror your traits.</p>
              </div>

              <div
                onMouseEnter={() => this.handleHoverthree(2)}
                className={`rounded-md cursor-pointer transition-all duration-150 py-6 px-6    hover:border-[#2196F3] space-y-2 ${hoveredElementthree === 2 ? '  bg-gradient-to-b from-[#2196f3]/10 to-transparent  ' : ''
                  }`}
              >
                <h2 className="text-xl font-bold text-[#212121]">2. Master Your Potential: Empowering Self-Discovery with TalentPlusStrengths</h2>
                <p className="text-base text-[#212121]/70 ">Embark on a journey of self-discovery with your personalized TalentPlusStrengths reports and guides, uncovering enlightening moments that deepen your understanding of your exceptional individuality.</p>
              </div>

              <div
                onMouseEnter={() => this.handleHoverthree(3)}
                className={`rounded-md cursor-pointer transition-all duration-150 py-6 px-6    hover:border-[#2196F3] space-y-2 ${hoveredElementthree === 3 ? '  bg-gradient-to-b from-[#2196f3]/10 to-transparent  ' : ''
                  }`}
              >
                <h2 className="text-xl font-bold text-[#212121]">3. Personalized Learning and Practical Feedback</h2>
                <p className="text-base text-[#212121]/70 ">Our platform employs advanced technical capabilities to offer personalized learning journeys and provide practical feedback on your talents.</p>
              </div>

              <div className="flex flex-row justify-start gap-4 px-6 py-4 space-y-2">
                <button data-aos="fade-right" onClick={() => this.gotoPage("how-talentplus-strength-works")} className="flex space-x-2 items-center  border border-black  hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-200  text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center"> <span>  Learn More</span><FiArrowUpRight className="text-[1.5rem]" /></button>
              </div>

            </div>

            {/* img */}
            <div className="lg:w-[60%] w-full flex flex-col justify-end lg:pr-10 px-4">
              {hoveredElementthree === 1 ? <img
                className="w-full mx-auto "
                src="/img/assessment/assessment_survey.webp"
                alt="unlock-your-potential-with-TalentPlusStrengths"
              /> : null}
              {hoveredElementthree === 2 ? <img
                className="w-full mx-auto "
                src="/img/assessment/summary_report.webp"

                alt="unlock-your-potential-with-TalentPlusStrengths"
              /> : null}
              {hoveredElementthree === 3 ? <img
                className="w-full mx-auto "
                src="/img/assessment/detail_report.webp"

                alt="unlock-your-potential-with-TalentPlusStrengths"
              /> : null}

            </div>
          </section>
        </section>

        {/*All Company logo*/}
        <section id="section2" className="w-full py-20 space-y-6 bg-cover bg-white">
          <div className="px-4 mx-auto mb-6 space-y-4 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem] capitalize font-bold text-[#212121]">Some of our esteemed clients</h1>
            <p className="text-xl text-[#3D405B]">Trusted by leading global enterprises and humans everywhere</p>

            <section className="flex flex-col-reverse items-start w-full gap-10 pt-10 lg:flex-row lg:gap-10 md:gap-20">
              {/* text */}
              <div data-aos="fade-right" className="w-full text-left grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
                <img className="border border-[#E8E8E8]" src="/clients/zee-logo.webp" alt="zee-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/axis-securities-logo.webp" alt="axis-securities-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/exeltis-logo.webp" alt="exeltis-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/fortune-logo.webp" alt="fortune-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/gennova-logo'.webp" alt="gennova-logo'.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/green-wave-logo.webp" alt="green-wave-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/hitachi-logo.webp" alt="hitachi-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/icici-lombard-logo.webp" alt="icici-lombard-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/indian-bank-logo.webp" alt="indian-bank-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/itc-hotels-logo.webp" alt="itc-hotels-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/itc-logo.webp" alt="itc-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/raheja-logo.webp" alt="raheja-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/srf-logo.webp" alt="srf-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/suzlon-logo.webp" alt="suzlon-logo.webp" />
                <img className="border border-[#E8E8E8]" src="/clients/toshiba-logo.webp" alt="toshiba-logo.webp" />
              </div>
            </section>
          </div>
        </section>


        {/*Testimonials*/}
        <section id="section2" className="w-full py-20 space-y-6 bg-cover bg-[#FAFAFA]">
          <div className="px-4 mx-auto mb-6 space-y-4 text-center 2xl:w-8/12 xl:w-11/12 xl:px-0">
            <p className="text-xl text-[#2196F3] leading-7 tracking-widest uppercase">Testimonials</p>
            <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem] capitalize font-bold text-[#212121]">Hear from our trusted partners</h1>
            <div className="w-full px-4 mx-auto space-y-16 xl:pt-10 2xl:w-10/12 xl:w-11/12 xl:px-0">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
                <div data-aos="fade-in" className="border bg-white hover:bg-[#2196F3]/5 shadow-lg md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom space-y-4 flex flex-col justify-between h-full">
                  <div className="space-y-6">
                    <div class="flex flex-col items-center">
                      <div class="relative text-left">
                        <svg class="absolute top-0 left-0 w-8 h-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <path d="M25.1984 25.1992V44.7992H5.59843V25.1992C5.59843 19.0392 10.6384 11.1992 16.7984 11.1992H22.3984L19.5984 16.7992C19.5984 16.7992 13.9984 16.7992 13.9984 25.1992H25.1984ZM50.3984 25.1992V44.7992H30.7984V25.1992C30.7984 19.0392 35.8384 11.1992 41.9984 11.1992H47.5984L44.7984 16.7992C44.7984 16.7992 39.1984 16.7992 39.1984 25.1992H50.3984Z" fill="#2196F3" />
                        </svg>
                        <p class="px-10 py-2 text-base text-[#212121]/80">I am writing to share about a winner in your portfolio of offerings and that's TalentPlus. Thank you for extending it to me. Across my experience with psychometric instruments for development- coaching and otherwise, TalentPlus, for its authentic attempt at surfacing the trait and behavioural nuance and lessening ones blind spot, is clearly one at the top of the stack. Its simplicity and precision are unmissable. Professionals will recognise it for its long legs and put it to good use for self, teams and the organization at large.</p>
                        <svg class="absolute bottom-0 right-0 w-8 h-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <path d="M30.8016 30.8008V11.2008H50.4016V30.8008C50.4016 36.9608 45.3616 44.8008 39.2016 44.8008H33.6016L36.4016 39.2008C36.4016 39.2008 42.0016 39.2008 42.0016 30.8008H30.8016ZM5.60156 30.8008V11.2008H25.2016V30.8008C25.2016 36.9608 20.1616 44.8008 14.0016 44.8008H8.40156L11.2016 39.2008C11.2016 39.2008 16.8016 39.2008 16.8016 30.8008H5.60156Z" fill="#2196F3" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-full pt-6">
                    <div className="flex items-center justify-center w-full border-t ">
                      <div class="flex items-center gap-5 pt-5">
                        <img class="rounded-full object-cover h-24 w-24" src="testimonial/dheeraj-jaggi.webp" alt="dheeraj-jaggi.webp" />
                        <div class="grid gap-1 text-left">
                          <h5 class="text-gray-900 font-semibold text-lg capitalize">dheeraj jaggi</h5>
                          <span class="text-sm leading-6 text-gray-500 capitalize">Chief Human Resources Officer  At Zee</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-aos="fade-in" className="border bg-white hover:bg-[#2196F3]/5 shadow-lg md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom space-y-4 flex flex-col justify-between h-full">
                  <div className="space-y-6">
                    <div class="flex flex-col items-center">
                      <div class="relative text-left">
                        <svg class="absolute top-0 left-0 w-8 h-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <path d="M25.1984 25.1992V44.7992H5.59843V25.1992C5.59843 19.0392 10.6384 11.1992 16.7984 11.1992H22.3984L19.5984 16.7992C19.5984 16.7992 13.9984 16.7992 13.9984 25.1992H25.1984ZM50.3984 25.1992V44.7992H30.7984V25.1992C30.7984 19.0392 35.8384 11.1992 41.9984 11.1992H47.5984L44.7984 16.7992C44.7984 16.7992 39.1984 16.7992 39.1984 25.1992H50.3984Z" fill="#2196F3" />
                        </svg>
                        <p class="px-10 py-2 text-base text-[#212121]/80">While I found the Talent Plus tool to be insightful and accurate, what was really helpful was the de-briefing session that helped me understand how the different parts of personality interact with each other, and "show up" as a part of who we are. The application of the tool in real life is where the magic lies.</p>
                        <svg class="absolute bottom-0 right-0 w-8 h-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                          <path d="M30.8016 30.8008V11.2008H50.4016V30.8008C50.4016 36.9608 45.3616 44.8008 39.2016 44.8008H33.6016L36.4016 39.2008C36.4016 39.2008 42.0016 39.2008 42.0016 30.8008H30.8016ZM5.60156 30.8008V11.2008H25.2016V30.8008C25.2016 36.9608 20.1616 44.8008 14.0016 44.8008H8.40156L11.2016 39.2008C11.2016 39.2008 16.8016 39.2008 16.8016 30.8008H5.60156Z" fill="#2196F3" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-full pt-6">
                    <div className="flex items-center justify-start w-full mt-12 border-t ">
                      <div class="flex items-center gap-5 pt-5">
                        <img class="rounded-full object-cover h-24 w-24" src="testimonial/mahzarine-jehangir-jogani.webp" alt="mahzarine-jehangir-jogani.webp" />
                        <div class="grid gap-1 text-left">
                          <h5 class="text-gray-900 font-semibold text-lg capitalize">Mahzarine Jehangir - Jogani</h5>
                          <span class="text-sm leading-6 text-gray-500 capitalize">Director - Leadership Development and DEI At ProEves</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Testimonials/> */}

        {
          showOrgTypeModal ? (
            <OrgType
              closeModal={this.closeOrgTypeModal}
              formData={formData}
              handleOptionInput={this.handleOptionInput}
              handleOptionSubmit={this.handleOptionSubmit}
            />
          ) : null
        }


        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
HomeTwo.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(HomeTwo);
