import React from "react";
import { useHistory } from "react-router-dom";

function Header(props) {
  let { stage } = props;
  let history = useHistory()

  function stageClass(stage, itemStage) {
    return stage === itemStage
      ? "border-b-2 border-[#1976D2] pb-4 md:text-xl text-sm uppercase text-[#1976D2]"
      : "border-b-2 pb-4 md:text-xl text-sm uppercase text-[#3D405B]/70 border-transparent";
  }
  return (
    <div className="flex items-center w-full bg-white border-b md:h-28">
      <div className="flex flex-col items-start justify-between w-full gap-4 px-4 py-4 mx-auto md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 md:items-center ">
        {/* <img onClick={() => history.push('/assessment/how-talentplus-strength-works')}
          src="/img/talent_plus_logo.svg"
          className="bg-cover cursor-pointer md:w-36 w-28"
        /> */}
        <img onClick={() => history.push("home")} src="/img/TalentPlusStrengths_logo.svg" className="bg-cover cursor-pointer md:w-36 w-28" />
        <div className="relative flex items-center justify-center w-full pt-4 space-x-2 font-medium capitalize cursor-pointer md:space-x-4 md:justify-end md:w-auto group md:pt-0 ">
          <span className={stageClass(stage, 1)}>cart</span>
          <span className="border-t border-dashed md:w-20 w-6 border-[#3D405B]"></span>
          <span className={stageClass(stage, 2)}>Order confirm</span>
          <span className="border-t border-dashed md:w-20 w-6 border-[#3D405B]"></span>
          <span className={stageClass(stage, 3)}>completed</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="hidden opacity-0 md:block icon icon-tabler icon-tabler-menu-2"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#2196F3"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="6" x2="20" y2="6" />
          <line x1="4" y1="12" x2="20" y2="12" />
          <line x1="4" y1="18" x2="20" y2="18" />
        </svg>
      </div>
    </div>
  );
}
export default Header;
