import React from 'react';

export default function Component(props) {

const { question, answersList,surveyLanguageCode, handleRadio, clicked, surveyScreenData } = props;

return(
    <>
    <div className="grid md:grid-cols-1 grid-cols-1 gap-4 md:px-6 px-4 sm:px-40 md:px-40 lg:px-30 xl:px-32 2xl:px-40">
      {question && question.optionsList && question.optionsList.length>0?
        question.optionsList.map((option, optionindex) =>
        <button 
        style={{
          margin:'7px',
          borderWidth:'2px',
          // borderColor: (answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ? ((surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2:'black') : (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : (afterSubmitData.OptionColor1)?afterSubmitData.OptionColor1: "#757575",
          color:(answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ?surveyScreenData && surveyScreenData.OptionColor2?surveyScreenData.OptionColor2:'black':(surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : "#757575",
          background:(answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ?(surveyScreenData && surveyScreenData.OptionColor3) ? surveyScreenData.OptionColor3 : "#757575":(surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1:'#757575' ,
          fontSize:'15px',

          fontWeight:'bold'
        }} 
        onClick={() => handleRadio(optionindex, question, option)} 
        className="w-full bg-white-1t p-2 rounded-lg text-center md:text-md text-md " >{(surveyLanguageCode!=='')?
        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id])?
            question.transl[surveyLanguageCode].optionsList[option.id]:option.name
        
        :option.name}</button>
      ):null}
    </div>
    </>
  );}