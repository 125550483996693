import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';

// import { toast } from 'react-toastify';
export const userActions = {
    userlogin,
    userAssessmentLogin,
    logout,
    validateOtp,
    validateAssessmentOtp,
    darwinboxVerify,



    userAssessmentRegistration,
    userAssessmentEmailVerify,
    userAssessmentSignIn,
    emptyuserAssessmentSignInVerify,
    userAssessmentValidateOtp,
    contactUs

};
function userlogin(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userlogin(data)
            .then(
                user => {
                    dispatch(success(user));
                    console.log("user: ", user);

                    if (user && user.userinfoToken && user.userinfoToken.code) {
                        props.history.push(`/v2/` + user.userinfoToken.code);
                    }
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function contactUs(data) {

    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }

    return dispatch => {
        dispatch(request());
        userService.contactUs(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_CREATE_CONTACTUS_REQUEST } }
    function success(users) { return { type: userConstants.USER_CREATE_CONTACTUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_CREATE_CONTACTUS_FAILURE, error } }
}


function userAssessmentLogin(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userAssessmentLogin(data)
            .then(
                user => {
                    dispatch(success(user));
                    console.log("user: ", user);

                    if (user && user.userinfoToken && user.userinfoToken.code) {
                        props.history.push(`/v2/` + user.userinfoToken.code);
                    }
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_ASSESSMENT_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_ASSESSMENT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_ASSESSMENT_FAILURE, error } }
}


function userAssessmentRegistration(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userAssessmentRegistration(data)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.USER_ASSESSMENT_REGISTRATION_REQUEST, user } }
    function success(user) { return { type: userConstants.USER_ASSESSMENT_REGISTRATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_ASSESSMENT_REGISTRATION_FAILURE, error } }
}


function userAssessmentEmailVerify(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userAssessmentEmailVerify(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push(`/assessment/products`);

                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.USER_ASSESSMENT_VERIFY_REQUEST, user } }
    function success(user) { return { type: userConstants.USER_ASSESSMENT_VERIFY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_ASSESSMENT_VERIFY_FAILURE, error } }
}


function userAssessmentSignIn(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userAssessmentSignIn(data)
            .then(
                user => {
                    // dispatch(alertActions.success("Otp sent to your email address!"));
                    dispatch(success(user));
                    console.log("user: ", user);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.SIGNIN_ASSESSMENT_REQUEST, user } }
    function success(user) { return { type: userConstants.SIGNIN_ASSESSMENT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SIGNIN_ASSESSMENT_FAILURE, error } }
}


function emptyuserAssessmentSignInVerify() {
    let user1 = {
        userAssessmentSignInSuccess: false,
        userAssessmentSignInFailure: false
    }
    let user2 = {
        userAssessmentValidateOtpSuccess: false,
        userAssessmentValidateOtpFailure: false
    }

    return dispatch => {
        dispatch(success(user1));
        dispatch(success2(user2));
    };
    function success(user) { return { type: userConstants.SIGNIN_ASSESSMENT_SUCCESS, user } }
    function success2(user) { return { type: userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_SUCCESS, user } }
}




function validateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    // props.history.push(`/survey`);

                    if (user && user.userinfo && user.userinfo.code) {
                        props.history.push(`/v2/` + user.userinfo.code);
                    }


                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_OTP_FAILURE, error } }
}


function validateAssessmentOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateAssessmentOtp(data)
            .then(
                user => {
                    dispatch(success(user));

                    props.history.push(`/assessment/survey`);

                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_ASSESSMENT_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_ASSESSMENT_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_ASSESSMENT_OTP_FAILURE, error } }
}

function darwinboxVerify(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.darwinboxVerify(data)
            .then(
                user => {
                    dispatch(success(user));
                    // props.history.push(`/survey`);

                    if (user && user.darwinboxVerify && user.darwinboxVerify.code) {
                        props.history.push(`/v2/` + user.darwinboxVerify.code);
                    }


                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.DARWIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.DARWIN_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DARWIN_OTP_FAILURE, error } }
}



function userAssessmentValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userAssessmentValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    // dispatch(alertActions.success("Successfully Logged In!"));

                    if (user && user.userAssessmentinfoToken && user.userAssessmentinfoToken.role === "Counsellor") {
                        props.history.push("/assessment/student-dashboard");
                    } else {
                        let getPrevRoute = localStorage.getItem('route')
                        localStorage.removeItem('route')
                        console.log("getPrevRoute---->", getPrevRoute)
                        if (getPrevRoute) {
                            props.history.push(getPrevRoute);
                        } else {
                            props.history.push("/assessment/products");
                        }
                    }

                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_FAILURE, error } }
}




function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}