import React, { useState } from 'react';

const Product = ({ product, onAddToCart }) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div>
      <h2>{product.name}</h2>
      <p>{product.description}</p>
      <p>${product.price}</p>
      <div>
        <button onClick={() => onAddToCart(product, quantity)}>Add to Cart</button>
        <div>
          <button onClick={handleDecrement}>-</button>
          <span>{quantity}</span>
          <button onClick={handleIncrement}>+</button>
        </div>
      </div>
    </div>
  );
};

const Cart = ({ cartItems }) => {
  return (
    <div>
      <h2>Shopping Cart</h2>
      <ul>
        {cartItems.map((item, index) => (
          <li key={index}>
            {item.name} - Quantity: {item.quantity}
          </li>
        ))}
      </ul>
    </div>
  );
};

const App = () => {
  const [cart, setCart] = useState([]);

  const handleAddToCart = (product, quantity) => {
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += quantity;
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...product, quantity }]);
    }
  };

  return (
    <div>
      <h1>Online Store</h1>
      <Product
        product={{ id: 1, name: 'Product 1', description: 'Description 1', price: 10.99 }}
        onAddToCart={handleAddToCart}
      />
      <Product
        product={{ id: 2, name: 'Product 2', description: 'Description 2', price: 19.99 }}
        onAddToCart={handleAddToCart}
      />
      <Cart cartItems={cart} />
    </div>
  );
};

export default App;
