import React from 'react';

export default function Component(props) {

const { question, answersList, ansNPSInput, surveyLanguageCode, surveyScreenData } = props;
  // let [showValue, handleValue ] = React.useState(0)

// let showValue = false;
// function handleValue(){}


let [showValue2, handleValue2 ] = React.useState(111)

let showValue = false;
function handleValue(){}

function CustomLabels(optionID,optionName, question){
  let surveyLanguageCode = "customNPS";
  return  (surveyLanguageCode!=='')?
  (question && question.transl && 
    question.transl[surveyLanguageCode] && 
        question.transl[surveyLanguageCode][optionID])?
          question.transl[surveyLanguageCode][optionID]:optionName
  :optionName
}

  
function NPSName(optionID, optionName, surveyLanguageCode, question){

  return  (surveyLanguageCode!=='')?
  (question && question.transl && 
    question.transl[surveyLanguageCode] && 
      question.transl[surveyLanguageCode].optionsList && 
        question.transl[surveyLanguageCode].optionsList[optionID])?
          question.transl[surveyLanguageCode].optionsList[optionID]:optionName
  :optionName
}



function NPSColor(question){



  let Color = "#a3a3a3";
  let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
  let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
  if(showValue){
    answer = showValue;
  }

  
  if(answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 ){
    Color = "#2074e3";
    if(answer === 1 || answer === 2){
      Name = NPSName("Extremelydissatisfied", CustomLabels("Extremelydissatisfied", "Extremely dissatisfied",question), surveyLanguageCode, question)
    }
    else if(answer === 3 || answer === 4){
      Name = NPSName("Dissatisfied", CustomLabels("Dissatisfied", "Dissatisfied",question), surveyLanguageCode, question)
      console.log('Dissatisfied Name: ',Name)
    }
    else if(answer === 5 || answer === 6){
      Name = NPSName("Neither",  CustomLabels("Neither", "Neither",question), surveyLanguageCode, question)
    }
  }
  else if(answer === 7 || answer === 8){
    Color = "#2074e3";
    Name = NPSName("Satisfied", CustomLabels("Satisfied", "Satisfied",question), surveyLanguageCode, question)
  }
  else if(answer === 9 || answer === 10){
    Color = "#2074e3";
    Name = NPSName("Extremelysatisfied", CustomLabels("Extremelysatisfied", "Extremely Satisfied",question), surveyLanguageCode, question)
  }

  return {
    color:Color,
    name:Name
  }
}


function NPSColor2(question){



  let Color = "#a3a3a3";
  let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
  let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
  if(showValue2 || showValue2===0){
    answer = showValue2;
  }

  
  if(answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 ){
    Color = "#2074e3";
    if(answer === 1 || answer === 2){
      Name = NPSName("Extremelydissatisfied", CustomLabels("Extremelydissatisfied", "Extremely dissatisfied",question), surveyLanguageCode, question)
    }
    else if(answer === 3 || answer === 4){
      Name = NPSName("Dissatisfied", CustomLabels("Dissatisfied", "Dissatisfied",question), surveyLanguageCode, question)
      console.log('Dissatisfied Name: ',Name)
    }
    else if(answer === 5 || answer === 6){
      Name = NPSName("Neither", CustomLabels("Neither", "Neither",question), surveyLanguageCode, question)
    }
  }
  else if(answer === 7 || answer === 8){
    Color = "#2074e3";
    Name = NPSName("Satisfied", CustomLabels("Satisfied", "Satisfied",question), surveyLanguageCode, question)
  }
  else if(answer === 9 || answer === 10){
    Color = "#2074e3";
    Name = NPSName("Extremelysatisfied", CustomLabels("Extremelysatisfied", "Extremely Satisfied",question), surveyLanguageCode, question)
  }

  return {
    color:Color,
    name:Name
  }
}

let answerText = "";
if(answersList && answersList.length>0){
  let FindIndex = answersList.findIndex(item => item.questionId === question.id);
  answerText = answersList[FindIndex] && answersList[FindIndex].answer?answersList[FindIndex].answer:"";
}

return(
    <>

    <div className="p-4 flex justify-around text-blue-500 text-sm">


    {[...Array(10).keys()].map((value,index)=>
            <>
            {((value+1).toString()===answerText)?

              <div onMouseEnter={()=>handleValue2((value+1))}  onClick={()=>ansNPSInput((value+1).toString(),question)} key={index} 
              style={{background:NPSColor(question,surveyLanguageCode).color  ,color:'white'}} 
              className="w-6 h-6 rounded-md border border-blue-500 flex items-center justify-center cursor-pointer">
              {value+1}
              </div>

              :
            <>
            {(value+1) <= (showValue || parseInt(answerText))?
              <div onMouseEnter={()=>handleValue2((value+1))}  onClick={()=>ansNPSInput((value+1).toString(),question)} key={index} 
                style={{background:NPSColor(question,surveyLanguageCode).color  ,color:'white'}} 
                className="w-6 h-6 rounded-md border border-blue-500 flex items-center justify-center cursor-pointer">
                {value+1}
              </div>
            :
            <div onMouseEnter={()=>handleValue2((value+1))}  onClick={()=>ansNPSInput((value+1).toString(),question)} key={index} 
             className="w-6 h-6 rounded-md border border-blue-500 flex items-center justify-center cursor-pointer">
            {value+1}
              </div>
            }
            </>
            }
            </>
            )}


    </div>
          
    </>
  );}