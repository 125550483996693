import React from 'react'
import moment from "moment";

export default function AssessmentTop5(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, createdAt, EditButtonFn } = props;

  const cirleColor = (element) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD")
      }}
      className={"w-5 h-5 rounded-full "}
    />)
  }


  const barWidthColor = (element, max) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD"),
      }}
      className={
        "w-5 h-5 rounded-full "
      }
    />)
  }



  return (
    <>
      <main className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="px-12 space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">
              {EditButtonFn(
                "TalentAssessmentReport",
                "TalentPlusStrengths Assessment"
              )}
            </h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""} | {createdAt}
            </p>
          </div>
          {/* <div className="py-4 space-y-2">
            <h1 className="text-3xl font-medium text-[#212121]">{EditButtonFn("Part2", "Part 2")}</h1>
          </div> */}
          <div className="w-full space-y-1">
            <div className="w-full pb-4">
              <h1 className="text-3xl font-light text-[#212121]">
                <b>{EditButtonFn("DeepDive", "Deep dive into your TalentPlusStrengths themes")}</b>
              </h1>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>1. </p>
              <p className="mx-2">{EditButtonFn("top5list1", "Each theme is thoroughly explained, detailing typical behaviors observed in successful individuals with that theme.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>2.</p>
              <p className="mx-2"> {EditButtonFn("top5list2", "The talent is identified with a specific color representing its category: Leading, Acting, Relating, or Thinking.")}</p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>3.</p>
              <p className="mx-2">{EditButtonFn("top5list3", "The explanation provides a comprehensive understanding of the talent's manifestation, leaving room for further exploration based on personal observations.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>4.</p>
              <p className="mx-2">{EditButtonFn("top5list4", "Ideas and situations are provided to inspire the utilization of talents, serving as a starting point for brainstorming additional applications.")}
              </p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>5.</p>
              <p className="mx-2">{EditButtonFn("top5list5", "Acknowledging potential blind spots, the report highlights the importance of recognizing when overuse of a talent may be perceived as a weakness by others.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>6.</p>
              <p className="mx-2">{EditButtonFn("top5list6", "Guidance is offered on interacting with individuals possessing specific themes, emphasizing the importance of understanding and leveraging their talents for mutual benefit.")}</p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>7.</p>
              <p className="mx-2">{EditButtonFn("top5list7", "Reflective learning is encouraged, prompting individuals to connect their successes and experiences back to their talents as a foundational step.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>8.</p>
              <p className="mx-2">{EditButtonFn("top5list8", "Proactive learning is introduced as the next phase, guiding individuals to define goals and select appropriate talent themes to achieve them, while also leveraging complementary talents from others.")}</p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>9.</p>
              <p className="mx-2">{EditButtonFn("top5list9", " Understanding team talents is emphasized as essential for effective leadership and team engagement.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>10.</p>
              <p className="mx-2">{EditButtonFn("top5list10", "Patience and persistence are encouraged as individuals embark on their talent journey, with the assurance that embracing their natural gifts will lead to a thriving life.")}</p>
            </div>

          </div>

          {/* <p>Copyright 2024, HappyPlus, Inc. All rights reserved</p> */}
        </div>
      </main>

    </>
  )

}
