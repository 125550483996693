import React from "react";
import LanguageAssessmentComponent from "./LanguageAssessmentComponent";
import BackSummary from "../QuestionV6/Summary/Summary/ExpertsSummary/BackSummary";
export default function LogoAssessmentHeader(props) {
  const {
    surveyScreenData,
    backSummary,
    crrSelectedEmp,
    template,
    details,
    logout,
    handleReport,
    LanguageList,
    handleLanguage,
    surveyLanguageName,
    createdAt
  } = props;

  let [scrollPosition, HandlescrollPosition] = React.useState(0);

  let lastKnownScrollPosition = 0;
  document.addEventListener("scroll", () => {
    lastKnownScrollPosition = window.scrollY;
    HandlescrollPosition(lastKnownScrollPosition);
  });

  // console.log("surveyScreenData",surveyScreenData)
  let headerColor =
    surveyScreenData && surveyScreenData.headerColor
      ? surveyScreenData.headerColor
      : "";




  return (
    <>
      {(scrollPosition < 50 && template === "v4") || true ? (
        <div >

          <div className="flex items-center w-full h-20 border-b ">
            <div className="flex items-center justify-between w-full px-4 mx-auto xl:w-11/12 xl:px-0 ">
              <img
                src="/img/TalentPlusStrengths_logo.svg"
                className="bg-cover md:w-36 w-28"
              />

              <div className="flex items-center space-x-2">
                {LanguageList && LanguageList.length > 0 ? (
                  LanguageList.length > 0 ? (
                    <div className="relative cursor-pointer group">
                      <LanguageAssessmentComponent
                        langList={LanguageList}
                        handleLanguage={handleLanguage}
                        surveyLanguageName={surveyLanguageName}
                        template={"v4"}
                      />
                    </div>
                  ) : (
                    null
                  )
                ) : (
                  null
                )}

                <div className="flex items-center space-x-1 text-base cursor-pointer   text-[#3D405B] relative  group lg:w-auto  h-20   ">
                  <div className="flex items-center space-x-2">
                    <div className="md:text-lg text-base font-semibold text-[#212121]  h-12  w-12 bg-slate-100 rounded-full flex items-center justify-center uppercase">
                      {/* {details && details.name
                        ? details.name.slice(0, 2)
                        : "NA"} */}
                      {details && details.name
                        ? details.name.split(' ').map(name => name[0]).join('')
                        : "NA"}
                    </div>
                    <p className="md:text-base text-sm font-semibold text-[#212121] md:block hidden capitalize ">
                      {details && details.name ? details.name : "User"}
                    </p>
                  </div>
                  <span className="cursor-pointer material-icons text-[2rem] md:block hidden">
                    arrow_drop_down
                  </span>

                  <div className="w-full text-left  rounded-b-md absolute group-hover:block hidden  transition-all transform top-16 -right-1 opacity-0 group-hover:opacity-100 py-2   bg-white shadow-xl  duration-150 overflow-hidden text-[#3D405B] space-y-4 z-40  ">
                    <button
                      onClick={() => handleReport()}
                      className="md:text-base text-sm font-semibold text-[#212121] flex items-center cursor-pointer hover:bg-slate-100 px-4 py-2 w-full "
                    >
                      <span className="material-symbols-outlined mr-2">
                        lab_profile
                      </span>{" "}
                      {"Report"}
                    </button>

                    <button
                      onClick={() => logout()}
                      className="flex items-center w-full px-4 py-2 text-sm font-semibold text-red-500 cursor-pointer md:text-base hover:bg-slate-100 "
                    >
                      <span className="material-symbols-outlined mr-2 text-red-500">
                        logout
                      </span>{" "}
                      {"Logout"}
                    </button>
                  </div>


                </div>
              </div>
            </div>
          </div>

          {/* new element */}
          <div className="w-full px-4 mx-auto xl:w-11/12 xl:px-0">
            {!backSummary ?
              <div className="flex items-center justify-between py-2">
                {/* <div className="flex items-center space-x-2 lg:space-x-4">
                  <img src="https://i.pinimg.com/474x/98/51/1e/98511ee98a1930b8938e42caf0904d2d.jpg" className="flex-shrink-0 object-cover w-12 h-12 rounded-full lg:w-14 lg:h-14 " />
                  <h1 className="lg:text-xl text-base font-semibold text-[#212121]">

                    {crrSelectedEmp && crrSelectedEmp.name
                      ? crrSelectedEmp.name
                      : "Employee Name"}
                  </h1>
                </div> */}

                {/* <div className="flex items-center space-x-2">
                  <div className="md:text-lg text-base font-semibold text-[#212121]  h-12  w-12 bg-slate-100 rounded-full flex items-center justify-center uppercase">
                    {details && details.name
                      ? details.name.slice(0, 2)
                      : "NA"}
                  </div>
                  <p className="md:text-base text-sm font-semibold text-[#212121] md:block hidden capitalize ">
                    {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "User"}
                  </p>
                </div> */}



                {/* <div className="flex flex-col gap-1 text-sm font-medium text-right lg:text-lg lg:flex-row"><span className="opacity-70 ">Survey Taken on:</span> <span>{createdAt}</span></div> */}
              </div>
              : null}



          </div>
        </div>


      ) : null}

    </>
  );
}
