import React from "react";
export default function ProfileHeader(props) {
  const { details, logout } = props;

  return (
    <>
      {true ? (
        <div className="  w-full  bg-white border-b  h-20 flex items-center ">
          <div className="flex justify-between  xl:w-11/12 w-full mx-auto xl:px-0 px-4 items-center ">
            <img
              src="/img/talent_plus_logo.svg"
              className="md:w-36 w-28 bg-cover"
            />

            <div className="flex md:space-x-4 space-x-2  items-center cursor-pointer relative group ">
              <span className="material-symbols-outlined text-[3rem] text-[#2196f3] ">
                account_circle
              </span>
              <p className="md:text-base text-sm font-semibold text-[#3D405B] ">
                {details && details.name ? details.name : "User"}
              </p>

              <div className="bg-white  rounded-lg shadow-xl absolute top-12 right-0 border overflow-hidden py-4 w-36 group-hover:block hidden">
               

                <button
                  onClick={() => logout()}
                  className="md:text-base  text-sm font-semibold text-red-500 flex items-center cursor-pointer hover:bg-slate-100 px-4 py-2 w-full "
                >
                  <span className="material-symbols-outlined mr-2 text-red-500">
                    logout
                  </span>{" "}
                  {"Logout"}
                </button>
              </div>
 
            </div>
 
          </div>
        </div>
      ) : null}
    </>
  );
}
