export const questionsConstants = {

    LOGOUT: 'USERS_LOGOUT',

    QUESTIONS_GETALL_REQUEST: 'QUESTIONS_GETALL_REQUEST',
    QUESTIONS_GETALL_SUCCESS: 'QUESTIONS_GETALL_SUCCESS',
    QUESTIONS_GETALL_FAILURE: 'QUESTIONS_GETALL_FAILURE',

    QUESTIONS_LIST_GETALL_REQUEST: 'QUESTIONS_LIST_GETALL_REQUEST',
    QUESTIONS_LIST_GETALL_SUCCESS: 'QUESTIONS_LIST_GETALL_SUCCESS',
    QUESTIONS_LIST_GETALL_FAILURE: 'QUESTIONS_LIST_GETALL_FAILURE',

    ANSWERS_SUBMIT_REQUEST: 'ANSWERS_SUBMIT_REQUEST',
    ANSWERS_SUBMIT_SUCCESS: 'ANSWERS_SUBMIT_SUCCESS',
    ANSWERS_SUBMIT_FAILURE: 'ANSWERS_SUBMIT_FAILURE',

    ANSWERS_ASSESSMENT_SUBMIT_REQUEST: 'ANSWERS_ASSESSMENT_SUBMIT_REQUEST',
    ANSWERS_ASSESSMENT_SUBMIT_SUCCESS: 'ANSWERS_ASSESSMENT_SUBMIT_SUCCESS',
    ANSWERS_ASSESSMENT_SUBMIT_FAILURE: 'ANSWERS_ASSESSMENT_SUBMIT_FAILURE',

    GET_LANGUAGE_LABEL_REQUEST: 'GET_LANGUAGE_LABEL_REQUEST',
    GET_LANGUAGE_LABEL_SUCCESS: 'GET_LANGUAGE_LABEL_SUCCESS',
    GET_LANGUAGE_LABEL_FAILURE: 'GET_LANGUAGE_LABEL_FAILURE',

    GET_SURVEY_TEMPLATE_CODE_REQUEST: 'GET_SURVEY_TEMPLATE_CODE_REQUEST',
    GET_SURVEY_TEMPLATE_CODE_SUCCESS: 'GET_SURVEY_TEMPLATE_CODE_SUCCESS',
    GET_SURVEY_TEMPLATE_CODE_FAILURE: 'GET_SURVEY_TEMPLATE_CODE_FAILURE',

    GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_REQUEST: 'GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_REQUEST',
    GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_SUCCESS: 'GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_SUCCESS',
    GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_FAILURE: 'GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_FAILURE',


    GET_EMPLOYEE_STATUS_REQUEST: 'GET_EMPLOYEE_STATUS_REQUEST',
    GET_EMPLOYEE_STATUS_SUCCESS: 'GET_EMPLOYEE_STATUS_SUCCESS',
    GET_EMPLOYEE_STATUS_FAILURE: 'GET_EMPLOYEE_STATUS_FAILURE',

    GET_SURVEY_TEMPLATE_SURVEY_CODE_REQUEST: 'GET_SURVEY_TEMPLATE_SURVEY_CODE_REQUEST',
    GET_SURVEY_TEMPLATE_SURVEY_CODE_SUCCESS: 'GET_SURVEY_TEMPLATE_SURVEY_CODE_SUCCESS',
    GET_SURVEY_TEMPLATE_SURVEY_CODE_FAILURE: 'GET_SURVEY_TEMPLATE_SURVEY_CODE_FAILURE',
    
    GET_RESPONDANT_NAME_REQUEST: 'GET_RESPONDANT_NAME_REQUEST',
    GET_RESPONDANT_NAME_SUCCESS: 'GET_RESPONDANT_NAME_SUCCESS',
    GET_RESPONDANT_NAME_FAILURE: 'GET_RESPONDANT_NAME_FAILURE',

    GET_ASSESSMENT_EMPLOYEE_DETAILS_REQUEST: 'GET_ASSESSMENT_EMPLOYEE_DETAILS_REQUEST',
    GET_ASSESSMENT_EMPLOYEE_DETAILS_SUCCESS: 'GET_ASSESSMENT_EMPLOYEE_DETAILS_SUCCESS',
    GET_ASSESSMENT_EMPLOYEE_DETAILS_FAILURE: 'GET_ASSESSMENT_EMPLOYEE_DETAILS_FAILURE',

    GET_ACCESSMENT_REPORT_REQUEST: 'GET_ACCESSMENT_REPORT_REQUEST',
    GET_ACCESSMENT_REPORT_SUCCESS: 'GET_ACCESSMENT_REPORT_SUCCESS',
    GET_ACCESSMENT_REPORT_FAILURE: 'GET_ACCESSMENT_REPORT_FAILURE',

    GET_ACCESSMENT_REPORT_RTL_REQUEST: 'GET_ACCESSMENT_REPORT_RTL_REQUEST',
    GET_ACCESSMENT_REPORT_RTL_SUCCESS: 'GET_ACCESSMENT_REPORT_RTL_SUCCESS',
    GET_ACCESSMENT_REPORT_RTL_FAILURE: 'GET_ACCESSMENT_REPORT_RTL_FAILURE',
    
    GET_ACCESSMENT_STATUS_REQUEST: 'GET_ACCESSMENT_STATUS_REQUEST',
    GET_ACCESSMENT_STATUS_SUCCESS: 'GET_ACCESSMENT_STATUS_SUCCESS',
    GET_ACCESSMENT_STATUS_FAILURE: 'GET_ACCESSMENT_STATUS_FAILURE',

    GET_TRANSLATE_ELEMENT_REQUEST: 'GET_TRANSLATE_ELEMENT_REQUEST',
    GET_TRANSLATE_ELEMENT_SUCCESS: 'GET_TRANSLATE_ELEMENT_SUCCESS',
    GET_TRANSLATE_ELEMENT_FAILURE: 'GET_TRANSLATE_ELEMENT_FAILURE',

    EMPTY_REDUCER:'EMPTY_REDUCER',

            
    ASSESSMENT_EMP_SHORT_PRODUCT_REQUEST: 'ASSESSMENT_EMP_SHORT_PRODUCT_REQUEST',
    ASSESSMENT_EMP_SHORT_PRODUCT_SUCCESS: 'ASSESSMENT_EMP_SHORT_PRODUCT_SUCCESS',
    ASSESSMENT_EMP_SHORT_PRODUCT_FAILURE: 'ASSESSMENT_EMP_SHORT_PRODUCT_FAILURE',

        
    UPDATE_ASSESSMENT_DETAILS_REQUEST: 'UPDATE_ASSESSMENT_DETAILS_REQUEST',
    UPDATE_ASSESSMENT_DETAILS_SUCCESS: 'UPDATE_ASSESSMENT_DETAILS_SUCCESS',
    UPDATE_ASSESSMENT_DETAILS_FAILURE: 'UPDATE_ASSESSMENT_DETAILS_FAILURE',
            
    GET_SURVEY_TAKEN_DATE_REQUEST: 'GET_SURVEY_TAKEN_DATE_REQUEST',
    GET_SURVEY_TAKEN_DATE_SUCCESS: 'GET_SURVEY_TAKEN_DATE_SUCCESS',
    GET_SURVEY_TAKEN_DATE_FAILURE: 'GET_SURVEY_TAKEN_DATE_FAILURE',

    GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST: 'GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST',
    GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS: 'GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS',
    GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE: 'GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE',
};
