import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Keyboard, Autoplay, Scrollbar, A11y, loop } from 'swiper/modules';

export default function Top5Talents(props) {

  let { handleShowMainSummaryModal, EditDetailsFn, getAssessmentReportByEmpIDData, crrDetail,
    EditButtonFn, handleShowMainSummaryModalBack, handleOpenTop5Modal, handleCloseTop5Modal, isSchool } = props;

  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);
  const sliderRef3 = useRef(null);

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - Talent every day?"
        : "How to deal with a person who has - Talent?";
    let final = itemName.replace("-", element.dimension.trim());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      descNew = temp.split("@").filter((prev) => prev !== "");
    }
    return descNew;
  }

  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    return list;
  }


  function checkForGeneralship(bucket, dimension, isSchool) {
    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else {
        if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
          text = "old";
        }
      }

    }
    return text
  }

  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }


  return (
    <>
      <div className="w-full h-full bg-[#FAFAFA]">
        <div className="flex items-start justify-center">
          <div className="p-4 mx-auto overflow-hidden rounded-md 2xl:w-6/6 xl:w-6/6 lg:w-6/6">
            <div className="flex items-center py-4 space-x-4 border-b">
              <span className="p-2 text-white duration-150 bg-[#2196F3] rounded-full cursor-pointer " onClick={handleShowMainSummaryModalBack}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 6l-6 6l6 6" /></svg></span>
              <h1 className="py-2 text-lg font-medium md:text-xl text-[#3D405B]">Back to Summary</h1>
            </div>
            <div className='flex pt-5'>
              <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">
                {
                  isSchool ? ("Top 5 Teen Talents") : ("Top 5 Talents")
                }
              </h1>
            </div>
            <div className='flex w-full my-5 space-x-3 overflow-x-auto whitespace-nowrap'>
              {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                getAssessmentReportByEmpIDData.map((element, index) =>
                  (index < getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) ?
                    (crrDetail.dimension === element.dimension) ?
                      <div
                        style={{
                          background:
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                        }}
                        className='flex items-center px-4 py-2 space-x-2 border rounded-full cursor-pointer'>
                        <div className='bg-white rounded-full '>
                          <img src={
                            "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                            ((element.dimension.replace(' ', '_')).toLowerCase()) +
                            ".svg"
                          } alt='dimension' className='w-8 h-8' />
                        </div>
                        <h1 className="flex text-base font-medium text-white md:text-lg flex-nowrap ">{index + 1}. {EditDetailsFn(element, 'dimension', element.dimension)}</h1>
                      </div>
                      :
                      <div onClick={() => handleShowMainSummaryModal(element)} className='flex items-center px-4 py-2 bg-white border rounded-full cursor-pointer' >
                        <div
                          style={{
                            background: hexToRgbA(
                              element && element.colorCode
                                ? element.colorCode
                                : "#DDDDDD",
                              "0.20"
                            ),
                          }}
                          className='mr-3 bg-[#673AB7]/10 rounded-full'>
                          <img src={
                            "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                            ((element.dimension.replace(' ', '_')).toLowerCase()) +
                            ".svg"
                          } alt='dimension' className='w-9 h-9' />
                        </div>
                        <h1 className="flex md:text-lg text-base text-[#3D405B] flex-nowrap font-medium ">{index + 1}. {EditDetailsFn(element, 'dimension', element.dimension)}</h1>
                      </div>
                    : null) : null}
            </div>

            {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
              getAssessmentReportByEmpIDData.map((element, index) =>
                (index < getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) ?
                  (crrDetail.dimension === element.dimension) ?
                    <>

                      {element && element.bucket && (<>
                        <div
                          style={{
                            background: hexToRgbA(
                              element && element.colorCode
                                ? element.colorCode
                                : "#DDDDDD",
                              "0.20"
                            ),
                          }}
                          className={
                            "cursor-pointer rounded-lg 2xl:px-8 md:py-4 p-4 md:flex justify-between  items-center md:space-x-6 md:space-y-0 space-y-4 "
                          }
                        >
                          <div className="2xl:space-y-4 space-y-2 md:w-[30%]">
                            <button
                              style={{
                                background:
                                  element && element.colorCode
                                    ? element.colorCode
                                    : "#DDDDDD",
                              }}
                              className={
                                " px-4 py-2 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest  rounded-full "
                              }
                            >
                              {EditDetailsFn(element, 'bucket', element.bucket)}
                            </button>
                            <h1 className="text-xl font-medium 2xl:text-2xl text-slate-900 md:px-4">
                              <span>{index + 1}. </span>{EditDetailsFn(element, 'dimension', element.dimension)}</h1>
                          </div>

                          <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end ">
                            <p className="flex-1 text-sm font-normal md:text-base text-slate-600 ">
                              {EditDetailsFn(element, 'desc', element.details.desc)}
                            </p>

                            <div className="w-16 xl:w-28 md:w-20">
                              <img
                                src={
                                  "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                                  ((element.dimension.replace(' ', '_')).toLowerCase()) +
                                  ".svg"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>)
                      }


                      {/* how to use section */}
                      <div className='pt-5 space-y-6'>
                        {getBulletPoints(element, "desc3") &&
                          getBulletPoints(element, "desc3").length > 0
                          ? chunkList(getBulletPoints(element, "desc3")).map(
                            (textList) => (
                              <div className='pt-5'>
                                <div className="flex items-start mb-4 space-x-4">
                                  <img src={
                                    "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                                    ((element.dimension.replace(' ', '_')).toLowerCase()) +
                                    ".svg"
                                  } alt='Group 17207 (3).svg' className='w-9 h-9' />
                                  <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">  {EditButtonFn(getTitle(element, 1), getTitle(element, 1))}</h1>
                                </div>

                                {textList && textList.length > 0
                                  ? textList.map((text, index6) => (
                                    <div style={{ borderColor: element && element.colorCode ? element.colorCode : "#DDDDDD", }} className="h-auto bg-white border-l-8 border-blue-500 rounded-lg shadow-md hover:shadow-xl">
                                      <div className="px-6 py-8 mb-4">
                                        <p className="text-base text-[#3D405B]">{EditDetailsFn(element, "desc3" + (index6 + 1), text)} </p>
                                      </div>
                                    </div>
                                  ))
                                  : null}
                                {/* <Swiper

                                  ref={sliderRef1}
                                  navigation={true}
                                  keyboard={true}
                                  loop={true}
                                  autoplay={true}
                                  modules={[Autoplay, Navigation, Keyboard]}
                                  spaceBetween={20}
                                  // slidesPerView={4}
                                  breakpoints={{
                                    640: {
                                      slidesPerView: 1,
                                      spaceBetween: 20,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                      spaceBetween: 20,
                                    },
                                    1024: {
                                      slidesPerView: 3,
                                      spaceBetween: 20,
                                    },
                                    1366: {
                                      slidesPerView: 4,
                                      spaceBetween: 20,
                                    },
                                  }}
                                  className="mySwiper">
                                </Swiper> */}
                              </div>
                            )
                          )
                          : null}

                        <div>

                          <div className="flex items-start mb-4 space-x-4">
                            <img src={
                              "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                              ((element.dimension.replace(' ', '_')).toLowerCase()) +
                              ".svg"
                            } alt='Group 17207 (3).svg' className='w-9 h-9' />
                            <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">{EditButtonFn(
                              "What needs to be looked out for_Possible Blind Spots?",
                              "What needs to be looked out for (Possible Blind Spots)?"
                            )}</h1>
                          </div>

                          {getBulletPoints(element, "desc4") &&
                            getBulletPoints(element, "desc4").length > 0
                            ? getBulletPoints(element, "desc4").map(
                              (text, index) =>
                                <div style={{ borderColor: element && element.colorCode ? element.colorCode : "#DDDDDD", }}
                                  className="h-auto bg-white border-l-8 border-blue-500 rounded-lg shadow-md hover:shadow-xl">
                                  <div className="px-6 py-8 mb-4">
                                    <p className="text-base text-[#3D405B]">{EditDetailsFn(
                                      element,
                                      "desc4" + (index + 1),
                                      text
                                    )}</p>
                                  </div>
                                </div>
                            ) : null}
                          {/* <Swiper
                            ref={sliderRef2}
                            navigation={true}
                            keyboard={true}
                            loop={true}
                            autoplay={true}
                            modules={[Autoplay, Navigation, Keyboard]}
                            spaceBetween={20}
                            // slidesPerView={4}
                            breakpoints={{
                              640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                              768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                              1366: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                              },
                            }}
                            className=" mySwiper">
                            {getBulletPoints(element, "desc4") &&
                              getBulletPoints(element, "desc4").length > 0
                              ? getBulletPoints(element, "desc4").map(
                                (text, index) =>

                                  
                              ) : null}
                          </Swiper> */}
                        </div>

                        <div>
                          {getBulletPoints(element, "desc5") &&
                            getBulletPoints(element, "desc5").length > 0
                            ? chunkList(getBulletPoints(element, "desc5")).map(
                              (textList, index5) => (
                                <>
                                  <div className="flex items-start py-4 space-x-4">
                                    <img src={
                                      "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                                      ((element.dimension.replace(' ', '_')).toLowerCase()) +
                                      ".svg"
                                    } alt='Group 17207 (3).svg' className='w-9 h-9' />
                                    <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">
                                      {EditButtonFn(
                                        getTitle(element, 2),
                                        getTitle(element, 2)
                                      )}</h1>
                                  </div>

                                  {textList && textList.length > 0
                                    ? textList.map((text, index6) => (
                                      <div style={{
                                        borderColor:
                                          element && element.colorCode
                                            ? element.colorCode
                                            : "#DDDDDD",
                                      }}
                                        className="h-auto bg-white border-l-8 border-blue-500 rounded-lg shadow-md hover:shadow-xl">
                                        <div className="px-6 py-8 mb-4">
                                          <p className="text-base text-[#3D405B]">
                                            {EditDetailsFn(
                                              element,
                                              "desc5" + (index6 + 1),
                                              text
                                            )}</p>
                                        </div>
                                      </div>

                                    ))

                                    : null}

                                  {/* <Swiper
                                    ref={sliderRef3}
                                    navigation={true}
                                    keyboard={true}
                                    loop={true}
                                    autoplay={true}
                                    modules={[Autoplay, Navigation, Keyboard]}
                                    spaceBetween={20}
                                    // slidesPerView={4}
                                    breakpoints={{
                                      640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                      },
                                      1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                      },
                                      1366: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                      },
                                    }}
                                    className=" mySwiper">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <SwiperSlide style={{
                                          borderColor:
                                            element && element.colorCode
                                              ? element.colorCode
                                              : "#DDDDDD",
                                        }}
                                          className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg  h-auto cursor-pointer">
                                          <div className="px-6 py-8">
                                            <p className="text-base text-[#3D405B]">
                                              {EditDetailsFn(
                                                element,
                                                "desc5" + (index6 + 1),
                                                text
                                              )}</p>
                                          </div>
                                        </SwiperSlide>

                                      ))

                                      : null}
                                  </Swiper> */}
                                </>
                              )
                            )
                            : null}
                        </div>
                      </div>
                    </>
                    : null : null) : null}
          </div>
        </div>
      </div>
    </>
  );
}
