import React from 'react';
// import './styles.css';

export default function Component(props) {

  const { question, answersList, nextQues, prevQues, surveyLanguageCode, handleRadio, clicked, surveyScreenData } = props;

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        {question && question.optionsList && question.optionsList.length > 0 ?
          question.optionsList.map((option, optionindex) =>
            <>
              <div key={optionindex} onClick={() => handleRadio(optionindex, question, option)}

                className={(((answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id)) ? "border border-[#2196F3] bg-[#2196F3]/10 text-[#2196F3]" : "border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 text-[#212121] hover:text-[#2196F3]") + "   p-4 py-2 rounded-md flex justify-between items-center cursor-pointer  transition-all duration-100 relative"}>
                <span className="font-medium">{(surveyLanguageCode !== '') ?
                  (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ? question.transl[surveyLanguageCode].optionsList[option.id] : option.name : option.name}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" className="opacity-0 pointer-events-none svgone icon icon-tabler icons-tabler-outline icon-tabler-check">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
              </div>


              {/* Old Button  */}
              {/* <button
                key={optionindex}
                style={{
                  margin: '7px',
                  borderWidth: '2px',
                  color: (answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ? surveyScreenData && surveyScreenData.OptionColor2 ? surveyScreenData.OptionColor2 : 'black' : (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : "#757575",
                  background: (answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ? (surveyScreenData && surveyScreenData.OptionColor3) ? surveyScreenData.OptionColor3 : "#757575" : (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#757575',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
                onClick={() => handleRadio(optionindex, question, option)}
                className="w-full p-2 text-center rounded-lg bg-white-1t md:text-md text-md " >{(surveyLanguageCode !== '') ? (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?  question.transl[surveyLanguageCode].optionsList[option.id] : option.name : option.name}</button> */}


              {/* New button design */}


              {/* <div className='grid grid-cols-1 gap-3'>
                <div className="border border-[#2196F3] hover:border-[#2196F3] hover:bg-[#2196F3]/10 bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-between items-center cursor-pointer text-[#2196F3] hover:text-[#2196F3] transition-all duration-100" >
                  <span className="font-medium ">Dislike</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                </div>
                <div className="border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-between items-center cursor-pointer text-[#212121] hover:text-[#2196F3] transition-all duration-100  " >
                  <span className="font-medium ">Slightly Dislike</span>
                </div>
                <div className="border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-between items-center cursor-pointer text-[#212121] hover:text-[#2196F3] transition-all duration-100  " >
                  <span className="font-medium ">Neutral</span>
                </div>
                <div className="border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-between items-center cursor-pointer text-[#212121] hover:text-[#2196F3] transition-all duration-100  " >
                  <span className="font-medium ">Slightly Like</span>
                </div>
                <div className="border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-between items-center cursor-pointer text-[#212121] hover:text-[#2196F3] transition-all duration-100  " >
                  <span className="font-medium ">Like</span>
                </div>
                <div className="border border-[#212121] hover:border-[#2196F3] hover:bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-start space-x-3 items-center cursor-pointer  hover:text-[#2196F3] transition-all duration-100" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 40 40" fill="none">
                    <path d="M33.332 13.333H23.9787L25.8504 7.72134C26.187 6.70801 26.017 5.58467 25.392 4.71801C24.767 3.85134 23.7537 3.33301 22.6854 3.33301H19.9987C19.5037 3.33301 19.0354 3.55301 18.717 3.93301L10.8837 13.333H6.66536C4.82703 13.333 3.33203 14.828 3.33203 16.6663V31.6663C3.33203 33.5047 4.82703 34.9997 6.66536 34.9997H28.8437C29.5218 34.9974 30.1833 34.7894 30.7407 34.4031C31.2981 34.0169 31.7251 33.4705 31.9654 32.8363L36.5604 20.5847C36.6301 20.3975 36.6657 20.1994 36.6654 19.9997V16.6663C36.6654 14.828 35.1704 13.333 33.332 13.333ZM6.66536 16.6663H9.9987V31.6663H6.66536V16.6663ZM33.332 19.698L28.8437 31.6663H13.332V15.603L20.7787 6.66634H22.6887L20.0854 14.4713C20.0009 14.7219 19.9773 14.9889 20.0166 15.2504C20.0559 15.5118 20.157 15.7602 20.3115 15.9747C20.4659 16.1893 20.6693 16.364 20.9048 16.4843C21.1402 16.6046 21.401 16.667 21.6654 16.6663H33.332V19.698Z" fill="#212121" />
                  </svg>
                  <span className="font-medium ">Dislike</span>

                </div>
                <div className="border border-[#2196F3]  hover:border-[#2196F3] bg-[#2196F3]/10 hover:bg-[#2196F3]/10 p-4 py-2.5 rounded-md flex justify-start space-x-3 items-center cursor-pointer text-[#2196F3] hover:text-[#2196F3] transition-all duration-100 " >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 40 40" fill="none">
                    <path d="M6.66536 5.00033H8.33203V26.667H6.66536C5.78131 26.667 4.93346 26.3158 4.30834 25.6907C3.68322 25.0656 3.33203 24.2177 3.33203 23.3337V8.33366C3.33203 7.4496 3.68322 6.60176 4.30834 5.97664C4.93346 5.35152 5.78131 5.00033 6.66536 5.00033ZM33.332 26.667H21.6654L23.5354 32.2803C23.7022 32.7813 23.7477 33.3147 23.668 33.8367C23.5883 34.3587 23.3857 34.8543 23.077 35.2827C22.7683 35.711 22.3622 36.0599 21.8922 36.3006C21.4222 36.5413 20.9017 36.6669 20.3737 36.667H19.9987L11.6654 27.6037V5.00033H29.9987L36.5187 19.327L36.6654 20.0003V23.3337C36.6654 24.2177 36.3142 25.0656 35.6891 25.6907C35.0639 26.3158 34.2161 26.667 33.332 26.667Z" fill="#2196F3" />
                  </svg>
                  <span className="font-medium ">Like</span>
                </div>

              </div> */}

            </>
          ) : null}

        {/* <div className="flex items-center justify-center mt-8 space-x-4">
          <button onClick={() => prevQues(2)} className="uppercase md:text-xl  text-base font-medium rounded-md relative group px-10 py-3 cursor-pointer   border border-[#212121] text-[#212121] overflow-hidden"><span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[20rem] group-hover:h-[20rem] opacity-10"></span>{"Previous"}</button>
          <button onClick={() => nextQues(2)} className="uppercase md:text-xl  text-base font-medium rounded-md relative group px-10 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"><span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[20rem] group-hover:h-[20rem] opacity-10"></span>{"Next"}</button>
        </div> */}
      </div>
    </>
  );
}