import React, { Component } from 'react';
import { connect } from 'react-redux';
import { questionsActions } from '../../_actions';
import QuestionV6 from './QuestionV6/questionsV6';
import QuestionV7TeenCareer from './QuestionV7TeenCareer/QuestionV7TeenCareer';

class AssessmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces: false,

      surveyLanguageName: 'English',
      surveyLanguageCode: '',
      ShowSubmitButton: false,


      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn: false,
      afterSubmitData: {
        "thankyouText": '',
        "bgColor": '',
        "bgImage": "",
        "textColor": "",
        "logo": ""
      },
      alreadySubmitted: false,
      clicked: false,

      showDropdownQues: false,
      dropdownInput: "",
      surveyLanguageRTL: false
    }
  }

  componentDidMount() {
    this.props.dispatch(questionsActions.getAssessmentSurveyTemplateByCode());
    this.props.dispatch(questionsActions.getAssessmenrEmployeeDetails({}));

  }



  static getDerivedStateFromProps(nextProps) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted: true
      }
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }




  // handleLanguage=(item, langList )=>{
  //   console.log("item------------------->",item)
  //   if(item.shortName === 'en'){
  //     this.setState({surveyLanguageCode:item.shortName, surveyLanguageName: 'English', surveyLanguageRTL:false});
  //   }
  //   else{
  //     this.setState({surveyLanguageCode:item.shortName, surveyLanguageName: item.name, surveyLanguageRTL:item.isRTL});
  //   }
  // }


  onLogout = () => {
    questionsActions.assessmentSurveyLogout(this.props);
  }


  handleTestPayment = (url) => {
    console.log("url------.", url)
    const { history } = this.props;
    history.push(`/assessment/` + "expert-booked/" + url);
  }


  viewMyExpert = (url) => {
    console.log("url------.", url)
    const { history } = this.props;
    history.push(`/assessment/` + "survey");
  }

  render() {
    let { questions } = this.props;

    let { getSurveyTemplateByCodeData, surveyScreenData, getRespondantNameData } = questions;

    let UserRespondantName = getRespondantNameData && getRespondantNameData.name ? getRespondantNameData.name : "Respondant";

    let bgImage = '';
    let bgColor = '';

    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;

    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = []
    let instructionTextDislay = []


    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = '';
    let bgType = '';

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );


    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);


    //console.log("surveyStatus: ", surveyStatus);

    //console.log("LanguageList: ", LanguageList);

    //console.log("surveyScreenData: ", surveyScreenData);


    let designTemplate = '';
    let langList = [{
      "name": "English",
      "shortName": "en"
    }];
    if (getSurveyTemplateByCodeData) {
      designTemplate = getSurveyTemplateByCodeData.designTemplate;
      if (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.langList && getSurveyTemplateByCodeData.langList.length > 0) {
        getSurveyTemplateByCodeData.langList.forEach((item) => {
          langList.push(item);
        });
      }
    }
    else {
      // designTemplate = 'v4'
    }


    //console.log('langList:',langList)

    let codeValue = this.props.match.params.id;


    //console.log("codeValue:------------> ",codeValue)
    //console.log("langList:------------> ",langList)




    return (
      <div
        style={{
          backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#FAFAFA" : "#FAFAFA",
          backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : "#FAFAFA" : "#FAFAFA",
        }}
      >

        {/* {langList && langList.length>0?
          langList.length>1?
  
          <div className="mt-16">
            <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} template={'v4'}/>
          </div>
          :
          <div className=""/>
        :<div className=""/>} */}


        <QuestionV7TeenCareer viewMyExpert={this.viewMyExpert} handleTestPayment={this.handleTestPayment} onLogout={this.onLogout} langList={langList} codeValue={codeValue} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />



      </div>);



  }
}
// MainPage.defaultProps = {
//   center: { lat: 26.953021, lng: 75.739797 },
//   zoom: 15
// };
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(AssessmentPage);
