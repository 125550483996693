import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

   const textColor = '#212121'

   return (
      <>
         <div className="pt-1 pl-4" >
            <div style={{ width: "400px", height: "250px" }}>
               <ReactSpeedometer
                  ringWidth={47}
                  fluidWidth={true}
                  minValue={0}
                  maxValue={100}
                  value={60}
                  needleColor="steelblue"
                  textColor={textColor}
                  segments={1}
                  segmentColors={["#64B5F6"]}
               />
            </div>
         </div>

      </>
   );
}
