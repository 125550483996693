 

import React, { Component } from 'react';

import { connect } from 'react-redux';

import LogoHeader from '../Component/LogoHeader';

import { questionsActions } from '../../../_actions';


class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emp_id: "",
      notFoundText:""
    }
  }

  componentDidMount() {


    

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questions.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!"

      }
    }
    if (nextProps.questions.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!"

      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  handleEmpInput=(e)=>{
    this.setState({emp_id: e.target.value});
  }

  submitEmpId=()=>{
    let { emp_id } = this.state;
    if(emp_id){
      this.props.dispatch(questionsActions.getEmployeeStatus({emp_id:emp_id}, this.props));
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitEmpId()
    }
  }

  
  render() {

    let { questions } = this.props;
    let { getEmployeeStatusData, employeeMessage } = questions;
    let { emp_id , notFoundText} = this.state;
    console.log("employeeMessage: ",employeeMessage)

    let surveyScreenData = {
      AlreadyText: "Survey Is Already Submitted !",
      ContinueButtonText: "Continue",
      ExpiryText: "This Survey is Expired!",
      OptionColor1: "#ffffff",
      OptionColor2: "#000000",
      OptionColor3: "#0770e9",
      StartButtonText: "Start Survey",
      SubmitButtonText: "Submit",
      WriteHereText: "Write here",
      bgColor: "#ffffff",
      bgImage: "",
      bgType: 1,
      buttonColor1: "#0069e4",
      buttonColor2: "#ffffff",
      designTemplate: "v4",
      langList:[],
      logo: "img/companylogo/kotaklogo.png",
      logo2: "img/companylogo/umatters.png",
      name: "UMatter - Employee Engagement Survey",
      okButtonText: "OK",
      textColor: "#262626",
      transl: {}
    }
    
  return (
      <>

      <LogoHeader
        surveyScreenData={surveyScreenData}
        template={'v4'}
      />

    <div>

      <div className="mt-24">
        <img src={surveyScreenData.logo} style={{height:"70px"}} className="mx-auto lg:my-8 my-4" />
        <h1 className="text-center md:text-xl text-lg font-bold capitalize px-6">
          {"Welcome to the "}
          {(surveyScreenData && surveyScreenData.name)? surveyScreenData.name:""
          }{"!"}
        </h1>
        <div className="bg-white shadow-xl md:max-w-md  md:mx-auto mx-6 md:my-6 my-4 p-10  ">
          <label className>Person No:</label>  <br />
          <input onKeyDown={this._handleKeyDown} autocomplete="off" type="text" value={emp_id} name="emp_id" onChange={this.handleEmpInput} 
          placeholder="Enter here..." className="border w-full p-2 text-sm focus:outline-none ring-blue-500 focus:ring-1 mt-4" />
          {notFoundText==="Employee not found!"?
          <>
          <p className="pt-2 text-red-500">{employeeMessage}</p>
          <button onClick={()=>this.submitEmpId()} className="bg-blue-500 text-white px-4 py-2 w-full mt-3 hover:bg-blue-600 ">Submit</button>
          </>
          :
          <>
          <button onClick={()=>this.submitEmpId()} className="bg-blue-500 text-white px-4 py-2 w-full mt-6 hover:bg-blue-600 ">Submit</button>
          </>}


        </div>
        <div className="text-center text-gray-500 mt-8">
          <p className="xl:max-w-md max-w-lg mx-auto text-sm px-4 pb-4">
            {"The survey is CONFIDENTIAL. All data will be directly collected & processed by HappyPlus. No one in Aster will have access to individual inputs/feedback."}
          </p>
        </div>
        
        
        <div className="fixed bottom-0  left-0 w-full flex  justify-center bg-white bg-opacity-100">
          <div>
            {/* btn */}

            <p 
            style={{
              backgroundColor:"#fff",
              // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
            }}
            className="text-sm py-4"> © Powered by <b className="font-semibold">happyPlus</b></p>
          </div>
      </div>


      </div>

    </div>






      </>
      );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(QuestionPublish);
