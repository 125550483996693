
import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions, questionsActions } from "../../../_actions";
import moment from "moment";
import { CONST } from "../../../_config";
import * as XLSX from 'xlsx';
import AssessmentReport from "../../Surveylink/Component/AssessmentReport/AssessmentReport";
import AssessmentSummary from "../../Surveylink/Component/AssessmentSummary/AssessmentSummary";
import AssessmentManagerReport from "../../Surveylink/Component/AssessmentManagerReport/AssessmentManagerReport";
import AssessmentHiringReport from "../../Surveylink/Component/AssessmentHiringReport/AssessmentHiringReport";
import Footer from "../Home/Component/Footer";
// import Header from "../Home/Component/Header";4
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import NewUserModal from "./Modal/NewUserModal";
import UpdateNewUserModal from "./Modal/UpdateNewUserModal";
import DeleteUserModal from "./Modal/DeleteUserModal";
import NewAssignAssessmentCodes from "./Modal/NewAssignAssessmentCodes";
import ViewAssessentCodes from "./Modal/ViewAssessentCodes";


import Ready from "../../../components/Ready";
import { FiPlusCircle } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { LiaFileExcel } from "react-icons/lia";
import { MdOutlineEdit } from "react-icons/md";
// import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import ReactPaginate from 'react-paginate';

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeIdList: [],
      offset: 0,
      updateNewEmpFormData: {},
      updateNewEmpFormDataError: {},
      newEmpFormData: {},
      newEmpFormDataError: {},
      email: "",
      userId: "",
      deleteUserModal: false,
      isDropdownOpen: false,
      showEmployeeModal: false,
      showUpdateEmployeeModal: false,
      showAssessmentCodesModal: false,
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 6,
      drowdown: true,
      viewCode: true,
      modaleOpen: false,
      isEditing: false,

      offset: 0,
      page: 0,
      size: 10,

      formNameData: {
        "id": "",
        "name": ""
      },
      crrPackId: "",
      showReportModal: false,
      showSummaryReport: false,
      surveyLanguageCode: '',
      themeType: 2,
      crrEmpDetails: {
        "emp_id": "",
        "name": ""
      },
      selectedValue: '1',
      formUserData: {
        "name": "",
        "email": "",
        "country": "",
        "postcode": "",
        "mobile": "",
        "organisation": ""
      },
      showManagerReportModal: false,
      showHiringReport: false,
      saveSuccess: false,
      viewAssessmentCodesModal: false
    };
    this.dropdownRef = React.createRef();
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.ViewCode = this.ViewCode.bind(this);
    this.drowDown = this.drowDown.bind(this);
    this.ModalOpen = this.ModalOpen.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    this.props.dispatch(assessmentActions.getOrderBookList());

    // let datatempemployee = {
    //   keyWord: "",
    //   pageNo: 1,
    //   size: this.state.employeesize2,
    // };
    this.props.dispatch(assessmentActions.getRoleUsersList({
      keyWord: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    }));

    window.scrollTo(0, 0);
  }

  ModalOpen = () => {
    this.setState({ modaleOpen: !this.state.modaleOpen });
  };
  ViewCode = () => {
    this.setState({ viewCode: !this.state.viewCode });
  };

  drowDown = () => {
    this.setState({ drowdown: !this.state.drowdown });
  };

  handleTabClick = (tabIndex, temp) => {
    this.setState({ activeTab: tabIndex, }, () => {
      if (tabIndex === 5) {
        let data = {
          // "id": temp & temp.id ? temp.id : ""
          "id": temp.id
        }
        console.log("temp-1->", temp)
        this.setState({ crrPackId: temp.id })
        this.props.dispatch(assessmentActions.userAssessmentOrders(data));
        this.props.dispatch(assessmentActions.userAssessmentResponseAnalytics(data));
      }
      else if (tabIndex === 2) {
        let { assessment } = this.props;
        let { userAssessmentUserProfileData } = assessment;
        let data = {
          "name": userAssessmentUserProfileData && userAssessmentUserProfileData.name ? userAssessmentUserProfileData.name : "",
          "email": userAssessmentUserProfileData && userAssessmentUserProfileData.email ? userAssessmentUserProfileData.email : "",
          "country": userAssessmentUserProfileData && userAssessmentUserProfileData.country ? userAssessmentUserProfileData.country : "",
          "postcode": userAssessmentUserProfileData && userAssessmentUserProfileData.postcode ? userAssessmentUserProfileData.postcode : "",
          "mobile": userAssessmentUserProfileData && userAssessmentUserProfileData.mobile ? userAssessmentUserProfileData.mobile : "",
          "organisation": userAssessmentUserProfileData && userAssessmentUserProfileData.organisation ? userAssessmentUserProfileData.organisation : ""
        }
        this.setState({ formUserData: data })
      } else {
        this.setState({ crrPackId: "" })
      }

      if (tabIndex === 2) {
        this.props.history.push(`/assessment/` + "profile-summary");
      } else if (tabIndex === 2) {
        this.props.history.push(`/assessment/` + "profile-summary");
      } else if (tabIndex === 3) {
        this.props.history.push(`/assessment/` + "my-orders");
      } else if (tabIndex === 4) {
        this.props.history.push(`/assessment/` + "assessment-code");
      } else if (tabIndex === 5) {
        this.props.history.push(`/assessment/` + "user");
      }

    });
  };

  // TabClick = (tabIndex) => {
  //   this.setState({ activeTab: tabIndex });
  // };


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentMyCartSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    }
    if (nextProps.assessment.createUserSucess) {
      return {
        ...nextProps,
        showEmployeeModal: false
      };
    }
    if (nextProps.assessment.updateUserSucess) {
      return {
        ...nextProps,
        showUpdateEmployeeModal: false
      };
    }
    else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem('route')
    localStorage.setItem('route', this.props.history.location.pathname)
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    this.props.history.push(`/assessment/` + field);
  };

  onEditSingleName = (element) => {
    let { formNameData } = this.state;
    formNameData = {
      "id": element.id,
      "name": element.name
    };
    this.setState({ showNameEdit: true, formNameData })
  }

  onSingleNameInput = (value, element) => {
    let { formNameData } = this.state;
    formNameData = {
      "id": element.id,
      "name": value
    };
    this.setState({ formNameData })
  }

  onSingleNameSave = () => {
    let { formNameData, crrPackId } = this.state;
    let data = {
      "id": formNameData.id,
      "name": formNameData.name,
    }
    let temp = {
      "id": crrPackId
    }
    this.props.dispatch(assessmentActions.userAssessmentOrderNameUpdate(data, temp))
    formNameData = {
      "id": "",
      "name": ""
    };
    this.setState({ showNameEdit: false })
  }

  viewReport = (ele) => {
    console.log("ele 1-->", ele);

    if (ele && ele.productId && ele.productId.code) {
      let code = ele.productId.code;

      if (code === "Top5TalentplusStrengths") {
        console.log("Top5TalentplusStrengths 1-->");
        this.handleSummaryReport(ele);
      } else if (code === "TalentPlusStrengths21") {
        console.log("TalentPlusStrengths21 1-->");
        this.handleReport(ele);
      } else if (code === "TalentplusManagerStrengths") {
        console.log("TalentplusManagerStrengths 1-->");
        this.handleManagerReport(ele);
      } else if (code === "TalentPlusHiringStrengths") {
        console.log("TalentPlusHiringStrengths 1-->");
        this.handleHiringReport(ele);
      }
    }


    let temp = {
      "emp_id": ele.emp_id,
      "name": ele.name
    }
    this.setState({ crrEmpDetails: temp })
  }

  handleReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showReportModal: true });
  };

  handleSummaryReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showSummaryReport: true });
  }

  handleManagerReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showManagerReportModal: true });
  }

  handleHiringReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showHiringReportModal: true });
  }

  closeReportModal = () => {
    this.setState({ showReportModal: false });
  };

  closeSummaryReportModal = () => {
    this.setState({ showSummaryReport: false });
  };

  closeManagerReportModal = () => {
    this.setState({ showManagerReportModal: false });
  };

  closeHiringReportModal = () => {
    this.setState({ showHiringReportModal: false });
  };

  handleLanguage = (item, langList) => {
    console.log("item------------------->", item)
    if (item.shortName === 'en') {
      this.setState({ surveyLanguageCode: item.shortName, surveyLanguageName: 'English', surveyLanguageRTL: false });
    }
    else {
      this.setState({ surveyLanguageCode: item.shortName, surveyLanguageName: item.name, surveyLanguageRTL: item.isRTL });
    }
  }

  handleDropdownChange(event) {
    const activeTab = event.target.value;

    if (Number(activeTab) === 2) {
      this.props.history.push(`/assessment/` + "profile-summary");

    } else if (Number(activeTab) === 2) {
      this.props.history.push(`/assessment/` + "profile-summary");

    } else if (Number(activeTab) === 3) {
      this.props.history.push(`/assessment/` + "my-orders");

    } else if (Number(activeTab) === 4) {
      this.props.history.push(`/assessment/` + "assessment-code");

    } else if (Number(activeTab) === 6) {
      this.props.history.push(`/assessment/` + "user");
    }

    this.setState({
      activeTab: Number(activeTab)
    });
  }

  handleDownloadSample = () => {
    let binary_univers = [
      {
        "name": "john"
      }
    ];

    //console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'namefile.xlsx');
  }


  updateUserDetails = () => {
    let { formUserData } = this.state;
    setTimeout(() => {
      this.setState({ saveSuccess: true })
    }, 1000)
    let data = {
      "name": formUserData && formUserData.name ? formUserData.name : "",
      "email": formUserData && formUserData.email ? formUserData.email : "",
      "country": formUserData && formUserData.country ? formUserData.country : "",
      "postcode": formUserData && formUserData.postcode ? formUserData.postcode : "",
      "mobile": formUserData && formUserData.mobile ? formUserData.mobile : "",
      "organisation": formUserData && formUserData.organisation ? formUserData.organisation : ""
    }
    this.props.dispatch(assessmentActions.updateAssessmentUserProfile(data));
    setTimeout(() => {
      this.setState({ saveSuccess: false })
    }, 4000)
  }


  handleFormData = (e) => {
    let { name, value } = e.target;

    let { newEmpFormData, newEmpFormDataError } = this.state;
    newEmpFormData[name] = value;
    newEmpFormDataError[name] = "";
    this.setState({ newEmpFormData, newEmpFormDataError })
  }

  handleAssessmentCodesCheckBox = (assessmentCode) => {
    this.setState((prevState) => {
      const { codeIdList } = prevState;
      if (codeIdList.includes(assessmentCode)) {
        // If it is, remove it
        return { codeIdList: codeIdList.filter((code) => code !== assessmentCode) };
      } else {
        // If it's not, add it
        return { codeIdList: [...codeIdList, assessmentCode] };
      }
    });
  };

  handleAddCode = () => {
    if (this.state.codeIdList.length > 0) {
      this.props.dispatch(assessmentActions.addUserAssessmentCode({ "userId": this.state.userId, codeIdList: this.state.codeIdList }));
    }
  };



  handleSelect = (e) => {
    let selectedOption = e.target.options[e.target.selectedIndex];
    const name = selectedOption.getAttribute('name');
    const value = selectedOption.getAttribute('value');
    let { newEmpFormData, newEmpFormDataError } = this.state;
    newEmpFormData[name] = value;
    newEmpFormDataError[name] = "";
    this.setState({ newEmpFormData, newEmpFormDataError })
  }

  handleSelectAssessmentCodes = (e) => {
    let selectedOption = e.target.options[e.target.selectedIndex];
    const name = selectedOption.getAttribute('name');
    const value = selectedOption.getAttribute('value');
    console.log('handleSelectAssessmentCodes___name___value???', name, value);
    this.props.dispatch(assessmentActions.userAssessmentOrders({ "id": name }));
  }

  handleValidationUser = () => {

    let newEmpFormData = this.state.newEmpFormData;
    let newEmpFormDataError = {};
    let formIsValid = true;


    //name
    if (!newEmpFormData["name"] || newEmpFormData["name"] === "") {
      formIsValid = false;
      newEmpFormDataError["name"] = "name Cannot Be Blank.";
    }

    //email
    if (!newEmpFormData["email"] || newEmpFormData["email"] === "") {
      formIsValid = false;
      newEmpFormDataError["email"] = "email Cannot Be Blank.";
    }

    //mobile
    if (!newEmpFormData["mobile"] || newEmpFormData["mobile"] === "") {
      formIsValid = false;
      newEmpFormDataError["mobile"] = "mobile Cannot Be Blank.";
    }

    //country
    if (!newEmpFormData["country"] || newEmpFormData["country"] === "") {
      formIsValid = false;
      newEmpFormDataError["country"] = "country Cannot Be Blank.";
    }

    //postcode
    if (!newEmpFormData["postcode"] || newEmpFormData["postcode"] === "") {
      formIsValid = false;
      newEmpFormDataError["postcode"] = "postcode Cannot Be Blank.";
    }

    //role
    if (!newEmpFormData["role"] || newEmpFormData["role"] === "") {
      formIsValid = false;
      newEmpFormDataError["role"] = "role Cannot Be Blank.";
    }

    console.log('errorsUser_errorsUser::::', newEmpFormDataError);

    this.setState({ newEmpFormDataError: newEmpFormDataError });
    return formIsValid;
  }

  handleUserSubmit = () => {

    if (this.handleValidationUser()) {

      let reqData = {
        "name": this.state.newEmpFormData.name,
        "email": this.state.newEmpFormData.email,
        "mobile": this.state.newEmpFormData.mobile,
        "role": this.state.newEmpFormData.role,
        "country": this.state.newEmpFormData.country,
        "postcode": this.state.newEmpFormData.postcode
      }
      let paginationData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
      }
      console.log('SUCCESS.................');
      this.props.dispatch(assessmentActions.createRoleUsers(reqData, paginationData));
      // this.props.dispatch(newsActions.createNews(reqData, paginationData, this.props));
    }

  }


  handleUpdateFormData = (e) => {
    let { name, value } = e.target;

    let { updateNewEmpFormData, updateNewEmpFormDataError } = this.state;
    updateNewEmpFormData[name] = value;
    updateNewEmpFormDataError[name] = "";
    this.setState({ updateNewEmpFormData, updateNewEmpFormDataError })
  }

  handleSelectUpdate = (e) => {
    let selectedOption = e.target.options[e.target.selectedIndex];
    const name = selectedOption.getAttribute('name');
    const value = selectedOption.getAttribute('value');
    let { updateNewEmpFormData, updateNewEmpFormDataError } = this.state;
    updateNewEmpFormData[name] = value;
    updateNewEmpFormDataError[name] = "";
    this.setState({ updateNewEmpFormData, updateNewEmpFormDataError })
  }

  handleValidationUserUpdate = () => {

    let updateNewEmpFormData = this.state.updateNewEmpFormData;
    let updateNewEmpFormDataError = {};
    let formIsValid = true;


    //name
    if (!updateNewEmpFormData["name"] || updateNewEmpFormData["name"] === "") {
      formIsValid = false;
      updateNewEmpFormDataError["name"] = "name Cannot Be Blank.";
    }

    //email
    if (!updateNewEmpFormData["email"] || updateNewEmpFormData["email"] === "") {
      formIsValid = false;
      updateNewEmpFormDataError["email"] = "email Cannot Be Blank.";
    }

    //mobile
    if (!updateNewEmpFormData["mobile"] || updateNewEmpFormData["mobile"] === "") {
      formIsValid = false;
      updateNewEmpFormDataError["mobile"] = "mobile Cannot Be Blank.";
    }

    //country
    if (!updateNewEmpFormData["country"] || updateNewEmpFormData["country"] === "") {
      formIsValid = false;
      updateNewEmpFormDataError["country"] = "country Cannot Be Blank.";
    }

    //postcode
    if (!updateNewEmpFormData["postcode"] || updateNewEmpFormData["postcode"] === "") {
      formIsValid = false;
      updateNewEmpFormDataError["postcode"] = "postcode Cannot Be Blank.";
    }

    //role
    if (!updateNewEmpFormData["role"] || updateNewEmpFormData["role"] === "") {
      formIsValid = false;
      updateNewEmpFormDataError["role"] = "role Cannot Be Blank.";
    }

    console.log('errorsUser_errorsUser::::', updateNewEmpFormDataError);

    this.setState({ updateNewEmpFormDataError: updateNewEmpFormDataError });
    return formIsValid;
  }

  handleUserUpdateSubmit = () => {

    if (this.handleValidationUserUpdate()) {

      let reqData = {
        "id": this.state.updateNewEmpFormData.id,
        "name": this.state.updateNewEmpFormData.name,
        "email": this.state.updateNewEmpFormData.email,
        "mobile": this.state.updateNewEmpFormData.mobile,
        "role": this.state.updateNewEmpFormData.role,
        "country": this.state.updateNewEmpFormData.country,
        "postcode": this.state.updateNewEmpFormData.postcode
      }
      let paginationData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
      }
      console.log('SUCCESS.................');
      this.props.dispatch(assessmentActions.updateRoleUsers(reqData, paginationData));
    }

  }

  handleDeleteUserModal = (value, data) => {
    console.log('yyyyyyyyyy', value, data);
    this.setState({ deleteUserModal: value, userId: data.id });
  }

  handleCloseDeleteUserModal = (value) => {
    this.setState({ deleteUserModal: value });
  }

  deleteUser = () => {
    let paginationdata = {
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(assessmentActions.deleteRoleUsers({ id: this.state.userId }, paginationdata));

  }

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem('route')
      localStorage.setItem('route', `/assessment/` + field)
      this.props.history.push(`/assessment/sign-in`);
    }
  }

  toggleEditing = () => {

    if (!this.state.isEditing) {
      let { assessment } = this.props;
      let { userAssessmentUserProfileData } = assessment;
      let data = {
        "name": userAssessmentUserProfileData && userAssessmentUserProfileData.name ? userAssessmentUserProfileData.name : "",
        "email": userAssessmentUserProfileData && userAssessmentUserProfileData.email ? userAssessmentUserProfileData.email : "",
        "country": userAssessmentUserProfileData && userAssessmentUserProfileData.country ? userAssessmentUserProfileData.country : "",
        "postcode": userAssessmentUserProfileData && userAssessmentUserProfileData.postcode ? userAssessmentUserProfileData.postcode : "",
        "mobile": userAssessmentUserProfileData && userAssessmentUserProfileData.mobile ? userAssessmentUserProfileData.mobile : "",
        "organisation": userAssessmentUserProfileData && userAssessmentUserProfileData.organisation ? userAssessmentUserProfileData.organisation : ""
      }
      this.setState({ formUserData: data })
    }



    this.setState(prevState => ({
      isEditing: !prevState.isEditing
    }));
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
    this.setState({ isEditing: false })
    // window.location.reload();
    // console.log('urlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurlurl', url);
  }

  handleClick = () => {
    const { history } = this.props;
    history.push('/assessment/expert'); // Replace '/expert-page' with the actual route of your expert page
  }

  handleToggleDropdown = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };

  handleCloseDropdown = () => {
    this.setState({
      isDropdownOpen: false
    });
  };

  handleNewUserModal = (value) => {
    this.setState({ showEmployeeModal: value, newEmpFormData: {}, newEmpFormDataError: {} });
  };

  handleCloseUpdateUserModal = (value) => {
    this.setState({ showUpdateEmployeeModal: value, updateNewEmpFormData: {}, updateNewEmpFormDataError: {} });
  };

  handleUpdateNewUserModal = (value, data) => {
    this.setState({
      showUpdateEmployeeModal: value
      , updateNewEmpFormData: data,
    });
  };

  handleShowAssessmentCode = (value, userId) => {
    this.setState({ showAssessmentCodesModal: value, userId: userId });
  };

  handleViewAssessmentCode = (value, userId) => {

    if (userId) {
      this.setState({ viewAssessmentCodesModal: value, userId: userId }, () => {
        this.props.dispatch(assessmentActions.getUserAssessmentCode({ userId: userId }));
      });
    } else {
      this.setState({ viewAssessmentCodesModal: value, userId: "" });
    }

  };



  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });

    let datatuser = {
      keyWord: "",
      pageNo: data.selected + 1,
      // companyId: this.props.match.params.id,
      size: this.state.size,
    }
    this.props.dispatch(assessmentActions.getRoleUsersList(datatuser));
  }

  handleSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    this.setState({ size: newSize, page: 0, offset: 0 }, () => {
      let datatuser = {
        keyWord: "",
        pageNo: 1,
        size: newSize,
      };
      this.props.dispatch(assessmentActions.getRoleUsersList(datatuser));
    });
  }


  render() {

    // console.log('RENDER___newEmpFormData::::', this.state.newEmpFormData);
    console.log('RENDER___codeIdList::::', this.state.codeIdList);
    console.log('RENDER___userId::::', this.state.userId);

    let { assessment, questions } = this.props;
    let {
      getAssessmentStatusData,
      getAssessmentReportByEmpIDData,
      getAssessmenrEmployeeDetailsData,
      getTranslateElementAllData,
      // getAssessmentCartCountData,
    } = questions;

    let { viewAssessmentCodesModal, showManagerReportModal, showHiringReportModal, formUserData, crrEmpDetails, showSummaryReport, showReportModal, surveyLanguageName, surveyLanguageCode, showNameEdit, formNameData, isDropdownOpen, showEmployeeModal, showUpdateEmployeeModal, showAssessmentCodesModal, deleteUserModal } = this.state;
    let { userAssessmentUserProfileData, getOrderBookListData, userAssessmentOrdersData, userAssessmentResponseAnalyticsData, getAssessmentPackagesShortListData, getAssessmentCartCountData, getRoleUsersListData, getRoleUsersListTotal, getUserAssessmentCodeData } = assessment;
    // console.log("formUserData-->", formUserData);

    let check =
      getAssessmentStatusData && getAssessmentStatusData.check
        ? getAssessmentStatusData.check
        : 0;

    let createdAt =
      getAssessmentStatusData && getAssessmentStatusData.createdAt
        ? moment(new Date(parseInt(getAssessmentStatusData.createdAt))).utcOffset("+05:30").format("DD MMMM, YYYY")
        : "";

    const { activeTab } = this.state;

    const GetTextByLanguage = (name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = "";
        if (getTranslateElementAllData && getTranslateElementAllData.length > 0) {
          let getIndex = getTranslateElementAllData.findIndex(prev => prev.name === name);
          if (getIndex !== -1) {
            sentence = getTranslateElementAllData && getTranslateElementAllData[getIndex] && getTranslateElementAllData[getIndex]["transl"] && getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode] ? getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode] : ""
          }
        }
        console.log("sentence------------>", sentence);
        console.log("surveyLanguageCode------------>", surveyLanguageCode);
        console.log("getTranslateElementAllData------------>", getTranslateElementAllData);

        return sentence ? sentence : text
      } else {
        return text
      }
    }

    const GetDetailsByLanguage = (element, name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = element && element["details"] && element["details"]["transl"] && element["details"]["transl"][surveyLanguageCode] && element["details"]["transl"][surveyLanguageCode][name] ?
          element["details"]["transl"][surveyLanguageCode][name] : ""
        return sentence ? sentence : text
      } else {
        return text
      }
    }

    function EditButtonFn(name, text) {
      return (
        <>
          {GetTextByLanguage(name, text)}
        </>
      )
    }

    function EditDetailsFn(element, name, text) {
      return (
        <>
          {GetDetailsByLanguage(element, name, text)}
        </>
      )
    }
    // console.log('getRoleUsersListDatagetRoleUsersListDatagetRoleUsersListData', getRoleUsersListData);

    function getDetails(orderBook, field, type, orderPack) {
      console.log('orderBookorderBookorderBookorderBookorderBook', orderBook);
      console.log('fieldfieldfieldfieldfieldfieldfieldfieldfieldfield', field);
      console.log('orderPackorderPackorderPackorderPackorderPackorderPack', orderPack);
      console.log('typetypetypetypetypetypetypetypetypetypetypetypetypetypetype', type);
      if (type === 2) {
        return field &&
          orderPack &&
          orderPack["productId"] &&
          orderPack["productId"][field]
          ? orderPack["productId"][field]
          : "";
      } else if (type === 3) {
        return field && orderPack && orderPack[field] ? orderPack[field] : "";
      } else {
        return field && orderBook && orderBook[field] ? orderBook[field] : "";
      }
    }

    function getUserDetails(field) {
      return (field && userAssessmentUserProfileData && userAssessmentUserProfileData[field] ? userAssessmentUserProfileData[field] : "NA")
    }

    const data = [
      { id: 1, name: 'Norma', email: 'Norma@gmail.com', date: '20/10/2024', role: 'Counselor', codes: 9, status: 'Inactive' },
      { id: 2, name: 'John', email: 'john@gmail.com', date: '21/10/2024', role: 'Advisor', codes: 8, status: 'Active' },
      { id: 3, name: 'Jane', email: 'jane@gmail.com', date: '22/10/2024', role: 'Teacher', codes: 10, status: 'Inactive' },
      { id: 4, name: 'Doe', email: 'doe@gmail.com', date: '23/10/2024', role: 'Engineer', codes: 7, status: 'Active' },
      { id: 5, name: 'Alice', email: 'alice@gmail.com', date: '24/10/2024', role: 'Designer', codes: 5, status: 'Inactive' },
      { id: 6, name: 'Bob', email: 'bob@gmail.com', date: '25/10/2024', role: 'Manager', codes: 3, status: 'Active' },
      { id: 7, name: 'Charlie', email: 'charlie@gmail.com', date: '26/10/2024', role: 'Developer', codes: 6, status: 'Inactive' },
      { id: 8, name: 'David', email: 'david@gmail.com', date: '27/10/2024', role: 'Analyst', codes: 4, status: 'Active' },
      { id: 9, name: 'Eve', email: 'eve@gmail.com', date: '28/10/2024', role: 'Consultant', codes: 2, status: 'Inactive' },
      { id: 10, name: 'Frank', email: 'frank@gmail.com', date: '29/10/2024', role: 'Specialist', codes: 1, status: 'Active' },
    ];

    const { page, size, offset } = this.state;
    const startItem = page * size + 1;
    const endItem = Math.min((page + 1) * size, getRoleUsersListTotal);

    console.log('___________________________userAssessmentOrdersData??', userAssessmentOrdersData);

    return (

      <div>
        <HeaderTransprent
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          gotoPage={this.gotoPage}
          props2={this.props}
          cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        <section className="flex flex-col w-full gap-10 px-4 mx-auto mb-10 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
          <div className="w-full py-5 space-y-4 md:py-10">
            <div className="flex items-center pb-6 space-x-4 border-b">
              <h1 className="md:text-3xl text-2xl font-medium text-[#3D405B]">
                My Account
              </h1>{" "}
            </div>

            <div className="flex flex-col items-start w-full gap-10 py-6 xl:flex-row ">

              <div className="hidden w-full capitalize md:block lg:w-72">
                <div
                  onClick={() => this.handleTabClick(2)}
                  className={
                    this.state.activeTab == 2
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <polyline points="5 12 3 12 12 3 21 12 19 12" /> <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /> <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                  <span>Profile Summary</span>
                </div>

                <div
                  onClick={() => this.handleTabClick(3)}
                  className={
                    this.state.activeTab == 3
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  > <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path> <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path> <path d="M17 17h-11v-14h-2"></path> <path d="M6 5l14 1l-1 7h-13"></path>
                  </svg>
                  <span>my orders</span>
                </div>

                <div
                  onClick={() => this.handleTabClick(4)}
                  className={
                    ((this.state.activeTab == 4) || (this.state.activeTab == 5))
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  > <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path> <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path> <path d="M9 12h6"></path> <path d="M9 16h6"></path>
                  </svg>
                  <span>Assessment Code</span>
                </div>

                <div
                  onClick={() => this.handleTabClick(6)}
                  className={
                    this.state.activeTab == 6
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  > <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path> <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path> <path d="M9 12h6"></path> <path d="M9 16h6"></path>
                  </svg> */}
                  <FiUsers className="w-6 h-6" />
                  <span>User</span>
                </div>
              </div>

              <div className="block w-full md:hidden">
                <label for="countries" className="block mb-2 text-sm font-medium text-[#3D405B]">Select an option</label>
                <select id="dropdown" onChange={this.handleDropdownChange} value={activeTab} className="bg-white border border-gray-300 text-[#3D405B] text-sm rounded-lg focus:ring-[#2196F3] focus:border-[#2196F3] block w-full p-2.5">
                  <option value="2">Profile Summary</option>
                  <option value="3">My Orders</option>
                  <option value="4">Assessment Code</option>
                  <option value="6">User</option>
                </select>
              </div>

              <div className="flex-1 w-full">

                <div className="w-full">
                  {this.state.activeTab === 6 ? (
                    <div className="w-full flex-1 space-y-4 text-[#3D405B]">
                      <div className="flex justify-between w-full">
                        <h1 className="md:text-2xl text-xl  text-[#3D405B]">Users</h1>
                        <button onClick={() => this.handleNewUserModal(true)} className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-sm  border-[#2196F3] flex justify-between px-4 items-center py-2 rounded-md whitespace-nowrap">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg><span> Add New</span>
                        </button>
                      </div>

                      <div className="items-stretch justify-between space-y-4 lg:flex md:space-x-6 md:space-y-0">
                        <div className="flex space-x-3 items-center">
                          <div className="flex space-x-2 capitalize items-center text-sm text-[#3D405B] font-medium">
                            <span>Show</span>
                            <form class="w-16">
                              <select id="entries" value={size} onChange={this.handleSizeChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2 py-1.5 focus:outline-none">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </form>
                            <span>entries</span>
                          </div>
                        </div>

                        <div className="flex space-x-4">
                          <div className="w-full flex justify-between border px-4 text-[#3D405B]/70 bg-white rounded-md text-sm items-center  overflow-hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                            > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <circle cx="10" cy="10" r="7" /> <line x1="21" y1="21" x2="15" y2="15" />
                            </svg>
                            <input type="search" name="search" placeholder="Search by email..." className="w-44 focus:outline-none px-2 py-2 text-[#3D405B] placeholder:font-normal font-medium " />
                          </div>

                          <div className="flex items-center py-3 px-4 text-sm border whitespace-nowrap rounded-md cursor-pointer font-medium text-[#212121] md:w-auto w-full" >
                            <span className="mr-2 " >
                              <LiaFileExcel className="w-5 h-5" />
                            </span> Download Report
                          </div>
                        </div>
                      </div>

                      <div className="w-full mx-auto">
                        <div className="inline-block min-w-full">
                          <div className="overflow-hidden">
                            <table className="min-w-full border">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">S.No.</th>
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Name</th>
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Email Address</th>
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Date Created</th>
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Role</th>
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Assessment Codes</th>
                                  {/* <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Status</th> */}
                                  <th className="px-3 py-3 text-left border text-sm font-semibold text-gray-900">Actions</th>
                                </tr>
                              </thead>
                              <tbody className="border divide-gray-200 bg-white">
                                {getRoleUsersListData && getRoleUsersListData.length > 0 ?
                                  getRoleUsersListData.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <tr className="hover:bg-gray-100">
                                        <td className="whitespace-nowrap px-2 py-2 border text-sm text-center">{this.state.offset + index + 1}</td>
                                        <td className="whitespace-nowrap px-2 py-2 border text-sm">{item.name}</td>
                                        <td className="whitespace-nowrap px-2 py-2 border text-sm">{item.email}</td>
                                        <td className="whitespace-nowrap px-2 py-2 border text-sm">{moment(new Date(parseInt(item.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>
                                        <td className="whitespace-nowrap px-2 py-2 border text-sm">{item.role}</td>
                                        <td className="whitespace-nowrap px-2 py-2 border text-sm">
                                          <div className="flex items-center space-x-2">
                                            <span onClick={() => this.handleShowAssessmentCode(true, item.id)} className="cursor-pointer border p-1 hover:bg-[#2196F3] hover:text-white rounded-md">
                                              <FiPlusCircle className="w-5 h-5" />
                                            </span>
                                            <p onClick={() => this.handleViewAssessmentCode(true, item.id)} className="cursor-pointer border-b border-[#212121] text-[#212121] font-medium">{item.codes} Codes</p>
                                            <IoIosArrowForward className="w-5 h-5" />
                                          </div>
                                        </td>

                                        {/* <td className="whitespace-nowrap px-2 py-2 border text-sm w-full">
                                          <select
                                            id="entries"
                                            className={`bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-md block w-full px-1 py-1.5 focus:outline-none ${item.isActive === true ? 'bg-green-500 text-white' : 'bg-white text-gray-900'}`}
                                          >
                                            <option className="option" value="Inactive" selected={item.isActive === false}>Inactive</option>
                                            <option className="option" value="Active" selected={item.isActive === true}>Active</option>
                                          </select>
                                        </td> */}

                                        <td className="whitespace-nowrap px-2 py-2 border text-sm">
                                          <div className="flex justify-center items-center space-x-2">
                                            <button className="text-base border rounded-md p-1 cursor-pointer text-[#212121]/70 hover:bg-[#2196F3] hover:text-white" onClick={() => this.handleUpdateNewUserModal(true, item)}>
                                              <MdOutlineEdit size={18} />
                                            </button>
                                            <button  className="text-base border rounded-md p-1 cursor-pointer text-[#212121]/70 hover:bg-[#2196F3] hover:text-white">
                                              <RiDeleteBinLine size={18} onClick={() => this.handleDeleteUserModal(true, item)} />
                                            </button>
                                          </div>
                                        </td>

                                      </tr>
                                    </React.Fragment>
                                  )) : null
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="sm:flex sm:flex-1 sm:items-center items-center justify-center sm:justify-between">
                          <div className="text-center">
                            <p class="text-sm text-gray-700 md:pb-0 pb-2">
                              Showing
                              <span class="font-medium"> {startItem} </span>
                              to
                              <span class="font-medium"> {endItem} </span>
                              of
                              <span class="font-medium"> {getRoleUsersListTotal} </span>
                              results
                            </p>
                          </div>
                          <div className="relative z-0 flex md:justify-end justify-center w-76">
                            {
                              getRoleUsersListTotal && getRoleUsersListTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={getRoleUsersListTotal / this.state.size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </div>
                        </div>

                      </div>

                    </div>
                  ) :

                    <section className="flex items-center justify-center w-full col-span-2 gap-10 px-4 mx-auto overflow-hidden">
                      <div className="flex flex-col items-center space-y-4 justify-center md:py-10 py-8 text-center">
                        <img src="/assessment_images/no-users.svg" alt="no-users.svg" className="w-3/6 2xl:w-2/4" />
                        <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">Data not found!</h2>
                        <p className="text-gray-500 text-lg">Looks like you haven’t added any user yet.</p>
                        <div className="mt-0 md:mt-4 bg-[#2196F3] border border-[#2196F3] text-white py-2 px-5 rounded uppercase flex items-center justify-center space-x-3">
                          <span><svg width="35" height="35" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.3281 40H50.3281M40.3281 30V50M10.3281 40C10.3281 43.9397 11.1041 47.8407 12.6117 51.4805C14.1194 55.1203 16.3292 58.4274 19.1149 61.2132C21.9007 63.999 25.2079 66.2087 28.8476 67.7164C32.4874 69.224 36.3885 70 40.3281 70C44.2678 70 48.1689 69.224 51.8086 67.7164C55.4484 66.2087 58.7556 63.999 61.5413 61.2132C64.3271 58.4274 66.5369 55.1203 68.0445 51.4805C69.5522 47.8407 70.3281 43.9397 70.3281 40C70.3281 36.0603 69.5522 32.1593 68.0445 28.5195C66.5369 24.8797 64.3271 21.5726 61.5413 18.7868C58.7556 16.001 55.4484 13.7913 51.8086 12.2836C48.1689 10.776 44.2678 10 40.3281 10C36.3885 10 32.4874 10.776 28.8476 12.2836C25.2079 13.7913 21.9007 16.001 19.1149 18.7868C16.3292 21.5726 14.1194 24.8797 12.6117 28.5195C11.1041 32.1593 10.3281 36.0603 10.3281 40Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          </svg></span>
                          <button className="">Add New user</button>
                        </div>
                      </div>
                    </section>
                  }
                </div>
                {/* )} */}
              </div>

            </div>
          </div>
        </section>

        <Ready />
        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        {showEmployeeModal ? (
          <>
            <NewUserModal
              newEmpFormData={this.state.newEmpFormData}
              newEmpFormDataError={this.state.newEmpFormDataError}
              handleNewEmpInput={this.handleFormData}
              handleSelect={this.handleSelect}
              handleUserSubmit={this.handleUserSubmit}
              handleNewUserModal={this.handleNewUserModal}
              saveNewEmployee={this.saveNewEmployee}
            // addNewEmpOtherField={this.addNewEmpOtherField}
            // handleNewEmpOtherInput={this.handleNewEmpOtherInput}
            />
          </>
        ) : null}

        {showUpdateEmployeeModal ? (
          <>
            <UpdateNewUserModal
              updateNewEmpFormData={this.state.updateNewEmpFormData}
              updateNewEmpFormDataError={this.state.updateNewEmpFormDataError}
              handleUpdateFormData={this.handleUpdateFormData}
              handleSelectUpdate={this.handleSelectUpdate}
              handleUserUpdateSubmit={this.handleUserUpdateSubmit}
              handleUpdateNewUserModal={this.handleUpdateNewUserModal}
              handleCloseUpdateUserModal={this.handleCloseUpdateUserModal}
            />
          </>
        ) : null}

        {deleteUserModal ? (
          <>
            <DeleteUserModal
              deleteUser={this.deleteUser}
              handleCloseDeleteUserModal={this.handleCloseDeleteUserModal}
            />
          </>
        ) : null}

        {showAssessmentCodesModal ? (
          <>
            <NewAssignAssessmentCodes
              // newEmpFormData={this.state.newEmpFormData}
              // handleNewEmpInput={this.handleNewEmpInput}
              handleShowAssessmentCode={this.handleShowAssessmentCode}
              handleSelectAssessmentCodes={this.handleSelectAssessmentCodes}
              handleAssessmentCodesCheckBox={this.handleAssessmentCodesCheckBox}
              handleAddCode={this.handleAddCode}
              codeIdList={this.state.codeIdList}
              saveNewEmployee={this.saveNewEmployee}
              getOrderBookListData={getOrderBookListData}
              getDetails={getDetails}
              userAssessmentOrdersData={userAssessmentOrdersData}
              addNewEmpOtherField={this.addNewEmpOtherField}
              handleNewEmpOtherInput={this.handleNewEmpOtherInput}
            />
          </>
        ) : null}


        {viewAssessmentCodesModal ? (
          <>
            <ViewAssessentCodes
              closeModal={this.handleViewAssessmentCode}
              createdAt={"-"}
              data={getUserAssessmentCodeData && getUserAssessmentCodeData.length > 0 ? getUserAssessmentCodeData.map((ele) => ({
                "id": ele && ele.purchaserOrderId && ele.purchaserOrderId.id ? ele.purchaserOrderId.id : "",
                "otpCode": ele && ele.purchaserOrderId && ele.purchaserOrderId.otpCode ? ele.purchaserOrderId.otpCode : ""
              })) : null}
            />
          </>
        ) : null}


        {
          this.state.modaleOpen ? (
            <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
              <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                <div className="flex items-center justify-between p-4 border-b md:px-8 ">
                  <h2 className="text-lg font-semibold md:text-xl test-gray-800 ">
                    Upload Team
                  </h2>
                  <div
                    onClick={() => this.ModalOpen()}
                    className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                  >
                    <svg
                      className="w-6 h-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                    </svg>
                  </div>
                </div>
                <div className="m-4 space-y-8 md:m-8 ">
                  <div className="p-4 space-y-2 text-sm border divide-y rounded-md text-[#3D405B]">
                    <h1 className="text-lg font-semibold text-[#3D405B] ">
                      {" "}
                      Guidelines for uploading the data
                    </h1>
                    <ol className="list-disc pl-6 space-y-1.5 py-4 text-[#3D405B] ">
                      <li className>
                        {" "}
                        Mandatory Column include emp_id and email as first column
                        &amp; second column should be in the file.
                      </li>
                      <li className="capitalize">
                        {" "}
                        Your file columns/headers should not contain any special
                        characters – this includes characters that are not numbers
                        or the English alphabet.
                      </li>
                      <li className="capitalize">
                        {" "}
                        Be careful not to include duplicates of the same row – no
                        two employees should have the same emp_id or email.
                      </li>
                      <li className="capitalize">
                        {" "}
                        Date format must be same as DD-MM-YYYY
                      </li>
                      <li className="capitalize">
                        {" "}
                        See the sample data format for reference and prepare your
                        master data accordingly without any change in mandatory
                        column names
                      </li>
                      <li className="capitalize">
                        {" "}
                        Make sure your Excel sheet is not password protected.
                      </li>
                    </ol>
                  </div>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                      <div className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2">
                        <span className="p-2 text-blue-500 bg-white rounded-full shadow-sm material-symbols-outlined">
                          download
                        </span>
                        <div onClick={() => this.handleDownloadSample()}>
                          <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                            Download sample date
                          </h1>
                          <p className="text-sm text-gray-500">
                            See the sample data format for reference
                          </p>
                        </div>
                      </div>
                      <input type="file" id="file" accept=".xlsx,.csv" hidden />
                      <label
                        htmlFor="file"
                        className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                      >
                        <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                          <span className="p-2 text-blue-500 bg-white rounded-full shadow-sm material-symbols-outlined">
                            upload
                          </span>
                          <div>
                            <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                              Upload Master Data
                            </h1>
                            <p className="text-sm text-gray-500">
                              Upload master data as per the guidelines
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="hidden">
                      <button className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none">
                        Sample CSV
                      </button>
                      <button className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

        {showReportModal ? (
          <AssessmentReport
            closeReportModal={this.closeReportModal}
            getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
            getAssessmenrEmployeeDetailsData={crrEmpDetails}
            createdAt={createdAt}

            EditButtonFn={EditButtonFn}
            EditDetailsFn={EditDetailsFn}
          />
        ) : null}


        {showSummaryReport ? (
          <AssessmentSummary
            closeReportModal={this.closeSummaryReportModal}
            getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
            getAssessmenrEmployeeDetailsData={crrEmpDetails}
            createdAt={createdAt}

            EditButtonFn={EditButtonFn}
            EditDetailsFn={EditDetailsFn}
          />
        ) : null}



        {showManagerReportModal ? (
          <AssessmentManagerReport
            closeReportModal={this.closeManagerReportModal}
            getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
            getAssessmenrEmployeeDetailsData={crrEmpDetails}
            createdAt={createdAt}

            EditButtonFn={EditButtonFn}
            EditDetailsFn={EditDetailsFn}
          />
        ) : null}


        {showHiringReportModal ? (
          <AssessmentHiringReport
            closeReportModal={this.closeHiringReportModal}
            getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
            getAssessmenrEmployeeDetailsData={crrEmpDetails}
            createdAt={createdAt}

            EditButtonFn={EditButtonFn}
            EditDetailsFn={EditDetailsFn}
          />
        ) : null}





      </div>
    );
  }
}
MyOrders.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(MyOrders);
