import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { Helmet } from "react-helmet";


const cards = [

  {
    id: 1,
    title: 'Agricultural & Food Sciences',
    desc1: `The study of farming, food production, and how to improve both.`,
    desc2: `Farmer, food scientist, agricultural engineer.`,
    imgSrcLink: '/assessment_images/agricultural-&-food-sciences.webp',
  },
  {
    id: 2,
    title: 'Medical Sciences',
    desc1: `The field focused on understanding and treating illnesses and health conditions.`,
    desc2: `Doctor, nurse, medical researcher.`,
    imgSrcLink: '/assessment_images/medical-sciences.webp',
  },
  {
    id: 3,
    title: 'Allied & Para Medical Sciences',
    desc1: `Support healthcare through specialized fields like radiology, physiotherapy, and medical technology.`,
    desc2: `Radiographer, physiotherapist, medical technician.`,
    imgSrcLink: '/assessment_images/allied-&-para-medical-sciences.webp',
  },
  {
    id: 4,
    title: 'Fitness & Well-Being',
    desc1: `Promoting health and fitness through exercise, nutrition, and wellness practices.`,
    desc2: `Personal trainer, nutritionist, wellness coach.`,
    imgSrcLink: '/assessment_images/fitness-&-well-being.webp',
  },
  {
    id: 5,
    title: 'Engineering & Technology',
    desc1: `Designing and building machines, structures, and technology solutions.`,
    desc2: `Civil engineer, software developer, mechanical engineer.`,
    imgSrcLink: '/assessment_images/engineering-&-technology.webp',
  },
  {
    id: 6,
    title: 'Architecture & Planning',
    desc1: `Creating and designing buildings and urban spaces.`,
    desc2: `Architect, urban planner, landscape designer.`,
    imgSrcLink: '/assessment_images/architecture-& -panning.webp',
  },
  {
    id: 7,
    title: 'Science & Mathematics',
    desc1: `Explore and apply scientific principles and mathematical theories to solve real-world problems.`,
    desc2: `biologist, physicist, mathematician.`,
    imgSrcLink: '/assessment_images/science-&-mathematics.webp',
  },
  {
    id: 8,
    title: 'Information Technology & Computer Science',
    desc1: `Developing and managing computer systems, software, and networks.`,
    desc2: `Programmer, IT specialist, cybersecurity analyst.`,
    imgSrcLink: '/assessment_images/information-technology-&-computer-science.webp',
  },
  {
    id: 9,
    title: 'Legal Services',
    desc1: `Helping people with legal issues and representing them in court.`,
    desc2: `Lawyer, paralegal, judge.`,
    imgSrcLink: '/assessment_images/legal-services.webp',
  },
  {
    id: 10,
    title: 'Business Management',
    desc1: `Running and improving businesses through effective strategies and leadership.`,
    desc2: `Business manager, entrepreneur, human resources manager.`,
    imgSrcLink: '/assessment_images/business-management.webp',
  },
  {
    id: 11,
    title: 'Sales & Marketing',
    desc1: `Promoting and selling products or services to customers.`,
    desc2: `Sales representative, marketing manager, advertising executive.`,
    imgSrcLink: '/assessment_images/sales-&-marketing.webp',
  },
  {
    id: 12,
    title: 'Finance & Banking',
    desc1: `Managing money, investments, and financial planning for individuals and organizations.`,
    desc2: ` Banker, financial analyst, accountant.`,
    imgSrcLink: '/assessment_images/finance-&-banking.webp',
  },
  {
    id: 13,
    title: 'Humanities, Liberal Arts & Social Sciences',
    desc1: `Studying human culture, history, and society to understand and address social issues.`,
    desc2: ` Historian, sociologist, philosopher.`,
    imgSrcLink: '/assessment_images/humanities-liberal-arts-&-social-sciences.webp',
  },
  {
    id: 14,
    title: 'Education & Teaching',
    desc1: `Helping students learn and develop skills and knowledge.`,
    desc2: ` Teacher, school counselor, educational administrator.`,
    imgSrcLink: '/assessment_images/education-&-teaching.webp',
  },
  {
    id: 15,
    title: 'Mass Communication',
    desc1: `Sharing information with the public through various media platforms.`,
    desc2: `Public relations specialist, media producer, communication strategist.`,
    imgSrcLink: '/assessment_images/mass-communication.webp',
  },
  {
    id: 16,
    title: 'Journalism',
    desc1: ` Reporting on news and events to inform the public.`,
    desc2: ` Journalist, news reporter, editor.`,
    imgSrcLink: '/assessment_images/journalism.webp',
  },
  {
    id: 17,
    title: 'Performing Arts',
    desc1: ` Expressing creativity through acting, music, dance, and other performance arts.`,
    desc2: `Actor, musician, dancer.`,
    imgSrcLink: '/assessment_images/performing-arts.webp',
  },
  {
    id: 18,
    title: 'Art & Design',
    desc1: `Creating visual art and design pieces that are both functional and aesthetic.`,
    desc2: `Graphic designer, painter, interior designer.`,
    imgSrcLink: '/assessment_images/art-&-design.webp',
  },
  {
    id: 19,
    title: 'Animation, Graphics & Visual Communication',
    desc1: `Creating visual content for entertainment, advertising, and communication.`,
    desc2: `Animator, graphic artist, visual effects specialist.`,
    imgSrcLink: '/assessment_images/animation-graphics-&-visual-communication.webp',
  },
  {
    id: 20,
    title: 'Hospitality, Tourism & Transport Services',
    desc1: `Managing travel, accommodation, and transportation services to enhance customer experiences.`,
    desc2: `Hotel manager, travel agent, airline pilot.`,
    imgSrcLink: '/assessment_images/hospitality-tourism-&-transport-services.webp',
  },
  {
    id: 21,
    title: 'Government & Defense Services',
    desc1: `Serving the public and protecting the country through various roles in government and the military.`,
    desc2: `Civil servant, military officer, diplomat.`,
    imgSrcLink: '/assessment_images/government-&-defence-services.webp',
  },
]



class TeenCareerLibraries extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      saveSuccess: false,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,

      fieldslogin: {},
      errorslogin: {},

      currentPage: 1,
      cardsPerPage: 7,

      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(assessmentActions.userAssessmentUserProfile('getAssessmentCartCount'));
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  handleTabClick = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };


  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleDownload = () => {
    let code = "TalentPlusStrengths21";
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    link.click();
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/how-talentplus-strength-works");
    window.scrollTo(0, 0);
  };

  closeOrgTypeModal = () => {
    this.setState({ showOrgTypeModal: false })
  }

  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["type"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;
    if (formData["type"] === "Individual") {
      this.props.history.push(`/assessment/` + "surveylogin");
    } else if (formData["type"] === "Organization") {
      window.location.href = 'https://plusinsight.happyplus.in/surveylink/#/assessment/signin';
    }
  };

  handleTakeAssessmentRedirect = () => {
    this.setState({ showOrgTypeModal: false }, () => {
      this.handleOptionSubmit();
    })
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  loginSubmit = () => {
    console.log(
      "this.handleValidationLogin()---->",
      this.handleValidationLogin()
    );

    if (this.handleValidationLogin()) {
      let { userName, email, phone, message } =
        this.state.fieldslogin;
      this.setState({ saveSuccess: true });
      this.props.dispatch(
        assessmentActions.saveAssessmentContactUs(
          {
            name: userName,
            email: email,
            phone: phone,
            message: message,
          },
          this.props
        )
      );
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  handleValidationLogin = () => {
    let { fieldslogin } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    //userName
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid += 1;
      console.log("1 userName !");
      errorslogin["userName"] = "Full name is required";
    }
    //email
    if (!fieldslogin["email"]) {
      console.log("1 email !");

      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(fieldslogin["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }

    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };

  scrollToElement = () => {
    if (this.myRef.current) {
      const offset = 180; // Adjust the offset value as needed
      const elementPosition = this.myRef.current.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  };

  handleClick = (event, page) => {
    event.preventDefault();
    this.setState({
      currentPage: page,
    });
  };



  render() {

    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;

    const { currentPage, cardsPerPage } = this.state;

    // Logic for displaying current cards
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(cards.length / cardsPerPage); i++) {
      pageNumbers.push(i);
    }


    return (
      <div className="overflow-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Empowering Teen Talent Potential for Meaningful Growth</title>
          <meta name="description" content="Elevate teen potential with Teenager Talent assessment, fostering confidence, purposeful living, and transformative growth." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/home" />
        </Helmet>


        {/* <TestHeader
          handleTakeAssessmentRedirect={this.handleTakeAssessmentRedirect}
        /> */}

        <section
          className="bg-gradient-to-b from-[#4CAF50]/20 w-full h-auto bg-center  bg-cover bg-no-repeat  "
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          {/* Hero Section */}
          <div className="w-full h-full border-t border-black/10">
            <div data-aos="fade-right" className="text-base text-[#212121] font-normal  pt-4 
                left-0 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto">
              <span className="cursor-pointer" onClick={() => this.gotoPage("home")}>Home</span>  {'>'}  <span className="cursor-pointer" onClick={() => this.gotoPage("teen-career-guidance")}>Career Guidance Program</span>  {'>'}  <span className="text-[#2196F3]">Career Libraries</span>
            </div>
            <div className="flex flex-col w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto  ">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-8 text-center md:pb-10 md:pt-0 md:text-left ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Career Libraries</h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-[#212121]">Choosing a career can be a daunting task for teens, given the diverse array of options available. Here’s a quick overview of various fields to help you explore different paths.
                    <br />
                    <br />
                    Choosing a career can be a daunting task for teens, given the diverse array of options available. Here’s a quick overview of various fields to help you explore different paths:</p>
                </div>
              </div>
              <div className="flex items-end justify-center w-full lg:justify-end">
                <img data-aos="fade-left" src="/img/assessment/teen-career-libraries.webp" alt="teen-career-libraries" className="z-0 w-full" />
              </div>
            </div>
          </div>
        </section>


        <section className="w-full px-4 mx-auto md:py-20 py-10 bg-white 2xl:w-9/12 xl:w-11/12 xl:px-0  space-y-10">
          <div className="mx-auto space-y-4 text-center">
            <h1
              data-aos="fade-up"
              className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
            >Explore our Career Libraries</h1>
            <p data-aos="fade-up" className="md:text-lg text-base text-[#212121]">A comprehensive collection of resources for various professions.</p>
          </div>


          <div className="mx-auto w-full space-y-6">
            <div className=" ">
              <table className="min-w-full border-collapse border border-white lg:block hidden">
                <thead className="bg-[#212121]/[10%] text-left">
                  <tr>
                    <th className="border-2 p-3 border-white"></th>
                    <th className="border-2 p-3 border-white">Careers</th>
                    <th className="border-2 p-3 border-white">What it is</th>
                    <th className="border-2 p-3 border-white">Example Jobs</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCards.map((card, index) => (
                    <tr key={card.id} className={index % 2 === 0 ? 'bg-[#212121]/5' : 'bg-[#212121]/10'}>
                      <td className="border p-3 border-white text-left">
                        <img src={card.imgSrcLink} alt={card.title} className="z-0 w-24" />
                      </td>
                      <td className="border-2 p-3 border-white text-left text-base font-medium">{card.title}</td>
                      <td className="border-2 p-3 border-white text-left text-base">{card.desc1}</td>
                      <td className="border-2 p-3 border-white text-left text-base font-medium">{card.desc2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* mobile view */}
              <div className="space-y-4 ">

                {currentCards.map((card, index) => (
                  <div key={card.id} className='bg-[#212121]/5 p-4 flex flex-col gap-4 lg:hidden flex'>
                    <div className="flex space-x-4 items-center">
                      <img src={card.imgSrcLink} alt={card.title} className="z-0 w-24" />
                      <td className=" text-left text-lg font-semibold">{card.title}</td>
                    </div>
                    <div className="space-y-2 ">
                      <p className="text-sm"><b className="font-semibold ">What it is :</b> {card.desc1}</p>
                      <p className="text-sm font-medium"><b className="font-semibold ">Example Jobs :</b> {card.desc2}</p>
                    </div>
                  </div>
                ))}

              </div>
            </div>


            <div className="flex items-center justify-center ">
              <div className="flex border divide-x text-lg">
                <button
                  className={`px-3 py-1.5 flex items-center  ${currentPage === 1 ? 'cursor-not-allowed text-[#212121]/60' : 'hover:bg-slate-100 text-[#2196F3]'
                    }`}
                  onClick={(event) => currentPage !== 1 && this.handleClick(event, currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M13.4486 12L17.3236 6.575C17.5569 6.24167 17.5859 5.896 17.4106 5.538C17.2353 5.18 16.9396 5.00067 16.5236 5C16.3569 5 16.1986 5.03767 16.0486 5.113C15.8986 5.18833 15.7819 5.29233 15.6986 5.425L10.9986 12L15.6986 18.575C15.7819 18.7083 15.8986 18.8127 16.0486 18.888C16.1986 18.9633 16.3569 19.0007 16.5236 19C16.9236 19 17.2153 18.821 17.3986 18.463C17.5819 18.105 17.5569 17.759 17.3236 17.425L13.4486 12ZM7.4986 12L11.3736 6.575C11.6069 6.24167 11.6363 5.896 11.4616 5.538C11.2869 5.18 10.9906 5.00067 10.5726 5C10.4059 5 10.2476 5.03767 10.0976 5.113C9.9476 5.18833 9.83093 5.29233 9.7476 5.425L5.0486 12L9.7486 18.575C9.83193 18.7083 9.9486 18.8127 10.0986 18.888C10.2486 18.9633 10.4069 19.0007 10.5736 19C10.9736 19 11.2653 18.821 11.4486 18.463C11.6319 18.105 11.6069 17.759 11.3736 17.425L7.4986 12Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <div>
                    Previous
                  </div>
                </button>

                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    href="#!"
                    className={`px-3 py-1.5 ${currentPage === number
                      ? 'text-[#2196F3] hover:bg-slate-100'
                      : 'text-[#212121]/80 hover:bg-slate-100'
                      }`}
                    onClick={(event) => this.handleClick(event, number)}
                  >
                    {number}
                  </button>

                ))}

                <button
                  className={`px-3 py-1.5 flex items-center ${currentPage === pageNumbers.length ? 'cursor-not-allowed text-[#212121]/60' : 'hover:bg-slate-100 text-[#2196F3]'
                    }`}
                  onClick={(event) => currentPage !== pageNumbers.length && this.handleClick(event, currentPage + 1)}
                  disabled={currentPage === pageNumbers.length}
                >
                  <div>Next</div>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M11.5514 12L7.6764 6.575C7.44307 6.24167 7.41407 5.896 7.5894 5.538C7.76473 5.18 8.0604 5.00067 8.4764 5C8.64307 5 8.8014 5.03767 8.9514 5.113C9.1014 5.18833 9.21807 5.29233 9.3014 5.425L14.0014 12L9.3014 18.575C9.21807 18.7083 9.1014 18.8127 8.9514 18.888C8.8014 18.9633 8.64307 19.0007 8.4764 19C8.0764 19 7.78473 18.821 7.6014 18.463C7.41807 18.105 7.44307 17.759 7.6764 17.425L11.5514 12ZM17.5014 12L13.6264 6.575C13.3931 6.24167 13.3637 5.896 13.5384 5.538C13.7131 5.18 14.0094 5.00067 14.4274 5C14.5941 5 14.7524 5.03767 14.9024 5.113C15.0524 5.18833 15.1691 5.29233 15.2524 5.425L19.9514 12L15.2514 18.575C15.1681 18.7083 15.0514 18.8127 14.9014 18.888C14.7514 18.9633 14.5931 19.0007 14.4264 19C14.0264 19 13.7347 18.821 13.5514 18.463C13.3681 18.105 13.3931 17.759 13.6264 17.425L17.5014 12Z"
                        fill="currentColor"
                        fill-opacity=""
                      />
                    </svg>
                  </span>

                </button>

              </div>
            </div>
          </div>
        </section>






        {/* Contact Us */}
        <section className="py-20 bg-[#F9F9FC] space-y-10" >
          {/* <h2 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center aos-init aos-animate capitalize">Get in Touch</h2> */}
          <div className="grid w-full grid-cols-1 gap-8 px-4 mx-auto text-gray-100 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:grid-cols-2">

            <div data-aos="fade-right" className="space-y-4 ">
              <div data-aos="fade-up" className="space-y-2 text-[#212121]">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#212121]  capitalize">
                  Get in touch
                </h2>
                <p className="lg:text-lg text-base text-[#212121]">
                  For customer support and query, get in touch with us.
                </p>
              </div>

              <div className="space-y-0 ">
                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 border-b">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#212121] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-mail"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="3" y="5" width="18" height="14" rx="2" />
                      <polyline points="3 7 12 13 21 7" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Email
                    </h1>
                    <p className="text-base ">connect@happyplus.in</p>
                  </div>
                </div>

                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 ">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#3D405B] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="3" y1="21" x2="21" y2="21" />
                      <line x1="9" y1="8" x2="10" y2="8" />
                      <line x1="9" y1="12" x2="10" y2="12" />
                      <line x1="9" y1="16" x2="10" y2="16" />
                      <line x1="14" y1="8" x2="15" y2="8" />
                      <line x1="14" y1="12" x2="15" y2="12" />
                      <line x1="14" y1="16" x2="15" y2="16" />
                      <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#3D405B] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Office Address:
                    </h1>
                    <p className="text-base ">
                      HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai
                      400065
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <img
                  data-aos="fade-right"
                  src="/img/domaim/contact-us.svg"
                  alt="contact-us"
                  className="flex items-center justify-center p-6 w-6/6 "
                  width={490}
                  height={360}
                />
              </div>
            </div>

            <div data-aos="fade-left" className="space-y-6 ">
              <div data-aos="fade-up" className="space-y-2 ">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                  Submit Details
                </h2>
                <p className="lg:text-lg text-base text-[#3D405B]">
                  Fill in your details below & we will get back to you soon!
                </p>
              </div>

              <div noValidate action className="grid grid-cols-1 gap-6 pt-2">
                <div className="space-y-2 ">
                  <label
                    htmlFor="name"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678] capitalize"
                  >
                    Full name
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["userName"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="userName"
                    name="userName"
                    placeholder="Enter Name"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["userName"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["userName"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="email"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678]"
                  >
                    Email
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["email"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["email"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["email"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="phone"
                    className="text-base font-medium text-[#212121]"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md"
                    id="phone"
                    name="phone"
                    placeholder="+91 "
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="message"
                    className="text-base font-medium text-[#212121]"
                  >
                    {" "}
                    Message (If Any)
                  </label>
                  <textarea
                    rows={3}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md resize-none"
                    id="message"
                    name="message"
                    placeholder=""
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <button
                  type="button"
                  className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150"
                  onClick={this.loginSubmit}
                >
                  {" "}
                  Send Message
                </button>

                {this.state.saveSuccess ? (
                  <button
                    type="button"
                    className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <span>Your query has been successfully sent!</span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
TeenCareerLibraries.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(TeenCareerLibraries);
