import React from 'react';
// import './styles.css';


export default function Component(props) {

  const { question, answersList, surveyLanguageCode, handleRadio, clicked, surveyScreenData, isArabic } = props;



  return (
    <>
      <div className={isArabic ? "" : "pt-4 grid grid-cols-1 gap-3"}>

        {question && question.optionsList && question.optionsList.length > 0 ?
          question.optionsList.map((option, optionindex) =>
            <div onClick={() => handleRadio(optionindex, question, option)}
              className={
                ((answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ?
                  "border border-[#2196F3] group text-[#2196F3] bg-[#2196F3]/10" : "border border-[#212121] hover:border-[#2196F3] group hover:text-[#2196F3] hover:bg-[#2196F3]/10") +
                " p-4 py-2 rounded-md flex justify-start space-x-3 items-center cursor-pointer transition-all duration-100  "}>

              {((surveyLanguageCode !== '') ?
                (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?
                  question.transl[surveyLanguageCode].optionsList[option.id] : option.name
                : option.name) === "Likely" ?

                <>
                  {(answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <path d="M6.66536 34.9997H8.33203V13.333H6.66536C5.78131 13.333 4.93346 13.6842 4.30834 14.3093C3.68322 14.9344 3.33203 15.7823 3.33203 16.6663V31.6663C3.33203 32.5504 3.68322 33.3982 4.30834 34.0234C4.93346 34.6485 5.78131 34.9997 6.66536 34.9997ZM33.332 13.333H21.6654L23.5354 7.71967C23.7022 7.2187 23.7477 6.68525 23.668 6.16327C23.5883 5.64128 23.3857 5.1457 23.077 4.71733C22.7683 4.28897 22.3622 3.94008 21.8922 3.6994C21.4222 3.45872 20.9017 3.33314 20.3737 3.33301H19.9987L11.6654 12.3963V34.9997H29.9987L36.5187 20.673L36.6654 19.9997V16.6663C36.6654 15.7823 36.3142 14.9344 35.6891 14.3093C35.0639 13.6842 34.2161 13.333 33.332 13.333Z" fill="currentColor" />
                    </svg>
                    :
                    <svg className='group-hover:stroke-[#2196F3] group-hover:text-[#2196F3] ' xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <path d="M33.332 13.333H23.9787L25.8504 7.72134C26.187 6.70801 26.017 5.58467 25.392 4.71801C24.767 3.85134 23.7537 3.33301 22.6854 3.33301H19.9987C19.5037 3.33301 19.0354 3.55301 18.717 3.93301L10.8837 13.333H6.66536C4.82703 13.333 3.33203 14.828 3.33203 16.6663V31.6663C3.33203 33.5047 4.82703 34.9997 6.66536 34.9997H28.8437C29.5218 34.9974 30.1833 34.7894 30.7407 34.4031C31.2981 34.0169 31.7251 33.4705 31.9654 32.8363L36.5604 20.5847C36.6301 20.3975 36.6657 20.1994 36.6654 19.9997V16.6663C36.6654 14.828 35.1704 13.333 33.332 13.333ZM6.66536 16.6663H9.9987V31.6663H6.66536V16.6663ZM33.332 19.698L28.8437 31.6663H13.332V15.603L20.7787 6.66634H22.6887L20.0854 14.4713C20.0009 14.7219 19.9773 14.9889 20.0166 15.2504C20.0559 15.5118 20.157 15.7602 20.3115 15.9747C20.4659 16.1893 20.6693 16.364 20.9048 16.4843C21.1402 16.6046 21.401 16.667 21.6654 16.6663H33.332V19.698Z" fill="currentColor" />
                    </svg>

                  }

                </>
                :
                <>
                  {(answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <path d="M6.66536 5.00033H8.33203V26.667H6.66536C5.78131 26.667 4.93346 26.3158 4.30834 25.6907C3.68322 25.0656 3.33203 24.2177 3.33203 23.3337V8.33366C3.33203 7.4496 3.68322 6.60176 4.30834 5.97664C4.93346 5.35152 5.78131 5.00033 6.66536 5.00033ZM33.332 26.667H21.6654L23.5354 32.2803C23.7022 32.7813 23.7477 33.3147 23.668 33.8367C23.5883 34.3587 23.3857 34.8543 23.077 35.2827C22.7683 35.711 22.3622 36.0599 21.8922 36.3006C21.4222 36.5413 20.9017 36.6669 20.3737 36.667H19.9987L11.6654 27.6037V5.00033H29.9987L36.5187 19.327L36.6654 20.0003V23.3337C36.6654 24.2177 36.3142 25.0656 35.6891 25.6907C35.0639 26.3158 34.2161 26.667 33.332 26.667Z" fill="currentColor" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <path d="M33.332 26.667H23.9787L25.8504 32.2787C26.187 33.292 26.017 34.4153 25.392 35.282C24.767 36.1487 23.7537 36.667 22.6854 36.667H19.9987C19.5037 36.667 19.0354 36.447 18.717 36.067L10.8837 26.667H6.66536C4.82703 26.667 3.33203 25.172 3.33203 23.3337V8.33366C3.33203 6.49533 4.82703 5.00033 6.66536 5.00033H28.8437C29.5218 5.00258 30.1833 5.21059 30.7407 5.59686C31.2981 5.98314 31.7251 6.52948 31.9654 7.16366L36.5604 19.4153C36.6301 19.6025 36.6657 19.8006 36.6654 20.0003V23.3337C36.6654 25.172 35.1704 26.667 33.332 26.667ZM6.66536 23.3337H9.9987V8.33366H6.66536V23.3337ZM33.332 20.302L28.8437 8.33366H13.332V24.397L20.7787 33.3337H22.6887L20.0854 25.5287C20.0009 25.2781 19.9773 25.0111 20.0166 24.7496C20.0559 24.4882 20.157 24.2398 20.3115 24.0253C20.4659 23.8107 20.6693 23.636 20.9048 23.5157C21.1402 23.3954 21.401 23.333 21.6654 23.3337H33.332V20.302Z" fill="currentColor" />
                    </svg>}
                </>

              }



              <span className="font-medium">
                {(surveyLanguageCode !== '') ?
                  (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?
                    question.transl[surveyLanguageCode].optionsList[option.id] : option.name
                  : option.name}
              </span>
            </div>


          ) : null}


      </div>
    </>
  );
}