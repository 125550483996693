

import React, { Component } from "react";

import { connect } from "react-redux";


import { assessmentActions } from "../../_actions";

class AssessmentLoginByOTP extends Component {
  constructor(props) {
    super(props);

    this.inputChangeSurveyOTP = this.inputChangeSurveyOTP.bind(this);

    this.state = {
      surveyotp: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
    };
  }

  componentDidMount() { }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.user.UserLoginEmailSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  inputChangeSurveyOTP(e) {
    this.setState({ surveyotp: e.target.value });
  }

  submitSurveyOTP = () => {
    let { surveyotp } = this.state;
    if (surveyotp) {
      this.props.dispatch(
        assessmentActions.userAssessmentSurveyLoginByOTP({ surveyotp: surveyotp }, this.props)
      );
      this.setState({ failureMSG: "" });
    }
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitSurveyOTP();
    }
  };

  render() {
    let { questions, user } = this.props;
    let {
      getSurveyDetailsBySurveyCodeData,
    } = questions;
    let { emp_id, otp_code } = this.state;
    let { UserLoginErrorMSG } = user;

    console.log("emp_id: ", emp_id);
    console.log("otp_code: ", otp_code);


    let errorMsh = "";
    if (UserLoginErrorMSG) {
      errorMsh = UserLoginErrorMSG;
    }



    console.log("getSurveyDetailsBySurveyCodeData--------->", getSurveyDetailsBySurveyCodeData);

    return (
      <>
        <div className="flex h-screen">
          {/* right section */}
          <div
            className=" bg-[#2196f3] w-5/12 h-full lg:flex hidden flex-col justify-center  items-center space "
          >
            {/* <LogoHeader surveyScreenData={surveyScreenData} template={"v4"} /> */}
            <img
              alt="talent_plus_assessment"
              src="/img/talent_plus_assessment.svg"
              className="w-11/12 mx-auto"
            />
            <p className="text-xl font-normal capitalize text-white  ">
              Powered by <b className="font-semibold">Happyplus</b>
            </p>
          </div>

          <div className=" lg:w-7/12 w-full h-full flex justify-center items-center bg-slate-100">
            <div className=" w-[30rem]  md:mx-auto mx-6 md:my-6 my-4 space-y-10  ">
              <img
                src="/img/talent_plus_logo.svg"
                className="md:w-52 w-40 bg-cover"
              />
              <div className="space-y-4 text-[#3D405B] ">
                <h1 className="md:text-4xl text-2xl font-semibold   ">
                  Welcome to TalentPlusStrengths  Assessment
                </h1>
                <p className="md:text-2xl text-lg ">
                  Sign in by assessment code
                </p>
              </div>
              <div className="space-y-2">
                <label className=" font-semibold text-slate-500 text-lg">
                  Assessment Code
                </label>
                <br />
                <input
                  onKeyDown={this._handleKeyDown}
                  autocomplete="off"
                  type="text"
                  value={emp_id}
                  name="surveyotp"
                  onChange={this.inputChangeSurveyOTP}
                  placeholder="Assessment Code"
                  className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                />
              </div>

              {errorMsh ? (
                <>
                  <p className="pt-2 text-red-500">{errorMsh}</p>
                  <button
                    onClick={() => this.submitSurveyOTP()}
                    className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => this.submitSurveyOTP()}
                    className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                    Submit
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
AssessmentLoginByOTP.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user } = state;
  return {
    loggingIn,
    questions,
    user,
  };
}

export default connect(mapStateToProps)(AssessmentLoginByOTP);
