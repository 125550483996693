import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import Ready from "../../../components/Ready";
import { GiCheckMark } from "react-icons/gi";
import { GoOrganization } from "react-icons/go";
import { MdOutlineCalendarMonth, MdOutlineLocationOn } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import ImpackSwiper from "./impactSwiper";
import { MdOutlineLocalPhone } from "react-icons/md";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.section1Ref = React.createRef();
    this.section2Ref = React.createRef();
    this.section3Ref = React.createRef();
    this.section4Ref = React.createRef();
    this.section5Ref = React.createRef();
    this.section6Ref = React.createRef();
    this.section8Ref = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      zoomed: false,
      saveSuccess: false,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,

      fieldslogin: {},
      errorslogin: {},

      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleImageClick = (value) => {
    this.setState({ currentImage: value });
  };

  componentDidMount() {
    // window.scrollTo(0, 0);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    // this.props.dispatch(assessmentActions.getAssessmentCartCount());

    // this.props.dispatch(
    //   assessmentActions.getAssessmentPackagesById({
    //     id: this.props.match.params.id2,
    //   })
    // );
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentSignInSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };
  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  changeQuantity = (type) => {
    let { formData } = this.state;
    if (type === "add") {
      formData["quantity"] += 1;
    } else if (type === "sub" && formData["quantity"] > 1) {
      formData["quantity"] -= 1;
    }
    this.setState({ formData });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  inputChange2 = (e, field) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[field] = value;
    errorslogin[field] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  loginSubmit = () => {
    console.log(
      "this.handleValidationLogin()---->",
      this.handleValidationLogin()
    );

    if (this.handleValidationLogin()) {
      let { userName, email, phone, message } =
        this.state.fieldslogin;
      this.setState({ saveSuccess: true });
      this.props.dispatch(
        assessmentActions.saveAssessmentContactUs(
          {
            name: userName,
            email: email,
            phone: phone,
            message: message,
          },
          this.props
        )
      );
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  handleValidationLogin = () => {
    let { fieldslogin } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    //userName
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid += 1;
      console.log("1 userName !");
      errorslogin["userName"] = "Full name is required";
    }
    //email
    if (!fieldslogin["email"]) {
      console.log("1 email !");

      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(fieldslogin["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }
    //phone
    // if (!fieldslogin["phone"]) {
    //   formIsValid = false;
    //   errorslogin["phone"] = "Phone is required";
    // }

    //message
    // if (!fieldslogin["message"]) {
    //   console.log("1 message !")

    //   formIsValid += 1;
    //   errorslogin["message"] = "Message is required";
    // }
    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };

  handleAddToCart = () => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;
    setTimeout(() => {
      this.setState({ saveSuccess: true });
    }, 4000);
    if (
      !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
    ) {
      this.props.history.push(`/assessment/` + "sign-in");
    } else {
      let { formData } = this.state;
      if (formData && formData["productId"]) {
        let data = {
          quantity: formData["quantity"],
          productId: formData["productId"],
          licensee: formData["licensee"],
        };
        this.props.dispatch(
          assessmentActions.assessmentAddToCart(data, this.props)
        );
      }
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };
  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/contact-us");
  };

  handleScroll = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
    const section1Offset = this.section1Ref.current.offsetTop;
    const section2Offset = this.section2Ref.current.offsetTop;
    const section3Offset = this.section3Ref.current.offsetTop;
    const section4Offset = this.section4Ref.current.offsetTop;
    const section5Offset = this.section5Ref.current.offsetTop;
    const section6Offset = this.section6Ref.current.offsetTop;
    const section8Offset = this.section8Ref.current.offsetTop;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop >= section1Offset) {
      this.setState({
        isScrolled: true,
        showButton1: true,
      });
    } else {
      this.setState({
        isScrolled: false,
        showButton1: true,
      });
    }
    if (scrollTop >= section2Offset) {
      this.setState({
        showButton2: true,
      });
    } else {
      this.setState({
        showButton2: false,
      });
    }
    if (scrollTop >= section3Offset) {
      this.setState({
        showButton3: true,
      });
    } else {
      this.setState({
        showButton3: false,
      });
    }
    if (scrollTop >= section4Offset) {
      this.setState({
        showButton4: true,
      });
    } else {
      this.setState({
        showButton4: false,
      });
    }
    if (scrollTop >= section5Offset) {
      this.setState({
        showButton5: true,
      });
    } else {
      this.setState({
        showButton5: false,
      });
    }
    if (scrollTop >= section6Offset) {
      this.setState({
        showButton5: true,
      });
    } else {
      this.setState({
        showButton5: false,
      });
    }
    if (scrollTop >= section8Offset) {
      this.setState({
        showButton8: true,
      });
    } else {
      this.setState({
        showButton8: false,
      });
    }
  };

  render() {
    const { assessment } = this.props;
    // const { currentImage, formData, fieldslogin } = this.state;
    let {
      userAssessmentUserProfileData,
      getAssessmentPackagesByIdData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;

    // console.log(
    //   "getAssessmentPackagesByIdData--->",
    //   getAssessmentPackagesByIdData
    // );

    function getDetails(field) {
      return field &&
        getAssessmentPackagesByIdData &&
        getAssessmentPackagesByIdData[field]
        ? getAssessmentPackagesByIdData[field]
        : "NA";
    }
    function getDetailsList(field) {
      return field &&
        getAssessmentPackagesByIdData &&
        getAssessmentPackagesByIdData[field]
        ? getAssessmentPackagesByIdData[field]
        : [];
    }

    let imageSrcList = [];
    let code = getDetails("code");
    let productno = 1;
    if (code) {
      let listNo = [1, 2, 3];
      if (code === "Top5TalentplusStrengths") {
        productno = 1;
        listNo = [1, 2, 3];
      } else if (code === "TalentPlusStrengths21") {
        productno = 2;
        listNo = [1, 2, 3, 4, 5];
      } else if (code === "TalentplusManagerStrengths") {
        productno = 3;
        listNo = [1, 2, 3, 4];
      } else if (code === "TalentPlusHiringStrengths") {
        productno = 4;
        listNo = [1, 2, 3, 4];
      }
      if (listNo && listNo.length > 0) {
        listNo.forEach((element) => {
          let path = "/photos/" + code + "_" + element + ".webp";
          console.log("path--->", path);
          imageSrcList.push(path);
        });
      }
    }

    // const { zoomed } = this.state;


    return (
      <div className="overflow-hidden ">


        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{
            backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={
              getAssessmentCartCountData ? getAssessmentCartCountData : 0
            }
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
          {/* Hero Section */}
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-8 text-center md:pb-10 md:pt-0 md:text-left ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Help Others To Unlock Their Talents As TalentPlusStrengths Impact Coach </h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-[#212121]">Become a part of the movement to democratize talent and happiness, uplifting lives along the way!</p>
                </div>
                <div className="flex flex-row items-center justify-center gap-4 md:justify-start md:items-center">
                  {!this.state.showButton5 && (
                    <button data-aos="fade-right" onClick={() =>
                      window.scrollTo({
                        top: this.section6Ref.current.offsetTop,
                        behavior: "smooth",
                      })
                    } className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center">  Enroll Now</button>
                  )}

                </div>
              </div>

              <div className="flex items-end justify-center w-full lg:justify-end">
                <img data-aos="fade-left" src="/img/assessment/impact-coach.webp" alt="impact-coach" className="z-0 w-full" />
              </div>
            </div>
          </div>

        </section>

        <section className="flex flex-col items-center justify-center w-full gap-10 px-4 pt-16 pb-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
          <div data-aos="fade-up" className="flex flex-row w-full gap-2 overflow-x-auto whitespace-nowrap">
            {!this.state.showButton1 && (
              <button
                onClick={() =>
                  window.scrollTo({
                    top: this.section1Ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
                className={
                  !this.state.showButton1
                    ? "border-[#2196f3]  border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer bg-[#2196f3]/10 hover:bg-[#ECE7F6] transition-all duration-150   w-full uppercase"
                    : "    border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150  w-full uppercase"
                }
              >
                Overview
              </button>
            )}

            {!this.state.showButton2 && (
              <span
                onClick={() =>
                  window.scrollTo({
                    top: this.section5Ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
                className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 w-full uppercase"
              >
                Benefits
              </span>
            )}

            {!this.state.showButton2 && (
              <span
                onClick={() =>
                  window.scrollTo({
                    top: this.section2Ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
                className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 w-full uppercase"
              >
                Why TalentPlusStrengths
              </span>
            )}

            {!this.state.showButton3 && (
              <span
                onClick={() =>
                  window.scrollTo({
                    top: this.section3Ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
                className="border text-[#212121] text-center py-4 px-4 w-full cursor-pointer hover:bg-gray-100 transition-all duration-150  uppercase"
              >
                Description
              </span>
            )}

            {!this.state.showButton4 && (
              <span
                onClick={() =>
                  window.scrollTo({
                    top: this.section4Ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
                className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 w-full uppercase"
              >
                Be The Change
              </span>
            )}

            {!this.state.showButton8 && (
              <span
                onClick={() =>
                  window.scrollTo({
                    top: this.section8Ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
                className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 w-full uppercase"
              >
                Testimonials
              </span>
            )}
          </div>
        </section>

        <section className="flex flex-col items-start w-full gap-10 px-4 pb-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row lg:divide-x">
          <div data-aos="fade-right" className="lg:w-[60%] w-full space-y-6">
            <h2 className="text-4xl font-semibold text-[#212121] leading-tight capitalize">
              If you judge a fish by its ability to climb a tree, it will always
              believe it had a wasted life.
            </h2>
            <p className="text-[#212121]">
              Imagine, like that fish, when we find ourselves trapped in roles
              or careers which don't align with our natural talents and
              passions, we too feel a sense of confusion and frustration. Each
              individual is unique, with their own set of talents and abilities.
              However, our society and surrounding often evaluates success based
              on a narrow set of criteria, pushing individuals to fit into
              predefined molds. This can lead to a struggling life and a feeling
              of wasting our true potential.
            </p>
            <p className="text-[#212121]">
              We spend a significant portion of our lives working, and it is
              essential to find work that truly resonates with us. When we love
              what we do every day, our lives become filled with purpose and
              joy. We experience a sense of fulfillment, knowing that we are in
              right path to make a meaningful contribution.
            </p>
            <p className="text-[#212121]">
              TalentPlusStrengths  is designed to make individual aware of their unique
              talent and capabilities, and utilize the unique talent to align
              with work & life. When we align our work with our passions,
              talents, and purpose, we can truly live a thriving life. Remember,
              life is too precious to spend it doing something that doesn't
              ignite our being. Have trust on self, believe in unique abilities,
              and create a life that resonates with our true essence.
              <span className="font-medium text-[#212121]">
                {" "}
                Every individual deserves to know their talent and experience a
                life filled with purpose, happiness, and fulfilment.
              </span>{" "}
            </p>
          </div>
          <div data-aos="fade-left" className="lg:w-[40%] w-full space-y-6 lg:pl-8 ">
            <h2 className="md:text-4xl text-3xl font-semibold text-[#212121] leading-tight capitalize">What Our Participants have to Say</h2>
            <>
              <Swiper
                cssMode={true}
                navigation={true}
                mousewheel={true}
                keyboard={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div>
                    <iframe className="youtube-video" src="https://www.youtube.com/embed/qchkvJ8L8rY" title="TalentPlusStrengths Impact Coach Ramya Uchil" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                    <div className="py-6 px-5 bg-[#1976D2]">
                      <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Ramya Uchil</h2>
                      <p className="tracking-wide text-white">Assistant General Manager</p>
                      <div className="flex mt-3 space-x-3">
                        <GoOrganization className="w-6 h-6 text-white" />
                        <button className="text-sm font-medium text-left text-white">Gennova Biopharmaceuticals</button>
                      </div>
                    </div>
                    <div className="mt-10 bg-white"></div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <iframe className="youtube-video" src="https://www.youtube.com/embed/S2qeTfOxDF8?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" title="TalentPlusStrengths Impact Coach Amit Sharma" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                    <div className="py-6 px-5 bg-[#1976D2]">
                      <h2 className="mb-1 text-2xl font-bold text-white capitalize">Amit sharma</h2>
                      <p className="tracking-wide text-white">Head HR</p>
                      <div className="flex mt-3 space-x-3">
                        <GoOrganization className="w-6 h-6 text-white" />
                        <button className="text-sm font-medium text-left text-white ">Zee Entertainment Enterprises Limited</button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <iframe className="youtube-video" src="https://www.youtube.com/embed/3UHncbbgD-c?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" title="TalentPlusStrengths  Impact Coach Akshatha Pai Karangutkar" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                    <div className="py-6 px-5 bg-[#1976D2]">
                      <h2 className="mb-1 text-2xl font-bold text-white capitalize">Akshatha Karangutkar</h2>
                      <p className="tracking-wide text-white">Associate Director, HR</p>
                      <div className="flex mt-3 space-x-3">
                        <GoOrganization className="w-6 h-6 text-white" />
                        <button className="text-sm font-medium text-left text-white">Samhita Social Ventures</button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <iframe className="youtube-video" src="https://www.youtube.com/embed/ByM0XngHx8g" title="TalentPlusStrengths  Impact Coach Preksha Sharma" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1"></iframe>
                    <div className="py-6 px-5 bg-[#1976D2]">
                      <h2 className="mb-1 text-2xl font-bold text-white capitalize">Preksha Sharma</h2>
                      <p className="tracking-wide text-white">Talent Acquisition Specialist</p>
                      <div className="flex mt-3 space-x-3">
                        <GoOrganization className="w-6 h-6 text-white" />
                        <button className="text-sm font-medium text-left text-white">Gennova Biopharmaceuticals</button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <iframe className="youtube-video" src="https://www.youtube.com/embed/CPtl7GKwgmA?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" title="TalentPlusStrengths  Impact Coach Abhay Deshmukh" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                    <div className="py-6 px-5 bg-[#1976D2]">
                      <h2 className="mb-1 text-2xl font-bold text-white capitalize">Abhay Deshmukh</h2>
                      <p className="tracking-wide text-white">Assistant General Manager, HR</p>
                      <div className="flex mt-3 space-x-3">
                        <GoOrganization className="w-6 h-6 text-white" />
                        <button className="text-sm font-medium text-left text-white">Gennova Biopharmaceuticals</button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>

              <button onClick={() => window.scrollTo({ top: this.section8Ref.current.offsetTop, behavior: "smooth", })} className="border border-[#2196F3] text-[#2196F3] text-lg font-semibold w-full py-3 uppercase hover:bg-[#1976D2] hover:text-white transition-all duration-100">See all videos</button>
            </>
          </div>
        </section>

        <section className="bg-[#BBDEFB]/50">
          <div ref={this.section5Ref} className="w-full px-4 py-20 mx-auto space-y-10 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0 md:space-y-20 ">
            <div className="space-y-4 md:space-y-6 ">
              <h2
                data-aos="fade-up"
                className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center"
              >
                TalentPlusStrengths Impact Coach Benefits
              </h2>
              <p data-aos="fade-up" className="text-lg xl:px-24 md:px-6 text-[#212121]">
                What distinguishes impact coach training from others in the
                industry is our empirical approach to driving lasting change.
                Our transformative coaching methodology combines the principles
                of Positive Psychology and Transpersonal Psychology with
                advanced learning theories, resulting in a comprehensive
                framework applicable to various coaching contexts.
              </p>
            </div>

            {/* Why TalentPlusStrengths  Card 1 to 3*/}
            <section className="w-full px-0 mx-auto space-y-6 xl:px-0 ">
              <div className="flex flex-row items-center gap-6 rounded-xl">
                <div className="grid grid-cols-1 gap-6 text-left lg:grid-cols-2">

                  <div data-aos="fade-right" className="group hover:bg-[#1976D2] p-8 bg-[#ffff]  border border-[#BBDEFB] flex md:flex-row flex-col gap-6 rounded-2xl hover:shadow-lg cursor-pointer">
                    <div className="flex">
                      <img src="img/Home/work.svg" alt="work.svg" className="w-16 h-16 p-2 rounded-full bg-[#BBDEFB] hover:bg-white group-hover:bg-white" />
                    </div>
                    <div className="flex-1 space-y-2">
                      <h3 className="text-2xl font-semibold text-[#212121] transition-all duration-200 group-hover:text-white">
                        Work Benefit
                      </h3>
                      <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6 group-hover:text-white">
                        Knowing your talent and using it at work gets you into flow. When in flow you are far more productive & happy.
                      </p>
                    </div>
                  </div>

                  <div data-aos="fade-right" className="group hover:bg-[#1976D2] p-8 bg-[#ffff]  border border-[#BBDEFB] flex md:flex-row flex-col gap-6 rounded-2xl hover:shadow-lg cursor-pointer">
                    <div className="flex">
                      <img src="img/Home/relationship.svg" alt="relationship.svg" className="w-16 h-16 p-2 bg-[#BBDEFB] rounded-full group-hover:bg-white" />
                    </div>
                    <div className="flex-1 space-y-2">
                      <h3 className="text-2xl font-semibold text-[#212121] transition-all duration-200 group-hover:text-white">
                        Relationship Benefit
                      </h3>
                      <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6 group-hover:text-white">
                        It allows you to not only understand yourself but also
                        people around you. It helps you connect with others far
                        more strongly.
                      </p>
                    </div>
                  </div>

                  <div data-aos="fade-right" className="group hover:bg-[#1976D2] p-8 bg-[#ffff]  border border-[#BBDEFB] flex md:flex-row flex-col gap-6 rounded-2xl hover:shadow-lg cursor-pointer">
                    <div className="flex items-center justify-center w-16 h-16 rounded-full p-2 bg-[#BBDEFB] group-hover:bg-white">
                      <img src="img/Home/achievement.svg" alt="achievement.svg" className="w-12 h-12" />
                    </div>
                    <div className="flex-1 space-y-2">
                      <h3 className="text-2xl font-semibold text-[#212121] transition-all duration-200 group-hover:text-white">
                        Achievement Benefit
                      </h3>
                      <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6 group-hover:text-white">
                        With your talent, you can achieve the impossible as
                        working on your talent gives you superior return on your
                        efforts.
                      </p>
                    </div>
                  </div>

                  <div data-aos="fade-right" className="group hover:bg-[#1976D2] p-8 bg-[#ffff]  border border-[#BBDEFB] flex md:flex-row flex-col gap-6 rounded-2xl hover:shadow-lg cursor-pointer">
                    <div className="flex">
                      <img src="img/Home/meaning.svg" alt="meaning.svg" className="w-16 h-16 p-2 rounded-full bg-[#BBDEFB] group-hover:bg-white" />
                    </div>

                    <div className="flex-1 space-y-2">
                      <h3 className="text-2xl font-semibold text-[#212121] transition-all duration-200 group-hover:text-white">
                        Meaning Benefit
                      </h3>
                      <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6 group-hover:text-white">
                        Your mindset in life changes once you know what your are
                        intrinsically good at & when you use it to bring a
                        change in the world you experience & work with meaning
                        and purpose.
                      </p>
                    </div>
                  </div>

                  <div data-aos="fade-right" className="group hover:bg-[#1976D2] p-8 bg-[#ffff]  border border-[#BBDEFB] flex md:flex-row flex-col gap-6 rounded-2xl hover:shadow-lg cursor-pointer">
                    <div className="flex">
                      <img src="img/Home/managerial.svg" alt="managerial.svg" className="w-16 h-16 p-2 rounded-full bg-[#BBDEFB] group-hover:bg-white" />
                    </div>
                    <div className="flex-1 space-y-2">
                      <h3 className="text-2xl font-semibold text-[#212121] transition-all duration-200 group-hover:text-white">
                        Managerial Benefit
                      </h3>
                      <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6 group-hover:text-white">
                        As a manager, your team loves you and provides you with
                        everything you need from them that too very happily.
                      </p>
                    </div>
                  </div>

                  <div data-aos="fade-right" className="group hover:bg-[#1976D2] p-8 bg-[#ffff]  border border-[#BBDEFB] flex md:flex-row flex-col gap-6 rounded-2xl hover:shadow-lg cursor-pointer">
                    <div className="flex">
                      <img src="img/Home/organization.svg" alt="organization.svg" className="w-16 h-16 p-2 rounded-full bg-[#BBDEFB] group-hover:bg-white" />
                    </div>

                    <div className="flex-1 space-y-2">
                      <h3 className="text-2xl font-semibold text-[#212121] transition-all duration-200 group-hover:text-white">
                        Organization Benefit
                      </h3>
                      <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6 group-hover:text-white">
                        You act as a catalyst to develop a talent and strength
                        culture at work where people believe in self and aligned
                        with organization goal to deliver success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="bg-[#1976D2]">
          <div className="items-center w-11/12 mx-auto space-y-10 2xl:w-9/12 xl:w-5/6">
            <div className="py-8">
              <div className="container flex flex-col justify-center p-4 mx-auto space-y-8 lg:space-y-0 lg:space-x-12 lg:justify-between lg:flex-row"><div className="flex flex-col space-y-4 text-center lg:text-left">
                <h3 data-aos="fade-right" className="xl:text-5xl md:text-4xl text-3xl leading-[3rem] xl:leading-[4rem] font-medium text-white md:text-left text-center capitalize">Are you ready to become a TalentPlusStrengths  Impact Coach?</h3>
              </div>
                <div className="flex flex-row items-center justify-center flex-shrink-0 rounded-full lg:justify-end">
                  <button data-aos="fade-left" onClick={() =>
                    window.scrollTo({
                      top: this.section6Ref.current.offsetTop,
                      behavior: "smooth",
                    })
                  } className="bg-[#2196F3] hover:bg-[#fff]/10 transition-all duration-150 text-white px-8 w-64 py-4 md:text-2xl text-xl flex justify-center items-center font-semibold overflow-hidden relative group cursor-pointer uppercase rounded-md">Enroll Now</button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why TalentPlusStrengths  */}
        <section ref={this.section2Ref} className="w-full px-4 py-20 mx-auto space-y-6 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <h2 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center capitalize">
            Why Should i Become a TalentPlusStrengths <br />- Certified impact Coach?</h2>
          <p data-aos="fade-up" className="text-lg text-[#212121]">
            To become a part of the movement to{" "}
            <span className="font-semibold text-[#212121]">
              democratize talent and happiness
            </span>
            , uplifting lives along the
            <br /> way!
          </p>
        </section>

        {/* Why TalentPlusStrengths  Card 1 to 3*/}
        <section className="w-full px-4 pb-20 mx-auto space-y-6 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="flex flex-row items-center gap-6 rounded-xl">
            <div className="grid grid-cols-1 gap-10 text-left lg:grid-cols-3 xl:gap-10">
              <div data-aos="fade-right" className="p-8 space-y-6 bg-[#F9FAFB] hover:bg-[#4CAF50]/10 border rounded-2xl text-center items-center hover:shadow-lg cursor-pointer">
                <div className="flex items-center justify-center">
                  <span className="w-28 h-28 bg-[#4CAF50]/8 shadow flex items-center justify-center rounded-full">
                    <img src="img/Home/build-the-foundation.svg" alt="build-the-foundation" width={192} height={192} />
                  </span>
                </div>
                <h3 className="text-3xl font-semibold text-[#212121] transition-all duration-200">
                  Build The Foundation
                </h3>
                <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6">
                  The certification is foundational. Its pave the path of
                  “Talent Coach” and develop competence in coaching field.
                  Enhancing your ability to facilitate positive change, drive
                  growth
                </p>
              </div>

              <div data-aos="fade-up" className="p-8 space-y-6 bg-[#F9FAFB] hover:bg-[#FFC107]/20 border rounded-2xl text-center items-center hover:shadow-lg cursor-pointer">
                <div className="flex items-center justify-center">
                  <span className="w-28 h-28 bg-[#FFC107]/10 shadow flex items-center justify-center rounded-full">
                    <img
                      src="img/Home/know-the-talent-pholisophy.svg"
                      alt="know-the-talent-pholisophy.svg"
                      width={192} height={192}
                    />
                  </span>
                </div>
                <h3 className="text-3xl font-semibold text-[#212121] transition-all duration-200 capitalize">
                  Know the Talent Philosophy
                </h3>
                <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6">
                  Equip you with coaching methodology, technique, and knowledge
                  to align talent and happy life. Enable coachees to embrace
                  their natural talents, leverage them effectively, and leave
                  inspired to tackle challenges with a positive mindset.
                </p>
              </div>

              <div data-aos="fade-left" className="p-8 space-y-6 bg-[#F9FAFB] hover:bg-[#673AB7]/10 border rounded-2xl text-center items-center hover:shadow-lg cursor-pointer">
                <div className="flex items-center justify-center">
                  <span className="w-28 h-28 bg-[##673AB7]/10 shadow flex items-center justify-center rounded-full">
                    <img
                      src="img/Home/make-a-difference.svg"
                      alt="make-a-difference.svg"
                      width={192}
                      height={192}
                    />
                  </span>
                </div>
                <h3 className="text-3xl font-semibold text-[#212121] transition-all duration-200 capitalize">
                  Make a Difference
                </h3>
                <p className="text-[#212121] break-all transition duration-200 ease-in-out line-clamp-6">
                  As a talent coach, you can help people to do best every day in
                  their life. With this coaching program, you can make a
                  meaningful and purpose driven change to others life.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Course Description */}
        <section ref={this.section3Ref} className="w-full pb-14 px-4 mx-auto bg-[#F9FAFB] bg-center bg-no-repeat bg-cover xl:px-0 xl:bg-contain">
          <div className="pt-16 mx-auto space-y-4 text-center ">
            <h2
              data-aos="fade-up"
              className="xl:text-5xl md:text-4xl text-3xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize"
            >
              Course Description
            </h2>
            <h2
              data-aos="fade-up"
              className=" md:text-2xl text-xl md:leading-[3rem]  font-medium text-[#212121] capitalize"
            >
              From Coursework to Certification in 3 Easy Steps
            </h2>
            <p
              data-aos="fade-up"
              className="md:text-lg text-base text-[#212121]"
            >
              Your customized TalentPlusStrengths reports and guides will give
              you <br /> an aha moment as you experience new ways to understand
              what makes you so unique.
            </p>
          </div>
          {/* Course Description 1 to 3*/}
          <div className="flex flex-col items-center w-full gap-10 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
              <div data-aos="fade-right" className="bg-white group hover:bg-[#1976D2] border border-[#E8E8E8] relative transition duration-500 rounded-xl hover:shadow-lg">
                <img className="w-full rounded-t-xl" src="/img/Home/step1.webp" alt="step1.png" width={531} height={298} />
                <div className="px-5 py-6">
                  <h3 className=" text-lg font-medium text-[#2196F3] group-hover:text-white uppercase">Step 1</h3>
                  <h2 className="md:text-2xl text-xl py-4 font-semibold text-[#212121] group-hover:text-white">Complete Your Prework</h2>
                  <div className="grid w-full grid-cols-1 gap-8 text-left xl:grid-cols-1">
                    <ul className="space-y-2 text-[#212121] group-hover:text-white   text-base">
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Complete Your Certification Application
                      </li>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Tell us more : A brief about you and your interest in
                        Talent Coaching
                      </li>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Complete Self Assessment
                      </li>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Understanding Self : 1 to 1 with Guide
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="bg-white group hover:bg-[#1976D2] border border-[#E8E8E8] relative transition duration-500 rounded-xl hover:shadow-lg">
                <img className="w-full rounded-t-xl" src="/img/Home/step2.webp" alt="step1.png" width={531} height={298} />
                <div className="px-5 py-6">
                  <h3 className=" text-lg font-medium text-[#2196F3] uppercase group-hover:text-white">Step 2</h3>
                  <h2 className="md:text-2xl text-xl py-4 font-semibold text-[#212121] group-hover:text-white">Complete Your Workshop (2 Days workshop)</h2>

                  <div className="grid w-full grid-cols-1 gap-8 text-left xl:grid-cols-1">
                    <ul className="space-y-2 text-[#212121] group-hover:text-white  text-base">
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Learn how to interpret and apply core essence of coaching
                        strengths through TalentPlusStrengths  Session
                      </li>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Maximize your coaching potential through TalentPlusStrengths:
                      </li>
                      <ul className="pl-12 list-disc">
                        <li>Talent Coaching Foundation</li>
                        <li>Talent Coaching Approach</li>
                        <li>Talent Coaching Competencies</li>
                        <li>Talent Coaching Tools</li>
                      </ul>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Get Provision Certification post workshop completion
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div data-aos="fade-left" className="bg-white group hover:bg-[#1976D2] border border-[#E8E8E8] relative transition duration-500 rounded-xl hover:shadow-lg">
                <img className="w-full rounded-t-xl" src="/img/Home/step3.webp" alt="step1.png" width={531} height={298} />
                <div className="px-5 py-6">
                  <h3 className=" text-lg font-medium text-[#2196F3] uppercase group-hover:text-white">Step 3</h3>
                  <h2 className="md:text-2xl text-xl py-4 font-semibold text-[#212121] group-hover:text-white">Complete Final Evaluation</h2>
                  <div className="grid w-full grid-cols-1 gap-8 text-left xl:grid-cols-1">
                    <ul className="space-y-2 text-[#212121] group-hover:text-white  text-base">
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Complete Your TalentPlusStrengths  Impact Coaching Certification
                        Exam
                      </li>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Submit Coaching Evaluation form from 5 coaches to
                        demonstrate your talent coaching abilities
                      </li>
                      <li className="flex">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50] group-hover:text-white" />
                        </span>
                        Get Certified as "TalentPlusStrengths  Impact Coach
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        {/* Be The Change */}
        <section className="" ref={this.section4Ref}>
          <ImpackSwiper />
        </section>


        <section className="bg-[#2196F3] w-full" ref={this.section8Ref}>
          <div className="w-full px-4 py-20 mx-auto space-y-6 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <h2 data-aos="fade-up" className="xl:text-5xl md:text-4xl text-3xl leading-[3rem] xl:leading-[4rem] font-medium text-center text-white pb-10">Let's Hear What Our Participants Have To Say</h2>

            <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">
              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/qchkvJ8L8rY" title="TalentPlusStrengths Impact Coach Ramya Uchil" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Ramya Uchil</h2>
                  <p className="tracking-wide text-white">Assistant General Manager</p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">Gennova Biopharmaceuticals</button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/S2qeTfOxDF8?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" title="TalentPlusStrengths  Impact Coach Amit Sharma" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Amit sharma</h2>
                  <p className="tracking-wide text-white">Head HR</p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">Zee Entertainment Enterprises Limited</button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/3UHncbbgD-c?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" title="TalentPlusStrengths  Impact Coach Akshatha Pai Karangutkar" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Akshatha Karangutkar</h2>
                  <p className="tracking-wide text-white">Associate Director, HR</p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">Samhita Social Ventures</button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/ByM0XngHx8g" title="TalentPlusStrengths  Impact Coach Preksha Sharma" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1"></iframe>
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Preksha Sharma</h2>
                  <p className="tracking-wide text-white">Talent Acquisition Specialist</p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">Gennova Biopharmaceuticals</button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/CPtl7GKwgmA?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" title="TalentPlusStrengths  Impact Coach Abhay Deshmukh" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Abhay Deshmukh</h2>
                  <p className="tracking-wide text-white">Assistant General Manager, HR</p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">Gennova Biopharmaceuticals</button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/n_0KYPfSmiA?si=dKbAGbTClkkRfYDy" title="TalentPlusStrengths  Impact Coach Abhay Deshmukh" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Leena surve</h2>
                  <p className="tracking-wide text-white">HR Department Manager </p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">Biopharmaceuticals Limited</button>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" className="transition-all duration-150 hover:shadow-xl">
                <iframe className="youtube-video" src="https://www.youtube.com/embed/pq93kTJACnw?si=9wDAU1HkCxElKFRK" title="TalentPlusStrengths  Impact Coach Abhay Deshmukh" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="1" />
                <div className="py-6 px-5 bg-[#1976D2]">
                  <h2 className="mb-1 text-xl font-bold text-white capitalize md:text-2xl">Tanaji Shelar</h2>
                  <p className="tracking-wide text-white">Sr. Manager, HR</p>
                  <div className="flex mt-3 space-x-3">
                    <GoOrganization className="w-6 h-6 text-white" />
                    <button className="text-sm font-medium text-left text-white">HRBP</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Us */}
        <section section ref={this.section6Ref} className="py-16 bg-[#F9F9FC] space-y-10" >
          <h2
            data-aos="fade-up"
            className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center aos-init aos-animate"
          >
            Enroll Now
          </h2>
          <div className="flex flex-col w-full gap-8 px-4 mx-auto text-gray-100 lg:flex-row lg:items-center 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
            <div data-aos="fade-right" className="w-full p-6 space-y-4 bg-white border lg:px-12">
              <div className="flex flex-row items-center gap-6">
                <div className=" mt-1 text-[#212121] flex-1">
                  <h2 className="md:text-3xl text-2xl text-[#212121] font-semibold  ">
                    TalentPlusStrengths
                    Impact Coach!
                  </h2>
                  <p className="pt-3 text-base">
                    Certification course to become a Talent Coach!
                  </p>
                </div>
              </div>

              <div className="space-y-0 border-collapse ">
                <div className="flex flex-col items-stretch py-4 lg:flex-row lg:py-0 ">
                  {/* <div className="flex items-center flex-shrink-0 w-full gap-2 px-4 lg:border-r lg:px-4 lg:w-44" >
                    <MdOutlineLocationOn className="size-8 text-[#2196F3]" />
                    <div className=" mt-1 text-[#212121] flex-1">
                      <h2 className="text-xl font-semibold text-[#212121]">Mumbai</h2>
                    </div>
                  </div> */}
                  <div className="w-full">
                    {/* <div className="w-full divide-y"> */}
                    {/* <div className="flex items-center justify-center gap-2 py-4 mx-4">
                      <MdOutlineCalendarMonth className="lg:size-8 size-6 text-[#2196F3]" />
                      <div className=" mt-1 text-[#212121] flex-1">
                        <h2 className="text-base font-medium capitalize lg:text-lg">
                          14th - 15th June 2024
                        </h2>
                      </div>
                    </div> */}
                    <div className="flex items-center justify-center gap-2 py-4 pb-8">
                      {/* <MdOutlineCalendarMonth className="lg:size-8 size-6 text-[#2196F3]" /> */}
                      <div className=" mt-1 text-[#212121] flex-1">
                        <h2 className="text-2xl font-semibold text-[#212121]">Next Workshop : Comming Soon!</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-col items-stretch py-4 border-b lg:flex-row lg:py-0 ">
                  <div className="flex items-center flex-shrink-0 w-full gap-2 px-4 lg:border-r lg:px-4 lg:w-44" >
                    <MdOutlineLocationOn className="size-8 text-[#2196F3]" />
                    <div className=" mt-1 text-[#212121] flex-1">
                      <h2 className="text-lg font-semibold ">
                        Delhi
                      </h2>
                    </div>
                  </div>
                  <div className="w-full divide-y">
                    <div className="flex items-center justify-center gap-2 p-4">
                      <MdOutlineCalendarMonth className="lg:size-8 size-6 text-[#2196F3]" />
                      <div className=" mt-1 text-[#212121] flex-1">
                        <h2 className="text-base font-medium capitalize lg:text-lg">
                          25th - 26th July 2024
                        </h2>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="flex flex-col items-stretch py-4 lg:flex-row lg:py-0 ">
                  <div className="flex items-center flex-shrink-0 w-full gap-2 px-4 lg:border-r lg:px-4 lg:w-44" >
                    <MdOutlineLocationOn className="size-8 text-[#2196F3]" />
                    <div className=" mt-1 text-[#212121] flex-1">
                      <h2 className="text-lg font-semibold ">
                        Bengaluru
                      </h2>
                    </div>
                  </div>
                  <div className="w-full divide-y">
                    <div className="flex items-center justify-center gap-2 p-4">
                      <MdOutlineCalendarMonth className="lg:size-8 size-6 text-[#2196F3]" />
                      <div className=" mt-1 text-[#212121] flex-1">
                        <h2 className="text-base font-medium capitalize lg:text-lg">
                          24th - 25th October 2024
                        </h2>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="flex flex-row items-center gap-6 py-4 pt-2">
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h2 className="text-2xl font-semibold lg:text-3xl ">
                      Contact Us
                    </h2>
                    <p className="pt-3 text-base">
                      For customer support and query, contact us.
                    </p>
                  </div>
                </div>

                <div className="flex flex-row items-center gap-6 py-4 border-b">
                  <div className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#212121]" href="#0">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x="3" y="5" width="18" height="14" rx="2" /><polyline points="3 7 12 13 21 7" />
                    </svg> */}
                    <MdOutlineLocalPhone className="h-8 w-8 text-[#212121]" />
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h2 className="text-xl font-semibold">Phone</h2>
                    <p className="text-base">+91 9833861003</p>
                  </div>
                </div>

                <div className="flex flex-row items-center gap-6 py-4 border-b">
                  <div
                    className="flex flex-shrink-0  justify-center items-center border lg:w-16 w-14 lg:h-16 h-14 bg-white rounded-full text-[#212121] "
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h2 className="text-xl font-semibold text-[#212121]">
                      Email
                    </h2>
                    <p className="text-base ">hibban@happyplus.in</p>
                  </div>
                </div>

                <div className="flex flex-row items-center gap-6 py-4 pb-3">
                  <div className="flex flex-shrink-0  justify-center items-center border lg:w-16 w-14 lg:h-16 h-14 bg-white rounded-full text-[#212121] ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <line x1="3" y1="21" x2="21" y2="21" />  <line x1="9" y1="8" x2="10" y2="8" />  <line x1="9" y1="12" x2="10" y2="12" />  <line x1="9" y1="16" x2="10" y2="16" />  <line x1="14" y1="8" x2="15" y2="8" />  <line x1="14" y1="12" x2="15" y2="12" />  <line x1="14" y1="16" x2="15" y2="16" />  <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" /></svg>
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h2 className="text-xl font-semibold ">
                      Office Address:
                    </h2>
                    <p className="text-base ">
                      HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai 400065
                    </p>
                  </div>
                </div>
              </div>

              {/* <img
                src="/img/domaim/contact-us.svg"
                alt=""
                className="flex items-center justify-center p-6 w-6/6 "
              /> */}
            </div>

            <div data-aos="fade-left" className="w-full space-y-6 ">
              <div className="space-y-2 ">
                <h2 className="text-2xl text-[#212121] font-semibold">
                  {" "}
                  Submit Details
                </h2>
                <p className="text-base text-[#212121]">
                  Fill in your details below & we will get back to you soon!
                </p>
              </div>

              <div noValidate action className="grid grid-cols-1 gap-6 pt-2">
                <div className="space-y-2 ">
                  <label
                    htmlFor="name"
                    className="text-base font-medium text-[#212121]/70 before:content-['*'] before:text-[#F14678] capitalize"
                  >
                    Full name
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["userName"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="userName"
                    name="userName"
                    placeholder="Enter Name"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["userName"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["userName"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="email"
                    className="text-base font-medium text-[#212121]/70 before:content-['*'] before:text-[#F14678]"
                  >
                    Email
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["email"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["email"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["email"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="phone"
                    className="text-base font-medium text-[#212121]/70"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md"
                    id="phone"
                    name="phone"
                    placeholder="+91 "
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="message"
                    className="text-base font-medium text-[#212121]/70"
                  >
                    {" "}
                    Message (If Any)
                  </label>
                  <textarea
                    rows={3}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md resize-none"
                    id="message"
                    name="message"
                    placeholder=""
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <button
                  type="button"
                  className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150"
                  onClick={this.loginSubmit}
                >
                  {" "}
                  Send Message
                </button>

                {this.state.saveSuccess ? (
                  <button
                    type="button"
                    className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <span>Your query has been successfully sent!</span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
SignIn.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(SignIn);
