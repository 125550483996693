import React, { Component } from "react";
import CryptoJS from "crypto-js";
import { assessmentActions, questionsActions } from "../../../_actions";
import { connect } from "react-redux";
import SingleChoice from "./ComponentV6/SingleChoice";
import ImageSingleChoice from "./ComponentV6/ImageSingleChoice";
import MultipleChoice from "./ComponentV6/MultipleChoice";
import TextBox from "./ComponentV6/TextBox";
import MultiLineText from "./ComponentV6/MultiLineText";
import RatingScale from "./ComponentV6/RatingScale";
import NPS from "./ComponentV6/NPS";
import NPSV2 from "./ComponentV6/NPSV2";
import Email from "./ComponentV6/Email";
import Number from "./ComponentV6/Number";
import Dropdown from "./ComponentV6/Dropdown";
import YesNo from "./ComponentV6/YesNo";
import Date from "./ComponentV6/Date";
import ImgType from "./ComponentV6/ImgType";
import LogoAssessmentHeader from "../Component/LogoAssessmentHeader";
import FeedbackModal from "./FeedbackModal";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";

import TestListModule from "./Modules/TestListModule";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import SurveySubmitModel from "./Modules/SurveySubmitModel";
import ConfirmSubmitModal from "./Modules/ConfirmSubmitModal";
import TimeoutModal from "./Modules/TimeoutModal";

import UserReportSummary from "./Summary/UserReportSummary";


const animatedComponents = makeAnimated();

class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.closeTimeoutModal = this.closeTimeoutModal.bind(this);
    this.state = {
      top5Talent: false,
      isDisableSubmit: false,
      SurveyCode: this.props.codeValue,
      routeMenu: "Summary",
      radioButton: false,
      radioButton2: false,
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces: false,

      surveyLanguageName: "English",
      ShowSubmitButton: false,

      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn: false,
      afterSubmitData: {
        thankyouText: "",
        bgColor: "",
        bgImage: "",
        textColor: "",
        logo: "",
      },
      alreadySubmitted: false,
      clicked: false,

      showDropdownQues: false,
      dropdownInput: "",
      scrollPosition: 0,
      IpObj: {},
      ShowNotAttempted: false,
      submitMandatory: "",
      setTimeoutRef: "",
      setTimeoutInit: 0,
      disableTimer: true,
      disableOverallTimer: false,
      timeCounter: 0,
      setIntervalRef: "",
      showReportModal: false,
      showSummaryReport: false,
      showManagerReportModal: false,
      showHiringReport: false,

      showViewReportThankYouButton: false,
      surveyLanguageCode: "",

      themeType: 2,
      showNameEditScreen: false,
      // nameFormData: {
      //   'name': '',
      //   'email': ''
      // },
      // errNameForm: {
      //   'name': '',
      //   'email': ''
      // },

      nameFormData: {
        name: '',
        email: '',
        gender: '',
        ageGroup: '',
        classes: '',
        interest: '',
        stream: ''
      },
      errNameForm: {
        name: '',
        email: '',
        gender: '',
        ageGroup: '',
        classes: '',
        interest: '',
        stream: ''
      },


      backSummary: false,
      showMainSummaryModal: false,

      showSubmitModal: false,

      crrDetail: "",
      crrCodeUserDetails: {
        "employeeId": "",
        "name": "",
        "email": "",
        "mobile": "",
        "message": ""
      },
      errorsCrrCodeUserDetails: {},
      topFiveTalent: true,
      contactExpertSection: false,
      showMyExpertList: false,
      showVideoModal: '',
      showVideoResource: false,
      showMainMenu: "Summary",

      showQuestionPanel: false,
      crrSurveyId: "",
      crrTestDetails: {},
      showConfirmSubmit: false,
      showSummaryModal: false,
      downloadStatusNo: 100,
      downloadStatusNo: 100,
      showTimeoutModal: false,
      showGenerateActive: false,
      timeoutModalLoad: false
    };


    this.handleShowMainSummaryModal = this.handleShowMainSummaryModal.bind(this);
    this.handleShowMainSummaryModalBack = this.handleShowMainSummaryModalBack.bind(this);
    this.handleExpertContactModalBack = this.handleExpertContactModalBack.bind(this);
    this.handleExpertContactModal = this.handleExpertContactModal.bind(this);
    this.handleInputExpertForm = this.handleInputExpertForm.bind(this);
    this.bookExpert = this.bookExpert.bind(this);
    this.handleExpertClick = this.handleExpertClick.bind(this);
    this.props.dispatch(assessmentActions.getTalentResources());

  }

  componentDidMount() {
    this.props.dispatch(questionsActions.emptyReducer());
    this.props.dispatch(questionsActions.getAssessmentReport());
    this.props.dispatch(questionsActions.getAssessmenrEmployeeDetails({}));
    // this.props.dispatch(questionsActions.getSurveyTakenDate({}));
    this.props.dispatch(questionsActions.getAssessmentStatus({}));
    // this.props.dispatch(questionsActions.getAssessmentQuestionList({}, "v4"));
    this.props.dispatch(questionsActions.getLanguageLabel());
    this.props.dispatch(questionsActions.getTranslateElementAll());
    this.props.dispatch(questionsActions.getAssessmenrEmployeeProduct({}));
    this.props.dispatch(assessmentActions.getTestModule({}));


    this.props.dispatch(assessmentActions.getTalentPlusEmpStatus({}));

    this.loadReportAPI()


  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        // showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted: true,
      };
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        // showThankyouScreen: true,
        instructionPage: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }


  decrementNumber = () => {
    this.setState((prevState) => ({
      downloadStatusNo: prevState.downloadStatusNo < 1 ? 0 : prevState.downloadStatusNo - 1,
    }));
  };



  prevQues = () => {
    this.setState({ changeType: "fade1" });
    this.setState({ ShowSubmitButton: false });
    this.setState({ submitMandatory: "" });
    let { questions } = this.props;
    let { questionsList } = questions;
    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex > 1 && CurrQuesIndex < questionsList.length + 1) {
        CurrQuesIndex = CurrQuesIndex - 1;

        this.setState({ CurrQuesIndex }, () => {
          // this.handleSubmitAppear();
        });
      } else {
        this.setState({
          showWelcomeScreen: false,
          showQuestionScreen: false,
          showThankyouScreen: false,
          instructionPage: true,
        });
      }
    }

    // //console.log("answersList: ", answersList)
  };

  nextQues = (type2) => {
    //console.log("Next -0----------------Question!", type2);
    // clearTimeout(this.state.setTimeoutRef);
    // clearInterval(this.state.setIntervalRef);
    window.scrollTo(0, 0);
    this.setState({ changeType: "fade", clicked: "" }, () => {
      let { questions } = this.props;
      let { questionsList, surveyScreenData, GroupQuestionOnlyList } =
        questions;
      let { CurrQuesIndex } = this.state;
      let itemQ =
        questionsList && questionsList.length > 0
          ? questionsList[CurrQuesIndex]
          : {};
      //---------------------------- basic ---------------------------------------
      let answersList = [];
      let AnswerListFinal =
        answersList && questions.answersList && questions.answersList.length > 0
          ? questions.answersList
          : [];

      if (questionsList && questionsList.length > 0) {
        if (CurrQuesIndex < questions.questionsList.length) {
          this.props.dispatch(
            questionsActions.handleRecordResTime(
              0,
              itemQ,
              AnswerListFinal,
              questionsList,
              surveyScreenData,
              GroupQuestionOnlyList
            )
          );
        }

        questionsList.forEach((ques) => {
          if (ques.templateId === 13) {
            let getIndex = AnswerListFinal.findIndex(
              (prev) => prev.questionId === ques.id
            );
            if (getIndex !== -1) {
              answersList.push({
                ...AnswerListFinal[getIndex],
                SubQuestionList: ques.SubQuestionList,
              });
            }
          } else {
            let getIndex = AnswerListFinal.findIndex(
              (prev) => prev.questionId === ques.id
            );
            if (getIndex !== -1) {
              answersList.push(AnswerListFinal[getIndex]);
            }
          }
        });
      }
      //-------------------------------------------------------------------
      let isFeedback = false;
      if (questionsList && questionsList.length > 0) {
        if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
          //----------------latestcode ---------------------------
          ////console.log("11111111111------>", CurrQuesIndex);

          let questionId =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1].id
              : "";
          let questionData =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1]
              : {};
          let flag = 0;

          if (answersList && answersList.length > 0) {
            let getIndex2 = answersList.findIndex(
              (prev) => prev.questionId === questionId
            );
            if (getIndex2 !== -1) {
              let item = answersList[getIndex2];

              //New added feedback
              if (
                item.answer &&
                questionData &&
                questionData.optionsList &&
                questionData.optionsList.length > 0
              ) {
                let optionList = questionData.optionsList;

                let getIndex5 = optionList.findIndex(
                  (prev) => prev.name === item.answer
                );
                if (getIndex5 !== -1) {
                  isFeedback = optionList[getIndex5]["isFeedback"]
                    ? true
                    : false;
                }
              }

              if (item.isMandatory) {
                let template = item.template;

                if (
                  template === 1 ||
                  template === 3 ||
                  template === 4 ||
                  template === 5 ||
                  template === 6 ||
                  template === 16 ||
                  template === 11
                ) {
                  if (!item.answer) {
                    flag = flag + 1;
                  }
                } else if (template === 13) {
                  let isAllAnswered = 0;
                  if (
                    item &&
                    item.SubQuestionList &&
                    item.SubQuestionList.length > 0
                  ) {
                    item.SubQuestionList.forEach((subQues) => {
                      let getIndex2 = AnswerListFinal.findIndex(
                        (prev) => prev.questionId === subQues.id
                      );

                      if (getIndex2 !== -1) {
                        if (
                          AnswerListFinal &&
                          AnswerListFinal[getIndex2] &&
                          AnswerListFinal[getIndex2].multiAnswer &&
                          AnswerListFinal[getIndex2].multiAnswer.length > 1
                        ) {
                          isAllAnswered++;
                        }
                      }
                    });
                  }

                  if (item.SubQuestionList.length !== isAllAnswered) {
                    flag = flag + 1;
                  }
                } else if (template === 2) {
                  if (
                    !(item && item.multiAnswer && item.multiAnswer.length > 0)
                  ) {
                    flag = flag + 1;
                  }
                } else {
                  if (!item.answer) {
                    flag = flag + 1;
                  }
                }
              }
            }
          }

          if (!flag) {
            try {
              let cryptedText = CryptoJS.AES.encrypt(
                JSON.stringify(AnswerListFinal),
                "secretkey1345100237401"
              ).toString();
              localStorage.setItem("myAnswers", JSON.stringify(cryptedText));
            } catch (error) {
              //console.log("not saved!");
            }

            ////console.log("isFeedback----------->", isFeedback);

            if (isFeedback && type2 !== 2) {
              this.setState({ showFeedbackModal: true });
            } else {
              CurrQuesIndex = CurrQuesIndex + 1;

              this.setState({ CurrQuesIndex }, () => {
                this.setState({
                  ShowNotAttempted: false,
                  showFeedbackModal: false,
                });

                this.handleSubmitAppear();
              });
            }
          } else {
            this.setState({ ShowNotAttempted: true });
          }

          // //console.log("CurrQuesIndex------>", CurrQuesIndex);
          // //console.log("questionsList.length------>", questionsList.length);
          // //console.log("itemQ------>", itemQ);

          // if (itemQ && itemQ.seconds && !this.state.disableTimer) {
          //   if (CurrQuesIndex < questionsList.length) {
          //     let setTimeoutRef = setTimeout(
          //       () => this.nextQues(),
          //       itemQ.seconds * 1000
          //     );
          //     this.setState(
          //       {
          //         timeCounter: itemQ.seconds,
          //         setTimeoutRef: setTimeoutRef,
          //         setTimeoutInit: moment().unix(),
          //       },
          //       () => {
          //         let setIntervalRef = setInterval(
          //           () =>
          //             this.setState({
          //               timeCounter:
          //                 this.state.timeCounter > 0
          //                   ? this.state.timeCounter - 1
          //                   : 0,
          //             }),
          //           1000
          //         );
          //         this.setState({ setIntervalRef: setIntervalRef });
          //       }
          //     );
          //   } else {
          //     let setTimeoutRef = setTimeout(
          //       () => this.saveAnswers(),
          //       itemQ.seconds * 1000
          //     );
          //     this.setState(
          //       {
          //         timeCounter: itemQ.seconds,
          //         setTimeoutRef: setTimeoutRef,
          //         setTimeoutInit: moment().unix(),
          //       },
          //       () => {
          //         let setIntervalRef = setInterval(
          //           () =>
          //             this.setState({
          //               timeCounter:
          //                 this.state.timeCounter > 0
          //                   ? this.state.timeCounter - 1
          //                   : 0,
          //             }),
          //           1000
          //         );
          //         this.setState({ setIntervalRef: setIntervalRef });
          //       }
          //     );
          //   }
          // }
        } else if (CurrQuesIndex === questionsList.length) {
          ////console.log("222222222------>", CurrQuesIndex);

          let questionId =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1].id
              : "";
          let questionData =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1]
              : {};

          if (answersList && answersList.length > 0) {
            let getIndex2 = answersList.findIndex(
              (prev) => prev.questionId === questionId
            );
            if (getIndex2 !== -1) {
              let item = answersList[getIndex2];

              //New added feedback
              if (
                item.answer &&
                questionData &&
                questionData.optionsList &&
                questionData.optionsList.length > 0
              ) {
                let optionList = questionData.optionsList;

                let getIndex5 = optionList.findIndex(
                  (prev) => prev.name === item.answer
                );
                if (getIndex5 !== -1) {
                  isFeedback = optionList[getIndex5]["isFeedback"]
                    ? true
                    : false;
                }
              }
            }
          }

          if (isFeedback && type2 !== 2) {
            this.setState({ showFeedbackModal: true });
          }
        }
      }
    });
  };

  submitFeedback = () => {
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;

    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex === questionsList.length) {
        this.setState({ showFeedbackModal: false }, () => {
          this.handleSubmitAppear();
        });
      } else {
        CurrQuesIndex = CurrQuesIndex + 1;
        this.setState({ CurrQuesIndex }, () => {
          this.setState({ ShowNotAttempted: false, showFeedbackModal: false });
          this.handleSubmitAppear();
        });
      }
    }
  };

  closeFeedbackModal = () => {
    this.setState({ showFeedbackModal: false });
  };

  handleSubmitAppear = () => {
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;
    setTimeout(() => {
      if (
        questionsList &&
        questionsList.length > 0 &&
        questionsList.length === CurrQuesIndex
      ) {
        this.setState({ ShowSubmitButton: true });
      } else {
        this.setState({ ShowSubmitButton: false });
      }
    }, 300);
  };

  handleRadio = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let { CurrQuesIndex } = this.state;

    if (this.state.radioButton === currentOption.id) {
      this.setState({ radioButton: null });
      this.props.dispatch(
        questionsActions.handleSubmitPreChange(
          ques,
          answersList,
          questionsList,
          "",
          0,
          surveyScreenData,
          "",
          GroupQuestionOnlyList
        )
      );
    } else {
      // //console.log("currentOption----------------------IIIIIIIIIIIIIIIIIIIIIIIII--------------------->",currentOption)
      this.setState({ radioButton: currentOption.id });
      this.props.dispatch(
        questionsActions.handleSubmitPreChange(
          ques,
          answersList,
          questionsList,
          currentOption,
          currentOption.weightage,
          surveyScreenData,
          Index,
          GroupQuestionOnlyList
        )
      );
    }

    this.setState({ radioButton: null });

    // if(!ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    // setTimeout(()=>this.checkFeedback(), 300);

    let resTime = 0;
    if (this.state.setTimeoutInit) {
      const then = moment.unix(this.state.setTimeoutInit);
      resTime = moment().diff(then, "milliseconds");
    } else {
      resTime = 0;
    }

    this.props.dispatch(
      questionsActions.handleRecordResTime(
        resTime,
        ques,
        answersList,
        questionsList,
        surveyScreenData,
        GroupQuestionOnlyList
      )
    );

    // //console.log("-----questionsListlength---->", questionsList.length);
    // //console.log("-----CurrQuesIndex---->", CurrQuesIndex);

    // if (questionsList && questionsList.length > 0 && !this.state.disableTimer) {
    //   if (questionsList.length - 1 >= CurrQuesIndex) {
    //     clearTimeout(this.state.setTimeoutRef);
    //     clearInterval(this.state.setIntervalRef);
    //   }

    //   if (CurrQuesIndex === questionsList.length) {
    //     // this.saveAnswers();
    //   } else if (CurrQuesIndex <= questionsList.length - 1) {
    //     clearTimeout(this.state.setTimeoutRef);
    //     clearInterval(this.state.setIntervalRef);
    //   }
    // }

    setTimeout(() => this.nextQues(), 300);
  };

  handleCheckInput = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    if (true) {
      this.setState({ radioButton: currentOption.id });
      // //console.log("currentOption::", currentOption)
      this.props.dispatch(
        questionsActions.handleSubmitPreChange(
          ques,
          answersList,
          questionsList,
          currentOption,
          currentOption.weightage,
          surveyScreenData,
          "",
          GroupQuestionOnlyList
        )
      );
    }

    // setTimeout(()=>this.nextQues(), 300);
  };

  goToInstructionPage = () => {
    let { questions } = this.props;
    let { surveyScreenData } = questions;

    if (surveyScreenData.isInstruction) {
      this.setState({
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: true,
        showNameEditScreen: false
      });
    } else {
      let { questionsList } = questions;
      let itemQ =
        questionsList && questionsList.length > 0 ? questionsList[0] : {};
      ////console.log("itemQ--01-->", itemQ);

      // if (itemQ && itemQ.seconds && !this.state.disableTimer) {
      //   ////console.log("Seconds--->", itemQ.seconds * 1000);
      //   let setTimeoutRef = setTimeout(
      //     () => this.nextQues(),
      //     itemQ.seconds * 1000
      //   );
      //   this.setState(
      //     {
      //       timeCounter: itemQ.seconds,
      //       setTimeoutRef: setTimeoutRef,
      //       setTimeoutInit: moment().unix(),
      //     },
      //     () => {
      //       let setIntervalRef = setInterval(
      //         () =>
      //           this.setState({
      //             timeCounter:
      //               this.state.timeCounter > 0 ? this.state.timeCounter - 1 : 0,
      //           }),
      //         1000
      //       );
      //       this.setState({ setIntervalRef: setIntervalRef });
      //     }
      //   );
      // }

      this.setState({
        showWelcomeScreen: false,
        showQuestionScreen: true,
        showThankyouScreen: false,
        instructionPage: false,
        showNameEditScreen: false
      });
    }
    window.scrollTo(0, 0);
  };

  goToQuestionPage = () => {
    let { questions } = this.props;
    let { questionsList } = questions;
    let itemQ =
      questionsList && questionsList.length > 0 ? questionsList[0] : {};
    ////console.log("itemQ--02-->", itemQ);

    // if (itemQ && itemQ.seconds && !this.state.disableTimer) {
    //   ////console.log("Seconds--->", itemQ.seconds * 1000);
    //   let setTimeoutRef = setTimeout(
    //     () => this.nextQues(),
    //     itemQ.seconds * 1000
    //   );
    //   this.setState(
    //     {
    //       timeCounter: itemQ.seconds,
    //       setTimeoutRef: setTimeoutRef,
    //       setTimeoutInit: moment().unix(),
    //     },
    //     () => {
    //       let setIntervalRef = setInterval(
    //         () =>
    //           this.setState({
    //             timeCounter:
    //               this.state.timeCounter > 0 ? this.state.timeCounter - 1 : 0,
    //           }),
    //         1000
    //       );
    //       this.setState({ setIntervalRef: setIntervalRef });
    //     }
    //   );
    // }

    this.setState({
      showWelcomeScreen: false,
      showQuestionScreen: true,
      showThankyouScreen: false,
      instructionPage: false,
    });
    window.scrollTo(0, 0);
  };

  saveAnswers(isAvoidCheck, isCheckMandatory) {
    let { questions } = this.props;
    let { surveyScreenData } = questions;

    if (surveyScreenData) {
      this.setState({
        afterSubmitData: surveyScreenData,
      });
    }

    let survey = this.state.crrSurveyId;

    //---------------------- new code ------------------------------------------

    let { questionsList } = questions;

    //---------------------------- basic ---------------------------------------
    let answersList = [];
    let AnswerListFinal =
      answersList && questions.answersList && questions.answersList.length > 0
        ? questions.answersList
        : [];

    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {
        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList,
            });
          }
        } else {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }
      });
    }
    //-------------------------------------------------------------------

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      let flag = 0;
      // //console.log(" CurrQuesIndex=====>: ",CurrQuesIndex," questionsList.length=====>: ", questionsList.length)

      if (CurrQuesIndex > 0 && CurrQuesIndex <= questionsList.length) {
        //----------------latestcode ---------------------------

        let questionId =
          questionsList &&
            questionsList[CurrQuesIndex - 1] &&
            questionsList[CurrQuesIndex - 1].id
            ? questionsList[CurrQuesIndex - 1].id
            : "";

        if (answersList && answersList.length > 0) {
          let getIndex2 = answersList.findIndex(
            (prev) => prev.questionId === questionId
          );
          if (getIndex2 !== -1) {
            let item = answersList[getIndex2];

            if (item.isMandatory && (!isAvoidCheck)) {
              let template = item.template;

              if (
                template === 1 ||
                template === 3 ||
                template === 4 ||
                template === 5 ||
                template === 6 ||
                template === 16 ||
                template === 11
              ) {
                if (!item.answer) {
                  flag = flag + 1;
                }
                // //console.log(" template: ",template," answer: ",item.answer," flag: ",flag)
              } else if (template === 13) {
                let isAllAnswered = 0;
                if (
                  item &&
                  item.SubQuestionList &&
                  item.SubQuestionList.length > 0
                ) {
                  item.SubQuestionList.forEach((subQues) => {
                    let getIndex2 = AnswerListFinal.findIndex(
                      (prev) => prev.questionId === subQues.id
                    );

                    if (getIndex2 !== -1) {
                      if (
                        AnswerListFinal &&
                        AnswerListFinal[getIndex2] &&
                        AnswerListFinal[getIndex2].multiAnswer &&
                        AnswerListFinal[getIndex2].multiAnswer.length > 1
                      ) {
                        isAllAnswered++;
                      }
                    }
                  });
                }

                if (item.SubQuestionList.length !== isAllAnswered) {
                  flag = flag + 1;
                }
              } else if (template === 2) {
                if (
                  !(item && item.multiAnswer && item.multiAnswer.length > 0)
                ) {
                  flag = flag + 1;
                }
              } else {
                if (!item.answer) {
                  flag = flag + 1;
                }
              }



            }
          }
        }

      }

      if (!flag) {


        if (isCheckMandatory) {
          this.setState({ showSubmitModal: true, showConfirmSubmit: false });

        } else {
          if (!this.state.isDisableSubmit) {
            clearTimeout(this.state.setTimeoutRef);
            clearInterval(this.state.setIntervalRef);

            //console.log("Answer Submitted!");

            setTimeout(() => {
              this.setState({ showViewReportThankYouButton: true });
            }, 10000);

            this.props.dispatch(
              questionsActions.saveAssessmentAnswers(
                AnswerListFinal,
                survey,
                this.props,
                this.state.IpObj,
                true
              )
            );

            this.setState({ showThankyouScreen: true });


          }
          this.setState({ submitMandatory: "" });


          setTimeout(() => {
            this.setState({ showTimeoutModal: false, showSubmitModal: false, showConfirmSubmit: false, showQuestionPanel: false, CurrQuesIndex: 1 });
            this.props.dispatch(assessmentActions.getTestModule({}));
            window.location.reload();
          }, 2000)


        }


      } else {

        this.setState({
          submitMandatory: "Please attempt mandatory question!",
        });

      }
    }

  }

  ansTextBoxClick(ques) {
    this.setState({ currQues: ques });
  }

  ansTextBoxChange1 = (e, Ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 1,
    };

    this.setState({ textAnswer: e.target.value });

    // //console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );
  };

  handleFeedbackInput = (e, Ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      name: e.target.value,
    };

    this.setState({ textAnswer: e.target.value });

    // //console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitFeedbackChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );
  };

  ansNPSInput = (val, ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: val,
      ans: 1,
    };

    this.setState({ textAnswer: val });

    let { CurrQuesIndex } = this.state;

    // //console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );

    let resTime = 0;
    if (this.state.setTimeoutInit) {
      const then = moment.unix(this.state.setTimeoutInit);
      resTime = moment().diff(then, "milliseconds");

    } else {
      resTime = 0;
    }

    this.props.dispatch(
      questionsActions.handleRecordResTime(
        resTime,
        ques,
        answersList,
        questionsList,
        surveyScreenData,
        GroupQuestionOnlyList
      )
    );

    // //console.log("-----questionsListlength---->", questionsList.length);
    // //console.log("-----CurrQuesIndex---->", CurrQuesIndex);

    if (questionsList && questionsList.length > 0 && !this.state.disableTimer) {
      if (questionsList.length - 1 >= CurrQuesIndex) {
        clearTimeout(this.state.setTimeoutRef);
        clearInterval(this.state.setIntervalRef);
      }

      if (CurrQuesIndex === questionsList.length) {
        this.saveAnswers();
      } else if (CurrQuesIndex <= questionsList.length - 1) {
        clearTimeout(this.state.setTimeoutRef);
        clearInterval(this.state.setIntervalRef);
      }
    }

    // if(!Ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    setTimeout(() => this.nextQues(), 300);
  };

  ansTextBoxChange2 = (e, Ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 2,
    };

    this.setState({ textAnswer: e.target.value });

    // //console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );
  };

  clickOK = () => {
    // setTimeout(()=>this.nextQues(), 300);
    this.nextQues();
  };

  clickOKLast = (Ques) => {
    // //console.log("clickOKLast; ")

    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: "",
      ans: 3,
    };

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex });
      }
    }

    let answersList2 = [];
    let AnswerListFinal =
      answersList && questions.answersList && questions.answersList.length > 0
        ? questions.answersList
        : [];
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {
        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList,
            });
          }
        } else {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }
      });
    }

    let flag = 0;
    let unattemptList = [];
    answersList2.forEach((item, index) => {
      if (item.isMandatory) {
        let template = item.template;

        if (
          template === 1 ||
          template === 3 ||
          template === 4 ||
          template === 5 ||
          template === 6 ||
          template === 16 ||
          template === 11
        ) {
          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1);
          }
        } else if (template === 13) {
          let isAllAnswered = 0;
          if (item && item.SubQuestionList && item.SubQuestionList.length > 0) {
            item.SubQuestionList.forEach((subQues) => {
              let getIndex2 = AnswerListFinal.findIndex(
                (prev) => prev.questionId === subQues.id
              );
              if (
                AnswerListFinal &&
                AnswerListFinal[getIndex2] &&
                AnswerListFinal[getIndex2].multiAnswer &&
                AnswerListFinal[getIndex2].multiAnswer.length > 1
              ) {
                isAllAnswered++;
              }
            });
            if (item.SubQuestionList.length !== isAllAnswered) {
              flag = flag + 1;
              unattemptList.push(index + 1);
            }
          }
        } else if (template === 2) {
          if (!(item && item.multiAnswer && item.multiAnswer.length > 0)) {
            flag = flag + 1;
            unattemptList.push(index + 1);
          }
        } else {
          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1);
          }
        }
      }
    });


    if (flag) {
      this.setState({ unattemptList: unattemptList });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        if (this.state.unattemptList && this.state.unattemptList.length > 0) {
          // //console.log("-----working x")
          this.setState({ CurrQuesIndex: unattemptList[0] });
          // //console.log("-----working x 1")
        }
      }
    } else {
      this.setState({ unattemptList: [] });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        // //console.log("All mandatory questions are attempted , now go to last question i.e. submit! ")
        this.setState({ CurrQuesIndex: questionsList.length });
      }
    }

    //nothing
  };

  onMandatoryAttempt = (elem) => {
    this.setState({ CurrQuesIndex: elem });
    this.setState({ mandatoryOn: true });
  };

  gotoSubmit = () => {
    let { questions } = this.props;
    let { questionsList } = questions;

    if (questionsList && questionsList.length > 0) {
      this.setState({ CurrQuesIndex: questionsList.length });
    }
  };

  // handleLanguage = (e) => {
  //   if (e.target.value === "en") {
  //     this.setState({ surveyLanguageCode: "", surveyLanguageName: "" });
  //   } else {
  //     this.setState({
  //       surveyLanguageCode: e.target.value,
  //       surveyLanguageName: "",
  //     });
  //   }
  // };

  openDropdownQuestion = (check) => {
    this.setState({ showDropdownQues: check });
  };

  handleDropdownSelect = (value) => {
    this.setState({ dropdownInput: value, showDropdownQues: false });
  };

  handleMatrixRadio = (ques, subQues, currentOption) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;


    ////console.log('------>answersList------>',answersList)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        ques,
        answersList,
        questionsList,
        currentOption,
        currentOption.weightage,
        surveyScreenData,
        subQues,
        GroupQuestionOnlyList
      )
    );

    // setTimeout(()=>this.nextQues(), 300);
  };

  handleReport = () => {
    this.props.dispatch(questionsActions.getAssessmentReport({}));
    this.setState({ showReportModal: true });
  };

  handleSummaryReport = () => {
    this.props.dispatch(questionsActions.getAssessmentReport({}));
    // this.setState({ showSummaryReport: true });
  };

  goToSummary = () => {
    window.location.reload();
  }

  viewReport = () => {
    let { questions, temp } = this.props;
    let { getAssessmenrEmployeeProductData } = questions;
    let ele = getAssessmenrEmployeeProductData;
    //console.log("ele 1-->", ele);
    if (ele && ele.productId && ele.productId.code) {
      let code = ele.productId.code;

      if (code === "Top5TalentplusStrengths") {
        //console.log("Top5TalentplusStrengths 1-->");
        this.handleSummaryReport(ele);
        this.setState({
          showReportModal: false,
          showSummaryReport: true,
          showManagerReportModal: false,
          showHiringReportModal: false,
          showSchoolReportModal: false
        });

      } else if (code === "TalentPlusStrengths21") {
        //console.log("TalentPlusStrengths21 1-->");
        // this.handleReport(ele);
        this.handleSummaryReport(ele);

        this.setState({
          showReportModal: true,
          showSummaryReport: false,
          showManagerReportModal: false,
          showHiringReportModal: false,
          showSchoolReportModal: false
        });
      } else if (code === "TalentplusManagerStrengths") {
        //console.log("TalentplusManagerStrengths 1-->");
        // this.handleManagerReport(ele);
        this.handleSummaryReport(ele);

        this.setState({
          showReportModal: false,
          showSummaryReport: false,
          showManagerReportModal: true,
          showHiringReportModal: false,
          showSchoolReportModal: false
        });
      } else if (code === "TalentPlusHiringStrengths") {
        //console.log("TalentPlusHiringStrengths 1-->");
        // this.handleHiringReport(ele);
        this.handleSummaryReport(ele);

        this.setState({
          showReportModal: false,
          showSummaryReport: false,
          showManagerReportModal: false,
          showHiringReportModal: true,
          showSchoolReportModal: false
        });
      } else if (code === "Top5TeenTalentplusStrengths") {
        // this.handleSchoolReport(ele);
        this.handleSummaryReport(ele);

        this.setState({
          showReportModal: false,
          showSummaryReport: false,
          showManagerReportModal: false,
          showHiringReportModal: false,
          showSchoolReportModal: true
        });
      }

    };
    this.setState({ crrEmpDetails: temp });
  };

  handleHiringReport = () => {
    this.props.dispatch(questionsActions.getAssessmentReport({}));
    this.setState({ showHiringReportModal: true });
  };

  closeReportModal = () => {
    this.setState({ showReportModal: false });
  };

  closeSummaryReportModal = () => {
    this.setState({ showSummaryReport: false });
  };

  closeManagerReportModal = () => {
    this.setState({ showManagerReportModal: false });
  };

  closeHiringReportModal = () => {
    this.setState({ showHiringReportModal: false });
  };


  closeReportPage = () => {
    this.setState({ showSummaryModal: false });
  };



  handleLanguage = (item) => {
    //console.log("item------------------->", item);
    if (item.shortName === "en") {
      this.setState({
        surveyLanguageCode: item.shortName,
        surveyLanguageName: "English",
        surveyLanguageRTL: false,
      });
    } else {
      this.setState({
        surveyLanguageCode: item.shortName,
        surveyLanguageName: item.name,
        surveyLanguageRTL: item.isRTL,
      });
    }
  };

  handleDownloadStatus = () => {
    let data = {
      moduleType: "ASSESSMENT",
    };
    this.props.dispatch(assessmentActions.assessmentDownloadStatus(data));
  };




  goToUpdateDetailsPage = () => {

    let { questions } = this.props;
    let { getAssessmenrEmployeeDetailsData } = questions;
    if (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name && getAssessmenrEmployeeDetailsData.email) {
      this.goToInstructionPage()
    } else {
      let { nameFormData } = this.state;
      nameFormData["name"] = getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name ? getAssessmenrEmployeeDetailsData.name : "";
      if (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name) {
        nameFormData["email"] = getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.email ? getAssessmenrEmployeeDetailsData.email : "";
      }
      this.setState({
        nameFormData,
        showNameEditScreen: true
      })
    }

    window.scrollTo(0, 0);




    let { crrTestDetails } = this.state;

    if (crrTestDetails && crrTestDetails.seconds && !this.state.disableOverallTimer) {

      let timeSec = crrTestDetails.seconds;

      let setTimeoutRef = setTimeout(
        () => {
          this.setState({ showTimeoutModal: true })
          clearTimeout(this.state.setTimeoutRef);
          clearInterval(this.state.setIntervalRef);
        },
        timeSec * 1000
      );
      this.setState(
        {
          timeCounter: timeSec,
          setTimeoutRef: setTimeoutRef,
          setTimeoutInit: moment().unix(),
        },
        () => {
          let setIntervalRef = setInterval(
            () =>
              this.setState({
                timeCounter:
                  this.state.timeCounter > 0 ? this.state.timeCounter - 1 : 0,
              }),
            1000
          );
          this.setState({ setIntervalRef: setIntervalRef });
        }
      );
    }




  };

  handleNameInput = (e) => {
    let { nameFormData } = this.state;
    nameFormData[e.target.name] = e.target.value;
    this.setState({ nameFormData })
  }

  // onUpdateDetails = () => {
  //   let { nameFormData, errNameForm } = this.state;

  //   if (nameFormData.name && nameFormData.email) {
  //     let data = {
  //       name: nameFormData.name,
  //       email: nameFormData.email
  //     };
  //     this.props.dispatch(questionsActions.updateAssessmentUserDetails(data));

  //     this.goToInstructionPage()

  //     this.setState({
  //       errNameForm: {
  //         'name': '', 'email': ''
  //       },
  //       nameFormData: {
  //         'name': '', 'email': ''
  //       }
  //     })
  //   } else {
  //     if (!nameFormData.name) {
  //       errNameForm["name"] = 'Please enter your name';
  //     }
  //     if (!nameFormData.email) {
  //       errNameForm["email"] = 'Please enter your email';
  //     } else {
  //       function validateEmail(email) {
  //         const re =
  //           /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //         return re.test(String(email).toLowerCase());
  //       }
  //       if (!validateEmail(nameFormData["email"])) {
  //         errNameForm["email"] = 'Please enter valid email';
  //       }

  //     }
  //     this.setState({ errNameForm })
  //   }

  // }

  onUpdateDetails = () => {
    let { nameFormData, errNameForm } = this.state;
    let { questions } = this.props;
    let { getAssessmentStatusData } = questions;
    let isSchool = getAssessmentStatusData && getAssessmentStatusData.isSchool ? true : false;



    if (!nameFormData.name) {
      errNameForm["name"] = 'Please enter your name';
    } else {
      errNameForm["name"] = "";
    }


    if (!nameFormData.email) {
      errNameForm["email"] = 'Please enter your email';
    } else {
      const emailPattern =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValid = emailPattern.test(nameFormData["email"]);

      // let validE = validateEmail(nameFormData["email"]);
      //console.log("isValid---------------------->", isValid)

      if (!isValid) {
        errNameForm["email"] = 'Please enter valid email';
      } else {
        errNameForm["email"] = "";
      }
    }

    if (isSchool) {
      if (!nameFormData.gender) {
        errNameForm["gender"] = 'Please select your gender';
      } else {
        errNameForm["gender"] = '';
      }

      if (!nameFormData.ageGroup) {
        errNameForm["ageGroup"] = 'Please select your Age Group';
      } else {
        errNameForm["ageGroup"] = '';
      }

      if (!nameFormData.classes) {
        errNameForm["classes"] = 'Please select your Class';
      } else {
        errNameForm["classes"] = '';
      }

      if (!nameFormData.stream) {
        errNameForm["stream"] = 'Please select your Stream';
      } else {
        errNameForm["stream"] = '';
      }

      if (!(nameFormData && nameFormData.interest.length > 0)) {
        errNameForm["interest"] = 'Please select your Interest';
      } else {
        errNameForm["interest"] = '';
      }
    }


    this.setState({ errNameForm }, () => {

      if ((isSchool ? (errNameForm["gender"] === "" && errNameForm["ageGroup"] === "") : true) && (errNameForm["name"] === "" && errNameForm["email"] === "")) {

        let data = {
          name: nameFormData.name,
          email: nameFormData.email
        };

        let interest_list = nameFormData && nameFormData.interest && nameFormData.interest.length > 0 ? nameFormData.interest.map((ele) => (ele.value)).join(", ") : "";

        if (isSchool) {
          data["gender"] = nameFormData.gender;
          data["ageGroup"] = nameFormData.ageGroup;
          data["classes"] = nameFormData.classes;
          data["stream"] = nameFormData.stream;
          data["interest"] = interest_list;
        }

        //console.log("data---->", data)

        this.props.dispatch(questionsActions.updateAssessmentUserDetails(data));

        // if (isSchool) {
        //   this.goToTestPage()
        // } else {
        //   this.goToInstructionPage()
        // }

        this.setState({
          errNameForm: {
            'name': '', 'email': '', 'gender': '', 'ageGroup': '', 'classes': '', 'stream': '', 'interest': ''  // Reset error for gender field
          },
          nameFormData: {
            'name': '', 'email': '', 'gender': '', 'ageGroup': '', 'classes': '', 'stream': '', 'interest': ''  // Reset form for gender field
          }
        })


      }
    })

  }

  handleGenderChange = (e) => {
    const { nameFormData } = this.state;
    const selectedGender = e.target.value;

    this.setState({
      nameFormData: {
        ...nameFormData,
        gender: selectedGender
      }
    });
  }

  handleAgeGroupChange = (e) => {
    const { nameFormData } = this.state;
    const selectedAgeGroup = e.target.value;

    this.setState({
      nameFormData: {
        ...nameFormData,
        ageGroup: selectedAgeGroup
      }
    });
  }



  handleClassesChange = (e) => {
    const { nameFormData } = this.state;
    const selectedClasses = e.target.value;

    this.setState({
      nameFormData: {
        ...nameFormData,
        classes: selectedClasses
      }
    });
  }


  handleStreamChange = (e) => {
    const { nameFormData } = this.state;
    const selectedStream = e.target.value;

    this.setState({
      nameFormData: {
        ...nameFormData,
        stream: selectedStream
      }
    });
  }



  handleChange = (selectedOption) => {
    // this.setState({ selectedOption }, () =>
    //   //console.log(`Option selected:`, this.state.selectedOption)
    // );
    // this.setState({ selectedOption: selectedOption });
    const { nameFormData } = this.state;

    this.setState({
      nameFormData: {
        ...nameFormData,
        interest: selectedOption
      }
    });


  };

  loadExpertData = () => {
    let { questions } = this.props;
    let { getAssessmenrEmployeeDetailsData } = questions;

    if (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.id) {
      let eleUser = getAssessmenrEmployeeDetailsData;
      let temp2 = {
        employeeId: eleUser && eleUser.id ? eleUser.id : "",
        name: eleUser && eleUser.name ? eleUser.name : "",
        email: eleUser && eleUser.email ? eleUser.email : "",
        mobile: eleUser && eleUser.mobile ? eleUser.mobile : ""
      };
      this.setState({ crrCodeUserDetails: temp2 });
    }

    this.props.dispatch(assessmentActions.getExpertDetailsList());
    this.props.dispatch(assessmentActions.getExpertOrderBookList());
  }


  handleShowMainSummaryModalBack() {

    this.setState({ showMainSummaryModal: true, showMyExpertList: false, backSummary: false, top5Talent: false });
  }

  handleShowMainSummaryModal(ele) {

    this.loadExpertData()
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());

    this.setState({ showMainSummaryModal: false, backSummary: true, showMyExpertList: false, crrDetail: ele, top5Talent: true });
  }

  handleExpertContactModal(ele) {
    this.loadExpertData()
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());
    this.setState({
      contactExpertSection: ele, topFiveTalent: false
    });
  }

  handleExpertContactModalBack() {
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());
    this.setState({
      contactExpertSection: false, topFiveTalent: true
    });
  }

  handleInputExpertForm(value, name) {
    let { crrCodeUserDetails, errorsCrrCodeUserDetails } = this.state;
    crrCodeUserDetails[name] = value;
    errorsCrrCodeUserDetails[name] = "";
    this.setState({ crrCodeUserDetails, errorsCrrCodeUserDetails });
  }

  // inputChangeUpdateUser = (e) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   let fieldsUserUpdate = this.state.fieldsUserUpdate;
  //   let errorsUpdateQuote = this.state.errorsUpdateQuote;
  //   fieldsUserUpdate[name] = value;
  //   errorsUpdateQuote[name] = "";
  //   //console.log(name, value);
  //   this.setState({ fieldsUserUpdate, errorsUpdateQuote });
  // }

  bookExpert(userDetails, expertDetails) {

    if (this.handleValidationUpdateUser()) {
      let data = {
        "name": userDetails.name,
        "email": userDetails.email,
        "mobile": userDetails.mobile,
        "message": userDetails.message,
        "expertId": expertDetails.id,
      }
      //console.log("--------->data<---------123123123131-------", data)

      this.props.dispatch(assessmentActions.userAssessmentExpertOrderPlace(data));

    }

  }

  handleValidationUpdateUser = () => {
    let crrCodeUserDetails = this.state.crrCodeUserDetails;
    let errorsCrrCodeUserDetails = {};
    let formIsValid = true;

    //name
    if (!crrCodeUserDetails["name"] || crrCodeUserDetails["name"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["name"] = "Name cannot be empty";
    }

    //email
    if (!crrCodeUserDetails["email"] || crrCodeUserDetails["email"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["email"] = "Email cannot be empty";
    }
    //mobile
    if (!crrCodeUserDetails["mobile"] || crrCodeUserDetails["mobile"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["mobile"] = "Mobile cannot be empty";
    }
    //message
    if (!crrCodeUserDetails["message"] || crrCodeUserDetails["message"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["message"] = "Message cannot be empty";
    }
    //console.log("errorsCrrCodeUserDetailserrorsCrrCodeUserDetails:::::", errorsCrrCodeUserDetails);
    this.setState({ errorsCrrCodeUserDetails: errorsCrrCodeUserDetails });
    return formIsValid;
  }

  handleExpertClick() {
    const { history } = this.props;
    history.push('/assessment/expert'); // Replace '/expert-page' with the actual route of your expert page
  }

  viewMyExpert = () => {
    this.loadExpertData()
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());
    this.setState({ backSummary: true, showMyExpertList: true })

  }

  handlePlayVideo = (dimension) => {
    //console.log("dimension:::::", dimension);
    this.setState({ showVideoModal: dimension });
  }

  closeVideoModal = () => {
    this.setState({ showVideoModal: '' });
  }

  viewMyVideoReport = () => {
    this.setState({ showVideoResource: true })
  }

  closeVideoCourse = () => {
    this.setState({ showVideoResource: false })
  }



  handleMainMenu = (value) => {
    this.setState({ showMainMenu: value }, () => {
      if (value === "Summary") {
        this.setState({ backSummary: false, top5Talent: false })
      } else if (value === "VideoTutorials") {
        this.viewMyVideoReport();
      } else if (value === "Report") {
        this.viewReport();
      } else if (value === "Experts") {
        this.viewMyExpert();
      }
    });
  }


  handleTestSelect = (item) => {
    let { crrSurveyId } = this.state;

    if (item.surveyId === crrSurveyId) {
      this.setState({ crrSurveyId: "", crrTestDetails: {} })
    } else {
      this.setState({ crrSurveyId: item.surveyId, crrTestDetails: item })
    }

    this.setState({ showThankyouScreen: false, })

  }

  handleTestClick = () => {
    let { crrSurveyId, crrTestDetails } = this.state;
    //console.log("--------------------------------------------------------------", crrSurveyId)
    if (crrSurveyId) {
      let data = {
        surveyId: crrSurveyId
      }
      this.props.dispatch(questionsActions.getAssessmentQuestionList(data, "v4"));
      this.setState({ showQuestionPanel: true, showWelcomeScreen: true, showThankyouScreen: false, submitSucces: false })




    }
  }


  goToTestPage = () => {
    this.setState({ showQuestionPanel: false, crrSurveyId: "", crrTestDetails: {} })
  }

  handleSubmitOpenModel = () => {
    this.saveAnswers(false, true);
  };

  handleSubmitCloseModal = () => {
    clearTimeout(this.state.setTimeoutRef);
    clearInterval(this.state.setIntervalRef);
    this.setState({ showSubmitModal: false, showConfirmSubmit: false, showTimeoutModal: false, });
  };


  closeFinalSubmitConfirm = () => {
    this.setState({ showSubmitModal: true, showConfirmSubmit: false });
  };


  handleFinalSubmitConfirm = () => {
    this.onFinalSubmit();
  };


  onFinalSubmit = () => {
    this.setState({ timeoutModalLoad: true })
    this.saveAnswers(true);
    setTimeout(() => {
      this.setState({ showTimeoutModal: false, showSubmitModal: false, showConfirmSubmit: false, showQuestionPanel: false, CurrQuesIndex: 1 });
      this.props.dispatch(assessmentActions.getTestModule({}));
      window.location.reload();
    }, 2000)
  }

  handleBackButton = () => {
    this.setState({
      showQuestionPanel: false,
    });
  }

  loadReportAPI = () => {

    let data1 = {
      surveyId: "66336ca4ba900057d751ff06"
    };
    this.props.dispatch(assessmentActions.getPersonalityIndex_User(data1));

    let data2 = {
      surveyId: "663a0fae8312d8618444d304"
    }
    this.props.dispatch(assessmentActions.getWorkValueDimensionScore_User(data2));

    let data3 = {
      surveyId: "663376deba900057d752056d"
    }
    this.props.dispatch(assessmentActions.getInterestDimensionScore_User(data3));

    let data4 = {
      surveyId: "666aa3e7e44c722a2beb7ce9"
    }
    this.props.dispatch(assessmentActions.getAptitudeTestScore_User(data4));


  }



  handleShowReport = () => {
    this.setState({ showGenerateActive: true });
    this.loadReportAPI();
    // this.props.dispatch(assessmentActions.getCareerFilamentScore_User({}));
    this.props.dispatch(assessmentActions.generateCareerReport({}));
    this.interval = setInterval(this.decrementNumber, 350);
    // setTimeout(() => {
    //   this.interval = setInterval(this.decrementNumber, 400);
    //   // setTimeout(() => {
    //   //   window.location.reload();
    //   //   //console.log("complete!@")
    //   // }, 20000);
    // }, 3000);
  }


  closeTimeoutModal = () => {
    this.setState({ showTimeoutModal: false });
  }

  submitTimeoutModal = () => {
    this.saveAnswers()
    this.setState({ showTimeoutModal: false });
    setTimeout(() => {
      this.setState({ showTimeoutModal: false, showSubmitModal: false, showConfirmSubmit: false, showQuestionPanel: false, CurrQuesIndex: 1 });
      this.props.dispatch(assessmentActions.getTestModule({}));

      window.location.reload();

    }, 2000)
  }


  render() {

    const { downloadStatusNo, showSummaryModal, routeMenu, crrSurveyId, crrTestDetails } = this.state;
    let { questions, assessment, onLogout, handleTestPayment } = this.props;
    let { userAssessmentExpertOrderPlaceData, getExpertOrderBookListData, getTalentResourcesData, getTestModuleData,
      generateCareerReportLoading,
      getTalentPlusEmpStatusData,
      getCareerFilamentScoreData,
      getCareerSurveyListData,
      getPersonalityIndexData,
      getWorkValueDimensionScoreData,
      getInterestDimensionScoreData,
      getAptitudeTestScoreData
    } = assessment;

    let {
      getAssessmentStatusData,
      getAssessmentReportByEmpIDData,
      getAssessmenrEmployeeDetailsData,
      questionsList,
      answersList,
      surveyStatus,
      GroupQuestionOnlyList,
      getTranslateElementAllData,
      loading,
      getSurveyTakenDateData
    } = questions;

    let check =
      getAssessmentStatusData && getAssessmentStatusData.check
        ? getAssessmentStatusData.check
        : 0;
    let isSchool =
      getAssessmentStatusData && getAssessmentStatusData.isSchool
        ? true
        : false;


    // //console.log('getAssessmentStatusData', getAssessmentStatusData);

    let createdAt =
      getTalentPlusEmpStatusData && getTalentPlusEmpStatusData.createdAt
        ? moment(parseInt(getTalentPlusEmpStatusData.createdAt))
          .utcOffset("+05:30")
          .format("DD MMMM, YYYY")
        : "";


    let {
      timeoutModalLoad,
      showFeedbackModal,
      CurrQuesIndex,
      afterSubmitData,
      clicked,
      ShowNotAttempted,
      themeType,
      nameFormData,
      errNameForm,
      showQuestionPanel,
      showConfirmSubmit,
      showTimeoutModal,
      timeCounter,
      showGenerateActive,
      showReportModal,
      surveyLanguageCode,
      surveyLanguageName
    } = this.state;


    // //console.log('showMainMenushowMainMenushowMainMenushowMainMenushowMainMenu', showMainMenu);

    let { UserRespondantName } = this.props;


    const isMobile = window.innerWidth <= 700;

    ////console.log('isMobile: ',isMobile)

    // //console.log(
    //   "setTimeoutInit:---------------------------------> ",
    //   this.state.setTimeoutInit
    // );
    ////console.log("questionsList:---------------------------------> ", questionsList)
    // //console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // //console.log("surveyStatus:---------------------------------> ", surveyStatus)

    let surveyScreenData = {};
    if (questions.surveyScreenData) {
      surveyScreenData = { ...questions.surveyScreenData };
      // surveyScreenData["name"] = "UMatter - Employee Engagement Survey";
      // surveyScreenData["logo"] = "img/companylogo/kotaklogo.png";
      // surveyScreenData["logo2"] = "img/companylogo/umatters.png";

      // if(isMobile){
      //   surveyScreenData["logo2"] = "img/companylogo/kotaklogo2.png";
      // }
      // else{
      //   surveyScreenData["logo2"] = "img/companylogo/kotakbanner.png";
      // }

      // surveyScreenData["logo"] = "img/companylogo/umatters.png";
    }

    let bgImage = "";
    let bgColor = "";
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;
    }

    // //console.log("CurrQuesIndex: ", CurrQuesIndex);

    // //console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // //console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // //console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = [];
    let instructionTextDislay = [];

    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText;
    }

    ////console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = "";
    let bgType = "";

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // //console.log("this.state.unattemptList : ", this.state.unattemptList );

    let LabelObj = questions.getLanguageLabelData;
    ////console.log("surveyScreenData: ", surveyScreenData);

    ////console.log("afterSubmitData: ", afterSubmitData);

    ////console.log("surveyStatus: ", surveyStatus);

    let LanguageList = questions.langList;
    ////console.log("LanguageList: ", LanguageList);

    ////console.log("surveyScreenData: ", surveyScreenData);

    function showRadioMatrix(SubQues, option) {
      let getIndex1 = answersList.findIndex(
        (prev) => prev.questionId === SubQues.id
      );
      let getIndex4 = answersList[getIndex1]["multiAnswer"].findIndex(
        (prev) => prev.optionHead === option.heading
      );
      let getIndex3 = answersList[getIndex1]["multiAnswer"].findIndex(
        (prev) => prev.optionId === option.id
      );

      if (getIndex4 !== -1) {
        if (getIndex3 !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    function FormatMatriXOptionHeading(question, isOption) {
      let paraObjShow = {};
      if (question && question.optionsList && question.optionsList.length > 0) {
        question.optionsList.forEach((item) => {
          if (paraObjShow && paraObjShow[item.heading]) {
            paraObjShow[item.heading] += 1;
          } else {
            paraObjShow[item.heading] = 1;
          }
        });
      }

      let headingList = [];
      let tempKey = Object.keys(paraObjShow);
      if (tempKey && tempKey.length > 0) {
        tempKey.forEach((item) => {
          headingList.push({
            name: item,
            span: paraObjShow[item],
          });
        });
      }

      if (isOption) {
        let OptionList = [];
        if (headingList && headingList.length > 0) {
          headingList.forEach((heading) => {
            if (
              question &&
              question.optionsList &&
              question.optionsList.length > 0
            ) {
              question.optionsList.forEach((option) => {
                if (heading.name === option.heading) {
                  OptionList.push(option);
                }
              });
            }
          });
        }
        return OptionList;
      } else {
        return headingList;
      }
    }

    function TranslateQuestion(surveyLanguageCode, question) {
      return surveyLanguageCode !== ""
        ? question &&
          question.transl &&
          question.transl[surveyLanguageCode] &&
          question.transl[surveyLanguageCode].name
          ? question.transl[surveyLanguageCode].name
          : question.name
        : question.name;
    }

    function TranslationOptionMatrix(
      question,
      heading,
      surveyLanguageCode,
      type
    ) {
      let optionId = "";
      if (question && question.optionsList && question.optionsList.length > 0) {
        let Index = question.optionsList.findIndex(
          (prev) => prev.heading === heading
        );
        optionId = question.optionsList[Index].id;
      }
      let text =
        surveyLanguageCode !== ""
          ? question &&
            question.transl &&
            question.transl[surveyLanguageCode] &&
            question.transl[surveyLanguageCode].headingList &&
            question.transl[surveyLanguageCode].headingList[optionId]
            ? question.transl[surveyLanguageCode].headingList[optionId]
            : heading
          : heading;

      let arr = text.split("-");
      if (type === 1) {
        return arr && arr[0] ? arr[0] : text;
      }
      if (type === 2) {
        return arr && arr[0] ? arr[0] : text;
      } else if (type === 3) {
        return arr && arr[1] ? arr[1] : "";
      } else {
        return text;
      }
    }


    let { getSurveyTemplateByCodeData } = this.props;
    function SurveyAttributes(name) {
      return surveyLanguageCode !== ""
        ? getSurveyTemplateByCodeData &&
          getSurveyTemplateByCodeData.transl &&
          getSurveyTemplateByCodeData.transl[surveyLanguageCode] &&
          getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]
          ? getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]
          : ""
        : getSurveyTemplateByCodeData && getSurveyTemplateByCodeData[name]
          ? getSurveyTemplateByCodeData[name]
          : "";
    }


    function MandatoryMsg(Ques) {
      let Text = "";
      let Color = "";
      if (Ques.isMandatory) {
        if (Ques.templateId === 13) {
          Text = SurveyAttributes("allmandatoryText")
            ? SurveyAttributes("allmandatoryText") + "*"
            : "All questions are mandatory*";
          Color = "#eb3434";
        } else {
          Text = "*";
          Color = "#eb3434";
        }
      }
      return {
        text: Text,
        color: Color,
      };
    }

    function showNotCompletedFn(Que) {
      let answersList = [];
      let AnswerListFinal =
        answersList && questions.answersList && questions.answersList.length > 0
          ? questions.answersList
          : [];
      let check = false;
      if (ShowNotAttempted) {
        let getIndex = AnswerListFinal.findIndex(
          (prev) => prev.questionId === Que.id
        );
        if (getIndex !== -1) {
          check =
            AnswerListFinal[getIndex].multiAnswer.length !== 2 ? true : false;
        }
      }

      // //console.log("showNotCompletedFn===>",check)
      return check;
    }


    function CompletedCountV1(list) {
      let list2 = [];
      if (list && list.length > 0) {
        list2 = list.filter((prev) => prev.answer !== "");
      }
      // //console.log("list2::::", list2);
      if (list2 && list2.length > 0) {
        return list2.length;
      } else {
        return 0;
      }
    }

    let answerCount = CompletedCountV1(answersList);
    // //console.log("answerCount::::", answerCount);

    let completedMilestone = answerCount;


    function getGroupIndex(QuesId) {
      ////console.log("QuesId---------->",QuesId)

      if (GroupQuestionOnlyList && GroupQuestionOnlyList.length > 0) {
        let getIndex = GroupQuestionOnlyList.findIndex(
          (prev) => prev.id === QuesId
        );
        if (getIndex !== -1) {
          ////console.log("match---------->",getIndex+1)

          return (getIndex + 1).toString() + ". ";
        } else {
          return "";
        }
      } else {
        return "";
      }
    }

    // //console.log(
    //   "questionsList---------------------------------->",
    //   questionsList
    // );
    // //console.log("answersList--------------------- ---->", answersList);

    function functionNameVariable(quesName) {
      return quesName && quesName.toString()
        ? quesName.toString().replace("[variable]", UserRespondantName)
        : quesName;
    }

    function getSecondsToTime(given_seconds) {
      var seconds = parseInt(given_seconds);
      var format =
        (Math.floor(moment.duration(seconds, "seconds").asHours()) < 10
          ? Math.floor(moment.duration(seconds, "seconds").asHours())
          : Math.floor(moment.duration(seconds, "seconds").asHours())) +
        ":" +
        (moment.duration(seconds, "seconds").minutes() < 10
          ? "0" + moment.duration(seconds, "seconds").minutes()
          : moment.duration(seconds, "seconds").minutes()) +
        ":" +
        (moment.duration(seconds, "seconds").seconds() < 10
          ? "0" + moment.duration(seconds, "seconds").seconds()
          : moment.duration(seconds, "seconds").seconds());
      return format;
    }
    // //console.log("surveyLanguageCode------------>", surveyLanguageCode);

    const GetTextByLanguage = (name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = "";
        if (
          getTranslateElementAllData &&
          getTranslateElementAllData.length > 0
        ) {
          let getIndex = getTranslateElementAllData.findIndex(
            (prev) => prev.name === name
          );
          if (getIndex !== -1) {
            sentence =
              getTranslateElementAllData &&
                getTranslateElementAllData[getIndex] &&
                getTranslateElementAllData[getIndex]["transl"] &&
                getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode]
                ? getTranslateElementAllData[getIndex]["transl"][
                surveyLanguageCode
                ]
                : "";
          }
        }
        //console.log("sentence------------>", sentence);
        //console.log("surveyLanguageCode------------>", surveyLanguageCode);


        return sentence ? sentence : text;
      } else {
        return text;
      }
    };

    const GetDetailsByLanguage = (element, name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence =
          element &&
            element["details"] &&
            element["details"]["transl"] &&
            element["details"]["transl"][surveyLanguageCode] &&
            element["details"]["transl"][surveyLanguageCode][name]
            ? element["details"]["transl"][surveyLanguageCode][name]
            : "";
        return sentence ? sentence : text;
      } else {
        return text;
      }
    };

    function EditButtonFn(name, text) {
      return <>{GetTextByLanguage(name, text)}</>;
    }

    function EditDetailsFn(element, name, text) {
      return <>{GetDetailsByLanguage(element, name, text)}</>;
    }

    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    function getTheme(field, init) {
      if (themeType === 2) {
        if (field === "backgroundImage") {
          return 'url("/img/survey-light-bg.webp")';
        } else if (field === "welcomeImage") {
          return "url('/img/assessment-welcome-large.webp')";
        } else if (field === "text") {
          return "#212121";
        } else if (field === "lightblue") {
          return hexToRgbA("#2196F3", "0.12");
        } else if (field === "textblue") {
          return "#2196F3";
        } else if (field === "lightblue") {
          return hexToRgbA("#2196F3", "0.12");
        } else if (field === "hoverActive") {
          return (
            "hover:bg-[" +
            hexToRgbA("#2196F3", "0.12") +
            "] hover:border-[#2196F3] hover:text-[#2196F3] "
          );
        } else if (field === "grayheader") {
          return " bg-[" + hexToRgbA("#212121", "0.05") + "] ";
        } else if (field === "textOption") {
          return "border-[#212121] text-[#212121] ";
        } else {
          return init;
        }
      } else {
        return init;
      }
    }

    let crrEmpDetails = getAssessmenrEmployeeDetailsData;

    const colourOptions = [
      { value: 'Sports', label: 'Sports' },
      { value: 'Reading', label: 'Reading' },
      { value: 'Music', label: 'Music' },
      { value: 'Travel', label: 'Travel' },
      { value: 'FoodandCooking', label: 'Food and Cooking' },
      { value: 'ArtsandCrafts', label: 'Arts and Crafts' },
      { value: 'FitnessandWellness', label: 'Fitness and Wellness' },
      { value: 'Technology', label: 'Technology' },
      { value: 'NatureandOutdoors', label: 'Nature and Outdoors' },
    ]

    //console.log('Teen Career', isSchool)

    let isNameEditScreen = false;

    isNameEditScreen = loading ? false : (!(getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name && getAssessmenrEmployeeDetailsData.email));
    // //console.log('Teen isNameEditScreen', isNameEditScreen)
    // //console.log('Teen getAssessmenrEmployeeDetailsData', getAssessmenrEmployeeDetailsData)

    function getQuesDescWithCount(text, count) {
      if (text) {
        let final = text.replace("_COUNT_", (count).toString());
        return final.toString();
      } else {
        return text;
      }
    }



    let questionCount = questionsList && questionsList.length > 0 ? questionsList.length : 0;
    function getAnsweredCount(answersList) {

      let count = 0;
      if (answersList && answersList.length > 0) {
        answersList.forEach((item) => {
          let template = item.template;

          if (
            template === 1 ||
            template === 3 ||
            template === 4 ||
            template === 5 ||
            template === 6 ||
            template === 16 ||
            template === 11
          ) {
            if (item.answer) {
              count = count + 1;
            }
          } else if (template === 2) {
            if (
              (item && item.multiAnswer && item.multiAnswer.length > 0)
            ) {
              count = count + 1;
            }
          } else {
            if (item.answer) {
              count = count + 1;
            }
          }




        })
      }

      return count;

    }
    let Unanswered = questionCount - getAnsweredCount(answersList)
    // //console.log('questionCount=======================>:', questionCount);
    // //console.log('answersList=======================>:', getAnsweredCount(answersList));
    // //console.log('Unanswered=======================>:', Unanswered);



    function isQuesAnsComplete(answersList, quesId) {
      let count = 0;

      if (answersList && answersList.length > 0) {
        answersList.forEach((item) => {
          if (quesId.toString() === item.questionId.toString()) {

            let template = item.template;
            if (item.answer) {
            } else {
            }

            if (
              template === 1 ||
              template === 3 ||
              template === 4 ||
              template === 5 ||
              template === 6 ||
              template === 16 ||
              template === 11
            ) {
              if (item.answer) {
                count = count + 1;
              }
            } else if (template === 2) {
              if (
                (item && item.multiAnswer && item.multiAnswer.length > 0)
              ) {
                count = count + 1;
              }
            } else {
              if (item.answer) {
                count = count + 1;
              }
            }

          }

        })
      }

      return count;
    }

    function getQuesAnswer(answersList, quesId) {
      let answer = "";

      if (answersList && answersList.length > 0) {
        answersList.forEach((item) => {
          if (quesId.toString() === item.questionId.toString()) {

            let template = item.template;


            if (
              template === 1 ||
              template === 3 ||
              template === 4 ||
              template === 5 ||
              template === 6 ||
              template === 16 ||
              template === 11
            ) {
              if (item.answer) {
                answer = item.answer;
              }
            } else if (template === 2) {
              if (
                (item && item.multiAnswer && item.multiAnswer.length > 0)
              ) {
                answer = item.multiAnswer.map((ele) => (ele)).join(",");
              }
            } else {
              if (item.answer) {
                answer = item.answer;
              }
            }

          }

        })
      }

      return answer;
    }



    let categoryQuesTemp = [];
    let categoryQuestions = [];
    if (questionsList && questionsList.length > 0) {

      if (crrTestDetails && crrTestDetails.type === 4) {


        // Function to get unique values based on a property (e.g., 'id')
        function getUniquePropertyValues(arr, prop) {
          return arr.reduce((unique, obj) => {
            if (!unique.some(o => o[prop] === obj[prop])) {
              unique.push(obj);
            }
            return unique;
          }, []).map(obj => obj[prop]);
        }

        function isQuesIdListCompleted(list, para) {
          let isCompleted = false;
          let completedLength = 0;
          if (list && list.length > 0) {
            let quesList = list.filter(prev => prev.parameterDimensionName === para);
            if (quesList && quesList.length > 0) {

              completedLength = quesList.filter((ele) => (isQuesAnsComplete(answersList, ele.id) > 0 ? true : false)).length


              if (quesList.length === completedLength) {
                isCompleted = true
              }
            }
          }
          return isCompleted
        }



        function quesByParaList(list, para) {
          let quesListV2 = [];
          if (list && list.length > 0) {
            let quesList = list.filter(prev => prev.parameterDimensionName === para);
            if (quesList && quesList.length > 0) {
              for (var i = 0; i < quesList.length; i++) {
                let quesData = quesList[i];
                let tempData = {
                  ...quesData,
                  "isComplete": isQuesAnsComplete(answersList, quesData.id) > 0 ? true : false
                }
                quesListV2.push(tempData)

              }
            }
          }
          return quesListV2
        }

        // Usage
        let questionsListV2 = questionsList.map((ele) => ({
          ...ele, parameterDimensionName: ele && ele.parameterDimensionId && ele.parameterDimensionId.name ? ele.parameterDimensionId.name : ""
        }))
        let uniqueList = getUniquePropertyValues(questionsListV2, 'parameterDimensionName');

        if (uniqueList && uniqueList.length > 0) {
          categoryQuestions = uniqueList.map((ele, index) => ({
            "name": ele,
            "list": quesByParaList(questionsListV2, ele),
            "isComplete": isQuesIdListCompleted(questionsListV2, ele)
          }))
        }



      } else {
        const chunkSize = parseInt((questionsList.length / 4) + 1);
        for (let i = 0; i < questionsList.length; i += chunkSize) {
          const chunk = questionsList.slice(i, i + chunkSize);
          categoryQuesTemp.push(chunk);
        }

        categoryQuesTemp.forEach((ele, index) => {
          let tempX = [];
          let countX = 0;
          let totalX = 0;
          ele.forEach((inner) => {
            totalX++

            let tempY = {
              "questionId": inner.id,
              "answer": getQuesAnswer(answersList, inner.id),
              "templateId": inner.templateId,
              "isComplete": isQuesAnsComplete(answersList, inner.id) > 0 ? true : false
            }
            tempX.push(tempY)
            if (tempY && tempY.isComplete) {
              countX++
            }
          })
          categoryQuestions.push({
            "name": crrTestDetails && crrTestDetails.name ? crrTestDetails.name + " Part " + (index + 1).toString() : "",
            "list": tempX,
            "isComplete": (totalX === countX) ? true : false
          })
        })
      }
    }


    //console.log('categoryQuestions=======================>:', categoryQuestions);
    let showContinue = 0;
    let count = 0;
    if (getTestModuleData && getTestModuleData.length > 0) {
      for (var i = 0; i < getTestModuleData.length; i++) {
        let item = getTestModuleData[i];
        if (item && item.isComplete) {
          count++
        }
        if (crrSurveyId === item.surveyId) {
          showContinue = 1;
          break;
        }
      }

      if (getTestModuleData.length === count) {
        showContinue = 2;
      }
    }

    //console.log('getTalentPlusEmpStatusData--->mgetTalentPlusEmpStatusData', getTalentPlusEmpStatusData)


    function getStarterStatus(list, index) {
      let check = false;
      if (list && list.length > 0 && index > 0) {
        //console.log("list[index - 1]--->", list[index - 1])
        check = list && list[index - 1] && list[index - 1]["isComplete"] ? true : false
      } else {
        check = true
      }
      return check
    }


    let crrSectionName = ""
    if (categoryQuestions && categoryQuestions.length > 0) {
      for (var i = 0; i < categoryQuestions.length; i++) {
        let eleZ = categoryQuestions[i];
        if (!(eleZ && eleZ.isComplete) && getStarterStatus(categoryQuestions, i)) {
          crrSectionName = eleZ.name;
        }
      }
    }



    console.log("----------------------crrTestDetails----------------------", crrTestDetails)


    function getTime() {
      let seconds = crrTestDetails && crrTestDetails.seconds ? crrTestDetails.seconds : 0;
      let sec = 0;

      console.log("----------------------seconds----------------------", seconds)
      if (seconds) {
        sec = seconds / 60;
      }
      console.log("----------------------sec----------------------", sec)
      return sec.toString()
    }


    return (
      <>
        <div className="h-dvh">

          {(check === 1) || true ? (
            <>
              <div
                className="w-full h-screen overflow-hidden bg-center bg-cover"
                style={{
                  backgroundImage: getTheme(
                    "backgroundImage",
                    'url("/img/survey-bg.jpg")'
                  ),
                }}
              >
                <LogoAssessmentHeader
                  logout={onLogout}
                  surveyScreenData={surveyScreenData}
                  template={"v4"}
                  details={getAssessmenrEmployeeDetailsData}
                  handleReport={this.viewReport}
                  surveyLanguageName={surveyLanguageName}
                  handleLanguage={this.handleLanguage}
                  LanguageList={LanguageList}
                />


                {showSummaryModal || (getTalentPlusEmpStatusData && getTalentPlusEmpStatusData.status && getTalentPlusEmpStatusData.status === 1) ?
                  // || (showContinue === 2)?
                  <>
                    <UserReportSummary
                      createdAt={createdAt}
                      EditButtonFn={EditButtonFn}
                      EditDetailsFn={EditDetailsFn}
                      closeReportModal={this.closeReportPage}
                      loading={loading}
                      crrEmpDetails={crrEmpDetails}

                      getCareerSurveyListData={getCareerSurveyListData}
                      getPersonalityIndexData={getPersonalityIndexData}
                      getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
                      getInterestDimensionScoreData={getInterestDimensionScoreData}
                      getCareerFilamentScoreData={getCareerFilamentScoreData}
                      getAptitudeTestScoreData={getAptitudeTestScoreData}

                    />
                  </>
                  :
                  <>


                    {isNameEditScreen ?
                      <>
                        <div className="w-full h-[calc(100vh-4rem)] overflow-y-auto ">
                          {/*isSchool Teen Talentplus details second screen */}
                          <section className="px-4 py-4 mx-auto space-y-6 2xl:w-6/12 lg:w-8/12 md:py-6 lg:px-0 lg:space-y-10">

                            <div className="w-full h-full mx-auto space-y-6 lg:space-y-10 ">
                              <h1 className="py-4 pb-0 text-2xl font-medium text-center lg:pb-4 xl:text-3xl text-white/100" style={{ color: getTheme("text", "#FFFFFF") }}> {"Please update your details"}</h1>
                              <div className="grid grid-cols-1 md:gap-4 gap-3 md:grid-cols-2">
                                <div className="space-y-1 ">
                                  <div className="text-base font-semibold text-[#212121]">Name<span className="text-red-500">*</span></div>
                                  <input name="name" value={nameFormData.name} onChange={this.handleNameInput} placeholder="Name" className="w-full md:p-3 p-2  border focus:ring-1 ring-[#2196f3] focus:outline-none  rounded-md  " />
                                  <div className="text-sm font-normal text-red-500">{errNameForm && errNameForm.name ? errNameForm.name : ""}</div>
                                </div>

                                <div className="space-y-1 ">
                                  <div className="text-base font-semibold text-[#212121]">Email<span className="text-red-500">*</span></div>
                                  <input name="email" value={nameFormData.email} onChange={this.handleNameInput} placeholder="Email" className="w-full md:p-3 p-2  border  rounded-md   focus:ring-1 ring-[#2196f3] focus:outline-none" />
                                  <div className="text-sm font-normal text-red-500">{errNameForm && errNameForm.email ? errNameForm.email : ""}</div>
                                </div>

                                <div>
                                  <div className="space-y-1 ">
                                    <label className=" text-base font-semibold text-[#212121]">What is your gender?<span className="text-red-500">*</span></label>
                                    <div className="relative w-full ">
                                      <select className="w-full md:p-3 p-2 text-[#212121] bg-white border   rounded-md  outline-none appearance-none" onChange={this.handleGenderChange}>
                                        <option disabled selected>Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                      </select>
                                      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 pointer-events-none">
                                        <IoIosArrowDown className="w-4 h-4 fill-current" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-sm font-normal text-red-500 ">{errNameForm && errNameForm.gender ? errNameForm.gender : ""}</div>
                                </div>

                                <div>
                                  <div className="space-y-1 ">
                                    <label className=" text-base font-semibold text-[#212121]">What is your age group?<span className="text-red-500">*</span></label>
                                    <div className="relative w-full ">
                                      <select className="w-full md:p-3 p-2 text-[#212121] bg-white border   rounded-md  outline-none appearance-none" onChange={this.handleAgeGroupChange}>
                                        <option disabled selected>Select</option>
                                        <option value="Below 15 Years">Below 15 Years</option>
                                        <option value="15 Years to 18 Years">15 Years to 18 Years</option>
                                        <option value="18 Years to 21 Years">18 Years to 21 Years</option>
                                        <option value="21 Years to 25 Years">21 Years to 25 Years</option>
                                      </select>
                                      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 pointer-events-none">
                                        <IoIosArrowDown className="w-4 h-4 fill-current" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-sm font-normal text-red-500">{errNameForm && errNameForm.ageGroup ? errNameForm.ageGroup : ""}</div>
                                </div>

                                <div>
                                  <div className="space-y-1 ">
                                    <label className="text-base font-semibold text-[#212121]">Which class you are in?<span className="text-red-500">*</span></label>
                                    <div className="relative w-full ">
                                      <select className="w-full md:p-3 p-2 text-[#212121] bg-white border   rounded-md  outline-none appearance-none" onChange={this.handleClassesChange}>
                                        <option disabled selected>Select</option>
                                        <option value="10th">10th</option>
                                        <option value="11th">11th</option>
                                        <option value="12th">12th</option>
                                        <option value="Graduation">Graduation</option>
                                        <option value="PostGraduation">Post Graduation</option>
                                      </select>
                                      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 pointer-events-none">
                                        <IoIosArrowDown className="w-4 h-4 fill-current" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-sm font-normal text-red-500">{errNameForm && errNameForm.classes ? errNameForm.classes : ""}</div>
                                </div>

                                <div>
                                  <div className="space-y-1 ">
                                    <label className="  text-base font-semibold text-[#212121]">What is your Stream?<span className="text-red-500">*</span></label>
                                    <div className="relative w-full ">
                                      <select className="w-full md:p-3 p-2 text-[#212121] bg-white border   rounded-md  outline-none appearance-none" onChange={this.handleStreamChange}>
                                        <option disabled selected>Select</option>
                                        <option value="Science">Not for me</option>
                                        <option value="PCM">PCM</option>
                                        <option value="PCB">PCB</option>
                                        <option value="PCB">PCMB</option>
                                        <option value="PCB">Commerce</option>
                                        <option value="PCB">Biology</option>
                                        <option value="PCB">Science</option>
                                        <option value="Arts">Arts with Maths</option>
                                        <option value="Arts">Arts without Maths</option>
                                      </select>
                                      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 pointer-events-none">
                                        <IoIosArrowDown className="w-4 h-4 fill-current" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-sm font-normal text-red-500">{errNameForm && errNameForm.stream ? errNameForm.stream : ""}</div>
                                </div>

                                <div>
                                  <div className="space-y-1 ">
                                    <label className=" text-base font-semibold text-[#212121]">What are your interests?<span className="text-red-500">*</span></label>
                                    <div className="relative w-full ">
                                      <Select className="w-full text-[#212121] "
                                        classNamePrefix='md:p-0.5 p-[1px] !border'
                                        // closeMenuOnSelect={false}
                                        // onChange={this.handleInterestChange}
                                        // handleChange
                                        menuPlacement="top"
                                        value={this.state.nameFormData.interest}
                                        onChange={this.handleChange}
                                        components={animatedComponents}
                                        // defaultValue={[colourOptions[4], colourOptions[5]]}
                                        isMulti
                                        options={colourOptions}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-sm font-normal text-red-500">{errNameForm && errNameForm.interest ? errNameForm.interest : ""}</div>
                                </div>

                              </div>
                              <div className="h-20"></div>
                              <div className="w-full py-3 bg-white/40 backdrop-blur-xl border-t fixed bottom-0 right-0 flex items-center">
                                <div className=" 2xl:w-6/12 lg:w-8/12 w-full lg:px-0 px-4  gap-4 mx-auto  flex md:flex-row flex-row justify-between items-center ">
                                  <p className="text-sm text-[#212121] w-fit flex justify-center items-center">
                                    {" "}
                                    © Powered by <b className="ml-1 font-medium text-[#212121]">HappyPlus </b>
                                  </p>
                                  <button onClick={() => this.onUpdateDetails()} className="md:w-60 w-fit  uppercase md:text-base text-base font-medium rounded-md    items-center justify-center md:px-4 px-8  py-3 cursor-pointer hover:bg-[#1976d2] transition-all duration-100 bg-[#2196f3] text-white lg:mr-3">
                                    Continue
                                  </button>
                                </div>
                              </div>


                            </div>
                          </section>


                        </div>
                      </>
                      :
                      <>
                        {showQuestionPanel ?
                          <>
                            {surveyStatus !== 2 && surveyStatus !== 3 ? (
                              <>
                                {this.state.showWelcomeScreen &&
                                  questionsList &&
                                  questionsList.length > 0 ? (
                                  <>
                                    <section className="w-full overflow-y-auto h-[calc(100vh-5rem)]">
                                      <div className="px-4 py-4 mx-auto space-y-6 2xl:w-6/12 lg:w-8/12 md:py-6 lg:px-0 md:px-6 lg:space-y-10">
                                        {/* logo */}
                                        <div className=" space-y-4   ">
                                          <div className="space-y-2 lg:pt-6">
                                            <h1 className=" text-center text-2xl left 2xl:text-4xl xl:text-3xl text-[#212121] pb-6">
                                              {SurveyAttributes("welcomeToText")
                                                ? SurveyAttributes("welcomeToText")
                                                : "Welcome to "}
                                              <b className="font-semibold"> {crrTestDetails && crrTestDetails.name}</b>
                                            </h1>
                                            <p className="md:text-base text-sm text-[#212121]">
                                              {getQuesDescWithCount(crrTestDetails && crrTestDetails.desc2, crrTestDetails.questionCount)}
                                            </p>
                                          </div>
                                          <div className='flex items-start space-x-2 p-2 px-4 rounded-sm bg-[#212121]/10'>
                                            <h2 className='md:text-lg text-base font-semibold text-[#212121]'>Important Instructions:</h2>
                                          </div>
                                          <p className=" md:text-base text-sm text-[#212121]">Before you start the different sections, please read the instructions very carefully so that the outcome is effective. This is meant as a career-guidance tool and you would need to answer these questions truthfully to provide you the best recommendations.</p>

                                          {crrTestDetails && crrTestDetails.type !== 4 && (
                                            <>
                                              <div className='flex items-start space-x-3 p-3 px-4 rounded-sm bg-[#F5EBCE]'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 " width="24" height="24" viewBox="0 0 36 36" fill="none">
                                                  <rect width="36" height="36" rx="4" fill="#FFC107" />
                                                  <g clip-path="url(#clip0_5553_661)">
                                                    <path d="M19.3001 9.14844L27.9342 24.1024C28.0658 24.3305 28.1351 24.5891 28.1351 24.8524C28.1351 25.1157 28.0658 25.3744 27.9342 25.6024C27.8025 25.8304 27.6132 26.0198 27.3851 26.1515C27.1571 26.2831 26.8985 26.3524 26.6352 26.3524H9.36715C9.10385 26.3524 8.84519 26.2831 8.61716 26.1515C8.38914 26.0198 8.19979 25.8304 8.06814 25.6024C7.93649 25.3744 7.86719 25.1157 7.86719 24.8524C7.86719 24.5891 7.9365 24.3305 8.06815 24.1024L16.7021 9.14844C17.2791 8.14844 18.7221 8.14844 19.3001 9.14844ZM18.0011 10.8984L10.2331 24.3524H25.7691L18.0011 10.8984ZM18.0011 21.0004C18.2664 21.0004 18.5207 21.1058 18.7083 21.2933C18.8958 21.4809 19.0011 21.7352 19.0011 22.0004C19.0011 22.2657 18.8958 22.52 18.7083 22.7075C18.5207 22.8951 18.2664 23.0004 18.0011 23.0004C17.7359 23.0004 17.4816 22.8951 17.294 22.7075C17.1065 22.52 17.0011 22.2657 17.0011 22.0004C17.0011 21.7352 17.1065 21.4809 17.294 21.2933C17.4816 21.1058 17.7359 21.0004 18.0011 21.0004ZM18.0011 14.0004C18.2664 14.0004 18.5207 14.1058 18.7083 14.2933C18.8958 14.4809 19.0011 14.7352 19.0011 15.0004V19.0004C19.0011 19.2657 18.8958 19.52 18.7083 19.7075C18.5207 19.8951 18.2664 20.0004 18.0011 20.0004C17.7359 20.0004 17.4816 19.8951 17.294 19.7075C17.1065 19.52 17.0011 19.2657 17.0011 19.0004V15.0004C17.0011 14.7352 17.1065 14.4809 17.294 14.2933C17.4816 14.1058 17.7359 14.0004 18.0011 14.0004Z" fill="white" />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_5553_661">
                                                      <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                                <p className="text-sm text-[#212121] font-semibold">Please note all questions are mandatory to be answered and your report will not be available if any of the questions remain unanswered.
                                                </p>
                                              </div>
                                            </>
                                          )}


                                          <div className='flex items-start space-x-2 p-2 px-4 rounded-sm bg-[#212121]/10'>
                                            <h2 className='md:text-lg text-base font-semibold text-[#212121]'>General Instructions:</h2>
                                          </div>
                                          <ul className='pl-4 list-disc list-inside'>
                                            <li className=" md:text-base text-sm text-[#212121]">Total duration of this section is {getTime(crrTestDetails)}  minutes.</li>
                                          </ul>
                                          <div className='flex items-start space-x-2 p-2 px-4 rounded-sm bg-[#212121]/10'>
                                            <h2 className='md:text-lg text-base font-semibold text-[#212121]'>Answering a Question:
                                            </h2>
                                          </div>
                                          <ul className='pl-4 list-disc list-inside'>
                                            <li className=" md:text-base text-sm text-[#212121]">To select your answer, click on the response button of one of the options.</li>
                                            <li className=" md:text-base text-sm text-[#212121]">To change your chosen answer, click on the button of another option.</li>
                                          </ul>
                                        </div>
                                        <div className="h-20" />

                                        <div className="w-full py-3 bg-white/40 backdrop-blur-xl border-t fixed bottom-0 right-0 flex items-center">
                                          <div className=" 2xl:w-6/12 lg:w-8/12 w-full lg:px-0 px-4  gap-4 mx-auto  flex md:flex-row flex-col-reverse justify-between items-center ">
                                            <p className="text-sm text-[#212121] w-full flex md:justify-start justify-center items-center"> © Powered by <b className="ml-1 font-medium text-[#212121]">HappyPlus </b></p>
                                            <div className="lg:w-auto w-full flex justify-end  items-center space-x-3  ">
                                              <button onClick={() => this.goToTestPage()} className="md:w-40 w-full uppercase md:text-base text-base font-medium rounded-md  md:px-4 px-4  py-3 cursor-pointer  border border-[#212121] hover:bg-[#212121]/10 transition-all duration-100 text-[#212121] " > Back</button>

                                              <button onClick={() => this.goToUpdateDetailsPage()} className="md:w-40 w-full  uppercase md:text-base text-base font-medium rounded-md   inline-flex   items-center justify-center md:px-4 px-4 py-3 cursor-pointer  bg-[#2196f3] hover:bg-[#1976d2] border border-[#2196f3] hover:border-[#1976d2] transition-all duration-100 text-white ">{SurveyAttributes("StartAssessmentButtonText") ? SurveyAttributes("StartAssessmentButtonText") : "Start Test"}
                                              </button>
                                            </div>
                                          </div>

                                        </div>

                                      </div>
                                    </section>
                                  </>
                                ) : null}


                                {/* Instruction Section */}
                                {this.state.instructionPage ? (
                                  <>
                                    <div>
                                      <section className="px-2 py-4 mx-auto lg:w-5/6 md:py-6 lg:px-0 md:px-6 " style={{ height: "75%" }}>
                                        <div className="flex justify-center -mt-4"></div>

                                        <p style={{ color: surveyScreenData && surveyScreenData.textColor ? surveyScreenData.textColor : "black", }} className="py-2 text-2xl font-bold text-center">  {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].InstructionLabelText ? LabelObj[surveyLanguageCode].InstructionLabelText : SurveyAttributes("instructionLabel") ? SurveyAttributes("instructionLabel") : "Instructions"}</p>

                                        {/* pragraph */}
                                        <div className="pb-24">
                                          <div id="chatbot" className="mx-4 mt-2 overflow-y-auto cursor-default lg:px-14 lg:pl-32 lg:pr-4" style={{ height: "400px", color: surveyScreenData && surveyScreenData.textColor ? surveyScreenData.textColor : "black", }}
                                            dangerouslySetInnerHTML={{ __html: surveyLanguageCode !== "" ? surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].instructionText ? surveyScreenData.transl[surveyLanguageCode].instructionText : instructionTextDislay : instructionTextDislay, }} />
                                        </div>
                                        <div className="flex justify-center ">
                                          <div>
                                            {/* btn */}
                                            <button
                                              onClick={() => this.goToQuestionPage()}
                                              className="w-80  uppercase text-xl font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-4 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                                            // style={{
                                            //   background:
                                            //     surveyScreenData &&
                                            //     surveyScreenData.buttonColor1
                                            //       ? surveyScreenData.buttonColor1
                                            //       : afterSubmitData.buttonColor1
                                            //       ? afterSubmitData.buttonColor1
                                            //       : "#757575",
                                            //   color:
                                            //     surveyScreenData &&
                                            //     surveyScreenData.buttonColor2
                                            //       ? surveyScreenData.buttonColor2
                                            //       : afterSubmitData.buttonColor2
                                            //       ? afterSubmitData.buttonColor2
                                            //       : "#757575",
                                            // }}
                                            >
                                              {SurveyAttributes("ContinueButtonText")
                                                ? SurveyAttributes("ContinueButtonText")
                                                : "Continue"}
                                            </button>
                                            <p
                                              style={{
                                                backgroundColor:
                                                  bgType === 1 || afterSubmitData.bgType === 1
                                                    ? bgColor
                                                      ? bgColor
                                                      : afterSubmitData.bgColor
                                                        ? afterSubmitData.bgColor
                                                        : "#757575"
                                                    : "#f1f1f1",
                                                // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                                              }}
                                              className="py-4 pl-6 text-sm"
                                            >
                                              {" "}
                                              © Powered by{" "}
                                              <b className="font-semibold">happyPlus</b>
                                            </p>
                                          </div>
                                        </div>
                                        {/*  */}
                                      </section>
                                    </div>
                                  </>
                                ) : null}


                                {/* Question Section */}
                                {this.state.showQuestionScreen && !this.state.submitSucces ? (
                                  <section>
                                    {/* Remaining section */}
                                    <div className="md:h-12 h-12 w-full bg-[#3D405B] flex items-center ">
                                      <div className="flex items-center justify-between w-full px-4 mx-auto xl:w-11/12 xl:px-0 ">

                                        <div className="flex items-center text-white md:space-x-2 ">
                                          <div className="flex items-center space-x-4 ">
                                            <span className="cursor-pointer " onClick={this.handleBackButton}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48" fill="none">
                                                <rect width="48" height="48" rx="24" fill="white" fill-opacity="0.08" />
                                                <path d="M34.6654 22.6663H18.4387L25.892 15.213L23.9987 13.333L13.332 23.9997L23.9987 34.6663L25.8787 32.7863L18.4387 25.333H34.6654V22.6663Z" fill="white" />
                                              </svg>
                                            </span>
                                            <p className="text-lg md:text-xl"> {surveyScreenData && surveyScreenData.name ? surveyScreenData.name : ""} </p>
                                          </div>
                                        </div>

                                        <div className="flex items-center space-x-3">
                                          <div className="flex items-center space-x-2 text-white md:space-x-2 ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-progress-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969" /><path d="M14 3.223a9.003 9.003 0 0 1 0 17.554" /><path d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592" /><path d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305" /><path d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356" /><path d="M9 12l2 2l4 -4" /></svg>
                                            <span className="text-sm md:block hidden">Progress</span>
                                            <h1 className="text-sm font-medium md:text-base">
                                              {(CurrQuesIndex).toString() + " "}
                                              <b className="font-medium text-white/60 ">
                                                {" "}
                                                /{" "}
                                                {questionsList && questionsList.length > 0
                                                  ? questionsList.length
                                                  : 0}
                                              </b>
                                            </h1>
                                          </div>
                                          <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="32" viewBox="0 0 4 48" fill="none">
                                              <rect width="4" height="48" fill="white" fill-opacity="0.24" />
                                            </svg>
                                          </span>
                                          <div className="flex items-center text-white md:space-x-1 ">
                                            <div className="flex items-center space-x-2 ">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-clock-hour-4"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 12l3 2" /><path d="M12 7v5" /></svg>
                                              <p className="text-sm md:block hidden">
                                                {SurveyAttributes("Remaining")
                                                  ? SurveyAttributes("Remaining")
                                                  : "Remaining"}
                                              </p>
                                            </div>
                                            <h1 className="p-2 text-sm font-medium md:text-base">
                                              {getSecondsToTime(timeCounter)}
                                            </h1>
                                          </div>
                                        </div>

                                      </div>
                                    </div>

                                    <div className="w-full h-[calc(100vh-7.5rem)] overflow-hidden overflow-y-auto">
                                      {/* Tab section */}
                                      <div className="w-full ">
                                        <div className='px-4 mx-auto xl:px-0 xl:w-11/12 lg:block '>
                                          <div className="my-2 flex items-center md:justify-center justify-center lg:space-x-2 space-x-2">
                                            {categoryQuestions && categoryQuestions.length > 0 ?
                                              categoryQuestions.map((eleZ, indexx) =>
                                                <>
                                                  {eleZ && eleZ.isComplete ?
                                                    <div className="flex items-center justify-between whitespace-nowrap space-x-2">
                                                      <div className="flex items-center py-2 space-x-2">
                                                        <div className="w-6 h-6 rounded-full bg-[#2196F3] items-center justify-center flex text-white flex-shrink-0">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
                                                        </div>
                                                        <p className="text-[#2196F3] font-medium whitespace-nowrap text-sm flex space-x-1">
                                                          <span className="2xl:block hidden capitalize">{eleZ && eleZ.name}</span></p>
                                                      </div>
                                                      <div className={`h-0.5 md:w-10 w-6 bg-[#2196F3] ${indexx === categoryQuestions.length - 1 ? 'hidden' : ''}`}></div>
                                                    </div>
                                                    :
                                                    <div className="flex items-center justify-between whitespace-nowrap space-x-2">
                                                      <div className="flex items-center py-2 space-x-2 ">
                                                        {getStarterStatus(categoryQuestions, indexx) ?
                                                          <div class="w-6 h-6 rounded-full border-2 border-[#2196F3] flex items-center justify-center flex-shrink-0 text-sm text-[#2196F3]">
                                                            {indexx + 1}
                                                          </div>
                                                          :
                                                          <div class="w-6 h-6 rounded-full border-2 border-[#212121]/20 flex items-center justify-center flex-shrink-0 text-sm">
                                                            {indexx + 1}
                                                          </div>
                                                        }
                                                        {getStarterStatus(categoryQuestions, indexx) ?
                                                          <p className="text-[#2196F3] font-medium whitespace-nowrap text-sm lg:flex hidden space-x-1">
                                                            <span className="">{eleZ && eleZ.name}</span></p>
                                                          :
                                                          <p className="text-[#212121] font-medium whitespace-nowrap text-sm 2xl:flex hidden  space-x-1">
                                                            <span className="">{eleZ && eleZ.name}</span></p>
                                                        }
                                                      </div>
                                                      <div
                                                        className={`h-0.5 md:w-10 w-6 bg-[#212121]/20 ${indexx === categoryQuestions.length - 1 ? 'hidden' : ''}`}
                                                      ></div>
                                                    </div>
                                                  }
                                                </>
                                              ) : null}

                                          </div>
                                          <p className="text-base font-medium text-[#2196F3] text-center lg:pb-0 pb-2 lg:hidden block">{crrSectionName}</p>



                                        </div>
                                      </div>

                                      {/* Total Questions header section */}
                                      <div className='bg-[#F9EFD2]  '>
                                        <div className="flex lg:flex-row flex-col gap-2 md:items-center items-start justify-between px-4 py-2 mx-auto xl:px-0 xl:w-11/12">
                                          <div className="flex items-center space-x-2 md:text-sm text-xs" >
                                            <span className="bg-[#3D405B] md:rounded-md rounded-sm p-1 px-3 text-white">Total Questions: {questionCount}</span>
                                            <span className="bg-[#4CAF50] md:rounded-md rounded-sm p-1 px-3 text-white">Answered:  {getAnsweredCount(answersList)}</span>
                                            <span className="bg-[#E74C3C] md:rounded-md rounded-sm p-1 px-3 text-white">Unanswered: {Unanswered}</span>
                                          </div>
                                          <div className="flex items-center space-x-2" >
                                            <div className="bg-[#FFC107] text-white p-1 rounded-sm" style={{ color: getTheme("text", "#FFFFFF") }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-bulb"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" /><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" /><path d="M9.7 17l4.6 0" /></svg>
                                            </div>
                                            <span className="text-[#212121] font-medium text-sm" style={{ color: getTheme("text", "#FFFFFF") }}> {SurveyAttributes("Kindly answer the given question") ? SurveyAttributes("Kindly answer the given question") : "Kindly answer the given question"}</span>
                                          </div>
                                        </div>
                                      </div>
                                      {/* Question list section */}
                                      <div className="w-full pt-8 mx-auto space-y-6 2xl:w-5/12 lg:w-6/12 lg:px-0 md:px-6 px-4" >
                                        {questionsList && questionsList.length > 0
                                          ? questionsList.map((question, questionindex) =>
                                            questionindex + 1 === CurrQuesIndex ? (
                                              <>
                                                <div className='space-y-2 '>
                                                  <div className="text-[#212121] rounded-md flex w-fit font-semibold bg-white px-3 py-1.5 border after:pl-1 after:content-['*'] after:text-[#E53935] text-sm ">Question {questionindex + 1} </div>
                                                  <h1 className='lg:text-xl text-lg font-medium text-[#212121]'>{functionNameVariable(surveyLanguageCode !== "" ? question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name ? question.transl[surveyLanguageCode].name : question.name : question.name)}</h1>
                                                </div>

                                              </>
                                            ) : null
                                          )
                                          : null}
                                      </div>
                                      {/* Answer list section */}
                                      <div className="flex w-full mb-6 px-4 py-4 mx-auto md:mt-4 2xl:w-5/12 lg:w-6/12 md:py-2 lg:px-0 md:px-6 ">
                                        <div className="w-full">
                                          {questionsList && questionsList.length > 0
                                            ? questionsList.map((question, questionindex) =>
                                              questionindex + 1 === CurrQuesIndex ? (
                                                <>
                                                  <div>
                                                    {/* {question.templateId !== 14 ? (
                                        question.templateId === 10 ? (

                                          <p style={{ color: getTheme("text", "#FFFFFF"), }} className="px-4 py-4 mb-4 text-2xl italic font-semibold text-left text-white cursor-default quz md:text-2xl md:px-6 md:py-6 md:mt-4">
                                            <span className="font-bold">
                                              {functionNameVariable(
                                                surveyLanguageCode !== ""
                                                  ? question &&
                                                    question.transl &&
                                                    question.transl[
                                                    surveyLanguageCode
                                                    ] &&
                                                    question.transl[
                                                      surveyLanguageCode
                                                    ].name
                                                    ? question.transl[
                                                      surveyLanguageCode
                                                    ].name
                                                    : question.name
                                                  : question.name
                                              )}
                                            </span>

                                            {question.isMandatory ? (
                                              <div
                                                style={{
                                                  background:
                                                    surveyScreenData &&
                                                      surveyScreenData.OptionColor1
                                                      ? surveyScreenData.OptionColor1
                                                      : "white",
                                                  color: getTheme(
                                                    "text",
                                                    "#FFFFFF"
                                                  ),
                                                }}
                                                className="font-semibold text-gray-500 bg-gray-300 shadow-lg subtitle"
                                              >
                                                {SurveyAttributes(
                                                  "mandatoryText"
                                                )
                                                  ? SurveyAttributes(
                                                    "mandatoryText"
                                                  )
                                                  : "This question is mandatory"}
                                              </div>
                                            ) : null}
                                          </p>

                                        ) : (
                                          <>
                                            {question.parentId ? (
                                              <>
                                                {question &&
                                                  question["QuesMain"] ? (
                                                  <QuestionHeader
                                                    surveyLanguageCode={surveyLanguageCode}
                                                    question={question["QuesMain"]}
                                                  />
                                                ) : null}

                                                <div style={{ color: getTheme("text", "#FFFFFF"), }} className="px-4 py-2 mb-2 text-lg font-semibold text-left text-white cursor-default quz md:text-2xl md:px-6 md:py-4 md:mt-2">
                                                  {getGroupIndex(
                                                    question["QuesMain"].id
                                                  )}
                                                  {functionNameVariable(
                                                    surveyLanguageCode !== "" ? question && question["QuesMain"] && question["QuesMain"].transl && question["QuesMain"].transl[surveyLanguageCode] && question["QuesMain"].transl[surveyLanguageCode].name ? question["QuesMain"].transl[surveyLanguageCode].name : question && ["QuesMain",] && question["QuesMain"].name ? question["QuesMain"].name : "" : question && ["QuesMain",] && question["QuesMain"].name ? question["QuesMain"].name : ""
                                                  )}
                                                </div>

                                                <div style={{ color: getTheme("text", "#FFFFFF"), }}
                                                  className={
                                                    question.templateId === 3 || question.templateId === 4 || question.templateId === 6 || question.templateId === 16
                                                      ? "text-left mb-0 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal  md:mt-2"
                                                      : " mb-2 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal md:mt-2  text-left"
                                                  } >
                                                  {question.isMandatory ? (
                                                    <div className="text-sm text-red-500 ">{SurveyAttributes("mandatoryText") ? SurveyAttributes("mandatoryText") : "This question is mandatory*"} </div>
                                                  ) : null}


                                                  <span className="font-bold">
                                                    {" "}
                                                    {functionNameVariable(
                                                      surveyLanguageCode !== "" ? question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name ? question.transl[surveyLanguageCode].name : question.name : question.name
                                                    )}{" "}
                                                  </span>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="quz">
                                                  <QuestionHeader
                                                    surveyLanguageCode={
                                                      surveyLanguageCode
                                                    }
                                                    questionName={functionNameVariable(
                                                      surveyLanguageCode !==
                                                        ""
                                                        ? question &&
                                                          question.transl &&
                                                          question.transl[
                                                          surveyLanguageCode
                                                          ] &&
                                                          question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          ? question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          : question.name
                                                        : question.name
                                                    )}
                                                    QuesNo={
                                                      (
                                                        questionindex + 1
                                                      ).toString() + ". "
                                                    }
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    color: getTheme(
                                                      "text",
                                                      "#FFFFFF"
                                                    ),
                                                  }}
                                                  className="px-4 mb-2 text-lg font-normal text-left text-white cursor-default quz md:text-2xl md:px-6 md:mt-4"
                                                >
                                                  {question.isMandatory ? (
                                                    <div className="text-sm text-red-500 ">
                                                      {SurveyAttributes(
                                                        "mandatoryText"
                                                      )
                                                        ? SurveyAttributes(
                                                          "mandatoryText"
                                                        )
                                                        : "This question is mandatory*"}
                                                    </div>
                                                  ) : null}

                                                  <div className="flex flex-row gap-2">
                                                    <p> {(questionindex + 1).toString() + ". "}</p>
                                                    <p>{functionNameVariable(
                                                      surveyLanguageCode !==
                                                        ""
                                                        ? question &&
                                                          question.transl &&
                                                          question.transl[
                                                          surveyLanguageCode
                                                          ] &&
                                                          question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          ? question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          : question.name
                                                        : question.name
                                                    )}</p>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )
                                      ) : null} */}

                                                    {showFeedbackModal ? (
                                                      <FeedbackModal
                                                        closeModal={this.closeFeedbackModal}
                                                        question={question}
                                                        answersList={answersList}
                                                        handleFeedbackInput={this.handleFeedbackInput}
                                                        submitFeedback={this.submitFeedback}
                                                        surveyScreenData={surveyScreenData}
                                                        afterSubmitData={afterSubmitData}
                                                      />
                                                    ) : null}

                                                    {/* Single Choice */}
                                                    {question.templateId === 1 ? (
                                                      <>
                                                        <SingleChoice
                                                          question={question}
                                                          clicked={clicked}
                                                          answersList={answersList}
                                                          surveyLanguageCode={surveyLanguageCode}
                                                          handleRadio={this.handleRadio}
                                                          surveyScreenData={surveyScreenData}
                                                          isMobile={isMobile}
                                                          nextQues={this.nextQues}
                                                          prevQues={this.prevQues}
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Image Single Choice */}
                                                    {question.templateId === 17 ? (
                                                      <>
                                                        <ImageSingleChoice
                                                          question={question}
                                                          clicked={clicked}
                                                          answersList={answersList}
                                                          surveyLanguageCode={surveyLanguageCode}
                                                          handleRadio={this.handleRadio}
                                                          surveyScreenData={surveyScreenData}
                                                          isMobile={isMobile}
                                                          nextQues={this.nextQues}
                                                          prevQues={this.prevQues}
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Multiple Choice */}
                                                    {question.templateId === 2 ? (
                                                      <>
                                                        <MultipleChoice
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={surveyLanguageCode}
                                                          handleCheckInput={this.handleCheckInput}
                                                          surveyScreenData={surveyScreenData}
                                                          clickOK={this.clickOK}
                                                          SurveyAttributes={SurveyAttributes}
                                                        />
                                                      </>
                                                    ) : null}
                                                    {/* Multitext Box */}
                                                    {question.templateId === 3 ? (
                                                      <>
                                                        <TextBox
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          SurveyAttributes={
                                                            SurveyAttributes
                                                          }
                                                          ansTextBoxChange1={
                                                            this.ansTextBoxChange1
                                                          }
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                          clickOK={this.clickOK}
                                                          handleViewReport={this.handleViewReport}
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Multitext Box */}
                                                    {question.templateId === 4 ? (
                                                      <>
                                                        <MultiLineText
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          SurveyAttributes={
                                                            SurveyAttributes
                                                          }
                                                          ansTextBoxChange1={
                                                            this.ansTextBoxChange1
                                                          }
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                          clickOK={this.clickOK}
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Rating Scale */}
                                                    {question.templateId === 5 ? (
                                                      <>
                                                        <RatingScale
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={surveyLanguageCode}
                                                          handleRadio={this.handleRadio}
                                                          surveyScreenData={surveyScreenData}
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* NPS Scale 1-10*/}
                                                    {question.templateId === 6 ? (
                                                      <>
                                                        <NPS
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={surveyLanguageCode}
                                                          ansNPSInput={this.ansNPSInput}
                                                          surveyScreenData={surveyScreenData}
                                                          isMobile={isMobile}
                                                          questionsList={questionsList}
                                                          CurrQuesIndex={CurrQuesIndex}
                                                          nextQues={this.nextQues}
                                                          SurveyAttributes={SurveyAttributes}
                                                          getTheme={getTheme}
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* NPS Scale 0-10*/}
                                                    {question.templateId === 16 ? (
                                                      <>
                                                        <NPSV2
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          ansNPSInput={this.ansNPSInput}
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Email */}
                                                    {question.templateId === 7 ? (
                                                      <>
                                                        <Email
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          ansTextBoxChange1={
                                                            this.ansTextBoxChange1
                                                          }
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                          clickOK={this.clickOK}
                                                          SurveyAttributes={
                                                            SurveyAttributes
                                                          }
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Number */}
                                                    {question.templateId === 8 ? (
                                                      <>
                                                        <Number
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          ansTextBoxChange1={
                                                            this.ansTextBoxChange1
                                                          }
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                          clickOK={this.clickOK}
                                                          SurveyAttributes={
                                                            SurveyAttributes
                                                          }
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Dropdown */}
                                                    {question.templateId === 9 ? (
                                                      <>
                                                        <Dropdown
                                                          question={question}
                                                          clicked={clicked}
                                                          answersList={answersList}
                                                          handleRadio={this.handleRadio}
                                                          handleDropdownSelect={
                                                            this.handleDropdownSelect
                                                          }
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Yes/No */}
                                                    {question.templateId === 11 ? (
                                                      <>
                                                        <YesNo
                                                          question={question}
                                                          clicked={clicked}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          handleRadio={this.handleRadio}
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Date */}
                                                    {question.templateId === 12 ? (
                                                      <>
                                                        <Date
                                                          question={question}
                                                          answersList={answersList}
                                                          surveyLanguageCode={
                                                            surveyLanguageCode
                                                          }
                                                          ansTextBoxChange1={
                                                            this.ansTextBoxChange1
                                                          }
                                                          surveyScreenData={
                                                            surveyScreenData
                                                          }
                                                          clickOK={this.clickOK}
                                                          SurveyAttributes={
                                                            SurveyAttributes
                                                          }
                                                        />
                                                      </>
                                                    ) : null}

                                                    {/* Matrix  */}
                                                    {question.templateId === 13 ? (
                                                      <>
                                                        <div
                                                          className="px-4 md:px-0 lg:px-0 xl:px-0"
                                                          style={{
                                                            color: MandatoryMsg(
                                                              question,
                                                              questionindex + 1,
                                                              CurrQuesIndex,
                                                              this.state.unattemptList,
                                                              this.state.showRedFlag
                                                            ).color,
                                                          }}
                                                        >
                                                          {
                                                            MandatoryMsg(
                                                              question,
                                                              questionindex + 1,
                                                              CurrQuesIndex,
                                                              this.state.unattemptList,
                                                              this.state.showRedFlag
                                                            ).text
                                                          }
                                                        </div>

                                                        <div className="w-full pt-2 mb-4">
                                                          <div className="w-full overflow-x-auto overflow-y-auto whitespace-nowrap ">
                                                            {isMobile ? (
                                                              <>
                                                                {FormatMatriXOptionHeading(
                                                                  question,
                                                                  false
                                                                ) &&
                                                                  FormatMatriXOptionHeading(
                                                                    question,
                                                                    false
                                                                  ).length > 0
                                                                  ? FormatMatriXOptionHeading(
                                                                    question,
                                                                    false
                                                                  ).map(
                                                                    (
                                                                      heading,
                                                                      optionindex
                                                                    ) =>
                                                                      heading.span ? (
                                                                        <div className="pb-2 mx-4">
                                                                          <div
                                                                            className="w-full px-2 py-1 mb-1 font-bold"
                                                                            style={{
                                                                              color:
                                                                                "#fff",
                                                                              background:
                                                                                surveyScreenData &&
                                                                                  surveyScreenData.OptionColor1
                                                                                  ? surveyScreenData.OptionColor1
                                                                                  : "#fff",
                                                                              background:
                                                                                optionindex ===
                                                                                  0
                                                                                  ? "#1ec756"
                                                                                  : optionindex ===
                                                                                    1
                                                                                    ? "#2362d9"
                                                                                    : "#fff",
                                                                              borderRight:
                                                                                optionindex ==
                                                                                  0
                                                                                  ? "4px solid #1ec756"
                                                                                  : null,
                                                                            }}
                                                                          >
                                                                            {TranslationOptionMatrix(
                                                                              question,
                                                                              heading.name,
                                                                              surveyLanguageCode,
                                                                              2
                                                                            )}
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              color:
                                                                                optionindex ===
                                                                                  0
                                                                                  ? "#1ec756"
                                                                                  : optionindex ===
                                                                                    1
                                                                                    ? "#2362d9"
                                                                                    : "#fff",
                                                                            }}
                                                                            className="px-2 font-bold text-gray-500 whitespace-pre-line"
                                                                          >
                                                                            {TranslationOptionMatrix(
                                                                              question,
                                                                              heading.name,
                                                                              surveyLanguageCode,
                                                                              3
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      ) : null
                                                                  )
                                                                  : null}

                                                                {/* MOBILE VIEW */}
                                                                {question &&
                                                                  question.SubQuestionList &&
                                                                  question.SubQuestionList
                                                                    .length > 0
                                                                  ? question.SubQuestionList.map(
                                                                    (
                                                                      SubQues,
                                                                      index
                                                                    ) => (
                                                                      <>
                                                                        <div
                                                                          className="pb-4 mx-4"
                                                                          key={index}
                                                                        >
                                                                          <div
                                                                            className="py-2 whitespace-pre-line"
                                                                            style={{
                                                                              color:
                                                                                showNotCompletedFn(
                                                                                  SubQues
                                                                                )
                                                                                  ? "#e31919"
                                                                                  : surveyScreenData &&
                                                                                    surveyScreenData.OptionColor2
                                                                                    ? surveyScreenData.OptionColor2
                                                                                    : "black",
                                                                            }}
                                                                          >
                                                                            {(
                                                                              questionindex +
                                                                              1
                                                                            ).toString() +
                                                                              "." +
                                                                              (
                                                                                index +
                                                                                1
                                                                              ).toString() +
                                                                              ") " +
                                                                              TranslateQuestion(
                                                                                surveyLanguageCode,
                                                                                SubQues
                                                                              )}
                                                                          </div>
                                                                          <table
                                                                            className="w-full mx-auto text-center border border-gray-300"
                                                                            style={{
                                                                              background:
                                                                                surveyScreenData &&
                                                                                  surveyScreenData.OptionColor1
                                                                                  ? surveyScreenData.OptionColor1
                                                                                  : "#fff",
                                                                            }}
                                                                          >
                                                                            <thead>
                                                                              <tr>
                                                                                {FormatMatriXOptionHeading(
                                                                                  question,
                                                                                  false
                                                                                ) &&
                                                                                  FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    false
                                                                                  )
                                                                                    .length >
                                                                                  0
                                                                                  ? FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    false
                                                                                  ).map(
                                                                                    (
                                                                                      heading,
                                                                                      optionindex
                                                                                    ) => (
                                                                                      <td
                                                                                        style={{
                                                                                          color:
                                                                                            "#fff",
                                                                                          background:
                                                                                            surveyScreenData &&
                                                                                              surveyScreenData.OptionColor1
                                                                                              ? surveyScreenData.OptionColor1
                                                                                              : "#fff",
                                                                                          background:
                                                                                            optionindex ===
                                                                                              0
                                                                                              ? "#1ec756"
                                                                                              : optionindex ===
                                                                                                1
                                                                                                ? "#2362d9"
                                                                                                : "#fff",
                                                                                          borderRight:
                                                                                            optionindex ==
                                                                                              0
                                                                                              ? "4px solid #1ec756"
                                                                                              : null,
                                                                                        }}
                                                                                        key={
                                                                                          optionindex
                                                                                        }
                                                                                        colSpan={
                                                                                          heading.span
                                                                                        }
                                                                                        className="px-2 text-sm font-bold text-center border border-gray-300"
                                                                                      >
                                                                                        {TranslationOptionMatrix(
                                                                                          question,
                                                                                          heading.name,
                                                                                          surveyLanguageCode,
                                                                                          1
                                                                                        )}
                                                                                      </td>
                                                                                    )
                                                                                  )
                                                                                  : null}
                                                                              </tr>
                                                                              <tr>
                                                                                {FormatMatriXOptionHeading(
                                                                                  question,
                                                                                  true
                                                                                ) &&
                                                                                  FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    true
                                                                                  )
                                                                                    .length >
                                                                                  0
                                                                                  ? FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    true
                                                                                  ).map(
                                                                                    (
                                                                                      option,
                                                                                      optionindex
                                                                                    ) => (
                                                                                      <td
                                                                                        style={{
                                                                                          color:
                                                                                            surveyScreenData &&
                                                                                              surveyScreenData.OptionColor2
                                                                                              ? surveyScreenData.OptionColor2
                                                                                              : "black",
                                                                                          background:
                                                                                            surveyScreenData &&
                                                                                              surveyScreenData.OptionColor1
                                                                                              ? surveyScreenData.OptionColor1
                                                                                              : "#fff",
                                                                                          borderRight:
                                                                                            optionindex ==
                                                                                              1
                                                                                              ? "4px solid #1ec756"
                                                                                              : null,
                                                                                        }}
                                                                                        className="font-bold text-center border border-gray-300"
                                                                                      >
                                                                                        {surveyLanguageCode !==
                                                                                          ""
                                                                                          ? question &&
                                                                                            question.transl &&
                                                                                            question
                                                                                              .transl[
                                                                                            surveyLanguageCode
                                                                                            ] &&
                                                                                            question
                                                                                              .transl[
                                                                                              surveyLanguageCode
                                                                                            ]
                                                                                              .optionsList &&
                                                                                            question
                                                                                              .transl[
                                                                                              surveyLanguageCode
                                                                                            ]
                                                                                              .optionsList[
                                                                                            option
                                                                                              .id
                                                                                            ]
                                                                                            ? question
                                                                                              .transl[
                                                                                              surveyLanguageCode
                                                                                            ]
                                                                                              .optionsList[
                                                                                            option
                                                                                              .id
                                                                                            ]
                                                                                            : option.name
                                                                                          : option.name}
                                                                                      </td>
                                                                                    )
                                                                                  )
                                                                                  : null}
                                                                              </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                              <tr>
                                                                                {FormatMatriXOptionHeading(
                                                                                  question,
                                                                                  true
                                                                                ) &&
                                                                                  FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    true
                                                                                  )
                                                                                    .length >
                                                                                  0
                                                                                  ? FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    true
                                                                                  ).map(
                                                                                    (
                                                                                      option,
                                                                                      optionindex
                                                                                    ) => (
                                                                                      <td
                                                                                        style={{
                                                                                          color:
                                                                                            surveyScreenData &&
                                                                                              surveyScreenData.OptionColor2
                                                                                              ? surveyScreenData.OptionColor2
                                                                                              : "black",
                                                                                          background:
                                                                                            surveyScreenData &&
                                                                                              surveyScreenData.OptionColor1
                                                                                              ? surveyScreenData.OptionColor1
                                                                                              : "#fff",
                                                                                          borderRight:
                                                                                            optionindex ==
                                                                                              1
                                                                                              ? "4px solid #1ec756"
                                                                                              : null,
                                                                                        }}
                                                                                        className="p-1 text-center border border-gray-300"
                                                                                      >
                                                                                        {showRadioMatrix(
                                                                                          SubQues,
                                                                                          option
                                                                                        ) ? (
                                                                                          <>
                                                                                            {option.name ===
                                                                                              "Yes" ? (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "#0ec934",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                  this.handleMatrixRadio(
                                                                                                    question,
                                                                                                    SubQues,
                                                                                                    option
                                                                                                  )
                                                                                                }
                                                                                                className="material-icons"
                                                                                              >
                                                                                                task_alt
                                                                                              </span>
                                                                                            ) : (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "#e02626",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                  this.handleMatrixRadio(
                                                                                                    question,
                                                                                                    SubQues,
                                                                                                    option
                                                                                                  )
                                                                                                }
                                                                                                className="material-icons"
                                                                                              >
                                                                                                highlight_off
                                                                                              </span>
                                                                                            )}
                                                                                          </>
                                                                                        ) : (
                                                                                          <>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  surveyScreenData &&
                                                                                                    surveyScreenData.OptionColor2
                                                                                                    ? surveyScreenData.OptionColor2
                                                                                                    : "black",
                                                                                              }}
                                                                                              onClick={() =>
                                                                                                this.handleMatrixRadio(
                                                                                                  question,
                                                                                                  SubQues,
                                                                                                  option
                                                                                                )
                                                                                              }
                                                                                              className="material-icons"
                                                                                            >
                                                                                              radio_button_unchecked
                                                                                            </span>
                                                                                          </>
                                                                                        )}
                                                                                      </td>
                                                                                    )
                                                                                  )
                                                                                  : null}
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </>
                                                                    )
                                                                  )
                                                                  : null}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {FormatMatriXOptionHeading(
                                                                  question,
                                                                  false
                                                                ) &&
                                                                  FormatMatriXOptionHeading(
                                                                    question,
                                                                    false
                                                                  ).length > 0
                                                                  ? FormatMatriXOptionHeading(
                                                                    question,
                                                                    false
                                                                  ).map(
                                                                    (
                                                                      heading,
                                                                      optionindex
                                                                    ) =>
                                                                      heading.span ? (
                                                                        <div className="flex">
                                                                          <div
                                                                            className="px-2 py-1 mb-1 font-bold"
                                                                            style={{
                                                                              color:
                                                                                "#fff",
                                                                              background:
                                                                                surveyScreenData &&
                                                                                  surveyScreenData.OptionColor1
                                                                                  ? surveyScreenData.OptionColor1
                                                                                  : "#fff",
                                                                              background:
                                                                                optionindex ===
                                                                                  0
                                                                                  ? "#1ec756"
                                                                                  : optionindex ===
                                                                                    1
                                                                                    ? "#2362d9"
                                                                                    : "#fff",
                                                                              borderRight:
                                                                                optionindex ==
                                                                                  0
                                                                                  ? "4px solid #1ec756"
                                                                                  : null,
                                                                            }}
                                                                          >
                                                                            {TranslationOptionMatrix(
                                                                              question,
                                                                              heading.name,
                                                                              surveyLanguageCode,
                                                                              2
                                                                            )}
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              color:
                                                                                optionindex ===
                                                                                  0
                                                                                  ? "#1ec756"
                                                                                  : optionindex ===
                                                                                    1
                                                                                    ? "#2362d9"
                                                                                    : "#fff",
                                                                            }}
                                                                            className="px-2 pt-1 font-bold text-gray-500 whitespace-pre-line"
                                                                          >
                                                                            {TranslationOptionMatrix(
                                                                              question,
                                                                              heading.name,
                                                                              surveyLanguageCode,
                                                                              3
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      ) : null
                                                                  )
                                                                  : null}

                                                                {question &&
                                                                  question.SubQuestionList &&
                                                                  question.SubQuestionList
                                                                    .length > 0 ? (
                                                                  <>
                                                                    <div
                                                                      className="overflow-y-auto"
                                                                      id="chatbot"
                                                                      style={{
                                                                        height: "450px",
                                                                      }}
                                                                    >
                                                                      <table
                                                                        className="relative min-w-full "
                                                                        style={{
                                                                          background:
                                                                            surveyScreenData &&
                                                                              surveyScreenData.OptionColor1
                                                                              ? surveyScreenData.OptionColor1
                                                                              : "#fff",
                                                                        }}
                                                                      >
                                                                        <thead className="">
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                color:
                                                                                  surveyScreenData &&
                                                                                    surveyScreenData.OptionColor2
                                                                                    ? surveyScreenData.OptionColor2
                                                                                    : "black",
                                                                                background:
                                                                                  surveyScreenData &&
                                                                                    surveyScreenData.OptionColor1
                                                                                    ? surveyScreenData.OptionColor1
                                                                                    : "#fff",
                                                                              }}
                                                                              className="sticky top-0 p-1 border border-gray-300"
                                                                              rowSpan={2}
                                                                            ></td>
                                                                            {FormatMatriXOptionHeading(
                                                                              question,
                                                                              false
                                                                            ) &&
                                                                              FormatMatriXOptionHeading(
                                                                                question,
                                                                                false
                                                                              ).length > 0
                                                                              ? FormatMatriXOptionHeading(
                                                                                question,
                                                                                false
                                                                              ).map(
                                                                                (
                                                                                  heading,
                                                                                  optionindex
                                                                                ) => (
                                                                                  <td
                                                                                    key={
                                                                                      optionindex
                                                                                    }
                                                                                    colSpan={
                                                                                      heading.span
                                                                                    }
                                                                                    className="sticky top-0 p-2 font-bold text-center border border-gray-300"
                                                                                    style={{
                                                                                      color:
                                                                                        "#fff",
                                                                                      background:
                                                                                        optionindex ===
                                                                                          0
                                                                                          ? "#1ec756"
                                                                                          : optionindex ===
                                                                                            1
                                                                                            ? "#2362d9"
                                                                                            : "#fff",
                                                                                      borderRight:
                                                                                        optionindex ==
                                                                                          0
                                                                                          ? "4px solid #1ec756"
                                                                                          : null,
                                                                                    }}
                                                                                  >
                                                                                    {TranslationOptionMatrix(
                                                                                      question,
                                                                                      heading.name,
                                                                                      surveyLanguageCode,
                                                                                      1
                                                                                    )}
                                                                                  </td>
                                                                                )
                                                                              )
                                                                              : null}
                                                                          </tr>

                                                                          <tr>
                                                                            {/* <td className="w-40 px-4 py-3 text-sm text-left border border-gray-300 "> </td> */}

                                                                            {FormatMatriXOptionHeading(
                                                                              question,
                                                                              true
                                                                            ) &&
                                                                              FormatMatriXOptionHeading(
                                                                                question,
                                                                                true
                                                                              ).length > 0
                                                                              ? FormatMatriXOptionHeading(
                                                                                question,
                                                                                true
                                                                              ).map(
                                                                                (
                                                                                  option,
                                                                                  optionindex
                                                                                ) => (
                                                                                  <td
                                                                                    key={
                                                                                      optionindex
                                                                                    }
                                                                                    style={{
                                                                                      color:
                                                                                        surveyScreenData &&
                                                                                          surveyScreenData.OptionColor2
                                                                                          ? surveyScreenData.OptionColor2
                                                                                          : "black",
                                                                                      background:
                                                                                        surveyScreenData &&
                                                                                          surveyScreenData.OptionColor1
                                                                                          ? surveyScreenData.OptionColor1
                                                                                          : "#fff",
                                                                                      borderRight:
                                                                                        optionindex ==
                                                                                          1
                                                                                          ? "4px solid #1ec756"
                                                                                          : null,
                                                                                    }}
                                                                                    className="sticky px-4 py-1 text-sm font-bold text-center border border-gray-300 top-10 "
                                                                                  >
                                                                                    {surveyLanguageCode !==
                                                                                      ""
                                                                                      ? question &&
                                                                                        question.transl &&
                                                                                        question
                                                                                          .transl[
                                                                                        surveyLanguageCode
                                                                                        ] &&
                                                                                        question
                                                                                          .transl[
                                                                                          surveyLanguageCode
                                                                                        ]
                                                                                          .optionsList &&
                                                                                        question
                                                                                          .transl[
                                                                                          surveyLanguageCode
                                                                                        ]
                                                                                          .optionsList[
                                                                                        option
                                                                                          .id
                                                                                        ]
                                                                                        ? question
                                                                                          .transl[
                                                                                          surveyLanguageCode
                                                                                        ]
                                                                                          .optionsList[
                                                                                        option
                                                                                          .id
                                                                                        ]
                                                                                        : option.name
                                                                                      : option.name}
                                                                                  </td>
                                                                                )
                                                                              )
                                                                              : null}
                                                                          </tr>
                                                                        </thead>
                                                                        <tbody className="">
                                                                          {question.SubQuestionList.map(
                                                                            (
                                                                              SubQues,
                                                                              index
                                                                            ) => (
                                                                              <tr
                                                                                key={
                                                                                  index
                                                                                }
                                                                              >
                                                                                <td
                                                                                  className="px-4 py-1 text-sm text-left whitespace-pre-line border border-gray-300 w-82"
                                                                                  style={{
                                                                                    color:
                                                                                      showNotCompletedFn(
                                                                                        SubQues
                                                                                      )
                                                                                        ? "#e31919"
                                                                                        : surveyScreenData &&
                                                                                          surveyScreenData.OptionColor2
                                                                                          ? surveyScreenData.OptionColor2
                                                                                          : "black",
                                                                                  }}
                                                                                >
                                                                                  {" "}
                                                                                  {(
                                                                                    questionindex +
                                                                                    1
                                                                                  ).toString() +
                                                                                    "." +
                                                                                    (
                                                                                      index +
                                                                                      1
                                                                                    ).toString() +
                                                                                    ") " +
                                                                                    TranslateQuestion(
                                                                                      surveyLanguageCode,
                                                                                      SubQues
                                                                                    )}
                                                                                </td>

                                                                                {FormatMatriXOptionHeading(
                                                                                  question,
                                                                                  true
                                                                                ) &&
                                                                                  FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    true
                                                                                  ).length >
                                                                                  0
                                                                                  ? FormatMatriXOptionHeading(
                                                                                    question,
                                                                                    true
                                                                                  ).map(
                                                                                    (
                                                                                      option,
                                                                                      optionindex
                                                                                    ) => (
                                                                                      <td
                                                                                        key={
                                                                                          optionindex
                                                                                        }
                                                                                        style={{
                                                                                          borderRight:
                                                                                            optionindex ==
                                                                                              1
                                                                                              ? "4px solid #1ec756"
                                                                                              : null,
                                                                                        }}
                                                                                        className="px-4 py-1 text-center border border-gray-300"
                                                                                      >
                                                                                        {showRadioMatrix(
                                                                                          SubQues,
                                                                                          option
                                                                                        ) ? (
                                                                                          <>
                                                                                            {option.name ===
                                                                                              "Yes" ? (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "#0ec934",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                  this.handleMatrixRadio(
                                                                                                    question,
                                                                                                    SubQues,
                                                                                                    option
                                                                                                  )
                                                                                                }
                                                                                                className="material-icons"
                                                                                              >
                                                                                                task_alt
                                                                                              </span>
                                                                                            ) : (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "#e02626",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                  this.handleMatrixRadio(
                                                                                                    question,
                                                                                                    SubQues,
                                                                                                    option
                                                                                                  )
                                                                                                }
                                                                                                className="material-icons"
                                                                                              >
                                                                                                highlight_off
                                                                                              </span>
                                                                                            )}
                                                                                          </>
                                                                                        ) : (
                                                                                          <>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  surveyScreenData &&
                                                                                                    surveyScreenData.OptionColor2
                                                                                                    ? surveyScreenData.OptionColor2
                                                                                                    : "black",
                                                                                              }}
                                                                                              onClick={() =>
                                                                                                this.handleMatrixRadio(
                                                                                                  question,
                                                                                                  SubQues,
                                                                                                  option
                                                                                                )
                                                                                              }
                                                                                              className="material-icons"
                                                                                            >
                                                                                              radio_button_unchecked
                                                                                            </span>
                                                                                          </>
                                                                                        )}
                                                                                      </td>
                                                                                    )
                                                                                  )
                                                                                  : null}
                                                                              </tr>
                                                                            )
                                                                          )}
                                                                        </tbody>
                                                                      </table>
                                                                    </div>
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>

                                                        <div className="px-2 mb-24 md:px-2"> </div>
                                                      </>
                                                    ) : null}
                                                  </div>

                                                  <div className={question.templateId === 13 ? "px-4 -pt-4 justify-center items-center" : "px-4 pt-4 justify-center items-center"} >
                                                    {this.state.unattemptList &&
                                                      this.state.showRedFlag ? (
                                                      this.state.unattemptList &&
                                                        this.state.unattemptList.length >
                                                        0 ? (
                                                        <p className="px-4 font-light text-gray-100 md:px-6 md:text-md">
                                                          <span style={{ cursor: "default", color: surveyScreenData && surveyScreenData.textColor ? surveyScreenData.textColor : "black", }} className="font-semibold"> {"Please attempt mandatory question no. "}</span>
                                                          {this.state.unattemptList.map(
                                                            (elem, index) => (
                                                              <span
                                                                key={index}
                                                                onClick={() =>
                                                                  this.onMandatoryAttempt(
                                                                    elem
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor: "pointer",
                                                                  fontWeight: "bold",
                                                                  color:
                                                                    surveyScreenData &&
                                                                      surveyScreenData.textColor
                                                                      ? surveyScreenData.textColor
                                                                      : "black",
                                                                }}
                                                              >
                                                                {index + 1 ===
                                                                  this.state.unattemptList
                                                                    .length ? (
                                                                  <span
                                                                    style={{
                                                                      textDecoration:
                                                                        "underline",
                                                                    }}
                                                                  >
                                                                    {" " + elem}
                                                                  </span>
                                                                ) : (
                                                                  <span
                                                                    style={{
                                                                      textDecoration:
                                                                        "underline",
                                                                    }}
                                                                  >
                                                                    {elem + " , "}
                                                                  </span>
                                                                )}
                                                              </span>
                                                            )
                                                          )}
                                                        </p>
                                                      ) : null
                                                    ) : null}
                                                  </div>

                                                  {/* <div className="flex items-center justify-center mt-8 space-x-4">
                                          {questionindex + 1 > 1 ?
                                            <button onClick={() => this.prevQues(2)} className="uppercase md:text-base text-base font-medium rounded-md  px-10 py-3 cursor-pointer border border-[#212121] hover:bg-[#212121]/10 transition-all duration-100 text-[#212121] overflow-hidden"> {"Previous"}</button>
                                            : null
                                          }

                                          {this.state.ShowSubmitButton ?
                                            <button onClick={() => this.handleSubmitOpenModel()} className="uppercase md:text-base  text-base font-medium rounded-md  px-10 py-3 cursor-pointer  bg-[#2196f3] border-[#2196f3] hover:border-[#1976d2] text-white">
                                              {"Submit"}</button>
                                            :
                                            <button onClick={() => this.nextQues(2)} className="uppercase md:text-base text-base font-medium rounded-md  px-10 py-3 cursor-pointer  bg-[#2196f3] hover:bg-[#1976d2] border-[#2196f3] hover:border-[#1976d2] transition-all duration-100 text-white overflow-hidden">
                                              {"Next"}</button>
                                          }

                                        </div> */}
                                                </>
                                              ) : null
                                            )
                                            : null}

                                          <div className="h-20" />

                                          {/* footer button */}

                                          {questionsList && questionsList.length > 0
                                            ? questionsList.map((question, questionindex) =>
                                              questionindex + 1 === CurrQuesIndex ? (
                                                <div className="w-full py-3  bg-white/40 backdrop-blur-xl border-t  fixed bottom-0 right-0 flex items-center  ">
                                                  <div className=" 2xl:w-5/12 lg:w-6/12 w-full lg:px-0 px-4  gap-4 mx-auto  flex md:flex-row flex-col-reverse justify-between items-center ">
                                                    <p className="text-sm text-[#212121] flex md:justify-start justify-center  w-full items-center"> © Powered by <b className="ml-1 font-medium text-[#212121]">HappyPlus </b></p>
                                                    <div className="lg:w-auto w-full flex justify-end items-center space-x-3 ">
                                                      {questionindex + 1 > 1 ?
                                                        <button onClick={() => this.prevQues(2)} className="uppercase md:text-base text-base font-medium rounded-md px-4 py-2 cursor-pointer border border-[#212121] hover:bg-[#212121]/10 transition-all duration-100 text-[#212121] overflow-hidden md:w-36 w-full"> {"Previous"}</button>
                                                        : null
                                                      }

                                                      {this.state.ShowSubmitButton ?
                                                        <button onClick={() => this.handleSubmitOpenModel()} className="uppercase md:text-base  text-base font-medium rounded-md px-4 py-2 cursor-pointer  bg-[#2196f3] border-[#2196f3] hover:border-[#1976d2] text-white md:w-36 w-full">
                                                          {"Submit"}</button>
                                                        :
                                                        <button onClick={() => this.nextQues(2)} className="uppercase md:text-base text-base font-medium rounded-md px-4 py-2 cursor-pointer  bg-[#2196f3] hover:bg-[#1976d2] border-[#2196f3] hover:border-[#1976d2] transition-all duration-100 text-white overflow-hidden md:w-36 w-full">
                                                          {"Next"}</button>
                                                      }

                                                    </div>

                                                  </div>

                                                </div>
                                              ) : null
                                            )
                                            : null}

                                        </div>
                                      </div>
                                    </div>

                                  </section>
                                ) : null}



                                {/* Thank you section start */}
                                {this.state.showThankyouScreen && false ? (
                                  <>
                                    <div className="h-[calc(100vh-8rem)] flex items-center justify-center">
                                      <section className="flex items-start w-full px-6 py-10 mx-auto 2xl:w-8/12 xl:w-10/12 xl:px-0 md:space-x-10 ">
                                        <div className="w-full mx-auto space-y-10 text-center lg:w-4/6 md:px-10">
                                          <div className="space-y-4 text-base font-normal text-white/80 ">
                                            <div className="flex flex-col items-center justify-center space-y-6 md:space-y-10">
                                              <div
                                                className="md:w-[12rem] w-[10rem] md:h-[12rem] h-[10rem] rounded-full bg-white/10 flex justify-center items-center"
                                                style={{
                                                  background: getTheme(
                                                    "lightblue",
                                                    hexToRgbA("#FFFFFF", "0.10")
                                                  ),
                                                }}
                                              >
                                                <div style={{ background: getTheme("textblue", hexToRgbA("#FFFFFF", "0.10")), }} className="md:w-[10rem] w-[8rem] h-[8rem] md:h-[10rem] rounded-full bg-white/20 flex justify-center items-center">
                                                  <span className="material-icons md:text-[4.5rem] text-[4rem] text-white">
                                                    thumb_up
                                                  </span>
                                                </div>
                                              </div>
                                              <h1 className="text-3xl font-bold text-white lg:text-5xl md:text-4xl" style={{ color: getTheme("text", "#FFFFFF") }}>  Thank You!</h1>
                                            </div>

                                            <h1
                                              className="py-2 text-xl font-normal md:text-2xl text-white/100"
                                              style={{ color: getTheme("text", "#FFFFFF") }}
                                            >
                                              Your report will be generate within a few minutes. Please click on "view report" button or refresh the page to see the report.
                                            </h1>
                                          </div>

                                          {/* {showViewReportThankYouButton ? (
                              <div className="flex justify-center w-full">
                                <button
                                  onClick={() => this.goToSummary()}
                                  className="md:w-80 w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-4 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden  "
                                >
                                  <span className="material-icons-outlined mr-2 text-[1.5rem]">
                                    assignment
                                  </span>
                                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                                  {"View Report"}
                                </button>
                              </div>
                            ) : (
                              <div className="px-6">
                                <div className="dot-pulse"></div>
                              </div>
                            )} */}

                                          <div className="fixed bottom-0 left-0 w-full">
                                            <div className="w-full px-4 py-4 mx-auto xl:w-11/12 xl:px-0 md:py-6 md:bg-transparent bg-white/10">
                                              <div className="flex justify-end w-full">
                                                <div className="flex flex-col items-center w-full space-y-4 md:justify-center 2xl:space-y-10 xl:space-y-10 ">
                                                  <div className="flex justify-center w-full md:justify-end">
                                                    <p className="text-sm text-white/80 " style={{ color: getTheme("text", "#FFFFFF"), }}>  © Powered by  <b className="ml-1 font-medium text-white/100" style={{ color: getTheme("text", "#FFFFFF"), }}> HappyPlus  </b>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </section>
                                    </div>
                                  </>
                                ) : null}
                                {/* Thank you section end */}

                              </>
                            ) : null}

                            {(surveyStatus === 2 &&
                              !(questionsList && questionsList.length > 0)) ||
                              this.state.alreadySubmitted ? ( //already done
                              <div className="">

                                <section className="px-6 py-6 mx-auto xl:w-3/6 lg:w-4/6 lg:px-0 md:px-6 ">
                                  <div className="flex justify-center pb-16 mt-12">
                                  </div>
                                  <div className="flex items-center justify-center h-full">
                                    <div className="text-center text-white">
                                      <div className="flex justify-center pb-8">
                                        <img
                                          alt="pic"
                                          src="pictures/surveydone.png"
                                          className="md:w-auto w-6/6 "
                                        />
                                      </div>
                                      <h1
                                        className="text-3xl font-bold leading-10 tracking-widest text-gray-800 md:text-4xl leading_lose"
                                        style={{}}
                                      >
                                        <div
                                          style={{
                                            textAlign: "center",
                                            color:
                                              surveyScreenData && surveyScreenData.textColor
                                                ? surveyScreenData.textColor
                                                : "black",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              SurveyAttributes("AlreadyText")
                                                ? "<h1>" +
                                                SurveyAttributes("AlreadyText") +
                                                "</h1>"
                                                : "<h1>Survey Is Already Submitted !</h1>",
                                          }}
                                        />
                                      </h1>
                                      <p className="py-2 text-xl font-light opacity-80"></p>
                                    </div>
                                  </div>
                                </section>
                                {/* Survey Is Already Submitted end */}


                                {/* Footer start */}
                                <div
                                  style={{
                                    height: "10%",
                                    backgroundColor:
                                      bgType === 1 || afterSubmitData.bgType === 1
                                        ? bgColor
                                          ? bgColor
                                          : afterSubmitData.bgColor
                                            ? afterSubmitData.bgColor
                                            : "#757575"
                                        : "#f1f1f1",
                                    backgroundImage:
                                      bgType === 2 || afterSubmitData.bgType === 2
                                        ? bgImage
                                          ? bgImage
                                          : afterSubmitData.bgImage
                                            ? afterSubmitData.bgImage
                                            : null
                                        : null,
                                  }}
                                  className="fixed left-0 flex justify-center w-full bg-white bg-opacity-100 bottom-6"
                                >
                                  <p
                                    className="py-4 text-sm"
                                    style={{ color: getTheme("text", "#FFFFFF") }}
                                  >
                                    {" "}
                                    © Powered by <b className="font-semibold">happyPlus</b>
                                  </p>
                                </div>
                                {/* Footer end */}

                              </div>
                            ) : null}

                            {surveyStatus === 3 &&
                              !(questionsList && questionsList.length > 0) ? (
                              //expired
                              <div>
                                {/* This Survey is Expired  start*/}
                                <section className="flex items-center justify-center px-2 py-32 mx-auto overflow-y-hidden lg:w-4/6 lg:px-0 md:px-6">
                                  <div>
                                    <div className="flex justify-center">
                                      <img src="img/expiry.png" className="w-32" />
                                    </div>
                                    <div className="flex items-center justify-center h-full">
                                      <div
                                        className="mt-6 text-2xl font-bold text-center"
                                        style={{
                                          textAlign: "center",
                                          color:
                                            surveyScreenData && surveyScreenData.textColor
                                              ? surveyScreenData.textColor
                                              : "black",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            SurveyAttributes("ExpiryText")
                                              ? "<h1>" +
                                              SurveyAttributes("ExpiryText") +
                                              "</h1>"
                                              : "<h1>This Survey is <br>Expired!</h1>",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </section>

                                <div
                                  style={{
                                    height: "10%",
                                    backgroundColor:
                                      bgType === 1 || afterSubmitData.bgType === 1
                                        ? bgColor
                                          ? bgColor
                                          : afterSubmitData.bgColor
                                            ? afterSubmitData.bgColor
                                            : "#757575"
                                        : "#f1f1f1",
                                    backgroundImage:
                                      bgType === 2 || afterSubmitData.bgType === 2
                                        ? bgImage
                                          ? bgImage
                                          : afterSubmitData.bgImage
                                            ? afterSubmitData.bgImage
                                            : null
                                        : null,
                                  }}
                                  className="fixed left-0 flex justify-center w-full bg-white bg-opacity-100 bottom-6">
                                  <p className="py-4 text-sm" style={{ color: getTheme("text", "#FFFFFF") }}> {" "} © Powered by <b className="font-semibold">happyPlus</b></p>
                                </div>
                                {/* This Survey is Expired  end*/}
                              </div>
                            ) : null}


                          </>

                          :
                          <>
                            {generateCareerReportLoading && showGenerateActive ?
                              <>
                                <div className="h-[calc(100vh-8rem)] flex items-center justify-center">
                                  <section className="flex items-start w-full px-6 py-10 mx-auto 2xl:w-8/12 xl:w-10/12 xl:px-0 md:space-x-10 ">
                                    <div className="w-full mx-auto space-y-10 text-center lg:w-[40rem] md:p-10 p-6 bg-white rounded-2xl shadow-lg shadow-black/5">
                                      <div className="space-y-2 text-base font-normal text-white/80 ">
                                        <div className="flex flex-col items-center justify-center space-y-2">

                                          <div className="relative size-48">
                                            <svg className="size-full" width={180} height={180} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                              {/* Background Circle */}
                                              <circle cx={18} cy={18} r={16} fill="none" className="stroke-current  text-[#E3F2FD]" strokeWidth={3} />
                                              {/* Progress Circle inside a group with rotation */}
                                              <g className="origin-center -rotate-90 transform">
                                                <circle cx={18} cy={18} r={16} fill="none" className="stroke-current text-[#2196f3]" strokeWidth={3}
                                                  strokeDasharray={100}
                                                  strokeDashoffset={downloadStatusNo} />
                                              </g>
                                            </svg>
                                            {/* Percentage Text */}
                                            <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M66.6673 26.667L46.6673 6.66699H20.0007C18.2325 6.66699 16.5368 7.36937 15.2866 8.61961C14.0364 9.86986 13.334 11.5655 13.334 13.3337V66.667C13.334 68.4351 14.0364 70.1308 15.2866 71.381C16.5368 72.6313 18.2325 73.3337 20.0007 73.3337H60.0006C61.7688 73.3337 63.4645 72.6313 64.7147 71.381C65.9649 70.1308 66.6673 68.4351 66.6673 66.667V26.667ZM30.0007 63.3337H23.334V33.3337H30.0007V63.3337ZM43.334 63.3337H36.6673V43.3337H43.334V63.3337ZM56.6673 63.3337H50.0007V53.3337H56.6673V63.3337ZM46.6673 30.0003H43.334V13.3337L60.0006 30.0003H46.6673Z" fill="#2196F3" />
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                        <h1 className="text-2xl font-bold text-white md:text-4xl pt-4" style={{ color: getTheme("text", "#FFFFFF") }}>
                                          {SurveyAttributes("Generating Report") ? SurveyAttributes("Generating Report") + "!" : "Generating Report!"}</h1>
                                        <p className="pb-2 md:text-xl text-lg font-normal text-white/100" style={{ color: getTheme("text", "#FFFFFF") }}>
                                          {SurveyAttributes("Please wait a moment while report is generating") ? SurveyAttributes("Please wait a moment while report is generating") + "!" : "Please wait a moment while report is generating!"}</p>

                                      </div>
                                    </div>
                                  </section>
                                </div>

                              </>
                              :
                              <TestListModule
                                handleTestSelect={this.handleTestSelect}
                                handleTestClick={this.handleTestClick}
                                getTestModuleData={getTestModuleData}
                                crrSurveyId={crrSurveyId}
                                handleViewReport={this.handleShowReport}
                              />
                            }

                          </>}

                      </>}

                  </>}



              </div>
            </>
          ) : null
          }


          {this.state.showSubmitModal && (
            <SurveySubmitModel
              onClose={this.handleSubmitCloseModal}
              onSubmitConfirm={this.handleFinalSubmitConfirm}
              categoryQuestions={categoryQuestions}
              crrTestDetails={crrTestDetails}
              timeoutModalLoad={timeoutModalLoad}
            />
          )}

          {showConfirmSubmit && (<>
            <ConfirmSubmitModal
              onClose={this.closeFinalSubmitConfirm}
              onSubmit={this.onFinalSubmit}
            />
          </>)}


          {showTimeoutModal && (<>
            <TimeoutModal
              onClose={this.closeTimeoutModal}
              onSubmitConfirm={this.handleFinalSubmitConfirm}
              categoryQuestions={categoryQuestions}
              crrTestDetails={crrTestDetails}
              timeoutModalLoad={timeoutModalLoad}
            />
          </>)}

        </div>
      </>
    );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, assessment } = state;
  return {
    loggingIn,
    questions,
    users,
    assessment
  };
}

export default connect(mapStateToProps)(QuestionPublish);