import React from "react";

export default function VideoFrameModal(props) {
  let { getTalentResourcesData, dimension, closeVideoModal } = props;

  let ele = {
    "url": "",
    "imageUrl": "",
  }

  if (getTalentResourcesData && getTalentResourcesData.length > 0) {
    let getIndex = getTalentResourcesData.findIndex(prev => prev.dimension === dimension);
    if (getIndex !== -1) {
      ele["url"] = getTalentResourcesData[getIndex]["url"];
      ele["imageUrl"] = getTalentResourcesData[getIndex]["imageUrl"];
    }
  }

  console.log("getTalentResourcesData-------->",getTalentResourcesData)
  console.log("ele-------->",ele)

  return (
    <>
      <div className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full bg-black bg-opacity-60" >
        <div className="p-4 flex justify-center items-start h-screen overflow-hidden">

          <div className="bg-white  2xl:w-3/6 xl:w-4/6 lg:w-5/6 mx-auto rounded-md overflow-y-auto customscroll5 overflow-hidden"
            style={{ height: '700px' }} >

            <div className="flex md:flex-row flex-col justify-between border-b items-center p-4 px-6 sticky top-0 bg-white z-20"  >
              <h1 className="font-semibold md:text-xl text-lg line-clamp-1 text-[#3D405B]">
                {dimension}
              </h1>

              <div onClick={() => closeVideoModal()} className=" w-10 h-10 rounded-full  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>
            <div className="p-12 flex justify-center items-center">
              <video width="750" height="500" controls poster={ele.imageUrl} >
                <source src={ele.url} type="video/mp4" />
              </video>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
