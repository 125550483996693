import { dataTool } from "echarts";
import React from "react";

export default function NewAssignAssessmentCodes(props) {
  let { closeModal, createdAt, data } = props;

  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative   left-0 right-0 overflow-hidden">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden block">

            <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl text-gray-800">View Assessment Codes</h2>
              <div onClick={() => closeModal(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

            <div className="py-4 p-6 space-y-3">

              {/* <div className="text-sm space-y-1">
                <h2 className="text-base font-semibold text-[#212121]">{createdAt}</h2>
                <p className="text-sm text-[#212121]/70">Total assigned assessment codes: <span className="font-semibold text-[#212121]">4</span></p>
              </div> */}


              <div className="mt-4">
                <p className="text-sm text-[#212121]/70">Total assessment codes: <span className="font-semibold text-[#212121]">{data && data.length > 0 ? data.length : 0}</span></p>
                <div className="w-full mx-auto">
                  <div className="inline-block min-w-full py-2">
                    <div className="overflow-hidden overflow-y-auto h-[350px]">
                      <table className="min-w-full border">
                        <thead className="bg-gray-50 sticky top-0">
                          <tr>
                            <th className="px-3 py-2 border text-sm font-semibold text-gray-900 w-20 text-center">S.N.</th>
                            <th className="px-3 py-2 text-left border text-sm font-semibold text-gray-900">Code</th>
                            <th className="px-3 py-2 text-left border text-sm font-semibold text-gray-900">Status</th>
                          </tr>
                        </thead>
                        <tbody className="border divide-gray-200 bg-white">
                          {data && data.length > 0 && data.map((item, index) => (
                            <tr>
                              <td className="whitespace-nowrap px-2 py-1 border text-sm text-center w-20">{index + 1}</td>
                              <td className="whitespace-nowrap px-2 py-1 border text-sm">
                                <div className="border-dashed border-2 border-[#4CAF50] bg-[#4CAF50]/10 text-[#4CAF50] px-3 py-1 w-56">
                                  {item.otpCode}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-2 py-1 border text-sm">
                                <div className="flex items-center justify-center">
                                  <div className="bg-white border cursor-pointer border-gray-300 text-center text-gray-900 text-sm rounded-md w-32 px-3 py-1.5 focus:outline-none">
                                    <span className="border-b-2 border-[#212121]">{'UnAssigned'}</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* 
              <div className="text-right pt-4">
                <button onClick={() => saveNewEmployee()} type="button" className="w-44 bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase">Add Code</button>
              </div> */}
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
