import React from 'react';

export default function Component(props) {
const { question, answersList,handleRadio, surveyLanguageCode, surveyScreenData } = props;
let gridType = question && question.optionsList && question.optionsList.length>0?question.optionsList.length:0;

let designId = question && question.designId?question.designId:"";
let designType = "star";
if(designId==="number"){
  designType = "number"
}
else{
  designType = "star"
}
return(
    <>
      {designType==="star"?
      <>
        {/* <div className="p-2 flex justify-around text-yellow-500">
          <span className="material-icons-outlined cursor-pointer">grade</span>
          <span className="material-icons-outlined cursor-pointer">grade</span>
          <span className="material-icons-outlined cursor-pointer">grade</span>
          <span className="material-icons-outlined cursor-pointer">grade</span>
          <span className="material-icons-outlined cursor-pointer">grade</span>
        </div> */}


        <div className="p-2 flex justify-around text-yellow-500">

        {question && question.optionsList && question.optionsList.length>0?
          question.optionsList.map((option, optionindex) =>
          <span key={optionindex}>
         { ((answersList && answersList.length > 0 && answersList.findIndex(item => item.questionId === question.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question.id)].answer: '')?
                ((optionindex+1)<=answersList[answersList.findIndex(item => item.questionId === question.id)].answer)?
                <>
                {/* <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="px-2 cursor-pointer text-white ">
                <img src="img/questiontype/star/star-bot.png" style={{width:"35px",height:'35px'}} alt="star"/></div>



                <div className='w-16 h-16 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div> */}

                <span onClick={() => handleRadio((optionindex+1), question, option)} className="material-icons-outlined cursor-pointer">star</span>

                

                </>
                :
                <>
                {/* <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="px-2 cursor-pointer text-white "><img src="img/questiontype/star/star-outline-bot.png" style={{width:"35px",height:'35px'}} alt="emptystar"/></div>
                
                
                
                
                <div className='w-16 h-16 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div> */}


                <span onClick={() => handleRadio((optionindex+1), question, option)} className="material-icons-outlined cursor-pointer">grade</span>

                </>
              :
              <>
              {/* <div>
              <div onClick={() => handleRadio((optionindex+1), question, option)}  className="px-2 cursor-pointer text-white "><img src="img/questiontype/star/star-outline-bot.png" style={{width:"35px",height:'35px'}} alt="emptystar"/></div>
              <div className='w-16 h-16 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
              </div> */}


              <span onClick={() => handleRadio((optionindex+1), question, option)} className="material-icons-outlined cursor-pointer">grade</span>

              </>
              }
              </span>
        ):null}
        </div>
      </>
      :null}


    {designType==="number"?
      <>
        {/* <div className={"flex  pt-4 gap-4 md:px-6 px-4 sm:px-40 md:px-40 lg:px-30 xl:px-32 2xl:px-40 justify-center items-center "+"grid md:grid-cols-"+gridType+" grid-cols-"+gridType}> */}
        <div className="flex justify-end py-4  float-right w-full">

        {question && question.optionsList && question.optionsList.length>0?
          question.optionsList.map((option, optionindex) =>

          ((answersList && answersList.length > 0 && answersList.findIndex(item => item.questionId === question.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question.id)].answer: '')?
                ((optionindex+1)<=answersList[answersList.findIndex(item => item.questionId === question.id)].answer)?
                <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="rounded-md md:p-4 md:py-4 p-4 py-2 border-2 border-blue-500  flex items-center justify-center  bg-blue-400 text-white font-medium  cursor-pointer   px-1">
                  {optionindex+1}
                </div>
                <div className='w-12 h-12 pt-2 text-xs font-bold text-white'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div>
                :
                <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 border-2 border-gray-500  flex items-center justify-center  hover:bg-blue-400 hover:text-white font-medium  cursor-pointer   px-1">
                  {optionindex+1}
                  </div>
                <div className='w-12 h-12 pt-2 text-xs font-bold text-white'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div>
              :
              <div>
              <div onClick={() => handleRadio((optionindex+1), question, option)}  className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 border-2 border-gray-500  flex items-center justify-center  hover:bg-blue-400 hover:text-white font-medium  cursor-pointer   px-1">
                {optionindex+1}
                </div>
              <div className='w-12 h-12 pt-2 text-xs font-bold text-white'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
              </div>
        ):null}
        </div>
      </>
      :null}


    </>
  );}