import React, { Component } from "react"; 
import { connect } from "react-redux";
import { assessmentActions, userActions } from "../../../_actions"; 
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import { Helmet } from "react-helmet";
import AOS from "aos";
import Ready from "../../../components/Ready";
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      fieldslogin: {},
      errorslogin: {},
      saveSuccess: false
    };
  }

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());

    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });

  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };


  inputChange2 = (e, field) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[field] = value;
    errorslogin[field] = "";
    this.setState({ fieldslogin, errorslogin });
  };


  loginSubmit = () => {

    console.log("this.handleValidationLogin()---->", this.handleValidationLogin())

    if (this.handleValidationLogin()) {
      let { userName, email, phone, jobtitle, company, postalcode, message } =
        this.state.fieldslogin;
      this.setState({ saveSuccess: true })
      this.props.dispatch(
        assessmentActions.saveAssessmentContactUs(
          {
            name: userName,
            email: email,
            phone: phone,
            jobtitle: jobtitle,
            company: company,
            postalcode: postalcode,
            message: message,
          },
          this.props
        )
      );
      setTimeout(() => {
        this.setState({ saveSuccess: false })
      }, 4000)
    }
  };

  handleValidationLogin = () => {
    let { fieldslogin } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }


    //userName
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid += 1;
      console.log("1 userName !")
      errorslogin["userName"] = "Full name is required";
    }
    //email
    if (!fieldslogin["email"]) {
      console.log("1 email !")

      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(fieldslogin["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }
    //phone
    if (!fieldslogin["phone"]) {
      formIsValid = false;
      errorslogin["phone"] = "Phone is required";
    }
    //jobtitle
    if (!fieldslogin["jobtitle"]) {
      console.log("1 jobtitle !")

      formIsValid += 1;
      errorslogin["jobtitle"] = "Job Title is required";
    }
    //company
    if (!fieldslogin["company"]) {
      console.log("1 company !")

      formIsValid += 1;
      errorslogin["company"] = "Company is required";
    }
    //country
    if (!fieldslogin["country"]) {
      console.log("1 country !")

      formIsValid += 1;
      errorslogin["country"] = "Country is required";
    }
    //postalcode
    if (!fieldslogin["postalcode"]) {
      formIsValid += 1;
      errorslogin["postalcode"] = "Postal Code is required";
    }
    //message
    if (!fieldslogin["message"]) {
      console.log("1 message !")

      formIsValid += 1;
      errorslogin["message"] = "Message is required";
    }
    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };



  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem('route')
      localStorage.setItem('route', `/assessment/` + field)
      this.props.history.push(`/assessment/sign-in`);
    }
  }

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (!(userAssessmentUserProfileData && userAssessmentUserProfileData.email)) {
        this.props.history.push(`/assessment/` + 'userlogin');
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  handleSignInPage = () => {
    localStorage.removeItem('route')
    localStorage.setItem('route', this.props.history.location.pathname)
    this.props.history.push(`/assessment/sign-in`);
  };


  handleRedirect = () => {
    this.props.history.push('/assessment/contact-us');
  }

  handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData,
      getAssessmentCartCountData, getAssessmentPackagesShortListData } = assessment;
    let { quantity, fieldslogin } = this.state;
    console.log("fieldslogin-->", fieldslogin);


    return (
      <>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us | TalentPlusStrengths</title>
          <meta name="description" content="For customer support and query, get in touch with TalentPlusStrengths." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/contact-us" />
        </Helmet>

        <div className="">
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={
              getAssessmentCartCountData ? getAssessmentCartCountData : 0
            }
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          <section className="py-16 bg-[#F9F9FC]">
            <div className="grid w-full grid-cols-1 gap-8 px-4 mx-auto text-gray-100 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:grid-cols-2">
              <div className="space-y-4 ">
                <div data-aos="fade-up" className="space-y-2 text-[#3D405B]">
                  <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                    Get in touch
                  </h2>
                  <p className="lg:text-lg text-base text-[#3D405B]">
                    For customer support and query, get in touch with us.
                  </p>
                </div>

                <div className="space-y-0 ">
                  <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 border-b">
                    <div
                      className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#3D405B] "
                      href="#0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-mail"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="3" y="5" width="18" height="14" rx="2" />
                        <polyline points="3 7 12 13 21 7" />
                      </svg>
                    </div>
                    <div className=" mt-1 text-[#3D405B] flex-1">
                      <h1 className="text-xl font-semibold ">
                        Email
                      </h1>
                      <p className="text-base ">connect@happyplus.in</p>
                    </div>
                  </div>

                  <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 ">
                    <div
                      className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#3D405B] "
                      href="#0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="3" y1="21" x2="21" y2="21" />
                        <line x1="9" y1="8" x2="10" y2="8" />
                        <line x1="9" y1="12" x2="10" y2="12" />
                        <line x1="9" y1="16" x2="10" y2="16" />
                        <line x1="14" y1="8" x2="15" y2="8" />
                        <line x1="14" y1="12" x2="15" y2="12" />
                        <line x1="14" y1="16" x2="15" y2="16" />
                        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                      </svg>
                    </div>
                    <div className=" mt-1 text-[#3D405B] flex-1">
                      <h1 className="text-xl font-semibold ">
                        Office Address:
                      </h1>
                      <p className="text-base ">
                        HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai
                        400065
                      </p>
                    </div>
                  </div>
                </div>

                <img
                  data-aos="fade-right"
                  src="/img/domaim/contact-us.svg"
                  alt="contact-us"
                  className="flex items-center justify-center p-6 w-6/6 "
                  width={790}
                  height={560}
                />
              </div>
              <div className="space-y-6 ">
                <div data-aos="fade-up" className="space-y-2 ">
                  <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                    Submit Details
                  </h2>
                  <p className="lg:text-lg text-base text-[#3D405B]">
                    Fill in your details below & we will get back to you soon!
                  </p>
                </div>

                <div noValidate action="true" className="grid grid-cols-1 gap-6 pt-2">
                  <div data-aos="fade-up" className="space-y-2">
                    <label
                      htmlFor="name"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678] capitalize"
                    >
                      Full name
                    </label>
                    <input
                      className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["userName"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                      id="userName"
                      name="userName"
                      placeholder="John Methu"
                      type="text"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin["userName"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["userName"]}
                      </div>
                    ) : null}
                  </div>

                  <div data-aos="fade-up" className="space-y-2">
                    <label
                      htmlFor="email"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Email
                    </label>
                    <input
                      className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["email"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                      id="email"
                      name="email"
                      placeholder="johnmethu@gmail.com"
                      type="text"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin["email"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["email"]}
                      </div>
                    ) : null}
                  </div>

                  <div data-aos="fade-up" className="space-y-2">
                    <label
                      htmlFor="phone"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Phone
                    </label>
                    <input
                      className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["phone"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                      id="phone"
                      name="phone"
                      placeholder="+91 "
                      type="text"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin["phone"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["phone"]}
                      </div>
                    ) : null}
                  </div>

                  <div data-aos="fade-up" className="space-y-2">
                    <label
                      htmlFor="job"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Job Title
                    </label>
                    <select
                      onChange={(e) => this.inputChange2(e, "jobtitle")}
                      id="job"
                      className={`bg-white border border-gray-300 text-[#3D405B] text-base font-base rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4 ${!this.state.errorslogin["postalcode"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}>
                      <option
                        selected="selected"
                        value={this.state.fieldslogin && this.state.fieldslogin["jobtitle"] ? this.state.fieldslogin["jobtitle"] : "select"}
                        className="text-[#3D405B]"
                      >
                        Select One
                      </option>
                      <option value="Executive">Executive</option>
                      <option value="Vice President">Vice President</option>
                      <option value="Director">Director</option>
                      <option value="Senior Manager">Senior Manager</option>
                      <option value="Manager">Manager</option>
                      <option value="Staff">Staff</option>
                      <option value="President/Superintendent">
                        President/Superintendent
                      </option>
                      <option value="Senior Administrator">
                        Senior Administrator
                      </option>
                      <option value="Faculty/Staff/Educator">
                        Faculty/Staff/Educator
                      </option>
                      <option value="Student">Student</option>
                      <option value="Other">Other</option>
                    </select>
                    {this.state.errorslogin["jobtitle"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["jobtitle"]}
                      </div>
                    ) : null}
                  </div>

                  <div data-aos="fade-up" className="space-y-2">
                    <label
                      htmlFor="company"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Company/Organization
                    </label>
                    <input
                      className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["company"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                      id="company"
                      name="company"
                      placeholder=""
                      type="text"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin["company"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["company"]}
                      </div>
                    ) : null}
                  </div>

                  <div data-aos="fade-up" className="space-y-2">
                    <label
                      htmlFor="countries"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Country Or Region
                    </label>
                    <select
                      id="countries"
                      onChange={(e) => this.inputChange2(e, "country")}
                      className={`bg-white border border-gray-300 text-[#3D405B] text-base font-base rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4 ${!this.state.errorslogin["postalcode"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                    >
                      <option selected="selected" value="">
                        Select One
                      </option>
                      <option value="Executive">India</option>
                      <option value="Vice President">America</option>
                      <option value="Director">Afganistan</option>
                      <option value="Director">ShreeLanka</option>
                    </select>
                    {this.state.errorslogin["country"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["country"]}
                      </div>
                    ) : null}
                  </div>

                  <div data-aos="fade-up" className="space-y-2 ">
                    <label
                      htmlFor="postalcode"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Postal Code
                    </label>
                    <input
                      className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["postalcode"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                      id="postalcode"
                      name="postalcode"
                      placeholder=""
                      type="text"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin["postalcode"] ? (
                      <div className="pt-2 text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["postalcode"]}
                      </div>
                    ) : null}
                  </div>
                  <div data-aos="fade-up" className="space-y-2 ">
                    <label
                      htmlFor="message"
                      className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]  "
                    >
                      Message (If Any)
                    </label>
                    <textarea
                      rows={3}
                      className={`w-full rounded-md border resize-none border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["message"]
                        ? "placeholder-gray-500"
                        : "border border-opacity-100 border-red-500 "
                        }`}
                      id="message"
                      name="message"
                      placeholder=""
                      type="text"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin["message"] ? (
                      <div className="text-xs text-red-600 invalid-feedback">
                        {this.state.errorslogin["message"]}
                      </div>
                    ) : null}
                  </div>

                  {/* <form
                    className=""
                    action="https://api.form-data.com/f/bqtxth46icif4r4ot1p5qn"
                    method="post"
                  >
                    <div
                      className="g-recaptcha"
                      data-sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"
                    ></div>
                  </form> */}

                  <button
                    data-aos="fade-up"
                    type="button"
                    onClick={this.loginSubmit}
                    className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150"
                  >
                    Send Message
                  </button>



                  {this.state.saveSuccess ?
                    <button
                      type="button"

                      className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <path d="M9 12l2 2l4 -4" />
                      </svg>
                      <span> Your query has been successfully sent!</span>
                    </button>
                    : null}

                </div>
              </div>
            </div>
          </section>


          <Ready />

          <Footer
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
        </div>
      </>
    );
  }
}

ContactUs.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(ContactUs);
