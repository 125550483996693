import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import Footer from "./Footer";
import { MdOutlineClose } from "react-icons/md";
import CartHeader from "./CartHeader";

class MyCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
    };
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartItems());
    this.props.dispatch(assessmentActions.getAssessmentCartPriceTotal());
    this.props.dispatch(assessmentActions.getAssessmentPackagesList());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentMyCartSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  changeQuantity = (cart, type) => {
    let data = {
      id: cart.id,
      quantity: Number(cart.quantity),
    };
    if (type === "add") {
      data["quantity"] += 1;
    } else if (type === "sub" && data["quantity"] > 1) {
      data["quantity"] -= 1;
    }
    this.props.dispatch(assessmentActions.updateCartItemQuantity(data));
  };

  handleDeleteCartItem = (cart) => {
    let data = {
      id: cart.id,
    };
    this.props.dispatch(assessmentActions.deleteCartItem(data));
  };
  gotoPage = (feild) => {
    this.props.history.push(`/assessment/` + feild);
  };

  handleAddToCart = (item) => {
    let dataV2 = {
      productId: item.id,
      quantity: 1,
      licensee: "team",
    };
    if (dataV2 && dataV2["productId"]) {
      let data = {
        quantity: dataV2["quantity"],
        productId: dataV2["productId"],
        licensee: dataV2["licensee"],
      };
      this.props.dispatch(
        assessmentActions.assessmentAddToCart(data, this.props, 2)
      );
    }
  };

  gotoPageProductDetails = (cart) => {
    let productId =
      cart && cart.productId && cart.productId.id ? cart.productId.id : "";
    let licensee = cart && cart.licensee ? cart.licensee : "";

    if (licensee && productId) {
      this.props.history.push(
        `/assessment/productdetails/` + licensee + "/" + productId
      );
    }
  };

  handleRedirect = () => {
    this.props.history.push('/assessment/contact-us');
  }

  handleCheckout = () => {
    this.props.dispatch(
      assessmentActions.userAssessmentPackageOrderPlace({}, this.props)
    );
  }

  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartItemsData,
      getAssessmentCartPriceTotalData,
      getAssessmentPackagesListData,
    } = assessment;

    function getDetails(cart, field, type) {
      if (type === 2) {
        return field && cart && cart[field] ? cart[field] : "NA";
      } else {
        return field && cart && cart["productId"] && cart["productId"][field]
          ? cart["productId"][field]
          : "NA";
      }
    }

    console.log(
      "getAssessmentCartPriceTotalData---->",
      getAssessmentCartPriceTotalData
    );
    console.log("getAssessmentCartItemsData---->", getAssessmentCartItemsData);

    function getAmount(field) {
      return field &&
        getAssessmentCartPriceTotalData &&
        getAssessmentCartPriceTotalData[field]
        ? getAssessmentCartPriceTotalData[field]
        : "0";
    }
    function roundOff2(item) {
      return item ? item.toFixed(2) : 0;
    }
    function getTotalPrice(price, quantity) {
      return roundOff2(Number(price) * Number(quantity));
    }

    function validProducts(productId) {
      let check = true
      if (getAssessmentCartItemsData && getAssessmentCartItemsData.length > 0) {
        let list = getAssessmentCartItemsData.map((item) => (item && item.productId && item.productId.id ? item.productId.id : ""));
        console.log("list--->", list)
        console.log("productId--->", productId)
        if (list.includes(productId)) {
          check = false
        }
      }
      console.log("check--->", check)
      return check
    }



    let customerAlsoBrought = []
    if (getAssessmentPackagesListData && getAssessmentPackagesListData.length > 0) {
      getAssessmentPackagesListData.forEach(element => {
        if (validProducts(element.id)) {
          customerAlsoBrought.push(element)
        }
      });
    }


    return (
      <div className="">
        {/* <Header 
         
        /> */}

        <CartHeader
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          stage={1}
        />

        {getAssessmentCartItemsData && getAssessmentCartItemsData.length > 0 ? (
          <>
            <section className="flex flex-col w-full gap-10 px-4 mx-auto mb-10 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
              <div className="space-y-4 lg:w-[65%] w-[100%] lg:py-20 py-10">
                <div className="flex flex-col-reverse justify-between gap-4 pb-6 border-b md:flex-row">
                  <div className="flex items-center space-x-4">
                    <h1 className="md:text-3xl text-2xl font-medium text-[#3D405B]">
                      My Cart
                    </h1>{" "}
                    <span className="md:text-base text-sm text-[#3D405B]/70">
                      (
                      {getAssessmentCartItemsData &&
                        getAssessmentCartItemsData.length > 0
                        ? getAssessmentCartItemsData.length
                        : 0}{" "}
                      items)
                    </span>
                  </div>
                  <div
                    className="flex  text-[#1976D2] cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/assessment/` + "products")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-chevron-left"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M15 6l-6 6l6 6"></path>
                    </svg>
                    <p className=" pb-0.5 border-b text-[#1976D2] border-[#1976D2]">
                      <p>Back To Products</p>
                    </p>
                  </div>
                </div>

                <div className="divide-y ">
                  {getAssessmentCartItemsData &&
                    getAssessmentCartItemsData.length > 0
                    ? getAssessmentCartItemsData.map((cart) => (
                      <>
                        <div className="flex flex-row items-start gap-4 py-6 md:gap-6 md:items-start md:py-8">
                          <div className="flex items-start w-20 h-20 cursor-pointer md:w-40 md:h-40">
                            <img
                              src="/img/assessment/Rectangle.png"
                              className="w-20 h-20 md:w-40 md:h-40 overflow-cover"
                            />{" "}
                          </div>
                          <div className="flex-1 w-full space-y-4 md:space-y-6 ">
                            <div className="text-[#3D405B] flex  2xl:flex-row flex-col md:gap-4 gap-2 justify-between w-full ">
                              <h1 className="text-lg 2xl:text-2xl md:text-xl">
                                {getDetails(cart, "name")}
                              </h1>
                              <h1 className="text-lg font-semibold 2xl:text-2xl md:text-xl">
                                <span className="font-[roboto]"> ₹</span>
                                {getTotalPrice(
                                  getDetails(cart, "price"),
                                  getDetails(cart, "quantity", 2)
                                )}
                              </h1>
                            </div>

                            <div className="flex flex-row items-center gap-4">
                              <span className="font-medium ">Qty:</span>
                              <div className="flex items-stretch overflow-hidden border rounded-md">
                                {cart.licensee === "team" ? (
                                  <button
                                    onClick={() =>
                                      this.changeQuantity(cart, "sub")
                                    }
                                    className="p-1.5 px-4 bg-gray-100 cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-plus"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                  </button>
                                ) : null}

                                <span className="p-1.5 md:w-20 w-12 text-center font-semibold md:text-lg text-base ">
                                  {getDetails(cart, "quantity", 2)}
                                </span>

                                {cart.licensee === "team" ? (
                                  <button
                                    onClick={() =>
                                      this.changeQuantity(cart, "add")
                                    }
                                    className="p-1.5 px-4 bg-gray-100 cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-plus"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <line x1="12" y1="5" x2="12" y2="19" />
                                      <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                  </button>
                                ) : null}
                              </div>
                            </div>

                            <div className="flex items-center space-x-4 capitalize md:space-x-10">
                              <span
                                onClick={() =>
                                  this.gotoPageProductDetails(cart)
                                }
                                className="md:text-base text-sm text-[#3D405B] font-medium  underline cursor-pointer hover:text-[#2196F3] transition-all duration-150"
                              >
                                View items
                              </span>
                              <span
                                onClick={() =>
                                  this.handleDeleteCartItem(cart)
                                }
                                className="md:text-base text-sm text-[#3D405B] font-medium  underline cursor-pointer hover:text-[#2196F3] transition-all duration-150"
                              >
                                Remove items
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                    : null}

                  {false ?
                    <div class="max-w-4xl mx-auto space-y-4">
                      <h2 class="text-2xl font-semibold pt-4">Add On</h2>
                      <div class="flex flex-row md:items-center items-start bg-[#F9FAFB] border md:p-5 p-2">
                        <img src="/img/assessment/Rectangle.png" alt="Product Image" class="w-24 h-24 sm:w-32 sm:h-32" />
                        <div class="flex-1 ml-4 sm:mt-0">
                          <h2 class="text-lg font-medium">TalentPlusStrengths Summary</h2>
                          <p class="text-[#3D405B] mt-2">Talent+ Strengths 21 is the best way for you to understand your talents and maximize your potential... <a href="#" class="text-[#2196F3]">Know More</a></p>
                          <p class="mt-2 text-xl font-semibold"><span className="font-[roboto]">₹</span> 4,500</p>
                        </div>
                        <button class="text-gray-500 hover:text-red-600 sm:mt-0 ml-4">
                          <MdOutlineClose className="w-8 h-8 bg-[#E8E8E8] p-1" />
                        </button>
                      </div>

                      <div class="flex flex-row md:items-center items-start bg-[#F9FAFB] border md:p-5 p-2">
                        <img src="/img/assessment/Rectangle.png" alt="Product Image" class="w-24 h-24 sm:w-32 sm:h-32" />
                        <div className="flex md:flex-row md:items-center flex-col">
                          <div class="flex flex-col ml-4 sm:mt-0">
                            <h2 class="text-lg font-medium">TalentPlusStrengths Summary</h2>
                            <p class="text-[#3D405B] mt-2">Talent+ Strengths 21 is the best way for you to understand your talents and maximize your potential... <a href="#" class="text-[#2196F3]">Know More</a></p>
                            <p class="mt-2 text-xl font-semibold"><span className="font-[roboto]">₹</span> 4,500</p>
                          </div>
                          <button class="bg-white hover:text-[#2196F3] text-gray-700 whitespace-nowrap h-11 border font-semibold px-4 py-2 rounded-sm mt-4 sm:mt-0 ml-4">Remove Item</button>
                        </div>
                      </div>

                      <div class="flex flex-row md:items-center items-start bg-white border md:p-5 p-2">
                        <img src="/img/assessment/Rectangle.png" alt="Product Image" class="w-24 h-24 sm:w-32 sm:h-32" />
                        <div className="flex md:flex-row md:items-center flex-col">
                          <div class="ml-4 sm:mt-0">
                            <h2 class="text-lg font-medium">TalentPlusStrengths Summary</h2>
                            <p class="text-[#3D405B] mt-2">Talent+ Strengths 21 is the best way for you to understand your talents and maximize your potential... <a href="#" class="text-[#2196F3]">Know More</a></p>
                            <p class="mt-2 text-xl font-semibold"><span className="font-[roboto]">₹</span> 4,500</p>
                          </div>
                          <button class="bg-[#2196F3] text-white whitespace-nowrap h-11 font-semibold hover:bg-[#2196F3]/90 px-8 py-2 rounded-sm mt-4 sm:mt-0 ml-4">Add Item</button>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }

                </div>

                {/*  <div className="flex flex-row items-start gap-4 py-6 md:gap-6 md:items-center md:py-8 ">
                <div className="flex items-center w-20 h-20 cursor-pointer md:w-56 md:h-56">
                  <img
                    src="/img/assessment/Rectangle.png"
                    className="w-20 h-20 md:w-56 md:h-56 overflow-cover"
                  />{" "}
                </div>
                <div className="flex-1 w-full space-y-4 md:space-y-6 ">
                  <div className="text-[#3D405B] flex  2xl:flex-row flex-col md:gap-4 gap-2 justify-between w-full ">
                    <h1 className="text-lg 2xl:text-3xl md:text-2xl ">
                      Top 5 TalentPlusStrengths
                    </h1>
                    <h1 className="text-lg font-semibold 2xl:text-3xl md:text-2xl ">
                      ₹49.99
                    </h1>
                  </div>
                  <div className="flex flex-row items-center gap-4">
                    <span className="font-medium ">Qty:</span>
                    <div className="flex items-stretch overflow-hidden border rounded-md">
                      <button className="p-1.5 px-4 bg-gray-100 cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-plus"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                      </button>
                      <span className="p-1.5 md:w-20 w-12 text-center font-semibold md:text-lg text-base ">
                        1
                      </span>
                      <button className="p-1.5 px-4 bg-gray-100 cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-plus"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="12" y1="5" x2="12" y2="19" />
                          <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4 capitalize md:space-x-10">
                    <span className="md:text-base text-sm text-[#3D405B] font-medium  underline cursor-pointer hover:text-[#2196F3] transition-all duration-150">
                      View items
                    </span>
                    <span className="md:text-base text-sm text-[#3D405B] font-medium  underline cursor-pointer hover:text-[#2196F3] transition-all duration-150">
                      Remove items
                    </span>
                  </div>
                </div>
              </div> */}


                <div className="bg-[#F9FAFB] border p-8 flex md:flex-row flex-col justify-between md:items-center items-start text-[#3D405B]">
                  <div className="space-y-4 ">
                    <h1 className="text-2xl font-semibold">Need Help?</h1>
                    <p className="text-lg">Mail: connect@talentplus.in</p>
                  </div>
                  <button onClick={this.handleRedirect} className="px-6 py-2 md:mt-0 mt-4 bg-[#2196F3] text-white hover:bg-[#2196F3]/90 ">
                    Contact Us{" "}
                  </button>
                </div>
              </div>
              {/* text */}
              <div className="lg:w-[35%] w-full md:p-8 lg:py-20 py-0 text-[#3D405B] space-y-6  lg:border-l">
                <div className="flex items-center justify-center p-4 space-x-4 bg-gray-100">
                  <h1 className="2xl:text-2xl xl:text-xl  text-xl  text-[#3D405B]">
                    Order Summary
                  </h1>{" "}
                  {/* <span className="xl:text-lg text-base text-[#3D405B]/70 ">
                    (
                    {getAssessmentCartItemsData &&
                      getAssessmentCartItemsData.length > 0
                      ? getAssessmentCartItemsData.length
                      : 0}{" "}
                    items)
                  </span> */}
                </div>

                <div className="space-y-8 text-lg md:text-xl ">
                  <div className="flex items-center justify-between capitalize">
                    <p className="">Subtotal</p>
                    <p className="font-medium font-[roboto]">₹{getAmount("sum")}</p>
                  </div>
                  {/* <div className="flex items-center justify-between capitalize">
                    <p className="">Esitamted tax</p>
                    <p className="font-medium ">₹{getAmount("tax")}</p>
                  </div> */}
                  {/* <div className="flex items-center justify-between capitalize">
                    <p className="">Discount</p>
                    <p className="font-medium ">₹{getAmount("discount")}</p>
                  </div> */}
                  <div className="flex items-center justify-between py-6 capitalize border-t-2 border-dashed ">
                    <p className="font-bold ">Total Payable</p>
                    <p className="font-bold font-[roboto]">₹{getAmount("total")}</p>
                  </div>
                </div>

                <button
                  // onClick={() => this.gotoPage("order-review")}
                  onClick={() => this.handleCheckout()}
                  className="lg:relative fixed bottom-0 left-0 bg-[#4CAF50] text-[#fff] hover:bg-[#4CAF50]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                >
                  <span>Checkout</span>
                </button>
                <div className="flex items-start py-6 space-x-4 capitalize justify-left ">
                  <img
                    src="/img/assessment/security-checked.svg"
                    className="w-10 h-10"
                  />
                  <p className="text-base md:text-xl">
                    Safe and secure payment {" "}
                  </p>
                </div>
              </div>
            </section>
            {customerAlsoBrought && customerAlsoBrought.length > 0 ?
              <section className="w-full px-4 mx-auto mb-10 space-y-8 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
                <div className="flex items-center space-x-4  border-b  pb-6 w-full">
                  <h1 className="capitalize  text-xl font-semibold text-[#3D405B]">
                    Customer also bought
                  </h1>{" "}
                </div>

                <div className="grid grid-cols-1 gap-4 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
                  {customerAlsoBrought &&
                    customerAlsoBrought.length > 0
                    ? customerAlsoBrought.map((item) =>
                      true ? (
                        <div className="border rounded text-[#3D405B] flex flex-col justify-between h-full">
                          <div>
                            <img
                              src="/img/assessment/Rectangle.png"
                              className="w-full"
                            />
                            <div className="p-6 space-y-4 ">
                              <h1 className="text-lg font-medium">
                                {item.name}
                              </h1>
                              <p className="text-xl font-semibold">
                                <span className="font-[roboto]">₹</span>
                                {item.price}
                              </p>
                              <p className="line-clamp-4">{item.desc}</p>
                            </div>
                          </div>
                          <div className="p-6 pt-0">
                            <button
                              onClick={() => this.handleAddToCart(item)}
                              className="bg-[#2196F3] text-[#fff] hover:bg-[#2196F3]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-shopping-cart"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#fff"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <circle cx="6" cy="19" r="2" />
                                <circle cx="17" cy="19" r="2" />
                                <path d="M17 17h-11v-14h-2" />
                                <path d="M6 5l14 1l-1 7h-13" />
                              </svg>
                              <span>Add to Cart</span>
                            </button>
                          </div>
                        </div>
                      ) : null
                    )
                    : null}
                </div>
              </section>
              : null
            }

          </>
        ) : (
          <>
            <section className="flex items-center justify-center xl:h-[calc(100vh-12rem)] gap-10 px-4 overflow-hidden">
              <div className="flex flex-col items-center space-y-4 justify-center md:py-10 py-8 text-center">
                <img
                  src="/img/newicons/empty_cart.svg"
                  alt="emptycart.svg"
                  className="w-3/6 2xl:w-2/4"
                />
                <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">
                  Your Cart is Empty!
                </h2>
                <p className="text-gray-500 text-lg">
                  Looks like you haven't added any items to your cart yet.
                </p>
                <div className="mt-0 md:mt-8">
                  <button
                    onClick={() =>
                      this.props.history.push(`/assessment/` + "products")
                    }
                    className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-10 rounded uppercase mt-10"
                  >
                    shop Now
                  </button>
                </div>
              </div>
            </section>
          </>
        )}

        <Footer />
      </div>
    );
  }
}
MyCart.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(MyCart);
