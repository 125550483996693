import React from 'react';

export default function Component(props) {

const { question, answersList,SurveyAttributes, handleCheckInput, surveyScreenData, clickOK } = props;

return(
    <>
      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 md:px-6 px-4 sm:px-40 md:px-40 lg:px-30 xl:px-32 2xl:px-40">
        
        <div className="ml-10">
        {question && question.optionsList && question.optionsList.length>0?
          question.optionsList.map((option, optionindex) =>
            <div className="justify-center items-center" key={optionindex}  >
                <input type="checkbox" className="form-check" 
                checked={
                  (answersList && answersList.length > 0 && answersList
                    .findIndex(item => item.questionId === question.id) !== -1) ?
                    answersList[answersList.findIndex(item => item.questionId === question.id)].multiAnswer
                    .some(prev=>prev.optionId===option.id)?true:false:false
                } 
                onClick={() => handleCheckInput(optionindex, question, option)} 
                onChange={()=>{}} 
                style={{marginTop:'15px',fontSize:'25px'}}/>
              <h4 variant="h4" className="mx-2"  style={{marginLeft:'15px',display:'inline'}}  >
                {option.name}
              </h4>
            </div>
        ):null}
        </div>

      </div>

      {/* <div className="md:px-6 px-4 ">

        <button 
        style={{
          background:(surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 :  "#757575",
          color:(surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : "#757575",
        }} 
        onClick={() => clickOK()} className="mt-6 md:rounded-lg rounded-md p-2 px-4 mr-1 text-center text-xl">{SurveyAttributes("okButtonText")?SurveyAttributes("okButtonText"):"OK"}</button>

        </div> */}
    </>
  );}