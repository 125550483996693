import React from 'react';
import ReactEcharts from "echarts-for-react";

export default function Top5Content(props) {

  const { crrSelectedEmp, createdAt, listX, getDetails, sortX, staticList, title } = props;

  let colorList = [
    "#64B5F6",
    "#4DD0E1",
    "#7986CB",
    "#BA68C8",
    "#B388FF",
    "#4DB6AC",
    "#81C784",
    "#DCE775",
    "#FFB74D",
  ]


  let dataNew = []
  if (listX && listX.length > 0) {
    listX.forEach((ele, index) => {
      dataNew.push({
        children: [
          {
            name: getDetails(ele, "label"),
            itemStyle: {
              color: colorList[index]
            },
            children: [
              {
                name: getDetails(ele, "score").toString(),
                value: getDetails(ele, "score"),
                itemStyle: {
                  color: colorList[index]
                }
              }
            ]
          },
        ]
      })

      dataNew.sort((b, a) => sortX(a, "value") - sortX(b, "value"));


    })
  }
  console.log('listX--///--listX----------->', listX)
  console.log('list--///--list----------->', dataNew)


  function getValues(items, field) {
    let value = "";

    let name = items["children"][0]["name"];

    if (field === "name") {
      value = name;
    }
    else if (field === "image") {
      if (staticList && staticList.length > 0) {
        let getIndex = staticList.findIndex(prev => prev.title === name);
        if (getIndex !== -1) {
          value = staticList[getIndex]["imgSrc"];
        }
      }
    } else if (field === "desc") {
      if (listX && listX.length > 0) {
        let getIndex = listX.findIndex(prev => getDetails(prev, "label") === name)
        if (getIndex !== -1) {
          let ele2 = listX[getIndex];
          console.log('list--///--ele2----------->', ele2)
          value = getDetails(listX[getIndex], "desc")
        }
      }
    }
    return value

  }

  return (
    <>

      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium uppercase">
            <p className="text-3xl font-bold uppercase ">{title}</p>
          </div>
          <div className="space-y-4">
            <div className="space-y-1">
              {dataNew && dataNew.length > 0 ?
                dataNew.map((items, index) => (
                  (index < 3) ?
                    <div className="inline-flex items-center justify-start w-full bg-[#f5f5f5]">
                      <div className="flex-shrink-0  ">
                        <img alt={getValues(items, "name")} src={getValues(items, "image")} className="flex-shrink-0 object-cover h-44 w-44" />
                      </div>
                      <div className="px-6 py-4 ">
                        <p className="lg:text-lg text-base font-bold text-[#212121] uppercase">{getValues(items, "name")}</p>
                        <p className="grow shrink basis-0 leading-snug">{getValues(items, "desc")}</p>
                      </div>
                    </div>
                    : null
                )) : null}
            </div>
          </div>
        </div>
      </main>


    </>
  );
}

