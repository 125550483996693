import React from "react";
import moment from "moment";

export default function AssessmentScore(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, EditDetailsFn, EditButtonFn, createdAt } = props;


  const cirleColor = (element) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD")
      }}
      className={"w-5 h-5 rounded-full "}
    />)
  }

  const barWidthColor = (element) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD"),
      }}
      className={
        "w-5 h-5 rounded-full "
      }
    />)
  }

  const shortSummary = (element, index) => {
    //console.log("element--->",element)

    return (<div className="flex items-center px-2 space-x-4">
      {barWidthColor(element)}

      <div className="flex">
        <p className="text-base font-normal">
          <b className="pr-2">{(index + 1).toString() + ". "}
            {EditDetailsFn(element, 'dimension', element.dimension)}:
          </b>
          {/* {element &&
            element.details &&
            element.details.desc
              ? element.details.desc
              : ""} */}
          {EditDetailsFn(element, 'desc', element.details.desc)}
        </p>
      </div>
    </div>)
  }




  let textList1 = [
    "Read every word of the report. Reflect on it and understand your uniqueness. Think about a time when your talent was in use and how did it affect your performance. Keep making notes on the same.",
    "Focus on the Dominant themes as it has a huge influence on your behaviour. It not only allows you to achieve more but also creates your personal brand. People see your behaviour but you can now see from where these behaviour comes and thus help you create a authentic perception of yourself.",
    "You should also give attention to your non-dominant talent themes as it allows you to understand where you will need to partner with someone and what are some areas you might not be naturally gifted.",
    "Once you understand your inherent talent, try making a plan to use it proactively for your next task and see how it helped you being better in it.",
    "In Case you want more insights and clarity, feel free to write to us at contact@happyplus.in so that we can set up a coaching call with you to explain/clarify questions you have."
  ]

  let textList2 = [
    "Keep your Dominant talents in front of you & before you undertake any task, think of the ways you are going to use it.",
    "Share the report with your friends and close ones and ask them to reflect it with you.",
    "Share it with your manager to help them understand what makes you tick and help you leverage it much more.",
    "Share it with your colleagues so that they can leverage unique talents which you bring on table.",
  ]


  return (
    <>
      <main className="py-10 space-y-10 bg-white">

        <section className="flex flex-col items-center justify-between space-y-10 ">

          <div className="flex flex-col items-center space-y-8 text-center">
            <div className="flex justify-center pt-2">
              <img
                src="/img/logo/happypluslogo.png"
                className="w-auto "
              />
            </div>
            <div className="py-2 space-y-4 text-center">
              <h1 className="text-3xl text-[#3D405B] font-semibold ">
                {EditButtonFn('page_1_title_1', 'TALENTPLUSSTRENGTHS ASSESSMENT REPORT')}
              </h1>
              <h1 className="text-xl font-medium capitalize text-sky-500 ">
                {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}{" "} | {createdAt ? moment(createdAt).format("DD MMMM YYYY") : ""}
              </h1>
            </div>
            <div className="flex justify-center pt-6">
              <img src="/img/talentplus_assessment_report.png" className="w-full mx-auto" />
            </div>
            <div className="text-center">
              <p className="text-base text-[#3D405B] font-medium">
                {EditButtonFn('page_1_footer_text_1', 'TalentPlusStrengths  Online Assessments © 2023')}
              </p>
              <p className="text-sm text-[#3D405B]/70">
                {EditButtonFn('page_1_confidential_text_1', 'This report is confidential and should not be distributed without permission')}
              </p>
            </div>
          </div>


        </section>

      </main>



      <main className="p-10 px-20 space-y-10 bg-white">

        <div className="space-y-6 page-break ">
          <div className="flex items-center justify-between w-full py-4 border-b">
            <h1 className="text-3xl font-medium">{EditButtonFn('TalentAssessmentReport', 'TalentPlusStrengths  Assessment Report')}
              {/* Talent Assessment Report */}
            </h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>


          <div className="space-y-4">
            <h1 className="text-3xl font-medium text-slate-900">
              {/* Your TalentPlusStrengths  Assessment Report  */}
              {EditButtonFn('page_2_title_2', 'Your TalentPlusStrengths  Assessment Report')}

            </h1>


            <p>
              {EditButtonFn('page_2_desc_1', 'Your talents are the essence of who you are, shaping how you think, feel, and act. Understanding and leveraging these talents are key to success. They enable you to find your flow, excel at work, and stand out authentically. Embracing your uniqueness leads to fulfillment and happiness, allowing you to be your true self. ')}

              {/* Talents are our natural ways of thinking, feeling, and behaving. They define how we do what we do. Talents are core to our being. Successful people know their talents and are able to utilise it beautifully for the work they do and success they achieve. Knowing your talents allows you to experience flow at work and produces superior performance. Your talents make you unique and allows you to stand out amongst others. It also makes you authentic and allows you to lead a fulfilling and happy life by being who you are and not trying to be someone else.  */}
              <br /><br />
              {/* Based on your response to the TalentPlusStrengths  assessment, this report has been created for you. Idea is that you go through the result, reflect on the same and be aware of your uniqueness. Once aware, you can start utilising it proactively to add appropriate skills & knowledge to do anything you want to do in life. It also will help you understand how do you think, relate, act and lead in your life. All these four buckets are the basic structure of making you successful.  */}
              {EditButtonFn('page_2_desc_2', 'Based on your response to the TalentPlusStrengths  assessment, this report has been created for you. Idea is that you go through the result, reflect on the same and be aware of your uniqueness. Once aware, you can start utilising it proactively to add appropriate skills & knowledge to do anything you want to do in life. It also will help you understand how do you think, relate, act and lead in your life. All these four buckets are the basic structure of making you successful.')}

            </p>



            <div className="w-full space-y-1 page-break">
              <h1 className="text-3xl font-light text-[#3D405B]">
                {/* <b>{"How to go through this report:"}</b> */}
                <b>{EditButtonFn('page_2_title_3', "How to go through this report:")}</b>

              </h1>
            </div>

            <div className="space-y-4 text-base">
              {textList1 && textList1.length > 0 ?
                textList1.map((text, index6) => (
                  <>
                    <div className="flex">
                      <img
                        src={"/img/icon/dot.png"}
                        className="w-2 h-2 mt-2 bg-gray-900 rounded-full"
                      />
                      <p className="mx-2">
                        {/* {text} */}
                        {EditButtonFn('textList1' + (index6 + 1).toString(), text)}

                      </p>
                    </div>
                  </>
                ))
                : null}
            </div>

            <div className="w-full space-y-1 page-break">
              <h1 className="text-3xl font-light text-[#3D405B]">
                {/* <b>{"How to use this report:"}</b> */}
                <b>{EditButtonFn('page_2_title_4', "How to use this report:")}</b>

              </h1>
            </div>

            <div className="space-y-4 text-base">
              {textList2 && textList2.length > 0 ?
                textList2.map((text, index6) => (
                  <>
                    <div className="flex">
                      <img
                        src={"/img/icon/dot.png"}
                        className="w-2 h-2 mt-2 bg-gray-900 rounded-full"
                      />
                      <p className="mx-2">
                        {/* {text} */}
                        {EditButtonFn('textList2' + (index6 + 1).toString(), text)}

                      </p>
                    </div>
                  </>
                ))
                : null}
            </div>


          </div>
        </div>

      </main>



      <main className="p-10 px-20 space-y-10 bg-white page-break">
        <div className="space-y-6 page-break ">
          <div className="flex items-center justify-between w-full py-4 border-b">
            <h1 className="text-3xl font-medium">{EditButtonFn('TalentAssessmentReport', 'TalentPlusStrengths  Assessment Report')}</h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

          <div className="w-full space-y-1 page-break">
            <h1 className="text-3xl font-light text-[#3D405B]">
              {/* <b>{"4 Domains of TalentPlusStrengths themes: "}</b> */}
              <b>{EditButtonFn('domain_4_text', "Domains of TalentPlusStrengths themes: ")}</b>

            </h1>
          </div>

          <div className="w-full bg-[#F5F5F5] p-4 space-y-4">
            {/*             
            <h1 className="text-lg">
              {"4 Domains of TalentPlusStrengths themes:"}
            </h1> */}

            <div className="flex items-start w-full space-x-2 space-y-2">
              <p className="text-md">
                <b className="w-4 h-4 bg-red-500" />

                <span style={{ background: "#FFC107" }} className={"w-5 h-5 rounded-full "} />
                <div className="flex mr-2 text-base font-bold">
                  {EditButtonFn('THINKINGTALENTS', "THINKING TALENTS")} <p className="ml-3" >{EditButtonFn('HighlightedYellow', "(Highlighted in colour Yellow in the Report)")}</p>
                </div>
                <span className="">{EditButtonFn('THINKINGDESC', 'These are the talents which defines your way of thinking and making a sense of the world. Using these talents, you deliberate on ideas, possibilities, and next course of action. This theme deals with intellectual abilities which is the hallmark of human progress. Thinking themes allows us to explore our past and gaze into future so that we can plan things in present. Thinking talents are the strategic pathway to our future.')}
                </span>
              </p>
            </div>
            <br />
            <div className="flex items-start w-full space-x-2 space-y-2">
              <p className="text-md">
                <b className="w-4 h-4 bg-red-500" />

                <span style={{ background: "#4CAF50" }} className={"w-5 h-5 rounded-full "} />
                <div className="flex mr-2 text-base font-bold">
                  {EditButtonFn('RELATINGTALENTS', "RELATING TALENTS")}<p className="ml-3" style={{ color: "#4CAF50" }}>{EditButtonFn('HighlightedGreen', "(Highlighted in colour Green in the Report")}</p>
                </div>
                <span className="">{EditButtonFn('RELATINGDESC', 'Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life. It’s the bond between one person and another, which takes us higher and ensures, we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together.')}
                </span>
              </p>
            </div>
            <br />
            <div className="flex items-start w-full space-x-2 space-y-2">
              <p className="text-md">
                <b className="w-4 h-4 bg-red-500" />

                <span style={{ background: "#2196F3" }} className={"w-5 h-5 rounded-full "} />
                <div className="flex mr-2 text-base font-bold">
                  {EditButtonFn('ACTINGTALENTS', "ACTING TALENTS")}<p className="ml-3" style={{ color: "#2196F3" }}>{EditButtonFn('HighlightedBlue', "(Highlighted in colour Blue in the Report)")}</p>
                </div>
                <span className="">{EditButtonFn('ACTINGDESC', 'These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen. It is these themes which makes things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently.')}
                </span>
              </p>
            </div>
            <br />
            <div className="flex items-start w-full space-x-2 space-y-2">
              <p className="text-md">
                <b className="w-4 h-4 bg-red-500" />

                <span style={{ background: "#673AB7" }} className={"w-5 h-5 rounded-full "} />
                <div className="flex mr-2 text-base font-bold">
                  {EditButtonFn('LEADINGTALENTS', "LEADING TALENTS")}<p className="ml-3" style={{ color: "#673AB7" }}>{EditButtonFn('HighlightedPurple', "(Highlighted in the colour Purple in the Report)")}</p>
                </div>
                <span className="">{EditButtonFn('LEADINGDESC', 'These are the talents, which defines our leadership. Leadership is not a position but it is a mindset, through which people share their vision with others, get them aligned and motivated and ensure work takes place towards that desired future. Leaders are the people responsible for a better tomorrow, so they not only have to keep looking at the things to come but also make that dream a reality with the help of others.')}
                </span>
              </p>
            </div>


          </div>

        </div>
      </main>



      <main className="p-10 px-20 space-y-10 bg-white page-break">
        <div className="space-y-6 page-break ">
          <div className="flex items-center justify-between w-full py-4 border-b">
            <h1 className="text-3xl font-medium">{EditButtonFn('TalentAssessmentReport', 'TalentPlusStrengths  Assessment Report')}</h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>


          <div className="flex items-center justify-between">
            <div className="flex p-2 space-x-2 border rounded-full">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  index + 1 <= 5 ? cirleColor(element) : null
                )
                : null}
            </div>

            <div className="flex p-2 space-x-2 border rounded-full ">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  6 <= index + 1 && index + 1 <= 16 ? cirleColor(element) : null
                )
                : null}
            </div>

            <div className="flex p-2 space-x-2 border rounded-full ">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  17 <= index + 1 ? cirleColor(element) : null
                )
                : null}
            </div>
          </div>





          <div className="flex space-x-6">
            <div className="w-full divide-y divide-dashed ">

              <div className="py-2 text-base font-normal">
                <h1 className="font-medium text-md text-slate-900">
                  {EditButtonFn('YourTop5TalentPlustheme', "Your Dominant TalentPlusStrengths  themes (Broadly they define who you are what makes you successful, they need to be understood, nurtured and used regularly to experience a thriving life)")}
                </h1>
              </div>
              <div className="flex w-full py-4">
                {/* <img src={"/img/assessment/top5.png"} className="w-10" /> */}
                <div className="w-full space-y-2">
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      index + 1 <= 5 ? (
                        <>
                          {shortSummary(element, index)}
                        </>
                      ) : null
                    )
                    : null}
                </div>
              </div>


              <div className="py-2 text-base font-normal">
                <h1 className="font-medium text-md text-slate-900">
                  {EditButtonFn('YourNext11TalentPlustheme', "Your Moderate TalentPlusStrengths  Themes (This defines, talents which are there and can be drawn when required. Once you have mastered the moderate talents, these talents too can be developed further)")}
                </h1>
              </div>
              <div className="flex w-full py-4">
                {/* <div className="flex flex-col items-center justify-center w-10 bg-slate-100">
                <p className="text-base" style={{ writingMode: "vertical-lr" }}>
                  Mid
                </p>
              </div> */}
                {/* <img src={"/img/assessment/middle.png"} className="w-10" /> */}
                <div className="w-full space-y-2">
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      6 <= index + 1 && index + 1 <= 16 ? (
                        <>
                          {shortSummary(element, index)}
                        </>
                      ) : null
                    )
                    : null}
                </div>
              </div>


              <div className="py-2 text-base font-normal">
                <h1 className="font-medium text-md text-slate-900">
                  {EditButtonFn('YourLast5TalentPlustheme', "Your Non-Dominant TalentPlusStrengths  Themes (These are the talents, which are there but not much & you may need to learn how to manage it to achieve your goals in life)")}
                </h1>
              </div>
              <div className="flex w-full py-4">
                {/* <div className="flex flex-col items-center justify-center w-10 bg-slate-100">
                <p className="text-base" style={{ writingMode: "vertical-lr" }}>
                  Bottom 5
                </p>
              </div> */}
                {/* <img src={"/img/assessment/bottom5.png"} className="w-10" /> */}
                <div className="w-full space-y-2">
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      17 <= index + 1 ? (
                        <>
                          {shortSummary(element, index)}
                        </>
                      ) : null
                    )
                    : null}
                </div>
              </div>

            </div>

          </div>

        </div>
        {/* <p>Copyright © 2023, HappyPlus, Inc. All rights reserved</p> */}
      </main>
    </>
  );
}
