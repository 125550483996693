import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { Helmet } from "react-helmet";


class TeenCareerGuidance extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      saveSuccess: false,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,

      fieldslogin: {},
      errorslogin: {},

      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(assessmentActions.userAssessmentUserProfile('getAssessmentCartCount'));
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  handleTabClick = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };


  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleDownload = () => {
    let code = "TalentPlusStrengths21";
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    link.click();
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/how-talentplus-strength-works");
    window.scrollTo(0, 0);
  };

  closeOrgTypeModal = () => {
    this.setState({ showOrgTypeModal: false })
  }

  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["type"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;
    if (formData["type"] === "Individual") {
      this.props.history.push(`/assessment/` + "surveylogin");
    } else if (formData["type"] === "Organization") {
      window.location.href = 'https://plusinsight.happyplus.in/surveylink/#/assessment/signin';
    }
  };

  handleTakeAssessmentRedirect = () => {
    this.setState({ showOrgTypeModal: false }, () => {
      this.handleOptionSubmit();
    })
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  loginSubmit = () => {
    console.log(
      "this.handleValidationLogin()---->",
      this.handleValidationLogin()
    );

    if (this.handleValidationLogin()) {
      let { userName, email, phone, message } =
        this.state.fieldslogin;
      this.setState({ saveSuccess: true });
      this.props.dispatch(
        assessmentActions.saveAssessmentContactUs(
          {
            name: userName,
            email: email,
            phone: phone,
            message: message,
          },
          this.props
        )
      );
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  handleValidationLogin = () => {
    let { fieldslogin } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    //userName
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid += 1;
      console.log("1 userName !");
      errorslogin["userName"] = "Full name is required";
    }
    //email
    if (!fieldslogin["email"]) {
      console.log("1 email !");

      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(fieldslogin["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }

    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };

  scrollToElement = () => {
    if (this.myRef.current) {
      const offset = 180; // Adjust the offset value as needed
      const elementPosition = this.myRef.current.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  };

  handleDownload = () => {
    const url = '/TeenCareerGuideline.pdf'; // Replace with your actual PDF URL
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'TeenCareerGuideline.pdf'); // You can specify a default file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {

    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    // console.log("quantity-->", quantity);

    const workValue = [
      {
        id: 1,
        title: 'Support',
        dics: `Reflects the level of mentorship, guidance, and assistance teens value in a work environment.`,
        icon: 'assessment_images/support.webp',
      },
      {
        id: 2,
        title: 'Relationships',
        dics: `Represents the importance of teamwork, camaraderie, and positive interactions in the workplace.`,
        icon: 'assessment_images/relationship.png ',
      },
      {
        id: 3,
        title: 'Autonomy',
        dics: `The degree of independence and flexibility teens desire in their job roles to make their decisions and take ownership of their work.`,
        icon: 'assessment_images/autonomy.webp',
      },
      {
        id: 4,
        title: 'Recognition',
        dics: `Captures the value placed on acknowledgment and appreciation for accomplishments at work.`,
        icon: 'assessment_images/recognition.webp',
      },
      {
        id: 5,
        title: 'Work Life Balance',
        dics: `Signifies the importance of maintaining a healthy balance between work and personal life.`,
        icon: 'assessment_images/work-life-balance.webp',
      },
      {
        id: 6,
        title: 'Compensation And Benefits',
        dics: `Reflects the emphasis on competitive pay and comprehensive benefits packages.`,
        icon: 'assessment_images/compensation-and-benefits.webp',
      },
      {
        id: 7,
        title: 'Security',
        dics: `Relates to the preference for job stability and long-term employment prospects.`,
        icon: 'assessment_images/security.webp',
      },
      {
        id: 8,
        title: 'Creativity And Innovation',
        dics: `Represents the value of creativity, innovation, and new ideas in a career.`,
        icon: 'assessment_images/creativity-and-innovation.webp',
      },
      {
        id: 9,
        title: 'Contribution And Impact',
        dics: `Indicates the importance of work that makes a positive societal impact.`,
        icon: 'assessment_images/contribution-and-impact.webp',
      },
      {
        id: 10,
        title: 'Work Environment',
        dics: `Refers to the desired workplace atmosphere, including diversity, inclusivity, and organizational culture.`,
        icon: 'assessment_images/work-environment.webp',
      },
    ]

    const hollandValue = [
      {
        label: "R - Realistic",
        title: "Realistic",
        description: "Realistic types prefer hands-on activities that often involve physical work, machinery, or working outdoors. They are practical, physically active, and enjoy building or repairing things.",
        imgSrc: "./assessment_images/realistic.webp"
      },
      {
        label: "I - Investigative",
        title: "Investigative",
        description: "Investigative types enjoy working with ideas and solving complex problems. They often involve research, analysis, and understanding systems. They are curious, analytical, and like to work independently.",
        imgSrc: "/assessment_images/Investigative.webp"
      },
      {
        label: "A - Artistic",
        title: "Artistic",
        description: "Artistic types are creative and prefer work that allows for self-expression and originality. They enjoy creating visual or performing arts and generally value aesthetics and innovation.",
        imgSrc: "/assessment_images/Artistic.webp"
      },
      {
        label: "S - Social",
        title: "Social",
        description: "Social types are people-oriented and enjoy helping, teaching, or providing services to others. They have strong communication skills and often work in collaborative environments.",
        imgSrc: "/assessment_images/Social.webp"
      },
      {
        label: "E - Enterprising",
        title: "Enterprising",
        description: "Enterprising types enjoy leadership, influencing others, and taking risks. They are often drawn to business, management, and roles that involve persuasion and negotiation.",
        imgSrc: "/assessment_images/Enterprising.webp"
      },
      {
        label: "C - Conventional",
        title: "Conventional",
        description: "Conventional types prefer structured, organized, and detail-oriented work. They enjoy following established procedures and often work with data, administration, or clerical tasks.",
        imgSrc: "/assessment_images/Conventional.webp"
      }
    ];

    const numericalTest = [
      {
        imgSrc: "./assessment_images/logical-reasoning.webp",
        title: "Logical Reasoning",
        description: "Logical reasoning tests assess a teen's ability to think critically and solve problems, essential for careers in fields like science, technology, and law."
      },
      {
        imgSrc: "./assessment_images/numerical-ability.webp",
        title: "Numerical Ability",
        description: "Numerical ability tests measure a teen's proficiency with numbers and mathematical concepts, crucial for careers in finance, engineering, and data analysis."
      },
      {
        imgSrc: "./assessment_images/verbal-aptitude.webp",
        title: "Verbal Aptitude",
        description: "Verbal aptitude tests assess a teen's language skills, including vocabulary, grammar, and comprehension, important for careers in journalism, law, and communication."
      },
      {
        imgSrc: "./assessment_images/spatial-reasoning.webp",
        title: "Spatial Reasoning",
        description: "Spatial reasoning tests measure a teen's ability to visualize and manipulate objects, key for careers in architecture, design, and engineering."
      },
      {
        imgSrc: "./assessment_images/mechanical-reasoning.webp",
        title: "Mechanical Reasoning ",
        description: "Mechanical reasoning tests evaluate a teen's understanding of physical and mechanical principles, important for careers in engineering, mechanics, and technical trades."
      },
      {
        imgSrc: "./assessment_images/interpersonal-and-communication-skills.webp",
        title: "Interpersonal and Communication Skills",
        description: "Interpersonal and communication skills tests assess a teen's ability to interact effectively with others, crucial for careers in management, healthcare, and customer service."
      }
    ];

    return (
      <div className="overflow-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Empowering Teen Talent Potential for Meaningful Growth</title>
          <meta name="description" content="Elevate teen potential with Teenager Talent assessment, fostering confidence, purposeful living, and transformative growth." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/home" />
        </Helmet>


        {/* <TestHeader
          handleTakeAssessmentRedirect={this.handleTakeAssessmentRedirect}
        /> */}

        <section
          className="bg-[#2196F3]/10 w-full h-auto bg-center  bg-cover bg-no-repeat  "
          style={{
            backgroundImage: `url('./img/career-bg-image.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          {/* Hero Section */}
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10 px-4 mx-auto md:pt-10 md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-6 text-center md:pb-40 pb-20 md:pt-8 ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Your integrated one-stop <br /> solution for teen career guidance</h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-[#212121]">Discover your ideal career path with our comprehensive assessment tool and Career Fitment Analysis.</p>
                </div>
                <div className="md:flex grid grid-cols-2 gap-4 items-center justify-center  ">
                  <div className="flex flex-col justify-center items-center gap-4 w-48">
                    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                      <path d="M46.774 51.3217V43.0383C46.774 40.0727 47.551 38.5303 49.077 35.952C50.9373 32.8023 51.9314 29.2166 51.9584 25.5587C51.9855 21.9007 51.0445 18.3007 49.231 15.1239C47.4175 11.947 44.7959 9.30637 41.6323 7.46983C38.4688 5.63328 34.8757 4.66618 31.2176 4.66666C25.7178 4.66728 20.4433 6.85204 16.5539 10.7406C12.6644 14.6291 10.4785 19.9031 10.4766 25.403C10.4766 26.7563 10.4766 27.433 10.4206 27.811C10.2853 28.7163 9.86063 29.4957 9.44763 30.303L5.29197 38.3623L8.55863 39.9957C9.50363 40.467 9.9773 40.7027 10.3063 41.139C10.6353 41.5753 10.7286 42.07 10.913 43.0523L10.9316 43.1503C11.3586 45.409 11.8276 47.901 13.1483 49.4737C13.615 50.0267 14.1936 50.477 14.8446 50.7967C15.9203 51.3217 17.2433 51.3217 19.8893 51.3217C21.1143 51.3217 22.3416 51.3497 23.5666 51.3217C26.467 51.2493 28.6253 48.8087 28.6253 45.9783" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M23.0541 24.5747C24.0481 24.5747 24.9558 24.1967 25.6441 23.5783M23.0541 24.5747C23.0541 27.2487 24.6035 29.7873 27.5855 29.7873C30.5675 29.7873 32.1168 32.326 32.1168 35M23.0541 24.5747C18.0375 24.5747 18.0375 16.7557 23.0541 16.7557C23.5115 16.7572 23.9447 16.8311 24.3538 16.9773C24.1088 11.1487 32.5111 9.56667 34.4151 15.0267M34.4151 15.0267C33.0268 15.9623 32.1168 17.5537 32.1168 19.3597M34.4151 15.0267C38.7365 12.11 44.4951 17.3437 41.7558 22.0103C46.3525 22.6987 45.8625 29.7873 41.1795 29.7873C40.5551 29.7871 39.94 29.6359 39.3866 29.3466C38.8333 29.0573 38.3581 28.6386 38.0015 28.126" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className="font-semibold text-lg uppercase">Psychometric Assessments</p>
                  </div>
                  <div className="w-2 h-20 border-l border-[#2196F3]/40 md:block hidden" />
                  <div className="flex flex-col justify-center items-center gap-4 w-48">
                    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                      <path d="M12.541 21H26.541C27.1599 21 27.7533 20.7542 28.1909 20.3166C28.6285 19.879 28.8743 19.2855 28.8743 18.6667V9.33333C28.8743 8.71449 28.6285 8.121 28.1909 7.68342C27.7533 7.24583 27.1599 7 26.541 7L12.541 7C11.9222 7 11.3287 7.24583 10.8911 7.68342C10.4535 8.121 10.2077 8.71449 10.2077 9.33333M12.541 21C11.9222 21 11.3287 20.7542 10.8911 20.3166C10.4535 19.879 10.2077 19.2855 10.2077 18.6667V9.33333M12.541 21C11.9222 21 11.3287 21.2458 10.8911 21.6834C10.4535 22.121 10.2077 22.7145 10.2077 23.3333V32.6667C10.2077 33.2855 10.4535 33.879 10.8911 34.3166C11.3287 34.7542 11.9222 35 12.541 35M12.541 21H35.8743C36.4932 21 37.0867 21.2458 37.5243 21.6834C37.9618 22.121 38.2077 22.7145 38.2077 23.3333V32.6667C38.2077 33.2855 37.9618 33.879 37.5243 34.3166C37.0867 34.7542 36.4932 35 35.8743 35H12.541M10.2077 9.33333V42M12.541 35C11.9222 35 11.3287 35.2458 10.8911 35.6834C10.4535 36.121 10.2077 36.7145 10.2077 37.3333V46.6667C10.2077 47.2855 10.4535 47.879 10.8911 48.3166C11.3287 48.7542 11.9222 49 12.541 49H45.2077C45.8265 49 46.42 48.7542 46.8576 48.3166C47.2952 47.879 47.541 47.2855 47.541 46.6667V37.3333C47.541 36.7145 47.2952 36.121 46.8576 35.6834C46.42 35.2458 45.8265 35 45.2077 35H12.541Z" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className="font-semibold text-lg uppercase">Career Fitment Analysis</p>
                  </div>
                  <div className="w-2 h-20 border-l border-[#2196F3]/40 md:block hidden" />
                  <div className="flex flex-col justify-center items-center gap-4 w-48">
                    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                      <path d="M18.7923 11.6667H14.1257C12.888 11.6667 11.701 12.1583 10.8258 13.0335C9.95065 13.9087 9.45898 15.0956 9.45898 16.3333V44.3333C9.45898 45.571 9.95065 46.758 10.8258 47.6332C11.701 48.5083 12.888 49 14.1257 49H27.4186M42.1256 32.6667V42H51.459M42.1256 25.6667V16.3333C42.1256 15.0956 41.634 13.9087 40.7588 13.0335C39.8836 12.1583 38.6967 11.6667 37.459 11.6667H32.7923" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18.793 25.6667H28.1263M18.793 35H25.793M18.793 11.6667C18.793 10.429 19.2846 9.242 20.1598 8.36683C21.035 7.49167 22.222 7 23.4596 7H28.1263C29.364 7 30.551 7.49167 31.4261 8.36683C32.3013 9.242 32.793 10.429 32.793 11.6667C32.793 12.9043 32.3013 14.0913 31.4261 14.9665C30.551 15.8417 29.364 16.3333 28.1263 16.3333H23.4596C22.222 16.3333 21.035 15.8417 20.1598 14.9665C19.2846 14.0913 18.793 12.9043 18.793 11.6667ZM32.793 42C32.793 44.4754 33.7763 46.8493 35.5266 48.5997C37.277 50.35 39.6509 51.3333 42.1263 51.3333C44.6017 51.3333 46.9756 50.35 48.726 48.5997C50.4763 46.8493 51.4596 44.4754 51.4596 42C51.4596 39.5246 50.4763 37.1507 48.726 35.4003C46.9756 33.65 44.6017 32.6667 42.1263 32.6667C39.6509 32.6667 37.277 33.65 35.5266 35.4003C33.7763 37.1507 32.793 39.5246 32.793 42Z" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className="font-semibold text-lg uppercase">Personalized Reports</p>
                  </div>
                  <div className="w-2 h-20 border-l border-[#2196F3]/40 md:block hidden" />
                  <div className="flex flex-col justify-center items-center gap-4 w-48">
                    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
                      <path d="M12.0423 9.33334H21.3757C21.9945 9.33334 22.588 9.57918 23.0256 10.0168C23.4632 10.4543 23.709 11.0478 23.709 11.6667V25.6667C23.709 26.2855 23.4632 26.879 23.0256 27.3166C22.588 27.7542 21.9945 28 21.3757 28H12.0423C11.4235 28 10.83 27.7542 10.3924 27.3166C9.95482 26.879 9.70898 26.2855 9.70898 25.6667V11.6667C9.70898 11.0478 9.95482 10.4543 10.3924 10.0168C10.83 9.57918 11.4235 9.33334 12.0423 9.33334ZM12.0423 37.3333H21.3757C21.9945 37.3333 22.588 37.5792 23.0256 38.0168C23.4632 38.4543 23.709 39.0478 23.709 39.6667V44.3333C23.709 44.9522 23.4632 45.5457 23.0256 45.9833C22.588 46.4208 21.9945 46.6667 21.3757 46.6667H12.0423C11.4235 46.6667 10.83 46.4208 10.3924 45.9833C9.95482 45.5457 9.70898 44.9522 9.70898 44.3333V39.6667C9.70898 39.0478 9.95482 38.4543 10.3924 38.0168C10.83 37.5792 11.4235 37.3333 12.0423 37.3333ZM35.3757 28H44.709C45.3278 28 45.9213 28.2458 46.3589 28.6834C46.7965 29.121 47.0423 29.7145 47.0423 30.3333V44.3333C47.0423 44.9522 46.7965 45.5457 46.3589 45.9833C45.9213 46.4208 45.3278 46.6667 44.709 46.6667H35.3757C34.7568 46.6667 34.1633 46.4208 33.7257 45.9833C33.2881 45.5457 33.0423 44.9522 33.0423 44.3333V30.3333C33.0423 29.7145 33.2881 29.121 33.7257 28.6834C34.1633 28.2458 34.7568 28 35.3757 28ZM35.3757 9.33334H44.709C45.3278 9.33334 45.9213 9.57918 46.3589 10.0168C46.7965 10.4543 47.0423 11.0478 47.0423 11.6667V16.3333C47.0423 16.9522 46.7965 17.5457 46.3589 17.9833C45.9213 18.4208 45.3278 18.6667 44.709 18.6667H35.3757C34.7568 18.6667 34.1633 18.4208 33.7257 17.9833C33.2881 17.5457 33.0423 16.9522 33.0423 16.3333V11.6667C33.0423 11.0478 33.2881 10.4543 33.7257 10.0168C34.1633 9.57918 34.7568 9.33334 35.3757 9.33334Z" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className="font-semibold text-lg uppercase">Dashboard & Analytics</p>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-center gap-4 py-4 ">
                  <button data-aos="fade-up"
                    onClick={() => this.gotoPage("products")} className="flex space-x-2  bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer  w-fit justify-center"><span>  Take Your Test</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </button>
                  <button onClick={this.handleDownload} data-aos="fade-up" className="flex space-x-2 items-center border border-black hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black transition-all duration-200 text-black py-2 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-clipboard-text"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M9 12h6" /><path d="M9 16h6" /></svg>
                    <span>Get Career Guide</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="bg-gradient-to-r from-[#2196F3] to-[#673AB7]">
          <div className="flex flex-col w-full h-full gap-10 px-4 py-10 mx-auto md:flex-row md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
            <p className="w-full md:text-xl text-lg text-[#fff] md:pr-10">Teen Career Guidance Tool is a groundbreaking tool designed to unleash the full potential of teen individuals. It offers a transformative approach to helping teens discover their personality, navigate challenges, and find their suitable career. It helps in providing tailored insights and guidance for their career journeys.</p>
            <div className="h-auto border-t md:border-l border-white/30"></div>
            <h2 className="w-full md:text-4xl text-3xl md:leading-[3rem] capitalize font-bold text-[#fff] md:pl-10 ">If you judge a fish by its ability to climb a tree, it will always believe it had a wasted life.</h2>
          </div>
        </section>

        <section ref={this.myRef} className="w-full px-4 mx-auto bg-white 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="pt-10 mx-auto space-y-4 text-center">
            <h1
              data-aos="fade-up"
              className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
            >Career Guidance Program Test Flow</h1>
            <p data-aos="fade-up" className="md:text-lg text-base text-[#212121]">
              You need to take following test in order to get the career guidance program report.
            </p>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center w-full gap-10 md:px-8 px-4 lg:pt-16 pt-10 mx-auto 2xl:w-9/12 xl:w-11/12 md:w-12/12 lg:px-0 md:flex-row ">
          <div data-aos="fade-up" className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1   w-full gap-4   border-collapse   whitespace-nowrap ">

            <div className={`bg-gradient-to-b from-[#2196F3] to-transparent w-full rounded-t-xl p-0.5 ${this.state.activeTab === 1 ? '' : 'grayscale'}`}>
              <div className="bg-white rounded-t-xl ">
                <button onClick={() => this.handleTabClick(1)} className="text-[#212121] text-center py-4 px-4 cursor-pointer bg-gradient-to-b from-[#2196F3]/10 to-[#fff] transition-all duration-150 w-full flex items-center justify-center space-x-4 rounded-t-xl">
                  <img src="./assessment_images/aptitude-test.svg" alt="personality-test" className="w-14 flex-shrink-0" />
                  <span className="text-lg font-semibold">Aptitude</span>
                </button>
              </div>
            </div>

            <div className={`bg-gradient-to-b from-[#2196F3] to-transparent w-full rounded-t-xl p-0.5 ${this.state.activeTab === 2 ? '' : 'grayscale'}`}>
              <div className="bg-white rounded-t-xl ">
                <button onClick={() => this.handleTabClick(2)} className="text-[#212121] text-center py-4 px-4 cursor-pointer bg-gradient-to-b from-[#2196F3]/10 to-[#fff] transition-all duration-150 w-full flex items-center justify-center space-x-4 rounded-t-xl">
                  <img src="./assessment_images/personality-test.svg" alt="personality-test" className="w-14 flex-shrink-0" />
                  <span className="text-lg font-semibold">Personality  </span>
                </button>
              </div>
            </div>

            <div className={`bg-gradient-to-b from-[#2196F3] to-transparent w-full rounded-t-xl p-0.5 ${this.state.activeTab === 3 ? '' : 'grayscale'}`}>
              <div className="bg-white rounded-t-xl ">
                <button onClick={() => this.handleTabClick(3)} className="text-[#212121] text-center py-4 px-4 cursor-pointer bg-gradient-to-b from-[#2196F3]/10 to-[#fff] transition-all duration-150 w-full flex items-center justify-center space-x-4 rounded-t-xl">
                  <img src="./assessment_images/holland's-interest-test.svg" alt="holland's-interest-test" className="w-14 flex-shrink-0" />
                  <span className="text-lg font-semibold"> Interest  </span>
                </button>
              </div>
            </div>

            <div className={`bg-gradient-to-b from-[#2196F3] to-transparent w-full rounded-t-xl p-0.5 ${this.state.activeTab === 4 ? '' : 'grayscale'}`}>
              <div className="bg-white rounded-t-xl ">
                <button onClick={() => this.handleTabClick(4)} className="text-[#212121] text-center py-4 px-4 cursor-pointer bg-gradient-to-b from-[#2196F3]/10 to-[#fff] transition-all duration-150 w-full flex items-center justify-center space-x-4 rounded-t-xl">
                  <img src="./assessment_images/work-value.svg" alt="work-value" className="w-14 flex-shrink-0" />
                  <span className="text-lg font-semibold">Work Value  </span>
                </button>
              </div>
            </div>

          </div>
        </section>

        {/* Learning section 1*/}
        {this.state.activeTab === 1 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
              <div data-aos="fade-right" className="lg:w-7/12 w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">Aptitude Test</h1>
                <p className="text-lg text-[#212121]">Aptitude Test measures a teenager's ability to handle numerical data, perform calculations, reasoning and understand verbal information. This helps identify strengths in math and language skills, guiding suitable career paths.</p>
                <ul className="list-disc text-lg font-semibold italic space-y-2 pl-10 grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 ">
                  <li>Logical Reasoning</li>
                  <li>Numerical Ability</li>
                  <li>Verbal Aptitude</li>
                  <li>Spatial Reasoning</li>
                  <li>Mechanical Reasoning</li>
                  <li>Interpersonal and Communication Skills</li>
                </ul>

              </div>
              <div className="lg:w-5/12 md:w-11/12 w-full space-y-2">
                {/* <p className="text-xl font-medium text-[#212121]/80">Example</p> */}
                <img
                  data-aos="fade-left"
                  alt="Learning"
                  width={790}
                  height={790}
                  src="./assessment_images/aptitude-test-example.svg"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="grid lg:grid-cols-2 md:gap-6 gap-2 w-full  ">
                {numericalTest.map((items, index) => (
                  <div className="flex  flex-row  items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 md:gap-6 gap-4">
                    <img alt={items.title} src={items.imgSrc} className="object-cover h-auto md:w-36 w-24" />
                    <div className=" md:space-y-3 space-y-1">
                      <p className="lg:text-xl text-base font-bold text-[#212121]">{items.title}</p>
                      <p className="md:text-lg text-sm">{items.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          : null}

        {this.state.activeTab === 2 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
              <div data-aos="fade-right" className="lg:w-7/12 w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  Personality Test
                </h1>
                <p className="text-lg text-[#212121]">The Personality test for teens helps young people understand their unique traits and work environments that align with their personalities.</p>
                <ul className="list-disc text-lg font-semibold italic space-y-2 pl-10 grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 ">
                  <li>Learning Beliefs</li>
                  <li>Interpersonal Beliefs</li>
                  <li>Emotional Beliefs</li>
                  <li>Attitudinal Beliefs</li>
                  <li>Conscientious Beliefs</li>
                </ul>

              </div>
              <div className="lg:w-5/12 md:w-11/12 w-full space-y-2">
                {/* <p className="text-xl font-medium text-[#212121]/80">Example</p> */}
                <img
                  data-aos="fade-left"
                  alt="Learning"
                  width={790}
                  height={790}
                  src="./assessment_images/personality-test-example.svg"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="grid lg:grid-cols-2 md:gap-6 gap-2 w-full  ">
                <div className="flex  flex-row  items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 md:gap-6 gap-4">
                  <img alt="img" src="./assessment_images/learning-beliefs.webp" className="object-cover h-auto md:w-36 w-24" />
                  <div className=" md:space-y-3 space-y-1  "><p className="lg:text-xl text-base font-bold text-[#212121]">Learning Beliefs</p><p className="md:text-lg text-sm">Focuses on teens' attitudes toward learning and education, impacting their openness to acquiring new skills and knowledge in their career journey.</p></div>
                </div>
                <div className="flex  flex-row  items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 md:gap-6 gap-4">
                  <img alt="img" src="./assessment_images/interpersonal-beliefs.webp" className="object-cover h-auto md:w-36 w-24" />
                  <div className="md:space-y-3 space-y-1 "><p className="lg:text-xl text-base font-bold text-[#212121]">Interpersonal Beliefs</p><p className="md:text-lg text-sm">Relates to teens' perceptions of their social interactions and relationships, influencing their ability to work collaboratively and build networks in their careers.</p></div>
                </div>
                <div className="flex  flex-row  items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 md:gap-6 gap-4">
                  <img alt="img" src="./assessment_images/emotional-beliefs.webp" className="object-cover h-auto md:w-36 w-24" />
                  <div className="md:space-y-3 space-y-1 "><p className="lg:text-xl text-base font-bold text-[#212121]">Emotional Beliefs</p><p className="md:text-lg text-sm">Involves teens' understanding of emotions and stress management, guiding how they handle workplace challenges and maintain emotional well-being.</p></div>
                </div>
                <div className="flex  flex-row  items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 md:gap-6 gap-4">
                  <img alt="img" src="./assessment_images/attitudinal-beliefs.webp" className="object-cover h-auto md:w-36 w-24" />
                  <div className="md:space-y-3 space-y-1 "><p className="lg:text-xl text-base font-bold text-[#212121]">Attitudinal Beliefs</p><p className="md:text-lg text-sm">Concerns teens' attitudes and mindset towards work, impacting their motivation, resilience, and approach to career opportunities.</p></div>
                </div>
                <div className="flex  flex-row  items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 md:gap-6 gap-4">
                  <img alt="img" src="./assessment_images/conscientious-beliefs.webp" className="object-cover h-auto md:w-36 w-24" />
                  <div className="md:space-y-3 space-y-1 "><p className="lg:text-xl text-base font-bold text-[#212121]">Conscientious Beliefs</p><p className="md:text-lg text-sm">Addresses teens' sense of responsibility, discipline, and work ethic, affecting their reliability and commitment to career goals.</p></div>
                </div>

              </div>
            </div>
          </section>
          : null}

        {this.state.activeTab === 3 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
              <div data-aos="fade-right" className="lg:w-7/12 w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem] capitalize font-bold text-[#212121]"> Interest Test</h1>
                <p className="text-lg text-[#212121]">The  Interest Test helps teens find career paths and industries that align with their personal interests and preferences.</p>
                <ul className="list-disc text-lg font-semibold italic space-y-2 pl-10 grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 ">
                  {hollandValue.map((items) => (
                    <li>{items.label}</li>
                  ))}
                </ul>

              </div>
              <div className="lg:w-5/12 md:w-11/12 w-full space-y-2">
                {/* <p className="text-xl font-medium text-[#212121]/80">Example</p> */}
                <img
                  data-aos="fade-left"
                  alt="Learning"
                  width={790}
                  height={790}
                  src="./assessment_images/interest-test-example.svg"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="grid lg:grid-cols-2 md:gap-6 gap-2 w-full  ">

                {hollandValue.map((items) => (
                  <div key={items.id} className="flex flex-row md:items-center items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10 md:p-6 p-4 md:gap-6 gap-4">
                    <img alt="img" src={items.imgSrc} className="object-cover h-auto md:w-36 w-24" />
                    <div className="md:space-y-3 space-y-1">
                      <p className="lg:text-xl text-base font-bold text-[#212121]">{items.label}</p>
                      <p className="md:text-lg text-sm">{items.description}</p>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </section>
          : null}

        {this.state.activeTab === 4 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
              <div data-aos="fade-right" className="lg:w-7/12 w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem] capitalize font-bold text-[#212121]">Work Value Test</h1>
                <p className="text-lg text-[#212121]">Work value survey for teens helps young people identify what they value most in a work environment, that align with their personal values and ethics.</p>
                <ul className="list-disc text-lg font-semibold italic space-y-2 pl-10 grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 ">
                  {workValue.map((items) => (
                    <li>{items.title}</li>
                  ))}
                </ul>

              </div>
              <div className="lg:w-5/12 md:w-11/12 w-full space-y-2">
                {/* <p className="text-xl font-medium text-[#212121]/80">Example</p> */}
                <img
                  data-aos="fade-left"
                  alt="Learning"
                  width={790}
                  height={790}
                  src="./assessment_images/work-value-test-example.svg"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="grid lg:grid-cols-2 md:gap-6 gap-2 w-full  ">

                {workValue.map((items) => (
                  <div key={items.id} className="flex flex-row md:items-center items-start justify-start w-full bg-gradient-to-b from-[#2196F3]/10 md:p-6 p-4 md:gap-6 gap-4">
                    <img alt="img" src={items.icon} className="object-cover h-auto md:w-36 w-24" />
                    <div className="md:space-y-3 space-y-1">
                      <p className="lg:text-xl text-base font-bold text-[#212121]">{items.title}</p>
                      <p className="md:text-lg text-sm">{items.dics}</p>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </section>
          : null}



        <section className="bg-[#f5f5f5] space-y-10 md:py-20 py-10">
          <div className="  mx-auto space-y-4 text-center">
            <h1 data-aos="fade-up" className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">Our Career Guidance Tool</h1>
          </div>
          <div className="items-center w-full gap-10 px-4  mx-auto  2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="grid lg:grid-cols-2 md:gap-6 gap-4 w-full  ">
              <div className="flex md:flex-row flex-col md:items-start items-center md:justify-start justify-center w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 gap-4 ">
                <img alt="img" src="./img/discover-yourself.svg" className="object-cover h-auto w-36" />
                <div className="space-y-6 md:text-left text-center ">
                  <div className="md:space-y-4 space-y-2 ">
                    <h2 className="lg:text-xl text-lg font-bold text-[#212121]">Discover Yourself</h2>
                    <p className="md:text-lg text-base">Recognizes and celebrates the unique talents and abilities of each teenager, valuing their individuality.</p>
                  </div>
                  <button onClick={() => this.gotoPage("teen-discover-yourself")} className="flex items-center space-x-2 text-[#2196F3] uppercase font-medium w-fit md:mx-0 mx-auto">
                    <span>Know More</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </button>
                </div>
              </div>

              <div className="flex md:flex-row flex-col md:items-start items-center md:justify-start justify-center w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 gap-4 ">
                <img alt="img" src="./img/career-libraries.svg" className="object-cover h-auto w-36" />
                <div className="space-y-6 md:text-left text-center ">
                  <div className="space-y-4 ">
                    <h2 className="lg:text-xl text-xl font-bold text-[#212121]">Career Libraries</h2>
                    <p className="md:text-lg text-base">Challenges traditional success metrics, offering a comprehensive evaluation that goes beyond narrow criteria.</p>
                  </div>
                  <button onClick={() => this.gotoPage("teen-career-libraries")} className="flex items-center space-x-2 text-[#2196F3] uppercase font-medium w-fit md:mx-0 mx-auto">
                    <span>Know More</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </button>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-start items-center md:justify-start justify-center w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 gap-4 ">
                <img alt="img" src="./img/career-fitment-analysis.svg" className="object-cover h-auto w-36" />
                <div className="space-y-6 md:text-left text-center ">
                  <div className="space-y-4 ">
                    <h2 className="lg:text-xl text-xl font-bold text-[#212121]">Career Fitment Analysis</h2>
                    <p className="md:text-lg text-base">Challenges traditional success metrics, offering a comprehensive evaluation that goes beyond narrow criteria.</p>
                  </div>
                  <button onClick={() => this.gotoPage("teen-career-fitment-analysis")} className="flex items-center space-x-2 text-[#2196F3] uppercase font-medium w-fit md:mx-0 mx-auto">
                    <span>Know More</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </button>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-start items-center md:justify-start justify-center w-full bg-gradient-to-b from-[#2196F3]/10  md:p-6 p-4 gap-4 ">
                <img alt="img" src="./img/report.svg" className="object-cover h-auto w-36" />
                <div className="space-y-6 md:text-left text-center ">
                  <div className="space-y-4 ">
                    <h2 className="lg:text-xl text-xl font-bold text-[#212121]">Report</h2>
                    <p className="md:text-lg text-base">Guides teenagers through societal pressures, empowering them to resist predefined expectations and find their authentic path.</p>
                  </div>
                  <button onClick={() => this.gotoPage("teen-personalized-report")} className="flex items-center space-x-2 text-[#2196F3] uppercase font-medium w-fit md:mx-0 mx-auto">
                    <span>Know More</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{ backgroundImage: 'url("/img/assessment/bg-gradient.webp")' }} >

          <div className="w-full h-full">
            <div className="flex flex-col items-center w-full gap-10 py-10 2xl:py-20 lg:flex-row 2xl:gap-20 lg:gap-10 md:gap-20 img-changer ">
              <div className="lg:w-[40%] w-full text-left grid grid-cols-1 gap-0 2xl:ml-[8%] xl:ml-[4%] xl:px-0 px-4 mx-auto aos-init aos-animate space-y-8   ">
                <h1
                  data-aos="fade-right"
                  className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] capitalize aos-init aos-animate"
                >
                  Career Fitment Analysis
                </h1>
                <p className="font-medium lg:text-xl text-lg ">A summary report include the following key elements based on detailed Teen Career Assessment Report:</p>
                <ul
                  data-aos="fade-right"
                  className="text-[#212121] font-normal flex flex-col gap-4 text-left aos-init aos-animate"
                >
                  <li className="flex space-x-2 lg:text-lg text-base text-[#212121]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1 flex-shrink-0"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      />
                    </svg>
                    <span>Top 5 Career Recommendations: List of the five most suitable career options based on the individual's assessment results.</span>
                  </li>
                  <li className="flex space-x-2 lg:text-lg text-base text-[#212121]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1 flex-shrink-0"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      />
                    </svg>
                    <span>
                      {" "}
                      Top 5 Career Overall Fitment Scores: The scores indicating how well the individual fits into each of the top five career recommendations.
                    </span>
                  </li>
                  <li className="flex space-x-2 lg:text-lg text-base text-[#212121]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1 flex-shrink-0"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      />
                    </svg>
                    <span>
                      Top 3 Aptitude Test Sections: Highlights the three highest-scoring sections out of six sections of the aptitude test.
                    </span>
                  </li>
                  <li className="flex space-x-2 lg:text-lg text-base text-[#212121]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1 flex-shrink-0"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      />
                    </svg>
                    <span>
                      Top 3 Personality Traits: The three most prominent personality traits of the individual, as identified by the assessment.
                    </span>
                  </li>


                  <li className="flex space-x-2 lg:text-lg text-base text-[#212121]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1 flex-shrink-0"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      />
                    </svg>
                    <span>
                      Top 3 Interest Traits: The three most prominent Interest traits, out of six types: Realistic, Investigative, Artistic, Social, Enterprising, and Conventional.
                    </span>
                  </li>
                  <li className="flex space-x-2 lg:text-lg text-base text-[#212121]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1 flex-shrink-0"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      />
                    </svg>
                    <span>
                      Top 3 Work Values Behaviors: The three most important work values for the individual, reflecting what they value most in a work environment.
                    </span>
                  </li>
                </ul>
                <p className="lg:text-lg text-base text-[#212121]">This summary report provides a concise overview of the individual's strengths, interests, and suitable career paths based on a comprehensive analysis.</p>

              </div>
              <div className="lg:w-[60%] w-full flex flex-col justify-end lg:pl-10 px-4">
                <img
                  data-aos="fade-left"
                  src="./img/assessment/teen-career-summary.webp"
                  alt="impact-coach"
                  className="z-0 w-full aos-init aos-animate"
                />
              </div>
            </div>
          </div>
        </section>


        {/* Contact Us */}
        <section className="py-20 bg-[#F9F9FC] space-y-10" >
          {/* <h2 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center aos-init aos-animate capitalize">Get in Touch</h2> */}
          <div className="grid w-full grid-cols-1 gap-8 px-4 mx-auto text-gray-100 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:grid-cols-2">

            <div data-aos="fade-right" className="space-y-4 ">
              <div data-aos="fade-up" className="space-y-2 text-[#212121]">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#212121]  capitalize">
                  Get in touch
                </h2>
                <p className="lg:text-lg text-base text-[#212121]">
                  For customer support and query, get in touch with us.
                </p>
              </div>

              <div className="space-y-0 ">
                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 border-b">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#212121] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-mail"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="3" y="5" width="18" height="14" rx="2" />
                      <polyline points="3 7 12 13 21 7" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Email
                    </h1>
                    <p className="text-base ">connect@happyplus.in</p>
                  </div>
                </div>

                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 ">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#3D405B] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="3" y1="21" x2="21" y2="21" />
                      <line x1="9" y1="8" x2="10" y2="8" />
                      <line x1="9" y1="12" x2="10" y2="12" />
                      <line x1="9" y1="16" x2="10" y2="16" />
                      <line x1="14" y1="8" x2="15" y2="8" />
                      <line x1="14" y1="12" x2="15" y2="12" />
                      <line x1="14" y1="16" x2="15" y2="16" />
                      <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#3D405B] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Office Address:
                    </h1>
                    <p className="text-base ">
                      HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai
                      400065
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <img
                  data-aos="fade-right"
                  src="/img/domaim/contact-us.svg"
                  alt="contact-us"
                  className="flex items-center justify-center p-6 w-6/6 "
                  width={490}
                  height={360}
                />
              </div>
            </div>

            <div data-aos="fade-left" className="space-y-6 ">
              <div data-aos="fade-up" className="space-y-2 ">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                  Submit Details
                </h2>
                <p className="lg:text-lg text-base text-[#3D405B]">
                  Fill in your details below & we will get back to you soon!
                </p>
              </div>

              <div noValidate action className="grid grid-cols-1 gap-6 pt-2">
                <div className="space-y-2 ">
                  <label
                    htmlFor="name"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678] capitalize"
                  >
                    Full name
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["userName"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="userName"
                    name="userName"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["userName"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["userName"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="email"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678]"
                  >
                    Email
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["email"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["email"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["email"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="phone"
                    className="text-base font-medium text-[#212121]"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md"
                    id="phone"
                    name="phone"
                    placeholder="+91 "
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="message"
                    className="text-base font-medium text-[#212121]"
                  >
                    {" "}
                    Message (If Any)
                  </label>
                  <textarea
                    rows={3}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md resize-none"
                    id="message"
                    name="message"
                    placeholder=""
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <button
                  type="button"
                  className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150"
                  onClick={this.loginSubmit}
                >
                  {" "}
                  Send Message
                </button>

                {this.state.saveSuccess ? (
                  <button
                    type="button"
                    className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <span>Your query has been successfully sent!</span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
TeenCareerGuidance.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(TeenCareerGuidance);
