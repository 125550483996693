import React from "react";
import moment from "moment";

export default function NewAssignAssessmentCodes(props) {
  let { saveNewEmployee, getDetails, handleShowAssessmentCode, getOrderBookListData, handleSelectAssessmentCodes, userAssessmentOrdersData, handleAssessmentCodesCheckBox, handleAddCode, codeIdList } = props;

  // console.log('getOrderBookListDatagetOrderBookListDatagetOrderBookListData', getOrderBookListData);


  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative   left-0 right-0 overflow-hidden">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden block">

            <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl text-gray-800">Assign Assessment Codes</h2>
              <div onClick={() => handleShowAssessmentCode(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

            <div className="py-4 p-6 space-y-3">

              <div className="text-sm space-y-1">
                <h2 className="text-base font-semibold text-[#212121]">Pooja Sharma</h2>
                <p className="text-sm text-[#212121]/70">Total assigned assessment codes: <span className="font-semibold text-[#212121]">4</span></p>
              </div>


              <div className="text-sm space-y-1">
                <label className="text-sm font-medium">Purchase Date & Time:</label>
                <select id="entries" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full px-2 py-1.5 focus:outline-none"
                  onChange={handleSelectAssessmentCodes}
                >
                  <option value="">Select</option>
                  {getOrderBookListData && getOrderBookListData.length > 0
                    ? getOrderBookListData.map((orderBook) =>
                      getDetails(orderBook, "orderPacks").map((orderPack) => {
                        const name = getDetails(orderPack, "name", 2, orderPack);
                        const createdAt = moment(new Date(parseInt(getDetails(orderBook, "createdAt"))))
                          .utcOffset("+05:30")
                          .format("DD MMM YYYY");
                        return (
                          <option name={orderPack.id} value={name}>
                            {`${name}, ${createdAt}`}
                          </option>
                        );
                      })
                    )
                    : null}
                </select>
              </div>

              <div className="mt-4">
                <p className="text-sm text-[#212121]/70">Total assessment codes: <span className="font-semibold text-[#212121]">{userAssessmentOrdersData && userAssessmentOrdersData.length ? userAssessmentOrdersData.length : 0}</span></p>
                <div className="w-full mx-auto">
                  <div className="inline-block min-w-full py-2">
                    <div className="overflow-hidden overflow-y-auto h-[350px]">
                      <table className="min-w-full border">
                        <thead className="bg-gray-50 sticky top-0">
                          <tr>
                            <th className="px-2 py-2 border text-sm font-semibold text-gray-900 w-20 text-center">
                              <label className="py-2 flex px-4 cursor-pointer w-full">
                                <input className="mr-3 text-[#3D405B]"
                                  type="checkbox"
                                  checked={false}
                                  onChange={() => { }}
                                ></input>
                                S.N.
                              </label>
                            </th>
                            <th className="px-3 py-2 text-left border text-sm font-semibold text-gray-900">Name</th>
                          </tr>
                        </thead>
                        <tbody className="border divide-gray-200 bg-white">
                          {userAssessmentOrdersData && userAssessmentOrdersData.length > 0 ? (
                            userAssessmentOrdersData.map((assessmentCode, assessmentCodeId) => (
                              <React.Fragment key={assessmentCodeId}>
                                <tr>
                                  <td className="whitespace-nowrap px-2 py-1 border text-sm text-center w-20">
                                    <label className="py-2 flex px-4 cursor-pointer w-full">
                                      <input
                                        className="mr-3 text-[#3D405B]"
                                        type="checkbox"
                                        checked={codeIdList.includes(assessmentCode.id)}//Check if the ID is in list                                        
                                        onChange={() => handleAssessmentCodesCheckBox(assessmentCode.id)}
                                      />
                                      {assessmentCodeId + 1}
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-1 border text-sm">
                                    <div className="border-dashed border-2 border-[#4CAF50] bg-[#4CAF50]/10 text-[#4CAF50] px-3 py-1 w-56">
                                      {assessmentCode && assessmentCode.otpCode ? assessmentCode.otpCode : "-"}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))
                          ) : null}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>


              <div className="text-right pt-4 border-t">
                <button onClick={() => handleAddCode()} type="button" className="w-44 bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase">Add Code</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
