import React from 'react';

export default function Top5Talents(props) {

  let { } = props;

  return (
    <>

      {/* Resources section */}

      {/* <section className="w-full py-4">
                                    <h1 className="py-2 text-2xl font-medium md:text-4xl text-[#3D405B]">Resources</h1>
                                    <h1 className="py-2 text-2xl font-medium md:text-2xl text-[#3D405B] mb-3">Choose an experts according to your choice</h1>
                                    <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">
          <div className="transition-all duration-150 rounded-md shadow-sm hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/qchkvJ8L8rY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6 bg-white border">
              <h1 className="text-[#3D405B] font-bold text-2xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B] tracking-wide text-base text-left py-3">Astro Teller, TED Talk</p>
            </div>
          </div>
          <div className="transition-all duration-150 rounded-md shadow-sm hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/S2qeTfOxDF8?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6 bg-white border">
              <h1 className="text-[#3D405B] font-bold text-2xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B] tracking-wide text-base text-left py-3">Astro Teller, TED Talk</p>
            </div>
          </div>
          <div className="transition-all duration-150 rounded-md shadow-sm hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/3UHncbbgD-c?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6 bg-white border">
              <h1 className="text-[#3D405B] font-bold text-2xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B] tracking-wide text-base text-left py-3">Astro Teller, TED Talk</p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
