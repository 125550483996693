import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { GiCheckMark } from "react-icons/gi";
import { Helmet } from "react-helmet";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
    };
  }

  useEffect() {
    window.scrollTo(0, 0);
  }


  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    let { quantity } = this.state;
    // console.log("quantity-->", quantity);

    return (
      <div className="overflow-x-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Discover Your talent Strengths by The Psychometric Approach backed data driven talent assessment with scientific precision</title>
          <meta
            name="description"
            content="Know your talent strengths through precise, data-driven talent assessment using the psychometric approach."
          />
          <link
            rel="canonical"
            href="https://www.talentplusstrengths.com/#/assessment/how-talentplus-strength-works"
          />
        </Helmet>

        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{
            backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={
              getAssessmentCartCountData ? getAssessmentCartCountData : 0
            }
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
          {/* Hero Section */}
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-8 text-center md:pb-10 md:pt-0 md:text-left ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Learn TalentPlusStrengths Assessment Process </h1>
                <ul data-aos="fade-right" className="text-[#212121] font-normal flex flex-col gap-2 text-left">
                  <li className="flex space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      ></path>
                    </svg>
                    <span> Uncover your inherent strengths and abilities</span>
                  </li>
                  <li className="flex space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      ></path>
                    </svg>
                    <span>
                      {" "}
                      Gain insight into enhancing and nurturing your exceptional
                      talents
                    </span>
                  </li>
                  <li className="flex space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M0.819336 11.7609C4.16387 15.3797 7.40668 18.6375 10.5239 22.7578C13.9129 16.0172 17.3817 9.25312 23.1051 1.92796L21.5629 1.22156C16.7301 6.34687 12.9754 11.1984 9.71293 16.9641C7.44418 14.9203 3.77762 12.0281 1.53887 10.5422L0.819336 11.7609Z"
                        fill="#4CAF50"
                      ></path>
                    </svg>
                    <span>
                      Leverage your personalized outcomes and reports to unlock
                      your full potential
                    </span>
                  </li>
                </ul>
                <div className="flex flex-row items-center justify-center gap-4 md:justify-start md:items-center">

                  <button data-aos="fade-right"
                    onClick={() => this.gotoPage("products")}
                    className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center">  Book a Demo</button>


                </div>
              </div>

              <div className="flex items-end justify-center w-full lg:justify-end">
                <img data-aos="fade-left" src="/img/assessment/how-talentplusstrengths-works.webp" alt="impact-coach" className="z-0 w-full" />
              </div>
            </div>
          </div>


        </section>

        <section className="w-full px-4 py-20 mx-auto space-y-6 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="space-y-2">
            <h1
              data-aos="fade-up"
              className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] capitalize"
            >
              Tap into your greatest talents<br /> with the TalentPlusStrengths
              Assessment.
            </h1>
          </div>
          <p
            data-aos="fade-up"
            className="md:text-lg text-base text-[#212121] "
          >
            Empower yourself to reach new heights by taking the complete
            TalentPlusStrengths assessment. Access personalized reports
            specifically tailored to your unique strengths and abilities.
          </p>
        </section>

        <div className="space-y-16 ">
          {/* 1 */}
          <section className="flex flex-col-reverse items-center w-full gap-10 px-4 py-10 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
            {/* text */}
            <div data-aos="fade-right" className="w-full space-y-6">
              <span className="text-[#2196F3] uppercase font-medium">Step 01</span>
              <h2 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                Take the TalentPlusStrengths  Assessment
              </h2>
              <p className="text-lg text-[#212121] ">
                During this 20-minute assessment, you'll engage with 105 sets of
                statements representing 22 themes across 4 domains. Each set of
                statements will measure your talents, describe your way of
                feeling and behaving, and require you to choose which ones best
                reflect you.{" "}
              </p>
              {/* <p className="text-2xl font-semibold text-[#3D405B] pt-2">TalentPlusStrengths  allows you to:</p> */}
              <ul className=" space-y-2 text-lg text-[#212121] font-medium ">
                <li className="flex">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  User-Friendly Interactive Module
                </li>
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Unlock your inner-self with questions around 4 domains and 22
                  themes
                </li>
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Only 105 questions to Re-discover true you
                </li>
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Assessment helps to measures your natural patterns of
                  thinking, feeling and behaving
                </li>
              </ul>
            </div>
            {/* img */}
            <div className="w-full">
              <img
                alt="assessment_survey"
                data-aos="fade-left"
                src="/img/assessment/assessment_survey.webp"
                width={992}
                height={582}
              />
            </div>
          </section>

          {/* 2 */}
          <section className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
            {/* img */}
            <div className="w-full">
              <img alt="summary_report" data-aos="fade-right" src="/img/Home/summary_report.webp" width={992}
                height={582} />
            </div>
            {/* text */}
            <div data-aos="fade-left" className="w-full space-y-6">
              <span className="text-[#2196F3] uppercase font-medium">Step 02</span>
              <h2 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                Access your tailored TalentPlusStrengths Assessment results
              </h2>
              <p className="text-lg text-[#212121] ">
                Experience an "aha" moment as you delve into your customized
                TalentPlusStrengths reports and guides, gaining new insights
                and understanding into your exceptional uniqueness.
              </p>
              {/* <p className="text-2xl font-semibold text-[#3D405B] pt-2">TalentPlusStrengths  allows you to:</p> */}
              <ul className=" space-y-2 text-lg text-[#212121] font-medium ">
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Make the most of your strongest TalentPlusStrengths
                </li>
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Understand your full TalentPlusStrengths profile
                </li>
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Manage potential weaknesses
                </li>
                <li className="flex ">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Helps in unlocks who you are and how you can maximize your
                  infinite potential.
                </li>
              </ul>
            </div>
          </section>

          {/* 3 */}
          <section className="flex flex-col-reverse items-center w-full gap-10 px-4 py-10 pb-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
            {/* text */}
            <div data-aos="fade-right" className="w-full space-y-6">
              <span className="text-[#2196F3] uppercase font-medium">Step 03</span>
              <h2 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                Utilize the potential of your TalentPlusStrengths Assessment to
                propel yourself towards success.
              </h2>
              <p className="md:text-lg text-[#212121]">
                Keep your top 5 talents in front of you & before you undertake
                any task, think of the ways you are going to use them.
              </p>
              {/* <p className="text-2xl font-semibold text-[#3D405B] pt-2">TalentPlusStrengths  allows you to:</p> */}
              <ul className=" space-y-2 text-lg text-[#212121] font-medium ">

                <li className="flex">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Share the report with your friends and close ones and ask them
                  to reflect on it with you.
                </li>
                <li className="flex">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Share it with your manager to help them understand what makes
                  you tick and help you leverage it much more.
                </li>
                <li className="flex">
                  <span className="pr-2">
                    <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                  </span>
                  Share it with your colleagues so that they can leverage unique
                  talents which you bring on table.{" "}
                </li>
              </ul>
            </div>
            {/* img */}
            <div className="w-full">
              <img alt="detail_report" data-aos="fade-left" src="/img/Home/detail_report.webp"
                width={992}
                height={582} />
            </div>
          </section>
        </div>

        <section className="w-full px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:px-0">
          <div className="text-center">
            <p className="text-lg text-[#2196F3] font-medium uppercase tracking-widest ">
              Reports
            </p>
            <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[4rem]  capitalize font-bold text-[#212121]">
              Choose Your Report
            </h1>
            <p className="text-lg text-[#3D405B] ">
              Embrace Your Identity And Reach Your Full Potential
            </p>
          </div>
          <section className="flex flex-col-reverse items-center w-full gap-10 py-20 mx-auto xl:gap-10 lg:gap-0 lg:flex-row lg:w-5/6">

            <div
              data-aos="fade-right"
              className="grid w-full grid-cols-1 gap-10 text-left lg:grid-cols-2 aos-init aos-animate"
            >
              <div className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom cursor-pointer space-y-4   flex flex-col justify-between h-full">
                <div className="space-y-4 ">
                  <p className="text-lg text-[#212121] font-semibold">
                    Top 5 TalentPlusStrengths
                  </p>
                  <h1 className="text-2xl font-semibold text-[#2196F3]">
                    ₹1200
                  </h1>
                  <p className="pb-6 border-b border-[#2196F3]/50 text-[#212121]/80">
                    Discover your top five Talent Strengths themes (1-5) shaping your behavior. These strengths not only enhance productivity but also build your personal brand, letting you authentically present yourself and make a lasting impression.
                  </p>
                  <p className="text-lg font-semibold">Feature included:</p>
                  <ul className="space-y-2 text-[#212121]/80 ">
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  TalentPlusStrengths assessment</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Custom strengths insights</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Theme definitions</li>
                  </ul>
                </div>
                <div className="flex flex-row items-center md:justify-start md:items-center">
                  <button
                    onClick={() => this.gotoPage("products")}
                    data-aos="fade-right"
                    className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate"
                  >
                    Buy Now
                  </button>
                  <button
                    onClick={() => this.gotoPage("the-4-domains-and-22-themes")}
                    data-aos="fade-right"
                    className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"
                  >
                    <span> learn more</span>{" "}
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="text-[1.5rem]"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 6l6 6l-6 6" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom cursor-pointer space-y-4   flex flex-col justify-between h-full">
                <div className="space-y-4 ">
                  <p className="text-lg text-[#212121] font-semibold">
                    TalentPlusStrengths 22
                  </p>
                  <h1 className="text-2xl font-semibold text-[#2196F3]">
                    ₹2400
                  </h1>
                  <p className="pb-6 border-b border-[#2196F3]/50 text-[#212121]/80">
                    To understand all themes and identify your strengths, take the assessment TalentPlusStrengths 22. It ranks the 22 themes, revealing your dominant strengths. Knowing your strengths, leveraging them purposefully, acknowledging blind spots, and effectively empower it to maximize your potential.
                  </p>
                  <p className="text-lg font-semibold">Feature included:</p>
                  <ul className="space-y-2 text-[#212121]/80 ">
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  TalentPlusStrengths assessment</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Custom strengths insights</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Action items</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Your areas of excellence</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Managing your lesser strengths</li>
                    <li className="flex flex-row items-start gap-1.5">
                      <span className="pt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                        </svg>
                      </span>  Unique domain distribution</li>
                  </ul>
                </div>
                <div className="flex flex-row items-center md:justify-start md:items-center">
                  <button
                    onClick={() => this.gotoPage("products")}
                    data-aos="fade-right"
                    className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate"
                  >
                    Buy Now
                  </button>
                  <button
                    onClick={() => this.gotoPage("the-4-domains-and-22-themes")}
                    data-aos="fade-right"
                    className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"
                  >
                    <span> learn more</span>{" "}
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="text-[1.5rem]"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 6l6 6l-6 6" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </section>


        <Ready />
        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
Home.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(Home);
