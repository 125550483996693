import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import Ready from "../../../components/Ready";
import AOS from "aos";
class PrivacyandPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      formData: {
        quantity: 1,
        type: "",
        licensee: "self",
        nameList: [""],
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesList());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  changeQuantity = (type) => {
    let { formData } = this.state;
    if (type === "add") {
      formData["quantity"] += 1;
      formData["nameList"].push("");
    } else if (type === "sub" && formData["quantity"] > 1) {
      formData["quantity"] -= 1;
      formData["nameList"].pop();
    }
    this.setState({ formData });
  };

  onPurchase = (product) => {
    let { formData } = this.state;
    formData["productId"] = product.id;
    this.setState({ formData });

    if (product.code === "Top5TalentplusStrengths") {
      console.log("product--->", product);

      this.setState({ showNameModal: true });
    } else {
      this.props.history.push(
        `/assessment/productdetails/team/` + product.id.toString()
      );
    }
  };

  closeNameEntryModal = () => {
    this.setState({ showNameModal: false });
  };

  handleNameInput = (value, index) => {
    let { formData } = this.state;
    formData["nameList"][index] = value;
    this.setState({ formData });
  };

  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["licensee"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;

    if (formData["licensee"] === "self") {
      this.props.history.push(
        `/assessment/productdetails/self/` + formData["productId"].toString()
      );
    } else if (formData["licensee"] === "team") {
      this.props.history.push(
        `/assessment/productdetails/team/` + formData["productId"].toString()
      );
    }
  };

  handleSignInPage = () => {
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  handleDownload = (code) => {
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    link.click();
    console.log(" link.href link.href link.href link.href", link.href);
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentPackagesListData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    let { quantity, formData, showNameModal } = this.state;
    console.log("quantity-->", quantity);

    return (
      <>
        <HeaderTransprent
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          gotoPage={this.gotoPage}
          cartCount={
            getAssessmentCartCountData ? getAssessmentCartCountData : 0
          }
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        <section className="px-4 py-20 mx-auto space-y-4 2xl:w-9/12 xl:w-11/12 xl:px-0">
          <h1 className="pb-8 text-3xl font-medium capitalize border-b border-black/20">
            Privacy policy
          </h1>

          <h2 className="pt-4 text-xl">
            SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
          </h2>

          <p>
            When you purchase something from our website store, as part of the
            buying and selling process, we collect the personal information you
            give us such as your name, address, and email address.
          </p>

          <p>
            Email marketing (if applicable): With your permission, we may send
            you emails about our existing products, new products, and other
            updates.
          </p>

          <h2 className="text-xl">SECTION 2 - CONSENT</h2>

          <p>How do you get my consent?</p>

          <p>When you pro</p>
          <p>
            vide us with personal information to complete a transaction, verify
            your credit card, place an order, we imply that you consent to our
            collecting it and using it for that specific reason only.
          </p>
          <p>
            If we ask for your personal information for a secondary reason, like
            marketing, we will either ask you directly for your expressed
            consent, or provide you with an opportunity to say no.
          </p>

          <p>How do I withdraw my consent?</p>

          <p>
            If after you opt-in, you change your mind, you may withdraw your
            consent for us to contact you, for the continued collection, use or
            disclosure of your information, at any time, by contacting us at
            connect@happyplus.in  or mailing us at:   HappyPlus Consulting Pvt.
            Ltd. Goregaon (e), Mumbai 400065
          </p>

          <h2 className="text-xl">SECTION 3 - DISCLOSURE</h2>

          <p>
            We may disclose your personal information if we are required by law
            to do so or if you violate our Terms of Service.
          </p>

          <h1 className="text-xl">SECTION 4 - PAYMENT</h1>

          <p>
            We use Razor pay for processing payments. We/Razor pay do not store
            your card data on their servers. The data is encrypted through the
            Payment Card Industry Data Security Standard (PCI-DSS) when
            processing payment. Your purchase transaction data is only used if
            it is necessary to complete your purchase transaction. After that is
            complete, your purchase transaction information is not saved.
          </p>
          <p>
            Our payment gateway adheres to the standards set by PCI-DSS as
            managed by the PCI Security Standards Council, which is a joint
            effort of brands like Visa, MasterCard, American Express and
            Discover.
          </p>
          <p>
            PCI-DSS requirements help ensure the secure handling of credit card
            information by our store and its service providers.
          </p>

          <h2 className="text-xl">SECTION 5 - THIRD-PARTY SERVICES</h2>

          <p>
            In general, the third-party providers used by us will only collect,
            use, and disclose your information to the extent necessary to allow
            them to perform the services they provide to us.
          </p>
          <p>
            However, certain third-party service providers, such as payment
            gateways and other payment transaction processors, have their own
            privacy policies in respect to the information we are required to
            provide to them for your purchase-related transactions.
          </p>
          <p>
            For these providers, we recommend that you read their privacy
            policies so you can understand the way your personal information
            will be handled by these providers.
          </p>
          <p>
            Remember that certain providers may be in or have facilities that
            are located a different jurisdiction than either you or us. So, if
            you elect to proceed with a transaction that involves the services
            of a third-party service provider, then your information may become
            subject to the laws of the jurisdiction(s) in which that service
            provider or its facilities are located.
          </p>
          <p>
            Once you leave our store’s website or are redirected to a
            third-party website or application, you are no longer governed by
            this Privacy Policy or our website’s Terms of Service.
          </p>
          <p>Links</p>

          <p>
            When you click on links on our website store, you will get redirect
            to specified pages
          </p>

          <h2 className="text-xl">SECTION 6 - SECURITY</h2>

          <p>
            To protect your personal information, we take reasonable precautions
            and follow industry best practices to make sure it is not
            inappropriately lost, misused, accessed, disclosed, altered, or
            destroyed.
          </p>

          <h2 className="text-xl">SECTION 7 - COOKIES</h2>

          <p>
            We use cookies to maintain session of your user. It is not used to
            personally identify you on other websites.
          </p>

          <h2 className="text-xl">SECTION 8 - AGE OF CONSENT</h2>

          <p>
            By using this site, you represent that you are at least the age of
            majority in your state or province of residence, or that you are the
            age of majority in your state or province of residence and you have
            given us your consent to allow any of your minor dependents to use
            this site.
          </p>

          <h2 className="text-xl">
            SECTION 9 - CHANGES TO THIS PRIVACY POLICY
          </h2>

          <p>
            We reserve the right to modify this privacy policy at any time, so
            please review it frequently. Changes and clarifications will take
            effect immediately upon their posting on the website. If we make
            material changes to this policy, we will notify you here that it has
            been updated, so that you are aware of what information we collect,
            how we use it, and under what circumstances, if any, we use and/or
            disclose it.
          </p>
          <p>
            If our website store is acquired or merged with another company,
            your information may be transferred to the new owners so that we may
            continue to sell products to you.
          </p>

          <h2 className="text-xl font-medium ">
            QUESTIONS AND CONTACT INFORMATION
          </h2>

          <p>
            If you would like to: access, correct, amend, or delete any personal
            information we have about you, register a complaint, or simply want
            more information contact at <b className="font-semibold ">connect@happyplus.in</b> or by mail at<b className="font-semibold "> HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai 400065.</b>
          </p>


        </section>

        <Ready />
        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(PrivacyandPolicy);
