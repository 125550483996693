

import React, { Component } from "react";

import { connect } from "react-redux";

import { userActions, assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import { Helmet } from "react-helmet";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.inputChangeEmail = this.inputChangeEmail.bind(this);
    this.inputChangeOTP = this.inputChangeOTP.bind(this);

    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      saveSuccess: false,
      showEmailSuccessful: true,
    };
  }

  componentDidMount() {

    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.user.userAssessmentSignInSuccess) {
    //   return {
    //     ...nextProps,
    //     showLogin: false,
    //   };
    // }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  // handleEmpInput=(e)=>{
  //   this.setState({email: e.target.value});
  // }

  inputChangeEmail(e) {
    this.setState({ email: e.target.value, failureMSG: "" });
  }

  inputChangeOTP(e) {
    this.setState({ otp_code: e.target.value, failureMSG: "" });
  }

  submitEmail = () => {
    if (this.handleValidationLogin()) {
      let { email } = this.state;
      if (email) {
        this.setState({ showEmailSuccessful: true });

        this.props.dispatch(
          userActions.userAssessmentSignIn({ email: email }, this.props)
        );
        this.setState({ failureMSG: "", showLogin: false });

        setTimeout(() => {
          this.setState({ showEmailSuccessful: false });
        }, 3000);
      }
    }
  };

  handleValidationLogin = () => {
    console.log(
      "Get otpGet otpGet otpGet otpGet otpGet otpGet otpGet otpGet otpGet otp VALIDATION:::"
    );

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    // let email = this.state;
    let failureMSG = this.state;
    let formIsValid = true;

    //email
    if (this.state.email === "") {
      formIsValid = false;
      failureMSG = "Please enter your email";
    } else {
      if (!validateEmail(this.state.email)) {
        formIsValid = false;
        failureMSG = "Please enter valid email";
      }
    }
    this.setState({ failureMSG: failureMSG });
    return formIsValid;
  };

  OTPSubmit = () => {
    const { user } = this.props;
    const { UserAssessmentEmailToken } = user;
    // console.log("UserAssessmentEmailToken: ",UserAssessmentEmailToken)
    let { otp_code } = this.state;

    let failureMSG = "";
    let formIsValid = true;

    let data = {
      token: UserAssessmentEmailToken,
      otp: otp_code,
    };
    //otp_code
    if (this.state.otp_code === "") {
      formIsValid = false;
      failureMSG = "Please enter your OTP";
    } else {
      this.setState({ failureMSG: "" });
      this.setState({ saveSuccess: true });
      // return formIsValid;
      // this.setState({ failureOTPMSG: "" });
      this.props.dispatch(
        userActions.userAssessmentValidateOtp(data, this.props)
      );
    }
    this.setState({ failureMSG: failureMSG });

    setTimeout(() => {
      this.setState({ saveSuccess: false });
    }, 4000);
  };

  reSendOtp = () => {
    if (this.handleValidationLogin()) {
      let { email } = this.state;
      if (email) {
        this.setState({ showEmailSuccessful: true });

        this.props.dispatch(
          userActions.userAssessmentSignIn({ email: email }, this.props)
        );

        setTimeout(() => {
          this.setState({ showEmailSuccessful: false });
        }, 3000);

        this.setState({ failureMSG: "" });
        this.setState({ saveSuccess: true });
      }
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitEmail();
    }
  };

  changeUsername = () => {
    this.setState(
      { showLogin: true, failureMSG: "", otp_code: "", email: "" },
      () => {
        window.location.reload();
      }
    );
  };

  handleRegisterPage = () => {
    this.props.history.push(`/assessment/sign-up`);
  };

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  render() {
    let { questions, user, assessment } = this.props;
    let { getAssessmentPackagesShortListData } = assessment;

    let {
      getEmployeeStatusData,
      employeeMessage,
      getSurveyDetailsBySurveyCodeData,
    } = questions;
    let { email, notFoundText, otp_code, showEmailSuccessful } = this.state;
    let {
      UserLoginErrorMSG,
      userAssessmentValidateOtpFailure,
      userAssessmentValidateOtpSuccess,
      userAssessmentSignInSuccess,
      userAssessmentSignInErrorMSG,
    } = user;

    console.log("email: ", email);

    let surveyScreenData = getSurveyDetailsBySurveyCodeData;

    let errorMsh = "";
    if (UserLoginErrorMSG) {
      errorMsh = UserLoginErrorMSG;
    }

    const isMobile = window.innerWidth <= 700;

    console.log(
      "userAssessmentValidateOtpFailure: ",
      userAssessmentValidateOtpFailure
    );

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login and Access TalentPlusStrengths  Reports</title>
          <meta name="description" content="TalentPlusStrengths: Empowering self-improvement, growth, strength, leadership, performance, and goal achievement. Unlock your potential with our comprehensive platform." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/sign-in" />
        </Helmet>



        <div className="bg-[#E3F2FD]">
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            logout={this.logout}
            gotoPage={this.gotoPage}
            isLogin={true}
            cartCount={0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
          />

          <div className="grid grid-cols-1 p-4 py-6 mx-auto 2xl:w-10/12 lg:w-11/12 lg:grid-cols-2 lg:py-10 lg:px-0 ">
            {/* right section */}
            <div className=" bg-[#2196f3] w-full h-full lg:flex hidden flex-col justify-center  items-center   p-10">
              {/* <LogoHeader surveyScreenData={surveyScreenData} template={"v4"} /> */}
              <img
                src="/img/talent_plus_assessment.svg"
                className="w-11/12 mx-auto"
              />
              {/* <p className="text-xl font-normal text-white capitalize ">
              Powered by <b className="font-semibold">Happyplus</b>
            </p> */}
            </div>

            <div className="flex items-center justify-center w-full h-full bg-white ">
              {this.state.showLogin ? (
                <>
                  <div className="w-full p-4 space-y-10 xl:px-14 md:p-6 ">
                    {/* <img
                    src="/img/talent_plus_logo.svg"
                    className="w-40 bg-cover md:w-52"
                  /> */}
                    <div className="space-y-4 text-[#3D405B] ">
                      <h1 className="text-2xl font-semibold md:text-4xl ">
                        Sign In
                      </h1>
                      <p className="text-base md:text-xl ">
                        Sign in by entering email below
                      </p>
                    </div>
                    <div className="space-y-2">
                      <label className=" font-medium text-[#3D405B]/70 text-base">
                        Email
                      </label>
                      <br />
                      <input
                        onKeyDown={this._handleKeyDown}
                        autocomplete="off"
                        type="email"
                        value={email}
                        name="email"
                        onChange={this.inputChangeEmail}
                        placeholder="Enter Email"
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                      />

                      {this.state.failureMSG ? (
                        <div className="text-sm text-red-600 invalid-feedback">
                          {this.state.failureMSG ? this.state.failureMSG : ""}
                        </div>
                      ) :

                        <>
                          {userAssessmentSignInErrorMSG ?
                            <div className="pl-2 text-sm text-red-600 invalid-feedback">
                              {userAssessmentSignInErrorMSG}
                            </div>
                            : null}
                        </>
                      }
                    </div>

                    {errorMsh ? (
                      <>
                        <p className="pt-2 text-red-500">{"Otp sent to your email address"}</p>
                        <button
                          onClick={() => this.submitEmail()}
                          className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                        >
                          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => this.submitEmail()}
                          className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                        >
                          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[50rem] group-hover:h-[50rem] opacity-10"></span>
                          Get otp
                        </button>

                        {this.state.saveSuccess ? (
                          <button
                            type="button"
                            className="w-full flex space-x-4 items-center py-4 px-4 text-base tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-circle-check"
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              {" "}
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />{" "}
                              <circle cx="12" cy="12" r="9" />{" "}
                              <path d="M9 12l2 2l4 -4" />
                            </svg>
                            <span>Your OTP has been successfully sent!</span>
                          </button>
                        ) : null}
                      </>
                    )}

                    <div
                      onClick={() => this.handleRegisterPage()}
                      className="text-[#3D405B] md:text-base text-sm"
                    >
                      Don't have an account?
                      <span className="text-[#2196F3] pl-2 cursor-pointer">Sign Up</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full p-4 space-y-10 xl:px-14 md:p-6 ">
                    {/* <img
                    src="/img/talent_plus_logo.svg"
                    className="bg-cover w-52"
                  /> */}
                    <div className="space-y-4 text-[#3D405B] ">
                      <h1 className="text-2xl font-semibold md:text-4xl ">
                        Verification
                      </h1>
                      <div className="flex flex-col items-start justify-between md:flex-row md:items-end">
                        <div className="space-y-2">
                          <p className="text-base md:text-xl ">
                            We have sent 6 digit OTP to:
                          </p>
                          <p className="text-base font-semibold md:text-xl ">
                            {this.state.email}
                          </p>
                        </div>
                        <button
                          onClick={() => this.changeUsername()}
                          className="text-lg font-medium text-[#2196f3] cursor-pointer "
                        >
                          Change
                        </button>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <label className=" font-medium text-[#3D405B]/70 text-base">
                        Verify OTP:
                      </label>{" "}
                      <br />
                      <input
                        autocomplete="off"
                        type="text"
                        value={otp_code}
                        name="otp_code"
                        onChange={this.inputChangeOTP}
                        placeholder="Enter OTP"
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                      />

                      {this.state.failureMSG ? (
                        <div className="pl-2 text-sm text-red-600 invalid-feedback">
                          {this.state.failureMSG}
                        </div>
                      ) : (
                        <>
                          {userAssessmentValidateOtpFailure ? (
                            <div className="pl-2 text-sm text-red-600 invalid-feedback">
                              {"Please enter Valid OTP"}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>

                    <div className="flex items-center justify-between ">
                      <p className="text-base md:text-xl">Didn’t receive OTP?</p>
                      <button
                        onClick={() => this.reSendOtp()}
                        className="border border-[#2196F3] text-[#2196F3] px-4 py-2 rounded "
                      >
                        Resend OTP
                      </button>
                    </div>

                    {notFoundText === "Employee not found!" ? (
                      <>
                        <p className="pt-2 text-red-500">{employeeMessage}</p>
                        <button
                          onClick={() => this.OTPSubmit()}
                          className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                        >
                          Log In
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => this.OTPSubmit()}
                          className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                        >
                          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[50rem] group-hover:h-[50rem] opacity-10"></span>
                          Verify
                        </button>

                        <div>
                          {!userAssessmentValidateOtpFailure ? (
                            <>
                              {userAssessmentValidateOtpSuccess ? (
                                <button
                                  type="button"
                                  className="w-full flex space-x-4 items-center  py-4 px-4 text-base  tracking-wide text-[#4CAF50]  rounded bg-[#4CAF5012]"
                                >
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-circle-check"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />{" "}
                                    <circle cx="12" cy="12" r="9" />
                                    <path d="M9 12l2 2l4 -4" />
                                  </svg>
                                  <span>OTP has been verified!</span>
                                </button>
                              ) : null}
                            </>
                          ) : null}

                          {userAssessmentSignInSuccess && showEmailSuccessful ? (
                            <button
                              type="button"
                              className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50]  rounded bg-[#4CAF5012]"
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-circle-check"
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                {" "}
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />{" "}
                                <circle cx="12" cy="12" r="9" />
                                <path d="M9 12l2 2l4 -4" />
                              </svg>
                              <span>OTP has been successfully sent!</span>
                            </button>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="p-4 bg-white">
            <p className="md:text-base text-sm text-[#3D405B] text-center">
              All Rights Reserved | Copyright © TalentPlusStrengths.
            </p>
          </div>
        </div>
      </>
    );
  }
}
SignIn.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(SignIn);
