import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import Footer from "./Footer"; 
import CartHeader from "../MyCart/CartHeader";
import { CONST } from "../../../_config";
import CryptoJS from 'crypto-js';
class OrderConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      preOrderId: this.props.match.params.id
    };
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  componentDidMount() {
    let tempData = {
      preOrderId: this.state.preOrderId
    }
    this.props.dispatch(assessmentActions.getAssessmentCartByPreOrderId(tempData));
    this.props.dispatch(assessmentActions.getAssessmentCartByPreOrderPriceTotal(tempData));

    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentMyCartSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.userAssessmentLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem('route')
    localStorage.setItem('route', this.props.history.location.pathname)
    this.props.history.push(`/assessment/sign-in`);
  };

  changeQuantity = (cart, type) => {
    let data = {
      id: cart.id,
      quantity: Number(cart.quantity),
    };
    if (type === "add") {
      data["quantity"] += 1;
    } else if (type === "sub" && data["quantity"] > 1) {
      data["quantity"] -= 1;
    }
    this.props.dispatch(assessmentActions.updateCartItemQuantity(data));
  };

  // AssessmentPackageBuy = () => {
  //   this.props.dispatch(
  //     assessmentActions.userAssessmentPackageBuy({}, this.props)
  //   );
  // };

  testPackageBuy = (sCode, isTest) => {
    if (isTest) {
      this.props.history.push('/assessment/order-complete/' + (sCode ? sCode : ''));
    }
  }


  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartByPreOrderIdList,
      getAssessmentCartByOrderNumber,
      getAssessmentCartByPreOrderNumberData,
      getAssessmentCartByPreOrderCodeData,
      getAssessmentCartByPreOrderPriceTotalData,
    } = assessment;

    function getDetails(cart, field, type) {
      if (type === 2) {
        return field && cart && cart[field] ? cart[field] : "NA";
      } else {
        return field && cart && cart["productId"] && cart["productId"][field]
          ? cart["productId"][field]
          : "NA";
      }
    }

    function getAmount(field) {
      return field &&
        getAssessmentCartByPreOrderPriceTotalData &&
        getAssessmentCartByPreOrderPriceTotalData[field]
        ? getAssessmentCartByPreOrderPriceTotalData[field]
        : "0";
    }
    function roundOff2(item) {
      return item ? item.toFixed(2) : 0;
    }
    function getTotalPrice(price, quantity) {
      return roundOff2(Number(price) * Number(quantity));
    }




    //U2FsdGVkX18ZUVvShFSES21qHsQEqZXMxQ9zgHy+bu0=






    let sCode = '';
    if (getAssessmentCartByPreOrderCodeData) {
      console.log("getAssessmentCartByPreOrderCodeData--->", getAssessmentCartByPreOrderCodeData)

      let msg = JSON.stringify({ "code": getAssessmentCartByPreOrderCodeData })
      console.log("msg--->", msg)

      var encrypted = CryptoJS.AES.encrypt(msg, CONST.CRYPTO_KEY).toString();
      sCode = encrypted;


      console.log("sCode///////////////////--------------------->", sCode)

      let pCode = "";
      try {

        var decrypted = CryptoJS.AES.decrypt(sCode, CONST.CRYPTO_KEY);
        pCode = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
        console.log("pCode--->", pCode)

      } catch (error) {

        console.log("error--->", error)

      }
      console.log("pCode///////////////////--------------------->", pCode)

    }



    let isTest = userAssessmentUserProfileData && userAssessmentUserProfileData.skipPay ? true : false;



    return (
      <div className="">
        {/* <Header
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
        /> */}

        <CartHeader
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          stage={2}
        />

        <section className="flex flex-col w-full gap-10 px-4 mx-auto mb-10 2xl:w-9/12 xl:w-11/12 xl:px-0 p lg:flex-row ">
          <div className="space-y-4 lg:w-[65%] w-[100%] md:py-20 pt-10">
            <div className="flex flex-col-reverse justify-between gap-4 pb-6 border-b md:flex-row">
              <div className="flex items-center space-x-4">
                <h1 className="md:text-3xl text-2xl font-medium text-[#3D405B]">
                  Order Confirmation
                </h1>{" "}
                <span className="md:text-base text-sm text-[#3D405B]/70">
                  (
                  {getAssessmentCartByPreOrderIdList &&
                    getAssessmentCartByPreOrderIdList.length > 0
                    ? getAssessmentCartByPreOrderIdList.length
                    : 0}{" "}
                  items)
                </span>
              </div>
              <div
                className="flex  text-[#1976D2] cursor-pointer"
                onClick={() =>
                  this.props.history.push(`/assessment/` + "my-cart")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-pencil"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                  <path d="M13.5 6.5l4 4"></path>
                </svg>
                <p className="pl-2 pb-0.5 border-b text-[#1976D2] border-[#1976D2]">
                  <p>Edit Cart</p>
                </p>
              </div>
            </div>

            <div className="divide-y ">
              {getAssessmentCartByPreOrderIdList &&
                getAssessmentCartByPreOrderIdList.length > 0
                ? getAssessmentCartByPreOrderIdList.map((cart) => (
                  <>
                    <div className="flex flex-row items-start gap-4 py-6 md:gap-6 md:items-center md:py-8 ">
                      <div className="flex items-center w-20 h-20 cursor-pointer md:w-40 md:h-40">
                        <img
                          src="/img/assessment/Rectangle.png"
                          className="w-20 h-20 md:w-40 md:h-40 overflow-cover"
                        />{" "}
                      </div>
                      <div className="flex-1 w-full space-y-4 md:space-y-6 ">
                        <div className="text-[#3D405B] flex 2xl:flex-row flex-col md:gap-4 gap-2 justify-between w-full ">
                          <h1 className="text-lg 2xl:text-2xl md:text-xl">
                            {getDetails(cart, "name")}
                          </h1>
                          <h1 className="text-lg font-semibold 2xl:text-2xl md:text-xl">
                            <span className="font-[roboto]">  ₹</span>
                            {getTotalPrice(
                              getDetails(cart, "price"),
                              getDetails(cart, "quantity", 2)
                            )}
                          </h1>
                        </div>

                        <div className="flex flex-row items-center">
                          <span className="font-medium text-[#3D405B]">Qty:</span>
                          <div className="flex items-stretch overflow-hidden rounded-md">
                            {/* <button onClick={() => this.changeQuantity(cart, "sub")} className="p-1.5 px-4 bg-gray-100 cursor-pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                              > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <line x1="5" y1="12" x2="19" y2="12" />
                              </svg>
                            </button> */}
                            <span className="p-1.5 w-8 text-center font-medium md:text-lg text-base text-[#3D405B]">
                              {getDetails(cart, "quantity", 2)}
                            </span>
                            {/* <button onClick={() => this.changeQuantity(cart, "add")} className="p-1.5 px-4 bg-gray-100 cursor-pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                              > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <line x1="12" y1="5" x2="12" y2="19" /> <line x1="5" y1="12" x2="19" y2="12" />
                              </svg>
                            </button> */}
                          </div>
                        </div>
                        {/* <div className="flex items-center space-x-4 capitalize md:space-x-10">
                          <span className="md:text-base text-sm text-[#3D405B] font-medium  underline cursor-pointer hover:text-[#2196F3] transition-all duration-150">
                            View items
                          </span>
                          <span className="md:text-base text-sm text-[#3D405B] font-medium  underline cursor-pointer hover:text-[#2196F3] transition-all duration-150">
                            Remove items
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </>
                ))
                : null}
            </div>
          </div>
          {/* text */}
          <div className="lg:w-[35%] w-full md:p-6 md:py-20 text-[#3D405B] space-y-6  lg:border-l">
            <div className="flex items-center justify-center p-4 space-x-4 bg-gray-100">
              <h1 className="2xl:text-2xl xl:text-xl  text-xl  text-[#3D405B]">
                Order Summary
              </h1>{" "}
              {/* <span className="xl:text-lg text-base text-[#3D405B]/70 ">
                (
                {getAssessmentCartByPreOrderIdList &&
                  getAssessmentCartByPreOrderIdList.length > 0
                  ? getAssessmentCartByPreOrderIdList.length
                  : 0}{" "}
                items)
              </span> */}
            </div>

            <div className="space-y-8 text-lg md:text-xl ">
              <div className="flex items-center justify-between capitalize">
                <p className="">Subtotal</p>
                <p className="font-medium "><span className="font-[roboto]"> ₹</span>{getAmount("sum")}</p>
              </div>
              <div className="flex items-center justify-between py-6 capitalize border-t-2 border-dashed ">
                <p className="font-bold ">Total Payable</p>
                <p className="font-bold "><span className="font-[roboto]"> ₹</span>{getAmount("total")}</p>
              </div>
            </div>

            {isTest ?
              <>
                <button
                  onClick={() => this.testPackageBuy(sCode, isTest)}
                  className="lg:relative fixed bottom-0 left-0   bg-[#4CAF50] text-[#fff] hover:bg-[#4CAF50]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                >
                  <span>Continue To Get </span>
                </button>
              </>
              :
              <>
                {getAssessmentCartByPreOrderIdList && getAssessmentCartByPreOrderIdList.length > 0 ?
                  <form method={"POST"} name={"customerData"} action={"/ccavRequestHandler"}>
                    <table width="40%" align="center" className="hidden">
                      <tbody>
                        <tr>
                          <td>Merchant Id</td>
                          <td>
                            <input className="border" type="text" name="merchant_id" id="merchant_id" value={CONST.MERCHANT_ID} />
                          </td>
                        </tr>
                        <tr>
                          <td>Order Id</td>
                          <td>
                            <input className="border" type="text" name="order_id" value={getAssessmentCartByPreOrderNumberData && getAssessmentCartByPreOrderNumberData > 0 ? getAssessmentCartByPreOrderNumberData : 0} />
                          </td>
                        </tr>
                        <tr>
                          <td>Currency</td>
                          <td>
                            <input className="border" type="text" name="currency" value={"INR"} />
                          </td>
                        </tr>
                        <tr>
                          <td>Amount</td>
                          <td>
                            <input className="border" type="text" name="amount" value={getAmount("total")} />
                          </td>
                        </tr>
                        <tr>
                          <td>Redirect URL</td>
                          <td>
                            <input className="border" type="text" name="redirect_url" value={CONST.BACKEND_HOST + '/#/assessment/order-complete/' + (sCode ? sCode : '')} />
                          </td>
                        </tr>
                        <tr>
                          <td>Cancel URL</td>
                          <td>
                            <input className="border" type="text" name="cancel_url" value={CONST.BACKEND_HOST + '/#/assessment/order-cancelled'} />
                          </td>
                        </tr>
                        <tr>
                          <td>Language</td>
                          <td>
                            <input className="border" type="text" name="language" id="language" value={"EN"} />
                          </td>
                        </tr>
                        <tr>
                          <td>Customer Id:</td>
                          <td>
                            <input className="border" type="text" name="customer_identifier" value={userAssessmentUserProfileData && userAssessmentUserProfileData.id ? userAssessmentUserProfileData.id : ""} />
                          </td>
                        </tr>
                      </tbody>
                    </table>


                    <button
                      type="submit"
                      className="lg:relative fixed bottom-0 left-0   bg-[#4CAF50] text-[#fff] hover:bg-[#4CAF50]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                    >
                      <span>Continue To Pay</span>
                    </button>


                  </form>
                  :
                  <button
                    className="lg:relative fixed bottom-0 left-0   bg-[#4CAF50] text-[#fff] hover:bg-[#4CAF50]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                  >
                    <span>Continue To Pay</span>
                  </button>
                }
              </>
            }







            <div className="flex items-start py-6 space-x-4 capitalize justify-left ">
              <img
                src="/img/assessment/security-checked.svg"
                className="w-10 h-10"
              />
              <p className="text-base md:text-xl ">
                Safe and secure payment
              </p>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
OrderConfirm.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(OrderConfirm);
