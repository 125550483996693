import { questionsConstants } from '../_constants';
import { questionsService } from '../_services';
import { alertActions, assessmentActions } from '.';
export const questionsActions = {
    getAllQuestions,
    getQuestionList,
    handleNameQuestion,
    handleNameOption,
    handleSubmitPreChange,
    handleRecordResTime,
    handleSubmitFeedbackChange,
    saveAnswers,
    emptyReducer,

    getLanguageLabel,
    getSurveyTemplateByCode,
    getEmployeeStatus,
    getSurveyDetailsBySurveyCode,
    getRespondantName,

    getAssessmenrEmployeeDetails,
    getAssessmentSurveyTemplateByCode,
    getAssessmentQuestionList,
    saveAssessmentAnswers,
    assessmentLogout,
    assessmentSurveyLogout,

    getAssessmentReport,
    getAssessmentReportRTL,
    getAssessmentReportAdmin,
    getAssessmentStatus,
    getTranslateElementAll,
    getAssessmenrEmployeeProduct,
    updateAssessmentUserDetails,
    getSurveyTakenDate,
    getSurveyTakenDateAdmin



};


function assessmentSurveyLogout(props) {

    localStorage.removeItem('surveyuser')
    // props.history.push(`/assessment/login`);
    props.history.push(`/assessment/surveylogin`);

}
function assessmentLogout(props) {

    localStorage.removeItem('adminuser')
    // props.history.push(`/assessment/login`);
    props.history.push(`/assessment/sign-in`);

}


function getAssessmenrEmployeeDetails(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getAssessmenrEmployeeDetails(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ASSESSMENT_EMPLOYEE_DETAILS_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ASSESSMENT_EMPLOYEE_DETAILS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ASSESSMENT_EMPLOYEE_DETAILS_FAILURE, error } }
}


function getTranslateElementAll(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getTranslateElementAll(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_TRANSLATE_ELEMENT_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_TRANSLATE_ELEMENT_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_TRANSLATE_ELEMENT_FAILURE, error } }
}


function getAllQuestions(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getAllQuestions(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}



function getAssessmentReport(data) {
    let obj = {
        getAssessmentReportByEmpIDData:[]
    }

    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        questionsService.getAssessmentReport(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ACCESSMENT_REPORT_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ACCESSMENT_REPORT_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ACCESSMENT_REPORT_FAILURE, error } }
}


function getAssessmentReportRTL(data) {
    let obj = {
        getAssessmentReportRTLData:[]
    }

    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        questionsService.getAssessmentReportRTL(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ACCESSMENT_REPORT_RTL_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ACCESSMENT_REPORT_RTL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ACCESSMENT_REPORT_RTL_FAILURE, error } }
}



function getAssessmentReportAdmin(data) {
    let obj = {
        getAssessmentReportByEmpIDData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        questionsService.getAssessmentReportAdmin(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ACCESSMENT_REPORT_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ACCESSMENT_REPORT_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ACCESSMENT_REPORT_FAILURE, error } }
}


function getAssessmentStatus(data) {
    let obj = {
        getAssessmentStatusData:[]
    }

    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        questionsService.getAssessmentStatus(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ACCESSMENT_STATUS_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ACCESSMENT_STATUS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ACCESSMENT_STATUS_FAILURE, error } }
}


function getQuestionList(data,Template) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getQuestionList(data, Template)
            .then(
                questions => {
                    // console.log("answers_submission list 2", QuesSub)
                    dispatch(success(questions));
                },
                error => {
                    // props.history.push(`/done/${data.code}`)
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}


function getAssessmentQuestionList(data,Template) {
    let obj = {
        questionsList: [],
        answersList: [],
        surveyScreenData: {},
        surveyStatus: {},
        langList:[],
        GroupQuestionOnlyList: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        questionsService.getAssessmentQuestionList(data, Template)
            .then(
                questions => {
                    // console.log("answers_submission list 2", QuesSub)
                    dispatch(success(questions));
                },
                error => {
                    // props.history.push(`/done/${data.code}`)
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}



function handleNameQuestion(questionList, question, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === question.id);
    questionList[currentQuestionIndex]['name'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameOption(questionList, question, option, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === question.id);
    let currentOptionIndex = questionList[currentQuestionIndex]['options'].findIndex(x=>x._id === option._id);
    questionList[currentQuestionIndex]['options'][currentOptionIndex]['name'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}





function handleSubmitPreChange(currQues, answersList,questionsList, value, weightage ,surveyScreenData, subQues, GroupQuestionOnlyList,langList) {
    let questionObj={};
    if(currQues.templateId===2){
        
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        let valueName = value && value.name?value.name:"";
        let valueId = value && value.id?value.id:"";

        if(valueName){
            if(currIndex !==-1){
            let getIndex = answersList[currIndex]['multiAnswer'].findIndex(prev=>prev.optionId===valueId);
            if(getIndex===-1){
                answersList[currIndex]['multiAnswer'].push({
                    "optionId":valueId,
                    "optionAns":valueName,
                });
            }
            else{
                answersList[currIndex]['multiAnswer'].splice(getIndex,1);
            }
            }

            // if(answersList && answersList[currIndex] && answersList[currIndex]['multiAnswer'] && answersList[currIndex]['multiAnswer'].length>0){
            //     answersList[currIndex]['answer'] = JSON.stringify(answersList[currIndex]['multiAnswer']);
            // }
            // else{
            //     answersList[currIndex]['answer'] = "";
            // }

            questionObj = {
                questionsList:questionsList,
                answersList:answersList,
                surveyScreenData:surveyScreenData,
                GroupQuestionOnlyList:GroupQuestionOnlyList,
                langList:langList
            }
        }



    }
    else if(currQues.templateId===3){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        if(currIndex !==-1){
        answersList[currIndex]['questionOptionId'] = "";
        answersList[currIndex]['answer'] = value.name;
        }
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList,
            langList:langList
        }
    
    }
    else if(currQues.templateId===4){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        if(currIndex !==-1){
        answersList[currIndex]['questionOptionId'] = "";
        if(value.ans===1){
            answersList[currIndex]['answer1'] = value.name;
        }
        else if(value.ans===2){
            answersList[currIndex]['answer2'] = value.name;
        }

        answersList[currIndex]['answer'] = answersList[currIndex]['answer1'];

            if(!answersList[currIndex]['answer1'] && !answersList[currIndex]['answer2']){
                answersList[currIndex]['answer'] = '';
            }
        }
            
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList,
            langList:langList
        }
    }
    else if(currQues.templateId===5){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        if(currIndex !==-1){
        answersList[currIndex]['answer'] = subQues.toString();
        }
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList,
            langList:langList
        }
    }
    else if(currQues.templateId===16 || currQues.templateId===6 || currQues.templateId===7 || currQues.templateId===8){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        if(currIndex !==-1){
        answersList[currIndex]['answer'] = value.name;
        console.log("value.name--->",value.name)
        }
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList,
            langList:langList
        }
    }
    else if(currQues.templateId===13){

            let currIndex4 = answersList.findIndex(element=>element.questionId === subQues.id);

            // let textAnswer = value.heading + " " +value.name;

            // answersList[currIndex4]['answer'] = textAnswer;  
            if(currIndex4 !==-1){
                let getIndex1 = answersList[currIndex4]['multiAnswer'].findIndex(prev=>prev.optionHead===value.heading)
                if(getIndex1===-1){
                    answersList[currIndex4]['multiAnswer'].push({
                        optionAns:value.name,
                        optionHead:value.heading,
                        optionId:value.id
                    });
                }
                else{
                    answersList[currIndex4]['multiAnswer'][getIndex1]["optionId"] = value.id;
                    answersList[currIndex4]['multiAnswer'][getIndex1]["optionAns"] = value.name;
                    answersList[currIndex4]['multiAnswer'][getIndex1]["optionHead"] = value.heading;
                }
            }

            // if(answersList && answersList[currIndex4] && answersList[currIndex4]['multiAnswer'] && answersList[currIndex4]['multiAnswer'].length>0){
            //     answersList[currIndex4]['answer'] = answersList[currIndex4]['multiAnswer'].map((item,index)=>{
            //         return ( "ans"+(index+1).toString()+": " + item.optionHead+" "+item.optionAns)
            //     }).join(" , ")
            // }



        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList,
            langList:langList
        }
    }
    else{
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        if(currIndex !==-1){
            answersList[currIndex]['questionOptionId'] = value.id;
            answersList[currIndex]['answer'] = value.name;
            answersList[currIndex]['feedback'] = "";
        }


        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList,
            langList:langList
        }
    }
   
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}




function handleRecordResTime(resTime, currQues, answersList,questionsList,surveyScreenData, GroupQuestionOnlyList) {

    let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
    if(currIndex !==-1){
        answersList[currIndex]['milliseconds'] = resTime;
    }

    let questionObj = {
        questionsList:questionsList,
        answersList:answersList,
        surveyScreenData:surveyScreenData,
        GroupQuestionOnlyList:GroupQuestionOnlyList
    }
   
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}





function handleSubmitFeedbackChange(currQues, answersList,questionsList, value, weightage ,surveyScreenData, subQues, GroupQuestionOnlyList) {
    let questionObj={};
    
    let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        if(currIndex !==-1){
            answersList[currIndex]['feedback'] = value.name;
        }


    questionObj = {
        questionsList:questionsList,
        answersList:answersList,
        surveyScreenData:surveyScreenData,
        GroupQuestionOnlyList:GroupQuestionOnlyList
    }
   
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}


// IPv4: "43.228.140.212"
// city: "Delhi"
// country_code: "IN"
// country_name: "India"
// latitude: 28.6667
// longitude: 77.2167
// postal: "110007"
// state: "National Capital Territory of Delhi"

function saveAnswers(answersList, survey, props, ipObj, isAssessment) {
    // console.log("surveyData: ", answersList)

    let ansData={
        code: survey.code,
        answers: []
    }

    if(ipObj){
        ansData["ip"] = ipObj && ipObj.IPv4?ipObj.IPv4:"";
        ansData["region"] = ipObj && ipObj.state?ipObj.state:"";
        ansData["browser"] = ipObj && ipObj.browser?ipObj.browser:"";
    }
    console.log('ipObj',ipObj)
    answersList.forEach((surveyData)=>{

        let tempAnswer = {
            questionId:surveyData.questionId,
            questionOptionId:surveyData.questionOptionId,
            answer:isAssessment?(surveyData.answer?surveyData.answer:"0"):surveyData.answer,
            milliseconds:surveyData && surveyData.milliseconds?surveyData.milliseconds:0,
            feedback:surveyData && surveyData.feedback?surveyData.feedback:""
        }
        if(surveyData && surveyData.multiAnswer && surveyData.multiAnswer.length>0){
            tempAnswer['multiAnswer'] = surveyData.multiAnswer
        }

        ansData.answers.push(tempAnswer)
    })
    console.log("Final Answers: ", ansData)

             return dispatch => {
            dispatch(request());
            // dispatch(success2({ questionsList: null }));
            questionsService.saveAnswers(ansData)
                .then(
                    questions => {
                        dispatch(success(questions));
                        localStorage.removeItem('myAnswers');
                        // dispatch(alertActions.success("Answer Submit successfully."));
                        // props.history.push(`/app/thankyou/${ansData.code}`)
                    },
                    error => {
                        dispatch(alertActions.error(error));
                        // dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: questionsConstants.ANSWERS_SUBMIT_REQUEST } }
        function success(questions) { return { type: questionsConstants.ANSWERS_SUBMIT_SUCCESS, questions } }
        function failure(error) { return { type: questionsConstants.ANSWERS_SUBMIT_FAILURE, error } }

        function success2(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
}

function saveAssessmentAnswers(answersList, surveyId, props, ipObj, isAssessment) {
    // console.log("surveyData: ", answersList)

    let ansData={
        surveyId: surveyId,
        answers: []
    }

    if(ipObj){
        ansData["ip"] = ipObj && ipObj.IPv4?ipObj.IPv4:"";
        ansData["region"] = ipObj && ipObj.state?ipObj.state:"";
        ansData["browser"] = ipObj && ipObj.browser?ipObj.browser:"";
    }
    console.log('ipObj',ipObj)
    answersList.forEach((surveyData)=>{

        let tempAnswer = {
            questionId:surveyData.questionId,
            questionOptionId:surveyData.questionOptionId,
            answer:isAssessment?(surveyData.answer?surveyData.answer:"0"):surveyData.answer,
            milliseconds:surveyData && surveyData.milliseconds?surveyData.milliseconds:0,
            feedback:surveyData && surveyData.feedback?surveyData.feedback:""
        }
        if(surveyData && surveyData.multiAnswer && surveyData.multiAnswer.length>0){
            tempAnswer['multiAnswer'] = surveyData.multiAnswer
        }

        ansData.answers.push(tempAnswer)
    })
    console.log("Final Answers: ", ansData)

             return dispatch => {
            dispatch(request());
            // dispatch(success2({ questionsList: null }));
            questionsService.saveAssessmentAnswers(ansData)
                .then(
                    questions => {
                        localStorage.removeItem('myAnswers');
                        // dispatch(alertActions.success("Answer Submit successfully."));
                        // props.history.push(`/app/thankyou/${ansData.code}`)
                        dispatch(success(questions));

                        // setTimeout(()=>{
                        //     window.location.reload();
                        //     dispatch(assessmentActions.getTestModule({}))
                        // },3000)
                    },
                    error => {
                        dispatch(alertActions.error(error));
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: questionsConstants.ANSWERS_ASSESSMENT_SUBMIT_REQUEST } }
        function success(questions) { return { type: questionsConstants.ANSWERS_ASSESSMENT_SUBMIT_SUCCESS, questions } }
        function failure(error) { return { type: questionsConstants.ANSWERS_ASSESSMENT_SUBMIT_FAILURE, error } }

        function success2(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
}

function emptyReducer() {

    let questionObj = {
        questionsList:[],
        answersList:[],
        surveyScreenData:[],
        surveyStatus:[]
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.EMPTY_REDUCER, questions } }

}



function getLanguageLabel(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getLanguageLabel(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_LANGUAGE_LABEL_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_LANGUAGE_LABEL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_LANGUAGE_LABEL_FAILURE, error } }
}



function getSurveyTemplateByCode(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getSurveyTemplateByCode(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_SURVEY_TEMPLATE_CODE_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_SURVEY_TEMPLATE_CODE_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_SURVEY_TEMPLATE_CODE_FAILURE, error } }
}


function getAssessmentSurveyTemplateByCode(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getAssessmentSurveyTemplateByCode(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_FAILURE, error } }
}


function getEmployeeStatus(props){
    return dispatch => {
        dispatch(request());
        questionsService.getEmployeeStatus()
            .then(
                questions => {
                    dispatch(success(questions));
                    // if(questions && questions.getEmployeeStatusData && questions.getEmployeeStatusData.code){
                    //     props.history.push(`/survey/`+questions.getEmployeeStatusData.code);
                    // }
                },
                error => {
                    dispatch(failure(error));

                }
            );
    };
    function request() { return { type: questionsConstants.GET_EMPLOYEE_STATUS_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_EMPLOYEE_STATUS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_EMPLOYEE_STATUS_FAILURE, error } }
}


function getSurveyDetailsBySurveyCode(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getSurveyDetailsBySurveyCode(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_SURVEY_TEMPLATE_SURVEY_CODE_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_SURVEY_TEMPLATE_SURVEY_CODE_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_SURVEY_TEMPLATE_SURVEY_CODE_FAILURE, error } }
}


function getRespondantName(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getRespondantName(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_RESPONDANT_NAME_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_RESPONDANT_NAME_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_RESPONDANT_NAME_FAILURE, error } }
}



function getAssessmenrEmployeeProduct(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getAssessmenrEmployeeProduct(data)
            .then(
                questions =>{
                    dispatch(success(questions));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ASSESSMENT_EMP_SHORT_PRODUCT_REQUEST } }
    function success(questions) { return { type: questionsConstants.ASSESSMENT_EMP_SHORT_PRODUCT_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ASSESSMENT_EMP_SHORT_PRODUCT_FAILURE, error } }
}




function updateAssessmentUserDetails(data) {
    let obj = {
        updateAssessmentUserDetailsData:{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        questionsService.updateAssessmentUserDetails(data)
            .then(
                assessment =>{
                    dispatch(success(assessment));
                    dispatch(this.getAssessmenrEmployeeDetails({}));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_ASSESSMENT_DETAILS_REQUEST } }
    function success(assessment) { return { type: questionsConstants.UPDATE_ASSESSMENT_DETAILS_SUCCESS, assessment } }
    function failure(error) { return { type: questionsConstants.UPDATE_ASSESSMENT_DETAILS_FAILURE, error } }
}



function getSurveyTakenDate(data) {
    let obj = {
        getSurveyTakenDateData:{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        questionsService.getSurveyTakenDate(data)
            .then(
                assessment =>{
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_SURVEY_TAKEN_DATE_REQUEST } }
    function success(assessment) { return { type: questionsConstants.GET_SURVEY_TAKEN_DATE_SUCCESS, assessment } }
    function failure(error) { return { type: questionsConstants.GET_SURVEY_TAKEN_DATE_FAILURE, error } }
}


function getSurveyTakenDateAdmin(data) {
    let obj = {
        getSurveyTakenDateAdminData:{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        questionsService.getSurveyTakenDateAdmin(data)
            .then(
                assessment =>{
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST } }
    function success(assessment) { return { type: questionsConstants.GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS, assessment } }
    function failure(error) { return { type: questionsConstants.GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE, error } }
}