import React, { Component } from 'react';
import { connect } from 'react-redux';
import Top5Talents from '../Summary/Summary/ExpertsSummary/Top5Talents';
import Resources from '../Summary/Summary/ExpertsSummary/Resources';
import { MdClose } from "react-icons/md";

class BackSummary extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get the date in "YYYY-MM-DD" format
    this.state = {
      rowModalData: {},
      showMainPage: true,
      showFilter: false,
      showValue: '',
      showTabView: 2,
      activeTab: 1,
      isTest: true,
      crrExpertId: false,
      rating: 0,
      menu: "BookExpert",
      showGrid: true,
      isOpenTop5Model: false,
      currentDate: formattedDate,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }

  handleOpenTop5Modal = (data) => {
    console.log('open model on click to same page');
    this.setState({ isOpenTop5Model: true, rowModalData: data });
  };

  handleCloseTop5Modal = () => {
    this.setState({ isOpenTop5Model: false });
  };

  handleMenuClick = (selectedMenu) => {
    this.setState({
      menu: selectedMenu,
      menuTab: selectedMenu
    });
  };


  render() {

    let { isSchool, getTalentResourcesData, showMyExpertList, handleShowMainSummaryModal, EditDetailsFn,
      getAssessmentReportByEmpIDData, crrDetail, loading, getAssessmentDimensionsData, EditButtonFn, handleShowMainSummaryModalBack,
      topFiveTalent } = this.props;
    let { isOpenTop5Model } = this.state;

    // console.log("showTabView,-------->","showTabView")

    return (
      <div className="w-full mb-6" >
        <div className="">

          <div className="space-y-6">
            <Top5Talents
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              EditDetailsFn={EditDetailsFn}
              EditButtonFn={EditButtonFn}
              crrDetail={crrDetail}
              handleOpenTop5Modal={this.handleOpenTop5Modal}
              handleCloseTop5Modal={this.handleCloseTop5Modal}
              handleShowMainSummaryModal={handleShowMainSummaryModal}
              handleShowMainSummaryModalBack={handleShowMainSummaryModalBack}
              isSchool={isSchool}
            />
            <Resources getTalentResourcesData={getTalentResourcesData} />
          </div>


          {isOpenTop5Model && (
            <div className="fixed top-0 left-0 z-20 flex items-center justify-center w-full h-full py-10 bg-black bg-opacity-50">
              <div className="w-full max-w-lg max-h-full overflow-y-auto bg-white sm:rounded-2xl">
                <div className="w-full">
                  <div className='flex items-end justify-end mt-5 mr-5'>
                    <MdClose className='w-8 h-8 text-[#3D405B] cursor-pointer bg-gray-50 hover:bg-blue-200 rounded-full p-1' onClick={this.handleCloseTop5Modal} />
                  </div>
                  <div className="m-4 mb-10 max-w-[400px] mx-auto">
                    <p className="text-gray-600">{this.state.rowModalData ? this.state.rowModalData : "NA"}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>

    );
  }
}


function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, assessment } = state;
  return {
    loggingIn,
    users,
    dashboard,
    assessment
  };
}
export default connect(mapStateToProps)(BackSummary);