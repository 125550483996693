import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
// components
import Layout from "./Layout";
import MainPage from "../pages/Surveylink/MainPage";
import TeenCareerPage from "../pages/Surveylink/TeenCareerPage";

// pages
import Login from "../pages/Login/Login";
import Ssologin from "../pages/Ssologin/Ssologin";
import NotFoundPage from "../pages/NotFoundPage";
import AssessmentLogin from "../pages/Login/AssessmentLogin";
import AssessmentPage from "../pages/Surveylink/AssessmentPage";
import Registration from "../pages/Assessment/Registration/Registration";
import SignIn from "../pages/Assessment/Login/SignIn";
import Package from "../pages/Assessment/Package/Package";
import Orders from "../pages/Assessment/Orders/Orders";
import SurveyLogin from "../pages/Assessment/Survey/SurveyLogin";
import AssessmentLoginByOTP from "../pages/Login/AssessmentLoginByOTP";
import Home from "../pages/Assessment/Home/Home";
import Homeold from "../pages/Assessment/Home/Homeold";
import TeenTalent from "../pages/Assessment/TeenTalent/TeenTalent";
import TeenCareerGuidance from "../pages/Assessment/TeenCareerGuidance2/TeenCareerGuidance";
import TeenDiscoverYourself from "../pages/Assessment/TeenDiscoverYourself/TeenDiscoverYourself";
import TeenCareerLibraries from "../pages/Assessment/TeenCareerLibraries/TeenCareerLibraries";
import TeenPersonalizedReport from "../pages/Assessment/TeenPersonalizedReport/TeenPersonalizedReport";
import TeenCareerFitment from "../pages/Assessment/TeenCareerFitment/TeenCareerFitment";
import Employer from "../pages/Assessment/Home/Employer";
import Individual from "../pages/Assessment/Home/Individual";
import ProductDetails from "../pages/Assessment/ProductDetails/ProductDetails";
import Mycart from "../pages/Assessment/MyCart/Mycart";
import MyOrders from "../pages/Assessment/MyOrders/MyOrders.js";
import OrderReview from "../pages/Assessment/Orders/OrderReview";
import Payment from "../pages/Assessment/Payment/Payment";
import DomainTheme from "../pages/Assessment/DomainTheme/DomainTheme";
import ContactUs from "../pages/Assessment/ContactUs/ContactUs";
import StrengthWorks from "../pages/Assessment/StrengthWorks/StrengthWorks";
import EditProfile from "../pages/Assessment/MyOrders/EditProfile";
import User from "../pages/Assessment/MyOrders/User";
import AssessmentProducts from "../pages/Assessment/MyOrders/AssessmentProducts";
import AssessmentProductsNew from "../pages/Assessment/MyOrders/AssessmentProductsNew";
import Term from "../pages/Assessment/Term/Term";
import ExpertBooked from "../pages/Surveylink/QuestionV6/Summary/Summary/ExpertsSummary/ExpertBooked";
import Privacy from "../pages/Assessment/PrivacyandPolicy/Privacy";
import Emailer from "../pages/Assessment/Emailer/Emailer";
import { Helmet } from "react-helmet";
import Cancelled from "../pages/Assessment/Payment/Cancelled";
import ImpactCoach from "../pages/Assessment/ImpactCoach/ImpactCoach";
import Test from "../pages/Test";
import Temp from "../pages/Assessment/ProductDetails/Temp";
import Blog from "../pages/Assessment/Blog/Blog";
import BlogDeatil from "../pages/Assessment/Blog/BlogDeatails";
import Aboutus from "../pages/Assessment/Aboutus/Aboutus";

// import StudentSignIn from "../pages/Assessment/StudentLogin/StudentSignIn";
// import StudentRegistration from "../pages/Assessment/StudentLogin/StudentRegistration";
// import StudentDashboard from "../pages/Assessment/StudentDashboard/StudentDashboard";
// import StudentSchool from "../pages/Assessment/StudentDashboard/StudentSchool";
// import StudentAssessmentCode from "../pages/Assessment/StudentDashboard/StudentAssessmentCode";
// import StudentAssessmentCodeList from "../pages/Assessment/StudentDashboard/StudentAssessmentCodeList";
// import StudentReports from "../pages/Assessment/StudentDashboard/StudentReports";
// import StudentProfile from "../pages/Assessment/StudentDashboard/StudentProfile";
// import StudentKnowledgeHub from "../pages/Assessment/StudentDashboard/StudentKnowledgeHub";
// import StudentList from "../pages/Assessment/StudentDashboard/StudentList";
// import TeenCareerLibrariesDetails from "../pages/Assessment/TeenCareerLibrariesDetails/TeenCareerLibrariesDetails.js";


export default function App() {

  useEffect(() => {
    // Scroll to the top when the component mounts (page reloads)
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const TalentPlusSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": "https://www.talentplus.com/",
    name: "Consultant in Mumbai, Maharashtra",
    image: "https://www.talentplus.com/svg/talentplus-logo.svg",
    url: "https://www.talentplus.com/",
    review: {
      "@type": "Review",
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: "4.9",
      bestRating: "5",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "1&2, GREEN HILL-B, 303, 25/A, near NNP, Goregaon ",
      addressLocality: "Mumbai,",
      addressRegion: "Maharashtra",
      postalCode: "400065 ,",
      addressCountry: "IN",
    },
    offers: {
      "@type": "Offer",
      price: "20-25",
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
    },
    author: {
      "@type": "Person",
      name: "talentplus",
    },
  };

  return (
    <div className="font-fma">
      <Helmet>
        <script type="application/ld+json" defer>
          {JSON.stringify(TalentPlusSchema)}</script>
      </Helmet>
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/assessment/home" />} />

          <Route path="/assessment/employer" component={withRouter(Employer)} />
          <Route path="/assessment/individual" component={withRouter(Individual)} />
          <Route path="/v2/:id" component={withRouter(MainPage)} />
          <Route path="/login/:id" component={withRouter(Login)} />

          <Route path="/ssologin" component={withRouter(Ssologin)} />
          <Route path="/notfound" component={withRouter(NotFoundPage)} />
          <Route path="/assessment/login" component={withRouter(AssessmentLogin)} />
          <Route path="/assessment/signin" component={withRouter(AssessmentLoginByOTP)} />
          <Route path="/assessment/home" component={withRouter(Home)} />
          <Route path="/assessment/survey" component={withRouter(AssessmentPage)} />
          <Route path="/assessment/career" component={withRouter(TeenCareerPage)} />
          <Route path="/assessment/teen-talent" component={withRouter(TeenTalent)} />
          <Route path="/assessment/teen-career-guidance" component={withRouter(TeenCareerGuidance)} />
          <Route path="/assessment/teen-discover-yourself" component={withRouter(TeenDiscoverYourself)} />
          <Route path="/assessment/teen-career-libraries" component={withRouter(TeenCareerLibraries)} />
          {/* <Route path="/assessment/teen-career-libraries-deatils" component={withRouter(TeenCareerLibrariesDetails)} /> */}
          <Route path="/assessment/teen-personalized-report" component={withRouter(TeenPersonalizedReport)} />
          <Route path="/assessment/teen-career-fitment-analysis" component={withRouter(TeenCareerFitment)} />
          <Route path="/assessment/homeold" component={withRouter(Homeold)} />
          <Route path="/assessment/impact-coach" component={withRouter(ImpactCoach)} />
          <Route path="/assessment/sign-up" component={withRouter(Registration)} />
          <Route path="/assessment/sign-in" component={withRouter(SignIn)} />
          <Route path="/assessment/products" component={withRouter(Package)} />
          <Route path="/assessment/terms-and-conditions" component={withRouter(Term)} />
          <Route path="/assessment/privacy-policy" component={withRouter(Privacy)} />
          <Route path="/assessment/orders" component={withRouter(Orders)} />
          <Route path="/assessment/surveylogin" component={withRouter(SurveyLogin)} />
          <Route path="/assessment/my-cart" component={withRouter(Mycart)} />
          <Route path="/assessment/my-orders" component={withRouter(MyOrders)} />
          <Route path="/assessment/productdetails/:id1/:id2" component={withRouter(ProductDetails)} />
          <Route path="/assessment/temp" component={withRouter(Temp)} />
          <Route path="/assessment/order-review/:id" component={withRouter(OrderReview)} />
          <Route path="/assessment/the-4-domains-and-22-themes" component={withRouter(DomainTheme)} />
          <Route path="/assessment/contact-us" component={withRouter(ContactUs)} />
          <Route path="/assessment/order-complete/:id" component={withRouter(Payment)} />
          <Route path="/assessment/order-cancelled" component={withRouter(Cancelled)} />
          <Route path="/assessment/how-talentplus-strength-works" component={withRouter(StrengthWorks)} />
          <Route path="/assessment/profile-summary" component={withRouter(EditProfile)} />
          <Route path="/assessment/user" component={withRouter(User)} />
          <Route path="/assessment/assessment-code" component={withRouter(AssessmentProducts)} />
          <Route path="/assessment/assessment-code-new" component={withRouter(AssessmentProductsNew)} />
          <Route path="/assessment/emailer" component={withRouter(Emailer)} />
          <Route path="/assessment/impact-coach" component={withRouter(ImpactCoach)} />
          <Route path="/assessment/test" component={withRouter(Test)} />
          <Route path="/assessment/expert-booked/:id" component={withRouter(ExpertBooked)} />
          <Route path="/assessment/blog" component={withRouter(Blog)} />
          <Route path="/assessment/blogdetails/:id" component={withRouter(BlogDeatil)} />
          <Route path="/assessment/about-us" component={withRouter(Aboutus)} />


          {/* <Route path="/assessment/student-sign-in" component={withRouter(StudentSignIn)} />
          <Route path="/assessment/student-sign-up" component={withRouter(StudentRegistration)} />
          <Route path="/assessment/student-dashboard" component={withRouter(StudentDashboard)} />
          <Route path="/assessment/student-school" component={withRouter(StudentSchool)} />
          <Route path="/assessment/student-list/:id" component={withRouter(StudentList)} />
          <Route path="/assessment/student-assessment-code" component={withRouter(StudentAssessmentCode)} />
          <Route path="/assessment/student-assessment-code-list" component={withRouter(StudentAssessmentCodeList)} />
          <Route path="/assessment/student-reports" component={withRouter(StudentReports)} />
          <Route path="/assessment/student-profile" component={withRouter(StudentProfile)} />
          <Route path="/assessment/student-knowledge-hub" component={withRouter(StudentKnowledgeHub)} /> */}


          {/* <Route component={Error} /> */}
        </Switch>
      </HashRouter>
    </div>
  );
}
