import React, { Component, useState } from "react";
import CryptoJS from "crypto-js";
import { assessmentActions, questionsActions } from "../../../_actions";
import { connect } from "react-redux";
import SingleChoice from "./ComponentV6/SingleChoice";
import MultipleChoice from "./ComponentV6/MultipleChoice";
import TextBox from "./ComponentV6/TextBox";
import MultiLineText from "./ComponentV6/MultiLineText";
import RatingScale from "./ComponentV6/RatingScale";
import NPS from "./ComponentV6/NPS";
import NPSV2 from "./ComponentV6/NPSV2";
import Email from "./ComponentV6/Email";
import Number from "./ComponentV6/Number";
import Dropdown from "./ComponentV6/Dropdown";
import YesNo from "./ComponentV6/YesNo";
import Date from "./ComponentV6/Date";
import Summary from "./Summary/Summary";
import SummaryRTL from "./Summary/SummaryRTL";
import ExpertScreen from "./Summary/ExpertScreen";
import QuestionHeader from "../Component/QuestionHeader";
import LogoAssessmentHeader from "../Component/LogoAssessmentHeader";
import LogoAssessmentSummaryHeader from "../Component/LogoAssessmentSummaryHeader";
import FeedbackModal from "./FeedbackModal";
import AssessmentReportSchool from "../Component/AssessmentReportSchool/AssessmentReportSchool";
import AssessmentReport from "../Component/AssessmentReport/AssessmentReport";
import AssessmentSummary from "../Component/AssessmentSummary/AssessmentSummary";
import AssessmentTop5Summary from "../Component/AssessmentTop5Summary/AssessmentTop5Summary";
import AssessmentManagerReport from "../Component/AssessmentManagerReport/AssessmentManagerReport";
import AssessmentHiringReport from "../Component/AssessmentHiringReport/AssessmentHiringReport";
import VideoFrameModal from "./Summary/VideoFrameModal/VideoFrameModal";
import VideoCourse from "./VideoCourse/VideoCourse";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";


class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top5Talent: false,
      isDisableSubmit: false,
      SurveyCode: this.props.codeValue,
      routeMenu: "Summary",
      radioButton: false,
      radioButton2: false,
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces: false,

      surveyLanguageName: "English",
      ShowSubmitButton: false,

      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn: false,
      afterSubmitData: {
        thankyouText: "",
        bgColor: "",
        bgImage: "",
        textColor: "",
        logo: "",
      },
      alreadySubmitted: false,
      clicked: false,

      showDropdownQues: false,
      dropdownInput: "",
      scrollPosition: 0,
      IpObj: {},
      ShowNotAttempted: false,
      submitMandatory: "",
      setTimeoutRef: "",
      setTimeoutInit: 0,
      disableTimer: false,
      timeCounter: 0,
      setIntervalRef: "",
      showReportModal: false,
      showSummaryReport: false,
      showManagerReportModal: false,
      showHiringReport: false,

      showViewReportThankYouButton: false,
      surveyLanguageCode: "",

      themeType: 2,
      showNameEditScreen: false,
      // nameFormData: {
      //   'name': '',
      //   'email': ''
      // },
      // errNameForm: {
      //   'name': '',
      //   'email': ''
      // },

      nameFormData: {
        name: '',
        email: '',
        gender: '', // Add gender field to your component state
        ageGroup: '' // Add ageGroup field to your component state
      },
      errNameForm: {
        name: '',
        email: '',
        gender: '', // Add gender field to your component state
        ageGroup: '' // Add ageGroup field to your component state
      },


      backSummary: false,
      showMainSummaryModal: false,
      crrDetail: "",
      crrCodeUserDetails: {
        "employeeId": "",
        "name": "",
        "email": "",
        "mobile": "",
        "message": ""
      },
      errorsCrrCodeUserDetails: {},
      topFiveTalent: true,
      contactExpertSection: false,
      showMyExpertList: false,
      showVideoModal: '',
      showVideoResource: false,
      showMainMenu: "Summary",
      downloadStatusNo: 100,

      surveyLanguageCode: 'en',
      surveyLanguageName: 'English',
      surveyLanguageRTL: false,
    };


    this.handleShowMainSummaryModal = this.handleShowMainSummaryModal.bind(this);
    this.handleShowMainSummaryModalBack = this.handleShowMainSummaryModalBack.bind(this);
    this.handleExpertContactModalBack = this.handleExpertContactModalBack.bind(this);
    this.handleExpertContactModal = this.handleExpertContactModal.bind(this);
    this.handleInputExpertForm = this.handleInputExpertForm.bind(this);
    this.bookExpert = this.bookExpert.bind(this);
    this.handleExpertClick = this.handleExpertClick.bind(this);
    this.props.dispatch(assessmentActions.getTalentResources());


  }

  componentDidMount() {
    this.props.dispatch(questionsActions.emptyReducer());
    this.props.dispatch(questionsActions.getAssessmentReport());
    this.props.dispatch(questionsActions.getAssessmenrEmployeeDetails({}));
    this.props.dispatch(questionsActions.getSurveyTakenDate({}));
    this.props.dispatch(questionsActions.getAssessmentStatus({}));
    this.props.dispatch(questionsActions.getAssessmentQuestionList({}, "v4"));
    this.props.dispatch(questionsActions.getLanguageLabel());
    this.props.dispatch(questionsActions.getTranslateElementAll());
    this.props.dispatch(questionsActions.getAssessmenrEmployeeProduct({}));


    // setTimeout(() => {
    //   if (downloadStatusNo === 0) {
    //     this.setState({ downloadStatusNo: 100 })
    //   } else {
    //     this.setState({ downloadStatusNo: 100 - 5 })
    //   }
    // }, 3000)



  }

  decrementNumber = () => {
    this.setState((prevState) => ({
      downloadStatusNo: prevState.downloadStatusNo < 1 ? 0 : prevState.downloadStatusNo - 10,
    }));
  };



  static getDerivedStateFromProps(nextProps) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted: true,
      };
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  prevQues = () => {
    this.setState({ changeType: "fade1" });
    this.setState({ ShowSubmitButton: false });
    this.setState({ submitMandatory: "" });
    let { questions } = this.props;
    let { questionsList } = questions;
    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex > 1 && CurrQuesIndex < questionsList.length + 1) {
        CurrQuesIndex = CurrQuesIndex - 1;

        this.setState({ CurrQuesIndex }, () => {
          // this.handleSubmitAppear();
        });
      } else {
        this.setState({
          showWelcomeScreen: false,
          showQuestionScreen: false,
          showThankyouScreen: false,
          instructionPage: true,
        });
      }
    }

    // console.log("answersList: ", answersList)
  };

  nextQues = (type2) => {
    console.log("Next Question!");
    clearTimeout(this.state.setTimeoutRef);
    clearInterval(this.state.setIntervalRef);
    window.scrollTo(0, 0);
    this.setState({ changeType: "fade", clicked: "" }, () => {
      let { questions } = this.props;
      let { questionsList, surveyScreenData, GroupQuestionOnlyList } =
        questions;
      let { CurrQuesIndex } = this.state;
      let itemQ =
        questionsList && questionsList.length > 0
          ? questionsList[CurrQuesIndex]
          : {};
      //---------------------------- basic ---------------------------------------
      let answersList = [];
      let AnswerListFinal =
        answersList && questions.answersList && questions.answersList.length > 0
          ? questions.answersList
          : [];

      if (questionsList && questionsList.length > 0) {
        if (CurrQuesIndex < questions.questionsList.length) {
          this.props.dispatch(
            questionsActions.handleRecordResTime(
              0,
              itemQ,
              AnswerListFinal,
              questionsList,
              surveyScreenData,
              GroupQuestionOnlyList
            )
          );
        }

        questionsList.forEach((ques) => {
          if (ques.templateId === 13) {
            let getIndex = AnswerListFinal.findIndex(
              (prev) => prev.questionId === ques.id
            );
            if (getIndex !== -1) {
              answersList.push({
                ...AnswerListFinal[getIndex],
                SubQuestionList: ques.SubQuestionList,
              });
            }
          } else {
            let getIndex = AnswerListFinal.findIndex(
              (prev) => prev.questionId === ques.id
            );
            if (getIndex !== -1) {
              answersList.push(AnswerListFinal[getIndex]);
            }
          }
        });
      }
      //-------------------------------------------------------------------
      let isFeedback = false;
      if (questionsList && questionsList.length > 0) {
        if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
          //----------------latestcode ---------------------------
          //console.log("11111111111------>", CurrQuesIndex);

          let questionId =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1].id
              : "";
          let questionData =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1]
              : {};
          let flag = 0;

          if (answersList && answersList.length > 0) {
            let getIndex2 = answersList.findIndex(
              (prev) => prev.questionId === questionId
            );
            if (getIndex2 !== -1) {
              let item = answersList[getIndex2];

              //New added feedback
              if (
                item.answer &&
                questionData &&
                questionData.optionsList &&
                questionData.optionsList.length > 0
              ) {
                let optionList = questionData.optionsList;

                let getIndex5 = optionList.findIndex(
                  (prev) => prev.name === item.answer
                );
                if (getIndex5 !== -1) {
                  isFeedback = optionList[getIndex5]["isFeedback"]
                    ? true
                    : false;
                }
              }

              if (item.isMandatory) {
                let template = item.template;

                if (
                  template === 1 ||
                  template === 3 ||
                  template === 4 ||
                  template === 5 ||
                  template === 6 ||
                  template === 16 ||
                  template === 11
                ) {
                  if (!item.answer) {
                    flag = flag + 1;
                  }
                } else if (template === 13) {
                  let isAllAnswered = 0;
                  if (
                    item &&
                    item.SubQuestionList &&
                    item.SubQuestionList.length > 0
                  ) {
                    item.SubQuestionList.forEach((subQues) => {
                      let getIndex2 = AnswerListFinal.findIndex(
                        (prev) => prev.questionId === subQues.id
                      );

                      if (getIndex2 !== -1) {
                        if (
                          AnswerListFinal &&
                          AnswerListFinal[getIndex2] &&
                          AnswerListFinal[getIndex2].multiAnswer &&
                          AnswerListFinal[getIndex2].multiAnswer.length > 1
                        ) {
                          isAllAnswered++;
                        }
                      }
                    });
                  }

                  if (item.SubQuestionList.length !== isAllAnswered) {
                    flag = flag + 1;
                  }
                } else if (template === 2) {
                  if (
                    !(item && item.multiAnswer && item.multiAnswer.length > 0)
                  ) {
                    flag = flag + 1;
                  }
                } else {
                  if (!item.answer) {
                    flag = flag + 1;
                  }
                }
              }
            }
          }

          if (!flag) {
            try {
              let cryptedText = CryptoJS.AES.encrypt(
                JSON.stringify(AnswerListFinal),
                "secretkey1345100237401"
              ).toString();
              localStorage.setItem("myAnswers", JSON.stringify(cryptedText));
            } catch (error) {
              console.log("not saved!");
            }

            //console.log("isFeedback----------->", isFeedback);

            if (isFeedback && type2 !== 2) {
              this.setState({ showFeedbackModal: true });
            } else {
              CurrQuesIndex = CurrQuesIndex + 1;

              this.setState({ CurrQuesIndex }, () => {
                this.setState({
                  ShowNotAttempted: false,
                  showFeedbackModal: false,
                });

                this.handleSubmitAppear();
              });
            }
          } else {
            this.setState({ ShowNotAttempted: true });
          }

          // console.log("CurrQuesIndex------>", CurrQuesIndex);
          // console.log("questionsList.length------>", questionsList.length);
          // console.log("itemQ------>", itemQ);

          if (itemQ && itemQ.seconds && !this.state.disableTimer) {
            if (CurrQuesIndex < questionsList.length) {
              let setTimeoutRef = setTimeout(
                () => this.nextQues(),
                itemQ.seconds * 1000
              );
              this.setState(
                {
                  timeCounter: itemQ.seconds,
                  setTimeoutRef: setTimeoutRef,
                  setTimeoutInit: moment().unix(),
                },
                () => {
                  let setIntervalRef = setInterval(
                    () =>
                      this.setState({
                        timeCounter:
                          this.state.timeCounter > 0
                            ? this.state.timeCounter - 1
                            : 0,
                      }),
                    1000
                  );
                  this.setState({ setIntervalRef: setIntervalRef });
                }
              );
            } else {
              let setTimeoutRef = setTimeout(
                () => this.saveAnswers(),
                itemQ.seconds * 1000
              );
              this.setState(
                {
                  timeCounter: itemQ.seconds,
                  setTimeoutRef: setTimeoutRef,
                  setTimeoutInit: moment().unix(),
                },
                () => {
                  let setIntervalRef = setInterval(
                    () =>
                      this.setState({
                        timeCounter:
                          this.state.timeCounter > 0
                            ? this.state.timeCounter - 1
                            : 0,
                      }),
                    1000
                  );
                  this.setState({ setIntervalRef: setIntervalRef });
                }
              );
            }
          }
        } else if (CurrQuesIndex === questionsList.length) {
          //console.log("222222222------>", CurrQuesIndex);

          let questionId =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1].id
              : "";
          let questionData =
            questionsList &&
              questionsList[CurrQuesIndex - 1] &&
              questionsList[CurrQuesIndex - 1].id
              ? questionsList[CurrQuesIndex - 1]
              : {};

          if (answersList && answersList.length > 0) {
            let getIndex2 = answersList.findIndex(
              (prev) => prev.questionId === questionId
            );
            if (getIndex2 !== -1) {
              let item = answersList[getIndex2];

              //New added feedback
              if (
                item.answer &&
                questionData &&
                questionData.optionsList &&
                questionData.optionsList.length > 0
              ) {
                let optionList = questionData.optionsList;

                let getIndex5 = optionList.findIndex(
                  (prev) => prev.name === item.answer
                );
                if (getIndex5 !== -1) {
                  isFeedback = optionList[getIndex5]["isFeedback"]
                    ? true
                    : false;
                }
              }
            }
          }

          if (isFeedback && type2 !== 2) {
            this.setState({ showFeedbackModal: true });
          }
        }
      }
    });
  };

  submitFeedback = () => {
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;

    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex === questionsList.length) {
        this.setState({ showFeedbackModal: false }, () => {
          this.handleSubmitAppear();
        });
      } else {
        CurrQuesIndex = CurrQuesIndex + 1;
        this.setState({ CurrQuesIndex }, () => {
          this.setState({ ShowNotAttempted: false, showFeedbackModal: false });
          this.handleSubmitAppear();
        });
      }
    }
  };

  closeFeedbackModal = () => {
    this.setState({ showFeedbackModal: false });
  };

  handleSubmitAppear = () => {
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;
    setTimeout(() => {
      if (
        questionsList &&
        questionsList.length > 0 &&
        questionsList.length === CurrQuesIndex
      ) {
        this.setState({ ShowSubmitButton: true });
      } else {
        this.setState({ ShowSubmitButton: false });
      }
    }, 300);
  };

  handleRadio = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
      langList
    } = questions;

    let { CurrQuesIndex } = this.state;

    if (this.state.radioButton === currentOption.id) {
      this.setState({ radioButton: null });
      this.props.dispatch(
        questionsActions.handleSubmitPreChange(
          ques,
          answersList,
          questionsList,
          "",
          0,
          surveyScreenData,
          "",
          GroupQuestionOnlyList,
          langList
        )
      );
    } else {
      // console.log("currentOption----------------------IIIIIIIIIIIIIIIIIIIIIIIII--------------------->",currentOption)
      this.setState({ radioButton: currentOption.id });
      this.props.dispatch(
        questionsActions.handleSubmitPreChange(
          ques,
          answersList,
          questionsList,
          currentOption,
          currentOption.weightage,
          surveyScreenData,
          Index,
          GroupQuestionOnlyList,
          langList
        )
      );
    }

    this.setState({ radioButton: null });

    // if(!ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    // setTimeout(()=>this.checkFeedback(), 300);

    let resTime = 0;
    if (this.state.setTimeoutInit) {
      const then = moment.unix(this.state.setTimeoutInit);
      resTime = moment().diff(then, "milliseconds");
    } else {
      resTime = 0;
    }

    this.props.dispatch(
      questionsActions.handleRecordResTime(
        resTime,
        ques,
        answersList,
        questionsList,
        surveyScreenData,
        GroupQuestionOnlyList
      )
    );

    // console.log("-----questionsListlength---->", questionsList.length);
    // console.log("-----CurrQuesIndex---->", CurrQuesIndex);

    if (questionsList && questionsList.length > 0 && !this.state.disableTimer) {
      if (questionsList.length - 1 >= CurrQuesIndex) {
        clearTimeout(this.state.setTimeoutRef);
        clearInterval(this.state.setIntervalRef);
      }

      if (CurrQuesIndex === questionsList.length) {
        this.saveAnswers();
      } else if (CurrQuesIndex <= questionsList.length - 1) {
        clearTimeout(this.state.setTimeoutRef);
        clearInterval(this.state.setIntervalRef);
      }
    }

    setTimeout(() => this.nextQues(), 300);
  };

  handleCheckInput = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    if (true) {
      this.setState({ radioButton: currentOption.id });
      // console.log("currentOption::", currentOption)
      this.props.dispatch(
        questionsActions.handleSubmitPreChange(
          ques,
          answersList,
          questionsList,
          currentOption,
          currentOption.weightage,
          surveyScreenData,
          "",
          GroupQuestionOnlyList
        )
      );
    }
    // setTimeout(()=>this.nextQues(), 300);
  };

  goToInstructionPage = () => {
    let { questions } = this.props;
    let { surveyScreenData } = questions;

    if (surveyScreenData.isInstruction) {
      this.setState({
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: true,
        showNameEditScreen: false
      });
    } else {
      let { questionsList } = questions;
      let itemQ =
        questionsList && questionsList.length > 0 ? questionsList[0] : {};
      //console.log("itemQ--01-->", itemQ);

      if (itemQ && itemQ.seconds && !this.state.disableTimer) {
        //console.log("Seconds--->", itemQ.seconds * 1000);
        let setTimeoutRef = setTimeout(
          () => this.nextQues(),
          itemQ.seconds * 1000
        );
        this.setState(
          {
            timeCounter: itemQ.seconds,
            setTimeoutRef: setTimeoutRef,
            setTimeoutInit: moment().unix(),
          },
          () => {
            let setIntervalRef = setInterval(
              () =>
                this.setState({
                  timeCounter:
                    this.state.timeCounter > 0 ? this.state.timeCounter - 1 : 0,
                }),
              1000
            );
            this.setState({ setIntervalRef: setIntervalRef });
          }
        );
      }

      this.setState({
        showWelcomeScreen: false,
        showQuestionScreen: true,
        showThankyouScreen: false,
        instructionPage: false,
        showNameEditScreen: false
      });
    }
    window.scrollTo(0, 0);
  };

  goToQuestionPage = () => {
    let { questions } = this.props;
    let { questionsList } = questions;
    let itemQ =
      questionsList && questionsList.length > 0 ? questionsList[0] : {};
    //console.log("itemQ--02-->", itemQ);

    if (itemQ && itemQ.seconds && !this.state.disableTimer) {
      //console.log("Seconds--->", itemQ.seconds * 1000);
      let setTimeoutRef = setTimeout(
        () => this.nextQues(),
        itemQ.seconds * 1000
      );
      this.setState(
        {
          timeCounter: itemQ.seconds,
          setTimeoutRef: setTimeoutRef,
          setTimeoutInit: moment().unix(),
        },
        () => {
          let setIntervalRef = setInterval(
            () =>
              this.setState({
                timeCounter:
                  this.state.timeCounter > 0 ? this.state.timeCounter - 1 : 0,
              }),
            1000
          );
          this.setState({ setIntervalRef: setIntervalRef });
        }
      );
    }

    this.setState({
      showWelcomeScreen: false,
      showQuestionScreen: true,
      showThankyouScreen: false,
      instructionPage: false,
    });
    window.scrollTo(0, 0);
  };

  saveAnswers() {
    let { questions } = this.props;
    let { surveyScreenData } = questions;

    if (surveyScreenData) {
      this.setState({
        afterSubmitData: surveyScreenData,
      });
    }

    let survey = null;

    //---------------------- new code ------------------------------------------

    let { questionsList } = questions;

    //---------------------------- basic ---------------------------------------
    let answersList = [];
    let AnswerListFinal =
      answersList && questions.answersList && questions.answersList.length > 0
        ? questions.answersList
        : [];

    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {
        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList,
            });
          }
        } else {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }
      });
    }
    //-------------------------------------------------------------------

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      let flag = 0;
      // console.log(" CurrQuesIndex=====>: ",CurrQuesIndex," questionsList.length=====>: ", questionsList.length)

      if (CurrQuesIndex > 0 && CurrQuesIndex <= questionsList.length) {
        //----------------latestcode ---------------------------

        let questionId =
          questionsList &&
            questionsList[CurrQuesIndex - 1] &&
            questionsList[CurrQuesIndex - 1].id
            ? questionsList[CurrQuesIndex - 1].id
            : "";

        if (answersList && answersList.length > 0) {
          let getIndex2 = answersList.findIndex(
            (prev) => prev.questionId === questionId
          );
          if (getIndex2 !== -1) {
            let item = answersList[getIndex2];

            if (item.isMandatory) {
              let template = item.template;

              if (
                template === 1 ||
                template === 3 ||
                template === 4 ||
                template === 5 ||
                template === 6 ||
                template === 16 ||
                template === 11
              ) {
                if (!item.answer) {
                  flag = flag + 1;
                }
                // console.log(" template: ",template," answer: ",item.answer," flag: ",flag)
              } else if (template === 13) {
                let isAllAnswered = 0;
                if (
                  item &&
                  item.SubQuestionList &&
                  item.SubQuestionList.length > 0
                ) {
                  item.SubQuestionList.forEach((subQues) => {
                    let getIndex2 = AnswerListFinal.findIndex(
                      (prev) => prev.questionId === subQues.id
                    );

                    if (getIndex2 !== -1) {
                      if (
                        AnswerListFinal &&
                        AnswerListFinal[getIndex2] &&
                        AnswerListFinal[getIndex2].multiAnswer &&
                        AnswerListFinal[getIndex2].multiAnswer.length > 1
                      ) {
                        isAllAnswered++;
                      }
                    }
                  });
                }

                if (item.SubQuestionList.length !== isAllAnswered) {
                  flag = flag + 1;
                }
              } else if (template === 2) {
                if (
                  !(item && item.multiAnswer && item.multiAnswer.length > 0)
                ) {
                  flag = flag + 1;
                }
              } else {
                if (!item.answer) {
                  flag = flag + 1;
                }
              }
            }
          }
        }

      }

      if (!flag) {
        if (!this.state.isDisableSubmit) {
          clearTimeout(this.state.setTimeoutRef);
          clearInterval(this.state.setIntervalRef);

          console.log("Answer Submitted!");



          setTimeout(() => {
            this.setState({ showViewReportThankYouButton: true });
            this.interval = setInterval(this.decrementNumber, 500);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }, 3000);

          this.props.dispatch(
            questionsActions.saveAssessmentAnswers(
              AnswerListFinal,
              survey,
              this.props,
              this.state.IpObj,
              true
            )
          );
        }
        this.setState({ submitMandatory: "" });
      } else {
        this.setState({
          submitMandatory: "Please attempt mandatory question!",
        });
      }
    }

  }

  ansTextBoxClick(ques) {
    this.setState({ currQues: ques });
  }

  ansTextBoxChange1 = (e, Ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 1,
    };

    this.setState({ textAnswer: e.target.value });

    // console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );
  };

  handleFeedbackInput = (e, Ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      name: e.target.value,
    };

    this.setState({ textAnswer: e.target.value });

    // console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitFeedbackChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );
  };

  ansNPSInput = (val, ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: val,
      ans: 1,
    };

    this.setState({ textAnswer: val });

    let { CurrQuesIndex } = this.state;

    // console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );

    let resTime = 0;
    if (this.state.setTimeoutInit) {
      const then = moment.unix(this.state.setTimeoutInit);
      resTime = moment().diff(then, "milliseconds");
      console.log(
        "resTime----------------------resTime--------------------->",
        resTime
      );
    } else {
      resTime = 0;
    }

    this.props.dispatch(
      questionsActions.handleRecordResTime(
        resTime,
        ques,
        answersList,
        questionsList,
        surveyScreenData,
        GroupQuestionOnlyList
      )
    );

    // console.log("-----questionsListlength---->", questionsList.length);
    // console.log("-----CurrQuesIndex---->", CurrQuesIndex);

    if (questionsList && questionsList.length > 0 && !this.state.disableTimer) {
      if (questionsList.length - 1 >= CurrQuesIndex) {
        clearTimeout(this.state.setTimeoutRef);
        clearInterval(this.state.setIntervalRef);
      }

      if (CurrQuesIndex === questionsList.length) {
        this.saveAnswers();
      } else if (CurrQuesIndex <= questionsList.length - 1) {
        clearTimeout(this.state.setTimeoutRef);
        clearInterval(this.state.setIntervalRef);
      }
    }

    // if(!Ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    setTimeout(() => this.nextQues(), 300);
  };

  ansTextBoxChange2 = (e, Ques) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 2,
    };

    this.setState({ textAnswer: e.target.value });

    // console.log("ansData; ", ansData)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );
  };

  clickOK = () => {
    // setTimeout(()=>this.nextQues(), 300);
    this.nextQues();
  };

  clickOKLast = (Ques) => {
    // console.log("clickOKLast; ")

    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;

    let ansData = {
      _id: "",
      name: "",
      ans: 3,
    };

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        Ques,
        answersList,
        questionsList,
        ansData,
        null,
        surveyScreenData,
        "",
        GroupQuestionOnlyList
      )
    );

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex });
      }
    }

    let answersList2 = [];
    let AnswerListFinal =
      answersList && questions.answersList && questions.answersList.length > 0
        ? questions.answersList
        : [];
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {
        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList,
            });
          }
        } else {
          let getIndex = AnswerListFinal.findIndex(
            (prev) => prev.questionId === ques.id
          );
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }
      });
    }

    let flag = 0;
    let unattemptList = [];
    answersList2.forEach((item, index) => {
      if (item.isMandatory) {
        let template = item.template;

        if (
          template === 1 ||
          template === 3 ||
          template === 4 ||
          template === 5 ||
          template === 6 ||
          template === 16 ||
          template === 11
        ) {
          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1);
          }
        } else if (template === 13) {
          let isAllAnswered = 0;
          if (item && item.SubQuestionList && item.SubQuestionList.length > 0) {
            item.SubQuestionList.forEach((subQues) => {
              let getIndex2 = AnswerListFinal.findIndex(
                (prev) => prev.questionId === subQues.id
              );
              if (
                AnswerListFinal &&
                AnswerListFinal[getIndex2] &&
                AnswerListFinal[getIndex2].multiAnswer &&
                AnswerListFinal[getIndex2].multiAnswer.length > 1
              ) {
                isAllAnswered++;
              }
            });
            if (item.SubQuestionList.length !== isAllAnswered) {
              flag = flag + 1;
              unattemptList.push(index + 1);
            }
          }
        } else if (template === 2) {
          if (!(item && item.multiAnswer && item.multiAnswer.length > 0)) {
            flag = flag + 1;
            unattemptList.push(index + 1);
          }
        } else {
          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1);
          }
        }
      }
    });


    if (flag) {
      this.setState({ unattemptList: unattemptList });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        if (this.state.unattemptList && this.state.unattemptList.length > 0) {
          // console.log("-----working x")
          this.setState({ CurrQuesIndex: unattemptList[0] });
          // console.log("-----working x 1")
        }
      }
    } else {
      this.setState({ unattemptList: [] });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        // console.log("All mandatory questions are attempted , now go to last question i.e. submit! ")
        this.setState({ CurrQuesIndex: questionsList.length });
      }
    }

    //nothing
  };

  onMandatoryAttempt = (elem) => {
    this.setState({ CurrQuesIndex: elem });
    this.setState({ mandatoryOn: true });
  };

  gotoSubmit = () => {
    let { questions } = this.props;
    let { questionsList } = questions;

    if (questionsList && questionsList.length > 0) {
      this.setState({ CurrQuesIndex: questionsList.length });
    }
  };

  // handleLanguage = (e) => {
  //   if (e.target.value === "en") {
  //     this.setState({ surveyLanguageCode: "", surveyLanguageName: "" });
  //   } else {
  //     this.setState({
  //       surveyLanguageCode: e.target.value,
  //       surveyLanguageName: "",
  //     });
  //   }
  // };

  openDropdownQuestion = (check) => {
    this.setState({ showDropdownQues: check });
  };

  handleDropdownSelect = (value) => {
    this.setState({ dropdownInput: value, showDropdownQues: false });
  };

  handleMatrixRadio = (ques, subQues, currentOption) => {
    let { questions } = this.props;
    let {
      questionsList,
      answersList,
      surveyScreenData,
      GroupQuestionOnlyList,
    } = questions;


    //console.log('------>answersList------>',answersList)

    this.props.dispatch(
      questionsActions.handleSubmitPreChange(
        ques,
        answersList,
        questionsList,
        currentOption,
        currentOption.weightage,
        surveyScreenData,
        subQues,
        GroupQuestionOnlyList
      )
    );

    // setTimeout(()=>this.nextQues(), 300);
  };

  handleReport = () => {
    this.props.dispatch(questionsActions.getAssessmentReport({}));
    this.setState({ showReportModal: true });
  };

  handleSummaryReport = (_, type) => {
    this.props.dispatch(questionsActions.getAssessmentReport({
      "type": type
    }));
    // this.setState({ showSummaryReport: true });
  };

  goToSummary = () => {
    window.location.reload();
  }

  viewReport = (type) => {
    let { questions, temp } = this.props;
    let { getAssessmenrEmployeeProductData } = questions;
    let ele = getAssessmenrEmployeeProductData;
    console.log("ele 1-->", ele);
    if (ele && ele.productId && ele.productId.code) {
      let code = ele.productId.code;
      this.handleSummaryReport(ele, type);

      if (code === "Top5TalentplusStrengths") {
        console.log("Top5TalentplusStrengths 1-->");

        this.setState({
          showReportModal: false,
          showSummaryReport: true,
          showManagerReportModal: false,
          showHiringReportModal: false,
          showSchoolReportModal: false
        });

      } else if (code === "TalentPlusStrengths21") {
        console.log("TalentPlusStrengths21 1-->");
        // this.handleReport(ele);


        this.setState({
          showReportModal: true,
          showSummaryReport: false,
          showManagerReportModal: false,
          showHiringReportModal: false,
          showSchoolReportModal: false
        });
      } else if (code === "TalentplusManagerStrengths") {
        console.log("TalentplusManagerStrengths 1-->");
        // this.handleManagerReport(ele);


        this.setState({
          showReportModal: false,
          showSummaryReport: false,
          showManagerReportModal: true,
          showHiringReportModal: false,
          showSchoolReportModal: false
        });
      } else if (code === "TalentPlusHiringStrengths") {
        console.log("TalentPlusHiringStrengths 1-->");
        // this.handleHiringReport(ele);


        this.setState({
          showReportModal: false,
          showSummaryReport: false,
          showManagerReportModal: false,
          showHiringReportModal: true,
          showSchoolReportModal: false
        });
      } else if (code === "Top5TeenTalentplusStrengths") {
        // this.handleSchoolReport(ele);


        this.setState({
          showReportModal: false,
          showSummaryReport: false,
          showManagerReportModal: false,
          showHiringReportModal: false,
          showSchoolReportModal: true
        });
      }

    };


    this.setState({ crrEmpDetails: temp });
  };

  handleHiringReport = () => {
    this.props.dispatch(questionsActions.getAssessmentReport({}));
    this.setState({ showHiringReportModal: true });
  };

  closeReportModal = () => {
    this.setState({ showReportModal: false });
  };

  closeSummaryReportModal = () => {
    this.setState({ showSummaryReport: false });
  };

  closeManagerReportModal = () => {
    this.setState({ showManagerReportModal: false });
  };

  closeHiringReportModal = () => {
    this.setState({ showHiringReportModal: false });
  };

  handleLanguage = (item) => {
    console.log("item------------------->", item);
    if (item.shortName === "en") {
      this.setState({
        surveyLanguageCode: item.shortName,
        surveyLanguageName: "English",
        surveyLanguageRTL: false,
      });
    } else {
      this.setState({
        surveyLanguageCode: item.shortName,
        surveyLanguageName: item.name,
        surveyLanguageRTL: item.isRTL,
      });
    }
  };

  handleDownloadStatus = () => {
    let data = {
      moduleType: "ASSESSMENT",
    };
    this.props.dispatch(assessmentActions.assessmentDownloadStatus(data));
  };




  goToUpdateDetailsPage = () => {

    let { questions } = this.props;
    let { getAssessmenrEmployeeDetailsData } = questions;
    if (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name && getAssessmenrEmployeeDetailsData.email) {
      this.goToInstructionPage()
    } else {
      let { nameFormData } = this.state;
      nameFormData["name"] = getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name ? getAssessmenrEmployeeDetailsData.name : "";
      if (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name) {
        nameFormData["email"] = getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.email ? getAssessmenrEmployeeDetailsData.email : "";
      }
      this.setState({
        nameFormData,
        showNameEditScreen: true
      })
    }

    window.scrollTo(0, 0);
  };

  handleNameInput = (e) => {
    let { nameFormData } = this.state;
    nameFormData[e.target.name] = e.target.value;
    this.setState({ nameFormData })
  }

  // onUpdateDetails = () => {
  //   let { nameFormData, errNameForm } = this.state;

  //   if (nameFormData.name && nameFormData.email) {
  //     let data = {
  //       name: nameFormData.name,
  //       email: nameFormData.email
  //     };
  //     this.props.dispatch(questionsActions.updateAssessmentUserDetails(data));

  //     this.goToInstructionPage()

  //     this.setState({
  //       errNameForm: {
  //         'name': '', 'email': ''
  //       },
  //       nameFormData: {
  //         'name': '', 'email': ''
  //       }
  //     })
  //   } else {
  //     if (!nameFormData.name) {
  //       errNameForm["name"] = 'Please enter your name';
  //     }
  //     if (!nameFormData.email) {
  //       errNameForm["email"] = 'Please enter your email';
  //     } else {
  //       function validateEmail(email) {
  //         const re =
  //           /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //         return re.test(String(email).toLowerCase());
  //       }
  //       if (!validateEmail(nameFormData["email"])) {
  //         errNameForm["email"] = 'Please enter valid email';
  //       }

  //     }
  //     this.setState({ errNameForm })
  //   }

  // }

  onUpdateDetails = () => {
    let { nameFormData, errNameForm } = this.state;
    let { questions } = this.props;
    let { getAssessmentStatusData } = questions;
    let isSchool = getAssessmentStatusData && getAssessmentStatusData.isSchool ? true : false;


    if (!nameFormData.name) {
      errNameForm["name"] = 'Please enter your name';
    } else {
      errNameForm["name"] = "";
    }


    if (!nameFormData.email) {
      errNameForm["email"] = 'Please enter your email';
    } else {
      const emailPattern =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValid = emailPattern.test(nameFormData["email"]);

      // let validE = validateEmail(nameFormData["email"]);
      console.log("isValid---------------------->", isValid)

      if (!isValid) {
        errNameForm["email"] = 'Please enter valid email';
      } else {
        errNameForm["email"] = "";
      }
    }

    if (isSchool) {
      if (!nameFormData.gender) {
        errNameForm["gender"] = 'Please select your gender';
      } else {
        errNameForm["gender"] = '';
      }

      if (!nameFormData.ageGroup) {
        errNameForm["ageGroup"] = 'Please select your Age Group';
      } else {
        errNameForm["ageGroup"] = '';
      }
    }


    this.setState({ errNameForm }, () => {

      console.log("errNameForm---->", errNameForm)
      if ((isSchool ? (errNameForm["gender"] === "" && errNameForm["ageGroup"] === "") : true) && (errNameForm["name"] === "" && errNameForm["email"] === "")) {

        let data = {
          name: nameFormData.name,
          email: nameFormData.email
        };

        if (isSchool) {
          data["gender"] = nameFormData.gender;
          data["ageGroup"] = nameFormData.ageGroup;
        }
        this.props.dispatch(questionsActions.updateAssessmentUserDetails(data));
        this.goToInstructionPage()
        this.setState({
          errNameForm: {
            'name': '', 'email': '', 'gender': '', 'ageGroup': '' // Reset error for gender field
          },
          nameFormData: {
            'name': '', 'email': '', 'gender': '', 'ageGroup': '' // Reset form for gender field
          }
        })


      }
    })

  }

  handleGenderChange = (e) => {
    const { nameFormData } = this.state;
    const selectedGender = e.target.value;

    this.setState({
      nameFormData: {
        ...nameFormData,
        gender: selectedGender
      }
    });
  }

  handleAgeGroupChange = (e) => {
    const { nameFormData } = this.state;
    const selectedAgeGroup = e.target.value;

    this.setState({
      nameFormData: {
        ...nameFormData,
        ageGroup: selectedAgeGroup
      }
    });
  }


  loadExpertData = () => {
    let { questions } = this.props;
    let { getAssessmenrEmployeeDetailsData } = questions;

    if (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.id) {
      let eleUser = getAssessmenrEmployeeDetailsData;
      let temp2 = {
        employeeId: eleUser && eleUser.id ? eleUser.id : "",
        name: eleUser && eleUser.name ? eleUser.name : "",
        email: eleUser && eleUser.email ? eleUser.email : "",
        mobile: eleUser && eleUser.mobile ? eleUser.mobile : ""
      };
      this.setState({ crrCodeUserDetails: temp2 });
    }

    this.props.dispatch(assessmentActions.getExpertDetailsList());
    this.props.dispatch(assessmentActions.getExpertOrderBookList());
  }



  handleShowMainSummaryModalBack() {

    this.setState({ showMainSummaryModal: true, showMyExpertList: false, backSummary: false, top5Talent: false });
  }

  handleShowMainSummaryModal(ele) {

    this.loadExpertData()
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());

    this.setState({ showMainSummaryModal: false, backSummary: true, showMyExpertList: false, crrDetail: ele, top5Talent: true });
  }

  handleExpertContactModal(ele) {
    this.loadExpertData()
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());
    this.setState({
      contactExpertSection: ele, topFiveTalent: false
    });
  }

  handleExpertContactModalBack() {
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());
    this.setState({
      contactExpertSection: false, topFiveTalent: true
    });
  }
  handleInputExpertForm(value, name) {
    let { crrCodeUserDetails, errorsCrrCodeUserDetails } = this.state;
    crrCodeUserDetails[name] = value;
    errorsCrrCodeUserDetails[name] = "";
    this.setState({ crrCodeUserDetails, errorsCrrCodeUserDetails });
  }

  // inputChangeUpdateUser = (e) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   let fieldsUserUpdate = this.state.fieldsUserUpdate;
  //   let errorsUpdateQuote = this.state.errorsUpdateQuote;
  //   fieldsUserUpdate[name] = value;
  //   errorsUpdateQuote[name] = "";
  //   console.log(name, value);
  //   this.setState({ fieldsUserUpdate, errorsUpdateQuote });
  // }

  bookExpert(userDetails, expertDetails) {

    if (this.handleValidationUpdateUser()) {
      let data = {
        "name": userDetails.name,
        "email": userDetails.email,
        "mobile": userDetails.mobile,
        "message": userDetails.message,
        "expertId": expertDetails.id,
      }
      console.log("--------->data<---------123123123131-------", data)

      this.props.dispatch(assessmentActions.userAssessmentExpertOrderPlace(data));

    }

  }

  handleValidationUpdateUser = () => {
    let crrCodeUserDetails = this.state.crrCodeUserDetails;
    let errorsCrrCodeUserDetails = {};
    let formIsValid = true;

    //name
    if (!crrCodeUserDetails["name"] || crrCodeUserDetails["name"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["name"] = "Name cannot be empty";
    }

    //email
    if (!crrCodeUserDetails["email"] || crrCodeUserDetails["email"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["email"] = "Email cannot be empty";
    }
    //mobile
    if (!crrCodeUserDetails["mobile"] || crrCodeUserDetails["mobile"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["mobile"] = "Mobile cannot be empty";
    }
    //message
    if (!crrCodeUserDetails["message"] || crrCodeUserDetails["message"] === "") {
      formIsValid = false;
      errorsCrrCodeUserDetails["message"] = "Message cannot be empty";
    }
    console.log("errorsCrrCodeUserDetailserrorsCrrCodeUserDetails:::::", errorsCrrCodeUserDetails);
    this.setState({ errorsCrrCodeUserDetails: errorsCrrCodeUserDetails });
    return formIsValid;
  }

  handleExpertClick() {
    const { history } = this.props;
    history.push('/assessment/expert'); // Replace '/expert-page' with the actual route of your expert page
  }

  viewMyExpert = () => {
    this.loadExpertData()
    this.props.dispatch(assessmentActions.userAssessmentExpertOrderEmpty());
    this.setState({ backSummary: true, showMyExpertList: true })

  }

  handlePlayVideo = (dimension) => {
    console.log("dimension:::::", dimension);
    this.setState({ showVideoModal: dimension });
  }

  closeVideoModal = () => {
    this.setState({ showVideoModal: '' });
  }

  viewMyVideoReport = () => {
    this.setState({ showVideoResource: true })
  }

  closeVideoCourse = () => {
    this.setState({ showVideoResource: false })
  }



  handleMainMenu = (value) => {
    this.setState({ showMainMenu: value }, () => {
      if (value === "Summary") {
        this.setState({ backSummary: false, top5Talent: false })
      } else if (value === "VideoTutorials") {
        this.viewMyVideoReport();
      } else if (value === "Report") {
        this.viewReport();
      } else if (value === "Top5Summary") {
        this.viewReport("Top5Summary");
      } else if (value === "Experts") {
        this.viewMyExpert();
      }
    });
  }

  // handleMainMenu = (newRouteMenu) => {
  //   this.setState({ routeMenu: newRouteMenu }, () => {
  //     if (newRouteMenu === "Summary") {
  //       // Your logic for Summary
  //     } else if (newRouteMenu === "VideoTutorials") {
  //       // Your logic for VideoTutorials
  //     } else if (newRouteMenu === "Report") {
  //       // Your logic for Report
  //     } else if (newRouteMenu === "Experts") {
  //       // Your logic for Experts
  //     }
  //   });
  // }


  downloadRTLReport = (value) => {
    this.props.dispatch(questionsActions.getAssessmentReportRTL({
      languageShortName: value
    }));
  }


  render() {
    const { routeMenu } = this.state;
    let { questions, assessment, onLogout, handleTestPayment } = this.props;
    let {
      userAssessmentExpertOrderPlaceData,
      getExpertOrderBookListData,
      getTalentResourcesData
    } = assessment;


    // console.log('getExpertOrderBookListDatagetExpertOrderBookListDatagetExpertOrderBookListDatagetExpertOrderBookListData', getExpertOrderBookListData);

    let {
      getAssessmentStatusData,
      getAssessmentReportByEmpIDData,
      getAssessmenrEmployeeDetailsData,
      questionsList,
      answersList,
      surveyStatus,
      GroupQuestionOnlyList,
      getTranslateElementAllData,
      loading,
      getSurveyTakenDateData
    } = questions;

    let check =
      getAssessmentStatusData && getAssessmentStatusData.check
        ? getAssessmentStatusData.check
        : 0;
    let isSchool =
      getAssessmentStatusData && getAssessmentStatusData.isSchool
        ? true
        : false;


    console.log('getAssessmentStatusData', getAssessmentStatusData);




    let createdAt =
      getSurveyTakenDateData && getSurveyTakenDateData.createdAt
        ? moment(parseInt(getSurveyTakenDateData.createdAt))
          .utcOffset("+05:30")
          .format("DD MMMM, YYYY")
        : "";


    let {
      timeCounter,
      showFeedbackModal,
      CurrQuesIndex,
      afterSubmitData,
      clicked,
      ShowNotAttempted,
      submitMandatory,
      showViewReportThankYouButton,
      showSummaryReport,
      showManagerReportModal,
      showHiringReportModal,
      themeType,
      showNameEditScreen,
      nameFormData,
      errNameForm,
      showMyExpertList,
      showVideoModal,
      showVideoResource,
      showMainMenu,
      showSchoolReportModal,
      downloadStatusNo,
      surveyLanguageRTL
    } = this.state;


    console.log("isSchool-11111-->", isSchool)
    // console.log('showMainMenushowMainMenushowMainMenushowMainMenushowMainMenu', showMainMenu);

    let { UserRespondantName } = this.props;

    let { showReportModal, surveyLanguageCode, surveyLanguageName } =
      this.state;

    const isMobile = window.innerWidth <= 700;

    //console.log('isMobile: ',isMobile)

    // console.log(
    //   "setTimeoutInit:---------------------------------> ",
    //   this.state.setTimeoutInit
    // );
    //console.log("questionsList:---------------------------------> ", questionsList)
    // console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // console.log("surveyStatus:---------------------------------> ", surveyStatus)

    let surveyScreenData = {};
    if (questions.surveyScreenData) {
      surveyScreenData = { ...questions.surveyScreenData };
      // surveyScreenData["name"] = "UMatter - Employee Engagement Survey";
      // surveyScreenData["logo"] = "img/companylogo/kotaklogo.png";
      // surveyScreenData["logo2"] = "img/companylogo/umatters.png";

      // if(isMobile){
      //   surveyScreenData["logo2"] = "img/companylogo/kotaklogo2.png";
      // }
      // else{
      //   surveyScreenData["logo2"] = "img/companylogo/kotakbanner.png";
      // }

      // surveyScreenData["logo"] = "img/companylogo/umatters.png";
    }

    let bgImage = "";
    let bgColor = "";
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;
    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = [];
    let instructionTextDislay = [];

    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText;
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = "";
    let bgType = "";

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );

    let LabelObj = questions.getLanguageLabelData;
    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);

    //console.log("surveyStatus: ", surveyStatus);

    let LanguageList = questions.langList;
    //console.log("LanguageList: ", LanguageList);

    //console.log("surveyScreenData: ", surveyScreenData);

    function showRadioMatrix(SubQues, option) {
      let getIndex1 = answersList.findIndex(
        (prev) => prev.questionId === SubQues.id
      );
      let getIndex4 = answersList[getIndex1]["multiAnswer"].findIndex(
        (prev) => prev.optionHead === option.heading
      );
      let getIndex3 = answersList[getIndex1]["multiAnswer"].findIndex(
        (prev) => prev.optionId === option.id
      );

      if (getIndex4 !== -1) {
        if (getIndex3 !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    function FormatMatriXOptionHeading(question, isOption) {
      let paraObjShow = {};
      if (question && question.optionsList && question.optionsList.length > 0) {
        question.optionsList.forEach((item) => {
          if (paraObjShow && paraObjShow[item.heading]) {
            paraObjShow[item.heading] += 1;
          } else {
            paraObjShow[item.heading] = 1;
          }
        });
      }

      let headingList = [];
      let tempKey = Object.keys(paraObjShow);
      if (tempKey && tempKey.length > 0) {
        tempKey.forEach((item) => {
          headingList.push({
            name: item,
            span: paraObjShow[item],
          });
        });
      }

      if (isOption) {
        let OptionList = [];
        if (headingList && headingList.length > 0) {
          headingList.forEach((heading) => {
            if (
              question &&
              question.optionsList &&
              question.optionsList.length > 0
            ) {
              question.optionsList.forEach((option) => {
                if (heading.name === option.heading) {
                  OptionList.push(option);
                }
              });
            }
          });
        }
        return OptionList;
      } else {
        return headingList;
      }
    }

    function TranslateQuestion(surveyLanguageCode, question) {
      return surveyLanguageCode !== ""
        ? question &&
          question.transl &&
          question.transl[surveyLanguageCode] &&
          question.transl[surveyLanguageCode].name
          ? question.transl[surveyLanguageCode].name
          : question.name
        : question.name;
    }

    function TranslationOptionMatrix(
      question,
      heading,
      surveyLanguageCode,
      type
    ) {
      let optionId = "";
      if (question && question.optionsList && question.optionsList.length > 0) {
        let Index = question.optionsList.findIndex(
          (prev) => prev.heading === heading
        );
        optionId = question.optionsList[Index].id;
      }
      let text =
        surveyLanguageCode !== ""
          ? question &&
            question.transl &&
            question.transl[surveyLanguageCode] &&
            question.transl[surveyLanguageCode].headingList &&
            question.transl[surveyLanguageCode].headingList[optionId]
            ? question.transl[surveyLanguageCode].headingList[optionId]
            : heading
          : heading;

      let arr = text.split("-");
      if (type === 1) {
        return arr && arr[0] ? arr[0] : text;
      }
      if (type === 2) {
        return arr && arr[0] ? arr[0] : text;
      } else if (type === 3) {
        return arr && arr[1] ? arr[1] : "";
      } else {
        return text;
      }
    }


    let { getSurveyTemplateByCodeData } = this.props;
    function SurveyAttributes(name) {
      return surveyLanguageCode !== ""
        ? getSurveyTemplateByCodeData &&
          getSurveyTemplateByCodeData.transl &&
          getSurveyTemplateByCodeData.transl[surveyLanguageCode] &&
          getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]
          ? getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]
          : ""
        : getSurveyTemplateByCodeData && getSurveyTemplateByCodeData[name]
          ? getSurveyTemplateByCodeData[name]
          : "";
    }


    function MandatoryMsg(Ques) {
      let Text = "";
      let Color = "";
      if (Ques.isMandatory) {
        if (Ques.templateId === 13) {
          Text = SurveyAttributes("allmandatoryText")
            ? SurveyAttributes("allmandatoryText") + "*"
            : "All questions are mandatory*";
          Color = "#eb3434";
        } else {
          Text = "*";
          Color = "#eb3434";
        }
      }
      return {
        text: Text,
        color: Color,
      };
    }

    function showNotCompletedFn(Que) {
      let answersList = [];
      let AnswerListFinal =
        answersList && questions.answersList && questions.answersList.length > 0
          ? questions.answersList
          : [];
      let check = false;
      if (ShowNotAttempted) {
        let getIndex = AnswerListFinal.findIndex(
          (prev) => prev.questionId === Que.id
        );
        if (getIndex !== -1) {
          check =
            AnswerListFinal[getIndex].multiAnswer.length !== 2 ? true : false;
        }
      }

      // console.log("showNotCompletedFn===>",check)
      return check;
    }


    function CompletedCountV1(list) {
      let list2 = [];
      if (list && list.length > 0) {
        list2 = list.filter((prev) => prev.answer !== "");
      }
      // console.log("list2::::", list2);
      if (list2 && list2.length > 0) {
        return list2.length;
      } else {
        return 0;
      }
    }

    let answerCount = CompletedCountV1(answersList);
    // console.log("answerCount::::", answerCount);

    let completedMilestone = answerCount;
    // let completedPercentage = 0;

    // if (questionsList && questionsList.length > 0) {
    //   let num = (answerCount * 100) / questionsList.length;
    //   console.log("num::::",num)

    //   completedPercentage = (answerCount * 100) / questionsList.length;
    //   if (num >= 25 && num < 50) {
    //     completedMilestone = "25%";
    //   } else if (num >= 50 && num < 75) {
    //     completedMilestone = "50%";
    //   } else if (num >= 75 && num < 100) {
    //     completedMilestone = "75%";
    //   } else if (num >= 100) {
    //     completedMilestone = "100%";
    //   }
    // }

    // let groupQuestionList = []
    // if(questionsList && questionsList.length>0){
    //   questionsList.forEach((item)=>{
    //     if(!(item && item.parentId)){
    //       groupQuestionList.push(item);
    //     }
    //   });
    // }
    //console.log("GroupQuestionOnlyList---------->",GroupQuestionOnlyList)

    function getGroupIndex(QuesId) {
      //console.log("QuesId---------->",QuesId)

      if (GroupQuestionOnlyList && GroupQuestionOnlyList.length > 0) {
        let getIndex = GroupQuestionOnlyList.findIndex(
          (prev) => prev.id === QuesId
        );
        if (getIndex !== -1) {
          //console.log("match---------->",getIndex+1)

          return (getIndex + 1).toString() + ". ";
        } else {
          return "";
        }
      } else {
        return "";
      }
    }

    // console.log(
    //   "questionsList---------------------------------->",
    //   questionsList
    // );
    // console.log("answersList--------------------- ---->", answersList);

    function functionNameVariable(quesName) {
      return quesName && quesName.toString()
        ? quesName.toString().replace("[variable]", UserRespondantName)
        : quesName;
    }


    function getSecondsToTime(given_seconds) {
      var seconds = parseInt(given_seconds);
      var format =
        (Math.floor(moment.duration(seconds, "seconds").asHours()) < 10
          ? Math.floor(moment.duration(seconds, "seconds").asHours())
          : Math.floor(moment.duration(seconds, "seconds").asHours())) +
        ":" +
        (moment.duration(seconds, "seconds").minutes() < 10
          ? "0" + moment.duration(seconds, "seconds").minutes()
          : moment.duration(seconds, "seconds").minutes()) +
        ":" +
        (moment.duration(seconds, "seconds").seconds() < 10
          ? "0" + moment.duration(seconds, "seconds").seconds()
          : moment.duration(seconds, "seconds").seconds());
      return format;
    }

    function getSecondsToTimeReverse(given_seconds) {
      var seconds = parseInt(given_seconds);
      var format =
        (moment.duration(seconds, "seconds").seconds() < 10
          ? "0" + moment.duration(seconds, "seconds").seconds()
          : moment.duration(seconds, "seconds").seconds()) +
        ":" +
        (moment.duration(seconds, "seconds").minutes() < 10
          ? "0" + moment.duration(seconds, "seconds").minutes()
          : moment.duration(seconds, "seconds").minutes()) +
        ":" +
        (Math.floor(moment.duration(seconds, "seconds").asHours()) < 10
          ? Math.floor(moment.duration(seconds, "seconds").asHours())
          : Math.floor(moment.duration(seconds, "seconds").asHours()));
      return format;
    }
    // console.log("surveyLanguageCode------------>", surveyLanguageCode);

    const GetTextByLanguage = (name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = "";
        if (
          getTranslateElementAllData &&
          getTranslateElementAllData.length > 0
        ) {
          let getIndex = getTranslateElementAllData.findIndex(
            (prev) => prev.name === name
          );
          if (getIndex !== -1) {
            sentence =
              getTranslateElementAllData &&
                getTranslateElementAllData[getIndex] &&
                getTranslateElementAllData[getIndex]["transl"] &&
                getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode]
                ? getTranslateElementAllData[getIndex]["transl"][
                surveyLanguageCode
                ]
                : "";
          }
        }
        console.log("sentence------------>", sentence);
        console.log("surveyLanguageCode------------>", surveyLanguageCode);
        console.log(
          "getTranslateElementAllData------------>",
          getTranslateElementAllData
        );

        return sentence ? sentence : text;
      } else {
        return text;
      }
    };

    const GetDetailsByLanguage = (element, name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence =
          element &&
            element["details"] &&
            element["details"]["transl"] &&
            element["details"]["transl"][surveyLanguageCode] &&
            element["details"]["transl"][surveyLanguageCode][name]
            ? element["details"]["transl"][surveyLanguageCode][name]
            : "";
        return sentence ? sentence : text;
      } else {
        return text;
      }
    };

    function EditButtonFn(name, text) {
      return <>{GetTextByLanguage(name, text)}</>;
    }
    function EditDetailsFn(element, name, text, type) {
      if (type === 1) {
        console.log("element-----111--------->", element)
        console.log("name--------111------>", name)
        console.log("text-------111------->", text)
      }
      return <>{GetDetailsByLanguage(element, name, text)}</>;
    }

    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    function getTheme(field, init) {
      if (themeType === 2) {
        if (field === "backgroundImage") {
          return 'url("/img/survey-light-bg.webp")';
        } else if (field === "welcomeImage") {
          return "url('/img/assessment-welcome-large.webp')";
        } else if (field === "text") {
          return "#3D405B";
        } else if (field === "lightblue") {
          return hexToRgbA("#2196F3", "0.12");
        } else if (field === "textblue") {
          return "#2196F3";
        } else if (field === "lightblue") {
          return hexToRgbA("#2196F3", "0.12");
        } else if (field === "hoverActive") {
          return (
            "hover:bg-[" +
            hexToRgbA("#2196F3", "0.12") +
            "] hover:border-[#2196F3] hover:text-[#2196F3] "
          );
        } else if (field === "grayheader") {
          return " bg-[" + hexToRgbA("#3D405B", "0.05") + "] ";
        } else if (field === "textOption") {
          return "border-[#3D405B] text-[#3D405B] ";
        } else {
          return init;
        }
      } else {
        return init;
      }
    }


    let crrEmpDetails = getAssessmenrEmployeeDetailsData;



    console.log('isSchool-------------121212------------>', isSchool)

    return (
      <div className="h-dvh">
        {check === 1 ? (
          <>
            <div
              className="w-full h-screen overflow-hidden bg-center bg-cover"
              style={{
                backgroundImage: getTheme(
                  "backgroundImage",
                  'url("/img/survey-bg.jpg")'
                ),
              }}
            >
              <LogoAssessmentHeader
                logout={onLogout}
                surveyScreenData={surveyScreenData}
                template={"v4"}
                details={getAssessmenrEmployeeDetailsData}
                handleReport={this.viewReport}
                surveyLanguageName={surveyLanguageName}
                handleLanguage={this.handleLanguage}
                LanguageList={LanguageList}
              />

              {/* Welcome Section */}
              {surveyStatus !== 2 && surveyStatus !== 3 ? (
                <>
                  {this.state.showWelcomeScreen &&
                    questionsList &&
                    questionsList.length > 0 ? (
                    <>

                      {showNameEditScreen ?
                        <>
                          <div>
                            {/* showNameEditScreen update your details second screen */}
                            <section className="xl:w-11/12 w-full mx-auto xl:px-0 px-4 md:py-10 py-4 flex items-start h-[calc(100vh-5rem)]">
                              {/* right side image section */}
                              <div className="justify-center hidden w-5/12 h-full md:flex ">
                                <div
                                  className="bg-[#bbdefb] rounded-2xl flex justify-center items-center  w-full "
                                  style={{
                                    backgroundImage: getTheme(
                                      "welcomeImage",
                                      "/img/talent_assessment_intro.svg"
                                    ),
                                    backgroundSize: "cover",
                                  }}
                                >
                                  {themeType === 1 ? (
                                    <img
                                      src={getTheme(
                                        "welcomeImage",
                                        "/img/talent_assessment_intro.svg"
                                      )}
                                      className="w-11/12 mx-auto 2xl:w-10/12"
                                    />
                                  ) : null}
                                </div>
                              </div>

                              {/*  Please update your details form*/}
                              <div className="flex flex-col justify-between w-full h-full space-y-8 md:w-7/12 2xl:space-y-10 xl:space-y-8">
                                <div className="text-base font-normal text-white md:space-y-4 " style={{ color: getTheme("text", "#FFFFFF") }}>


                                  <div className="space-y-4 md:px-10" style={{ color: getTheme("text", "#FFFFFF") }}>

                                    <div className="w-full py-2 mx-auto 2xl:w-4/6 lg:w-5/6">
                                      <h1 className="py-4 pb-8 text-2xl font-medium xl:text-4xl text-white/100" style={{ color: getTheme("text", "#FFFFFF") }}> {"Please update your details"}</h1>
                                      <div className="text-base font-bold ">Name<span className="text-red-500">*</span></div>
                                      <input name="name" value={nameFormData.name} onChange={this.handleNameInput} placeholder="Name" className="w-full p-3 mt-2 border focus:ring-1 ring-[#2196f3] focus:outline-none  rounded-md  " />
                                      <div className="text-lg font-normal text-red-500">{errNameForm && errNameForm.name ? errNameForm.name : ""}</div>
                                      <br />

                                      <div className="text-base font-bold ">Email<span className="text-red-500">*</span></div>
                                      <input name="email" value={nameFormData.email} onChange={this.handleNameInput} placeholder="Email" className="w-full p-3 mt-2 border  rounded-md   focus:ring-1 ring-[#2196f3] focus:outline-none" />

                                      <div className="mt-2 text-lg font-normal text-red-500">{errNameForm && errNameForm.email ? errNameForm.email : ""}</div>
                                      <br />

                                      {
                                        isSchool ?
                                          <div className="" >
                                            <div className="space-y-2 ">
                                              <label className="mb-2 text-base font-bold">What is your gender?<span className="text-red-500">*</span></label>
                                              <div className="relative w-full ">
                                                <select className="w-full p-3 text-[#212121] bg-white border   rounded-md  outline-none appearance-none" onChange={this.handleGenderChange}>
                                                  <option disabled selected>Select</option>
                                                  <option value="Male">Male</option>
                                                  <option value="Female">Female</option>
                                                  <option value="Others">Others</option>
                                                  <option value="Prefer not to say">Prefer not to say</option>
                                                </select>
                                                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 pointer-events-none">
                                                  <IoIosArrowDown className="w-4 h-4 fill-current" />
                                                </div>
                                              </div>

                                            </div>
                                            <div className="mt-2 text-lg font-normal text-red-500">{errNameForm && errNameForm.gender ? errNameForm.gender : ""}</div>
                                            <br />
                                            <div className="space-y-2 ">
                                              <label className="mb-2 text-base font-bold">What is your age group?<span className="text-red-500">*</span></label>
                                              <div className="relative w-full ">
                                                <select className="w-full p-3 text-[#212121] bg-white border   rounded-md  outline-none appearance-none" onChange={this.handleAgeGroupChange}>
                                                  <option disabled selected>Select</option>
                                                  <option value="Below 15 Years (For TeenTalent)">Below 15 Years (For TeenTalent)</option>
                                                  <option value="15 Years to 18 Years (For TeenTalent)">15 Years to 18 Years (For TeenTalent)</option>
                                                  <option value="18 Years to 21 Years (For TeenTalent)">18 Years to 21 Years (For TeenTalent)</option>
                                                  <option value="21 Years to 25 Years">21 Years to 25 Years</option>
                                                  <option value="26 years to 35 Years">26 years to 35 Years</option>
                                                  <option value="36 Years to 45 Years">36 Years to 45 Years</option>
                                                  <option value="46 years to 60 Years">46 years to 60 Years</option>
                                                  <option value="Above 60 years">Above 60 years</option>
                                                </select>
                                                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 pointer-events-none">
                                                  <IoIosArrowDown className="w-4 h-4 fill-current" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="mt-2 text-lg font-normal text-red-500">{errNameForm && errNameForm.ageGroup ? errNameForm.ageGroup : ""}</div>

                                          </div>

                                          : null
                                      }

                                      <button onClick={() => this.onUpdateDetails()} className="mt-8  w-full uppercase md:text-xl text-base font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-4 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"><span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                                        {SurveyAttributes("Continue")
                                          ? SurveyAttributes("Continue")
                                          : "Continue"}
                                      </button>

                                    </div>

                                  </div>
                                </div>

                                <div className="flex flex-col justify-start w-full space-y-4 md:space-y-8 md:pl-10">
                                  <div className="flex justify-center mt-4 mb-2 md:justify-end">
                                    <p
                                      className="text-sm text-white/80"
                                      style={{ color: getTheme("text", "#FFFFFF") }}
                                    >
                                      © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"}{" "}
                                      <b
                                        className="font-medium text-white/100"
                                        style={{
                                          color: getTheme("text", "#FFFFFF"),
                                        }}
                                      >
                                        {SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/*  */}
                            </section>
                          </div>


                        </>
                        :
                        <div>
                          {/* Welcome to the world First Scrren */}
                          <section className="xl:w-11/12 w-full mx-auto xl:px-0 px-4 md:py-10 py-4 lg:space-x-10  flex   items-start h-[calc(100vh-5rem)]">
                            {/* right image section*/}
                            <div className="justify-center hidden w-5/12 h-full md:flex ">
                              <div
                                className="bg-[#bbdefb] rounded-2xl flex justify-center items-center  w-full "
                                style={{
                                  backgroundImage: getTheme(
                                    "welcomeImage",
                                    "/img/talent_assessment_intro.svg"
                                  ),
                                  backgroundSize: "cover",
                                }}
                              >
                                {themeType === 1 ? (
                                  <img
                                    src={getTheme(
                                      "welcomeImage",
                                      "/img/talent_assessment_intro.svg"
                                    )}
                                    className="w-11/12 mx-auto 2xl:w-10/12"
                                  />
                                ) : null}
                              </div>
                            </div>

                            {/* welcome to the world of Infinite possibilities */}
                            <div className="w-full space-y-8 md:w-7/12 2xl:space-y-10 xl:space-y-8 ">
                              <div className="h-full text-base font-normal text-white md:space-y-4 " style={{ color: getTheme("text", "#FFFFFF") }}>

                                {surveyLanguageRTL ?
                                  <h1 className="pb-4 text-right text-xl font-semibold left 2xl:text-4xl xl:text-3xl text-white/100"
                                    style={{ color: getTheme("text", "#FFFFFF") }}>
                                    {SurveyAttributes("Welcome to the world of Infinite possibilities")
                                      ? SurveyAttributes("Welcome to the world of Infinite possibilities")
                                      : "Welcome to the world of Infinite possibilities"}
                                  </h1>
                                  : <h1 className="pb-4 text-xl font-semibold left 2xl:text-4xl xl:text-3xl text-white/100"
                                    style={{ color: getTheme("text", "#FFFFFF") }}>
                                    {SurveyAttributes("Welcome to the world of Infinite possibilities")
                                      ? SurveyAttributes("Welcome to the world of Infinite possibilities")
                                      : "Welcome to the world of Infinite possibilities"}
                                  </h1>}


                                <div className="lg:h-[calc(100vh-23rem)] md:h-[calc(100vh-26rem)] h-[calc(100vh-24rem)] overflow-y-auto  space-y-4 md:p-8 p-4 bg-white rounded-md shadow-md " style={{ color: getTheme("text", "#FFFFFF") }}>
                                  {surveyLanguageCode !== "" &&
                                    surveyLanguageCode !== "en" ? (
                                    <>
                                      {surveyLanguageCode === "ar" ?
                                        <div className="space-y-4 text-right" >
                                          <p className="text-lg font-semibold">
                                            ,مرحبًا


                                          </p>

                                          <p className="text-md">
                                            لقد ولدنا جميعا بمواهب فريدة من نوعها. الكثير منا يدرك هذا في مرحلة ما
                                            حياتنا. والأكثر حظا هم الذين يصلون
                                            المعرفة في بدايتها
                                            الحياة واستخدامها لتعيش حياة مرضية و
                                            حياة مزدهرة. لكن معظمنا كذلك
                                            غير مدركين أو مدركين لمواهبنا المتأصلة
                                            في وقت متأخر جدًا من حياتنا، مما يجعل حياتنا
                                            .العمل والحياة مملة


                                          </p>

                                          <p className="text-lg font-semibold">
                                            .الآن، لا أكثر


                                          </p>

                                          <p className="text-md">
                                            <b className="font-semibold">
                                              {" "}
                                              ,تقييم TalentPlusStrengths{" "}
                                            </b>{" "}

                                            سوف يسمح لك بمعرفة إمكاناتك الخفية
                                            موهبة بضغطة زر . يرد
                                            لدينا مصممة علميا
                                            الاستبيان ومن ثم الضغط على زر الإرسال.
                                            ستتمكن من تنزيل موهبتك
                                            .المواضيع على الفور
                                          </p>

                                          <p className="text-lg font-semibold !-mb-4">

                                            :الاتجاهات
                                          </p>

                                          <ul className="list-decimal list-inside text-md ">
                                            <li>
                                              {" "}
                                              سوف يستغرق الاختبار حوالي{" "}


                                              <b className="font-semibold ">
                                                20 دقيقة
                                              </b>{" "}
                                              .لإنهاء ذلك


                                            </li>
                                            <li>
                                              {" "}
                                              لقد حصلت{" "}


                                              <b className="font-semibold ">
                                                20 ثانية
                                              </b>{" "}
                                              .للرد على كل سؤال

                                            </li>
                                            <li>
                                              {" "}
                                              يجب عليك الرد مع الخاص بك{" "}

                                              <b className="font-semibold ">
                                                .الفكر الأول
                                              </b>
                                            </li>
                                            <li>
                                              {" "}
                                              إنها{" "}


                                              <b className="font-semibold ">
                                                مقياس التقييم من 10 نقاط

                                              </b>
                                              ,أين{" "}

                                              <b className="font-semibold ">
                                                1 تعني "ليس مثلك"
                                              </b>{" "}
                                              and{" "}
                                              <b className="font-semibold ">
                                                .10 يعني "مثلك تمامًا"
                                              </b>
                                            </li>
                                            <li>
                                              {" "}
                                              .ليس هناك جواب صحيح أو خاطئ. هذا فقط ما تشعر به تجاه البيان
                                            </li>
                                          </ul>

                                          <p className=" text-md">

                                            بمجرد الانتهاء، اضغط على زر الإرسال و
                                            انقر على ملف التعريف الخاص بك لرؤية النتيجة الخاصة بك.
                                            على الرغم من أن التقرير مفصل للغاية و
                                            ينبغي أن تعطيك فكرة عميقة جدا عنك
                                            ومواهبك لا تزال دعوة للتدريب
                                            يوصى باستخدام مستشار TalentPlusStrengths.
                                            يمكنك إصلاح نفس الشيء عن طريق الكتابة إلينا
                                            في
                                            <b> contact@happyplus.in</b>
                                          </p>

                                          <p className="block text-md">
                                            .أتمنى لك كل خير

                                          </p>
                                          <p className="text-lg font-medium">
                                            HappyPlus Assessment Team.
                                          </p>
                                        </div>
                                        :
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: functionNameVariable(
                                              surveyLanguageCode !== ""
                                                ? surveyScreenData &&
                                                  surveyScreenData.transl &&
                                                  surveyScreenData.transl[
                                                  surveyLanguageCode
                                                  ] &&
                                                  surveyScreenData.transl[
                                                    surveyLanguageCode
                                                  ].welcomeText
                                                  ? surveyScreenData.transl[
                                                    surveyLanguageCode
                                                  ].welcomeText
                                                  : welcomeTextDislay
                                                : welcomeTextDislay
                                            ),
                                          }}
                                        />}
                                    </>
                                  ) : (
                                    <div className="space-y-4 " >
                                      <p className="text-lg font-semibold">
                                        Hello,
                                      </p>

                                      <p className="text-md">
                                        We all are born with unique talents. Lot
                                        of us realise it at some point of time in
                                        our life. Luckier are those who get to
                                        know about it at the beginning of their
                                        life and use it to live a fulfilling and
                                        thriving life. But most of us are either
                                        unaware or realise our inherent talent
                                        quite late in our life, thus making our
                                        work & life boring.
                                      </p>

                                      <p className="text-lg font-semibold">
                                        Now, no more.
                                      </p>

                                      <p className="text-md">
                                        <b className="font-semibold">
                                          {" "}
                                          TalentPlusStrengths  assessment,{" "}
                                        </b>{" "}
                                        will allow you to know your inherent
                                        talents with a click of a button. Respond
                                        to our scientifically designed
                                        questionnaire and press the submit button.
                                        You will be able to download your talent
                                        themes immediately.
                                      </p>

                                      <p className="text-lg font-semibold !-mb-4">
                                        Instructions:
                                      </p>

                                      <ul className="list-decimal list-inside text-md ">
                                        <li>
                                          {" "}
                                          The test will take around{" "}
                                          <b className="font-semibold ">
                                            20 minutes
                                          </b>{" "}
                                          to finish.
                                        </li>
                                        <li>
                                          {" "}
                                          You get{" "}
                                          <b className="font-semibold ">
                                            20 seconds
                                          </b>{" "}
                                          to respond to each question.
                                        </li>
                                        <li>
                                          {" "}
                                          You should respond with your{" "}
                                          <b className="font-semibold ">
                                            first thought.
                                          </b>
                                        </li>
                                        <li>
                                          {" "}
                                          It’s a{" "}
                                          <b className="font-semibold ">
                                            10-point rating scale
                                          </b>
                                          , where{" "}
                                          <b className="font-semibold ">
                                            1 is Not like you
                                          </b>{" "}
                                          and{" "}
                                          <b className="font-semibold ">
                                            10 is Exactly like you.
                                          </b>
                                        </li>
                                        <li>
                                          {" "}
                                          There is no{" "}
                                          <b className="font-semibold ">
                                            right
                                          </b>{" "}
                                          or{" "}
                                          <b className="font-semibold ">wrong</b>{" "}
                                          answer. It’s just what you feel about a
                                          statement.{" "}
                                        </li>
                                      </ul>

                                      <p className=" text-md">
                                        Once done, press the submit button and
                                        click on your profile to see your result.
                                        Although the report is quite detailed and
                                        should give you a very deep insight on you
                                        and your talents still a coaching call
                                        with TalentPlusStrengths  Consultant is recommended.
                                        You can fix up the same by writing to us
                                        at
                                        <b> contact@happyplus.in</b>
                                      </p>

                                      <p className="block text-md">
                                        All the best.
                                      </p>
                                      <p className="text-lg font-medium">
                                        HappyPlus Assessment Team.
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex flex-col justify-start w-full space-y-4 md:space-y-8 ">
                                <button
                                  onClick={() => this.goToUpdateDetailsPage()}
                                  className="md:w-80 w-full  uppercase md:text-xl text-base font-medium rounded-md relative inline-flex group items-center justify-center px-4 lg:py-4  py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                                >
                                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                                  {SurveyAttributes("StartAssessmentButtonText")
                                    ? SurveyAttributes(
                                      "StartAssessmentButtonText"
                                    )
                                    : "START ASSESSMENT"}
                                </button>

                                <div className="flex justify-center mb-2 md:justify-end">
                                  <p
                                    className="text-sm text-white/80"
                                    style={{ color: getTheme("text", "#FFFFFF") }}
                                  >
                                    © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"}{" "}
                                    <b
                                      className="font-medium text-white/100"
                                      style={{
                                        color: getTheme("text", "#FFFFFF"),
                                      }}
                                    >
                                      {SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}
                                    </b>
                                  </p>
                                </div>
                              </div>

                            </div>

                            {/*  */}
                          </section>
                        </div>
                      }
                    </>
                  ) : null}

                  {/* Instruction Section */}

                  {this.state.instructionPage ? (
                    <>
                      <div>
                        <section
                          className="px-2 py-4 mx-auto lg:w-5/6 md:py-6 lg:px-0 md:px-6 "
                          style={{ height: "75%" }}
                        >
                          {/* logo */}
                          <div className="flex justify-center -mt-4"></div>

                          <p
                            style={{
                              color:
                                surveyScreenData && surveyScreenData.textColor
                                  ? surveyScreenData.textColor
                                  : "black",
                            }}
                            className="py-2 text-2xl font-bold text-center"
                          >
                            {LabelObj &&
                              LabelObj[surveyLanguageCode] &&
                              LabelObj[surveyLanguageCode].InstructionLabelText
                              ? LabelObj[surveyLanguageCode]
                                .InstructionLabelText
                              : SurveyAttributes("instructionLabel")
                                ? SurveyAttributes("instructionLabel")
                                : "Instructions"}
                          </p>

                          {/* pragraph */}
                          <div className="pb-24">
                            <div
                              id="chatbot"
                              className="mx-4 mt-2 overflow-y-auto cursor-default lg:px-14 lg:pl-32 lg:pr-4"
                              style={{
                                height: "400px",
                                color:
                                  surveyScreenData && surveyScreenData.textColor
                                    ? surveyScreenData.textColor
                                    : "black",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  surveyLanguageCode !== ""
                                    ? surveyScreenData &&
                                      surveyScreenData.transl &&
                                      surveyScreenData.transl[
                                      surveyLanguageCode
                                      ] &&
                                      surveyScreenData.transl[
                                        surveyLanguageCode
                                      ].instructionText
                                      ? surveyScreenData.transl[
                                        surveyLanguageCode
                                      ].instructionText
                                      : instructionTextDislay
                                    : instructionTextDislay,
                              }}
                            />
                          </div>
                          <div className="flex justify-center ">
                            <div>
                              {/* btn */}
                              <button
                                onClick={() => this.goToQuestionPage()}
                                className="w-80  uppercase text-xl font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-4 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"

                              >
                                {SurveyAttributes("Continue")
                                  ? SurveyAttributes("Continue")
                                  : "Continue"}
                              </button>
                              <p
                                style={{
                                  backgroundColor:
                                    bgType === 1 || afterSubmitData.bgType === 1
                                      ? bgColor
                                        ? bgColor
                                        : afterSubmitData.bgColor
                                          ? afterSubmitData.bgColor
                                          : "#757575"
                                      : "#f1f1f1",
                                  // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                                }}
                                className="py-4 pl-6 text-sm"
                              >
                                {" "}
                                © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"}{" "}
                                <b className="font-semibold">{SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}</b>
                              </p>
                            </div>
                          </div>
                          {/*  */}
                        </section>
                      </div>
                    </>
                  ) : null}

                  {/* Question Section */}

                  {this.state.showQuestionScreen && !this.state.submitSucces ? (
                    <div>
                      <div className="md:h-16 h-14 w-full bg-[#2196f3] flex items-center ">
                        <div className="flex items-center justify-between w-full px-4 mx-auto xl:w-11/12 xl:px-0 ">
                          {surveyLanguageRTL ?
                            <>

                              <div className="flex items-center space-x-2 text-white md:space-x-2 ">
                                <h1 className="text-sm font-medium md:text-2xl">
                                  <b className="font-medium text-white/60 ">
                                    {questionsList && questionsList.length > 0
                                      ? questionsList.length
                                      : 0}
                                    {" "}
                                    /{" "}
                                  </b>
                                  {(CurrQuesIndex).toString() + " "}

                                </h1>
                                <span className="material-symbols-outlined md:text-[2rem] text-[1rem]">
                                  check_circle
                                </span>
                              </div>
                              <div className="flex items-center text-white md:space-x-2 ">
                                <h1 className="p-2 text-sm font-medium md:text-2xl">
                                  {getSecondsToTimeReverse(timeCounter)}
                                </h1>
                                <div className="flex items-center space-x-2 ">
                                  <p className="text-sm md:text-xl ">
                                    {SurveyAttributes("Remaining")
                                      ? SurveyAttributes("Remaining")
                                      : "Remaining"}
                                  </p>
                                  <span className="material-symbols-outlined md:text-[2rem] text-[1rem]">
                                    schedule
                                  </span>
                                </div>
                              </div>
                            </>

                            :
                            <>
                              <div className="flex items-center text-white md:space-x-2 ">
                                <div className="flex items-center space-x-2 ">
                                  <span className="material-symbols-outlined md:text-[2rem] text-[1rem]">
                                    schedule
                                  </span>
                                  <p className="text-sm md:text-xl ">
                                    {SurveyAttributes("Remaining")
                                      ? SurveyAttributes("Remaining")
                                      : "Remaining"}
                                  </p>
                                </div>
                                <h1 className="p-2 text-sm font-medium md:text-2xl">
                                  {getSecondsToTime(timeCounter)}
                                </h1>
                              </div>
                              <div className="flex items-center space-x-2 text-white md:space-x-2 ">
                                <span className="material-symbols-outlined md:text-[2rem] text-[1rem]">
                                  check_circle
                                </span>
                                <h1 className="text-sm font-medium md:text-2xl">
                                  {(CurrQuesIndex).toString() + " "}
                                  <b className="font-medium text-white/60 ">
                                    {" "}
                                    /{" "}
                                    {questionsList && questionsList.length > 0
                                      ? questionsList.length
                                      : 0}
                                  </b>
                                </h1>
                              </div>
                            </>
                          }

                        </div>
                      </div>
                      <div className={"md:h-14 h-10 w-full flex items-center  " + getTheme("grayheader", " bg-black/20 ")}>
                        <div className="flex items-center justify-center w-full px-6 mx-auto 2xl:w-8/12 xl:w-10/12 xl:px-0 ">
                          {surveyLanguageRTL ?
                            <div className="flex items-center space-x-4 text-white ">
                              <p
                                className="text-sm md:text-xl "
                                style={{ color: getTheme("text", "#FFFFFF") }}
                              >

                                {SurveyAttributes(
                                  "Kindly answer the given question"
                                )
                                  ? SurveyAttributes(
                                    "Kindly answer the given question"
                                  )
                                  : "Kindly answer the given question"}
                              </p>
                              <span
                                className="material-symbols-outlined md:text-[2rem] text-[1.5rem]"
                                style={{ color: getTheme("text", "#FFFFFF") }}
                              >
                                tips_and_updates
                              </span>
                            </div>
                            :
                            <div className="flex items-center space-x-4 text-white ">
                              <span
                                className="material-symbols-outlined md:text-[2rem] text-[1.5rem]"
                                style={{ color: getTheme("text", "#FFFFFF") }}
                              >
                                tips_and_updates
                              </span>
                              <p
                                className="text-sm md:text-xl "
                                style={{ color: getTheme("text", "#FFFFFF") }}
                              >

                                {SurveyAttributes(
                                  "Kindly answer the given question"
                                )
                                  ? SurveyAttributes(
                                    "Kindly answer the given question"
                                  )
                                  : "Kindly answer the given question"}
                              </p>
                            </div>
                          }



                        </div>
                      </div>

                      <section className="2xl:mt-24 xl:mt-24 lg:mt-12 md:mt-12 mt-4  2xl:w-3/6 xl:w-4/6 lg:w-5/6 w-full md:py-2 py-4 mx-auto lg:px-0 md:px-6 px-2 overflow-y-hidden overflow-x-hidden flex h-[calc(100vh-14rem)] ">
                        <div className="w-full">
                          {questionsList && questionsList.length > 0
                            ? questionsList.map((question, questionindex) =>
                              questionindex + 1 === CurrQuesIndex ? (
                                // CurrQuesIndex
                                <>
                                  <div>
                                    {question.templateId !== 14 ? (
                                      question.templateId === 10 ? (

                                        <p
                                          style={{
                                            color: getTheme(
                                              "text",
                                              "#FFFFFF"
                                            ),
                                          }}
                                          className="px-4 py-4 mb-4 text-2xl italic font-semibold text-left text-white cursor-default quz md:text-2xl md:px-6 md:py-6 md:mt-4"
                                        >
                                          {/* {(questionindex+1).toString()+'. '} */}
                                          <span className="font-bold">
                                            {functionNameVariable(
                                              surveyLanguageCode !== ""
                                                ? question &&
                                                  question.transl &&
                                                  question.transl[
                                                  surveyLanguageCode
                                                  ] &&
                                                  question.transl[
                                                    surveyLanguageCode
                                                  ].name
                                                  ? question.transl[
                                                    surveyLanguageCode
                                                  ].name
                                                  : question.name
                                                : question.name
                                            )}
                                          </span>

                                          {question.isMandatory ? (
                                            <div
                                              style={{
                                                background:
                                                  surveyScreenData &&
                                                    surveyScreenData.OptionColor1
                                                    ? surveyScreenData.OptionColor1
                                                    : "white",
                                                color: getTheme(
                                                  "text",
                                                  "#FFFFFF"
                                                ),
                                              }}
                                              className="font-semibold text-gray-500 bg-gray-300 shadow-lg subtitle"
                                            >
                                              {SurveyAttributes(
                                                "mandatoryText"
                                              )
                                                ? SurveyAttributes(
                                                  "mandatoryText"
                                                )
                                                : "This question is mandatory"}
                                            </div>
                                          ) : null}
                                        </p>

                                      ) : (
                                        <>
                                          {question.parentId ? (
                                            <>
                                              {question &&
                                                question["QuesMain"] ? (

                                                <QuestionHeader
                                                  surveyLanguageCode={
                                                    surveyLanguageCode
                                                  }
                                                  question={
                                                    question["QuesMain"]
                                                  }
                                                />

                                              ) : null}

                                              <div
                                                style={{
                                                  color: getTheme(
                                                    "text",
                                                    "#FFFFFF"
                                                  ),
                                                }}
                                                className="px-4 py-2 mb-2 text-lg font-semibold text-left text-white cursor-default quz md:text-2xl md:px-6 md:py-4 md:mt-2"
                                              >
                                                {getGroupIndex(
                                                  question["QuesMain"].id
                                                )}
                                                {functionNameVariable(
                                                  surveyLanguageCode !==
                                                    ""
                                                    ? question &&
                                                      question[
                                                      "QuesMain"
                                                      ] &&
                                                      question["QuesMain"]
                                                        .transl &&
                                                      question["QuesMain"]
                                                        .transl[
                                                      surveyLanguageCode
                                                      ] &&
                                                      question["QuesMain"]
                                                        .transl[
                                                        surveyLanguageCode
                                                      ].name
                                                      ? question[
                                                        "QuesMain"
                                                      ].transl[
                                                        surveyLanguageCode
                                                      ].name
                                                      : question && [
                                                        "QuesMain",
                                                      ] &&
                                                        question[
                                                          "QuesMain"
                                                        ].name
                                                        ? question[
                                                          "QuesMain"
                                                        ].name
                                                        : ""
                                                    : question && [
                                                      "QuesMain",
                                                    ] &&
                                                      question["QuesMain"]
                                                        .name
                                                      ? question["QuesMain"]
                                                        .name
                                                      : ""
                                                )}
                                              </div>

                                              <div
                                                style={{
                                                  color: getTheme(
                                                    "text",
                                                    "#FFFFFF"
                                                  ),
                                                }}
                                                className={
                                                  question.templateId ===
                                                    3 ||
                                                    question.templateId ===
                                                    4 ||
                                                    question.templateId ===
                                                    6 ||
                                                    question.templateId ===
                                                    16
                                                    ? "text-left mb-0 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal  md:mt-2"
                                                    : " mb-2 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal md:mt-2  text-left"
                                                }
                                              >
                                                {question.isMandatory ? (
                                                  <div className="text-sm text-red-500 ">
                                                    {SurveyAttributes(
                                                      "mandatoryText"
                                                    )
                                                      ? SurveyAttributes(
                                                        "mandatoryText"
                                                      )
                                                      : "This question is mandatory*"}
                                                  </div>
                                                ) : null}

                                                {/* {(questionindex+1).toString()+'. '} */}
                                                <span className="font-bold">
                                                  {" "}
                                                  {functionNameVariable(
                                                    surveyLanguageCode !==
                                                      ""
                                                      ? question &&
                                                        question.transl &&
                                                        question.transl[
                                                        surveyLanguageCode
                                                        ] &&
                                                        question.transl[
                                                          surveyLanguageCode
                                                        ].name
                                                        ? question.transl[
                                                          surveyLanguageCode
                                                        ].name
                                                        : question.name
                                                      : question.name
                                                  )}{" "}
                                                </span>

                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="quz">
                                                <QuestionHeader
                                                  surveyLanguageCode={
                                                    surveyLanguageCode
                                                  }
                                                  questionName={functionNameVariable(
                                                    surveyLanguageCode !==
                                                      ""
                                                      ? question &&
                                                        question.transl &&
                                                        question.transl[
                                                        surveyLanguageCode
                                                        ] &&
                                                        question.transl[
                                                          surveyLanguageCode
                                                        ].name
                                                        ? question.transl[
                                                          surveyLanguageCode
                                                        ].name
                                                        : question.name
                                                      : question.name
                                                  )}
                                                  QuesNo={
                                                    (
                                                      questionindex + 1
                                                    ).toString() + ". "
                                                  }
                                                />
                                              </div>

                                              <div
                                                style={{
                                                  color: getTheme(
                                                    "text",
                                                    "#FFFFFF"
                                                  ),
                                                }}
                                                className="px-4 mb-2 text-lg font-normal text-left text-white cursor-default quz md:text-2xl md:px-6 md:mt-4"
                                              >
                                                {question.isMandatory ? (
                                                  <div className="text-sm text-red-500 ">
                                                    {SurveyAttributes(
                                                      "mandatoryText"
                                                    )
                                                      ? SurveyAttributes(
                                                        "mandatoryText"
                                                      )
                                                      : "This question is mandatory*"}
                                                  </div>
                                                ) : null}

                                                {surveyLanguageRTL ?
                                                  <div className="flex flex-row-reverse gap-2">
                                                    <p> {" ." + (questionindex + 1).toString()}</p>
                                                    <p>{functionNameVariable(
                                                      surveyLanguageCode !==
                                                        ""
                                                        ? question &&
                                                          question.transl &&
                                                          question.transl[
                                                          surveyLanguageCode
                                                          ] &&
                                                          question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          ? question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          : question.name
                                                        : question.name
                                                    )}</p>

                                                  </div>
                                                  :
                                                  <div className="flex flex-row gap-2">
                                                    <p> {(questionindex + 1).toString() + ". "}</p>
                                                    <p>{functionNameVariable(
                                                      surveyLanguageCode !==
                                                        ""
                                                        ? question &&
                                                          question.transl &&
                                                          question.transl[
                                                          surveyLanguageCode
                                                          ] &&
                                                          question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          ? question.transl[
                                                            surveyLanguageCode
                                                          ].name
                                                          : question.name
                                                        : question.name
                                                    )}</p>
                                                  </div>
                                                }

                                              </div>
                                            </>
                                          )}
                                        </>
                                      )
                                    ) : null}

                                    {showFeedbackModal ? (
                                      <FeedbackModal
                                        closeModal={
                                          this.closeFeedbackModal
                                        }
                                        question={question}
                                        answersList={answersList}
                                        handleFeedbackInput={
                                          this.handleFeedbackInput
                                        }
                                        submitFeedback={
                                          this.submitFeedback
                                        }
                                        surveyScreenData={
                                          surveyScreenData
                                        }
                                        afterSubmitData={afterSubmitData}
                                      />
                                    ) : null}

                                    {/* Single Choice */}
                                    {question.templateId === 1 ? (
                                      <>
                                        <SingleChoice
                                          question={question}
                                          clicked={clicked}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          handleRadio={this.handleRadio}
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          isMobile={isMobile}
                                        />
                                      </>
                                    ) : null}

                                    {/* Multiple Choice */}
                                    {question.templateId === 2 ? (
                                      <>
                                        <MultipleChoice
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          handleCheckInput={
                                            this.handleCheckInput
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          clickOK={this.clickOK}
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* Multitext Box */}
                                    {question.templateId === 3 ? (
                                      <>
                                        <TextBox
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                          ansTextBoxChange1={
                                            this.ansTextBoxChange1
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          clickOK={this.clickOK}
                                        />
                                      </>
                                    ) : null}

                                    {/* Multitext Box */}
                                    {question.templateId === 4 ? (
                                      <>
                                        <MultiLineText
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                          ansTextBoxChange1={
                                            this.ansTextBoxChange1
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          clickOK={this.clickOK}
                                        />
                                      </>
                                    ) : null}

                                    {/* Rating Scale */}
                                    {question.templateId === 5 ? (
                                      <>
                                        <RatingScale
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          handleRadio={this.handleRadio}
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* NPS Scale 1-10*/}
                                    {question.templateId === 6 ? (
                                      <>
                                        <NPS
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          ansNPSInput={this.ansNPSInput}
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          isMobile={isMobile}
                                          questionsList={questionsList}
                                          CurrQuesIndex={CurrQuesIndex}
                                          nextQues={this.nextQues}
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                          getTheme={getTheme}
                                          surveyLanguageRTL={surveyLanguageRTL}
                                        />
                                      </>
                                    ) : null}

                                    {/* NPS Scale 0-10*/}
                                    {question.templateId === 16 ? (
                                      <>
                                        <NPSV2
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          ansNPSInput={this.ansNPSInput}
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          surveyLanguageRTL={surveyLanguageRTL}
                                        />
                                      </>
                                    ) : null}

                                    {/* Email */}
                                    {question.templateId === 7 ? (
                                      <>
                                        <Email
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          ansTextBoxChange1={
                                            this.ansTextBoxChange1
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          clickOK={this.clickOK}
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* Number */}
                                    {question.templateId === 8 ? (
                                      <>
                                        <Number
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          ansTextBoxChange1={
                                            this.ansTextBoxChange1
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          clickOK={this.clickOK}
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* Dropdown */}
                                    {question.templateId === 9 ? (
                                      <>
                                        <Dropdown
                                          question={question}
                                          clicked={clicked}
                                          answersList={answersList}
                                          handleRadio={this.handleRadio}
                                          handleDropdownSelect={
                                            this.handleDropdownSelect
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* Yes/No */}
                                    {question.templateId === 11 ? (
                                      <>
                                        <YesNo
                                          question={question}
                                          clicked={clicked}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          handleRadio={this.handleRadio}
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* Date */}
                                    {question.templateId === 12 ? (
                                      <>
                                        <Date
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={
                                            surveyLanguageCode
                                          }
                                          ansTextBoxChange1={
                                            this.ansTextBoxChange1
                                          }
                                          surveyScreenData={
                                            surveyScreenData
                                          }
                                          clickOK={this.clickOK}
                                          SurveyAttributes={
                                            SurveyAttributes
                                          }
                                        />
                                      </>
                                    ) : null}

                                    {/* Matrix  */}
                                    {question.templateId === 13 ? (
                                      <>
                                        <div
                                          className="px-4 md:px-0 lg:px-0 xl:px-0"
                                          style={{
                                            color: MandatoryMsg(
                                              question,
                                              questionindex + 1,
                                              CurrQuesIndex,
                                              this.state.unattemptList,
                                              this.state.showRedFlag
                                            ).color,
                                          }}
                                        >
                                          {
                                            MandatoryMsg(
                                              question,
                                              questionindex + 1,
                                              CurrQuesIndex,
                                              this.state.unattemptList,
                                              this.state.showRedFlag
                                            ).text
                                          }
                                        </div>

                                        <div className="w-full pt-2 mb-4">
                                          <div className="w-full overflow-x-auto overflow-y-auto whitespace-nowrap ">
                                            {isMobile ? (
                                              <>
                                                {FormatMatriXOptionHeading(
                                                  question,
                                                  false
                                                ) &&
                                                  FormatMatriXOptionHeading(
                                                    question,
                                                    false
                                                  ).length > 0
                                                  ? FormatMatriXOptionHeading(
                                                    question,
                                                    false
                                                  ).map(
                                                    (
                                                      heading,
                                                      optionindex
                                                    ) =>
                                                      heading.span ? (
                                                        <div className="pb-2 mx-4">
                                                          <div
                                                            className="w-full px-2 py-1 mb-1 font-bold"
                                                            style={{
                                                              color:
                                                                "#fff",
                                                              background:
                                                                surveyScreenData &&
                                                                  surveyScreenData.OptionColor1
                                                                  ? surveyScreenData.OptionColor1
                                                                  : "#fff",
                                                              background:
                                                                optionindex ===
                                                                  0
                                                                  ? "#1ec756"
                                                                  : optionindex ===
                                                                    1
                                                                    ? "#2362d9"
                                                                    : "#fff",
                                                              borderRight:
                                                                optionindex ==
                                                                  0
                                                                  ? "4px solid #1ec756"
                                                                  : null,
                                                            }}
                                                          >
                                                            {TranslationOptionMatrix(
                                                              question,
                                                              heading.name,
                                                              surveyLanguageCode,
                                                              2
                                                            )}
                                                          </div>
                                                          <div
                                                            style={{
                                                              color:
                                                                optionindex ===
                                                                  0
                                                                  ? "#1ec756"
                                                                  : optionindex ===
                                                                    1
                                                                    ? "#2362d9"
                                                                    : "#fff",
                                                            }}
                                                            className="px-2 font-bold text-gray-500 whitespace-pre-line"
                                                          >
                                                            {TranslationOptionMatrix(
                                                              question,
                                                              heading.name,
                                                              surveyLanguageCode,
                                                              3
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : null
                                                  )
                                                  : null}

                                                {/* MOBILE VIEW */}
                                                {question &&
                                                  question.SubQuestionList &&
                                                  question.SubQuestionList
                                                    .length > 0
                                                  ? question.SubQuestionList.map(
                                                    (
                                                      SubQues,
                                                      index
                                                    ) => (
                                                      <>
                                                        <div
                                                          className="pb-4 mx-4"
                                                          key={index}
                                                        >
                                                          <div
                                                            className="py-2 whitespace-pre-line"
                                                            style={{
                                                              color:
                                                                showNotCompletedFn(
                                                                  SubQues
                                                                )
                                                                  ? "#e31919"
                                                                  : surveyScreenData &&
                                                                    surveyScreenData.OptionColor2
                                                                    ? surveyScreenData.OptionColor2
                                                                    : "black",
                                                            }}
                                                          >
                                                            {(
                                                              questionindex +
                                                              1
                                                            ).toString() +
                                                              "." +
                                                              (
                                                                index +
                                                                1
                                                              ).toString() +
                                                              ") " +
                                                              TranslateQuestion(
                                                                surveyLanguageCode,
                                                                SubQues
                                                              )}
                                                          </div>
                                                          <table
                                                            className="w-full mx-auto text-center border border-gray-300"
                                                            style={{
                                                              background:
                                                                surveyScreenData &&
                                                                  surveyScreenData.OptionColor1
                                                                  ? surveyScreenData.OptionColor1
                                                                  : "#fff",
                                                            }}
                                                          >
                                                            <thead>
                                                              <tr>
                                                                {FormatMatriXOptionHeading(
                                                                  question,
                                                                  false
                                                                ) &&
                                                                  FormatMatriXOptionHeading(
                                                                    question,
                                                                    false
                                                                  )
                                                                    .length >
                                                                  0
                                                                  ? FormatMatriXOptionHeading(
                                                                    question,
                                                                    false
                                                                  ).map(
                                                                    (
                                                                      heading,
                                                                      optionindex
                                                                    ) => (
                                                                      <td
                                                                        style={{
                                                                          color:
                                                                            "#fff",
                                                                          background:
                                                                            surveyScreenData &&
                                                                              surveyScreenData.OptionColor1
                                                                              ? surveyScreenData.OptionColor1
                                                                              : "#fff",
                                                                          background:
                                                                            optionindex ===
                                                                              0
                                                                              ? "#1ec756"
                                                                              : optionindex ===
                                                                                1
                                                                                ? "#2362d9"
                                                                                : "#fff",
                                                                          borderRight:
                                                                            optionindex ==
                                                                              0
                                                                              ? "4px solid #1ec756"
                                                                              : null,
                                                                        }}
                                                                        key={
                                                                          optionindex
                                                                        }
                                                                        colSpan={
                                                                          heading.span
                                                                        }
                                                                        className="px-2 text-sm font-bold text-center border border-gray-300"
                                                                      >
                                                                        {TranslationOptionMatrix(
                                                                          question,
                                                                          heading.name,
                                                                          surveyLanguageCode,
                                                                          1
                                                                        )}
                                                                      </td>
                                                                    )
                                                                  )
                                                                  : null}
                                                              </tr>
                                                              <tr>
                                                                {FormatMatriXOptionHeading(
                                                                  question,
                                                                  true
                                                                ) &&
                                                                  FormatMatriXOptionHeading(
                                                                    question,
                                                                    true
                                                                  )
                                                                    .length >
                                                                  0
                                                                  ? FormatMatriXOptionHeading(
                                                                    question,
                                                                    true
                                                                  ).map(
                                                                    (
                                                                      option,
                                                                      optionindex
                                                                    ) => (
                                                                      <td
                                                                        style={{
                                                                          color:
                                                                            surveyScreenData &&
                                                                              surveyScreenData.OptionColor2
                                                                              ? surveyScreenData.OptionColor2
                                                                              : "black",
                                                                          background:
                                                                            surveyScreenData &&
                                                                              surveyScreenData.OptionColor1
                                                                              ? surveyScreenData.OptionColor1
                                                                              : "#fff",
                                                                          borderRight:
                                                                            optionindex ==
                                                                              1
                                                                              ? "4px solid #1ec756"
                                                                              : null,
                                                                        }}
                                                                        className="font-bold text-center border border-gray-300"
                                                                      >
                                                                        {surveyLanguageCode !==
                                                                          ""
                                                                          ? question &&
                                                                            question.transl &&
                                                                            question
                                                                              .transl[
                                                                            surveyLanguageCode
                                                                            ] &&
                                                                            question
                                                                              .transl[
                                                                              surveyLanguageCode
                                                                            ]
                                                                              .optionsList &&
                                                                            question
                                                                              .transl[
                                                                              surveyLanguageCode
                                                                            ]
                                                                              .optionsList[
                                                                            option
                                                                              .id
                                                                            ]
                                                                            ? question
                                                                              .transl[
                                                                              surveyLanguageCode
                                                                            ]
                                                                              .optionsList[
                                                                            option
                                                                              .id
                                                                            ]
                                                                            : option.name
                                                                          : option.name}
                                                                      </td>
                                                                    )
                                                                  )
                                                                  : null}
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr>
                                                                {FormatMatriXOptionHeading(
                                                                  question,
                                                                  true
                                                                ) &&
                                                                  FormatMatriXOptionHeading(
                                                                    question,
                                                                    true
                                                                  )
                                                                    .length >
                                                                  0
                                                                  ? FormatMatriXOptionHeading(
                                                                    question,
                                                                    true
                                                                  ).map(
                                                                    (
                                                                      option,
                                                                      optionindex
                                                                    ) => (
                                                                      <td
                                                                        style={{
                                                                          color:
                                                                            surveyScreenData &&
                                                                              surveyScreenData.OptionColor2
                                                                              ? surveyScreenData.OptionColor2
                                                                              : "black",
                                                                          background:
                                                                            surveyScreenData &&
                                                                              surveyScreenData.OptionColor1
                                                                              ? surveyScreenData.OptionColor1
                                                                              : "#fff",
                                                                          borderRight:
                                                                            optionindex ==
                                                                              1
                                                                              ? "4px solid #1ec756"
                                                                              : null,
                                                                        }}
                                                                        className="p-1 text-center border border-gray-300"
                                                                      >
                                                                        {showRadioMatrix(
                                                                          SubQues,
                                                                          option
                                                                        ) ? (
                                                                          <>
                                                                            {option.name ===
                                                                              "Yes" ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "#0ec934",
                                                                                }}
                                                                                onClick={() =>
                                                                                  this.handleMatrixRadio(
                                                                                    question,
                                                                                    SubQues,
                                                                                    option
                                                                                  )
                                                                                }
                                                                                className="material-icons"
                                                                              >
                                                                                task_alt
                                                                              </span>
                                                                            ) : (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "#e02626",
                                                                                }}
                                                                                onClick={() =>
                                                                                  this.handleMatrixRadio(
                                                                                    question,
                                                                                    SubQues,
                                                                                    option
                                                                                  )
                                                                                }
                                                                                className="material-icons"
                                                                              >
                                                                                highlight_off
                                                                              </span>
                                                                            )}
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            {/* {ShowNotAttempted?
                                      <span style={{color:'#e32b2b'}} onClick={() => this.handleMatrixRadio(question,SubQues, option)} 
                                      className="material-icons">radio_button_unchecked</span>
                                      :
                                      <span style={{color:(surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2:'black'}} 
                                      onClick={() => this.handleMatrixRadio(question,SubQues, option)} className="material-icons">radio_button_unchecked</span>
                                      } */}
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  surveyScreenData &&
                                                                                    surveyScreenData.OptionColor2
                                                                                    ? surveyScreenData.OptionColor2
                                                                                    : "black",
                                                                              }}
                                                                              onClick={() =>
                                                                                this.handleMatrixRadio(
                                                                                  question,
                                                                                  SubQues,
                                                                                  option
                                                                                )
                                                                              }
                                                                              className="material-icons"
                                                                            >
                                                                              radio_button_unchecked
                                                                            </span>
                                                                          </>
                                                                        )}
                                                                      </td>
                                                                    )
                                                                  )
                                                                  : null}
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </>
                                                    )
                                                  )
                                                  : null}
                                              </>
                                            ) : (
                                              <>
                                                {FormatMatriXOptionHeading(
                                                  question,
                                                  false
                                                ) &&
                                                  FormatMatriXOptionHeading(
                                                    question,
                                                    false
                                                  ).length > 0
                                                  ? FormatMatriXOptionHeading(
                                                    question,
                                                    false
                                                  ).map(
                                                    (
                                                      heading,
                                                      optionindex
                                                    ) =>
                                                      heading.span ? (
                                                        <div className="flex">
                                                          <div
                                                            className="px-2 py-1 mb-1 font-bold"
                                                            style={{
                                                              color:
                                                                "#fff",
                                                              background:
                                                                surveyScreenData &&
                                                                  surveyScreenData.OptionColor1
                                                                  ? surveyScreenData.OptionColor1
                                                                  : "#fff",
                                                              background:
                                                                optionindex ===
                                                                  0
                                                                  ? "#1ec756"
                                                                  : optionindex ===
                                                                    1
                                                                    ? "#2362d9"
                                                                    : "#fff",
                                                              borderRight:
                                                                optionindex ==
                                                                  0
                                                                  ? "4px solid #1ec756"
                                                                  : null,
                                                            }}
                                                          >
                                                            {TranslationOptionMatrix(
                                                              question,
                                                              heading.name,
                                                              surveyLanguageCode,
                                                              2
                                                            )}
                                                          </div>
                                                          <div
                                                            style={{
                                                              color:
                                                                optionindex ===
                                                                  0
                                                                  ? "#1ec756"
                                                                  : optionindex ===
                                                                    1
                                                                    ? "#2362d9"
                                                                    : "#fff",
                                                            }}
                                                            className="px-2 pt-1 font-bold text-gray-500 whitespace-pre-line"
                                                          >
                                                            {TranslationOptionMatrix(
                                                              question,
                                                              heading.name,
                                                              surveyLanguageCode,
                                                              3
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : null
                                                  )
                                                  : null}

                                                {question &&
                                                  question.SubQuestionList &&
                                                  question.SubQuestionList
                                                    .length > 0 ? (
                                                  <>
                                                    <div
                                                      className="overflow-y-auto"
                                                      id="chatbot"
                                                      style={{
                                                        height: "450px",
                                                      }}
                                                    >
                                                      <table
                                                        className="relative min-w-full "
                                                        style={{
                                                          background:
                                                            surveyScreenData &&
                                                              surveyScreenData.OptionColor1
                                                              ? surveyScreenData.OptionColor1
                                                              : "#fff",
                                                        }}
                                                      >
                                                        <thead className="">
                                                          <tr>
                                                            <td
                                                              style={{
                                                                color:
                                                                  surveyScreenData &&
                                                                    surveyScreenData.OptionColor2
                                                                    ? surveyScreenData.OptionColor2
                                                                    : "black",
                                                                background:
                                                                  surveyScreenData &&
                                                                    surveyScreenData.OptionColor1
                                                                    ? surveyScreenData.OptionColor1
                                                                    : "#fff",
                                                              }}
                                                              className="sticky top-0 p-1 border border-gray-300"
                                                              rowSpan={2}
                                                            ></td>
                                                            {FormatMatriXOptionHeading(
                                                              question,
                                                              false
                                                            ) &&
                                                              FormatMatriXOptionHeading(
                                                                question,
                                                                false
                                                              ).length > 0
                                                              ? FormatMatriXOptionHeading(
                                                                question,
                                                                false
                                                              ).map(
                                                                (
                                                                  heading,
                                                                  optionindex
                                                                ) => (
                                                                  <td
                                                                    key={
                                                                      optionindex
                                                                    }
                                                                    colSpan={
                                                                      heading.span
                                                                    }
                                                                    className="sticky top-0 p-2 font-bold text-center border border-gray-300"
                                                                    style={{
                                                                      color:
                                                                        "#fff",
                                                                      background:
                                                                        optionindex ===
                                                                          0
                                                                          ? "#1ec756"
                                                                          : optionindex ===
                                                                            1
                                                                            ? "#2362d9"
                                                                            : "#fff",
                                                                      borderRight:
                                                                        optionindex ==
                                                                          0
                                                                          ? "4px solid #1ec756"
                                                                          : null,
                                                                    }}
                                                                  >
                                                                    {TranslationOptionMatrix(
                                                                      question,
                                                                      heading.name,
                                                                      surveyLanguageCode,
                                                                      1
                                                                    )}
                                                                  </td>
                                                                )
                                                              )
                                                              : null}
                                                          </tr>

                                                          <tr>
                                                            {/* <td className="w-40 px-4 py-3 text-sm text-left border border-gray-300 "> </td> */}

                                                            {FormatMatriXOptionHeading(
                                                              question,
                                                              true
                                                            ) &&
                                                              FormatMatriXOptionHeading(
                                                                question,
                                                                true
                                                              ).length > 0
                                                              ? FormatMatriXOptionHeading(
                                                                question,
                                                                true
                                                              ).map(
                                                                (
                                                                  option,
                                                                  optionindex
                                                                ) => (
                                                                  <td
                                                                    key={
                                                                      optionindex
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        surveyScreenData &&
                                                                          surveyScreenData.OptionColor2
                                                                          ? surveyScreenData.OptionColor2
                                                                          : "black",
                                                                      background:
                                                                        surveyScreenData &&
                                                                          surveyScreenData.OptionColor1
                                                                          ? surveyScreenData.OptionColor1
                                                                          : "#fff",
                                                                      borderRight:
                                                                        optionindex ==
                                                                          1
                                                                          ? "4px solid #1ec756"
                                                                          : null,
                                                                    }}
                                                                    className="sticky px-4 py-1 text-sm font-bold text-center border border-gray-300 top-10 "
                                                                  >
                                                                    {surveyLanguageCode !==
                                                                      ""
                                                                      ? question &&
                                                                        question.transl &&
                                                                        question
                                                                          .transl[
                                                                        surveyLanguageCode
                                                                        ] &&
                                                                        question
                                                                          .transl[
                                                                          surveyLanguageCode
                                                                        ]
                                                                          .optionsList &&
                                                                        question
                                                                          .transl[
                                                                          surveyLanguageCode
                                                                        ]
                                                                          .optionsList[
                                                                        option
                                                                          .id
                                                                        ]
                                                                        ? question
                                                                          .transl[
                                                                          surveyLanguageCode
                                                                        ]
                                                                          .optionsList[
                                                                        option
                                                                          .id
                                                                        ]
                                                                        : option.name
                                                                      : option.name}
                                                                  </td>
                                                                )
                                                              )
                                                              : null}
                                                          </tr>
                                                        </thead>
                                                        <tbody className="">
                                                          {question.SubQuestionList.map(
                                                            (
                                                              SubQues,
                                                              index
                                                            ) => (
                                                              <tr
                                                                key={
                                                                  index
                                                                }
                                                              >
                                                                <td
                                                                  className="px-4 py-1 text-sm text-left whitespace-pre-line border border-gray-300 w-82"
                                                                  style={{
                                                                    color:
                                                                      showNotCompletedFn(
                                                                        SubQues
                                                                      )
                                                                        ? "#e31919"
                                                                        : surveyScreenData &&
                                                                          surveyScreenData.OptionColor2
                                                                          ? surveyScreenData.OptionColor2
                                                                          : "black",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {(
                                                                    questionindex +
                                                                    1
                                                                  ).toString() +
                                                                    "." +
                                                                    (
                                                                      index +
                                                                      1
                                                                    ).toString() +
                                                                    ") " +
                                                                    TranslateQuestion(
                                                                      surveyLanguageCode,
                                                                      SubQues
                                                                    )}
                                                                </td>

                                                                {FormatMatriXOptionHeading(
                                                                  question,
                                                                  true
                                                                ) &&
                                                                  FormatMatriXOptionHeading(
                                                                    question,
                                                                    true
                                                                  ).length >
                                                                  0
                                                                  ? FormatMatriXOptionHeading(
                                                                    question,
                                                                    true
                                                                  ).map(
                                                                    (
                                                                      option,
                                                                      optionindex
                                                                    ) => (
                                                                      <td
                                                                        key={
                                                                          optionindex
                                                                        }
                                                                        style={{
                                                                          borderRight:
                                                                            optionindex ==
                                                                              1
                                                                              ? "4px solid #1ec756"
                                                                              : null,
                                                                        }}
                                                                        className="px-4 py-1 text-center border border-gray-300"
                                                                      >
                                                                        {showRadioMatrix(
                                                                          SubQues,
                                                                          option
                                                                        ) ? (
                                                                          <>
                                                                            {option.name ===
                                                                              "Yes" ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "#0ec934",
                                                                                }}
                                                                                onClick={() =>
                                                                                  this.handleMatrixRadio(
                                                                                    question,
                                                                                    SubQues,
                                                                                    option
                                                                                  )
                                                                                }
                                                                                className="material-icons"
                                                                              >
                                                                                task_alt
                                                                              </span>
                                                                            ) : (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "#e02626",
                                                                                }}
                                                                                onClick={() =>
                                                                                  this.handleMatrixRadio(
                                                                                    question,
                                                                                    SubQues,
                                                                                    option
                                                                                  )
                                                                                }
                                                                                className="material-icons"
                                                                              >
                                                                                highlight_off
                                                                              </span>
                                                                            )}
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  surveyScreenData &&
                                                                                    surveyScreenData.OptionColor2
                                                                                    ? surveyScreenData.OptionColor2
                                                                                    : "black",
                                                                              }}
                                                                              onClick={() =>
                                                                                this.handleMatrixRadio(
                                                                                  question,
                                                                                  SubQues,
                                                                                  option
                                                                                )
                                                                              }
                                                                              className="material-icons"
                                                                            >
                                                                              radio_button_unchecked
                                                                            </span>
                                                                          </>
                                                                        )}
                                                                      </td>
                                                                    )
                                                                  )
                                                                  : null}
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </>
                                                ) : null}
                                              </>
                                            )}
                                          </div>
                                        </div>

                                        <div className="px-2 mb-24 md:px-2 ">
                                        </div>
                                      </>
                                    ) : null}
                                  </div>

                                  <div
                                    className={
                                      question.templateId === 13
                                        ? "px-4 -pt-4 justify-center items-center"
                                        : "px-4 pt-4 justify-center items-center"
                                    }
                                  >
                                    {this.state.unattemptList &&
                                      this.state.showRedFlag ? (
                                      this.state.unattemptList &&
                                        this.state.unattemptList.length >
                                        0 ? (
                                        <p className="px-4 font-light text-gray-100 md:px-6 md:text-md">
                                          <span
                                            style={{
                                              cursor: "default",
                                              color:
                                                surveyScreenData &&
                                                  surveyScreenData.textColor
                                                  ? surveyScreenData.textColor
                                                  : "black",
                                            }}
                                            className="font-semibold"
                                          >
                                            {
                                              "Please attempt mandatory question no. "
                                            }
                                          </span>
                                          {this.state.unattemptList.map(
                                            (elem, index) => (
                                              <span
                                                key={index}
                                                onClick={() =>
                                                  this.onMandatoryAttempt(
                                                    elem
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  fontWeight: "bold",
                                                  color:
                                                    surveyScreenData &&
                                                      surveyScreenData.textColor
                                                      ? surveyScreenData.textColor
                                                      : "black",
                                                }}
                                              >
                                                {index + 1 ===
                                                  this.state.unattemptList
                                                    .length ? (
                                                  <span
                                                    style={{
                                                      textDecoration:
                                                        "underline",
                                                    }}
                                                  >
                                                    {" " + elem}
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      textDecoration:
                                                        "underline",
                                                    }}
                                                  >
                                                    {elem + " , "}
                                                  </span>
                                                )}
                                              </span>
                                            )
                                          )}
                                        </p>
                                      ) : null
                                    ) : null}
                                  </div>

                                  {this.state.ShowSubmitButton ? (
                                    <>
                                      <div className="px-4 text-center text-red-500">
                                        {submitMandatory}
                                      </div>

                                      <div className="flex justify-center mt-6">
                                        <button
                                          onClick={() =>
                                            this.saveAnswers()
                                          }
                                          className="md:w-80 w-60  uppercase md:text-xl text-base font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-4 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden "
                                        >
                                          {SurveyAttributes(
                                            "SubmitButtonText"
                                          )
                                            ? SurveyAttributes(
                                              "SubmitButtonText"
                                            )
                                            : "Submit"}
                                        </button>
                                      </div>

                                    </>
                                  ) : null}
                                </>

                              ) : null
                            )
                            : null}

                          <div className="fixed bottom-0 left-0 w-full">
                            <div className="w-full px-4 py-4 mx-auto xl:w-11/12 xl:px-0 md:py-6 md:bg-transparent bg-white/10">
                              <div className="flex justify-end w-full">
                                <div className="flex flex-col items-center w-full space-y-4 md:justify-center 2xl:space-y-10 xl:space-y-10 ">
                                  <div className="flex justify-center w-full md:justify-end">
                                    <p
                                      className="text-sm text-white/80 "
                                      style={{
                                        color: getTheme("text", "#FFFFFF"),
                                      }}
                                    >
                                      © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"}
                                      <b
                                        className="ml-1 font-medium text-white/100"
                                        style={{
                                          color: getTheme("text", "#FFFFFF"),
                                        }}
                                      >
                                        {SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}


                  {/* Thank you section */}
                  {this.state.showThankyouScreen ? (
                    <>
                      <div className="h-[calc(100vh-8rem)] flex items-center justify-center">
                        <section className="flex items-start w-full px-6 py-10 mx-auto 2xl:w-8/12 xl:w-10/12 xl:px-0 md:space-x-10 ">
                          <div className="w-full mx-auto space-y-10 text-center lg:w-[40rem] md:p-10 p-6 bg-white rounded-2xl shadow-lg shadow-black/5">
                            <div className="space-y-2 text-base font-normal text-white/80 ">
                              <div className="flex flex-col items-center justify-center space-y-2">
                                {/* Circular Progress */}
                                {showViewReportThankYouButton ? (
                                  <>
                                    <div className="relative size-48">
                                      <svg className="size-full" width={180} height={180} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                        {/* Background Circle */}
                                        <circle cx={18} cy={18} r={16} fill="none" className="stroke-current  text-[#E3F2FD]" strokeWidth={3} />
                                        {/* Progress Circle inside a group with rotation */}
                                        <g className="origin-center -rotate-90 transform">
                                          <circle cx={18} cy={18} r={16} fill="none" className="stroke-current text-[#2196f3]" strokeWidth={3}
                                            strokeDasharray={100}
                                            strokeDashoffset={downloadStatusNo} />
                                        </g>
                                      </svg>
                                      {/* Percentage Text */}
                                      <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M66.6673 26.667L46.6673 6.66699H20.0007C18.2325 6.66699 16.5368 7.36937 15.2866 8.61961C14.0364 9.86986 13.334 11.5655 13.334 13.3337V66.667C13.334 68.4351 14.0364 70.1308 15.2866 71.381C16.5368 72.6313 18.2325 73.3337 20.0007 73.3337H60.0006C61.7688 73.3337 63.4645 72.6313 64.7147 71.381C65.9649 70.1308 66.6673 68.4351 66.6673 66.667V26.667ZM30.0007 63.3337H23.334V33.3337H30.0007V63.3337ZM43.334 63.3337H36.6673V43.3337H43.334V63.3337ZM56.6673 63.3337H50.0007V53.3337H56.6673V63.3337ZM46.6673 30.0003H43.334V13.3337L60.0006 30.0003H46.6673Z" fill="#2196F3" />
                                        </svg>
                                      </div>
                                    </div>

                                    <h1 className="text-2xl font-bold text-white md:text-4xl pt-4" style={{ color: getTheme("text", "#FFFFFF") }}>
                                      {SurveyAttributes("Generating Report") ? SurveyAttributes("Generating Report") + "!" : "Generating Report!"}</h1>
                                    <p className="pb-2 md:text-xl text-lg font-normal text-white/100" style={{ color: getTheme("text", "#FFFFFF") }}>
                                      {SurveyAttributes("Please wait a moment while report is generating") ? SurveyAttributes("Please wait a moment while report is generating") + "!" : "Please wait a moment while report is generating!"}</p>


                                  </>
                                ) : (
                                  <>
                                    <div className="md:w-[11rem] w-[9rem] md:h-[11rem] h-[9rem] rounded-full bg-white/10 flex justify-center items-center" style={{ background: getTheme("lightblue", hexToRgbA("#FFFFFF", "0.10")), }}>
                                      <div style={{ background: getTheme("textblue", hexToRgbA("#FFFFFF", "0.10")), }} className="md:w-[9rem] w-[7rem] h-[7rem] md:h-[9rem] rounded-full bg-white/20 flex justify-center items-center">
                                        <span className="material-icons md:text-[4.5rem] text-[4rem] text-white">  thumb_up  </span>
                                      </div>
                                    </div>
                                    <h1 className="text-2xl font-bold text-white md:text-4xl pt-4" style={{ color: getTheme("text", "#FFFFFF") }}>
                                      {SurveyAttributes("Thank You") ? SurveyAttributes("Thank You") : "Thank You!"}</h1>
                                    <p className="pb-2 md:text-xl text-lg font-normal text-white/100" style={{ color: getTheme("text", "#FFFFFF") }}>
                                      {SurveyAttributes("For taking the assessment survey") ? SurveyAttributes("For taking the assessment survey") + "." : "For taking the assessment survey.!"}
                                    </p>
                                  </>
                                )}


                              </div>


                            </div>

                            <div className="fixed bottom-0 left-0 w-full">
                              <div className="w-full px-4 py-4 mx-auto xl:w-11/12 xl:px-0 md:py-6 md:bg-transparent bg-white/10">
                                <div className="flex justify-end w-full">
                                  <div className="flex flex-col items-center w-full space-y-4 md:justify-center 2xl:space-y-10 xl:space-y-10 ">
                                    <div className="flex justify-center w-full md:justify-end">
                                      <p
                                        className="text-sm text-white/80 "
                                        style={{
                                          color: getTheme("text", "#FFFFFF"),
                                        }}
                                      >
                                        © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"}
                                        <b
                                          className="ml-1 font-medium text-white/100"
                                          style={{
                                            color: getTheme("text", "#FFFFFF"),
                                          }}
                                        >
                                          {SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  */}
                        </section>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              {(surveyStatus === 2 &&
                !(questionsList && questionsList.length > 0)) ||
                this.state.alreadySubmitted ? ( //already done
                <div className="">
                  <section className="px-6 py-6 mx-auto xl:w-3/6 lg:w-4/6 lg:px-0 md:px-6 ">
                    <div className="flex justify-center pb-16 mt-12">

                    </div>
                    <div className="flex items-center justify-center h-full">
                      <div className="text-center text-white">
                        <div className="flex justify-center pb-8">
                          <img
                            alt="pic"
                            src="pictures/surveydone.png"
                            className="md:w-auto w-6/6 "
                          />
                        </div>
                        <h1
                          className="text-3xl font-bold leading-10 tracking-widest text-gray-800 md:text-4xl leading_lose"
                          style={{}}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              color:
                                surveyScreenData && surveyScreenData.textColor
                                  ? surveyScreenData.textColor
                                  : "black",
                            }}
                            dangerouslySetInnerHTML={{
                              __html:

                                SurveyAttributes("AlreadyText")
                                  ? "<h1>" +
                                  SurveyAttributes("AlreadyText") +
                                  "</h1>"
                                  : "<h1>Survey Is Already Submitted !</h1>",
                            }}
                          />
                        </h1>
                        <p className="py-2 text-xl font-light opacity-80"></p>
                      </div>
                    </div>
                  </section>



                  <div
                    style={{
                      height: "10%",
                      backgroundColor:
                        bgType === 1 || afterSubmitData.bgType === 1
                          ? bgColor
                            ? bgColor
                            : afterSubmitData.bgColor
                              ? afterSubmitData.bgColor
                              : "#757575"
                          : "#f1f1f1",
                      backgroundImage:
                        bgType === 2 || afterSubmitData.bgType === 2
                          ? bgImage
                            ? bgImage
                            : afterSubmitData.bgImage
                              ? afterSubmitData.bgImage
                              : null
                          : null,
                    }}
                    className="fixed left-0 flex justify-center w-full bg-white bg-opacity-100 bottom-6"
                  >
                    <p
                      className="py-4 text-sm"
                      style={{ color: getTheme("text", "#FFFFFF") }}
                    >
                      {" "}
                      © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"}
                      <b className="font-semibold">{SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}</b>
                    </p>
                  </div>
                </div>
              ) : null}

              {/* survey expired page ----------------------------------------------------------------------------------------------- */}

              {surveyStatus === 3 &&
                !(questionsList && questionsList.length > 0) ? ( //expired
                <div>
                  <section className="flex items-center justify-center px-2 py-32 mx-auto overflow-y-hidden lg:w-4/6 lg:px-0 md:px-6">
                    {/* logo */}
                    <div>
                      <div className="flex justify-center">
                        <img src="img/expiry.png" className="w-32" />
                      </div>
                      <div className="flex items-center justify-center h-full">
                        <div
                          className="mt-6 text-2xl font-bold text-center"
                          style={{
                            textAlign: "center",
                            color:
                              surveyScreenData && surveyScreenData.textColor
                                ? surveyScreenData.textColor
                                : "black",
                          }}
                          dangerouslySetInnerHTML={{
                            __html:

                              SurveyAttributes("ExpiryText")
                                ? "<h1>" +
                                SurveyAttributes("ExpiryText") +
                                "</h1>"
                                : "<h1>This Survey is <br>Expired!</h1>",
                          }}
                        />
                      </div>
                    </div>
                  </section>

                  <div
                    style={{
                      height: "10%",
                      backgroundColor:
                        bgType === 1 || afterSubmitData.bgType === 1
                          ? bgColor
                            ? bgColor
                            : afterSubmitData.bgColor
                              ? afterSubmitData.bgColor
                              : "#757575"
                          : "#f1f1f1",
                      backgroundImage:
                        bgType === 2 || afterSubmitData.bgType === 2
                          ? bgImage
                            ? bgImage
                            : afterSubmitData.bgImage
                              ? afterSubmitData.bgImage
                              : null
                          : null,
                    }}
                    className="fixed left-0 flex justify-center w-full bg-white bg-opacity-100 bottom-6"
                  >
                    <p
                      className="py-4 text-sm"
                      style={{ color: getTheme("text", "#FFFFFF") }}
                    >
                      {" "}
                      © {SurveyAttributes("Powered by") ? SurveyAttributes("Powered by") : "Powered by"} <b className="font-semibold">{SurveyAttributes("HappyPlus") ? SurveyAttributes("HappyPlus") : "HappyPlus"}</b>
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : null}

        {check === 2 ? (
          <div
            className="w-full h-screen overflow-y-auto bg-[#FAFAFA]"  >
            <div className="z-20 bg-white">
              <LogoAssessmentSummaryHeader
                logout={onLogout}
                surveyScreenData={surveyScreenData}
                template={"v4"}
                details={getAssessmenrEmployeeDetailsData}
                handleReport={this.viewReport}
                surveyLanguageName={surveyLanguageName}
                handleLanguage={this.handleLanguage}
                LanguageList={LanguageList}

                crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                createdAt={createdAt}
              />

              <div className="sticky bottom-0 z-20 w-full px-4 mx-auto xl:w-11/12 xl:px-0">
                <div className="flex justify-between overflow-x-auto text-sm md:justify-start md:space-x-8 whitespace-nowrap lg:text-base">
                  <button onClick={() => this.handleMainMenu("Summary")} className={showMainMenu === "Summary" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <g clipPath="url(#clip0_4081_272)">
                        <path d="M35.556 6.66667H4.44488C3.85551 6.66667 3.29028 6.9008 2.87353 7.31755C2.45678 7.73429 2.22266 8.29952 2.22266 8.88889V31.1111C2.22266 31.7005 2.45678 32.2657 2.87353 32.6825C3.29028 33.0992 3.85551 33.3333 4.44488 33.3333H35.556C36.1454 33.3333 36.7106 33.0992 37.1273 32.6825C37.5441 32.2657 37.7782 31.7005 37.7782 31.1111V8.88889C37.7782 8.29952 37.5441 7.73429 37.1273 7.31755C36.7106 6.9008 36.1454 6.66667 35.556 6.66667ZM35.556 31.1111H4.44488V8.88889H35.556V31.1111Z" fill="#212121" />
                        <path d="M9.99978 15.5556H29.9998C30.2945 15.5556 30.5771 15.4385 30.7855 15.2301C30.9938 15.0217 31.1109 14.7391 31.1109 14.4444C31.1109 14.1498 30.9938 13.8671 30.7855 13.6588C30.5771 13.4504 30.2945 13.3333 29.9998 13.3333H9.99978C9.7051 13.3333 9.42248 13.4504 9.21411 13.6588C9.00574 13.8671 8.88867 14.1498 8.88867 14.4444C8.88867 14.7391 9.00574 15.0217 9.21411 15.2301C9.42248 15.4385 9.7051 15.5556 9.99978 15.5556Z" fill="#212121" />
                        <path d="M9.99978 20H29.9998C30.2945 20 30.5771 19.8829 30.7855 19.6746C30.9938 19.4662 31.1109 19.1836 31.1109 18.8889C31.1109 18.5942 30.9938 18.3116 30.7855 18.1032C30.5771 17.8948 30.2945 17.7778 29.9998 17.7778H9.99978C9.7051 17.7778 9.42248 17.8948 9.21411 18.1032C9.00574 18.3116 8.88867 18.5942 8.88867 18.8889C8.88867 19.1836 9.00574 19.4662 9.21411 19.6746C9.42248 19.8829 9.7051 20 9.99978 20Z" fill="#212121" />
                        <path d="M9.99978 24.4445H21.1109C21.4056 24.4445 21.6882 24.3274 21.8966 24.119C22.1049 23.9106 22.222 23.628 22.222 23.3333C22.222 23.0387 22.1049 22.756 21.8966 22.5477C21.6882 22.3393 21.4056 22.2222 21.1109 22.2222H9.99978C9.7051 22.2222 9.42248 22.3393 9.21411 22.5477C9.00574 22.756 8.88867 23.0387 8.88867 23.3333C8.88867 23.628 9.00574 23.9106 9.21411 24.119C9.42248 24.3274 9.7051 24.4445 9.99978 24.4445Z" fill="#212121" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4081_272">
                          <rect width="40" height="40" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Summary</span>
                  </button>


                  {true &&
                    <button onClick={() => this.handleMainMenu("Top5Summary")} className={showMainMenu === "Top5Summary" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                        <path d="M5 35V31.6667C5 29.8986 5.70238 28.2029 6.95262 26.9526C8.20286 25.7024 9.89856 25 11.6667 25H18.3333C20.1014 25 21.7971 25.7024 23.0474 26.9526C24.2976 28.2029 25 29.8986 25 31.6667V35M26.6667 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58718C31.1871 8.75653 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.1871 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6667 18.1333M35 35V31.6667C34.9915 30.1953 34.4965 28.768 33.5921 27.6073C32.6877 26.4467 31.4247 25.6178 30 25.25M8.33333 11.6667C8.33333 13.4348 9.03571 15.1305 10.286 16.3807C11.5362 17.631 13.2319 18.3333 15 18.3333C16.7681 18.3333 18.4638 17.631 19.714 16.3807C20.9643 15.1305 21.6667 13.4348 21.6667 11.6667C21.6667 9.89856 20.9643 8.20286 19.714 6.95262C18.4638 5.70238 16.7681 5 15 5C13.2319 5 11.5362 5.70238 10.286 6.95262C9.03571 8.20286 8.33333 9.89856 8.33333 11.6667Z" stroke="#212121" stroke-opacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <span>Top 5 Insight</span>
                    </button>
                  }


                  <button onClick={() => this.handleMainMenu("Report")} className={showMainMenu === "Report" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <path d="M5 35V31.6667C5 29.8986 5.70238 28.2029 6.95262 26.9526C8.20286 25.7024 9.89856 25 11.6667 25H18.3333C20.1014 25 21.7971 25.7024 23.0474 26.9526C24.2976 28.2029 25 29.8986 25 31.6667V35M26.6667 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58718C31.1871 8.75653 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.1871 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6667 18.1333M35 35V31.6667C34.9915 30.1953 34.4965 28.768 33.5921 27.6073C32.6877 26.4467 31.4247 25.6178 30 25.25M8.33333 11.6667C8.33333 13.4348 9.03571 15.1305 10.286 16.3807C11.5362 17.631 13.2319 18.3333 15 18.3333C16.7681 18.3333 18.4638 17.631 19.714 16.3807C20.9643 15.1305 21.6667 13.4348 21.6667 11.6667C21.6667 9.89856 20.9643 8.20286 19.714 6.95262C18.4638 5.70238 16.7681 5 15 5C13.2319 5 11.5362 5.70238 10.286 6.95262C9.03571 8.20286 8.33333 9.89856 8.33333 11.6667Z" stroke="#212121" stroke-opacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>Report</span>
                  </button>


                  {
                    !isSchool ?
                      <>
                        <button onClick={() => this.handleMainMenu("VideoTutorials")} className={showMainMenu === "VideoTutorials" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                            <path d="M25 16.6667L32.5883 12.8733C32.8424 12.7464 33.1246 12.6865 33.4083 12.6992C33.692 12.712 33.9677 12.7971 34.2093 12.9463C34.4509 13.0956 34.6504 13.3041 34.7888 13.5521C34.9271 13.8001 34.9998 14.0793 35 14.3633V25.6367C34.9998 25.9207 34.9271 26.1999 34.7888 26.4479C34.6504 26.6959 34.4509 26.9044 34.2093 27.0537C33.9677 27.2029 33.692 27.288 33.4083 27.3008C33.1246 27.3135 32.8424 27.2536 32.5883 27.1267L25 23.3333V16.6667ZM5 13.3333C5 12.4493 5.35119 11.6014 5.97631 10.9763C6.60143 10.3512 7.44928 10 8.33333 10H21.6667C22.5507 10 23.3986 10.3512 24.0237 10.9763C24.6488 11.6014 25 12.4493 25 13.3333V26.6667C25 27.5507 24.6488 28.3986 24.0237 29.0237C23.3986 29.6488 22.5507 30 21.6667 30H8.33333C7.44928 30 6.60143 29.6488 5.97631 29.0237C5.35119 28.3986 5 27.5507 5 26.6667V13.3333Z" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span>Video Tutorials</span>
                        </button>
                        <button onClick={() => this.handleMainMenu("Experts")} className={showMainMenu === "Experts" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                            <path d="M12.5 22.5H22.5V25H12.5V22.5ZM12.5 16.25H27.5V18.75H12.5V16.25ZM12.5 28.75H18.75V31.25H12.5V28.75Z" fill="#212121" fill-opacity="0.7" />
                            <path d="M31.25 6.25H27.5V5C27.5 4.33696 27.2366 3.70107 26.7678 3.23223C26.2989 2.76339 25.663 2.5 25 2.5H15C14.337 2.5 13.7011 2.76339 13.2322 3.23223C12.7634 3.70107 12.5 4.33696 12.5 5V6.25H8.75C8.08696 6.25 7.45107 6.51339 6.98223 6.98223C6.51339 7.45107 6.25 8.08696 6.25 8.75V35C6.25 35.663 6.51339 36.2989 6.98223 36.7678C7.45107 37.2366 8.08696 37.5 8.75 37.5H31.25C31.913 37.5 32.5489 37.2366 33.0178 36.7678C33.4866 36.2989 33.75 35.663 33.75 35V8.75C33.75 8.08696 33.4866 7.45107 33.0178 6.98223C32.5489 6.51339 31.913 6.25 31.25 6.25ZM15 5H25V10H15V5ZM31.25 35H8.75V8.75H12.5V12.5H27.5V8.75H31.25V35Z" fill="#212121" fill-opacity="0.7" />
                          </svg>
                          <span>Experts</span>
                        </button> </> : null
                  }

                </div>
              </div>
            </div>

            {(showMainMenu === "Summary") && (<>
              {surveyLanguageRTL ?
                <SummaryRTL
                  handleReport={this.viewReport}
                  getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                  crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleSummaryReport={this.handleSummaryReport}
                  getTheme={getTheme}
                  backSummary={this.state.backSummary}
                  showMyExpertList={this.state.showMyExpertList}
                  showMainSummaryModal={this.state.showMainSummaryModal}
                  contactExpertSection={this.state.contactExpertSection}
                  topFiveTalent={this.state.topFiveTalent}
                  handleViewReport={this.handleViewReport}
                  closeReportummaryModal={this.closeReportummaryModal}
                  top5Talent={this.state.top5Talent}
                  handleShowMainSummaryModal={this.handleShowMainSummaryModal}
                  handleShowMainSummaryModalBack={this.handleShowMainSummaryModalBack}
                  handleExpertContactModalBack={this.handleExpertContactModalBack}
                  handleExpertContactModal={this.handleExpertContactModal}
                  crrDetail={this.state.crrDetail}
                  crrCodeUserDetails={this.state.crrCodeUserDetails}
                  handleInputExpertForm={this.handleInputExpertForm}
                  errorsCrrCodeUserDetails={this.state.errorsCrrCodeUserDetails}
                  bookExpert={this.bookExpert}
                  userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
                  handleTestPayment={handleTestPayment}
                  viewMyExpert={this.viewMyExpert}
                  getExpertOrderBookListData={getExpertOrderBookListData}
                  getTalentResourcesData={getTalentResourcesData}
                  handlePlayVideo={this.handlePlayVideo}
                  viewMyVideoReport={this.viewMyVideoReport}
                  isSchool={isSchool}
                  surveyLanguageRTL={surveyLanguageRTL}
                />
                : <Summary
                  handleReport={this.viewReport}
                  getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                  crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleSummaryReport={this.handleSummaryReport}
                  getTheme={getTheme}
                  backSummary={this.state.backSummary}
                  showMyExpertList={this.state.showMyExpertList}
                  showMainSummaryModal={this.state.showMainSummaryModal}
                  contactExpertSection={this.state.contactExpertSection}
                  topFiveTalent={this.state.topFiveTalent}
                  handleViewReport={this.handleViewReport}
                  closeReportummaryModal={this.closeReportummaryModal}
                  top5Talent={this.state.top5Talent}
                  handleShowMainSummaryModal={this.handleShowMainSummaryModal}
                  handleShowMainSummaryModalBack={this.handleShowMainSummaryModalBack}
                  handleExpertContactModalBack={this.handleExpertContactModalBack}
                  handleExpertContactModal={this.handleExpertContactModal}
                  crrDetail={this.state.crrDetail}
                  crrCodeUserDetails={this.state.crrCodeUserDetails}
                  handleInputExpertForm={this.handleInputExpertForm}
                  errorsCrrCodeUserDetails={this.state.errorsCrrCodeUserDetails}
                  bookExpert={this.bookExpert}
                  userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
                  handleTestPayment={handleTestPayment}
                  viewMyExpert={this.viewMyExpert}
                  getExpertOrderBookListData={getExpertOrderBookListData}
                  getTalentResourcesData={getTalentResourcesData}
                  handlePlayVideo={this.handlePlayVideo}
                  viewMyVideoReport={this.viewMyVideoReport}
                  isSchool={isSchool}
                  surveyLanguageRTL={surveyLanguageRTL}
                />}

            </>)}

            {showMainMenu === "Report" && (<>


              {showSchoolReportModal && isSchool ?
                <>
                  <AssessmentReportSchool
                    closeReportModal={this.closeReportModal}
                    getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                    onAssessmentByEmpID={this.onAssessmentByEmpID}
                    getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                    createdAt={createdAt}
                    EditButtonFn={EditButtonFn}
                    EditDetailsFn={EditDetailsFn}
                    handleDownloadStatus={this.handleDownloadStatus}
                    loading={loading}
                    isUser={true}
                    surveyLanguageRTL={surveyLanguageRTL}
                  />
                </>
                : null}


              {showReportModal ? (
                <AssessmentReport
                  closeReportModal={this.closeReportModal}
                  getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                  onAssessmentByEmpID={this.onAssessmentByEmpID}
                  getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={this.handleDownloadStatus}
                  loading={loading}
                  isUser={true}
                  surveyLanguageRTL={surveyLanguageRTL}
                  surveyLanguageCode={surveyLanguageCode}
                  downloadRTLReport={this.downloadRTLReport}
                />
              ) : null}

              {showSummaryReport ? (
                <AssessmentSummary
                  closeReportModal={this.closeSummaryReportModal}
                  getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                  onAssessmentByEmpID={this.onAssessmentByEmpID}
                  getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={this.handleDownloadStatus}
                  loading={loading}
                  isUser={true}
                  surveyLanguageRTL={surveyLanguageRTL}
                  downloadRTLReport={this.downloadRTLReport}
                />
              ) : null}

              {showManagerReportModal ? (
                <AssessmentManagerReport
                  closeReportModal={this.closeManagerReportModal}
                  getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                  getAssessmenrEmployeeDetailsData={crrEmpDetails}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={this.handleDownloadStatus}
                  loading={loading}
                  isUser={true}
                  surveyLanguageRTL={surveyLanguageRTL}
                  downloadRTLReport={this.downloadRTLReport}
                />
              ) : null}

              {showHiringReportModal ? (
                <AssessmentHiringReport
                  closeReportModal={this.closeHiringReportModal}
                  getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                  getAssessmenrEmployeeDetailsData={crrEmpDetails}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={this.handleDownloadStatus}
                  loading={loading}
                  isUser={true}
                  surveyLanguageRTL={surveyLanguageRTL}
                  downloadRTLReport={this.downloadRTLReport}
                />
              ) : null}

            </>)}

            {showMainMenu === "Top5Summary" && (<>

              <AssessmentTop5Summary
                closeReportModal={this.closeReportModal}
                getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                onAssessmentByEmpID={this.onAssessmentByEmpID}
                getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                createdAt={createdAt}
                EditButtonFn={EditButtonFn}
                EditDetailsFn={EditDetailsFn}
                handleDownloadStatus={this.handleDownloadStatus}
                loading={loading}
                isUser={true}
                surveyLanguageRTL={surveyLanguageRTL}
                surveyLanguageCode={surveyLanguageCode}
                downloadRTLReport={this.downloadRTLReport}
              />


            </>)}

            {showMainMenu === "VideoTutorials" && (<>
              <VideoCourse
                getTalentResourcesData={getTalentResourcesData}
                getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                EditButtonFn={EditButtonFn}
                EditDetailsFn={EditDetailsFn}
                getTheme={getTheme}
                closeVideoCourse={this.closeVideoCourse}
                showReportModal={showReportModal}
                showSummaryReport={showSummaryReport}
                surveyLanguageRTL={surveyLanguageRTL}
              />
            </>)}

            {(showMainMenu === "Experts") && (<>
              <ExpertScreen
                handleReport={this.viewReport}
                getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                createdAt={createdAt}
                EditButtonFn={EditButtonFn}
                EditDetailsFn={EditDetailsFn}
                handleSummaryReport={this.handleSummaryReport}
                getTheme={getTheme}
                backSummary={this.state.backSummary}
                showMyExpertList={this.state.showMyExpertList}
                showMainSummaryModal={this.state.showMainSummaryModal}
                contactExpertSection={this.state.contactExpertSection}
                topFiveTalent={this.state.topFiveTalent}
                handleViewReport={this.handleViewReport}
                closeReportummaryModal={this.closeReportummaryModal}
                handleShowMainSummaryModal={this.handleShowMainSummaryModal}
                handleShowMainSummaryModalBack={this.handleShowMainSummaryModalBack}
                handleExpertContactModalBack={this.handleExpertContactModalBack}
                handleExpertContactModal={this.handleExpertContactModal}
                crrDetail={this.state.crrDetail}
                crrCodeUserDetails={this.state.crrCodeUserDetails}
                handleInputExpertForm={this.handleInputExpertForm}
                errorsCrrCodeUserDetails={this.state.errorsCrrCodeUserDetails}
                bookExpert={this.bookExpert}
                userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
                handleTestPayment={handleTestPayment}
                viewMyExpert={this.viewMyExpert}
                getExpertOrderBookListData={getExpertOrderBookListData}
                getTalentResourcesData={getTalentResourcesData}
                handlePlayVideo={this.handlePlayVideo}
                viewMyVideoReport={this.viewMyVideoReport}
                surveyLanguageRTL={surveyLanguageRTL}
              />
            </>)}
          </div>
        ) : null}


        {showVideoModal ? (
          <VideoFrameModal
            getTalentResourcesData={getTalentResourcesData}
            dimension={showVideoModal}
            closeVideoModal={this.closeVideoModal}
          />
        ) : null}


      </div>
    );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, assessment } = state;
  return {
    loggingIn,
    questions,
    users,
    assessment
  };
}

export default connect(mapStateToProps)(QuestionPublish);