// @ts-check

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';


import React from "react";
import ReactDOM from "react-dom";

import App from "./components/App";
import './Index.css';

import * as serviceWorker from "./serviceWorker";
import { store } from './_helpers';
import { Provider } from 'react-redux';
 

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          welcome: 'Welcome to my app',
          greeting: 'Hello, {{name}}!',
        },
      },
      fr: {
        translation: {
          welcome: 'Bienvenue sur mon application',
          greeting: 'Bonjour, {{name}} !',
        },
      },
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes for us
    },
  });

ReactDOM.render(
// @ts-ignore
<>
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
</>
 , document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
