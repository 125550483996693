import React, { useState, useEffect } from "react";
import { assessmentActions } from "../../../../_actions";

function Header(props) {
  let {
    handleSignInPage,
    userDetails,
    logout,
    gotoPage,
    cartCount,
    isLogin,
    props2,
    prodList,
    gotoMyAccount,
  } = props;


  const [drop, setDrop] = useState(true);

  const gotoPage2 = (field) => {
    props2.history.push(`/assessment/` + field);

  };

  const gotoProd = (field) => {


    props2.history.push(`/assessment/productdetails/team/` + field.toString());
    window.location.reload();

  };
  function matchProdFn(field) {
    console.log("window.location--->", window.location.hash)
    let pathname = window.location.hash;
    if (pathname === ('#/assessment/productdetails/team/' + field.toString())) {
      return true
    } else {
      return false
    }
  }

  const gotoPageV3 = (field) => {
    if (userDetails && userDetails.email) {
      props2.history.push(`/assessment/` + field);
    } else {
      props2.history.push(`/assessment/sign-in`);
    }
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  function matchPathFn(name) {
    console.log("window.location--->", window.location.hash)
    let pathname = window.location.hash;
    if (pathname === ('#/assessment/' + name)) {
      return true
    } else {
      return false
    }
  }


  return (
    <header className="flex items-center w-full h-20 bg-white border-b ">
      <nav className="flex items-center justify-between w-full px-4 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
        <div className="flex flex-row items-center gap-4">
          <div className="lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="lg:hidden block p-2 text-[#3D405B] bg-slate-100 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=" icon icon-tabler icon-tabler-menu-2"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                <line x1="4" y1="6" x2="20" y2="6" />{" "}
                <line x1="4" y1="12" x2="20" y2="12" />{" "}
                <line x1="4" y1="18" x2="20" y2="18" />
              </svg>
            </button>
          </div>
          <img
            onClick={() => gotoPage2("home")}
            alt="talent_plus_logo"
            src="/img/talent_plus_logo.svg"
            className="bg-cover cursor-pointer md:w-36 w-28"
            width={280}
            height={100}
          />
        </div>

        <div className="flex items-center gap-4 md:gap-8">
          <div
            className={`${isMobileMenuOpen ? "block" : "hidden"
              } lg:flex lg:items-center gap-8 `}
          >
            <div className="absolute left-0 z-40 flex flex-col items-start w-full gap-8 p-6 bg-white border-b shadow-md lg:items-center lg:flex-row lg:relative lg:top-auto lg:p-0 top-20 lg:bg-transparent lg:w-auto lg:border-none lg:shadow-none">
              <a
                href="/#/assessment/home"
                // onClick={() => gotoPage2("home")}
                className={matchPathFn("home") ? "flex justify-between items-center text-base cursor-pointer text-[#2196f3]  lg:w-auto w-full lg:h-20 lg:border-b-[4px] lg:border-[#2196f3] border-[#2196f3]" : "flex justify-between items-center text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B]  lg:w-auto w-full lg:h-20 lg:border-b-[4px] lg:border-transparent lg:hover:border-[#2196f3]"}
              >
                Home{" "}
              </a>

              <div className="lg:flex hidden justify-between items-center space-x-1 text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B] relative  group lg:w-auto w-full h-20 border-b-[4px] border-transparent hover:border-[#2196f3]">
                <span>TalentPlusStrengths</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="2.2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>

                <div className="w-fit whitespace-nowrap  rounded-b-md absolute group-hover:block hidden  transition-all transform top-20 -left-1 opacity-0 group-hover:opacity-100 shadow-md  bg-white   duration-150 overflow-hidden text-[#3D405B] z-40">
                  <a
                    href="/#/assessment/how-talentplus-strength-works"
                    className={matchPathFn("how-talentplus-strength-works") ? "px-4 py-2.5 hover:bg-slate-100 block text-[#2196f3]" : "px-4 py-2.5 hover:bg-slate-100 block"}
                  >
                    How TalentPlusStrengths Works
                  </a>
                  <a
                    href="/#/assessment/the-4-domains-and-22-themes"
                    activeclassname='text-[#2196f3]'
                    className={matchPathFn("the-4-domains-and-22-themes") ? "px-4 py-2.5 hover:bg-slate-100 block text-[#2196f3]" : "px-4 py-2.5 hover:bg-slate-100 block"}
                  >
                    The 4 Domains and, 22 themes
                  </a>
                </div>
              </div>

              <div className="block w-full space-y-4 lg:hidden">
                <div
                  onClick={() => setDrop(drop === 1 ? 0 : 1)}
                  className={
                    drop === 1
                      ? "flex justify-between items-center space-x-1 text-base cursor-pointer  text-[#2196F3] relative  group lg:w-auto w-full"
                      : "flex justify-between items-center space-x-1 text-base cursor-pointer  text-[#3D405B] relative  group lg:w-auto w-full"
                  }
                >
                  <span>TalentPlusStrengths </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={drop === 1 ? "rotate-180" : "rotate-0"}
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    strokeWidth="2.2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
                {drop === 1 ? (
                  <div

                    className="w-full text-base space-y-4 text-[#3D405B]  "
                  >
                    <a
                      href="/#/assessment/how-talentplus-strength-works"
                      className="block hover:bg-slate-100">
                      How TalentPlusStrengths Works
                    </a>
                    <a
                      href="/#/assessment/the-4-domains-and-22-themes"
                      className="block hover:bg-slate-100"
                    >
                      The 4 Domains and, 22 themes
                    </a>
                  </div>
                ) : null}
              </div>
              <div className="lg:flex hidden justify-between items-center space-x-1 text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B] relative  group lg:w-auto w-full h-20 border-b-[4px] border-transparent hover:border-[#2196f3]">
                <span>Reports</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="2.2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
                <div className="w-fit whitespace-nowrap  rounded-b-md absolute group-hover:block hidden  transition-all transform top-20 -left-1 opacity-0 group-hover:opacity-100  bg-white shadow-md   duration-150 overflow-hidden text-[#3D405B] z-40">
                  {prodList && prodList.length > 0
                    ? prodList.map((item) => (
                      <span
                        key={item.id}
                        // href={`/#/assessment/${item.id}`}
                        onClick={() => gotoProd(item.id)}
                        className={matchProdFn(item.id) ? "px-4 py-2.5 hover:bg-slate-100 block text-[#2196f3]" : "px-4 py-2.5 hover:bg-slate-100 block"} >
                        {item.name}
                      </span>
                    ))
                    : null}
                </div>
              </div>

              <div className="block w-full space-y-4 lg:hidden">
                <div
                  onClick={() => setDrop(drop === 2 ? 0 : 2)}
                  className={
                    drop === 2
                      ? "flex justify-between items-center space-x-1 text-base cursor-pointer  text-[#2196F3] relative  group lg:w-auto w-full"
                      : "flex justify-between items-center space-x-1 text-base cursor-pointer  text-[#3D405B] relative  group lg:w-auto w-full"
                  }
                >
                  <span>Reports</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={drop === 2 ? "rotate-180" : "rotate-0"}
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    strokeWidth="2.2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
                {drop === 2 ? (
                  <div className="w-full text-base space-y-4 text-[#3D405B]  ">
                    {prodList && prodList.length > 0
                      ? prodList.map((item) => (
                        <span
                          key={item.id}
                          // href={`/#/assessment/${item.id}`}
                          onClick={() => gotoProd(item.id)}
                          className={matchProdFn(item.id) ? "block hover:bg-slate-100 text-[#2196F3]" : "block hover:bg-slate-100"}
                        >
                          {item.name}
                        </span>
                      ))
                      : null}

                  </div>
                ) : null}
              </div>

              <a
                href="/#/assessment/impact-coach"
                // onClick={() => gotoPage2("impact-coach")}
                className={matchPathFn("impact-coach") ? "flex justify-between items-center text-base cursor-pointer text-[#2196f3]  lg:w-auto w-full lg:h-20 lg:border-b-[4px] lg:border-[#2196f3] border-[#2196f3]" : "flex justify-between items-center text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B]  lg:w-auto w-full lg:h-20 lg:border-b-[4px] lg:border-transparent lg:hover:border-[#2196f3]"}
              >
                Impact Coach{" "}
              </a>

              <a
                activeclassname='text-[#2196f3]'
                href="/#/assessment/contact-us"
                // onClick={() => gotoPage("contact-us")}
                className={matchPathFn("contact-us") ? "flex justify-between items-center text-base cursor-pointer text-[#2196f3]  lg:w-auto w-full lg:h-20 lg:border-b-[4px] lg:border-[#2196f3] border-[#2196f3]" : "flex justify-between items-center text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B]  lg:w-auto w-full lg:h-20 lg:border-b-[4px] lg:border-transparent lg:hover:border-[#2196f3]"}
              >
                Contact us{" "}
              </a>

              {/* <div className="relative flex items-center h-20 gap-2 cursor-pointer group">
                <div className="flex items-center space-x-2">
                  <img src="https://cdn-icons-png.flaticon.com/512/555/555417.png" className="w-6" />
                  <p className="text-[#3D405B]">English</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={drop === 2 ? "rotate-180" : "rotate-0"}
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="2.2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                  <polyline points="6 9 12 15 18 9" />
                </svg>
                <div className="w-40 -ml-2 opacity-0 duration-150 transition-all transform  group-hover:opacity-100 group-hover:block hidden  bg-white absolute top-20 left-0 border shadow-lg  text-sm text-[#3D405B] capitalize">
                  <div className="flex items-center p-2 space-x-2 transition-all hover:bg-gray-100">
                    <img src="https://cdn-icons-png.flaticon.com/512/206/206606.png" className="w-6" />
                    <p>Hindi</p> 
                  </div>
                  <div className="flex items-center p-2 space-x-2 transition-all hover:bg-gray-100">
                    <img src="https://cdn-icons-png.flaticon.com/512/206/206724.png" className="w-6" />
                    <p>Spainsh</p> 
                  </div>
                  <div className="flex items-center p-2 space-x-2 transition-all hover:bg-gray-100">
                    <img src="https://cdn-icons-png.flaticon.com/512/5111/5111624.png" className="w-6" />
                    <p>chiness</p> 
                  </div>
                  <div className="flex items-center p-2 space-x-2 transition-all hover:bg-gray-100">
                    <img src="https://cdn-icons-png.flaticon.com/512/3373/3373319.png" className="w-6" />
                    <p>Arabic</p> 
                  </div>
                  <div className="flex items-center p-2 space-x-2 transition-all hover:bg-gray-100">
                    <img src="https://cdn-icons-png.flaticon.com/512/206/206657.png" className="w-6" />
                    <p>French</p> 
                  </div>

                </div>

              </div> */}
            </div>
          </div>
          <div className="flex flex-row items-center gap-4 md:gap-8 ">
            {!isLogin ? (
              <div className="flex items-center space-x-1 text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B] relative  group lg:w-auto  h-20 border-b-[4px] border-transparent hover:border-[#2196f3]">
                <div className="md:text-lg text-base font-semibold text-[#3D405B]  h-12  w-12 bg-slate-100 rounded-full flex items-center justify-center uppercase">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-user"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#2c3e50"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  </svg>
                </div>

                <div className="w-80 text-left  rounded-b-md absolute group-hover:block hidden  transition-all transform top-20 -right-1 opacity-0 group-hover:opacity-100 p-6 bg-white shadow-xl  duration-150 overflow-hidden text-[#3D405B] space-y-4 z-40">
                  {userDetails && userDetails.email ? (
                    <>
                      <div className="flex items-center w-full gap-3">
                        <div>
                          <div className="md:text-lg text-base font-semibold text-[#3D405B]  h-12  w-12 bg-slate-100 rounded-full flex items-center justify-center uppercase">
                            {userDetails && userDetails.name
                              ? userDetails.name.slice(0, 2)
                              : "NA"}
                          </div>
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-lg font-semibold truncate ">
                            {userDetails && userDetails.name
                              ? (userDetails.name).slice(0, 20) + (userDetails.name.length > 20 ? "..." : "")
                              : "NA"}
                          </h1>
                          <p className="text-sm truncate w-52">
                            {userDetails && userDetails.email
                              ? (userDetails.email).slice(0, 20) + (userDetails.name.length > 20 ? "..." : "")
                              : ""}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleSignInPage()}
                        className="bg-[#2196F3] text-white py-2 px-4 rounded uppercase  w-full"
                      >
                        Sign in
                      </button>
                      <div className="text-base text-[#3D405B]">
                        <a
                          href="/#/assessment/sign-up"
                          // onClick={() => gotoPage("sign-up")}
                          className="text-[#3D405B] md:text-base text-sm"
                        >
                          Don't have an account?
                          <span className="text-[#2196F3] pl-2">Sign up</span>
                        </a>
                      </div>
                    </>
                  )}

                  <p className="text-[#3D405B]/70 py-4 border-b">My Account</p>
                  <button
                    onClick={() => gotoMyAccount("profile-summary")}
                    className="flex flex-row items-center   py-1.5    hover:text-[#2196f3]  gap-3 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline points="5 12 3 12 12 3 21 12 19 12" />
                      <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                      <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                    </svg>
                    <span>Profile Summary</span>
                  </button>

                  <button
                    onClick={() => gotoMyAccount("my-orders")}
                    className="flex flex-row items-center py-1.5   hover:text-[#2196f3]  gap-3 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="6" cy="19" r="2" />
                      <circle cx="17" cy="19" r="2" />
                      <path d="M17 17h-11v-14h-2" />
                      <path d="M6 5l14 1l-1 7h-13" />
                    </svg>
                    <span>My Order</span>
                  </button>

                  <button
                    onClick={() => gotoMyAccount("assessment-code")}
                    className="flex flex-row items-center py-1.5    hover:text-[#2196f3]  gap-3 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                      <rect x="9" y="3" width="6" height="4" rx="2" />
                      <line x1="9" y1="12" x2="9.01" y2="12" />
                      <line x1="13" y1="12" x2="15" y2="12" />
                      <line x1="9" y1="16" x2="9.01" y2="16" />
                      <line x1="13" y1="16" x2="15" y2="16" />
                    </svg>
                    <span>Assessment Code</span>
                  </button>
                  {userDetails && userDetails.email ? (
                    <button
                      onClick={() => logout()}
                      className="flex flex-row items-center py-1.5    hover:text-[#2196f3]  gap-3 w-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-user"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                        <path d="M7 12h14l-3 -3m0 6l3 -3" />
                      </svg>
                      <span>Sign Out</span>
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}

            <button
              onClick={() => gotoPage("my-cart")}
              className=" text-base cursor-pointer  text-[#3D405B] relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-shopping-cart"
                width="34"
                height="34"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="6" cy="19" r="2" />
                <circle cx="17" cy="19" r="2" />
                <path d="M17 17h-11v-14h-2" />
                <path d="M6 5l14 1l-1 7h-13" />
              </svg>
              <span className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-sm font-light text-white bg-blue-500 rounded-full">
                {cartCount}
              </span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}
export default Header;
