import React, { useRef } from 'react';
  
export default function Top5Talents(props) {

  let { handleShowMainSummaryModal, EditDetailsFn, getAssessmentReportByEmpIDData, crrDetail,
    EditButtonFn, handleShowMainSummaryModalBack, isSchool } = props;
 
  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - Talent every day?"
        : "How to deal with a person who has - Talent?";
    let final = itemName.replace("-", element.dimension.trim());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      descNew = temp.split("@").filter((prev) => prev !== "");
    }
    return descNew;
  }

  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    return list;
  }



  function checkForGeneralship(bucket, dimension, isSchool) {

    console.log('isSchool---->', isSchool)
    let text = "";

    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else {

        if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
          text = "old";
        }
      }
    }

    return text
  }



  return (
    <div className='lg:px-8 px-4 space-y-6'>

      {/* Back to Summary page code */}

      <div className="flex items-center py-4 px-8 space-x-4 border-b bg-white -mx-8">
        <span className="p-1 text-white duration-150 bg-[#2196F3] rounded-full cursor-pointer" onClick={handleShowMainSummaryModalBack}>
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 6l-6 6l6 6" /></svg>
        </span>
        <h1 className="text-base font-medium md:text-lg text-[#212121]">Back to Summary</h1>
      </div>

      <div className='flex'>
        <h1 className="text-xl font-semibold text-[#212121] md:font-semibold md:text-2xl">Top 5 Talents</h1>
      </div>

      <div className='flex w-full my-5 space-x-3 overflow-x-auto whitespace-nowrap'>
        {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
          getAssessmentReportByEmpIDData.map((element, index) =>
            (index < 5) ?
              (crrDetail.dimension === element.dimension) ?
                <div
                  style={{
                    background:
                      element && element.colorCode
                        ? element.colorCode
                        : "#DDDDDD",
                  }}
                  className='flex items-center px-4 py-2 space-x-2 border rounded-full cursor-pointer'>
                  <div className='bg-white rounded-full '>
                    <img src={
                      "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                      element.dimension.toLowerCase() +
                      ".svg"
                    } alt='dimension' className='w-8 h-8' />
                  </div>
                  <h1 className="flex text-base font-medium text-white md:text-lg flex-nowrap ">{index + 1}. {EditDetailsFn(element, 'dimension', element.dimension)}</h1>
                </div>
                :
                <div onClick={() => handleShowMainSummaryModal(element)} className='flex items-center px-4 py-2 bg-white border rounded-full cursor-pointer' >
                  <div
                    style={{
                      background: hexToRgbA(
                        element && element.colorCode
                          ? element.colorCode
                          : "#DDDDDD",
                        "0.20"
                      ),
                    }}
                    className='mr-3 bg-[#673AB7]/10 rounded-full'>
                    <img src={
                      "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                      element.dimension.toLowerCase() +
                      ".svg"
                    } alt='dimension' className='w-9 h-9' />
                  </div>
                  <h1 className="flex md:text-lg text-base text-[#3D405B] flex-nowrap font-medium ">{index + 1}. {EditDetailsFn(element, 'dimension', element.dimension)}</h1>
                </div>
              : null) : null}

      </div>

      {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
        getAssessmentReportByEmpIDData.map((element, index) =>
          (index < 5) ?
            (crrDetail.dimension === element.dimension) ?
              <>
                <div className="items-center justify-between p-4 space-y-4 bg-white border shadow-sm md:p-8 md:py-6 rounded-xl xl:flex lg:space-y-0"
                  style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.20"), }}>
                  <div className="">
                    <button
                      style={{
                        background:
                          element && element.colorCode
                            ? element.colorCode
                            : "#DDDDDD",
                      }}
                      className="items-center justify-center px-4 py-1 text-base font-medium text-white uppercase rounded-full cursor-pointer"> {EditDetailsFn(element, "bucket", element.bucket)}</button>
                    <div className="p-2 space-y-3">
                      <h1 className="pt-3 text-xl font-semibold text-[#3D405B] md:text-2xl">
                        <span>{index + 1}. </span>{EditDetailsFn(element, 'dimension', element.dimension)}</h1>
                      <p className="text-base text-[#3D405B]">
                        {EditDetailsFn(element, 'desc', element.details.desc)}</p>
                    </div>
                  </div>
                </div>

                {/* how to use section */}
                <div className='space-y-6'>
                  {getBulletPoints(element, "desc3") &&
                    getBulletPoints(element, "desc3").length > 0
                    ? chunkList(getBulletPoints(element, "desc3")).map(
                      (textList) => (
                        <div>
                          <div className="flex items-start mb-4 space-x-4">
                            <img src={
                              `/img/assessment/circleicons/` + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                              element.bucket.toLowerCase() +
                              `.svg`
                            }
                              alt='Group 17207 (3).svg' className='w-9 h-9' />
                            <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">
                              {EditButtonFn(
                                getTitle(element, 1),
                                getTitle(element, 1)
                              )}
                            </h1>
                          </div>

                          {textList && textList.length > 0
                            ? textList.map((text, index6) => (
                              <div
                                style={{
                                  borderColor:
                                    element && element.colorCode
                                      ? element.colorCode
                                      : "#DDDDDD",
                                }}
                                className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg  h-auto cursor-pointer">
                                <div className="px-6 py-8 mb-4">
                                  <p className="text-base text-[#3D405B]">
                                    {EditDetailsFn(
                                      element,
                                      "desc3" + (index6 + 1),
                                      text
                                    )} </p>
                                </div>
                              </div>
                            ))
                            : null}

                          {/* <Swiper
                            ref={sliderRef1}
                            navigation={true}
                            keyboard={true}
                            loop={true}
                            autoplay={true}
                            modules={[Autoplay, Navigation, Keyboard]}
                            spaceBetween={20}
                            // slidesPerView={4}
                            breakpoints={{
                              640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                              768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                              1366: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                              },
                            }}
                            className=" mySwiper">
                            {textList && textList.length > 0
                              ? textList.map((text, index6) => (
                                <SwiperSlide
                                  style={{
                                    borderColor:
                                      element && element.colorCode
                                        ? element.colorCode
                                        : "#DDDDDD",
                                  }}
                                  className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg  h-auto cursor-pointer">
                                  <div className="px-6 py-8">
                                    <p className="text-base text-[#3D405B]">
                                      {EditDetailsFn(
                                        element,
                                        "desc3" + (index6 + 1),
                                        text
                                      )} </p>
                                  </div>
                                </SwiperSlide>
                              ))
                              : null}

                          </Swiper> */}


                        </div>
                      )
                    )
                    : null}


                  <div>
                    <div className="flex items-start mb-4 space-x-4">
                      <img src={
                        `/img/assessment/idea/blindspot/` + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                        element.bucket.toLowerCase() +
                        `.svg`
                      }
                        alt='Group 17207 (3).svg' className='w-9 h-9' />
                      <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">{EditButtonFn(
                        "What needs to be looked out for_Possible Blind Spots?",
                        "What needs to be looked out for (Possible Blind Spots)?"
                      )}</h1>
                    </div>

                    {getBulletPoints(element, "desc4") &&
                      getBulletPoints(element, "desc4").length > 0
                      ? getBulletPoints(element, "desc4").map(
                        (text, index) =>

                          <div style={{
                            borderColor:
                              element && element.colorCode
                                ? element.colorCode
                                : "#DDDDDD",
                          }}
                            className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg  h-auto cursor-pointer">
                            <div className="px-6 py-8 mb-4">
                              <p className="text-base text-[#3D405B]">{EditDetailsFn(
                                element,
                                "desc4" + (index + 1),
                                text
                              )}</p>
                            </div>
                          </div>
                      ) : null}

                    {/* <Swiper
                      ref={sliderRef2}
                      navigation={true}
                      keyboard={true}
                      loop={true}
                      autoplay={true}
                      modules={[Autoplay, Navigation, Keyboard]}
                      spaceBetween={20}
                      // slidesPerView={4}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1366: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                      className=" mySwiper">
                      {getBulletPoints(element, "desc4") &&
                        getBulletPoints(element, "desc4").length > 0
                        ? getBulletPoints(element, "desc4").map(
                          (text, index) =>

                            <SwiperSlide style={{
                              borderColor:
                                element && element.colorCode
                                  ? element.colorCode
                                  : "#DDDDDD",
                            }}
                              className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg  h-auto cursor-pointer">
                              <div className="px-6 py-8">
                                <p className="text-base text-[#3D405B]">{EditDetailsFn(
                                  element,
                                  "desc4" + (index + 1),
                                  text
                                )}</p>
                              </div>
                            </SwiperSlide>
                        ) : null}
                    </Swiper> */}
                  </div>

                  <div>
                    {getBulletPoints(element, "desc5") &&
                      getBulletPoints(element, "desc5").length > 0
                      ? chunkList(getBulletPoints(element, "desc5")).map(
                        (textList, index5) => (
                          <>
                            <div className="flex items-center py-4 space-x-4">
                              {/* <img src={"/img/assessment/idea/watermark/" + element.bucket.toLowerCase() + "_watermark.svg"} alt='Group 17207 (3).svg' className='w-9 h-9' /> */}
                              <img src={"/img/assessment/idea/howto/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                                element.bucket.toLowerCase() + ".svg"} alt='Group 17207 (3).svg' className='w-9 h-9' />
                              <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">
                                {EditButtonFn(
                                  getTitle(element, 2),
                                  getTitle(element, 2)
                                )}</h1>
                            </div>

                            {textList && textList.length > 0
                              ? textList.map((text, index6) => (
                                <div style={{
                                  borderColor:
                                    element && element.colorCode
                                      ? element.colorCode
                                      : "#DDDDDD",
                                }}
                                  className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg h-auto cursor-pointer">
                                  <div className="px-6 py-8 mb-4">
                                    <p className="text-base text-[#3D405B]">
                                      {EditDetailsFn(
                                        element,
                                        "desc5" + (index6 + 1),
                                        text
                                      )}</p>
                                  </div>
                                </div>
                              ))
                              : null}

                            {/* <Swiper
                              ref={sliderRef3}
                              navigation={true}
                              keyboard={true}
                              loop={true}
                              autoplay={true}
                              modules={[Autoplay, Navigation, Keyboard]}
                              spaceBetween={20}
                              // slidesPerView={4}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 20,
                                },
                                1366: {
                                  slidesPerView: 4,
                                  spaceBetween: 20,
                                },
                              }}
                              className=" mySwiper">
                              {textList && textList.length > 0
                                ? textList.map((text, index6) => (
                                  <SwiperSlide style={{
                                    borderColor:
                                      element && element.colorCode
                                        ? element.colorCode
                                        : "#DDDDDD",
                                  }}
                                    className="bg-white hover:bg-[#2196F3]/10 border-l-8 border-blue-500 hover:shadow-xl rounded-lg  h-auto cursor-pointer">
                                    <div className="px-6 py-8">
                                      <p className="text-base text-[#3D405B]">
                                        {EditDetailsFn(
                                          element,
                                          "desc5" + (index6 + 1),
                                          text
                                        )}</p>
                                    </div>
                                  </SwiperSlide>
                                ))
                                : null}
                            </Swiper> */}
                          </>
                        )
                      )
                      : null}
                  </div>
                </div>
              </>
              : null : null) : null}

    </div>
  );
}
