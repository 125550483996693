import React from 'react';

export default function Component(props) {

  const { question, answersList, ansNPSInput, surveyLanguageCode, surveyScreenData } = props;
  let [showValue, handleValue] = React.useState(0)


  function NPSColor(question) {

    function NPSName(optionID, optionName, surveyLanguageCode, question) {

      return (surveyLanguageCode !== '') ?
        (question && question.transl &&
          question.transl[surveyLanguageCode] &&
          question.transl[surveyLanguageCode].optionsList &&
          question.transl[surveyLanguageCode].optionsList[optionID]) ?
          question.transl[surveyLanguageCode].optionsList[optionID] : optionName
        : optionName
    }


    let Color = "#a3a3a3";
    let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
    let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
    if (showValue) {
      answer = showValue;
    }


    if (answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6) {
      Color = "#ed3e34";
      if (answer === 1 || answer === 2) {
        Name = NPSName("Extremelydissatisfied", "Extremely dissatisfied", surveyLanguageCode, question)
      }
      else if (answer === 3 || answer === 4) {
        Name = NPSName("Dissatisfied", "Dissatisfied", surveyLanguageCode, question)
        console.log('Dissatisfied Name: ', Name)
      }
      else if (answer === 5 || answer === 6) {
        Name = NPSName("Neither", "Neither", surveyLanguageCode, question)
      }
    }
    else if (answer === 7 || answer === 8) {
      Color = "#fcb817";
      Name = NPSName("Satisfied", "Satisfied", surveyLanguageCode, question)
    }
    else if (answer === 9 || answer === 10) {
      Color = "#0acc4b";
      Name = NPSName("Extremelysatisfied", "Extremely satisfied", surveyLanguageCode, question)
    }

    return {
      color: Color,
      name: Name
    }
  }



  return (
    <>
      <div className="grid grid-cols-10 gap-4 py-6 mx-4 text-sm lg:text-lg">
        {/* 1 */}
        {[...Array(10).keys()].map((value, index) =>
          <>
            {((value + 1).toString() === answersList[answersList.findIndex(item => item.questionId === question.id)].answer) ?

              <span onMouseEnter={() => handleValue((value + 1))} onClick={() => ansNPSInput((value + 1).toString(), question)} key={index}
                style={{ background: NPSColor(question, surveyLanguageCode).color }}
                className="flex items-center justify-center p-4 px-3 py-2 font-medium text-white bg-gray-100 rounded-md cursor-pointer md:p-4 md:py-4 hover:bg-red-400">
                {value + 1}
              </span>

              :
              <>
                {(value + 1) <= (showValue || parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer)) ?
                  <span onMouseEnter={() => handleValue((value + 1))} onClick={() => ansNPSInput((value + 1).toString(), question)} key={index}
                    style={{ background: NPSColor(question, surveyLanguageCode).color }}
                    className="flex items-center justify-center p-4 px-3 py-2 font-medium text-white bg-gray-100 rounded-md cursor-pointer md:p-4 md:py-4 hover:bg-red-400">
                    {value + 1}
                  </span>
                  :
                  <span onMouseEnter={() => handleValue((value + 1))} onClick={() => ansNPSInput((value + 1).toString(), question)} key={index}
                    className="flex items-center justify-center p-4 px-3 py-2 font-medium bg-gray-100 rounded-md cursor-pointer md:p-4 md:py-4 hover:bg-gray-400 hover:text-white">
                    {value + 1}
                  </span>
                }
              </>
            }
          </>
        )}

      </div>
      <div style={{ background: NPSColor(question, surveyLanguageCode).color }}
        className="py-1 mx-2 mb-2 text-center text-white bg-red-500 rounded-sm">{NPSColor(question, surveyLanguageCode).name}</div>
      {/* <div className="flex justify-between text-sm font-medium"><p>Not likely</p> <p>Very likely</p></div> */}


    </>
  );
}