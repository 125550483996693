import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import "./styles.css";
import { Helmet } from "react-helmet";
import Ready from "../../../components/Ready";
import SwiperComponent from "./swiperComponent";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      zoomed: false,
      saveSuccess: false,
      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }


  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleImageClick = (value) => {
    this.setState({ currentImage: value });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });



    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());

    this.props.dispatch(
      assessmentActions.getAssessmentPackagesById({
        id: this.props.match.params.id2,
      })
    );
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
  }




  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentSignInSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }



  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };
  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  changeQuantity = (type) => {
    let { formData } = this.state;
    if (type === "add") {
      formData["quantity"] += 1;
    } else if (type === "sub" && formData["quantity"] > 1) {
      formData["quantity"] -= 1;
    }
    this.setState({ formData });
  };

  handleInputChange = (e) => {
    let { formData } = this.state;
    let { value } = e.target;
    // console.log('value > 0:::', value > 0, "????", value);
    // if (value > 0) {
    formData["quantity"] = Number(value)
    this.setState({ formData });

    // this.setState({ quantity: Number(value) });
    // }
  }

  // handleIncrement = (data) => {
  //   this.setState({ quantity: this.state.quantity + 1 });
  // }

  // handleDecrement = (data) => {
  //   if (this.state.quantity > 0) {
  //     this.setState({ quantity: this.state.quantity - 1 });
  //   }
  // }

  // handleInputChange = (e) => {
  //   // console.log('kkkkkkkkk::::', e.target.value);
  //   let { value } = e.target;
  //   // console.log('value > 0:::', value > 0, "????", value);
  //   // if (value > 0) {
  //     this.setState({ quantity: Number(value) });
  //   // }
  // }






  // handleOptionSubmit = () => {
  //   let { formData } = this.state;
  //   if(formData && formData["productId"]){
  //     let data = {
  //       quantity: formData["quantity"],
  //       productId: formData["productId"],
  //       licensee: formData["licensee"]
  //     }
  //     this.props.dispatch(assessmentActions.userAssessmentPackageBuy(data, this.props));
  //   }
  // }

  handleAddToCart = () => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;
    setTimeout(() => {
      this.setState({ saveSuccess: true });
    }, 4000);
    if (
      !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
    ) {
      this.props.history.push(`/assessment/` + "sign-in");
    } else {
      let { formData } = this.state;
      if (formData && formData["productId"]) {
        let data = {
          quantity: formData["quantity"],
          productId: formData["productId"],
          licensee: formData["licensee"],
        };
        this.props.dispatch(
          assessmentActions.assessmentAddToCart(data, this.props)
        );
      }
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };
  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/contact-us");
  };

  handleMouseMove = (e) => {
    if (!this.state.zoomed) {
      this.setState({ zoomed: true });
    }
  };

  handleMouseLeave = () => {
    if (this.state.zoomed) {
      this.setState({ zoomed: false });
    }
  };

  render() {
    const { assessment } = this.props;
    // const { currentImage, formData } = this.state;
    const { formData } = this.state;
    let {
      userAssessmentUserProfileData,
      getAssessmentPackagesByIdData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;

    console.log(
      "getAssessmentPackagesByIdData--->",
      getAssessmentPackagesByIdData
    );

    function getDetails(field) {
      return field &&
        getAssessmentPackagesByIdData &&
        getAssessmentPackagesByIdData[field]
        ? getAssessmentPackagesByIdData[field]
        : "NA";
    }
    function getDetailsList(field) {
      return field &&
        getAssessmentPackagesByIdData &&
        getAssessmentPackagesByIdData[field]
        ? getAssessmentPackagesByIdData[field]
        : [];
    }

    let imageSrcList = [];
    let code = getDetails("code");
    let productno = 1;
    if (code) {
      let listNo = [1, 2, 3];
      if (code === "Top5TalentplusStrengths") {
        productno = 1;
        listNo = [1, 2, 3, 4, 5, 6];
      } else if (code === "TalentPlusStrengths21") {
        productno = 2;
        listNo = [1, 2, 3, 4, 5];
      } else if (code === "TalentplusManagerStrengths") {
        productno = 3;
        listNo = [1, 2, 3, 4];
      } else if (code === "TalentPlusHiringStrengths") {
        productno = 4;
        listNo = [1, 2, 3, 4];
      } else if (code === "TeenCareerGuidance") {
        productno = 5;
        listNo = [1, 2, 3, 4, 5];
      }
      if (listNo && listNo.length > 0) {
        listNo.forEach((element) => {
          let path = "/photos/" + code + "_" + element + ".webp";
          console.log("path--->", path);
          imageSrcList.push(path);
        });
      }
    }
    const { zoomed } = this.state;
    // const { slides, activeIndex } = this.props;

    // console.log('RENDER_____this.state.formData????', this.state.formData);

    return (
      <div className="">
        {productno === 1 ?
          <Helmet>
            <meta charSet="utf-8" />
            <title>Discover your Top 5 TalentPlusStrengths, Unique to your Capabilities and Talents.</title>
            <meta name="description" content="Gain valuable insights for personal and professional growth, leveraging core abilities for success and fulfillment." />
            <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/products" />
          </Helmet>
          : null}
        {productno === 2 ?
          <Helmet>
            <meta charSet="utf-8" />
            <title>Discover your TalentPlusStrengths 22 Strengths, Unique to your Capabilities and Talents.</title>
            <meta
              name="description"
              content="Gain valuable insights for personal and professional growth, leveraging core abilities for success and fulfillment."
            />
            <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/products" />
          </Helmet>
          : null}
        {productno === 3 ?
          <Helmet>
            <meta charSet="utf-8" />
            <title>TalentPlusStrengths Manager Strengths</title>
            <meta
              name="description"
              content="TalentPlusStrengths manager strengths are specific qualities and capabilities that contribute to effective management and leadership."
            />
            <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/products" />
          </Helmet>
          : null}
        {productno === 4 ?
          <Helmet>
            <meta charSet="utf-8" />
            <title>TalentPlusStrengths for Hiring</title>
            <meta
              name="description"
              content="Discover qualities desirable in candidates such as adaptability, communication, problem-solving, teamwork, leadership and cultural fit."
            />
            <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/products" />
          </Helmet>
          : null}

        <HeaderTransprent
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          gotoPage={this.gotoPage}
          cartCount={
            getAssessmentCartCountData ? getAssessmentCartCountData : 0
          }
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        {getAssessmentPackagesByIdData &&
          imageSrcList &&
          imageSrcList.length > 0 &&
          getAssessmentPackagesShortListData &&
          getAssessmentPackagesShortListData.length > 0 ? (
          <>
            {/* bread comes  */}
            {/* <div className="2xl:w-9/12 xl:w-11/12 w-full  mx-auto xl:px-0 px-4 flex md:flex-row items-center py-10  text-sm text-[#3D405B]">
              <span>Home</span>/<span>TalentPlusStrengths Assessments</span>/
              <span>Top 5 TalentPlusStrengths Strengths</span>
            </div>  */}

            <section className="flex flex-col w-full gap-10 px-4 mx-auto my-10 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
              <div className="w-full space-y-4">
                <div className={`relative ${zoomed ? "zoomed" : ""}`}>
                  <img
                    src={
                      this.state && this.state.currentImage
                        ? this.state.currentImage
                        : imageSrcList && imageSrcList.length > 0
                          ? imageSrcList[0]
                          : ""
                    }
                    alt="Zoomable"
                    className="cursor-pointer"
                    onMouseMove={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}
                  />
                </div>
                <div className="flex flex-row gap-4 ">
                  {imageSrcList && imageSrcList.length > 0
                    ? imageSrcList.map((element, index) => (
                      <div
                        onClick={() => this.handleImageClick(element)}
                        className={
                          (
                            this.state.currentImage
                              ? this.state.currentImage === element
                              : index === 0
                          )
                            ? "ring-offset-2 ring-2 ring-[#2196F3] cursor-pointer border-spacing-6 border-separate"
                            : "ring-offset-2 ring-2 ring-[#fff] cursor-pointer border-spacing-6 border-separate"
                        }
                      >
                        <img src={element} className="object-cover w-24 h-24" alt="Not found" />
                      </div>
                    ))
                    : null}

                  {/* <div onClick={this.handleImageClick} className={this.state.currentImage ? "ring-offset-2 ring-2 ring-[#2196F3] cursor-pointer border-spacing-6 border-separate" : "ring-offset-2 ring-2 ring-[#fff] cursor-pointer border-spacing-6 border-separate"}>
                <img
                  src="/img/assessment/Rectangle.png"
                  className="object-cover w-24 h-24 "
                />
              </div>
              <div onClick={this.handleImageClick} className={this.state.currentImage ? "ring-offset-2 ring-2 ring-[#2196F3] cursor-pointer border-spacing-6 border-separate" : "ring-offset-2 ring-2 ring-[#fff] cursor-pointer border-spacing-6 border-separate"}>
                <img
                  src="/img/Home/summary_report.webp"
                  className="object-cover w-24 h-24 "
                />
              </div>
              <div onClick={this.handleImageClick} className={this.state.currentImage ? "ring-offset-2 ring-2 ring-[#2196F3] cursor-pointer border-spacing-6 border-separate" : "ring-offset-2 ring-2 ring-[#fff] cursor-pointer border-spacing-6 border-separate"}>
                <img
                  src="/img/Home/understand-strongest_talentplus-strength.webp"
                  className="object-cover w-24 h-24 "
                />
              </div> */}
                </div>
              </div>
              {/* text */}
              <div className="w-full text-[#3D405B] space-y-6 ">
                <h1 data-aos="fade-up" className="text-3xl">{getDetails("name")}</h1>

                {productno !== 3 ?
                  <h2 data-aos="fade-up" className="text-4xl font-semibold">
                    <span className="font-[roboto]">₹</span>
                    {getDetails("price")}
                  </h2>
                  : null}

                <p data-aos="fade-up">{getDetails("desc")}</p>
                <p data-aos="fade-up">
                  <b className="font-medium ">Please note:</b> This product is
                  delivered digitally.
                </p>


                {(productno === 3) || (productno === 4) ?
                  <>
                    <button
                      data-aos="fade-up"
                      onClick={() => this.gotoPage("contact-us")}
                      className="bg-[#2196F3] text-[#fff] hover:bg-[#1976D2] w-3/6 justify-center duration-150 transition-all py-3 px-8 rounded uppercase space-x-2 flex items-center font-medium  cursor-pointer"
                    >
                      <span>Connect Us</span>
                    </button>
                  </>
                  : <>
                    <div data-aos="fade-up" className="flex flex-row items-center gap-4">
                      {formData["licensee"] === "self" ? (
                        <>
                          <span className="font-medium ">Qty:</span>
                          <div className="flex items-stretch overflow-hidden">
                            <span className="text-lg font-semibold text-center">
                              {formData["quantity"]}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <span className="font-medium ">Qty:</span>
                          <div className="flex items-stretch overflow-hidden border rounded-md">
                            <button
                              onClick={() => this.changeQuantity("sub")}
                              className="p-1.5 px-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition-all duration-100"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-plus"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                {" "}
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                              </svg>
                            </button>
                            <span className="w-20 text-lg font-semibold text-center ">
                              {/* {formData["quantity"]} */}
                              <input type="text" id="first_name" value={this.state.formData && this.state.formData["quantity"] ? this.state.formData["quantity"] : ""} onChange={this.handleInputChange} className="appearance-none text-center outline-none block w-full p-2.5" required></input>
                            </span>
                            <button
                              onClick={() => this.changeQuantity("add")}
                              className="p-1.5 px-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition-all duration-100"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-plus"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                {" "}
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />{" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                              </svg>
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    <button
                      data-aos="fade-up"
                      onClick={() => this.handleAddToCart()}
                      className="bg-[#2196F3] text-[#fff] hover:bg-[#1976D2]  justify-center duration-150 transition-all py-3 px-8 rounded uppercase space-x-2 flex items-center font-medium  cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                        <circle cx="6" cy="19" r="2" />{" "}
                        <circle cx="17" cy="19" r="2" />{" "}
                        <path d="M17 17h-11v-14h-2" />{" "}
                        <path d="M6 5l14 1l-1 7h-13" />
                      </svg>
                      <span>Add to Cart</span>
                    </button>
                  </>}

                {this.state.saveSuccess ? (
                  <div className="w-full flex space-x-4 items-center py-4 px-4 text-base tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      {" "}
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                      <circle cx="12" cy="12" r="9" />{" "}
                      <path d="M9 12l2 2l4 -4" />{" "}
                    </svg>{" "}
                    <span>Your profile has been successfully updated!</span>
                  </div>
                ) : null}
              </div>
            </section>

            <section data-aos="fade-up" className="flex flex-col items-start w-full gap-10 px-4 py-10 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex flex-row w-full gap-2 overflow-x-auto border-b border-collapse whitespace-nowrap ">
                <span
                  onClick={() => this.handleTabClick(1)}
                  className={
                    this.state.activeTab === 1
                      ? "bg-[#2196F3] rounded-t-md border-t border-l border-r text-white text-center py-2 px-4   cursor-pointer hover:bg-[#1976D2] transition-all duration-150 w-56"
                      : "rounded-t-md border-t border-l border-r text-[#3D405B] text-center py-2 px-4      cursor-pointer hover:bg-gray-100  transition-all duration-150 w-56"
                  }
                >
                  Product Details
                </span>

                {/* <span
                  onClick={() => this.handleTabClick(2)}
                  className={
                    this.state.activeTab === 2
                      ? "bg-[#2196F3] rounded-t-md border-t border-l border-r text-white text-center py-2 px-4   cursor-pointer hover:bg-[#1976D2] transition-all duration-150 w-56"
                      : "rounded-t-md border-t border-l border-r text-[#3D405B] text-center py-2 px-4      cursor-pointer hover:bg-gray-100  transition-all duration-150 w-56"
                  }
                >
                  Specification
                </span> */}
                <span
                  onClick={() => this.handleTabClick(3)}
                  className={
                    this.state.activeTab === 3
                      ? "bg-[#2196F3] rounded-t-md border-t border-l border-r text-white text-center py-2 px-4   cursor-pointer hover:bg-[#1976D2] transition-all duration-150 w-56"
                      : "rounded-t-md border-t border-l border-r text-[#3D405B] text-center py-2 px-4      cursor-pointer hover:bg-gray-100  transition-all duration-150 w-56"
                  }
                >
                  How It Works
                </span>
              </div>
            </section>

            {/*  Product Details */}
            {this.state.activeTab === 1 ? (
              <>
                <section data-aos="fade-up" className="flex flex-col items-start w-full gap-10 px-4 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
                  <div className="space-y-6 lg:w-full">
                    <h1 className="text-3xl font-medium text-[#3D405B]">
                      Product Details
                    </h1>

                    {getDetailsList("features1") &&
                      getDetailsList("features1").length > 0
                      ? getDetailsList("features1").map((element) => (
                        <>
                          <p>{element}</p>
                        </>
                      ))
                      : null}
                  </div>
                  <SwiperComponent getDetailsList={getDetailsList} />
                  {/* <div className="relative w-full space-y-6 ">
                    <div className="flex items-center justify-between">
                      <h1 className="text-3xl font-medium text-[#3D405B]">
                        Benefits
                      </h1> 
                    </div> 

                    <Swiper 
                      navigation={true}
                      mousewheel={true}
                      keyboard={true}
                      loop={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: true,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 40,
                        },
                        1366: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                        1920: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                      }}
                      pagination={true}
                      spaceBetween={30}
                      modules={[Navigation, Pagination, Autoplay]}
                      slidesPerView={1}
                      className="pb-10 mySwiper min-h-60 max-h-80">

                      <div className="">
                        {getDetailsList("features2") &&
                          getDetailsList("features2").length > 0
                          ? getDetailsList("features2").map((element) => (
                            <SwiperSlide className="bg-slate-100 p-6 space-y-4 rounded-tl-2xl rounded-br-2xl text-[#3D405B] transition-all duration-100 hover:bg-[#1976D2] cursor-pointer hover:text-white h-auto   ">
                             
                              <p className="font-medium "> {element} </p>

                            </SwiperSlide>
                          ))
                          : null}
                      </div>
                    </Swiper>


                  </div> */}
                </section>

                <section data-aos="fade-in" className="w-full px-4 pt-10 pb-20 mx-auto space-y-10 divide-x 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
                  <h1 data-aos="fade-up" className="text-3xl font-medium text-[#3D405B]">
                    Specification
                  </h1>

                  <table data-aos="fade-right" className="w-full   text-[#3D405B]">
                    <thead>
                    </thead>
                    <tbody className="font-medium">
                      <tr className="border-r">
                        <td className="p-6 py-8 bg-[#F9FAFB] w-[30%] border">
                          Format
                        </td>
                        <td className="p-6 py-8 bg-[#fff] border">Digital</td>
                      </tr>
                      <tr>
                        <td className="p-6 py-8 bg-[#F9FAFB] w-[30%] border">
                          Delivery Method
                        </td>
                        <td className="p-6 py-8 bg-[#fff] border">Email</td>
                      </tr>
                      <tr>
                        <td className="p-6 py-8 bg-[#F9FAFB] w-[30%] border">
                          Reports Included
                        </td>
                        <td className="p-6 py-8 bg-[#fff] border">
                          {getDetailsList("specs") && getDetailsList("specs").length > 0 ? getDetailsList("specs").map((element, index) => (element)).join(", ") : ""}
                        </td>


                      </tr>
                    </tbody>
                  </table>
                </section>
              </>

            ) : null}


            {this.state.activeTab === 3 ? (
              <section className="w-full px-4 pb-20 mx-auto space-y-10 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
                <h1 data-aos="fade-in" className="text-3xl font-medium text-[#3D405B]">
                  How It Works
                </h1>

                <div className="text-[#3D405B] space-y-6">
                  <h2 className="text-xl font-semibold">{"If you're purchasing " + getDetails("name").toLowerCase() + " for yourself or others."}</h2>

                  {getDetailsList("steps") &&
                    getDetailsList("steps").length > 0
                    ? getDetailsList("steps").map((element, index) => (

                      <div data-aos="fade-in" className="flex flex-row items-center gap-6 p-4 border rounded-xl lg:p-6">
                        <span className="bg-[#3D405B] text-white px-6 py-2 lg:text-lg text-sm whitespace-nowrap rounded-full ">
                          Step {index + 1}
                        </span>
                        <div>
                          <p>{element}</p>
                        </div>
                      </div>

                    ))
                    : null}


                </div>
              </section>
            ) : null}

            <Ready />

            <Footer
              props2={this.props}
              prodList={getAssessmentPackagesShortListData}
              gotoMyAccount={this.gotoMyAccount}
            />
          </>
        ) : null}
      </div>
    );
  }
}

SignIn.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};

function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(SignIn);