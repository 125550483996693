import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PageTemplate from './PageTemplate'; 
import AssessmentScore from './Components/AssessmentScore';
import AssessmentDetails from './Components/AssessmentDetails';
import AssessmentTop5 from './Components/AssessmentTop5';
import AssessmentAllInOne from './Components/AssessmentAllInOne';
import AssessmentCategories from './Components/AssessmentCategories';
class AssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
        crrSelectedEmp:""
    }
  }

  componentDidMount() {

  }

  exportPDFWithComponent = () => {
    let { handleDownloadStatus } = this.props;
    handleDownloadStatus("Detail Report");
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };
  

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { onAssessmentByEmpID } = this.props;
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      onAssessmentByEmpID(emp_id)

    })
  }

  render() {

    let { isUser, loading, EditDetailsFn,EditButtonFn, createdAt,  getAssessmenrEmployeeDetailsData, closeReportModal, getAssessmentReportByEmpIDData } = this.props;
    let {  } = this.state;

    return (


      <div className={isUser?"":"fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60"} >
        <div className="flex items-start justify-center p-4 overflow-hidden">

          <div className={isUser?"w-full mx-auto overflow-hidden overflow-y-auto bg-white rounded-md h-[calc(100vh-8rem)]  customscroll5":"mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-3/6 xl:w-4/6 lg:w-5/6 h-[calc(100vh-8rem)]  customscroll5"}  >

            <div className="flex md:flex-row flex-col justify-between border-b items-center p-4 px-6 sticky top-0 bg-white z-20"  >
              <h1 className="font-semibold md:text-xl text-lg line-clamp-1 text-[#3D405B]">
                TalentPlusStrengths  Manager Strengths
              </h1>

              <div className='flex items-center space-x-4'>
                
                {/* <EmployeeDropdown
                    getAnsweredEmployeesData={getAnsweredEmployeesData}
                    crrSelectedEmp={this.state.crrSelectedEmp}
                    handleSelectEmployee={this.handleSelectEmployee}
                />
                 */}
                {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length>0?
                <>
                    {/* <div onClick={() => this.exportPDFWithComponent()} className="bg-blue-100 p-2 px-4 rounded-full text-blue-500  cursor-pointer text-sm flex items-center " >
                        <span className="material-symbols-outlined mr-2 " >
                        file_download
                        </span> Download Report
                    </div> */}


                <button
                  onClick={() => this.exportPDFWithComponent()}
                  className="w-full  uppercase text-sm font-medium rounded-md  inline-flex  items-center justify-center px-2 py-2 cursor-pointer  
                  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden  "
                >
                  <span className="material-symbols-outlined mr-2 text-[1.5rem]">
                  file_download
                  </span>
                  <span className="transition-all duration-300 ease-out bg-white rounded-full opacity-10"></span>
                  {EditButtonFn('DownloadReport',"Download Report")}
                </button>

                </>
                :null}
                

                <div onClick={() => closeReportModal(false)} className=" w-10 h-10 rounded-full  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                  <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>

            </div>

            {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length>0?
                <div className=''>
                    <PDFExport ref={this.pdfExportComponent}
                        pageTemplate={(pageObj)=><PageTemplate pageNum={pageObj.pageNum}/>}
                        scale={0.6}
                        forcePageBreak=".page-break"
                        paperSize="A4"
                        margin={0}
                        fileName={'Assessment Report'+(getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name?
                          ' - '+getAssessmenrEmployeeDetailsData.name:"")}
                        author="Happyplus Team"
                        landscape={false}
                    >

                    <AssessmentScore 
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      createdAt={createdAt}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}

                    />

                    <AssessmentTop5
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData} 
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}

                    />


                    <AssessmentCategories
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData} 
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}

                    />

                    <AssessmentAllInOne
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData} 
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}

                    />  

                    <AssessmentDetails 
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData} 
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}

                    />


                </PDFExport>
                </div>
            :
            <>
                {loading?
                <div className='text-center py-24 text-gray-500 text-xl font-semibold'>{"Please wait while report is generating..."}</div>
                :
                <div className='text-center py-24 text-gray-500 text-xl font-semibold'>{"Report is not generated yet"}</div>
                }
            </>}

          </div>
        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AssessmentReport);
