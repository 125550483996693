import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Ready from "../../../components/Ready";
import Footer from "../Home/Component/Footer";
import moment from 'moment';

class BlogDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: this.props.match.params.id
    };
  }


  componentDidMount() {

    window.scrollTo(0, 0);
    let data = {
      keyWord: "",
      pageNo: 1,
      size: 100000,

    }
    this.props.dispatch(assessmentActions.getTalentBlogList(data));


    AOS.init();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.assessment.getTalentBlogListSuccess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.assessment.getTalentBlogListFailed) {
      return {
        ...nextProps,
        notFoundText: "Failure!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }


  handleRedirect = (path) => {
    this.props.history.push(path);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };


  render() {

    let { assessment } = this.props;
    // const { selectedBlog } = this.props.location.state;
    let { userAssessmentUserProfileData, getAssessmentCartCountData, getAssessmentPackagesShortListData, getTalentBlogData, getTalentBlogTotal } = assessment;
    let { productId } = this.state;

    let selectedBlog = "";
    if (getTalentBlogData && getTalentBlogData.length > 0) {
      getTalentBlogData.forEach(element => {
        if (element.id === productId) {
          selectedBlog = element;
        }
      });
    }

    return (
      <div>
        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{
            backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10 px-4 py-6 mx-auto md:pt-10 md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
              <div className="flex flex-col justify-center w-full h-full pt-10 pb-10 mx-auto my-auto space-y-4 text-center md:pb-10 md:pt-0 md:text-center ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Blog Details </h1>
                <div data-aos="fade-up" className="space-x-3 text-base font-light md:text-lg flex text-[#212121] items-center justify-center">
                  <p className="cursor-pointer" onClick={() => this.handleRedirect('/assessment/home')}> {' Home >'}</p>
                  <p className="cursor-pointer" onClick={() => this.handleRedirect('/assessment/blog')}> {' Blogs > '}</p>
                  <p className="cursor-pointer" onClick={() => this.handleRedirect('/assessment/blogdetails')}>{' Blog Details'}</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div className="flex flex-col w-full h-full gap-10 px-4 py-10 mx-auto lg:py-20 lg:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="flex flex-col mx-auto space-y-16 xl:w-4/6">
            <div className="space-y-6 ">
              <div className="space-y-2 ">
                <h1 className="xl:text-3xl text-2xl font-semibold text-[#212121] capitalize">{selectedBlog && selectedBlog.title ? selectedBlog.title : "NA"}</h1>
                <div className="flex items-center space-x-2 text-[#212121]/70 text-lg">
                  <span>By {selectedBlog && selectedBlog.author ? selectedBlog.author : "NA"}</span><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point-filled text-[#3D405B]/30" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" strokeWidth="0" fill="currentColor" /></svg> <span>{moment(selectedBlog && selectedBlog.createdAt ? selectedBlog.createdAt : "NA").format("DD MMMM, YYYY")}</span>
                </div>
              </div>
              <img src={selectedBlog && selectedBlog.imageLinkV2 ? selectedBlog.imageLinkV2 : "NA"} alt="Not Found" className="w-full bg-cover" />

              <div className="space-y-4 ">
                <div className="text-base">
                  <p className="text-[#] "><div dangerouslySetInnerHTML={{ __html: selectedBlog && selectedBlog.desc ? selectedBlog.desc : "NA" }} /></p>
                </div>

              </div>

            </div>

          </div>
        </div>



        <Ready />
        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>

    );
  }

}


function mapStateToProps(state) {
  //console.log("state  ", state);
  const { assessment } = state;
  return {
    assessment
  };
}

export default connect(mapStateToProps)(BlogDetail);