import React from "react";
import { useHistory } from 'react-router-dom';
import AOS from "aos";


function Ready() {

  AOS.init({
    duration: 1000,
    once: true,
    easing: "ease-out",
    delay: 100,
  });

  const history = useHistory();

  const handleRedirect = () => {
    history.push('/assessment/contact-us');
  }


  return (
    <section
      style={{
        backgroundImage: `url('/img/Home/book_a_demo_bg.webp')`,
      }}
      className=" bg-[#E3F2FD] xl:bg-contain bg-cover bg-no-repeat bg-center"
    >
      <div className="px-4 py-20 mx-auto space-y-8 text-center md:w-4/6 md:px-0">
        <div className="space-y-4 ">
          <h1
            data-aos="fade-up"
            className="xl:text-4xl text-3xl leading-[3rem] xl:leading-[4rem] font-semibold text-[#212121] text-center capitalize"
          >
            Ready to leap from feedback to action?
          </h1>
          <p data-aos="fade-up" className="text-xl  text-[#212121]">
            Invest in your people today. If not now, when?
          </p>
        </div>
        <button
          onClick={() => handleRedirect(window.scrollTo(0, 0))}
          data-aos="fade-up"
          // onClick={() => this.gotoPage("contact-us")}
          className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex justify-center items-center w-56 mx-auto"
        >
          Book a Demo
        </button>
        {/* <button onClick={() => handleRedirect(window.scrollTo(0, 0))} data-aos="fade-up"
          className="bg-[#2196F3] text-white py-2 px-4 rounded uppercase cursor-pointer hover:bg-[#1976D2] transition-all duration-150 w-56"> Book a demo</button> */}
      </div>
    </section>
  );
}
export default Ready;
