import React, { useRef, useState } from "react";
import { TbChevronDown, TbX } from 'react-icons/tb';

export default function Component(props) {
  const {
    getTalentResourcesData,
    getAssessmentReportByEmpIDData,
    EditButtonFn,
    EditDetailsFn,
    showReportModal,
    showSummaryReport
  } = props;

  const titleRef = useRef();



  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - Talent every day?"
        : "How to deal with a person who has - Talent?";
    let final = itemName.replace("-", element.dimension.trim());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      descNew = temp.split("@").filter((prev) => prev !== "");
    }
    return descNew;
  }

  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    return list;
  }


  const getVideoIntro = (resouceList, dimension) => {
    let obj = {}
    if (resouceList && resouceList.length > 0) {
      let getIndex = resouceList.findIndex(prev => prev.dimension === dimension);
      if (getIndex !== -1) {
        obj = resouceList[getIndex]
      }
    }
    return (obj ? obj : { "imageUrl": "", "url": "" })
  }

  const getVideoDetails = (talentList, resouceList, index) => {
    let obj = {}
    if (talentList && talentList.length > 0 && resouceList && resouceList.length > 0) {
      let dimension = talentList[index]["dimension"];
      if (dimension) {
        let getIndex = resouceList.findIndex(prev => prev.dimension === dimension);
        if (getIndex !== -1) {
          obj = resouceList[getIndex]
        }
      }
    }
    return (obj ? obj : { "imageUrl": "", "url": "" })
  }

  let detailInit = getVideoIntro(getTalentResourcesData, "Introduction");
  let [crrIndex, handleIndex] = useState(111)
  let [details, detailsFn] = useState(detailInit)

  console.log("details------>", details)

  const handleIndexFn = (index) => {

    detailsFn(null);
    setThemebar(false);

    if (titleRef && titleRef.current) {
      titleRef.current.pause();
      titleRef.current.load();
    }

    let detail = null;
    if (index === 111) {
      detail = getVideoIntro(getTalentResourcesData, "Introduction");
    } else if (index === 112) {
      detail = getVideoIntro(getTalentResourcesData, "Strengths");
    } else {
      detail = getVideoDetails(getAssessmentReportByEmpIDData, getTalentResourcesData, index);
    }
    console.log("detail------>", detail)

    detailsFn(detail);
    handleIndex(index);

  }

  const getDimensionDetails = (talentList, index) => {
    let obj = {}
    if (talentList && talentList.length > 0) {
      obj = talentList && talentList[index] && talentList[index] ? talentList[index] : {};
    }
    return obj
  }

  const getDimensionDetails2 = (talentList, index) => {
    let obj = {}
    if (talentList && talentList.length > 0) {
      obj = talentList && talentList[index] && talentList[index] ? talentList[index] : {};
    }
    return obj
  }



  function checkForGeneralship(bucket, dimension) {
    let text = "";
    if (bucket && dimension) {
      if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }
  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  const getDimensionTitle2 = (talentList, index1) => {
    let element = getDimensionDetails2(talentList, index1);
    // console.log("element----->", element)
    return (<>
      {element && element.bucket ?
        <>
          <p className="text-sm font-medium uppercase"> {EditDetailsFn(element, 'bucket', element.bucket)}</p>
          <p className="text-[#2196F3] font-medium"> {((index1 + 1) > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString() : (index1 + 1).toString()) + ". "}{EditDetailsFn(element, 'dimension', element.dimension)}</p>
        </>
        :
        <>
          <p className="text-[#2196F3] font-medium"> {"1. Introduction"}</p>
        </>
      }

    </>
    )
  }

  const getDimensionTitle = (talentList, index1) => {
    let element = getDimensionDetails(talentList, index1);
    console.log("element----->", element)
    return (<>
      {element && element.bucket && (<>
        <div style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.20"), }}
          className={"cursor-pointer 2xl:px-8 md:py-4 p-4 md:flex justify-between  items-center md:space-x-6 md:space-y-0 space-y-4"}>
          <div className="2xl:space-y-4 space-y-2 md:w-[30%]">
            <button style={{ background: element && element.colorCode ? element.colorCode : "#DDDDDD", }} className={" px-4 py-2 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest  rounded-full "}> {EditDetailsFn(element, 'bucket', element.bucket)}</button>
            <h1 className="text-xl font-medium 2xl:text-2xl text-slate-900 md:px-4">
              {((index1 + 1) > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString() : (index1 + 1).toString()) + ". "}{EditDetailsFn(element, 'dimension', element.dimension)}
            </h1>
          </div>
          <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end ">
            <p className="flex-1 text-sm font-normal md:text-base text-slate-600 ">
              {EditDetailsFn(element, 'desc', element.details.desc)}
            </p>

            <div className="w-16 xl:w-28 md:w-20">
              <img
                src={
                  "/img/assessment/circleicons/" +
                  element.dimension.toLowerCase() +
                  ".svg"
                }
              />
            </div>
          </div>
        </div>
      </>)
      }

    </>
    )
  }

  const getDimensionCompoment = (talentList, index1, type) => {

    let element = getDimensionDetails(talentList, index1);

    let sideNumber = {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      }
    }


    return (<>
      {type === 1 ?
        <>
          <div className="px-2 py-4">
            <p className="text-base font-medium">
              {EditDetailsFn(
                element,
                "desc1",
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      element &&
                        element.details &&
                        element.details.desc1
                        ? element.details.desc1
                        : "",
                  }}
                />
              )}
            </p>
          </div>
          <div className="px-2 py-4">
            {EditDetailsFn(
              element,
              "desc2",
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    element &&
                      element.details &&
                      element.details.desc2
                      ? element.details.desc2
                      : "",
                }}
              />
            )}
          </div>
        </>
        : null}


      {type === 2 ?
        <>
          {/* how to use section */}
          <div className='space-y-6'>
            {getBulletPoints(element, "desc3") &&
              getBulletPoints(element, "desc3").length > 0
              ? chunkList(getBulletPoints(element, "desc3")).map(
                (textList) => (
                  <div>
                    <div className="flex items-start pb-4 space-x-4">
                      <img src={"/img/assessment/circleicons/" + element.dimension.toLowerCase() + ".svg"} alt='Group 17207 (3).svg' className='w-9 h-9' />
                      <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">
                        {EditButtonFn(
                          getTitle(element, 1),
                          getTitle(element, 1)
                        )}
                      </h1>
                    </div>

                    <div className="">
                      {textList && textList.length > 0
                        ? textList.map((text, index6) => (
                          <div
                            style={{
                              borderColor:
                                element && element.colorCode
                                  ? element.colorCode
                                  : "#DDDDDD",
                            }}
                            className="h-auto bg-white border-l-8 border-blue-500 rounded-lg shadow-md hover:shadow-xl" >
                            <div className="px-6 py-8 mb-4">
                              <p className="text-base text-[#3D405B]">
                                {EditDetailsFn(
                                  element,
                                  "desc3" + (index6 + 1),
                                  text
                                )} </p>
                            </div>
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                )
              )
              : null}
          </div>
        </>
        : null
      }

      {
        type === 3 ?
          <>
            {/* Possible blind spots */}
            <div>
              <div className="flex items-start pb-4 space-x-4">
                <img src={"/img/assessment/circleicons/" + element.dimension.toLowerCase() + ".svg"} alt='Group 17207 (3).svg' className='w-9 h-9' />
                <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">{EditButtonFn(
                  "What needs to be looked out for_Possible Blind Spots?",
                  "What needs to be looked out for (Possible Blind Spots)?"
                )}</h1>
              </div>
              <div className="">
                {getBulletPoints(element, "desc4") &&
                  getBulletPoints(element, "desc4").length > 0
                  ? getBulletPoints(element, "desc4").map(
                    (text, index) =>
                      <div style={{
                        borderColor:
                          element && element.colorCode
                            ? element.colorCode
                            : "#DDDDDD",
                      }}
                        className="h-auto bg-white border-l-8 border-blue-500 rounded-lg shadow-md hover:shadow-xl">
                        <div className="px-6 py-8 mb-4">
                          <p className="text-base text-[#3D405B]">{EditDetailsFn(
                            element,
                            "desc4" + (index + 1),
                            text
                          )}</p>
                        </div>
                      </div>
                  ) : null}
              </div>
            </div>
          </>
          : null
      }

      {
        type === 4 ?
          <>
            {/* How to deal with a person */}
            <div>
              {getBulletPoints(element, "desc5") &&
                getBulletPoints(element, "desc5").length > 0
                ? chunkList(getBulletPoints(element, "desc5")).map(
                  (textList, index5) => (
                    <>
                      <div className="flex items-start pb-4 space-x-4">
                        <img src={"/img/assessment/circleicons/" + element.dimension.toLowerCase() + ".svg"} alt='Group 17207 (3).svg' className='w-9 h-9' />
                        <h1 className="text-xl font-medium md:text-2xl text-[#3D405B]">
                          {EditButtonFn(
                            getTitle(element, 2),
                            getTitle(element, 2)
                          )}</h1>
                      </div>
                      <div className="">
                        {textList && textList.length > 0
                          ? textList.map((text, index6) => (
                            <div style={{
                              borderColor:
                                element && element.colorCode
                                  ? element.colorCode
                                  : "#DDDDDD",
                            }}
                              className="h-auto bg-white border-l-8 border-blue-500 rounded-lg shadow-md hover:shadow-xl">
                              <div className="px-6 py-8 mb-4">
                                <p className="text-base text-[#3D405B]">
                                  {EditDetailsFn(
                                    element,
                                    "desc5" + (index6 + 1),
                                    text
                                  )}</p>
                              </div>
                            </div>
                          ))
                          : null}
                      </div>
                    </>
                  )
                )
                : null}
            </div>
          </>
          : null
      }
    </>)
  }

  const [openMenu, setMenu] = useState(1);
  const [themebar, setThemebar] = useState(false);
  let [menu, menuTab] = useState("Videos")
  const containerRef = useRef(null);
  const handleMenuClick = (newMenu) => {
    menuTab(newMenu);
    if (containerRef.current) {
      // You can adjust the scrollLeft value based on your requirements
      containerRef.current.scrollLeft += 50; // Adjust the scroll value as needed
    }
  };

  console.log('showSummaryReport', showSummaryReport);
  return (
    <>
      {/* Video page code */}
      <section className="w-full px-4 py-6 mx-auto xl:w-11/12 xl:px-0 ">
        <div className="w-full mx-auto ">
          <div className="flex items-center justify-between p-4 bg-white border rounded-md lg:hidden ">


            <div className="text-[#212121]">
              {/* {getDimensionTitle2(getAssessmentReportByEmpIDData, crrIndex, 0)} */}
              {getDimensionTitle2(getAssessmentReportByEmpIDData, crrIndex, 0) || "Introduction"}
            </div>

            <button onClick={() => setThemebar(!themebar)} className="border border-[#2196F3] text-sm px-4 py-2 text-[#2196F3] rounded-sm">
              Change
            </button>
          </div>

          <div className="flex flex-row justify-between gap-10 ">


            <div className={"lg:w-[30%] lg:sticky  lg:block hidden lg:z-30 z-20 lg:top-6 top-0 right-0 w-full lg:h-[calc(100vh-3rem)] h-screen overflow-y-auto  bg-white p-6 py-0"}  >
              <div className="flex items-center px-6 py-2 -mx-6 space-x-4 border-b lg:hidden ">
                <button onClick={() => setThemebar(true)} className="p-2 rounded-full bg-slate-100">
                  <TbX className="text-[1.5rem] " />
                </button>
                <p className="text-lg font-semibold capitalize">select</p>
              </div>
              <div className="">
                <div onClick={(e) => { e.preventDefault(); setMenu(1); }} className="flex items-center justify-between py-4">
                  <p className="text-lg font-semibold text-left md:text-xl">Introduction</p>
                  <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                </div>
                {openMenu === 1 ?
                  <div className="space-y-4 text-base md:text-lg">
                    <div onClick={() => handleIndexFn(111)} className={(111 === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer text-left flex space-x-2"}>
                      <span>{(1).toString() + "."}</span>
                      <span>{"Introduction"}</span>
                    </div>
                    {/* <div onClick={() => handleIndexFn(112)} className={(112 === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer text-left flex space-x-2"}>
                    <span>{(2).toString() + "."}</span>
                    <span>{"Your Strengths"}</span>
                  </div> */}
                  </div>
                  :
                  null}
              </div>

              {/* Dominant Themes right side */}
              <div>
                <div onClick={(e) => { e.preventDefault(); setMenu(2); }} className="flex items-center justify-between py-4 cursor-pointer">
                  <p className="text-lg font-semibold text-left md:text-xl">Dominant Themes</p>
                  <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                </div>

                {openMenu === 2 ?
                  <ul className="space-y-4 text-base list-disc md:text-lg ">
                    {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                      getAssessmentReportByEmpIDData.map((ele, index) =>
                        ((index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1)) ?
                          <li onClick={() => handleIndexFn(index)} className={(index === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer text-left flex space-x-2"}>
                            <span>{(index + 1).toString() + "."}</span>
                            <span>{ele.dimension}</span>
                          </li>
                          : null
                      ) : null}
                  </ul>
                  : null}
              </div>

              {/* other themes section right side */}

              {!showSummaryReport ?

                <div>
                  <div onClick={(e) => { e.preventDefault(); setMenu(3); }} className="flex items-center justify-between py-4 cursor-pointer">
                    <p className="text-lg font-semibold text-left md:text-xl">Other Themes</p>
                    <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                  </div>

                  {openMenu === 3 ?
                    <div className="space-y-4 text-base md:text-lg">
                      {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                        getAssessmentReportByEmpIDData.map((ele, index) =>
                          (getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2) ?
                            <div onClick={() => handleIndexFn(index)} className={(index === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer flex items-center space-x-2 "}>
                              <span>{(index + 1).toString() + "."}</span>
                              <span>{ele.dimension}</span>
                            </div>
                            : null
                        ) : null}
                    </div>
                    : null
                  }
                </div>
                : null}

              {/* Non Dominant Themes section right side */}

              {!showSummaryReport ?

                <div>
                  <div onClick={(e) => { e.preventDefault(); setMenu(4); }} className="flex items-center justify-between py-4 cursor-pointer">
                    <p className="text-lg font-semibold text-left md:text-xl">Non Dominant Themes</p>
                    <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                  </div>

                  {openMenu === 4 ?
                    <div className="space-y-4 text-base md:text-lg">
                      {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                        getAssessmentReportByEmpIDData.map((ele, index) =>
                          (index + 1 >= getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1) ?
                            <div onClick={() => handleIndexFn(index)} className={(index === crrIndex ? "bg-gray-200" : "") + "cursor-pointer flex items-center space-x-2 "}>
                              <span>{(index + 1).toString() + "."}</span>
                              <span>{ele.dimension}</span>
                            </div>
                            : null
                        ) : null}
                    </div>
                    : null}

                </div>
                : null}


            </div>


            <div className={themebar ? "lg:w-[30%] lg:sticky fixed lg:z-30 z-20 lg:top-6 top-0 right-0 w-full lg:h-[calc(100vh-3rem)] h-screen overflow-y-auto  bg-white p-6 py-0" : 'hidden'}  >
              <div className="flex items-center px-6 py-2 -mx-6 space-x-4 border-b lg:hidden ">
                <button onClick={() => setThemebar(false)} className="p-2 rounded-full bg-slate-100">
                  <TbX className="text-[1.5rem] " />
                </button>
                <p className="text-lg font-semibold capitalize">select</p>
              </div>
              <div className="">
                <div onClick={(e) => { e.preventDefault(); setMenu(1); }} className="flex items-center justify-between py-4">
                  <p className="text-lg font-semibold text-left md:text-xl">Introduction</p>
                  <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                </div>
                {openMenu === 1 ?
                  <div className="space-y-4 text-base md:text-lg">
                    <div onClick={() => handleIndexFn(111)} className={(111 === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer text-left flex space-x-2"}>
                      <span>{(1).toString() + "."}</span>
                      <span>{"Introduction"}</span>
                    </div>
                    {/* <div onClick={() => handleIndexFn(112)} className={(112 === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer text-left flex space-x-2"}>
                    <span>{(2).toString() + "."}</span>
                    <span>{"Your Strengths"}</span>
                  </div> */}
                  </div>
                  :
                  null}
              </div>

              {/* Dominant Themes right side */}
              <div>
                <div onClick={(e) => { e.preventDefault(); setMenu(2); }} className="flex items-center justify-between py-4 cursor-pointer">
                  <p className="text-lg font-semibold text-left md:text-xl">Dominant Themes</p>
                  <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                </div>

                {openMenu === 2 ?
                  <ul className="space-y-4 text-base list-disc md:text-lg ">
                    {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                      getAssessmentReportByEmpIDData.map((ele, index) =>
                        ((index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1)) ?
                          <li onClick={() => handleIndexFn(index)} className={(index === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer text-left flex space-x-2"}>
                            <span>{(index + 1).toString() + "."}</span>
                            <span>{ele.dimension}</span>
                          </li>
                          : null
                      ) : null}
                  </ul>
                  : null}
              </div>

              {/* other themes section right side */}

              <div>
                <div onClick={(e) => { e.preventDefault(); setMenu(3); }} className="flex items-center justify-between py-4 cursor-pointer">
                  <p className="text-lg font-semibold text-left md:text-xl">Other Themes</p>
                  <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                </div>

                {openMenu === 3 ?
                  <div className="space-y-4 text-base md:text-lg">
                    {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                      getAssessmentReportByEmpIDData.map((ele, index) =>
                        (getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2) ?
                          <div onClick={() => handleIndexFn(index)} className={(index === crrIndex ? "text-[#2196F3]" : "") + " cursor-pointer flex items-center space-x-2 "}>
                            <span>{(index + 1).toString() + "."}</span>
                            <span>{ele.dimension}</span>
                          </div>
                          : null
                      ) : null}
                  </div>
                  : null
                }
              </div>



              {/* Non Dominant Themes section right side */}
              <div>
                <div onClick={(e) => { e.preventDefault(); setMenu(4); }} className="flex items-center justify-between py-4 cursor-pointer">
                  <p className="text-lg font-semibold text-left md:text-xl">Non Dominant Themes</p>
                  <TbChevronDown className="text-[#2196F3] text-[1.5rem]" />
                </div>

                {openMenu === 4 ?
                  <div className="space-y-4 text-base md:text-lg">
                    {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                      getAssessmentReportByEmpIDData.map((ele, index) =>
                        (index + 1 >= getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1) ?
                          <div onClick={() => handleIndexFn(index)} className={(index === crrIndex ? "bg-gray-200" : "") + "cursor-pointer flex items-center space-x-2 "}>
                            <span>{(index + 1).toString() + "."}</span>
                            <span>{ele.dimension}</span>
                          </div>
                          : null
                      ) : null}
                  </div>
                  : null}

              </div>



            </div>


            <div className="lg:w-[70%] w-full space-y-6     ">

              {((crrIndex === 111) || (crrIndex === 112)) && false ?
                <>
                </>
                :
                <>
                  <div ref={containerRef} className="overflow-x-auto border-b border-gray-300 whitespace-nowrap">
                    <div className="flex flex-row  lg:text-base text-sm font-medium text-center text-[#212121]/70 space-x-6 ">

                      <button className={menu === 'Videos' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                        onClick={() => handleMenuClick("Videos")}>Videos</button>

                      <button className={menu === 'Overview' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                        onClick={() => handleMenuClick("Overview")}>Overview</button>

                      {!((crrIndex === 111) || (crrIndex === 112)) && (<>
                        <button className={menu === 'HowToUse' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                          onClick={() => handleMenuClick("HowToUse")}>How to use</button>

                        <button className={menu === 'PossibleBlindSpots' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                          onClick={() => handleMenuClick("PossibleBlindSpots")}>Possible blind spots</button>

                        <button className={menu === 'HowToDealWithAPerson' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                          onClick={() => handleMenuClick("HowToDealWithAPerson")}>How to deal with a person</button>
                      </>)}


                    </div>
                  </div>

                  {((crrIndex === 111) || (crrIndex === 112)) && (menu === "Overview") ?
                    <>
                      <div className="px-2 py-4 space-y-4">
                        <h1 className="text-3xl font-medium text-[#212121]">
                          {EditButtonFn('page_2_title_2', 'Your TalentPlusStrengths Assessment Report')}
                        </h1>
                        <p>
                          {EditButtonFn('page_2_desc_1', 'Your talents are the essence of who you are, shaping how you think, feel, and act. Understanding and leveraging these talents are key to success. They enable you to find your flow, excel at work, and stand out authentically. Embracing your uniqueness leads to fulfillment and happiness, allowing you to be your true self. ')}
                          <br /><br />
                          {EditButtonFn('page_2_desc_2', 'Based on your response to the TalentPlusStrengths assessment, this report has been created for you. Idea is that you go through the result, reflect on the same and be aware of your uniqueness. Once aware, you can start utilising it proactively to add appropriate skills & knowledge to do anything you want to do in life. It also will help you understand how do you think, relate, act and lead in your life. All these four buckets are the basic structure of making you successful.')}
                        </p>
                      </div>
                    </>
                    :
                    <>
                      <div className="">
                        {getDimensionTitle(getAssessmentReportByEmpIDData, crrIndex, 0)}
                      </div>
                    </>
                  }


                  {menu === "Overview" ?
                    <div className="py-2">
                      {getDimensionCompoment(getAssessmentReportByEmpIDData, crrIndex, 1)}
                    </div>
                    : null}

                  {menu === "Videos" ?
                    <div className="">
                      <div className="">
                        <video ref={titleRef} className="w-full !aspect-video" controls >
                          <source src={details && details.url ? details.url.toString() : ""} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    : null}

                  {menu === "HowToUse" ?
                    <div className="py-2">
                      {getDimensionCompoment(getAssessmentReportByEmpIDData, crrIndex, 2)}
                    </div>
                    : null}

                  {menu === "PossibleBlindSpots" ?
                    <div className="py-2">
                      {getDimensionCompoment(getAssessmentReportByEmpIDData, crrIndex, 3)}
                    </div>
                    : null}

                  {menu === "HowToDealWithAPerson" ?
                    <div className="py-2">
                      {getDimensionCompoment(getAssessmentReportByEmpIDData, crrIndex, 4)}
                    </div>
                    : null}

                </>
              }


              <div className="pt-4 ">
                <div className="flex justify-center w-full p-4 bg-white">
                  <p className="text-sm text-black/80 ">
                    © Powered by
                    <b className="ml-1 font-medium text-black/80">HappyPlus</b>
                  </p>
                </div>
              </div>



            </div>

          </div>

        </div>
      </section>
    </>
  );
}
