import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { Helmet } from "react-helmet";
class TeenTalent extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      saveSuccess: false,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,

      fieldslogin: {},
      errorslogin: {},

      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(assessmentActions.userAssessmentUserProfile('getAssessmentCartCount'));
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  handleTabClick = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };


  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleDownload = () => {
    let code = "TalentPlusStrengths21";
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    link.click();
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/how-talentplus-strength-works");
    window.scrollTo(0, 0);
  };

  closeOrgTypeModal = () => {
    this.setState({ showOrgTypeModal: false })
  }

  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["type"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;
    if (formData["type"] === "Individual") {
      this.props.history.push(`/assessment/` + "surveylogin");
    } else if (formData["type"] === "Organization") {
      window.location.href = 'https://plusinsight.happyplus.in/surveylink/#/assessment/signin';
    }
  };

  handleTakeAssessmentRedirect = () => {
    this.setState({ showOrgTypeModal: false }, () => {
      this.handleOptionSubmit();
    })
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  loginSubmit = () => {
    console.log(
      "this.handleValidationLogin()---->",
      this.handleValidationLogin()
    );

    if (this.handleValidationLogin()) {
      let { userName, email, phone, message } =
        this.state.fieldslogin;
      this.setState({ saveSuccess: true });
      this.props.dispatch(
        assessmentActions.saveAssessmentContactUs(
          {
            name: userName,
            email: email,
            phone: phone,
            message: message,
          },
          this.props
        )
      );
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  handleValidationLogin = () => {
    let { fieldslogin } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    //userName
    if (!fieldslogin["userName"] || fieldslogin["userName"] === "") {
      formIsValid += 1;
      console.log("1 userName !");
      errorslogin["userName"] = "Full name is required";
    }
    //email
    if (!fieldslogin["email"]) {
      console.log("1 email !");

      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(fieldslogin["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }

    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };

  scrollToElement = () => {
    if (this.myRef.current) {
      const offset = 180; // Adjust the offset value as needed
      const elementPosition = this.myRef.current.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  };

  render() {

    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    // console.log("quantity-->", quantity);
    const cards = [

      {
        id: 1,
        title: 'Celebrating Uniqueness',
        dics: `Recognizes and celebrates the unique talents and abilities of each teenager, valuing their individuality.`,
        icon: '/img/assessment/teentalent/celebrating-uniqueness.svg',
      },
      {
        id: 2,
        title: 'Holistic Evaluation',
        dics: `Challenges traditional success metrics, offering a comprehensive evaluation that goes beyond narrow criteria.`,
        icon: '/img/assessment/teentalent/holistic-evaluation.svg',
      },
      {
        id: 3,
        title: 'Navigating Expectations',
        dics: `Guides teenagers through societal pressures, empowering them to resist predefined expectations and find their authentic path.`,
        icon: '/img/assessment/teentalent/navigating-expectations.svg',
      },
      {
        id: 4,
        title: 'Avoiding Mismatch',
        dics: `Raises awareness about the consequences of pursuing roles misaligned with natural talents, preventing potential wasted potential and struggles.`,
        icon: '/img/assessment/teentalent/avoiding-mismatch.svg',
      },
      {
        id: 5,
        title: 'Purposeful Living',
        dics: `Emphasizes the importance of aligning work with passions, talents, and purpose for a fulfilling and purpose-driven life.`,
        icon: '/img/assessment/teentalent/purposeful-living.svg',
      },
      {
        id: 6,
        title: 'Guiding Alignment',
        dics: `Serves as a compass, guiding teenagers to align their unique talents with both their work and life.`,
        icon: '/img/assessment/teentalent/guiding-alignment.svg',
      },
      {
        id: 7,
        title: 'Empowering Contribution',
        dics: `Enables teenagers to make meaningful contributions by aligning their work with their inherent talents.`,
        icon: '/img/assessment/teentalent/empowering-contribution.svg',
      },
      {
        id: 8,
        title: 'Self-Trust and Confidence',
        dics: `Encourages self-trust, fostering confidence in teenagers to create a life that resonates authentically with their true essence.`,
        icon: '/img/assessment/teentalent/self-trust-and-confidence.svg',
      },
    ]


    return (
      <div className="overflow-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Empowering Teen Talent Potential for Meaningful Growth</title>
          <meta name="description" content="Elevate teen potential with Teenager Talent assessment, fostering confidence, purposeful living, and transformative growth." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/home" />
        </Helmet>


        {/* <TestHeader
          handleTakeAssessmentRedirect={this.handleTakeAssessmentRedirect}
        /> */}

        <section
          className="bg-[#2196F3]/10 w-full h-auto bg-center  bg-cover bg-no-repeat  "
          style={{
            backgroundImage: `url('/img/assessment/teen-talent-bg-img.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          {/* Hero Section */}
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10 px-4 mx-auto md:pt-10 md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-4 text-center md:pb-96 pb-60 md:pt-8 ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Unleashing Teen Potential: Navigating the Path with TalentPlusStrengths Assessment</h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-[#212121]">Empower teens to unlock their full potential with this innovative tool, fostering growth and resilience in all aspects of life</p>
                </div>
                <div className="flex flex-row items-center justify-center gap-4 py-4 ">


                  <button data-aos="fade-up"
                    onClick={() => this.gotoPage("products")} className="flex space-x-2  bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer  w-fit justify-center"><span>  Buy Now</span>  </button>


                  <button data-aos="fade-up"
                    onClick={this.scrollToElement}
                    className="flex space-x-2 items-center  border border-black  hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-200  text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center">
                    <span> Explore domains</span> {/* <TbChevronRight className="text-[1.5rem]" /> */}
                  </button>

                </div>
              </div>

            </div>
          </div>
        </section>



        <section className="bg-gradient-to-r from-[#2196F3] to-[#673AB7]">

          <div className="flex flex-col w-full h-full gap-10 px-4 py-10 mx-auto md:flex-row md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
            <p className="w-full md:text-xl text-lg text-[#fff] md:pr-10">Teen TalentPlus is an innovative tool crafted to unlock the boundless potential of young individuals, introducing a transformative approach to nurturing their minds.</p>
            <div className="h-auto border-t md:border-l border-white/30"></div>
            <h2 className="w-full md:text-4xl text-3xl md:leading-[3rem] capitalize font-bold text-[#fff] md:pl-10 ">If you judge a fish by its ability to climb a tree, it will always believe it had a wasted life.</h2>

          </div>

        </section>



        {/* section 3*/}
        <section className="w-full py-16 space-y-10 bg-cover"  >
          <div className="px-4 mx-auto space-y-4 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">Here's a summary of its attributes and advantages:</h1>
          </div>
          <section className="grid w-full grid-cols-1 gap-2 px-4 mx-auto lg:grid-cols-4 md:grid-cols-3 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
            {cards.map(item => (

              <div key={item.id} className="bg-[#2196F3]/10 border border-[#BBDEFB] p-6 py-10 text-center space-y-4"
              >
                <div className="flex justify-center">
                  <img src={item.icon} alt={"img"} className="w-28 " />
                </div>
                <h2 className="text-xl font-bold text-[#212121] h-12">{item.title}</h2>
                <p className="text-base text-[#212121] ">{item.dics}</p>
              </div>
            ))}

          </section>
        </section>



        <section ref={this.myRef} className="w-full px-4 mx-auto bg-white 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="pt-10 mx-auto space-y-4 text-center">
            <h1
              data-aos="fade-up"
              className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
            >6 Domains and 18 Themes of Teen TalentPlusStrengths?</h1>
            <p data-aos="fade-up" className="md:text-lg text-base text-[#212121]">
              Your customized Teen TalentPlusStrengths reports will help you to
              experience new ways to understand what makes you so unique.
            </p>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center w-full gap-10 px-4 pt-16 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
          <div
            data-aos="fade-up"
            className="flex flex-col items-center justify-start w-full gap-2 overflow-x-auto border-collapse md:flex-row md:justify-center whitespace-nowrap "
          >

            <button
              onClick={() => this.handleTabClick(1)}
              className={
                this.state.activeTab === 1 ? "border-[#673AB7] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  : " border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-slate-50 transition-all duration-150 md:w-56 w-full uppercase"
              }
            >
              Learning
            </button>



            <button
              onClick={() => this.handleTabClick(2)}
              className={
                this.state.activeTab === 2 ? "border-[#FFC107] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  : " border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-slate-50 transition-all duration-150 md:w-56 w-full uppercase"
              }
            >
              Thinking
            </button>


            <button
              onClick={() => this.handleTabClick(3)}
              className={
                this.state.activeTab === 3 ? "border-[#4CAF50] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  : " border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-slate-50 transition-all duration-150 md:w-56 w-full uppercase"
              }
            >
              Relating
            </button>


            <button
              onClick={() => this.handleTabClick(4)}
              className={
                this.state.activeTab === 4 ? "border-[#2196F3] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  : " border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-slate-50 transition-all duration-150 md:w-56 w-full uppercase"
              }
            >
              Acting
            </button>

            <button
              onClick={() => this.handleTabClick(5)}
              className={
                this.state.activeTab === 5 ? "border-[#FF9800] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  : " border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-slate-50 transition-all duration-150 md:w-56 w-full uppercase"
              }
            >
              Emotions
            </button>

            <button
              onClick={() => this.handleTabClick(6)}
              className={
                this.state.activeTab === 6 ? "border-[#00BCD4] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  : " border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-slate-50 transition-all duration-150 md:w-56 w-full uppercase"
              }
            >
              Communicating
            </button>

          </div>
        </section>

        {/* Learning section 1*/}
        {this.state.activeTab === 1 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Learning Domain of Teen TalentPlusStrengths?
                </h1>
                <p className="text-lg text-[#212121]">Learning talents encompass intellectual abilities, emphasizing deep thinking, exploring diverse themes, and strategic planning for the future. Individuals possessing these talents demonstrate a keen focus on intellectual pursuits, navigating complexities, and analyzing information to make informed decisions, using their ability to deliberate on ideas as a strategic pathway for continuous learning and growth.</p>
                {/* <p className="text-lg text-[#212121]">Leaders are the people responsible for a better tomorrow, so
                  they not only have to keep looking at the things to come but also make that dream a reality with the
                  help of others</p> */}
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="Learning"
                  width={790}
                  height={790}
                  src="/img/Home/Learning.webp"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex items-center justify-between w-full bg-white rounded-xl">
                <div className="w-full space-y-4">
                  <div className="pb-5">
                    <h2
                      data-aos="fade-up"
                      className="md:text-4xl text-3xl leading-[2.5rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#673AB7] pl-6 capitalize"
                    >
                      Learn More About Each Learning Teen TalentPlusStrengths Theme:
                    </h2>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Quencher
                    </h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You are an avid reader who finds excitement in gaining knowledge ahead of others. Your curious mind drives you to explore diverse subjects, and you take pleasure in sharing your insights with others. Your quick grasp of information, coupled with a belief in continuous learning, positions you as a knowledgeable and intelligent individual, capable of applying your wisdom to various aspects of life.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="quencher" src="./assessment_images/quencher.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>
             
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl w-[20%]">
                      Doer
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121]">
                        You are a passionate learner who values practical knowledge over theoretical understanding. Believing in the importance of hands-on experiences, you actively seek real-world challenges and engage in activities to gain wisdom, emphasizing the significance of experiential learning in navigating life's complexities.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="deor" src="./assessment_images/deor.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up" className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Explorer
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You live by the motto of embracing and exploring life every day, preferring to forge your unique path rather than following the familiar. Your open-mindedness and belief in firsthand experiences drive you to continually explore various possibilities, emphasizing the enrichment that comes from engaging in different activities and the importance of living a fully explored life.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="exploer" src="./assessment_images/exploer.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
          : null}

        {/* Thinking section 2*/}
        {this.state.activeTab === 2 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Thinking Domain of Teen TalentPlusStrengths?
                </h1>
                <p className="text-lg text-[#212121]">These are the talents which define your way of thinking and making sense of the world. Using these talents, you deliberate on ideas, possibilities, and the next course of action. This theme deals with intellectual abilities which is the hallmark of human progress.</p>
                <p className="text-lg text-[#212121]">Thinking about themes allows us to explore our past and gaze into the future so that we can plan things in the present. Thinking talents are the strategic pathway to our future.</p>
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="talentplusthinkingdomain.webp"
                  width={790}
                  height={790}
                  src="/img/Home/talentplusthinkingdomain.webp"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex items-center justify-between w-full bg-white rounded-xl">
                <div className="w-full space-y-4">
                  <div className="pb-5">
                    <h2
                      data-aos="fade-up"
                      className="md:text-4xl text-3xl leading-[2.5rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#fec029] pl-6 capitalize"
                    >
                      Learn More About Each Thinking Teen TalentPlusStrengths Theme:
                    </h2>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#fec029]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Disruptor
                    </h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You are an intelligent and innovative thinker, constantly generating new perspectives and disruptive solutions to problems, with a preference for independent and creative approaches over routine, inspired by role models who changed the world with their intelligence.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="disruptor" src="./assessment_images/disruptor.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#fec029]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Logical
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You have a deep love for numbers, data, and patterns, driven by a logical and analytical mindset, constantly seeking evidence, asking "why," and approaching problem-solving with a belief that every issue has a solution attainable through thorough investigation using data and patterns.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="logical" src="./assessment_images/logical.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up" className="items-center bg-[#fec029]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Emotional
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You are a passionate and expressive individual who embraces emotions openly, valuing authenticity, connecting with others through stories, appreciating art, and navigating life with a blend of warmth, sincerity, and a preference for heart over mind.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="emotional" src="./assessment_images/emotional.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
          : null}

        {/* Relating section 3 */}
        {this.state.activeTab === 3 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Relating Domain of Teen TalentPlusStrengths?
                </h1>
                <p className="text-lg text-[#212121]">Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life</p>
                <p className="text-lg text-[#212121]">It is the bond between one person and another, which takes us higher and ensures we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together.</p>
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="talentplusleadingdomain"
                  width={790}
                  height={790}
                  src="/img/Home/talentplusrelatingdomain.webp"
                />
              </div>
            </div>
            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex items-center justify-between w-full bg-white rounded-xl">
                <div className="w-full space-y-4">
                  <div className="pb-5">
                    <h2
                      data-aos="fade-up"
                      className=" md:text-4xl text-3xl leading-[2.5rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#4fb055] pl-6 capitalize"
                    >
                      Learn More About Each Relating Teen TalentPlusStrengths Theme:
                    </h2>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#4fb055]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Pleaser
                    </h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You are a charismatic and attention-loving individual, skilled at charming others with your manners, knowledge, and enthusiasm, striving to be in everyone's good books and adept at making people comfortable and energized in group settings.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="pleaser" src="./assessment_images/pleaser.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#4fb055]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Binder
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You prioritize deep connections, cherishing a few lifelong friendships, and while you may not have many friends, the ones you do have hold a special place in your heart, and you value open and enduring relationships that require effort and commitment.

                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="binder" src="./assessment_images/binder.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up" className="items-center bg-[#4fb055]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Lone Ranger
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You find contentment in solitude, viewing yourself as your best companion, relishing moments of self-reflection and intellectual pursuits, often preferring independence in navigating life's challenges without necessarily seeking understanding or validation
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="lone-ranger" src="./assessment_images/lone-ranger.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
          : null}

        {/* Acting section 4 */}
        {this.state.activeTab === 4 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Acting Domain of Teen TalentPlusStrengths?
                </h1>

                <p className="text-lg text-[#212121]">These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen.</p>
                <p className="text-lg text-[#212121]">It is these themes which make things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently.</p>
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="talentplusactingdomain"
                  width={790}
                  height={790}
                  src="/img/Home/talentplusactingdomain.webp"
                />
              </div>
            </div>
            <section className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex items-center justify-between w-full bg-white rounded-xl">
                <div className="w-full space-y-4">
                  <div className="pb-5">
                    <h2
                      data-aos="fade-up"
                      className=" md:text-4xl text-3xl leading-[2.5rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#2b97f1] pl-6 capitalize"
                    >
                      Learn More About Each Acting Teen TalentPlusStrengths Theme:
                    </h2>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#2b97f1]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Discipline
                    </h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You excel in maintaining a disciplined and structured life, adhering to schedules with punctuality and efficiency, garnering admiration for your organized approach and thoughtful actions, distinguishing yourself by creating a fulfilling and productive routine.
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="discipline" src="./assessment_images/discipline.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#2b97f1]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Achieving
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        Your relentless work ethic, commitment to completing tasks, and unwavering determination make you a hardworking individual with a strong sense of accomplishment and reliability, setting you on a path to achieve great things in life.
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="achieving" src="./assessment_images/achieving.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up" className="items-center bg-[#2b97f1]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Competitive
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        Your relentless drive to win, coupled with a strategic approach to competitions, defines you as a natural-born winner who seeks the top spot as the ultimate prize. Unapologetic about your desire for victory, you put in extra effort to ensure comprehensive success, taking pride in receiving recognition and accolades for your accomplishments.
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="competitive" src="./assessment_images/competitive.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </section>
          : null}

        {/* Emotions section 5 */}
        {this.state.activeTab === 5 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Emotions Domain of Teen TalentPlusStrengths?
                </h1>
                <p className="text-lg text-[#212121]">Emotion talents, encapsulating the Excitable, Circumspect, and Non-Reactive themes, provide individuals with versatile approaches to engaging and expressing emotions. Recognizing and utilizing these emotional themes can improve interpersonal relationships, facilitating effective communication and collaboration. Striking a balance among these talents enables individuals to navigate a broad spectrum of emotional expressions, adapting to diverse situations with emotional intelligence and resilience.</p>
                {/* <p className="text-lg text-[#212121]">Leaders are the people responsible for a better tomorrow, so
                  they not only have to keep looking at the things to come but also make that dream a reality with the
                  help of others</p> */}
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="Emotions"
                  width={790}
                  height={790}
                  src="/img/Home/Emotions.webp"
                />
              </div>
            </div>
            <section className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex items-center justify-between w-full bg-white rounded-xl">
                <div className="w-full space-y-4">
                  <div className="pb-5">
                    <h2
                      data-aos="fade-up"
                      className=" md:text-4xl text-3xl leading-[2.5rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#fd9721] pl-6 capitalize"
                    >
                      Learn More About Each Emotions Teen TalentPlusStrengths Theme:
                    </h2>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#fd9721]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Excitable
                    </h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        You are someone who knows where your life is going. You
                        are in control of things happening in your life. You feel
                        you have a compass which guides you in which direction you must go. People may suggest but it's you who decides
                        your next step.
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="excitable" src="./assessment_images/excitable.svg" width={96} height={96} />

                      </div>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="items-center bg-[#fd9721]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                  >
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Circumspect
                    </h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        Your remarkable emotional maturity at a young age, coupled with a balanced outlook on life, makes you a source of advice for others who appreciate your calm and composed nature, emphasizing your capability to handle situations independently and navigate diverse relationships with a unique tranquility and emotional intelligence that sets you apart.
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="circumspect" src="./assessment_images/circumspect.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up" className="items-center bg-[#fd9721]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                      Non Reactive
                    </h1>

                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                        Your pragmatic worldview acknowledges life's challenges, and your exceptional risk assessment skills allow you to foresee potential obstacles, making you a cautious and strategic planner who, although starting interactions with skepticism, can be a valuable asset to any team due to your ability to anticipate and navigate challenges effectively.
                      </p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="non-reactive" src="./assessment_images/non-reactive.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </section>
          : null}

        {/* Communicating section 6 */}
        {this.state.activeTab === 6 ?
          <section>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem] capitalize font-bold text-[#212121]">  What Is the Communicating Domain of Teen TalentPlusStrengths?</h1>
                <p className="text-lg text-[#212121]">Communicating talent involves adeptly conveying thoughts and ideas, utilizing various communication styles to connect with others effectively. This talent extends beyond linguistic skills, encompassing active listening, understanding diverse perspectives, and adapting communication strategies for successful interactions in both personal and professional settings.</p>
                {/* <p className="text-lg text-[#212121]">Leaders are the people responsible for a better tomorrow, so
                  they not only have to keep looking at the things to come but also make that dream a reality with the
                  help of others</p> */}
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="Communicating"
                  width={790}
                  height={790}
                  src="/img/Home/Communicating.webp"
                />
              </div>
            </div>

            <div className="items-center w-full gap-10 px-4 pb-10 mx-auto md:pb-20 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="flex items-center justify-between w-full bg-white rounded-xl">
                <div className="w-full space-y-4">
                  <div className="pb-5">
                    <h2 data-aos="fade-up" className=" md:text-4xl text-3xl leading-[2.5rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#1cbdd5] pl-6 capitalize">Learn More About Each Communicating Teen TalentPlusStrengths Theme:</h2>
                  </div>
                  <div data-aos="fade-up" className="items-center bg-[#1cbdd5]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]">Reserve</h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121]">Your preference for silence defines your approach to life, emphasizing action over words. As a silent observer, you possess a unique ability to grasp concepts without distraction, achieving significant milestones with minimal verbal expression. Despite being perceived as reserved or shy, you prioritize the impact of your actions over vocalizing your achievements, embodying a silent leadership style that aims to change the world without unnecessary noise.</p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="reserve" src="./assessment_images/reserve.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>
                  <div data-aos="fade-up" className="items-center bg-[#1cbdd5]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl w-[20%]">Speaker</h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121]">You are a natural performer who loves the stage and thrives on being in the center of attention, possessing exceptional verbal skills and a gift for effective communication. Your articulate expression, fondness for storytelling, and confidence in open communication mark you as a potential star in the making, emphasizing the importance of connecting with others in building relationships and solving problems.</p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="speaker" src="./assessment_images/speaker.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>
                  <div data-aos="fade-up" className="items-center bg-[#1cbdd5]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                    <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]">Engager</h1>
                    <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                      <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">You excel in one-on-one communication, earning trust effortlessly with your empathetic understanding, making people feel comfortable to open up to you. Your ability to engage in deep conversations and provide full attention creates a unique connection.</p>
                      <div className="w-16 xl:w-28 md:w-20">
                        <img alt="ideation" src="./assessment_images/engager.svg" width={96} height={96} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
          : null}

        {/* Contact Us */}
        <section className="py-20 bg-[#F9F9FC] space-y-10" >
          {/* <h2 data-aos="fade-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121] text-center aos-init aos-animate capitalize">Get in Touch</h2> */}
          <div className="grid w-full grid-cols-1 gap-8 px-4 mx-auto text-gray-100 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:grid-cols-2">

            <div data-aos="fade-right" className="space-y-4 ">
              <div data-aos="fade-up" className="space-y-2 text-[#212121]">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#212121]  capitalize">
                  Get in touch
                </h2>
                <p className="lg:text-lg text-base text-[#212121]">
                  For customer support and query, get in touch with us.
                </p>
              </div>

              <div className="space-y-0 ">
                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 border-b">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#212121] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-mail"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="3" y="5" width="18" height="14" rx="2" />
                      <polyline points="3 7 12 13 21 7" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#212121] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Email
                    </h1>
                    <p className="text-base ">connect@happyplus.in</p>
                  </div>
                </div>

                <div data-aos="fade-up" className="flex flex-row items-center gap-6 py-4 ">
                  <div
                    className="flex justify-center items-center border w-16 h-16 bg-white rounded-full text-[#3D405B] "
                    href="#0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="3" y1="21" x2="21" y2="21" />
                      <line x1="9" y1="8" x2="10" y2="8" />
                      <line x1="9" y1="12" x2="10" y2="12" />
                      <line x1="9" y1="16" x2="10" y2="16" />
                      <line x1="14" y1="8" x2="15" y2="8" />
                      <line x1="14" y1="12" x2="15" y2="12" />
                      <line x1="14" y1="16" x2="15" y2="16" />
                      <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                    </svg>
                  </div>
                  <div className=" mt-1 text-[#3D405B] flex-1">
                    <h1 className="text-xl font-semibold ">
                      Office Address:
                    </h1>
                    <p className="text-base ">
                      HappyPlus Consulting Pvt. Ltd. Goregaon (e), Mumbai
                      400065
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <img
                  data-aos="fade-right"
                  src="/img/domaim/contact-us.svg"
                  alt="contact-us"
                  className="flex items-center justify-center p-6 w-6/6 "
                  width={490}
                  height={360}
                />
              </div>
            </div>

            <div data-aos="fade-left" className="space-y-6 ">
              <div data-aos="fade-up" className="space-y-2 ">
                <h2 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                  Submit Details
                </h2>
                <p className="lg:text-lg text-base text-[#3D405B]">
                  Fill in your details below & we will get back to you soon!
                </p>
              </div>

              <div noValidate action className="grid grid-cols-1 gap-6 pt-2">
                <div className="space-y-2 ">
                  <label
                    htmlFor="name"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678] capitalize"
                  >
                    Full name
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["userName"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="userName"
                    name="userName"
                    placeholder="Enter Name"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["userName"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["userName"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="email"
                    className="text-base font-medium text-[#212121] before:content-['*'] before:text-[#F14678]"
                  >
                    Email
                  </label>
                  <input
                    className={`w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md ${!this.state.errorslogin["email"]
                      ? "placeholder-gray-500"
                      : "border border-opacity-100 border-red-500 "
                      }`}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin["email"] ? (
                    <div className="pt-2 text-xs text-red-600 invalid-feedback">
                      {this.state.errorslogin["email"]}
                    </div>
                  ) : null}
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="phone"
                    className="text-base font-medium text-[#212121]"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md"
                    id="phone"
                    name="phone"
                    placeholder="+91 "
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <div className="space-y-2 ">
                  <label
                    htmlFor="message"
                    className="text-base font-medium text-[#212121]"
                  >
                    {" "}
                    Message (If Any)
                  </label>
                  <textarea
                    rows={3}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none focus:border-[#2196F3] focus:shadow-md resize-none"
                    id="message"
                    name="message"
                    placeholder=""
                    type="text"
                    onChange={this.inputChange}
                  />
                </div>

                <button
                  type="button"
                  className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150"
                  onClick={this.loginSubmit}
                >
                  {" "}
                  Send Message
                </button>

                {this.state.saveSuccess ? (
                  <button
                    type="button"
                    className="w-full flex space-x-4 items-center py-4 px-4 text-base  tracking-wide text-[#4CAF50] rounded bg-[#4CAF5012]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-circle-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <span>Your query has been successfully sent!</span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
TeenTalent.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(TeenTalent);
