import React from "react";
export default function LanguageComponent(props) {
  const {
    surveyLanguageName,
    handleLanguage,
    langList,
    surveyLanguageRTL,
    template,
  } = props;

  let [showDrop, openLangModal] = React.useState(false);

  return (
    <>
      {surveyLanguageRTL ? (
        <div dir="rtl" lang="ar" className="flex justify-between">
          <div />

          <div className="lg:flex  border-r pr-3 m-4 items-center ">
            <h1
              onClick={() => openLangModal(!showDrop)}
              className="text-sm cursor-pointer"
            >
              {surveyLanguageName}
            </h1>
            <span
              onClick={() => openLangModal(!showDrop)}
              className="cursor-pointer material-icons"
            >
              arrow_drop_down
            </span>

            {showDrop ? (
              <div
                className={
                  "absolute " +
                  (template === "v4" || "v2" ? " top-10 " : " top-10 ") +
                  "  z-10 left-10 bg-white shadow-xl w-auto  py-1.5  lang"
                }
                style={{ maxHeight: "150px", overflowY: "auto" }}
              >
                {langList && langList.length > 0
                  ? langList.map((item) =>
                      surveyLanguageName !== item.name ? (
                        <span
                          onClick={() => {
                            handleLanguage(item);
                            openLangModal(!showDrop);
                          }}
                          className="cursor-pointer block text-xs w-full hover:bg-gray-100 py-1.5 px-4"
                        >
                          {item.name}
                        </span>
                      ) : null
                    )
                  : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex justify-between"> 

          <div
            className={
              template === "v2"
                ? "lg:flex    items-center    rounded-lg "
                : "lg:flex    items-center    rounded-lg"
            }
          >
            <div className=" p-2 rounded-md flex items-center space-x-2 ">
              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-world"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#1976D2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M3.6 9h16.8" />
                  <path d="M3.6 15h16.8" />
                  <path d="M11.5 3a17 17 0 0 0 0 18" />
                  <path d="M12.5 3a17 17 0 0 1 0 18" />
                </svg>

                <h1
                  onClick={() => openLangModal(!showDrop)}
                  className="text-base cursor-pointer font-medium text-[#3D405B] "
                >
                  {surveyLanguageName}
                </h1>
              </div>
              <span
                onClick={() => openLangModal(!showDrop)}
                className="cursor-pointer material-icons text-[1.5rem]"
              >
                arrow_drop_down
              </span>
            </div>

            {showDrop ? (
              <div
                className={
                  "absolute " +
                  (template === "v4" || "v2" ? " top-14 " : " top-5 ") +
                  " left-0 bg-white text-xs w-40 py-2  text-[#3D405B] rounded-sm shadow-xl  "
                }
              >
                {surveyLanguageName !== "English" ? (
                  <>
                    <span
                      onClick={() => {
                        handleLanguage({
                          name: "English",
                          shortName: "en",
                        });
                        openLangModal(!showDrop);
                      }}
                      className="block px-4 py-1 hover:bg-gray-100 text-base"
                    >
                      {"English"}
                    </span>
                  </>
                ) : null}

                {langList && langList.length > 0
                  ? langList.map((item) =>
                      surveyLanguageName !== item.name ? (
                        <span
                          onClick={() => {
                            handleLanguage(item);
                            openLangModal(!showDrop);
                          }}
                          className="block px-4 py-1 hover:bg-gray-100 text-base h-16 z-10"
                        >
                          {item.name}
                        </span>
                      ) : null
                    )
                  : null}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
