import React from "react";
import HeatMap from "./Summary/HeatMap";
import BackSummary from "./Summary/ExpertsSummary/BackSummary";

export default function Component(props) {
  const {
    handleReport,
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    createdAt,
    EditButtonFn,
    EditDetailsFn,
    handleSummaryReport,
    getTheme,
    loading,


    backSummary,
    handleShowMainSummaryModal,
    handleShowMainSummaryModalBack,
    contactExpertSection,
    handleExpertContactModal,
    topFiveTalent,
    handleExpertContactModalBack,
    crrDetail,
    crrCodeUserDetails,
    handleInputExpertForm,
    bookExpert,
    userAssessmentExpertOrderPlaceData,
    handleTestPayment,
    viewMyExpert,
    showMyExpertList,
    errorsCrrCodeUserDetails,

    getExpertOrderBookListData,
    getTalentResourcesData,
    handlePlayVideo,
    viewMyVideoReport


  } = props;


  console.log('backSummary--------------------->', backSummary)


  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace("&nbsp;", "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      console.log("descX", descX);
      console.log("temp", temp);
      descNew = temp.split("@").filter((prev) => prev !== "");
      // console.log("descNew",descNew)
    }
    return descNew;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - every day?"
        : "How to deal with a person who has - talent?";
    let final = itemName.replace("-", element.dimension.trim());
    console.log("final--->", final.toString());
    return final.toString();
  }

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function chunkList(array) {
    const chunkSize = 7;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    console.log("list--->", list);
    return list;
  }

  function getWeightage(rank) {
    let value = 0;
    value = 22 - rank;
    return value
  }

  let getAssessmentDimensionsData = [];

  if (getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0) {
    for (var i = 0; i < getAssessmentReportByEmpIDData.length; i++) {
      let detail = getAssessmentReportByEmpIDData[i];

      getAssessmentDimensionsData.push({
        "bucket": detail.bucket,
        "colorCode": detail.colorCode,
        "count": detail.count,
        "dimension": detail.dimension,
        "finalSum": Number(detail.count) * Number(detail.rankSum),
        "rankSum": getWeightage(i + 1)
      });

    }

    getAssessmentDimensionsData.sort((a, b) => a.rankSum - b.rankSum)

  }

  function checkForGeneralship(bucket, dimension) {
    let text = "";
    if (bucket && dimension) {
      if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }
  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }






  return (
    <>
      <section className="w-full px-4 py-2 mx-auto xl:w-11/12 xl:px-0">
        <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
          <BackSummary
            getAssessmentDimensionsData={getAssessmentDimensionsData}
            EditButtonFn={EditButtonFn}
            EditDetailsFn={EditDetailsFn}
            loading={loading}
            handleShowMainSummaryModalBack={handleShowMainSummaryModalBack}
            contactExpertSection={contactExpertSection}
            handleExpertContactModal={handleExpertContactModal}
            topFiveTalent={topFiveTalent}
            handleExpertContactModalBack={handleExpertContactModalBack}

            getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
            handleShowMainSummaryModal={handleShowMainSummaryModal}
            crrDetail={crrDetail}
            crrCodeUserDetails={crrCodeUserDetails}

            handleInputExpertForm={handleInputExpertForm}
            errorsCrrCodeUserDetails={errorsCrrCodeUserDetails}
            bookExpert={bookExpert}
            userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
            handleTestPayment={handleTestPayment}
            showMyExpertList={showMyExpertList}

            getExpertOrderBookListData={getExpertOrderBookListData}

            getTalentResourcesData={getTalentResourcesData}
          />
        </div>

        {/*  */}
      </section>

      <div className="pt-4 ">
        <div className="fixed bottom-0 flex justify-center w-full p-4 bg-white">
          <p className="text-sm text-black/80 ">
            © Powered by
            <b className="ml-1 font-medium text-black/80">HappyPlus</b>
          </p>
        </div>
      </div>
    </>
  );
}
