import React from "react";
import HeatMap from "./Summary/HeatMap";
import BackSummary from "./Summary/ExpertsSummary/BackSummary";
import TopBottomModal from "./TopBottomModal";

export default function Component(props) {
  const {
    top5Talent,
    handleReport,
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    createdAt,
    EditButtonFn,
    EditDetailsFn,
    handleSummaryReport,
    getTheme,
    loading,


    backSummary,
    handleShowMainSummaryModal,
    handleShowMainSummaryModalBack,
    contactExpertSection,
    handleExpertContactModal,
    topFiveTalent,
    handleExpertContactModalBack,
    crrDetail,
    crrCodeUserDetails,
    handleInputExpertForm,
    bookExpert,
    userAssessmentExpertOrderPlaceData,
    handleTestPayment,
    viewMyExpert,
    showMyExpertList,
    errorsCrrCodeUserDetails,

    getExpertOrderBookListData,
    getTalentResourcesData,
    handlePlayVideo,
    viewMyVideoReport,
    isSchool,
    surveyLanguageRTL


  } = props;


  console.log('backSummary--------------------->', backSummary)


  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace("&nbsp;", "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      console.log("descX", descX);
      console.log("temp", temp);
      descNew = temp.split("@").filter((prev) => prev !== "");
      // console.log("descNew",descNew)
    }
    return descNew;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - every day?"
        : "How to deal with a person who has - talent?";
    let final = itemName.replace("-", element.dimension.trim());
    console.log("final--->", final.toString());
    return final.toString();
  }

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function chunkList(array) {
    const chunkSize = 7;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    console.log("list--->", list);
    return list;
  }

  function getWeightage(rank) {
    let value = 0;
    value = 22 - rank;
    return value
  }

  let getAssessmentDimensionsData = [];

  if (getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0) {
    for (var i = 0; i < getAssessmentReportByEmpIDData.length; i++) {
      let detail = getAssessmentReportByEmpIDData[i];

      getAssessmentDimensionsData.push({
        "bucket": detail.bucket,
        "colorCode": detail.colorCode,
        "count": detail.count,
        "dimension": detail.dimension,
        "finalSum": Number(detail.count) * Number(detail.rankSum),
        "rankSum": getWeightage(i + 1)
      });

    }

    getAssessmentDimensionsData.sort((a, b) => a.rankSum - b.rankSum)

  }

  function checkForGeneralship(bucket, dimension, isTeen) {
    let text = "";
    console.log("isTeen---->X", isTeen);
    console.log("dimension---->X", dimension.toLowerCase());
    if (isTeen) {
      text = "school_";
    } else {
      if (bucket && dimension) {
        if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
          text = "old";
        }
      }

    }
    return text
  }
  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }


  return (
    <>


      {/* /assessment/survey */}
      <section className="w-full px-4 py-2 mx-auto xl:w-11/12 xl:px-0">

        {top5Talent ?
          <>

            {backSummary ?
              <>
                <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                  <TopBottomModal
                    getAssessmentDimensionsData={getAssessmentDimensionsData}
                    EditButtonFn={EditButtonFn}
                    EditDetailsFn={EditDetailsFn}
                    loading={loading}
                    handleShowMainSummaryModalBack={handleShowMainSummaryModalBack}
                    contactExpertSection={contactExpertSection}
                    handleExpertContactModal={handleExpertContactModal}
                    topFiveTalent={topFiveTalent}
                    handleExpertContactModalBack={handleExpertContactModalBack}

                    getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                    handleShowMainSummaryModal={handleShowMainSummaryModal}
                    crrDetail={crrDetail}
                    crrCodeUserDetails={crrCodeUserDetails}

                    handleInputExpertForm={handleInputExpertForm}
                    errorsCrrCodeUserDetails={errorsCrrCodeUserDetails}
                    bookExpert={bookExpert}
                    userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
                    handleTestPayment={handleTestPayment}
                    showMyExpertList={showMyExpertList}

                    getExpertOrderBookListData={getExpertOrderBookListData}

                    getTalentResourcesData={getTalentResourcesData}
                    isSchool={isSchool}
                  />
                </div>
              </> : null
            }

          </> :
          <>
            <div className="w-full mx-auto space-y-6">
              <div className="p-6 mt-6 space-y-2 text-base font-normal text-left bg-white border rounded-lg md:space-y-6">
                <h1 className={(surveyLanguageRTL ? "text-right" : "") + " text-2xl font-semibold  text-[#212121]"}>
                  {
                    isSchool ? EditButtonFn('TalentAssessmentSummary', "Teen TalentPlusStrengths  Assessment Summary") : EditButtonFn('TalentAssessmentSummary', "TalentPlusStrengths  Assessment Summary")
                  }
                </h1>
                <div className="flex flex-col items-start gap-4 md:flex-row">
                  <p className={(surveyLanguageRTL ? "text-right" : "") + " text-base  text-[#212121] md:text-lg "} >
                    {EditButtonFn('TalentAssessmentDesc', `Your talents are the essence of who you are, shaping how you think, feel, and act. Understanding and leveraging these talents are key to success. They enable you to find your flow, excel at work, and stand out authentically. Embracing your uniqueness leads to fulfillment and happiness, allowing you to be your true self.`)}
                  </p>
                  <div className="flex items-center justify-center w-16 h-16 flex-shrink-0 bg-[#FFC107]/10 rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2455 15.6916 34.5319 11.561 31.4855 8.51454C28.439 5.46806 24.3084 3.75455 20 3.75ZM20 33.75C17.2805 33.75 14.6221 32.9436 12.3609 31.4327C10.0997 29.9218 8.33737 27.7744 7.29666 25.2619C6.25596 22.7494 5.98366 19.9847 6.51421 17.3175C7.04476 14.6503 8.35432 12.2003 10.2773 10.2773C12.2003 8.35431 14.6503 7.04475 17.3175 6.5142C19.9848 5.98366 22.7494 6.25595 25.2619 7.29666C27.7744 8.33736 29.9218 10.0997 31.4327 12.3609C32.9436 14.6221 33.75 17.2805 33.75 20C33.7459 23.6455 32.2959 27.1404 29.7182 29.7182C27.1404 32.2959 23.6455 33.7459 20 33.75ZM22.5 27.5C22.5 27.8315 22.3683 28.1495 22.1339 28.3839C21.8995 28.6183 21.5815 28.75 21.25 28.75C20.587 28.75 19.9511 28.4866 19.4822 28.0178C19.0134 27.5489 18.75 26.913 18.75 26.25V20C18.4185 20 18.1005 19.8683 17.8661 19.6339C17.6317 19.3995 17.5 19.0815 17.5 18.75C17.5 18.4185 17.6317 18.1005 17.8661 17.8661C18.1005 17.6317 18.4185 17.5 18.75 17.5C19.413 17.5 20.0489 17.7634 20.5178 18.2322C20.9866 18.7011 21.25 19.337 21.25 20V26.25C21.5815 26.25 21.8995 26.3817 22.1339 26.6161C22.3683 26.8505 22.5 27.1685 22.5 27.5ZM17.5 13.125C17.5 12.7542 17.61 12.3916 17.816 12.0833C18.022 11.775 18.3149 11.5346 18.6575 11.3927C19.0001 11.2508 19.3771 11.2137 19.7408 11.286C20.1045 11.3584 20.4386 11.537 20.7008 11.7992C20.9631 12.0614 21.1416 12.3955 21.214 12.7592C21.2863 13.1229 21.2492 13.4999 21.1073 13.8425C20.9654 14.1851 20.725 14.478 20.4167 14.684C20.1084 14.89 19.7458 15 19.375 15C18.8777 15 18.4008 14.8025 18.0492 14.4508C17.6976 14.0992 17.5 13.6223 17.5 13.125Z" fill="#FFC107" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                <HeatMap
                  getAssessmentDimensionsData={getAssessmentDimensionsData}
                  EditButtonFn={EditButtonFn}
                  handlePlayVideo={handlePlayVideo}
                  isSchool={isSchool}
                />
              </div>

              <div className="pb-12 space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                <div className="lg:w-[30%] w-full">
                  <div className="w-full p-4 space-y-6 bg-white border rounded-lg md:p-8 ">
                    <div className="pb-4 border-b ">
                      <h1 className={(surveyLanguageRTL ? "text-right" : "") + " text-xl font-semibold text-[#212121] md:text-2xl"}>
                        {
                          isSchool ? EditButtonFn('ViewReport', "Domains Key of TEEN TALENTPLUSSTRENGTHS") : EditButtonFn('ViewReport', "Domains Key of TALENTPLUSSTRENGTHS")
                        }
                      </h1>
                    </div>
                    {!isSchool ?
                      <div className={(surveyLanguageRTL ? "text-right" : "") + " w-full space-y-4"}>
                        <div className="flex items-end w-full space-x-2 space-y-2 text-right">
                          <div className="space-y-2 text-md">
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('THINKINGTALENTS', "THINKING TALENTS")}
                              <p
                                className="w-4 h-4 mb-1 ml-2 text-lg bg-red-500"
                                style={{ background: "#FFC107" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "THINKINGDESC",
                                "These are the talents which define your way of thinking and making a sense of the world. Using these talents, you deliberate on ideas, possibilities, and next course of action. This theme deals with intellectual abilities which is the hallmark of human progress. Thinking about themes allows us to explore our past and gaze into the future so that we can plan things in the present. Thinking talents are the strategic pathway to our future."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('RELATINGTALENTS', "RELATING TALENTS")}
                              <p
                                className="w-4 h-4 mb-1 ml-2 text-lg bg-red-500"
                                style={{ background: "#4CAF50" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "RELATINGDESC",
                                "Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life. It is the bond between one person and another, which takes us higher and ensures we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <span className={"w-5 h-5 rounded-full "} />
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('ACTINGTALENTS', "ACTING TALENTS")}
                              <p
                                className="w-4 h-4 ml-2 bg-red-500"
                                style={{ background: "#2196F3" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "ACTINGDESC",
                                "These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen. It is these themes which make things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <span
                              style={{ background: "#673AB7" }}
                              className={"w-5 h-5 rounded-full "}
                            />
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('LEADINGTALENTS', "LEADING TALENTS")}
                              <p
                                className="w-4 h-4 ml-2 bg-red-500"
                                style={{ background: "#673AB7" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "LEADINGDESC",
                                "These are the talents, which defines our leadership. Leadership is not a position but it is a mindset, through which people share their vision with others, get them aligned and motivated and ensure work takes place towards that desired future. Leaders are the people responsible for a better tomorrow, so they not only have to keep looking at the things to come but also make that dream a reality with the help of others."
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="w-full space-y-4">
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2 text-md">
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('LEARNINGTALENTS', "LEARNING TALENTS")}
                              <p
                                className="w-4 h-4 mb-1 ml-2 text-lg bg-red-500"
                                style={{ background: "#673AB7" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "LEARNINGDESC",
                                "Learning talents encompass intellectual abilities, emphasizing deep thinking, exploring diverse themes, and strategic planning for the future. Individuals possessing these talents demonstrate a keen focus on intellectual pursuits, navigating complexities, and analyzing information to make informed decisions, using their ability to deliberate on ideas as a strategic pathway for continuous learning and growth."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2 text-md">
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('THINKINGTALENTS', "THINKING TALENTS")}
                              <p
                                className="w-4 h-4 mb-1 ml-2 text-lg bg-red-500"
                                style={{ background: "#FFC107" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "THINKINGDESC",
                                "These are the talents which define your way of thinking and making a sense of the world. Using these talents, you deliberate on ideas, possibilities, and next course of action. This theme deals with intellectual abilities which is the hallmark of human progress. Thinking about themes allows us to explore our past and gaze into the future so that we can plan things in the present. Thinking talents are the strategic pathway to our future."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('RELATINGTALENTS', "RELATING TALENTS")}
                              <p
                                className="w-4 h-4 mb-1 ml-2 text-lg bg-red-500"
                                style={{ background: "#4CAF50" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "RELATINGDESC",
                                "Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life. It is the bond between one person and another, which takes us higher and ensures we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <span className={"w-5 h-5 rounded-full "} />
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('ACTINGTALENTS', "ACTING TALENTS")}
                              <p
                                className="w-4 h-4 ml-2 bg-red-500"
                                style={{ background: "#2196F3" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "ACTINGDESC",
                                "These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen. It is these themes which make things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently."
                              )}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <span
                              style={{ background: "#FF9800" }}
                              className={"w-5 h-5 rounded-full "}
                            />
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('EMOTIONSTALENTS', "EMOTIONS TALENTS")}
                              <p
                                className="w-4 h-4 ml-2 bg-red-500"
                                style={{ background: "#FF9800" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "EMOTIONSDESC",
                                "Emotion talents, encapsulating the Excitable, Circumspect, and Non-Reactive themes, provide individuals with versatile approaches to engaging and expressing emotions. Recognizing and utilizing these emotional themes can improve interpersonal relationships, facilitating effective communication and collaboration. Striking a balance among these talents enables individuals to navigate a broad spectrum of emotional expressions, adapting to diverse situations with emotional intelligence and resilience."
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-end w-full space-x-2 space-y-2">
                          <div className="space-y-2">
                            <span
                              style={{ background: "#673AB7" }}
                              className={"w-5 h-5 rounded-full "}
                            />
                            <div className="flex items-center mr-2 text-base font-bold">

                              {EditButtonFn('COMMUNICATINGTALENTS', "COMMUNICATING TALENTS")}
                              <p
                                className="w-4 h-4 ml-2 bg-[#00bcd4]"
                                style={{ background: "#00bcd4" }}
                              />
                            </div>
                            <p className="text-base text-slate-600">
                              {EditButtonFn(
                                "COMMUNICATINGDESC",
                                "Communicating talent involves adeptly conveying thoughts and ideas, utilizing various communication styles to connect with others effectively. This talent extends beyond linguistic skills, encompassing active listening, understanding diverse perspectives, and adapting communication strategies for successful interactions in both personal and professional settings."
                              )}
                            </p>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>

                <div className="lg:w-[70%] w-full space-y-6">
                  <div className="flex items-center justify-between w-full p-4 bg-white border rounded-lg md:p-8">
                    <div className="w-full space-y-4">
                      {getAssessmentReportByEmpIDData &&
                        getAssessmentReportByEmpIDData.length > 0
                        ? getAssessmentReportByEmpIDData.map((element, index1) => ((index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) ?
                          <>
                            {index1 + 1 === 1 ? (
                              <div className={(surveyLanguageRTL?"text-right":"")+" space-y-4 "}>
                                <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">
                                  {
                                    isSchool ? EditButtonFn('Top5TalentPlustheme', "Dominant Teen TalentPlusStrengths  theme") : EditButtonFn('Top5TalentPlustheme', "Dominant TalentPlusStrengths  theme")
                                  }
                                </h1>
                                <p className="text-base">
                                  {
                                    isSchool ? EditButtonFn('Top5TalentPlusthemeDesc', "Your Dominant Teen TalentPlusStrengths  theme: (Broadly they define who you are what makes you successful, they need to be understood, nurtured and used regularly to experience a thriving life)") : EditButtonFn('Top5TalentPlusthemeDesc', "Your Dominant TalentPlusStrengths  theme: (Broadly they define who you are what makes you successful, they need to be understood, nurtured and used regularly to experience a thriving life)")
                                  }
                                </p>
                              </div>
                            ) : null}

                            {(index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) ? (
                              <>
                                <div
                                  onClick={() => handleShowMainSummaryModal(element, 2)}
                                  style={{
                                    background: hexToRgbA(
                                      element && element.colorCode
                                        ? element.colorCode
                                        : "#DDDDDD",
                                      "0.20"
                                    ),
                                  }}
                                  className={
                                    "cursor-pointer rounded-lg 2xl:px-8 md:py-4 p-4 md:flex justify-between  items-center md:space-x-6 md:space-y-0 space-y-4 "
                                  }
                                >


                                  <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end ">
                                    <div className="w-16 xl:w-28 md:w-20">
                                      <img
                                        src={
                                          "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                                          ((element.dimension.replace(' ', '_')).toLowerCase()).trim() +
                                          ".svg"
                                        }
                                      />
                                    </div>
                                    <p className="flex-1 text-sm font-normal md:text-base text-slate-600 ">

                                      {EditDetailsFn(element, 'desc', element.details.desc)}
                                    </p>
                                  </div>

                                  <div className="2xl:space-y-4 space-y-2 md:w-[30%]">
                                    <button
                                      style={{
                                        background:
                                          element && element.colorCode
                                            ? element.colorCode
                                            : "#DDDDDD",
                                      }}
                                      className={
                                        " px-4 py-2 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest  rounded-full "
                                      }
                                    >
                                      {EditDetailsFn(element, 'bucket', element.bucket)}
                                    </button>
                                    <h1 className="text-xl font-medium 2xl:text-2xl text-slate-900 md:px-4 ">

                                      {EditDetailsFn(element, 'dimension', element.dimension)}{" ." + ((index1 + 1) > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1).toString() : (index1 + 1).toString())}

                                    </h1>
                                  </div>

                                </div>
                              </>
                            ) : null}
                          </>
                          : null))
                        : null}
                    </div>
                  </div>

                  <div className="flex items-center justify-between w-full p-4 bg-white border rounded-lg cursor-default md:p-8">
                    <div className="w-full space-y-4">
                      {getAssessmentReportByEmpIDData &&
                        getAssessmentReportByEmpIDData.length > 0
                        ? getAssessmentReportByEmpIDData.map((element, index1) => ((index1 + 1 >= getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1) ?
                          <>
                            {index1 + 1 === getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 ? (
                              <div className={(surveyLanguageRTL?"text-right":"")+" space-y-4 "}>
                                <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">
                                  {
                                    isSchool ? EditButtonFn('Bottom5TalentPlustheme', "Non Dominant Teen TalentPlusStrengths theme") : EditButtonFn('Bottom5TalentPlustheme', "Non Dominant TalentPlusStrengths theme")
                                  }
                                </h1>
                                <p className="text-base">
                                  {
                                    isSchool ? EditButtonFn('Bottom5TalentPlusthemeDesc', "Your Non Dominant Teen TalentPlusStrengths Themes (These are the talents, which are there but not much & you may need tolearn how to manage it to achieve your goals in life)") : EditButtonFn('Bottom5TalentPlusthemeDesc', "Your Non Dominant TalentPlusStrengths Themes (These are the talents, which are there but not much & you may need tolearn how to manage it to achieve your goals in life)")
                                  }
                                </p>
                              </div>
                            ) : null}

                            {(index1 + 1 >= getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1) ? (
                              <>
                                <div
                                  style={{
                                    background: hexToRgbA(
                                      element && element.colorCode
                                        ? element.colorCode
                                        : "#DDDDDD",
                                      "0.20"
                                    ),
                                  }}
                                  className={
                                    "rounded-lg 2xl:px-8 md:py-4 p-4 md:flex justify-between  items-center md:space-x-6 md:space-y-0 space-y-4 "
                                  }
                                >

                                  <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end ">
                                    <div className="w-16 xl:w-28 md:w-20">
                                      <img
                                        src={
                                          "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) +
                                          ((element.dimension.replace(' ', '_')).toLowerCase()) +
                                          ".svg"
                                        }
                                      />
                                    </div>
                                    <p className="flex-1 text-sm font-normal md:text-base text-slate-600 ">
                                      {EditDetailsFn(element, 'desc' + (index1 + 1), element.details.desc)}
                                    </p>


                                  </div>


                                  <div className="2xl:space-y-4 space-y-2 md:w-[30%] cursor-default">
                                    <button
                                      style={{
                                        background:
                                          element && element.colorCode
                                            ? element.colorCode
                                            : "#DDDDDD",
                                      }}
                                      className={
                                        " px-4 py-2 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest rounded-full cursor-default"
                                      }
                                    >
                                      {EditDetailsFn(element, 'bucket', element.bucket)}
                                    </button>
                                    <h1 className="text-xl font-medium 2xl:text-2xl text-slate-900 md:px-4 ">
                                      {EditDetailsFn(element, 'dimension', element.dimension)}{((index1 + 1) > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString() : (index1 + 1).toString()) + ". "}
                                    </h1>
                                  </div>


                                </div>
                              </>
                            ) : null}
                          </>
                          : null))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>

        }










      </section>

      <div className="pt-4 ">
        <div className="fixed bottom-0 flex justify-center w-full p-4 bg-white">
          <p className="text-sm text-black/80 ">
            © Powered by
            <b className="ml-1 font-medium text-black/80">HappyPlus</b>
          </p>
        </div>
      </div>
    </>
  );
}
