import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeatMapBlock from './Charts/HeatMapBlock';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import moment from 'moment';
import PageTemplate from "./PageTemplate";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      showMainPage: true,
      showFilter: false,
      showValue: '',
      showTabView: 2

    }
  }

  componentDidMount() {

  }


  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }


  handleStrength = (params) => {
    let { handleThemeStrenght } = this.props;
    let { FilterValues } = this.state;
    let name = ''
    if (params && params.name) {
      name = params.name;
    }
    handleThemeStrenght(name, FilterValues);
    this.setState({ showMainPage: false })
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {

    let { handlAssessmentDashboard } = this.props;
    this.setState({
      FilterValues: finalFilter,
      ListName: name,
      showMainPage: true
    });

    handlAssessmentDashboard(finalFilter, true);

  }

  handleGOTOTheme = () => {
    this.setState({ showMainPage: true })
  }

  handleTabView = (value) => {
    this.setState({ showTabView: value })
  }

  render() {

    let { loading, getAssessmentDimensionsData, EditButtonFn, handlePlayVideo, isSchool } = this.props;
    let { showTabView } = this.state;

    console.log("getAssessmentDimensionsData----->", getAssessmentDimensionsData)

    let AssessmentList = []
    if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
      getAssessmentDimensionsData.forEach((element) => {
        AssessmentList.push({
          ...element,
          finalSum: (Number(element.count) * Number(element.rankSum))
        })
      })
      AssessmentList.sort((a, b) => (a.finalSum - b.finalSumSum))
    }

    console.log("AssessmentList--1->", AssessmentList)


    let newList = []
    if (AssessmentList && AssessmentList.length > 0) {
      AssessmentList.forEach((element, index) => {

        let rank = (AssessmentList.length - index);

        let check = 0;
        if (showTabView === 1) {
          check = 1
        } else if (showTabView === 2 && (rank <= 5)) {
          check = 2
        } else if (showTabView === 3 && (rank <= 7)) {
          check = 3
        } else if (showTabView === 4 && (rank <= 10)) {
          check = 4
        }
        console.log("check--1->", check)

        let getIndex = newList.findIndex(prev => prev.bucket === element.bucket)
        if (getIndex === -1) {
          let temp = {
            "showCount": 0,
            "bucket": element.bucket,
            "colorCode": element.colorCode,
            "dimensions": [{
              "rank": rank,
              "check": check,
              "name": element.dimension,
              "count": element.count,
              "finalSum": element.finalSum,
              "colorCode": element.colorCode,
            }],
          }
          newList.push(temp)
        } else {
          newList[getIndex]["showCount"] += 1

          newList[getIndex]["dimensions"].push({
            "rank": rank,
            "check": check,
            "name": element.dimension,
            "count": element.count,
            "finalSum": element.finalSum,
            "colorCode": element.colorCode,
          })
        }



      });
    }


    let newV2List = [];
    if (newList && newList.length > 0) {
      for (var ix = 0; ix < newList.length; ix++) {
        let details = newList[ix];
        let temp = details;
        if (details && details.dimensions && details.dimensions.length > 0) {
          temp["dimensions"] = details.dimensions.sort((a, b) => (a.rank - b.rank));
        }
        newV2List.push(temp)
      }
    }

    return (
      <>

        <div className="w-full mt-4">

          <div className="grid grid-cols-1 gap-4 md:grid-cols-1">

            <div className="md:p-6 p-4 bg-white border shadow-sm rounded-xl">

              <div className='flex pt-2 pb-6'>
                <h1 className="text-xl font-semibold text-gray-800 md:text-2xl">
                  {
                    isSchool ? EditButtonFn('Top5TalentPlustheme', "Dominant Teen TalentPlusStrengths  theme") : EditButtonFn('Top5TalentPlustheme', "Dominant TalentPlusStrengths  theme")
                  }
                </h1>
              </div>

              <PDFExport ref={this.pdfExportComponent}
                pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} />}
                scale={0.6}
                forcePageBreak=".page-break"
                paperSize="A4"
                margin={0}
                fileName={`Assessment Dominant Themes - ${moment(Date.now()).format("DD/MM/YYYY")}`}

                author="Happyplus Team"
                landscape={true}
              >

                <div className={isSchool ? 'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3' : 'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4'}>
                  {newV2List && newV2List.length > 0 ?
                    newV2List.map((item) =>
                      <>
                        <div className={''}>
                          <h1 className='text-lg text-center text-[#3D405B] font-semibold bg-[#F2F2F2] px-4 py-3 mb-2'>{item.bucket}</h1>
                          <HeatMapBlock
                            item={item}
                            handlePlayVideo={handlePlayVideo}
                          />
                        </div>
                      </>
                    ) : null}
                </div>

                {!(newV2List && newV2List.length > 0) ?
                  <>
                    {!loading ?
                      <div className="pt-16 pb-24 text-center text-xl text-[#3d405B]/70">Data Not Available</div>
                      : null}
                  </>
                  : null}



              </PDFExport>

            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
