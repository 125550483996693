import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay, Scrollbar, A11y } from 'swiper/modules';

const SwiperComponent = (props) => {
    let { getDetailsList } = props;
    const sliderRef = useRef(null);

    const handlePrev = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slideNext();
        }
    };

    return (
        <div className="relative w-full space-y-6 ">
            <div className="flex items-center justify-between">
                <h1 className="text-3xl font-medium text-[#3D405B]">
                    Benefits
                </h1>
                <div className='flex flex-row gap-4'>
                    <div className="prev-arrow w-12 h-12 bg-gray-100 rounded-full cursor-pointer flex justify-center items-center hover:bg-[#1976D2] transition-all duration-150 text-gray-600 hover:text-white" onClick={handlePrev}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M15 6l-6 6l6 6"></path>
                        </svg>
                    </div>
                    <div className="next-arrow w-12 h-12 bg-gray-100 rounded-full cursor-pointer flex justify-center items-center hover:bg-[#1976D2] transition-all duration-150 text-gray-600 hover:text-white" onClick={handleNext}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </div>
                </div>
            </div>


            <Swiper
                ref={sliderRef}
                navigation={false}
                mousewheel={true}
                keyboard={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    1366: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    1920: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                }}
                pagination={true}
                spaceBetween={30}
                modules={[Navigation, Pagination, Autoplay]}
                slidesPerView={1}
                className="mySwiper min-h-64 2xl:max-h-80 xl:max-h-[28rem] pb-14  pr-4">

                <div className="">
                    {getDetailsList("features2") &&
                        getDetailsList("features2").length > 0
                        ? getDetailsList("features2").map((element) => (
                            <SwiperSlide className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50  gap-4 rounded-3xl items-start box-shadow-custom cursor-pointer space-y-4  flex flex-col justify-between  p-6   text-[#212121]/80 transition-all duration-100 hover:bg-[#1976D2] h-auto   ">
                                {/* <h2 className="text-lg font-semibold "> {element} </h2>  */}
                                <p className="font-medium "> {element} </p>

                            </SwiperSlide>
                        ))
                        : null}
                </div>
            </Swiper>


        </div>
    );
};

export default SwiperComponent;