import React from 'react';
import ReactEcharts from "echarts-for-react";
import { color } from 'echarts';


export default function GaugeScore(props) {

   let { score, label } = props;

   return ( 

         <div className="overflow-hidden" style={{ height: '250px' }}>

            <ReactEcharts style={{ width: '270px', height: '250px' }}
               option={{
                  // tooltip: {
                  //    trigger: 'item'
                  // },
                  series: [
                     {
                        type: 'gauge',
                        progress: {
                           show: true,
                           width: 18,
                           itemStyle: { 
                              color: '#4CAF50', // Green color for the progress bar
                           },
                        },
                        axisLine: {
                           lineStyle: {
                              width: 18,
                              color: [
                                 [1, '#E0E0E0'] // gray color for the track
                              ]
                           },

                        },
                        axisTick: {
                           show: false,
                           itemStyle: { 
                              color: '#4CAF50', // Green color for the progress bar
                           },
                        },
                        splitLine: {
                           length: 10,
                           lineStyle: {
                              width: 2,
                              color: '#E0E0E0'
                           }
                        },
                        pointer: {
                           show: true,
                           length: '62%',
                           width: 6,
                           itemStyle: {
                              color: '#4CAF50', // Green color for the needle
                           },
                        },
                        axisLabel: {
                           distance: 25,
                           color: '#E0E0E0',
                           fontSize: 8,
                           show: false
                        },
                        anchor: {
                           show: true,
                           showAbove: true,
                           size: 0,
                           color: '#4CAF50',
                           itemStyle: {
                              borderWidth: 6,
                              color: '#4CAF50',
                           }
                        },
                        title: {
                           show: false
                        },
                        detail: {
                           valueAnimation: true,
                           fontSize: 1,
                           offsetCenter: [0, '30%'],
                           color: '#4CAF50',
                        },
                        data: [
                           {
                              value: ((score * 100) / 6).toFixed(0)
                           }
                        ]
                     }
                  ]
               }}
            />
            <div className='-mt-28 text-center font-bold text-3xl text-[#212121]'>{score}</div>

         </div> 
 
   );
}
