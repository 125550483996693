import React from 'react';

export default function Component(props) {

const { question, answersList,SurveyAttributes, ansTextBoxChange1, surveyScreenData, clickOK } = props;



function matchAnswer(answersList1,question1){
  let value = "";
  if(answersList1 && answersList1.length>0){
    let getIndex1 = answersList1.findIndex(item => item.questionId === question1.id);
    if(getIndex1!==-1){
      value = answersList1 && answersList1[getIndex1] && answersList1[getIndex1].answer1?answersList1[getIndex1].answer1:"";
    }
  }
  return value
}

return(
    <>
        <div className="mx-4 ">
            <textarea 
            rows={4} cols={50} 
            value={matchAnswer(answersList,question)} 
            onChange={(event)=>ansTextBoxChange1(event,question)} 
            className="w-full bg-gray-100 rounded-md mt-4 p-3 text-sm  placeholder-gray-500 outline-none " 
            style={{
              borderWidth:'3px',
              borderColor:'#757575'
            }} 
            placeholder={SurveyAttributes("WriteHereText")?SurveyAttributes("WriteHereText")+"...":"Write here..."}  />
        </div>
        {/* <div className="md:px-6 px-4 -mt-4 md:-mt-4 lg:-mt-4 xl:-mt-4">

        <button 
        style={{
          background:(surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 :  "#757575",
          color:(surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : "#757575",
        }} 
        onClick={() => clickOK()} className="mt-6 md:rounded-lg rounded-md p-2 px-4 mr-1 text-center text-xl">{SurveyAttributes("okButtonText")?SurveyAttributes("okButtonText"):"OK"}</button>

        </div> */}
    </>
  );}