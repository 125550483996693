import React from "react";
import AOS from "aos";
import { FiArrowUpRight } from "react-icons/fi";

function TestHeader(props) {
  let { handleTakeAssessmentRedirect } = props;

  AOS.init({
    duration: 1000,
    once: true,
    easing: "ease-out",
    delay: 100,
  });


  return (
    <section
      // style={{
      //   backgroundImage: `url('/img/Home/header_bar.webp')`,
      // }}
      className="bg-center bg-no-repeat bg-cover xl:bg-contain bg-gradient-to-r from-violet-800 to-sky-500"
    >
      <div className="flex flex-col items-center justify-center gap-2 p-2 px-4 mx-auto text-center 2xl:w-9/12 xl:w-10/12 xl:px-0 md:flex-row">
        <div className="space-y-2 ">
          <h2 data-aos="fade-right" className="text-lg font-normal text-center text-white md:text-xl">  Already have an Assessment Code?</h2>
        </div>
        <div className="flex items-center justify-center mx-6">
          <button onClick={() => handleTakeAssessmentRedirect(window.scrollTo(0, 0))} data-aos="fade-left"
            className="flex items-center px-4 py-1 text-black uppercase transition-all duration-150 bg-white rounded cursor-pointer hover:bg-gray-100 w-66">
            <span className="">START ASSESSMENT TEST</span>
            <div className="ml-2">
            <FiArrowUpRight className="text-[1.5rem]" />
            </div>
          </button>
        </div>

      </div>
    </section>
  );
}
export default TestHeader;
