

import React, { Component } from 'react';
import axios from 'axios'

import { connect } from 'react-redux';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import SingleChoice from "./ComponentV4/SingleChoice";
import MultipleChoice from "./ComponentV4/MultipleChoice";
import TextBox from "./ComponentV4/TextBox";
import MultiLineText from "./ComponentV4/MultiLineText";
import RatingScale from "./ComponentV4/RatingScale";
import NPS from "./ComponentV4/NPS";
import Email from "./ComponentV4/Email";
import Number from "./ComponentV4/Number";
import Dropdown from "./ComponentV4/Dropdown";
import YesNo from "./ComponentV4/YesNo";
import Date from "./ComponentV4/Date";

import { questionsActions } from '../../../_actions';

import QuestionHeader from '../Component/QuestionHeader';

import LogoHeader from '../Component/LogoHeader';

class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {

      isDisableSubmit: false,

      SurveyCode: this.props.codeValue,

      radioButton: false,
      radioButton2: false,
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces: false,

      surveyLanguageName: 'English',
      surveyLanguageCode: '',
      ShowSubmitButton: false,


      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn: false,
      afterSubmitData: {
        "thankyouText": '',
        "bgColor": '',
        "bgImage": "",
        "textColor": "",
        "logo": ""
      },
      alreadySubmitted: false,
      clicked: false,

      showDropdownQues: false,
      dropdownInput: "",
      scrollPosition: 0,
      ShowNotAttempted: false,
      submitMandatory: ""
    }
  }

  componentDidMount() {
    this.props.dispatch(questionsActions.emptyReducer());

    // const getData = async () => {
    //   const res = await axios.get('https://geolocation-db.com/json/')
    //   this.setState({IpObj:res.data})
    //   return(res.data)
    // }
    // getData();

    this.props.dispatch(questionsActions.getQuestionList({ code: this.state.SurveyCode }, "v4"));

    this.props.dispatch(questionsActions.getLanguageLabel());

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted: true
      }
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  prevQues = () => {
    this.setState({ changeType: "fade1" })
    this.setState({ ShowSubmitButton: false });
    this.setState({ submitMandatory: "" });

    let { questions } = this.props;
    let { questionsList } = questions;


    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {
      if (CurrQuesIndex > 1 && CurrQuesIndex < questionsList.length + 1) {
        CurrQuesIndex = CurrQuesIndex - 1;

        this.setState({ CurrQuesIndex }, () => {
          // this.handleSubmitAppear();
        });
      }
      else {
        this.setState({
          showWelcomeScreen: false,
          showQuestionScreen: false,
          showThankyouScreen: false,
          instructionPage: true
        })
      }
    }

    // console.log("answersList: ", answersList)

  }

  nextQues = () => {
    window.scrollTo(0, 0);

    this.setState({ changeType: "fade", clicked: '' })

    let { questions } = this.props;
    let { questionsList } = questions;

    //---------------------------- basic ---------------------------------------
    let answersList = [];
    let AnswerListFinal = answersList && questions.answersList && questions.answersList.length > 0 ? questions.answersList : [];

    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {

        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList
            });
          }
        }
        else {
          let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }

      });
    }
    //-------------------------------------------------------------------

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {


      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        //----------------latestcode ---------------------------

        let questionId = questionsList && questionsList[CurrQuesIndex - 1] && questionsList[CurrQuesIndex - 1].id ? questionsList[CurrQuesIndex - 1].id : "";
        let flag = 0;

        if (answersList && answersList.length > 0) {
          let getIndex2 = answersList.findIndex(prev => prev.questionId === questionId);
          if (getIndex2 !== -1) {
            let item = answersList[getIndex2];

            if (item.isMandatory) {
              let template = item.template;

              if (template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 11) {
                if (!item.answer) {
                  flag = flag + 1;
                }
              }
              else if (template === 13) {
                let isAllAnswered = 0;
                if (item && item.SubQuestionList && item.SubQuestionList.length > 0) {

                  item.SubQuestionList.forEach((subQues) => {
                    let getIndex2 = AnswerListFinal.findIndex(prev => prev.questionId === subQues.id);

                    if (getIndex2 !== -1) {
                      if (AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length > 1) {
                        isAllAnswered++;
                      }
                    }

                  });
                }


                if (item.SubQuestionList.length !== isAllAnswered) {
                  flag = flag + 1;
                }
              }
              else if (template === 2) {
                if (!(item && item.multiAnswer && item.multiAnswer.length > 0)) {
                  flag = flag + 1;
                }
              }
              else {
                if (!item.answer) {
                  flag = flag + 1;
                }
              }

            }

          }

        }


        if (!flag) {
          CurrQuesIndex = CurrQuesIndex + 1;

          localStorage.setItem('myAnswers', JSON.stringify(AnswerListFinal));

          this.setState({ CurrQuesIndex }, () => {
            this.setState({ ShowNotAttempted: false });

            this.handleSubmitAppear();
          });
        }
        else {
          this.setState({ ShowNotAttempted: true });
        }


      }

    }


  }


  handleSubmitAppear = () => {
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;

    setTimeout(() => {
      if (questionsList && questionsList.length > 0 && (questionsList.length === CurrQuesIndex)) {
        this.setState({ ShowSubmitButton: true });
      }
      else {
        this.setState({ ShowSubmitButton: false });
      }
    }, 300)


  }

  handleRadio = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;


    if (this.state.radioButton === currentOption.id) {

      this.setState({ radioButton: null });
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, "", 0, surveyScreenData));
    }
    else {

      this.setState({ radioButton: currentOption.id })
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData));
    }


    // if(!ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    setTimeout(() => this.nextQues(), 300);

  }






  handleCheckInput = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    if (true) {

      this.setState({ radioButton: currentOption.id })
      // console.log("currentOption::", currentOption)
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption,
        currentOption.weightage, surveyScreenData));
    }

    // setTimeout(()=>this.nextQues(), 300);

  }

  goToInstructionPage = () => {
    let { questions } = this.props;
    let { surveyScreenData } = questions;

    if (surveyScreenData.isInstruction) {
      this.setState({ showWelcomeScreen: false, showQuestionScreen: false, showThankyouScreen: false, instructionPage: true });
    }
    else {
      this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    }
    window.scrollTo(0, 0)

  }

  goToQuestionPage = () => {
    this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    window.scrollTo(0, 0)

  }


  saveAnswers(surveyScreenData) {



    if (surveyScreenData) {
      this.setState({
        afterSubmitData: surveyScreenData
      });
    }


    let survey = {
      code: this.state.SurveyCode,
    }

    //---------------------- new code ------------------------------------------

    let { questions } = this.props;
    let { questionsList } = questions;

    //---------------------------- basic ---------------------------------------
    let answersList = [];
    let AnswerListFinal = answersList && questions.answersList && questions.answersList.length > 0 ? questions.answersList : [];

    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {

        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList
            });
          }
        }
        else {
          let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }

      });
    }
    //-------------------------------------------------------------------

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {

      let flag = 0;

      if (CurrQuesIndex > 0 && CurrQuesIndex <= questionsList.length) {


        //----------------latestcode ---------------------------

        let questionId = questionsList && questionsList[CurrQuesIndex - 1] && questionsList[CurrQuesIndex - 1].id ? questionsList[CurrQuesIndex - 1].id : "";

        if (answersList && answersList.length > 0) {
          let getIndex2 = answersList.findIndex(prev => prev.questionId === questionId);
          if (getIndex2 !== -1) {
            let item = answersList[getIndex2];

            if (item.isMandatory) {
              let template = item.template;

              if (template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 11) {
                if (!item.answer) {
                  flag = flag + 1;
                }
              }
              else if (template === 13) {
                let isAllAnswered = 0;
                if (item && item.SubQuestionList && item.SubQuestionList.length > 0) {

                  item.SubQuestionList.forEach((subQues) => {
                    let getIndex2 = AnswerListFinal.findIndex(prev => prev.questionId === subQues.id);

                    if (getIndex2 !== -1) {
                      if (AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length > 1) {
                        isAllAnswered++;
                      }
                    }

                  });
                }


                if (item.SubQuestionList.length !== isAllAnswered) {
                  flag = flag + 1;
                }
              }
              else if (template === 2) {
                if (!(item && item.multiAnswer && item.multiAnswer.length > 0)) {
                  flag = flag + 1;
                }
              }
              else {
                if (!item.answer) {
                  flag = flag + 1;
                }
              }

            }

          }

        }

        console.log("% ===============> flag1 ==================>", flag)




      }

      if (!flag) {
        console.log("% ===============> flag2 ==================>", flag)

        if (!this.state.isDisableSubmit) {
          this.props.dispatch(questionsActions.saveAnswers(AnswerListFinal, survey, this.props, this.state.IpObj));
        }
        this.setState({ submitMandatory: "" });
      }
      else {
        this.setState({ submitMandatory: "Please attempt mandatory question!" });
      }

    }



    // console.log("% ===============> answersList2 ==================>",answersList)
    // console.log("% ===============> answersList2 ==================>",answersList)



  }

  ansTextBoxClick(ques) {
    this.setState({ currQues: ques });
  }

  ansTextBoxChange1 = (e, Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 1
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));
  }

  ansNPSInput = (val, Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: val,
      ans: 1
    }

    this.setState({ textAnswer: val })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));



    // if(!Ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    setTimeout(() => this.nextQues(), 300);

  }

  ansTextBoxChange2 = (e, Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 2
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));
  }

  clickOK = () => {
    // setTimeout(()=>this.nextQues(), 300);
    this.nextQues()
  }

  clickOKLast = (Ques) => {

    // console.log("clickOKLast; ")

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: "",
      ans: 3
    }

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));



    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {



      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex });

      }
    }


    let answersList2 = []
    let AnswerListFinal = answersList && questions.answersList && questions.answersList.length > 0 ? questions.answersList : [];
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {

        if (ques.templateId === 13) {
          let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
          if (getIndex !== -1) {
            answersList.push({
              ...AnswerListFinal[getIndex],
              SubQuestionList: ques.SubQuestionList
            });
          }
        }
        else {
          let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
          if (getIndex !== -1) {
            answersList.push(AnswerListFinal[getIndex]);
          }
        }

      });
    }






    let flag = 0
    let unattemptList = [];
    answersList2.forEach((item, index) => {
      if (item.isMandatory) {
        let template = item.template;

        if (template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 11) {
          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1)
          }
        }
        else if (template === 13) {
          let isAllAnswered = 0;
          if (item && item.SubQuestionList && item.SubQuestionList.length > 0) {
            item.SubQuestionList.forEach((subQues) => {
              let getIndex2 = AnswerListFinal.findIndex(prev => prev.questionId === subQues.id);
              if (AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length > 1) {
                isAllAnswered++;
              }
            });
            if (item.SubQuestionList.length !== isAllAnswered) {
              flag = flag + 1;
              unattemptList.push(index + 1)
            }
          }
        }
        else if (template === 2) {
          if (!(item && item.multiAnswer && item.multiAnswer.length > 0)) {
            flag = flag + 1;
            unattemptList.push(index + 1)
          }
        }
        else {
          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1)
          }
        }

      }
    })

    console.log("% ===============> answersList ==================>", answersList)

    if (flag) {
      this.setState({ unattemptList: unattemptList });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {


        if (this.state.unattemptList && this.state.unattemptList.length > 0) {
          // console.log("-----working x")
          this.setState({ CurrQuesIndex: unattemptList[0] });
          // console.log("-----working x 1")

        }

      }

    }
    else {
      this.setState({ unattemptList: [] });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        // console.log("All mandatory questions are attempted , now go to last question i.e. submit! ")
        this.setState({ CurrQuesIndex: questionsList.length });
      }
    }


    //nothing




  }


  onMandatoryAttempt = (elem) => {
    this.setState({ CurrQuesIndex: elem });
    this.setState({ mandatoryOn: true });

  }

  gotoSubmit = () => {
    let { questions } = this.props;
    let { questionsList } = questions;

    if (questionsList && questionsList.length > 0) {
      this.setState({ CurrQuesIndex: questionsList.length })
    }
  }




  handleLanguage = (e) => {
    if (e.target.value === 'en') {
      this.setState({ surveyLanguageCode: '', surveyLanguageName: '' });
    }
    else {
      this.setState({ surveyLanguageCode: e.target.value, surveyLanguageName: "" });
    }
  }




  openDropdownQuestion = (check) => {
    this.setState({ showDropdownQues: check })
  }

  handleDropdownSelect = (value) => {
    this.setState({ dropdownInput: value, showDropdownQues: false });
  }



  handleMatrixRadio = (ques, subQues, currentOption, items) => {

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;


    let currIndex2 = answersList.findIndex(element => element.questionId === subQues.id);

    //console.log('------>answersList------>',answersList)

    this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData, subQues));


    // setTimeout(()=>this.nextQues(), 300);

  }



  render() {
    let { questions } = this.props;

    let { questionsList, answersList, surveyScreenData, surveyStatus } = questions;

    let { CurrQuesIndex, afterSubmitData, clicked, showDropdownQues, ShowNotAttempted, submitMandatory } = this.state;

    let { surveyLanguageCode } = this.props;

    const isMobile = window.innerWidth <= 700;

    //console.log('isMobile: ',isMobile)

    //console.log("answersList:---------------------------------> ", answersList)
    //console.log("questionsList:---------------------------------> ", questionsList)
    // console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // console.log("surveyStatus:---------------------------------> ", surveyStatus)


    let bgImage = '';
    let bgColor = '';
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;

    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = []
    let instructionTextDislay = []


    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = '';
    let bgType = '';

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );



    let LabelObj = questions.getLanguageLabelData;
    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);


    //console.log("surveyStatus: ", surveyStatus);

    let LanguageList = questions.langList;
    //console.log("LanguageList: ", LanguageList);

    //console.log("surveyScreenData: ", surveyScreenData);



    function showRadioMatrix(SubQues, option) {

      let getIndex1 = answersList.findIndex(prev => prev.questionId === SubQues.id);
      let getIndex4 = answersList[getIndex1]['multiAnswer'].findIndex(prev => prev.optionHead === option.heading)
      let getIndex3 = answersList[getIndex1]['multiAnswer'].findIndex(prev => prev.optionId === option.id)

      if (getIndex4 !== -1) {
        if (getIndex3 !== -1) {
          return true
        }
        else {
          return false
        }

      }
      else {
        return false
      }

    }





    function FormatMatriXOptionHeading(question, isOption) {
      let paraObjShow = {};
      if (question && question.optionsList && question.optionsList.length > 0) {
        question.optionsList.forEach((item) => {
          if (paraObjShow && paraObjShow[item.heading]) {
            paraObjShow[item.heading] += 1
          }
          else {
            paraObjShow[item.heading] = 1
          }
        });
      }

      let headingList = []
      let tempKey = Object.keys(paraObjShow);
      if (tempKey && tempKey.length > 0) {
        tempKey.forEach((item) => {
          headingList.push({
            name: item,
            span: paraObjShow[item]
          })
        });
      }

      if (isOption) {
        let OptionList = []
        if (headingList && headingList.length > 0) {
          headingList.forEach((heading) => {
            if (question && question.optionsList && question.optionsList.length > 0) {
              question.optionsList.forEach((option) => {
                if (heading.name === option.heading) {
                  OptionList.push(option)
                }
              });
            }
          });
        }
        return OptionList
      }
      else {
        return headingList
      }

    }





    function TranslateQuestion(surveyLanguageCode, question) {
      return (surveyLanguageCode !== '') ?
        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name) ?
          question.transl[surveyLanguageCode].name : question.name
        : question.name
    }



    function TranslationOptionMatrix(question, heading, surveyLanguageCode, type) {
      let optionId = "";
      if (question && question.optionsList && question.optionsList.length > 0) {
        let Index = question.optionsList.findIndex(prev => prev.heading === heading);
        optionId = question.optionsList[Index].id;
      }
      let text = (surveyLanguageCode !== '') ?
        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].headingList && question.transl[surveyLanguageCode].headingList[optionId]) ?
          question.transl[surveyLanguageCode].headingList[optionId] : heading : heading

      let arr = text.split("-")
      if (type === 1) {
        return arr && arr[0] ? arr[0] : text
      }
      if (type === 2) {
        return arr && arr[0] ? arr[0] : text
      }
      else if (type === 3) {
        return arr && arr[1] ? arr[1] : ""
      }
      else {
        return text
      }
    }

    function TranslationStatementMatrix(question, surveyLanguageCode, type) {

      let text = (surveyLanguageCode !== '') ?
        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name) ?
          question.transl[surveyLanguageCode].name : question.name
        : question.name

      let arr = text.split("-")
      if (type === 1) {
        return arr && arr[0] ? arr[0] : text
      }
      if (type === 2) {
        return arr && arr[0] ? arr[0] : text
      }
      else if (type === 3) {
        return arr && arr[1] ? arr[1] : ""
      }
      else {
        return text
      }
    }


    let { getSurveyTemplateByCodeData } = this.props;
    function SurveyAttributes(name) {
      return (surveyLanguageCode !== '') ?
        (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.transl && getSurveyTemplateByCodeData.transl[surveyLanguageCode] && getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]) ?
          getSurveyTemplateByCodeData.transl[surveyLanguageCode][name] : ""
        :
        getSurveyTemplateByCodeData && getSurveyTemplateByCodeData[name] ? getSurveyTemplateByCodeData[name] : ""
    }



    console.log('unattemptList', this.state.unattemptList);
    console.log('CurrQuesIndex', this.state.CurrQuesIndex);

    console.log('answersList:===========>', answersList)


    function MandatoryMsg(Ques) {
      let Text = "";
      let Color = "";
      if (Ques.isMandatory) {
        if (Ques.templateId === 13) {
          Text = SurveyAttributes("allmandatoryText") ? SurveyAttributes("allmandatoryText") + "*" : "All questions are mandatory*";
          Color = "#eb3434";
        }
        else {
          Text = "*";
          Color = "#eb3434";
        }
      }
      return {
        text: Text,
        color: Color
      }
    }




    function showNotCompletedFn(Que) {
      let answersList = [];
      let AnswerListFinal = answersList && questions.answersList && questions.answersList.length > 0 ? questions.answersList : [];
      let check = false;
      if (ShowNotAttempted) {
        let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === Que.id);
        if (getIndex !== -1) {
          check = AnswerListFinal[getIndex].multiAnswer.length !== 2 ? true : false;
        }
      }

      console.log("showNotCompletedFn===>", check)
      return check
    }


    function CompletedCount() {
      let answerCount = 0;

      //---------------------------- basic ---------------------------------------
      let answersList = [];
      let AnswerListFinal = answersList && questions.answersList && questions.answersList.length > 0 ? questions.answersList : [];

      if (questionsList && questionsList.length > 0) {
        questionsList.forEach((ques) => {

          if (ques.templateId === 13) {
            let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
            if (getIndex !== -1) {
              answersList.push({
                ...AnswerListFinal[getIndex],
                SubQuestionList: ques.SubQuestionList
              });
            }
          }
          else {
            let getIndex = AnswerListFinal.findIndex(prev => prev.questionId === ques.id);
            if (getIndex !== -1) {
              answersList.push(AnswerListFinal[getIndex]);
            }
          }

        });
      }
      //-------------------------------------------------------------------

      if (questionsList && questionsList.length > 0) {

        questionsList.forEach((Ques) => {

          if (true) {
            //----------------latestcode ---------------------------
            let questionId = Ques && Ques.id ? Ques.id : "";
            let flag = 0;

            if (answersList && answersList.length > 0) {
              let getIndex2 = answersList.findIndex(prev => prev.questionId === questionId);
              if (getIndex2 !== -1) {
                let item = answersList[getIndex2];


                if (true) {
                  let template = item.template;

                  if (template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 11) {
                    if (item.answer) {
                      answerCount++;
                    }
                  }
                  else if (template === 13) {


                    let isAllAnswered = 0;
                    if (item && item.SubQuestionList && item.SubQuestionList.length > 0) {

                      item.SubQuestionList.forEach((subQues) => {
                        let getIndex2 = AnswerListFinal.findIndex(prev => prev.questionId === subQues.id);

                        if (item.isMandatory) {
                          if (getIndex2 !== -1) {
                            if (AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length > 1) {
                              isAllAnswered++;
                            }
                          }
                        }
                        else {
                          if (getIndex2 !== -1) {
                            if (AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length > 0) {
                              isAllAnswered++;
                            }
                          }
                        }



                      });
                    }


                    if (item.SubQuestionList.length >= isAllAnswered && isAllAnswered > 0) {
                      answerCount++;
                    }

                  }
                  else if (template === 2) {
                    if ((item && item.multiAnswer && item.multiAnswer.length > 0)) {
                      answerCount++;
                    }
                  }
                  else {
                    if (item.answer) {
                      answerCount++;
                    }
                  }

                }



              }

            }



          }

        });

      }

      return answerCount
    }

    let answerCount = CompletedCount();
    console.log("answerCount::::", answerCount)


    let completedMilestone = "0%";
    let completedPercentage = 0;

    if (questionsList && questionsList.length > 0) {
      let num = (answerCount * 100 / questionsList.length);
      completedPercentage = (answerCount * 100 / questionsList.length);
      if (num >= 25 && num < 50) {
        completedMilestone = "25%"
      }
      else if (num >= 50 && num < 75) {
        completedMilestone = "50%"
      }
      else if (num >= 75 && num < 100) {
        completedMilestone = "75%"
      }
      else if (num >= 100) {
        completedMilestone = "100%"
      }
    }





    return (
      <>
        <div dir="rtl" lang="ar" style={{
          backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#fff" : "#fff",
          backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
          minHeight: '800px',
          // backgroundRepeat: 'cover',
          backgroundSize: 'cover',
          overflowX: 'hidden',
          overflowY: 'hidden'
        }}>
          <LogoHeader
            surveyScreenData={surveyScreenData}
            template={"v4"}
          />

          {surveyStatus !== 2 && surveyStatus !== 3 ?
            <>
              {(this.state.showWelcomeScreen && questionsList && questionsList.length > 0) ?
                <>
                  <div dir="ltr" lang="ar" >
                    <section className="lg:w-5/6 md:py-6 py-4 mx-auto lg:px-0 md:px-6 px-2 " style={{ height: '75%' }}>
                      {/* logo */}
                      <div className="flex justify-center -mt-4">
                        {/* <img src="pictures/logo.png" /> */}
                        {(surveyScreenData && surveyScreenData.logo) ?
                          <img alt="newlogo" style={{ height: "70px" }}
                            src={surveyScreenData.logo}
                          />
                          : (afterSubmitData.logo) ?
                            <img alt="newlogo" style={{ height: "70px" }}
                              src={afterSubmitData.logo} />
                            :
                            null
                        }
                      </div>

                      <h1 className="text-center py-2 font-bold text-2xl">
                        {SurveyAttributes("welcomeToText") ? SurveyAttributes("welcomeToText") : "Welcome to the "}
                        {(surveyLanguageCode !== '') ?
                          (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].name) ? surveyScreenData.transl[surveyLanguageCode].name : surveytitle
                          : surveytitle
                        }</h1>

                      {/* pragraph */}
                      <div className="pb-24">
                        <div id="chatbot" className="cursor-default text-left mx-4 overflow-y-auto lg:px-14 lg:px-32" style={{
                          height: "420px",
                          color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black'
                        }}
                          dangerouslySetInnerHTML={{
                            __html:
                              (surveyLanguageCode !== '') ?
                                (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].welcomeText) ? surveyScreenData.transl[surveyLanguageCode].welcomeText : welcomeTextDislay
                                : welcomeTextDislay
                          }} />
                      </div>
                      <div className="fixed bottom-0  right-0 w-full flex  justify-center bg-white bg-opacity-100">
                        <div>
                          {/* btn */}
                          <button onClick={() => this.goToInstructionPage()}
                            className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100"
                            style={{
                              background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                              color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                            }}
                          >{
                              SurveyAttributes("StartButtonText") ? SurveyAttributes("StartButtonText") : "Start Survey"
                            }</button>
                          <p
                            style={{
                              backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                              // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                            }}
                            className="text-sm py-4 pl-6"> © Powered by <b className="font-semibold">happyPlus</b></p>
                        </div>
                      </div>
                      {/*  */}
                    </section>
                  </div>




                </>
                : null}

              {this.state.instructionPage ?
                <>
                  <div dir="ltr" lang="ar">
                    <section className="lg:w-5/6 md:py-6 py-4 mx-auto lg:px-0 md:px-6 px-2 " style={{ height: '75%' }}>
                      {/* logo */}
                      <div className="flex justify-center -mt-4">
                        {/* <img src="pictures/logo.png" /> */}
                        {(surveyScreenData && surveyScreenData.logo) ?
                          <img alt="newlogo" style={{ height: "70px" }}
                            src={surveyScreenData.logo}
                          />
                          : (afterSubmitData.logo) ?
                            <img alt="newlogo" style={{ height: "70px" }}
                              src={afterSubmitData.logo} />
                            :
                            null
                        }
                      </div>

                      <p
                        style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                        className="md:text-4xl text-3xl text-2xl-320 text-[#3D405B] font-light tracking-wider md:py-2 py-2 pt-2  md:mt-2 text-center cursor-default">
                        {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].InstructionLabelText ? LabelObj[surveyLanguageCode].InstructionLabelText : SurveyAttributes("instructionLabel") ? SurveyAttributes("instructionLabel") : "Instructions"}
                      </p>

                      {/* pragraph */}
                      <div className="pb-24">
                        <div id="chatbot" className="cursor-default mx-4 mt-2 overflow-y-auto lg:px-14 lg:pr-32 lg:pl-4" style={{
                          height: "400px",
                          color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black'
                        }}
                          dangerouslySetInnerHTML={{
                            __html:
                              (surveyLanguageCode !== '') ?
                                (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].instructionText) ? surveyScreenData.transl[surveyLanguageCode].instructionText : instructionTextDislay
                                : instructionTextDislay
                          }} />
                      </div>
                      <div className="fixed bottom-0  right-0 w-full flex  justify-center bg-white bg-opacity-100">
                        <div>
                          {/* btn */}
                          <button onClick={() => this.goToQuestionPage()}
                            className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100"
                            style={{
                              background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                              color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                            }}
                          >{
                              SurveyAttributes("ContinueButtonText") ? SurveyAttributes("ContinueButtonText") : "Continue"
                            }</button>
                          <p
                            style={{
                              backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                              // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                            }}
                            className="text-sm py-4 pl-6"> © Powered by <b className="font-semibold">happyPlus</b></p>
                        </div>
                      </div>
                      {/*  */}
                    </section>
                  </div>
                </>
                : null}

              {this.state.showQuestionScreen && !this.state.submitSucces ?
                <div>

                  <section
                    className="xl:w-5/6 lg:w-5/6 md:py-2 py-4 mx-auto lg:px-0 md:px-6 px-2 overflow-y-auto overflow-x-hidden"
                    style={{ height: '90%' }}>

                    <div className="flex justify-center pb-2 absolute left-2 top-0">

                      {/* {(surveyScreenData && surveyScreenData.logo) ? 
                    <img alt="newlogo" style={{height:"70px"}}
                    src={surveyScreenData.logo }
                    />
                    :(afterSubmitData.logo)?
                    <img alt="newlogo" style={{height:"70px"}}
                    src={afterSubmitData.logo }/>
                  :
                  null
                  }   */}

                    </div>

                    <div className="pt-2">
                      <TransitionGroup>

                        {questionsList && questionsList.length > 0 ?
                          questionsList.map((question, questionindex) =>
                            (questionindex + 1 === CurrQuesIndex
                              // CurrQuesIndex
                            ) ?
                              <CSSTransition
                                timeout={350}
                                classNames={(this.state.changeType)}
                                key={question.id}
                              >
                                <>
                                  <div>

                                    {question.templateId !== 14 ?


                                      question.templateId === 10 ?
                                        <>
                                          <p style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                                            className="text-center mb-4 cursor-default quz md:text-2xl md:px-6 px-4 text-2xl text-white font-semibold md:py-6 py-4 md:mt-4  text-right italic">

                                            {(questionindex + 1).toString() + '. '}{(surveyLanguageCode !== '') ?
                                              (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name) ?
                                                question.transl[surveyLanguageCode].name : question.name
                                              : question.name}

                                            {question.isMandatory ?
                                              <div style={{
                                                background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : 'white',
                                                color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',

                                              }} className="subtitle text-gray-500 font-semibold bg-gray-300 shadow-lg">{SurveyAttributes("mandatoryText") ? SurveyAttributes("mandatoryText") : "This question is mandatory"}</div>
                                              : null}
                                          </p>

                                        </>
                                        :
                                        <>





                                          {question.parentId ?
                                            <>

                                              {question && question["QuesMain"] ?
                                                <>
                                                  <QuestionHeader
                                                    surveyLanguageCode={surveyLanguageCode}
                                                    question={question["QuesMain"]}
                                                  />
                                                </>
                                                : null}

                                              <div style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                                                className="text-center mb-2 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-semibold md:py-4 py-2 md:mt-2  text-right">
                                                {(surveyLanguageCode !== '') ?
                                                  (question && question["QuesMain"] && question["QuesMain"].transl && question["QuesMain"].transl[surveyLanguageCode] && question["QuesMain"].transl[surveyLanguageCode].name) ?
                                                    question["QuesMain"].transl[surveyLanguageCode].name : question && ["QuesMain"] && question["QuesMain"].name ? question["QuesMain"].name : ""
                                                  : question && ["QuesMain"] && question["QuesMain"].name ? question["QuesMain"].name : ""}
                                              </div>



                                              <div style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                                                className={question.templateId === 3 || question.templateId === 4 || question.templateId === 6 ? "text-right mb-0 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal  md:mt-2" :
                                                  "text-center mb-2 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal md:mt-2  text-right"}>

                                                {question.isMandatory ?
                                                  <div className="text-red-500 text-sm ">{SurveyAttributes("mandatoryText") ? SurveyAttributes("mandatoryText") : "This question is mandatory*"}</div>
                                                  : null}



                                                {(questionindex + 1).toString() + '. '}{(surveyLanguageCode !== '') ?
                                                  (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name) ?
                                                    question.transl[surveyLanguageCode].name : question.name
                                                  : question.name}
                                                {/* {<span style={{color:MandatoryMsg(question,questionindex + 1,CurrQuesIndex, this.state.unattemptList, this.state.showRedFlag).color}}>
                                    {MandatoryMsg(question,questionindex + 1,CurrQuesIndex,this.state.unattemptList, this.state.showRedFlag).text}
                                  </span>} */}
                                                {/* {question.isMandatory?
                            <div style={{
                              background:(surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1:'white',
                              color:(surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2:'black',

                            }} className="subtitle text-gray-500 font-semibold bg-gray-300 shadow-lg">{SurveyAttributes("mandatoryText")?SurveyAttributes("mandatoryText"):"This question is mandatory"}</div>
                          :null} */}


                                              </div>

                                            </>
                                            :
                                            <>
                                              <div claassName="quz">
                                                <QuestionHeader
                                                  surveyLanguageCode={surveyLanguageCode}
                                                  question={question}
                                                  QuesNo={(questionindex + 1).toString() + '. '}
                                                />
                                              </div>


                                              <div style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                                                className="text-center mb-2 cursor-default quz md:text-2xl md:px-6 px-4 text-lg text-white font-normal  md:mt-4  text-right">

                                                {question.isMandatory ?
                                                  <div className="text-red-500 text-sm ">{SurveyAttributes("mandatoryText") ? SurveyAttributes("mandatoryText") : "This question is mandatory*"}</div>
                                                  : null}


                                                {/*                           

                          {(questionindex+1).toString()+'. '}{(surveyLanguageCode!=='')?
                                    (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name)?
                                        question.transl[surveyLanguageCode].name:question.name
                                  :question.name} */}

                                                <span>{(questionindex + 1).toString() + '. '}</span>
                                                <span className="font-semibold">{TranslationStatementMatrix(question, surveyLanguageCode, 2)}</span>
                                                <span className="font-semibold">{TranslationStatementMatrix(question, surveyLanguageCode, 3) ? "-" + TranslationStatementMatrix(question, surveyLanguageCode, 3) : ""}</span>


                                                {/* {question.isMandatory?
                            <div style={{
                              background:(surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1:'white',
                              color:(surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2:'black',

                            }} className="subtitle text-gray-500 font-bold bg-gray-300 shadow-lg">{SurveyAttributes("mandatoryText")?SurveyAttributes("mandatoryText"):"This question is mandatory"}</div>
                          :null} */}


                                              </div>

                                            </>
                                          }










                                        </>

                                      : null}



                                    {/* Single Choice */}
                                    {question.templateId === 1 ?
                                      <>
                                        <SingleChoice
                                          question={question}
                                          clicked={clicked}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          handleRadio={this.handleRadio}
                                          surveyScreenData={surveyScreenData}
                                          isMobile={isMobile}
                                        />
                                      </>
                                      : null}


                                    {/* Multiple Choice */}
                                    {question.templateId === 2 ?
                                      <>
                                        <MultipleChoice
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          handleCheckInput={this.handleCheckInput}
                                          surveyScreenData={surveyScreenData}
                                          clickOK={this.clickOK}
                                          SurveyAttributes={SurveyAttributes}

                                        />
                                      </>
                                      : null}



                                    {/* Multitext Box */}
                                    {question.templateId === 3 ?
                                      <>
                                        <TextBox
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          SurveyAttributes={SurveyAttributes}
                                          ansTextBoxChange1={this.ansTextBoxChange1}
                                          surveyScreenData={surveyScreenData}
                                          clickOK={this.clickOK}
                                        />
                                      </>
                                      : null}



                                    {/* Multitext Box */}
                                    {question.templateId === 4 ?
                                      <>
                                        <MultiLineText
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          SurveyAttributes={SurveyAttributes}
                                          ansTextBoxChange1={this.ansTextBoxChange1}
                                          surveyScreenData={surveyScreenData}
                                          clickOK={this.clickOK}
                                        />
                                      </>
                                      : null}


                                    {/* Rating Scale */}
                                    {question.templateId === 5 ?
                                      <>
                                        <RatingScale
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          handleRadio={this.handleRadio}
                                          surveyScreenData={surveyScreenData}

                                        />
                                      </>
                                      : null}


                                    {/* NPS Scale */}
                                    {question.templateId === 6 ?
                                      <>
                                        <NPS
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          ansNPSInput={this.ansNPSInput}
                                          surveyScreenData={surveyScreenData}
                                        />
                                      </>
                                      : null}




                                    {/* Email */}
                                    {question.templateId === 7 ?
                                      <>
                                        <Email
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          ansTextBoxChange1={this.ansTextBoxChange1}
                                          surveyScreenData={surveyScreenData}
                                          clickOK={this.clickOK}
                                          SurveyAttributes={SurveyAttributes}

                                        />
                                      </>
                                      : null}




                                    {/* Number */}
                                    {question.templateId === 8 ?
                                      <>
                                        <Number
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          ansTextBoxChange1={this.ansTextBoxChange1}
                                          surveyScreenData={surveyScreenData}
                                          clickOK={this.clickOK}
                                          SurveyAttributes={SurveyAttributes}

                                        />
                                      </>
                                      : null}




                                    {/* Dropdown */}
                                    {question.templateId === 9 ?
                                      <>
                                        <Dropdown
                                          question={question}
                                          clicked={clicked}
                                          answersList={answersList}
                                          handleRadio={this.handleRadio}
                                          handleDropdownSelect={this.handleDropdownSelect}
                                          surveyScreenData={surveyScreenData}
                                        />
                                      </>
                                      : null}





                                    {/* Yes/No */}
                                    {question.templateId === 11 ?
                                      <>
                                        <YesNo
                                          question={question}
                                          clicked={clicked}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          handleRadio={this.handleRadio}
                                          surveyScreenData={surveyScreenData}
                                        />
                                      </>
                                      : null}


                                    {/* Date */}
                                    {question.templateId === 12 ?
                                      <>


                                        <Date
                                          question={question}
                                          answersList={answersList}
                                          surveyLanguageCode={surveyLanguageCode}
                                          ansTextBoxChange1={this.ansTextBoxChange1}
                                          surveyScreenData={surveyScreenData}
                                          clickOK={this.clickOK}
                                          SurveyAttributes={SurveyAttributes}

                                        />
                                      </>
                                      : null}



                                    {/* Matrix  */}
                                    {question.templateId === 13 ?
                                      <>



                                        <div className="px-4 md:px-0 lg:px-0 xl:px-0" style={{ color: MandatoryMsg(question, questionindex + 1, CurrQuesIndex, this.state.unattemptList, this.state.showRedFlag).color }}>
                                          {MandatoryMsg(question, questionindex + 1, CurrQuesIndex, this.state.unattemptList, this.state.showRedFlag).text}
                                        </div>

                                        <div className="w-full mb-4 pt-2">

                                          <div className="overflow-x-auto overflow-y-auto whitespace-nowrap w-full " >


                                            {isMobile ?
                                              <>
                                                {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length > 0 ?
                                                  FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                                    heading.span ?
                                                      <div className="pb-2 mx-4">
                                                        <div
                                                          className="px-2 py-1 w-full mb-1 font-bold"
                                                          style={{
                                                            color: '#fff',
                                                            background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff',
                                                            background: optionindex === 0 ? '#1ec756' : optionindex === 1 ? '#2362d9' : '#fff',
                                                            borderLeft: optionindex == 0 ? '4px solid #1ec756' : null
                                                          }}
                                                        >{TranslationOptionMatrix(question, heading.name, surveyLanguageCode, 2)}
                                                        </div>
                                                        <div
                                                          style={{
                                                            color: optionindex === 0 ? '#1ec756' : optionindex === 1 ? '#2362d9' : '#fff'
                                                          }}
                                                          className="px-2 text-gray-500 font-bold whitespace-pre-line">{TranslationOptionMatrix(question, heading.name, surveyLanguageCode, 3)}</div>
                                                      </div>
                                                      : null
                                                  ) : null}


                                                {/* MOBILE VIEW */}
                                                {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                                                  question.SubQuestionList.map((SubQues, index) =>
                                                    <>
                                                      <div className="pb-4 mx-4" key={index}>
                                                        <div className="whitespace-pre-line py-2"
                                                          style={{ color: showNotCompletedFn(SubQues) ? '#e31919' : ((surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black') }}
                                                        >{(questionindex + 1).toString() + '.' + (index + 1).toString() + ') ' + TranslateQuestion(surveyLanguageCode, SubQues)}</div>
                                                        <table className="text-center border border-gray-300 w-full mx-auto" style={{ background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff' }}>
                                                          <thead >
                                                            <tr>

                                                              {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length > 0 ?
                                                                FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                                                  <td
                                                                    style={{
                                                                      color: '#fff',
                                                                      background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff',
                                                                      background: optionindex === 0 ? '#1ec756' : optionindex === 1 ? '#2362d9' : '#fff',
                                                                      borderLeft: optionindex == 0 ? '4px solid #1ec756' : null
                                                                    }}
                                                                    key={optionindex} colSpan={heading.span} className="px-2 text-sm font-bold text-center border border-gray-300">
                                                                    {TranslationOptionMatrix(question, heading.name, surveyLanguageCode, 1)}
                                                                  </td>
                                                                ) : null}

                                                            </tr>
                                                            <tr>
                                                              {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length > 0 ?
                                                                FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                                  <td
                                                                    style={{
                                                                      color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                                      background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff',
                                                                      borderLeft: optionindex == 1 ? '4px solid #1ec756' : null

                                                                    }}
                                                                    className="text-center font-bold border border-gray-300">{(surveyLanguageCode !== '') ?
                                                                      (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?
                                                                        question.transl[surveyLanguageCode].optionsList[option.id] : option.name

                                                                      : option.name
                                                                    }</td>
                                                                ) : null}
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length > 0 ?
                                                                FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                                  <td
                                                                    style={{
                                                                      color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                                      background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff',
                                                                      borderLeft: optionindex == 1 ? '4px solid #1ec756' : null

                                                                    }}
                                                                    className="text-center  border border-gray-300 p-1">
                                                                    {showRadioMatrix(SubQues, option) ?

                                                                      <>
                                                                        {option.name === "Yes" ?
                                                                          <span style={{ color: '#0ec934' }}
                                                                            onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">task_alt</span>
                                                                          :
                                                                          <span style={{ color: '#e02626' }}
                                                                            onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">highlight_off</span>
                                                                        }
                                                                      </>

                                                                      :
                                                                      <>
                                                                        {/* {ShowNotAttempted?
                                  <span style={{color:'#e32b2b'}} onClick={() => this.handleMatrixRadio(question,SubQues, option)} 
                                  className="material-icons">radio_button_unchecked</span>
                                  :
                                  <span style={{color:(surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2:'black'}} 
                                  onClick={() => this.handleMatrixRadio(question,SubQues, option)} className="material-icons">radio_button_unchecked</span>
                                  } */}

                                                                        <span style={{ color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black' }} onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">radio_button_unchecked</span>
                                                                      </>
                                                                    }
                                                                  </td>
                                                                ) : null}
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </>
                                                  ) : null}

                                              </>




                                              :




                                              <>
                                                {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length > 0 ?
                                                  FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                                    heading.span ?
                                                      <div className="flex">
                                                        <div
                                                          className="px-2 py-1 mb-1 font-bold"
                                                          style={{
                                                            color: '#fff',
                                                            background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff',
                                                            background: optionindex === 0 ? '#1ec756' : optionindex === 1 ? '#2362d9' : '#fff',
                                                            borderLeft: optionindex == 0 ? '4px solid #1ec756' : null
                                                          }}
                                                        >{TranslationOptionMatrix(question, heading.name, surveyLanguageCode, 2)}
                                                        </div>
                                                        <div
                                                          style={{
                                                            color: optionindex === 0 ? '#1ec756' : optionindex === 1 ? '#2362d9' : '#fff'
                                                          }}
                                                          className="px-2 text-gray-500 font-bold pt-1 whitespace-pre-line">{TranslationOptionMatrix(question, heading.name, surveyLanguageCode, 3)}</div>
                                                      </div>
                                                      : null
                                                  ) : null}




                                                {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                                                  <>
                                                    <div className="overflow-y-auto" id="chatbot" style={{ height: "450px" }}>

                                                      <table className="min-w-full relative  " style={{ background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff' }}>
                                                        <thead className="">

                                                          <tr>
                                                            <td
                                                              style={{
                                                                color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                                background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                              }}
                                                              className="border border-gray-300  p-1 sticky top-0" rowSpan={2}></td>
                                                            {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length > 0 ?
                                                              FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                                                <td key={optionindex} colSpan={heading.span}
                                                                  className="sticky top-0 border border-gray-300 text-center p-2 font-bold"
                                                                  style={{
                                                                    color: '#fff',
                                                                    background: optionindex === 0 ? '#1ec756' : optionindex === 1 ? '#2362d9' : '#fff',
                                                                    borderLeft: optionindex == 0 ? '4px solid #1ec756' : null
                                                                  }}>
                                                                  {TranslationOptionMatrix(question, heading.name, surveyLanguageCode, 1)}
                                                                </td>
                                                              ) : null}

                                                          </tr>

                                                          <tr>
                                                            {/* <td className="w-40 text-right py-3 px-4 text-sm border border-gray-300 "> </td> */}

                                                            {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length > 0 ?
                                                              FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                                <td key={optionindex}
                                                                  style={{
                                                                    color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                                    background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff',
                                                                    borderLeft: optionindex == 1 ? '4px solid #1ec756' : null
                                                                  }}
                                                                  className="sticky top-10 text-right py-1 px-4 font-bold text-sm text-center border border-gray-300 ">{
                                                                    (surveyLanguageCode !== '') ?
                                                                      (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?
                                                                        question.transl[surveyLanguageCode].optionsList[option.id] : option.name

                                                                      : option.name
                                                                  }</td>
                                                              ) : null}
                                                          </tr>



                                                        </thead>
                                                        <tbody className="">
                                                          {question.SubQuestionList.map((SubQues, index) =>
                                                            <tr key={index}>
                                                              <td className="w-82 text-right py-1 px-4 text-sm border border-gray-300 whitespace-pre-line"
                                                                style={{ color: showNotCompletedFn(SubQues) ? '#e31919' : ((surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black') }}
                                                              > {(questionindex + 1).toString() + '.' + (index + 1).toString() + ') ' + TranslateQuestion(surveyLanguageCode, SubQues)} </td>
                                                              {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length > 0 ?
                                                                FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                                  <td key={optionindex}
                                                                    style={{
                                                                      borderLeft: optionindex == 1 ? '4px solid #1ec756' : null
                                                                    }}
                                                                    className="text-center py-1 px-4 border border-gray-300">

                                                                    {showRadioMatrix(SubQues, option) ?

                                                                      <>
                                                                        {option.name === "Yes" ?
                                                                          <span style={{ color: '#0ec934' }}
                                                                            onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">task_alt</span>
                                                                          :
                                                                          <span style={{ color: '#e02626' }}
                                                                            onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">highlight_off</span>
                                                                        }
                                                                      </>

                                                                      :
                                                                      <>
                                                                        {/* {ShowNotAttempted?
                            <span style={{color:'#e32b2b'}} onClick={() => this.handleMatrixRadio(question,SubQues, option)} 
                            className="material-icons">radio_button_unchecked</span>
                            :
                            <span style={{color:(surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2:'black'}} 
                            onClick={() => this.handleMatrixRadio(question,SubQues, option)} className="material-icons">radio_button_unchecked</span>
                            }
                             */}
                                                                        <span style={{ color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black' }} onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">radio_button_unchecked</span>
                                                                      </>


                                                                    }

                                                                  </td>
                                                                ) : null}
                                                            </tr>
                                                          )}

                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </>
                                                  : null}

                                              </>

                                            }







                                          </div>



                                        </div>


                                        <div className="md:px-2 px-2 mb-24 ">
                                          {/*   <button 
                  style={{
                    background:(surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 :  "#757575",
                    color:(surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : "#757575",
                  }} 
                  onClick={() => this.clickOK()} className="mt-6 md:rounded-lg rounded-md p-2 px-4 mr-1 text-center text-xl">
                  {SurveyAttributes("okButtonText")?SurveyAttributes("okButtonText"):"OK"}</button> */}
                                        </div>
                                      </>
                                      : null}





                                  </div>







                                  <div className={question.templateId === 13 ? "px-4 -pt-4 justify-center items-center" : "px-4 pt-4 justify-center items-center"}>
                                    {this.state.unattemptList && this.state.showRedFlag ?
                                      this.state.unattemptList && this.state.unattemptList.length > 0 ?
                                        <p className="md:px-6 px-4 md:text-md text-gray-100 font-light">
                                          <span style={{ cursor: 'default', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} className="font-semibold">{'Please attempt mandatory question no. '}</span>
                                          {this.state.unattemptList.map((elem, index) =>
                                            <span key={index} onClick={() => this.onMandatoryAttempt(elem)}
                                              style={{ cursor: 'pointer', fontWeight: 'bold', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>
                                              {(index + 1 === this.state.unattemptList.length) ?
                                                <span style={{ textDecoration: 'underline' }}>{' ' + elem}</span>
                                                :
                                                <span style={{ textDecoration: 'underline' }}>{elem + ' , '}</span>
                                              }
                                            </span>
                                          )}

                                        </p>
                                        : null : null}
                                  </div>

                                  {this.state.ShowSubmitButton ?
                                    <>
                                      <div className="px-4 text-center text-red-500">{submitMandatory}</div>
                                      <div className="pt-3 pb-36 flex justify-center"> <button
                                        style={{
                                          background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                                          color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                                        }}
                                        onClick={() => this.saveAnswers(surveyScreenData)} className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100">
                                        {/* {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].SubmitButton?LabelObj[surveyLanguageCode].SubmitButton:"Submit"} */}
                                        {SurveyAttributes("SubmitButtonText") ? SurveyAttributes("SubmitButtonText") : "Submit"}
                                      </button></div>
                                    </>
                                    : null}





                                </>




                              </CSSTransition>
                              : null) : null}
                      </TransitionGroup>




                      <div className="fixed bottom-0  left-0 w-full" style={{ background: (bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgImage) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1" }}>
                        <div className="lg:px-8 xl:w-5/6 lg:w-5/6 lg:py-4 md:pb-4  pb-2 mx-auto bg-green-768">
                          {questionsList && questionsList.length > 0 ?
                            <div className="md:pb-2 pb-2">
                              <div className="h-2 bg-green-400 text-white px-2"
                                // style={{width:(questionsList && questionsList.length>0?parseInt(answerCount*100/questionsList.length).toString():"0")+"%" ,
                                // background:(surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor:'black'
                                // }}
                                style={{ width: (completedPercentage).toString() + "%" }}

                              >
                                {/* {(questionsList && questionsList.length>0?parseInt(answerCount*100/questionsList.length).toString():"0")+"% "}{"completed"} */}
                              </div>
                              <div className="border-b border-white opacity-50" style={{ borderColor: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} />
                            </div>
                            : null}


                          <div className="flex justify-between items-center lg:px-0  px-4">
                            <p className="md:text-xl text-base text-gray-300" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>
                              {/* <span >{CurrQuesIndex}</span> <span className="px-0.5">/</span>{questionsList && questionsList.length>0?questionsList.length:null} */}

                              {completedMilestone !== "0%" ? completedMilestone + " " + (!isMobile ? (SurveyAttributes("completedText") ? SurveyAttributes("completedText") : "Completed") : "") : ""}
                            </p>

                            <div className="flex">
                              <button
                                style={{
                                  background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                                  color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                                }}
                                onClick={() => this.prevQues()} className="material-icons bk-btn-bg md:rounded-lg rounded-md md:p-3 p-2 ml-1 bk-arrow-color text-center mat-425-16">arrow_forward_ios</button>

                              {questionsList && questionsList.length > 0 ? questionsList.length !== (CurrQuesIndex) ?
                                <button
                                  style={{
                                    background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                                    color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                                  }}
                                  onClick={() => this.nextQues()} className="material-icons bg-white md:rounded-lg rounded-md md:p-3 p-2 mr-1 text-green-1 text-center mat-425-16">arrow_back_ios_new </button>
                                : null : null}


                            </div>
                          </div>



                        </div>
                      </div>



                    </div>
                  </section>



                  <div className="flex justify-center">
                    <div
                      style={{
                        width: "200px",
                        // backgroundColor:(bgType===1 || afterSubmitData.bgType===1)? (bgColor) ? bgColor : (afterSubmitData.bgColor)?afterSubmitData.bgColor: "#757575"    :"#f1f1f1",
                        // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                      }}
                      className="fixed flex bottom-0  " >
                      <div className="xl:mx-0 lg:mx-0 mx-auto md:py-8 py-3">
                        <div className=" flex items-center"> <p className="text-white text-sm font-light pr-2"
                          style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} >© Powered by <b className="font-semibold">HappyPlus</b></p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                : null}




              {this.state.showThankyouScreen ?
                <>
                  <div>
                    <div className="flex justify-center z-10 fixed top-2 w-full">{
                      (surveyScreenData && surveyScreenData.logo) ?
                        <img alt="newlogo" style={{ height: "70px" }}
                          src={surveyScreenData.logo}
                        />
                        : (afterSubmitData && afterSubmitData.logo) ?
                          <img alt="newlogo" style={{ height: "70px" }}
                            src={afterSubmitData.logo} />
                          :
                          null

                    }</div>
                    <section className="lg:w-4/6   mx-auto lg:px-0 md:px-6 px-2 flex items-center h-screen justify-center">
                      <div className="-mt-40">
                        <div className="flex justify-center"><img src="img/notFound.png" className="w-32" /></div>
                        <div className="flex items-center h-full justify-center">
                          <div
                            className="text-center font-bold text-2xl mt-6"
                            style={{ textAlign: 'center', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : (afterSubmitData && afterSubmitData.textColor) ? afterSubmitData.textColor : 'black' }}
                            dangerouslySetInnerHTML={{
                              __html:
                                (surveyLanguageCode !== '') ? (afterSubmitData && afterSubmitData.transl && afterSubmitData.transl[surveyLanguageCode] && afterSubmitData.transl[surveyLanguageCode].thankyouText) ?
                                  "<h1>" + afterSubmitData.transl[surveyLanguageCode].thankyouText + "</h1>" : (afterSubmitData && afterSubmitData.thankyouText ? "<h1>" + afterSubmitData.thankyouText + "</h1>" : "<h1>Thank You for Participating !</h1>") : (afterSubmitData && afterSubmitData.thankyouText ? afterSubmitData.thankyouText : "<h1>Thank You for Participating !</h1>")
                            }} />
                        </div>
                      </div>
                    </section>
                    {/* <div 
              style={{
                backgroundColor:(bgType===1 || afterSubmitData.bgType===1)? (bgColor) ? bgColor : (afterSubmitData.bgColor)?afterSubmitData.bgColor: "#757575"    :"#f1f1f1"
              }}
            className="fixed bottom-6  left-0 w-full flex  justify-center bg-white bg-opacity-100">
              <p 
              style={{color:(surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor:(afterSubmitData && afterSubmitData.textColor)?afterSubmitData.textColor:'black'}}
              className="text-sm py-4"> © Powered by <b className="font-semibold">happyPlus</b></p>
            </div> */}


                    <div
                      style={{
                        height: '10%',
                        backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                        backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
                      }}
                      className="fixed bottom-6  left-0 w-full flex  justify-center bg-white bg-opacity-100">
                      <p className="text-sm py-4" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}> © Powered by <b className="font-semibold">happyPlus</b></p>
                    </div>



                  </div>

                </>
                : null}













            </>
            : null}



          {((surveyStatus === 2 && !(questionsList && questionsList.length > 0)) || this.state.alreadySubmitted) ? //already done




            <div className="">
              <section className="xl:w-3/6 lg:w-4/6  py-6 mx-auto lg:px-0 md:px-6 px-6 ">
                <div className="flex justify-center pb-16 ">
                  {(surveyScreenData && surveyScreenData.logo) ?
                    <img alt="newlogo" style={{ height: "70px" }}
                      src={surveyScreenData.logo}
                    />
                    : (afterSubmitData.logo) ?
                      <img alt="newlogo" style={{ height: "70px" }}
                        src={afterSubmitData.logo} />
                      :
                      null
                  }                            </div>
                <div className="flex items-center h-full justify-center">
                  <div className="text-center text-white">
                    <div className="flex justify-center pb-8"><img alt="pic" src="pictures/surveydone.png" className="md:w-auto w-6/6 " /></div>
                    <h1 className="font-bold text-gray-800 md:text-4xl text-3xl tracking-widest leading_lose leading-10" style={{}}>
                      <div
                        style={{ textAlign: 'center', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                        dangerouslySetInnerHTML={{
                          __html:

                            // LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].SurveyAlreadyDoneText?
                            // '<h1>'+LabelObj[surveyLanguageCode].SurveyAlreadyDoneText+'</h1>':"<h1>Survey Is Already Submitted !</h1>"

                            SurveyAttributes("AlreadyText") ? '<h1>' + SurveyAttributes("AlreadyText") + '</h1>' : "<h1>Survey Is Already Submitted !</h1>"

                        }} />
                    </h1>
                    <p className="text-xl opacity-80 font-light py-2">

                    </p>
                  </div>
                </div>
              </section>


              {/* {LanguageList && LanguageList.length>0?
                      <div className="flex items-center h-full justify-center">
                      <div className="relative inline-flex mb-4 ">
                          <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero"/></svg>
                          <select onChange={(e)=>this.handleLanguage(e)} className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                            <option value={"en"}>English</option>
                            {LanguageList && LanguageList.length>0?
                              LanguageList.map((item,index)=>
                                <option keys={index} value={item.shortName}>{item.name}</option>
                              )
                            :null}
                          </select>
                        </div> 
                      </div>
                      :null} */}



              {/* powered by logo */}
              {/* <div 
                                                                            style={{
                                                                              height: '10%',
                                                                              backgroundColor:(bgType===1 || afterSubmitData.bgType===1)? (bgColor) ? bgColor : (afterSubmitData.bgColor)?afterSubmitData.bgColor: "#757575"    :"#f1f1f1",
                                                                              backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                                                                            }}
                      className="fixed flex lg:bottom-4 bottom-0 lg:right-4 lg:w-auto w-full lg:border-none border-t border-tran bg-green-768" > 
                        <div className="lg:mx-0 mx-auto lg:py-8 md:py-4 py-4 ">
                          <div className="flex items-center"> <p className="text-gray-600 text-sm font-light" style={{color:(surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor:'black'}}>© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
                        </div>
                      </div> */}

              <div
                style={{
                  height: '10%',
                  backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                  backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
                }}
                className="fixed bottom-6  left-0 w-full flex  justify-center bg-white bg-opacity-100">
                <p className="text-sm py-4" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}> © Powered by <b className="font-semibold">happyPlus</b></p>
              </div>

            </div>






            : null}

          {/* survey expired page ----------------------------------------------------------------------------------------------- */}

          {surveyStatus === 3 && !(questionsList && questionsList.length > 0) ? //expired

            <div>
              <section className="lg:w-4/6   mx-auto lg:px-0 md:px-6 px-2 flex items-center py-32 justify-center overflow-y-hidden" >
                {/* logo */}
                <div>
                  <div className="flex justify-center"><img src="img/expiry.png" className="w-32" /></div>
                  <div className="flex items-center h-full justify-center">
                    <div
                      className="text-center font-bold text-2xl mt-6"
                      style={{ textAlign: 'center', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                      dangerouslySetInnerHTML={{
                        __html:

                          // LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].SurveyExpiredText? '<h1>' + LabelObj[surveyLanguageCode].SurveyExpiredText+'</h1>':"<h1>This Survey is <br>Expired!</h1>"

                          SurveyAttributes("ExpiryText") ? '<h1>' + SurveyAttributes("ExpiryText") + '</h1>' : "<h1>This Survey is <br>Expired!</h1>"


                      }} />
                  </div>
                </div>
              </section>
              {/* <div className="fixed bottom-6  left-0 w-full flex  justify-center bg-white bg-opacity-100">
                    <p className="text-sm py-4"> © Powered by <b className="font-semibold">happyPlus</b></p>
                  </div> */}

              <div
                style={{
                  height: '10%',
                  backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                  backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
                }}
                className="fixed bottom-6  left-0 w-full flex  justify-center bg-white bg-opacity-100">
                <p className="text-sm py-4" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}> © Powered by <b className="font-semibold">happyPlus</b></p>
              </div>


            </div>


            : null}




        </div>
      </>
    );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(QuestionPublish);
