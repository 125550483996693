import React from "react";
import moment from "moment";

export default function AssessmentDetails(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, createdAt, EditDetailsFn, EditButtonFn } = props;

  function chunkList(array) {
    const chunkSize = 8;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  return (
    <>
      {chunkList(getAssessmentReportByEmpIDData) &&
        chunkList(getAssessmentReportByEmpIDData).length > 0
        ? chunkList(getAssessmentReportByEmpIDData).map((getList, index1) =>
          <>

            {/* <main className="p-10 px-6 space-y-6 bg-white page-break">
              <div className="px-12 space-y-6 page-break">
                <div className="flex items-center justify-between w-full pt-4 pb-2 border-b">
                  <h1 className="text-lg font-semibold text-[#3D405B]">
                    {EditButtonFn(
                      "TalentAssessmentReport",
                      "Teen TalentPlusStrengths Assessment"
                    )}
                  </h1>
                  <p className="text-lg font-semibold text-[#3D405B]">
                    {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""} | {createdAt}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4 py-6">

                  {getList &&
                    getList.length > 0
                    ? getList.map((element, index1) => (
                      <>

                        <div className="w-full px-2 py-2 space-y-2">
                          <div className="space-y-2">
                            <button
                              style={{
                                background:
                                  element && element.colorCode
                                    ? element.colorCode
                                    : "#DDDDDD",
                              }}
                              className={
                                " px-2 py-1 text-sm flex text-white uppercase tracking-wide rounded-md "
                              }
                            >
                              {EditDetailsFn(element, 'bucket', element.bucket)}
                            </button>
                            <h1 className="text-2xl font-medium text-[#3D405B] ">
                              {EditDetailsFn(element, 'dimension', element.dimension)}
                            </h1>
                          </div>


                          <p className="text-base font-normal">
                            <div>
                              {EditDetailsFn(element, 'desc1', <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element &&
                                      element.details &&
                                      element.details.desc1
                                      ? element.details.desc1
                                      : "",
                                }}
                              />)}
                            </div>
                          </p>
                        </div>


                      </>
                    ))
                    : null}
                </div>

              </div>
            </main> */}

          </>
        ) : null}
    </>
  );
}
