import * as React from 'react';

const PageTemplate = props => {

  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";

  let pageNo = 0
  if (Number1 > 2) {
    pageNo = Number1 - 2
  }
  return (<>
    <div style={{
      position: "absolute",
      bottom: "30px",
      left: '50px'
    }}>

      <p>Copyright © 2023, HappyPlus, Inc. All rights reserved</p>

    </div>

    <div style={{
      position: "absolute",
      bottom: "10px",
      right: '20px'
    }}>
      <div className='flex items-center justify-between py-4' style={{ justifyContent: 'space-between' }}>
        {/* <p className='text-slate-600'>Page {pageNo}</p> */}


        <img src={"/img/logo/happypluslogo.png"} className="object-cover w-24 mx-4 " alt="default_survey_logo" />
      </div>

    </div>


  </>);
};

export default PageTemplate;