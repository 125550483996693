import React from 'react';

export default function Top5Talents(props) {

  let { getTalentResourcesData } = props;

  return (
    <>

      {/* Resources section */}

      <section className="w-full py-4 space-y-6">
        {/* <div>
          <h1 className=" md:text-3xl text-2xl font-semibold  text-[#3D405B]">Resources</h1>
          <h1 className=" md:text-xl text-base text-[#3D405B]  ">Choose an resource according to your choice</h1>
        </div>
        <div className="grid grid-cols-1 gap-6 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
          <div className="overflow-hidden transition-all duration-150 bg-white border shadow-sm rounded-xl hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/qchkvJ8L8rY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6 ">
              <h1 className="text-[#3D405B] font-semibold text-xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B]/70 tracking-wide text-base text-left py-2">Astro Teller, TED Talk</p>
            </div>
          </div>
          <div className="overflow-hidden transition-all duration-150 bg-white border shadow-sm rounded-xl hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/S2qeTfOxDF8?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6">
              <h1 className="text-[#3D405B] font-semibold text-xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B]/70 tracking-wide text-base text-left py-2">Astro Teller, TED Talk</p>
            </div>
          </div>
          <div className="overflow-hidden transition-all duration-150 bg-white border shadow-sm rounded-xl hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/3UHncbbgD-c?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6">
              <h1 className="text-[#3D405B] font-semibold text-xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B]/70 tracking-wide text-base text-left py-2">Astro Teller, TED Talk</p>
            </div>
          </div>
          <div className="overflow-hidden transition-all duration-150 bg-white border shadow-sm rounded-xl hover:shadow-xl">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/S2qeTfOxDF8?list=PL9H6i6Cs6lewRVfuCosTqvoU1237I9y4A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" />
            <div className="px-5 py-6">
              <h1 className="text-[#3D405B] font-semibold text-xl mb-1 capitalize">The Unexpected Benefits of Celebrating Failure</h1>
              <p className="text-[#3D405B]/70 tracking-wide text-base text-left py-2">Astro Teller, TED Talk</p>
            </div>
          </div>
        </div> */}

        {/* <div className="grid grid-cols-1 gap-6 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
          {getTalentResourcesData && getTalentResourcesData.length > 0 ?
            getTalentResourcesData.map((ele) =>
              <div className="overflow-hidden transition-all duration-150 bg-white border shadow-sm rounded-xl hover:shadow-xl">

                <video width="750" height="500" controls poster={ele.imageUrl} >
                  <source src={ele.url} type="video/mp4" />
                </video>

                <div className="px-5 py-6 ">
                  <h1 className="text-[#3D405B] font-semibold text-xl mb-1 capitalize">{ele.name}</h1>
                  <p className="text-[#3D405B]/70 tracking-wide text-base text-left py-2">{ele.desc}</p>
                </div>
              </div>
            ) : null}
        </div> */}



      </section>
    </>
  );
}
