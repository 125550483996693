 

import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';


import SingleChoice from "./ComponentBot/SingleChoice";
import MultipleChoice from "./ComponentBot/MultipleChoice";
import TextBox from "./ComponentBot/TextBox";
import MultiLineText from "./ComponentBot/MultiLineText";
import RatingScale from "./ComponentBot/RatingScale";
import NPS from "./ComponentBot/NPS";
import NPSV2 from "./ComponentBot/NPSV2";
import Email from "./ComponentBot/Email";
import Number from "./ComponentBot/Number";
import Dropdown from "./ComponentBot/Dropdown";
import YesNo from "./ComponentBot/YesNo";
import Date from "./ComponentBot/Date";

import { questionsActions } from '../../../_actions';

import FeedbackModal from './FeedbackModal';



class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {

      isDisableSubmit:false,

      SurveyCode:this.props.codeValue,

      radioButton: false,
      radioButton2: false,
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces:false,

      surveyLanguageName:'English',
      surveyLanguageCode:'',
      ShowSubmitButton:false,


      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn:false,
      afterSubmitData:{
        "thankyouText":'',
        "bgColor":'',
        "bgImage":"",
        "textColor":"",
        "logo":""
      },
      alreadySubmitted:false,
      clicked:false,

      showDropdownQues:false,
      dropdownInput:"",
      scrollPosition:0,
      IpObj:{},
      ShowNotAttempted:false,
      submitMandatory:"",


      quesStoreList:[],

      chatList:[
        { 
          "id":"WelcomeSurvey",
          "text":"Welcome to Employee Wellness Survey!",
          "mode":"bot",
          "command":""
        },
        {
          "id":"StartSurvey",
          "text":"Start Survey",
          "mode":"button",
          "command":"StartSurvey"
        }
      ],
      showFeedbackModal:false
    }
  }

  componentDidMount() {
    // this.props.dispatch(questionsActions.emptyReducer());

    // const getData = async () => {
    //   const res = await axios.get('https://geolocation-db.com/json/')
    //   this.setState({IpObj:res.data})
    //   return(res.data)
    // }
    // getData();

    this.props.dispatch(questionsActions.getQuestionList({ code: this.state.SurveyCode }, "v4"));

    this.props.dispatch(questionsActions.getLanguageLabel());

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,
        alreadySubmitted:true,
      }
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  prevQues = () => {
    this.setState({ changeType: "fade1" })
    this.setState({ShowSubmitButton:false});
    this.setState({submitMandatory:""});

    let { questions } = this.props;
    let { questionsList } = questions;


    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length>0) {
      if (CurrQuesIndex > 1 && CurrQuesIndex < questionsList.length + 1) {
        CurrQuesIndex = CurrQuesIndex - 1;

        this.setState({ CurrQuesIndex },()=>{
          // this.handleSubmitAppear();
        });
      }
      else{
        this.setState({
          showWelcomeScreen: false,
          showQuestionScreen: false,
          showThankyouScreen: false,
          instructionPage: true
        })
      }
    }

    // console.log("answersList: ", answersList)

  }

  nextQues = () => {


    
    // window.scrollTo(0, 0);

    this.setState({ changeType: "fade", clicked:'' })

    let { questions } = this.props;
    let { questionsList } = questions;

    //---------------------------- basic ---------------------------------------
    let answersList= [];
    let AnswerListFinal = answersList && questions.answersList && questions.answersList.length>0?questions.answersList:[];

    if(questionsList && questionsList.length>0){
      questionsList.forEach((ques)=>{

        if(ques.templateId===13){
                let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                if(getIndex!==-1){
                  answersList.push({
                    ...AnswerListFinal[getIndex],
                    SubQuestionList:ques.SubQuestionList
                  });
                }
        }
        else{
                let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                if(getIndex!==-1){
                  answersList.push(AnswerListFinal[getIndex]);
                }
        }

      });
    }
    //-------------------------------------------------------------------

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length>0) {
      

      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        //----------------latestcode ---------------------------
        
        let questionId = questionsList && questionsList[CurrQuesIndex-1] && questionsList[CurrQuesIndex-1].id?questionsList[CurrQuesIndex-1].id:"";
        

        
        
        
        let flag = 0;

        if(answersList && answersList.length>0){
          let getIndex2 = answersList.findIndex(prev=>prev.questionId === questionId);
          if(getIndex2!==-1){
          let item = answersList[getIndex2];

          if (item.isMandatory) {
            let template = item.template;
    
              if(template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 16|| template === 11){
                if (!item.answer) {
                  flag = flag + 1;
                }
              }
              else if(template === 13){
                let isAllAnswered = 0;
                  if(item && item.SubQuestionList && item.SubQuestionList.length>0){

                    item.SubQuestionList.forEach((subQues)=>{
                      let getIndex2 = AnswerListFinal.findIndex(prev=>prev.questionId === subQues.id);

                      if(getIndex2!==-1){
                        if(AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length>1){
                          isAllAnswered ++;
                        }
                      }

                    });
                  }


                  if(item.SubQuestionList.length !== isAllAnswered){
                    flag = flag + 1;
                  }
              } 
              else if(template === 2){
                if (!(item && item.multiAnswer && item.multiAnswer.length>0)) {
                  flag = flag + 1;
                }
              }
              else{
                if (!item.answer) {
                  flag = flag + 1;
                }
              }
    
          }

          }

        }


        if(!flag){
              CurrQuesIndex = CurrQuesIndex + 1;
              
              // localStorage.setItem('myAnswers', JSON.stringify(AnswerListFinal));


              let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(AnswerListFinal), "secretkey1345100237401").toString();
              localStorage.setItem('myAnswers', JSON.stringify(cryptedText));




              this.setState({ CurrQuesIndex },()=>{
                this.setState({ShowNotAttempted:false});

                    let { chatList } = this.state;
                    chatList.push({
                      "mode":"survey",
                      "id":(questionsList && questionsList[CurrQuesIndex-1] && questionsList[CurrQuesIndex-1].id?questionsList[CurrQuesIndex-1].id:"")
                    })
                    this.setState({chatList},()=>{
                      this.scrollToBottom();
                    })

                    
                
                this.handleSubmitAppear();
              });
        }
        else{
          this.setState({ShowNotAttempted:true});

        }

        
      }

    }


    this.scrollToBottom()
  }


  handleSubmitAppear =()=>{
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;

    setTimeout(()=>{
      if(questionsList && questionsList.length > 0 && (questionsList.length === CurrQuesIndex)){
        this.setState({ShowSubmitButton:true});



        let { chatList } = this.state;
        chatList.push({
          "id":"SubmitAnswer",
          "text":"Submit",
          "mode":"button",
          "command":"SubmitAnswer"
        })
        this.setState({chatList});



      }
      else{
        this.setState({ShowSubmitButton:false});
      }
    },300)


  }

  handleRadio = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;


    if (this.state.radioButton === currentOption.id) {

      this.setState({ radioButton: null });
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, "", 0, surveyScreenData,"",GroupQuestionOnlyList));
    }
    else {

      this.setState({ radioButton: currentOption.id })
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData, Index,GroupQuestionOnlyList));
    }


    // if(!ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }




    let { chatList } = this.state;
    let getIndex = chatList && chatList.length>0?chatList.findIndex(prev=>prev.id===ques.id):-1;
    let getIndex2 = chatList && chatList.length>0?chatList.findIndex(prev=>prev.id===ques.id+"_bot"):-1;
    console.log("getIndex--------------->",getIndex);
    console.log("getIndex2--------------->",getIndex2);


    if(getIndex2===-1){


      if(getIndex!==-1){

       
            if(currentOption && currentOption.bot){
              chatList.splice(getIndex+2, 0, {
                "id":ques.id+"_bot",
                "text":currentOption.bot,
                "mode":"bot",
                "command":""
              });
            }

            if(currentOption && currentOption.isFeedback){
              
              this.setState({showFeedbackModal:true});

              chatList.splice(getIndex+2, 0, {
                "id":ques.id+"_feedback",
                "text":"Give Feedback",
                "mode":"feedback",
                "command":""
              });

            }

        
      }
    }
    else{
      if(currentOption && currentOption.bot){

          
          chatList[getIndex2] = {
            "id":ques.id+"_bot",
            "text":currentOption.bot,
            "mode":"bot",
            "command":""
          }

          if(currentOption && currentOption.isFeedback){
              
            this.setState({showFeedbackModal:true});

            chatList[getIndex2] = {
              "id":ques.id+"_feedback",
              "text":"Give Feedback",
              "mode":"feedback",
              "command":""
            }

          }

      
      }
      else{
          
        
        chatList.splice(getIndex+2, 1);

      }
    }
    this.setState({chatList});
    

   
    if(currentOption && currentOption.isFeedback){

    }else{
      setTimeout(()=>this.nextQues(), 300);
    }


  }





  
  handleCheckInput = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;

    if(true) {

      this.setState({ radioButton: currentOption.id })
      // console.log("currentOption::", currentOption)
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, 
        currentOption.weightage, surveyScreenData,"",GroupQuestionOnlyList));
    }

    // setTimeout(()=>this.nextQues(), 300);

  }

  goToInstructionPage = () => {
    let { questions } = this.props;
    let { surveyScreenData } = questions;
    
    if(surveyScreenData.isInstruction){
      this.setState({ showWelcomeScreen: false, showQuestionScreen: false, showThankyouScreen: false, instructionPage: true });
    }
    else{
      this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    }
    window.scrollTo(0, 0)

  }

  goToQuestionPage = () => {
    this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    window.scrollTo(0, 0)

  }


  saveAnswers(surveyScreenData) {



    if(surveyScreenData){
      this.setState({
        afterSubmitData:surveyScreenData
      });
    }


    let survey = {
      code: this.state.SurveyCode,
    }
    
    //---------------------- new code ------------------------------------------

    let { questions } = this.props;
    let { questionsList,surveyStatus,langList,GroupQuestionOnlyList  } = questions;

    //---------------------------- basic ---------------------------------------
    let answersList= [];
    let AnswerListFinal = answersList && questions.answersList && questions.answersList.length>0?questions.answersList:[];

    if(questionsList && questionsList.length>0){
      questionsList.forEach((ques)=>{

        if(ques.templateId===13){
                let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                if(getIndex!==-1){
                  answersList.push({
                    ...AnswerListFinal[getIndex],
                    SubQuestionList:ques.SubQuestionList
                  });
                }
        }
        else{
                let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                if(getIndex!==-1){
                  answersList.push(AnswerListFinal[getIndex]);
                }
        }

      });
    }
    //-------------------------------------------------------------------

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length>0) {

      let flag = 0;
      // console.log(" CurrQuesIndex=====>: ",CurrQuesIndex," questionsList.length=====>: ", questionsList.length)

      if (CurrQuesIndex > 0 && CurrQuesIndex <= questionsList.length) {


        //----------------latestcode ---------------------------
        
        let questionId = questionsList && questionsList[CurrQuesIndex-1] && questionsList[CurrQuesIndex-1].id?questionsList[CurrQuesIndex-1].id:"";

        if(answersList && answersList.length>0){
          let getIndex2 = answersList.findIndex(prev=>prev.questionId === questionId);
          if(getIndex2!==-1){
          let item = answersList[getIndex2];

          if (item.isMandatory) {
            let template = item.template;
    
              if(template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 16|| template === 11){
                if (!item.answer) {
                  flag = flag + 1;
                }
                // console.log(" template: ",template," answer: ",item.answer," flag: ",flag)
              }
              else if(template === 13){
                let isAllAnswered = 0;
                  if(item && item.SubQuestionList && item.SubQuestionList.length>0){

                    item.SubQuestionList.forEach((subQues)=>{
                      let getIndex2 = AnswerListFinal.findIndex(prev=>prev.questionId === subQues.id);

                      if(getIndex2!==-1){
                        if(AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length>1){
                          isAllAnswered ++;
                        }
                      }

                    });
                  }


                  if(item.SubQuestionList.length !== isAllAnswered){
                    flag = flag + 1;
                  }
              } 
              else if(template === 2){
                if (!(item && item.multiAnswer && item.multiAnswer.length>0)) {
                  flag = flag + 1;
                }
              }
              else{
                if (!item.answer) {
                  flag = flag + 1;
                }
              }
    
          }

          }

        }

        // console.log("% ===============> flag1 ==================>",flag)



        
      }

      if (!flag) {

        if(!this.state.isDisableSubmit){

          this.props.dispatch(questionsActions.saveAnswers(AnswerListFinal, survey, this.props, this.state.IpObj, questionsList,surveyScreenData,surveyStatus,langList,GroupQuestionOnlyList ));
        }
        this.setState({submitMandatory:""});

      }
      else{
        this.setState({submitMandatory:"Please attempt mandatory question!"});
      }

      setTimeout(()=>{
        this.scrollToBottom();
      },1000)

    }



    // console.log("% ===============> answersList2 ==================>",answersList)
    // console.log("% ===============> answersList2 ==================>",answersList)



  }

  ansTextBoxClick(ques) {
    this.setState({ currQues: ques });
  }

  ansTextBoxChange1 = (e,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans:1
    }

    
    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData,"",GroupQuestionOnlyList));
  }

  ansNPSInput = (val,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;

    let ansData = {
      _id: "",
      name: val,
      ans:1
    }

    // let { chatList }  = this.state;
    // chatList.push({
    //   "id":"Answer",
    //   "text":val,
    //   "mode":"user",
    //   "command":""
    // });


    this.setState({ textAnswer: val })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData,"",GroupQuestionOnlyList));

    


    
    let { chatList } = this.state;
    let getIndex = chatList && chatList.length>0?chatList.findIndex(prev=>prev.id===Ques.id):-1;
    let getIndex2 = chatList && chatList.length>0?chatList.findIndex(prev=>prev.id===Ques.id+"_bot"):-1;
    console.log("getIndex--------------->",getIndex);
    console.log("getIndex2--------------->",getIndex2);

  let currentOption = {"bot":""}
  if(Ques && Ques.optionsList && Ques.optionsList.length>0){
    let getINDEX = Ques.optionsList.findIndex(prev=>prev.name === val);
    if(getINDEX!==-1){
      currentOption = Ques && Ques.optionsList && Ques.optionsList[getINDEX]?Ques.optionsList[getINDEX]:{"bot":""};
    }
  }


    // let currentOption = Ques && Ques.optionsList && Ques.optionsList[0] && Ques.optionsList[0]["bot"]?Ques.optionsList[0]:{"bot":""};

    console.log("A- text ###--#-##--####--####-@currentOption--------------->",currentOption);

    if(getIndex2===-1){


      if(getIndex!==-1){

       
            if(currentOption && currentOption.bot){
              chatList.splice(getIndex+1, 0, {
                "id":Ques.id+"_bot",
                "text":currentOption.bot,
                "mode":"bot",
                "command":""
              });
            }

        
      }
    }
    else{
      if(currentOption && currentOption.bot){

          
          chatList[getIndex2] = {
            "id":Ques.id+"_bot",
            "text":currentOption.bot,
            "mode":"bot",
            "command":""
          }

      
      }
      else{
          
        
        chatList.splice(getIndex+2, 1);

      }
    }
    this.setState({chatList});






    this.scrollToBottom()



    // if(!Ques.parentId){
    //   setTimeout(()=>this.nextQues(), 300);
    // }
    setTimeout(()=>this.nextQues(), 300);

  }

  ansTextBoxChange2 = (e,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData,GroupQuestionOnlyList } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans:2
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData,"",GroupQuestionOnlyList));
  }

  clickOK = (ques,type, chatIndex)=>{
    // setTimeout(()=>this.nextQues(), 300);

    // if(type==="multitext"){
    //   let { chatList } = this.state;
    //   let { questions } = this.props;
    //   let { answersList, questionsList } = questions;

    //   let answerText = (answersList && answersList.length > 0 && answersList
    //     .findIndex(item => item.questionId === question.id) !== -1) ?
    //     answersList[answersList.findIndex(item => item.questionId === question.id)].answer1 : '';
        

    //   chatList.splice(chatIndex+1, 0, {
    //     "id":"SubmitSuccess",
    //     "text":answerText,
    //     "mode":"user",
    //     "command":"SubmitSuccess"
    //   });

    // }


    let { chatList } = this.state;
    let getIndex = chatList && chatList.length>0?chatList.findIndex(prev=>prev.id===ques.id):-1;
    let getIndex2 = chatList && chatList.length>0?chatList.findIndex(prev=>prev.id===ques.id+"_bot"):-1;
    console.log("getIndex--------------->",getIndex);
    console.log("getIndex2--------------->",getIndex2);

    let currentOption = ques && ques.optionsList && ques.optionsList[0] && ques.optionsList[0]["bot"]?ques.optionsList[0]:{"bot":""};

    console.log("@- text W@@-@@@-@@@-@currentOption--------------->",currentOption);

    if(getIndex2===-1){


      if(getIndex!==-1){

       
            if(currentOption && currentOption.bot){
              chatList.splice(getIndex+1, 0, {
                "id":ques.id+"_bot",
                "text":currentOption.bot,
                "mode":"bot",
                "command":""
              });
            }

        
      }
    }
    else{
      if(currentOption && currentOption.bot){

          
          chatList[getIndex2] = {
            "id":ques.id+"_bot",
            "text":currentOption.bot,
            "mode":"bot",
            "command":""
          }

      
      }
      else{
          
        
        chatList.splice(getIndex+2, 1);

      }
    }
    this.setState({chatList});




    setTimeout(()=>{
      this.scrollToBottom();
    },1000)
    this.nextQues()
  }

  clickOKLast=(Ques)=>{

    // console.log("clickOKLast; ")

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;

    let ansData = {
      _id: "",
      name: "",
      ans:3
    }

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData,"",GroupQuestionOnlyList));



    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length>0) {
      


      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex });

      }
    }


    let answersList2= []
    let AnswerListFinal = answersList && questions.answersList && questions.answersList.length>0?questions.answersList:[];
    if(questionsList && questionsList.length>0){
      questionsList.forEach((ques)=>{

        if(ques.templateId===13){
                let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                if(getIndex!==-1){
                  answersList.push({
                    ...AnswerListFinal[getIndex],
                    SubQuestionList:ques.SubQuestionList
                  });
                }
        }
        else{
                let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                if(getIndex!==-1){
                  answersList.push(AnswerListFinal[getIndex]);
                }
        }

      });
    }




    

    let flag = 0
    let unattemptList = [];
    answersList2.forEach((item, index) => {
      if (item.isMandatory) {
        let template = item.template;

          if(template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 16 || template === 11){
            if (!item.answer) {
              flag = flag + 1;
              unattemptList.push(index + 1)
            }
          }
          else if(template === 13){
            let isAllAnswered = 0;
              if(item && item.SubQuestionList && item.SubQuestionList.length>0){
                item.SubQuestionList.forEach((subQues)=>{
                  let getIndex2 = AnswerListFinal.findIndex(prev=>prev.questionId === subQues.id);
                  if(AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length>1){
                    isAllAnswered ++;
                  }
                });
                if(item.SubQuestionList.length !== isAllAnswered){
                  flag = flag + 1;
                  unattemptList.push(index + 1)
                }
              }
          } 
          else if(template === 2){
            if (!(item && item.multiAnswer && item.multiAnswer.length>0)) {
              flag = flag + 1;
              unattemptList.push(index + 1)
            }
          }
          else{
            if (!item.answer) {
              flag = flag + 1;
              unattemptList.push(index + 1)
            }
          }

      }
    })

    // console.log("% ===============> answersList ==================>",answersList)

    if(flag){
      this.setState({ unattemptList: unattemptList });

      if(this.state.mandatoryOn &&  questionsList && questionsList.length>0){


        if(this.state.unattemptList && this.state.unattemptList.length>0){
          // console.log("-----working x")
          this.setState({ CurrQuesIndex: unattemptList[0] });
          // console.log("-----working x 1")

        }
  
      }

    }
    else{
      this.setState({ unattemptList: [] });

      if(this.state.mandatoryOn &&  questionsList && questionsList.length>0){
          // console.log("All mandatory questions are attempted , now go to last question i.e. submit! ")
          this.setState({ CurrQuesIndex: questionsList.length });
      }
    }


    //nothing




  }


  onMandatoryAttempt=(elem)=>{
    this.setState({ CurrQuesIndex:elem });
    this.setState({ mandatoryOn:true });

  }

  gotoSubmit = ()=>{
    let { questions } = this.props;
    let { questionsList } = questions;

    if(questionsList && questionsList.length>0){
      this.setState({CurrQuesIndex: questionsList.length})
    }
  }



  
  handleLanguage=(e)=>{
    if(e.target.value === 'en'){
      this.setState({surveyLanguageCode:'', surveyLanguageName: ''});
    }
    else{
      this.setState({surveyLanguageCode:e.target.value, surveyLanguageName: ""});
    }
  }



  
  openDropdownQuestion=(check)=>{
    this.setState({showDropdownQues:check})
  }

  handleDropdownSelect=(value)=>{
    this.setState({dropdownInput:value,showDropdownQues:false});
  }



  handleMatrixRadio = (ques, subQues, currentOption, items) => {

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;


    let currIndex2 = answersList.findIndex(element=>element.questionId === subQues.id);

    //console.log('------>answersList------>',answersList)

    this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData,subQues, GroupQuestionOnlyList));


    // setTimeout(()=>this.nextQues(), 300);

  }



  StartSurvey=()=>{
    let { questions } = this.props;
    let { questionsList, answersList, surveyStatus} = questions;


    let QuesId = questionsList && questionsList.length>0?questionsList[0].id:"";

    console.log("questionsList---------->",questionsList)

    let { chatList } = this.state;

    if(surveyStatus !== 2  && surveyStatus !== 3 ){
      chatList.push({
        "mode":"survey",
        "id":QuesId
      })
    }
    else if((surveyStatus === 2 && !(questionsList && questionsList.length>0)) || this.state.alreadySubmitted){
      chatList.push({ 
        "id":"AlreadySurvey",
        "text":"Survey is already done!",
        "mode":"bot",
        "command":""
      })
    }
    else if (surveyStatus === 3 && !(questionsList && questionsList.length>0)){
      chatList.push({ 
        "id":"ExpiredSurvey",
        "text":"Survey is expired!",
        "mode":"bot",
        "command":""
      })
    }

    
    // =[
    //   {
    //     "text":"Welcome to Employee Wellness Survey!",
    //     "mode":"bot",
    //     "type":"text"
    //   }
    // ]

    // {
    //   "text":"Start Survey",
    //   "mode":"bot",
    //   "type":"button",
    //   "command":"StartSurvey"
    // }

    // quesStoreList.push(QuesId)
    this.setState({ chatList});
  }
  

  handleSelectOption=()=>{

  }

  handleChatButton=(item)=>{
    let command = item.command;


    let surveyScreenData = {};
    let { questions } = this.props;
    if(questions.surveyScreenData){
      surveyScreenData = {...questions.surveyScreenData};
    }


    if(command==="StartSurvey"){
      this.StartSurvey()
    }
    else if(command==="SubmitAnswer"){
      this.saveAnswers(surveyScreenData)
    }
    
  }


  scrollToBottom = () => {
    const messagesContainer = ReactDOM.findDOMNode(this.messagesContainer);
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };



  handleFeedbackInput = (e,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData, GroupQuestionOnlyList } = questions;

    let ansData = {
      name: e.target.value
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitFeedbackChange(Ques, answersList, questionsList, ansData, null, surveyScreenData,"",GroupQuestionOnlyList));
  }




  submitFeedback=()=>{
    setTimeout(()=>this.nextQues(), 300);

    // let { CurrQuesIndex } = this.state;

    // let { questions } = this.props;
    // let { questionsList } = questions;


    // if(questionsList && questionsList.length>0){
    //   if(CurrQuesIndex === questionsList.length){
    //     this.setState({showFeedbackModal:false},()=>{
    //       this.handleSubmitAppear();
    //     });
    //   }else{
    //     CurrQuesIndex = CurrQuesIndex + 1;
    //     this.setState({ CurrQuesIndex },()=>{
    //       this.setState({ShowNotAttempted:false, showFeedbackModal:false});
    //       this.handleSubmitAppear();
    //     });
    //   }

    // }


  }


  closeFeedbackModal=()=>{
    this.setState({showFeedbackModal:false});
  }




  render() {
    let { questions } = this.props;
    let { questionsList, answersList, surveyStatus, GroupQuestionOnlyList } = questions;

    let { showFeedbackModal, CurrQuesIndex, afterSubmitData, clicked, showDropdownQues, IpObj, ShowNotAttempted, submitMandatory,
      quesStoreList  } = this.state;

    let { surveyLanguageCode } = this.props;

    const isMobile = window.innerWidth <= 700;

    let { chatList } = this.state;

    console.log("-----chatList----------------------------->",chatList)


    //console.log('isMobile: ',isMobile)

    //console.log("answersList:---------------------------------> ", answersList)
    //console.log("questionsList:---------------------------------> ", questionsList)
    // console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // console.log("surveyStatus:---------------------------------> ", surveyStatus)

    let surveyScreenData = {};
    if(questions.surveyScreenData){
      surveyScreenData = {...questions.surveyScreenData};
      // surveyScreenData["name"] = "UMatter - Employee Engagement Survey";
      // surveyScreenData["logo"] = "img/companylogo/kotaklogo.png";
      // surveyScreenData["logo2"] = "img/companylogo/umatters.png";

      // if(isMobile){
      //   surveyScreenData["logo2"] = "img/companylogo/kotaklogo2.png";
      // }
      // else{
      //   surveyScreenData["logo2"] = "img/companylogo/kotakbanner.png";
      // }

      // surveyScreenData["logo"] = "img/companylogo/umatters.png";

    }


    let bgImage = '';
    let bgColor = '';
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;

    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = []
    let instructionTextDislay = []


    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = '';
    let bgType = '';

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );



    let LabelObj = questions.getLanguageLabelData;
    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);


    //console.log("surveyStatus: ", surveyStatus);

    let LanguageList = questions.langList;
    //console.log("LanguageList: ", LanguageList);

    //console.log("surveyScreenData: ", surveyScreenData);



    function showRadioMatrix (SubQues,option){

      let getIndex1 = answersList.findIndex(prev=>prev.questionId === SubQues.id);
      let getIndex4 = answersList[getIndex1]['multiAnswer'].findIndex(prev=>prev.optionHead === option.heading)
      let getIndex3 = answersList[getIndex1]['multiAnswer'].findIndex(prev=>prev.optionId === option.id)

      if(getIndex4!==-1){
        if(getIndex3!==-1){
          return true
        }
        else{
          return false
        }

      }
      else{
        return false
      }
      
    }





    function FormatMatriXOptionHeading(question, isOption){
        let paraObjShow = {};
        if(question && question.optionsList && question.optionsList.length>0){
          question.optionsList.forEach((item)=>{
            if(paraObjShow && paraObjShow[item.heading]){
              paraObjShow[item.heading] += 1
            }
            else{
              paraObjShow[item.heading] = 1
            }
          });
        }
    
        let headingList = []
        let tempKey = Object.keys(paraObjShow);
        if(tempKey && tempKey.length>0){
          tempKey.forEach((item)=>{
          headingList.push({
            name:item,
            span:paraObjShow[item]
          })
        });
        }

        if(isOption){
          let OptionList = []
          if(headingList && headingList.length>0){
            headingList.forEach((heading)=>{
              if(question && question.optionsList && question.optionsList.length>0){
                question.optionsList.forEach((option)=>{
                  if(heading.name === option.heading){
                    OptionList.push(option)
                  }
                });
              }
            });
          }
          return OptionList
        }
        else{
          return headingList
        }

      }





    function TranslateQuestion(surveyLanguageCode, question){
      return (surveyLanguageCode!=='')?
                              (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name)?
                                  question.transl[surveyLanguageCode].name:question.name
                            :question.name
    }



    function TranslationOptionMatrix(question, heading, surveyLanguageCode, type){
      let optionId = "";
      if(question && question.optionsList && question.optionsList.length>0){
        let Index = question.optionsList.findIndex(prev=>prev.heading === heading);
        optionId = question.optionsList[Index].id;
      }
       let text = (surveyLanguageCode!=='')?
      (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].headingList && question.transl[surveyLanguageCode].headingList[optionId])?
          question.transl[surveyLanguageCode].headingList[optionId]:heading :heading

        let arr = text.split("-")
        if(type === 1){
          return arr && arr[0]?arr[0]:text
        }
        if(type === 2){
          return arr && arr[0]?arr[0]:text
        }
        else if(type === 3){
          return arr && arr[1]?arr[1]:""
        }
        else{
          return text
        }
    }

    function TranslationStatementMatrix(question, surveyLanguageCode, type){

       let text = (surveyLanguageCode!=='')?
       (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name)?
           question.transl[surveyLanguageCode].name:question.name
            :question.name

        let arr = text.split("-")
        if(type === 1){
          return arr && arr[0]?arr[0]:text
        }
        if(type === 2){
          return arr && arr[0]?arr[0]:text
        }
        else if(type === 3){
          return arr && arr[1]?arr[1]:""
        }
        else{
          return text
        }
    }


    let {getSurveyTemplateByCodeData}= this.props;
    function SurveyAttributes(name){
      return (surveyLanguageCode!=='')?
                  (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.transl && getSurveyTemplateByCodeData.transl[surveyLanguageCode] && getSurveyTemplateByCodeData.transl[surveyLanguageCode][name])?
                  getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]:""
                :
                getSurveyTemplateByCodeData && getSurveyTemplateByCodeData[name]?getSurveyTemplateByCodeData[name]:""
    }



    // console.log('unattemptList', this.state.unattemptList);
    // console.log('CurrQuesIndex', this.state.CurrQuesIndex);

    // console.log('answersList:===========>',answersList)


    function MandatoryMsg (Ques ){
      let Text = "";
      let Color = "";
      if(Ques.isMandatory){
        if(Ques.templateId===13){
          Text= SurveyAttributes("allmandatoryText")?SurveyAttributes("allmandatoryText")+"*":"All questions are mandatory*";
          Color="#eb3434";
        }
        else{
          Text="*";
          Color="#eb3434";
        }
      }
      return{
        text:Text,
        color:Color
      }
    }



function showNotCompletedFn(Que){
  let answersList= [];
  let AnswerListFinal = answersList && questions.answersList && questions.answersList.length>0?questions.answersList:[];
  let check = false;
  if(ShowNotAttempted){
    let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === Que.id);
    if(getIndex!==-1){
      check = AnswerListFinal[getIndex].multiAnswer.length!==2?true:false;
    }
  }

  // console.log("showNotCompletedFn===>",check)
  return check
}



function CompletedCount () {
      let answerCount  = 0;

      //---------------------------- basic ---------------------------------------
      let answersList= [];
      let AnswerListFinal = answersList && questions.answersList && questions.answersList.length>0?questions.answersList:[];
  
      if(questionsList && questionsList.length>0){
        questionsList.forEach((ques)=>{
  
          if(ques.templateId===13){
                  let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                  if(getIndex!==-1){
                    answersList.push({
                      ...AnswerListFinal[getIndex],
                      SubQuestionList:ques.SubQuestionList
                    });
                  }
          }
          else{
                  let getIndex = AnswerListFinal.findIndex(prev=>prev.questionId === ques.id);
                  if(getIndex!==-1){
                    answersList.push(AnswerListFinal[getIndex]);
                  }
          }
  
        });
      }
      //-------------------------------------------------------------------
  
      if (questionsList && questionsList.length>0) {
        
        questionsList.forEach((Ques)=>{

        if (true) {
          //----------------latestcode ---------------------------
          let questionId = Ques && Ques.id? Ques.id:"";
          let flag = 0;
          
          if(answersList && answersList.length>0){
            let getIndex2 = answersList.findIndex(prev=>prev.questionId === questionId);
            if(getIndex2!==-1){
            let item = answersList[getIndex2];
  

            if(true) {
              let template = item.template;
      
                if(template === 1 || template === 3 || template === 4 || template === 5 || template === 6 || template === 16|| template === 11){
                  if (item.answer) {
                    answerCount++;
                  }
                }
                else if(template === 13){

       
                  let isAllAnswered = 0;
                    if(item && item.SubQuestionList && item.SubQuestionList.length>0){
  
                      item.SubQuestionList.forEach((subQues)=>{
                        let getIndex2 = AnswerListFinal.findIndex(prev=>prev.questionId === subQues.id);
  
                        if(item.isMandatory){
                            if(getIndex2!==-1){
                              if(AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length>1){
                                isAllAnswered ++;
                              }
                            }
                        }
                        else{
                            if(getIndex2!==-1){
                              if(AnswerListFinal && AnswerListFinal[getIndex2] && AnswerListFinal[getIndex2].multiAnswer && AnswerListFinal[getIndex2].multiAnswer.length>0){
                                isAllAnswered ++;
                              }
                            }
                        }


  
                      });
                    }
  
  
                    if(item.SubQuestionList.length>=isAllAnswered && isAllAnswered>0 ){
                      answerCount++;
                    }

                } 
                else if(template === 2){
                  if ((item && item.multiAnswer && item.multiAnswer.length>0)) {
                    answerCount++;
                  }
                }
                else{
                  if (item.answer) {
                    answerCount++;
                  }
                }
      
            }
  
  
  
            }
  
          }
          
    
          
        }

        });
  
      }

      return answerCount
}

let answerCount = CompletedCount();
// console.log("answerCount::::",answerCount)


let completedMilestone = "0%";
let completedPercentage = 0;

if(questionsList && questionsList.length>0){
  let num = (answerCount*100/questionsList.length);
  completedPercentage = (answerCount*100/questionsList.length);
  if(num>=25 && num<50){
    completedMilestone = "25%"
  }
  else if(num>=50 && num<75){
    completedMilestone = "50%"
  }
  else if(num>=75 && num<100){
    completedMilestone = "75%"
  }
  else if(num>=100){
    completedMilestone = "100%"
  }
}



    // let groupQuestionList = []
    // if(questionsList && questionsList.length>0){
    //   questionsList.forEach((item)=>{
    //     if(!(item && item.parentId)){
    //       groupQuestionList.push(item);
    //     }
    //   });
    // }



    function getGroupIndex(QuesId){
      //console.log("QuesId---------->",QuesId)

      if(GroupQuestionOnlyList && GroupQuestionOnlyList.length>0){
        let getIndex = GroupQuestionOnlyList.findIndex(prev=>prev.id===QuesId);
        if(getIndex!==-1){
          //console.log("match---------->",getIndex+1)

          return (getIndex+1).toString()+". "
        }
        else{
          return ""
        }
      }
      else{
        return ""
      }
    }

    let isMobile1 = true;
    let PhoneNo = "";
    let loading = true;
    
    //console.log("chatList---------->",chatList)


    function getQuestionFn(QuesID,key){
      let getIndex = questionsList && questionsList.length>0?questionsList.findIndex(prev=>prev.id === QuesID):-1;
      if(getIndex!==-1){
        if(key === 'data'){
          return (questionsList && questionsList[getIndex]?questionsList[getIndex]:"")
        }
        else{
          return (questionsList && questionsList[getIndex] && questionsList[getIndex][key]?questionsList[getIndex][key]:"")
        }
      }
      else {
        return null
      }
 
    }










    function getAttemptedAnswers(question1){
      //console.log("question1--------------------->",question1);
      let answerText = "";

      if(question1){
        if(question1 && question1.templateId===4){
          answerText = (answersList && answersList.length > 0 && answersList
            .findIndex(item => item.questionId === question1.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question1.id)].answer1
            : '';
        }
        else{
          answerText = (answersList && answersList.length > 0 && answersList
            .findIndex(item => item.questionId === question1.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question1.id)].answer
            : '';
        }
      }


      return  answerText
    }


    //console.log("questionsList--------------------->",questionsList);
    console.log("answersList--------------------->",answersList);
    //console.log("GroupQuestionOnlyList--------------------->",GroupQuestionOnlyList);


    return (
      <>
<div className="md:w-96 w-auto bg-gradient-to-b from-sky-400 to-blue-800 fixed  md:right-6  md:m-0 m-4 shadow-blue-400 shadow-xl z-30  rounded-lg overflow-hidden" style={{height: 'calc(100% - 14%)', top: '5%'}}>
  {/*  */}
  {/* heaader */}
  <header className="h-16 flex  items-center  px-4 text-white space-x-4 ">
    <span className="material-icons bg-white bg-opacity-0 hover:bg-opacity-20 rounded-full p-2 -ml-2 transition duration-150 ">close</span>
    <h1 className="text-lg capitalize">Survey Bot</h1>
  </header>
  {/* main */}
  {/* message section */}
  <div ref={(el) => { this.messagesContainer = el; }}  className="px-4 pt-6 overflow-hidden overflow-y-auto relative bg-white" id="chatbot" style={{height: 'calc(100% - 8rem)'}}>
    
    

  {surveyStatus !== 2  && surveyStatus !== 3 ?
   <>
  {chatList && chatList.length>0?
            chatList.map((item, chatIndex)=>
             <>
    
    {/* chatbot messege */}





    

    {item.mode === 'bot'?
      <>
      {item.id === "WelcomeSurvey"?
      <>
      <div className="flex py-1 block float-left w-5/6">
        <div dangerouslySetInnerHTML={{ __html: 
          (surveyLanguageCode!=='')?
          (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].welcomeText)?surveyScreenData.transl[surveyLanguageCode].welcomeText:welcomeTextDislay
          :welcomeTextDislay
        }}
        className="bg-gray-100 text-gray-700  p-3 text-sm rounded-r-xl rounded-tl-xl "/>
      </div>
      </>
      :
      <>
      <div className="flex py-1 block float-left w-5/6">
        <p className="bg-gray-100 text-gray-700  p-3 text-sm rounded-r-xl rounded-tl-xl ">{item.text}
        </p>
      </div>
      </>
      }
      </>
    :null}



      {item.mode === 'feedback'?
      <>
        <div className="flex py-1 block float-left w-5/6 mt-2">
          <div className="border w-full rounded-md overflow-hidden">

          {showFeedbackModal?
            <FeedbackModal
              closeModal={this.closeFeedbackModal}
              question={getQuestionFn(item.id.replace("_feedback",""),"data")}
              answersList={answersList}
              handleFeedbackInput={this.handleFeedbackInput}
              submitFeedback={this.submitFeedback}
              surveyScreenData={surveyScreenData}
              afterSubmitData={afterSubmitData}
            />
          :null}

          </div>
        </div>
      </>
    :null}




    {item.mode === 'button'?
      <>
      <div className="flex justify-center w-full py-4 ">
        <button onClick={()=>this.handleChatButton(item)}  
        className="py-2.5 px-6 rounded-full bg-gradient-to-b from-sky-400 to-blue-600 text-white font-normal cursor-pointer text-sm capitalize ">{item.text}</button>
      </div>
      </>
    :null}


    {item.mode === 'user'?
    <>
    <div className="flex justify-end py-4 float-right w-5/6">
      <div className>
        <p className="bg-blue-500 text-white py-2 px-4 text-sm  rounded-l-xl rounded-tr-xl ">{item.text}</p>
        <div>
        </div>
      </div>
    </div>
    </>
    :null}

    
    {item.mode === 'survey'?
      <>
      <div className="flex py-1 block float-left w-5/6 mt-2">
        <div className="border w-full rounded-md overflow-hidden">
          <p className="bg-gray-100 text-gray-700 px-4 py-2 text-sm ">{getQuestionFn(item.id,"name")} 
          </p>
          

              





                {/* Single Choice */}
                {getQuestionFn(item.id,"templateId") === 1?
                  <>
                    <SingleChoice 
                      question={getQuestionFn(item.id,"data")}
                      answersList={answersList}
                      surveyLanguageCode={surveyLanguageCode}
                      handleRadio={this.handleRadio}
                      surveyScreenData={surveyScreenData}
                      isMobile={isMobile}
                    />
                  </>
                :null}



                 {/* Multiple Choice */}
                  {getQuestionFn(item.id,"templateId") === 2?
                    <>
                      <MultipleChoice 
                        question={getQuestionFn(item.id,"data")}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        handleCheckInput={this.handleCheckInput}
                        surveyScreenData={surveyScreenData}
                        clickOK={this.clickOK}
                        SurveyAttributes={SurveyAttributes}

                      />
                    </>
                  :null}



                    {/* Multitext Box */}
                    {getQuestionFn(item.id,"templateId") === 3?
                    <>
                      <TextBox
                        question={getQuestionFn(item.id,"data")}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        SurveyAttributes={SurveyAttributes}
                        ansTextBoxChange1={this.ansTextBoxChange1}
                        surveyScreenData={surveyScreenData}
                        clickOK={this.clickOK}
                      />
                    </>
                    :null}



                    {/* Multitext Box */}
                    {getQuestionFn(item.id,"templateId") === 4?
                    <>
                      <MultiLineText
                        question={getQuestionFn(item.id,"data")}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        SurveyAttributes={SurveyAttributes}
                        ansTextBoxChange1={this.ansTextBoxChange1}
                        surveyScreenData={surveyScreenData}
                        clickOK={this.clickOK}
                        chatIndex={chatIndex}
                      />
                    </>
                    :null}


                    {/* Rating Scale */}
                    {getQuestionFn(item.id,"templateId") === 5?
                    <>
                    <RatingScale
                        question={getQuestionFn(item.id,"data")}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        handleRadio={this.handleRadio}
                        surveyScreenData={surveyScreenData}
                        
                    /> 
                    </>
                    :null}


                    {/* NPS Scale 1-10*/}
                    {getQuestionFn(item.id,"templateId") === 6?
                    <>
                    <NPS
                        question={getQuestionFn(item.id,"data")}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        ansNPSInput={this.ansNPSInput}
                        surveyScreenData={surveyScreenData}
                    /> 
                    </>
                    :null}


                    {/* NPS Scale 0-10*/}
                    {getQuestionFn(item.id,"templateId") === 16?
                    <>
                    <NPSV2
                        question={getQuestionFn(item.id,"data")}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        ansNPSInput={this.ansNPSInput}
                        surveyScreenData={surveyScreenData}
                    /> 
                    </>
                    :null}


                    {/* Email */}
                    {getQuestionFn(item.id,"templateId") === 7?
                    <>
                      <Email
                          question={getQuestionFn(item.id,"data")}
                          answersList={answersList}
                          surveyLanguageCode={surveyLanguageCode}
                          ansTextBoxChange1={this.ansTextBoxChange1}
                          surveyScreenData={surveyScreenData}
                          clickOK={this.clickOK}
                          SurveyAttributes={SurveyAttributes}

                      />
                    </>
                    :null}




                    {/* Number */}
                    {getQuestionFn(item.id,"templateId") === 8?
                    <>
                      <Number
                          question={getQuestionFn(item.id,"data")}
                          answersList={answersList}
                          surveyLanguageCode={surveyLanguageCode}
                          ansTextBoxChange1={this.ansTextBoxChange1}
                          surveyScreenData={surveyScreenData}
                          clickOK={this.clickOK}
                          SurveyAttributes={SurveyAttributes}

                      />
                    </>
                    :null}




                    {/* Dropdown */}
                    {getQuestionFn(item.id,"templateId") === 9?
                          <>
                            <Dropdown
                              question={getQuestionFn(item.id,"data")}
                              clicked={clicked}
                              answersList={answersList}
                              handleRadio={this.handleRadio}
                              handleDropdownSelect={this.handleDropdownSelect}
                              surveyScreenData={surveyScreenData}
                            />
                          </>
                      :null}





                    {/* Yes/No */}
                    {getQuestionFn(item.id,"templateId") === 11?
                    <>
                      <YesNo 
                        question={getQuestionFn(item.id,"data")}
                        clicked={clicked}
                        answersList={answersList}
                        surveyLanguageCode={surveyLanguageCode}
                        handleRadio={this.handleRadio}
                        surveyScreenData={surveyScreenData}
                      />
                    </>
                  :null}


                    {/* Date */}
                    {getQuestionFn(item.id,"templateId") === 12?
                    <>

                    
                      <Date
                          question={getQuestionFn(item.id,"data")}
                          answersList={answersList}
                          surveyLanguageCode={surveyLanguageCode}
                          ansTextBoxChange1={this.ansTextBoxChange1}
                          surveyScreenData={surveyScreenData}
                          clickOK={this.clickOK}
                          SurveyAttributes={SurveyAttributes}

                      />
                    </>
                    :null}









        </div>



      </div>

      {getAttemptedAnswers(getQuestionFn(item.id,"data"))?
        <>
          <div className="flex justify-end py-4 float-right w-5/6">
            <div className>
              <p className="bg-blue-500 text-white py-2 px-4 text-sm  rounded-l-xl rounded-tr-xl ">{getAttemptedAnswers(getQuestionFn(item.id,"data"))}</p>
              <div>
              </div>
            </div>
          </div>
        </>
      :null}


      </>
    :null}



    </>
    ):null}


    
  {this.state.showThankyouScreen?
  <>
  <div className="flex py-1 block float-left w-5/6">
    <div dangerouslySetInnerHTML={{ __html: 
                    (surveyLanguageCode!=='')?(afterSubmitData && afterSubmitData.transl && afterSubmitData.transl[surveyLanguageCode] && afterSubmitData.transl[surveyLanguageCode].thankyouText)?
                    "<h1>"+afterSubmitData.transl[surveyLanguageCode].thankyouText+"</h1>":(afterSubmitData && afterSubmitData.thankyouText?"<h1>"+afterSubmitData.thankyouText+"</h1>":"<h1>Thank You for Participating !</h1>"):(afterSubmitData && afterSubmitData.thankyouText?afterSubmitData.thankyouText:"<h1>Thank You for Participating !</h1>")
                }}
    className="bg-gray-100 text-gray-700  p-3 text-sm rounded-r-xl rounded-tl-xl "/>
  </div>
  </>:null}
  </>
  :null}


  {((surveyStatus === 2 && !(questionsList && questionsList.length>0)) || this.state.alreadySubmitted)? //already done
  <>
        <div className="flex py-1 block float-left w-5/6">
        <div dangerouslySetInnerHTML={{ __html: 
          SurveyAttributes("AlreadyText")?'<h1>'+SurveyAttributes("AlreadyText")+'</h1>':"<h1>Survey Is Already Submitted !</h1>"
        }}
        className="bg-gray-100 text-gray-700  p-3 text-sm rounded-r-xl rounded-tl-xl "/>
      </div>
  </>
  :null}


  {surveyStatus === 3 && !(questionsList && questionsList.length>0)? //expired
  <>
        <div className="flex py-1 block float-left w-5/6">
        <div dangerouslySetInnerHTML={{ __html: 
          SurveyAttributes("ExpiryText")?'<h1>'+SurveyAttributes("ExpiryText")+'</h1>':"<h1>This Survey is <br>Expired!</h1>"
        }}
        className="bg-gray-100 text-gray-700  p-3 text-sm rounded-r-xl rounded-tl-xl "/>
      </div>
  </>
  :null}


  </div>
  {/*  */}
  {/*  */}
  <div className="flex items-center px-4 absolute bottom-0 w-full h-16 space-x-2 bg-white">
    <div className="h-10 border flex items-center rounded-full w-full bg-white ">
      <input type="text" name className="w-full border-none text-sm bg-transparent focus:outline-none ml-4" placeholder="Reply or say help...." />
    </div>
    {/* send icon */}
    <i className="material-icons text-white p-2 cursor-pointer rounded-full  bg-blue-500   hover:bg-opacity-90 transition duration-150 ">send</i>
  </div>
</div>

      </>
    );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(QuestionPublish);
