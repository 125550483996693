

import React, { Component } from 'react';

import { connect } from 'react-redux';



import { questionsActions } from '../../_actions';

import LanguageComponent from './Component/LanguageComponent';

import QuestionV1 from './QuestionV1/questionsV1';
import QuestionV1Arabic from './QuestionV1/questionsV1Arabic';

import QuestionV2 from './QuestionV2/questionsV2';
import QuestionV2Arabic from './QuestionV2/questionsV2Arabic';

import QuestionV3 from './QuestionV3/questionsV3';
import QuestionV3Arabic from './QuestionV3/questionsV3Arabic';

import QuestionV4 from './QuestionV4/questionsV4';
import QuestionV4Arabic from './QuestionV4/questionsV4Arabic';

import QuestionV5 from './QuestionV5/questionsV5';
import QuestionV5Arabic from './QuestionV5/questionsV5Arabic';

import QuestionV6 from './QuestionV6/questionsV6';

import QuestionBot from './QuestionBot/questionsBot';



class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces: false,

      surveyLanguageName: 'English',
      surveyLanguageCode: '',
      ShowSubmitButton: false,


      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn: false,
      afterSubmitData: {
        "thankyouText": '',
        "bgColor": '',
        "bgImage": "",
        "textColor": "",
        "logo": ""
      },
      alreadySubmitted: false,
      clicked: false,

      showDropdownQues: false,
      dropdownInput: "",
      surveyLanguageRTL: false
    }
  }

  componentDidMount() {


    this.props.dispatch(questionsActions.getSurveyTemplateByCode({ code: this.props.match.params.id }));

    this.props.dispatch(questionsActions.getRespondantName({ code: this.props.match.params.id }));


  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted: true
      }
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }




  handleLanguage = (item) => {

    if (item.shortName === 'en') {
      this.setState({ surveyLanguageCode: item.shortName, surveyLanguageName: 'English', surveyLanguageRTL: false });
    }
    else {
      this.setState({ surveyLanguageCode: item.shortName, surveyLanguageName: item.name, surveyLanguageRTL: item.isRTL });
    }
  }



  render() {
    let { questions } = this.props;

    let { getSurveyTemplateByCodeData, surveyScreenData, getRespondantNameData } = questions;

    let { surveyLanguageRTL, surveyLanguageName, surveyLanguageCode } = this.state;

    //console.log("getSurveyTemplateByCodeData:---------------------------------> ", getSurveyTemplateByCodeData)
    // console.log("questionsList:---------------------------------> ", questionsList)
    // console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // console.log("surveyStatus:---------------------------------> ", surveyStatus)

    let UserRespondantName = getRespondantNameData && getRespondantNameData.name ? getRespondantNameData.name : "Respondant";



    let bgImage = '';
    let bgColor = '';
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;

    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = []
    let instructionTextDislay = []


    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = '';
    let bgType = '';

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );



    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);


    //console.log("surveyStatus: ", surveyStatus);

    //console.log("LanguageList: ", LanguageList);

    //console.log("surveyScreenData: ", surveyScreenData);


    let designTemplate = '';
    let langList = [{
      "name": "English",
      "shortName": "en"
    }];
    if (getSurveyTemplateByCodeData) {
      designTemplate = getSurveyTemplateByCodeData.designTemplate;
      if (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.langList && getSurveyTemplateByCodeData.langList.length > 0) {
        getSurveyTemplateByCodeData.langList.forEach((item) => {
          langList.push(item);
        });
      }
    }
    else {
      // designTemplate = 'v4'
    }


    //console.log('langList:',langList)

    let codeValue = this.props.match.params.id;


    //console.log("codeValue:------------> ",codeValue)
    //console.log("langList:------------> ",langList)

    switch (designTemplate) {

      case 'v1': return (
        <>
          <div
            style={{
              backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#fff" : "#fff",
              backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : null : null,
            }}
          >

            <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} />

            {surveyLanguageRTL ?
              <QuestionV1Arabic codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
              :
              <QuestionV1 codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
            }



          </div>

        </>
      );
      case 'v2': return (
        <>

          <div
            style={{
              backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#fff" : "#fff",
              backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : null : null,
            }}
          >


            {langList && langList.length > 0 ?
              langList.length > 1 ?
                <>
                  <div className="mt-16 quz ">
                    <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} template={'v2'} />
                    {/* <div 
            style={{
              background:(getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.OptionColor1) ? getSurveyTemplateByCodeData.OptionColor1:'white',
              color:(getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.OptionColor2) ? getSurveyTemplateByCodeData.OptionColor2:'black',

            }}
            className="py-2 font-semibold text-center text-gray-500 bg-gray-100 shadow-lg subtitle2">
            {"Choose different language"}</div> */}
                  </div>

                </>
                : <div className="py-2" /> : <div className="py-2" />}



            {surveyLanguageRTL ?
              <QuestionV2Arabic codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
              :
              <QuestionV2 codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
            }

          </div>
        </>
      );
      case 'v3': return (
        <>
          <div
            style={{
              backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#fff" : "#fff",
              backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : null : null,
            }}
          >



            {langList && langList.length > 0 ?
              langList.length > 1 ?
                <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} />
                : <div className="py-2" /> : <div className="py-2" />}



            {surveyLanguageRTL ?
              <QuestionV3Arabic codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
              :
              <QuestionV3 codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
            }

          </div>
        </>
      );


      case 'v4': return (
        <>
          <div
            style={{
              backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#fff" : "#fff",
              backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : null : null,
            }}
          >

            {langList && langList.length > 0 ?
              langList.length > 1 ?

                <div className="mt-16">
                  <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} template={'v4'} />
                </div>
                :
                <div className="mt-20" />
              : <div className="mt-20" />}


            {surveyLanguageRTL ?
              <QuestionV4Arabic codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
              :
              <QuestionV4 codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />
            }

          </div>
        </>
      );
      case 'v5': return (
        <>
          <div
            style={{
              backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#fff" : "#fff",
              backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : null : null,
            }}
          >

            {/* <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName}/> */}

            {surveyLanguageRTL ?
              <QuestionV5Arabic codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData}
                langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} UserRespondantName={UserRespondantName}
              />
              :
              <QuestionV5 codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData}
                langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} UserRespondantName={UserRespondantName}
              />
            }



          </div>

        </>
      );
      case 'v6': return (
        <>
          <div
            style={{
              backgroundColor: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 1) ? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor : "#fff" : "#fff",
              backgroundImage: (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType === 2) ? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage : null : null,
            }}
          >

            {langList && langList.length > 0 ?
              langList.length > 1 ?

                <div className="mt-16">
                  <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} template={'v4'} />
                </div>
                :
                <div className="mt-20" />
              : <div className="mt-20" />}

            <QuestionV6


              codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName} />


          </div>
        </>
      );

      case 'bot': return (
        <>

          <QuestionBot codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData} UserRespondantName={UserRespondantName}
          />

        </>
      );



      default: return (
        <>

          {/* <div 
        style={{
          backgroundColor:(getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType===1)? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor :  "#fff"    :"#fff",
          backgroundImage:(getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType===2)? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage :  null   :null,
        }}
        >


        <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName}/>

        {surveyLanguageRTL?
          <QuestionV2Arabic  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData}/>
          :
          <QuestionV2  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData}/>
          }
          
        </div>  */}


          {/* <div 
        style={{
          backgroundColor:(getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType===1)? (getSurveyTemplateByCodeData.bgColor) ? getSurveyTemplateByCodeData.bgColor :  "#fff"    :"#fff",
          backgroundImage:(getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.bgType===2)? (getSurveyTemplateByCodeData.bgImage) ? getSurveyTemplateByCodeData.bgImage :  null   :null,
        }}
        >

      {langList && langList.length>0?
        langList.length>1?

        <div className="mt-16">
          <LanguageComponent langList={langList} handleLanguage={this.handleLanguage} surveyLanguageName={surveyLanguageName} template={'v4'}/>
        </div>
        :
        <div className="mt-20"/>
      :<div className="mt-20"/>}


        {surveyLanguageRTL?
          <QuestionV4Arabic  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData}/>
            :
          <QuestionV4  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode} getSurveyTemplateByCodeData={getSurveyTemplateByCodeData}/>
          }
    
        </div> */}


        </>
      );




    }

  }
}
// MainPage.defaultProps = {
//   center: { lat: 26.953021, lng: 75.739797 },
//   zoom: 15
// };
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(MainPage);
