import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions, assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
class Registration extends Component {
  constructor(props) {
    super(props);

    this.inputChangeEmpId = this.inputChangeEmpId.bind(this);
    this.inputChangeOTP = this.inputChangeOTP.bind(this);

    this.state = {
      emp_id: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      formData: {
        name: "",
        email: "",
        mobile: "",
        country: "",
        postcode: "",
      },
      formCheck: {
        check1: false,
        check2: false,
      },
      fieldslogin: {},
      errorslogin: {},
      showEmailSuccessful: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.user.userAssessmentRegistrationSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  // handleEmpInput=(e)=>{
  //   this.setState({emp_id: e.target.value});
  // }

  inputChangeEmpId(e) {
    this.setState({ emp_id: e.target.value });
  }

  inputChangeOTP(e) {
    this.setState({ otp_code: e.target.value });
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitRegistration();
    }
  };

  changeUsername = () => {
    window.location.reload();

    let tempClear = {
      name: "",
      email: "",
      mobile: "",
      country: "",
      postcode: "",
    };
    this.setState({
      showLogin: true,
      failureMSG: "",
      otp_code: "",
      email: "",
      formData: tempClear,
    });
  };

  handleInput = (value, name) => {
    let { formData } = this.state;
    let errorslogin = this.state.errorslogin;
    formData[name] = value;
    errorslogin[name] = "";
    this.setState({ formData, errorslogin });
  };

  submitRegistration = () => {
    if (this.handleValidationLogin()) {
      let { formData, formCheck } = this.state;
      this.setState({ saveSuccess: true });
      let data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        country: formData.country,
        postcode: formData.postcode,
      };

      if (formCheck["check1"]) {
        if (formData && formData.name && formData.email) {
          this.setState({ showEmailSuccessful: true });

          this.props.dispatch(
            userActions.userAssessmentRegistration(data, this.props)
          );
          this.setState({ failureMSG: "" });

          setTimeout(() => {
            this.setState({ showEmailSuccessful: false });
          }, 3000);
        }
      }
    }
  };

  reSendOtp = () => {
    if (this.handleValidationLogin()) {
      let { formData, formCheck } = this.state;
      let data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        country: formData.country,
        postcode: formData.postcode,
      };

      if (formCheck["check1"]) {
        if (formData && formData.name && formData.email) {
          this.setState({ showEmailSuccessful: true });

          this.props.dispatch(
            userActions.userAssessmentRegistration(data, this.props)
          );

          setTimeout(() => {
            this.setState({ showEmailSuccessful: false });
          }, 3000);

          this.setState({ failureMSG: "" });
          this.setState({ saveSuccess: true });
        }
      }
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };

  handleValidationLogin = () => {
    let { formData, formCheck } = this.state;
    let errorslogin = {};
    let formIsValid = 0;

    function validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    //userName
    if (!formData["name"] || formData["name"] === "") {
      formIsValid += 1;
      console.log("1 name !");
      errorslogin["name"] = "Full Name is required";
    }
    if (!formData["email"] || formData["email"] === "") {
      console.log("1 email !");
      formIsValid += 1;
      errorslogin["email"] = "Email is required";
    } else {
      if (!validateEmail(formData["email"])) {
        formIsValid += 1;
        errorslogin["email"] = "Please enter valid email";
      }
    }

    // if (!formData["mobile"] || formData["mobile"] === "") {
    //   formIsValid += 1;
    //   console.log("1 mobile !");
    //   errorslogin["mobile"] = "Mobile is required";
    // }
    // if (!formData["country"] || formData["country"] === "") {
    //   formIsValid += 1;
    //   console.log("1 country !");
    //   errorslogin["country"] = "Country is required";
    // }
    // if (!formData["postcode"] || formData["postcode"] === "") {
    //   formIsValid += 1;
    //   console.log("1 postcode !");
    //   errorslogin["postcode"] = "Post Code is required";
    // }
    if (formCheck["check1"] === false) {
      formIsValid += 1;
      console.log("1 check1 !");
      errorslogin["check1"] = "Please accept privacy policy";
    }
    console.log("Validating");
    this.setState({ errorslogin: errorslogin });

    return formIsValid ? false : true;
  };

  OTPSubmit = () => {
    const { user } = this.props;
    const { UserEmailToken } = user;
    // console.log("UserEmailToken: ",UserEmailToken)
    let { otp_code } = this.state;
    let data = {
      token: UserEmailToken,
      otp: otp_code,
    };

    let failureMSG = "";
    let formIsValid = true;

    if (this.state.otp_code === "") {
      formIsValid = false;
      failureMSG = "Please enter your OTP";
    } else {
      this.setState({ failureMSG: "" });
      this.setState({ saveSuccess: true });
      // return formIsValid;
      // this.setState({ failureOTPMSG: "" });
      this.props.dispatch(
        userActions.userAssessmentEmailVerify(data, this.props)
      );
    }

    // console.log("data: ",data)
    this.setState({ failureOTPMSG: "", failureMSG: failureMSG });

    this.props.dispatch(
      userActions.userAssessmentEmailVerify(data, this.props)
    );
  };
  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  handleCheckInput = (value, name) => {
    let { formCheck, errorslogin } = this.state;
    formCheck[name] = value;
    errorslogin[name] = "";
    this.setState({ formCheck, errorslogin });
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  openPrivacyPolicy = (check) => {
    this.setState({ showPrivacyPolicy: check });
    this.props.history.push(`/assessment/privacy-policy`);
  };

  render() {
    let { questions, user, assessment } = this.props;

    let {
      employeeMessage,
    } = questions;
    let { getAssessmentPackagesShortListData } = assessment;

    let {
      emp_id,
      notFoundText,
      otp_code,
      formData,
      formCheck,
      showEmailSuccessful,
    } = this.state;
    let {
      UserLoginErrorMSG,
      userAssessmentRegistrationSuccess,
      userAssessmentRegistrationVerifyFailure,
      userAssessmentRegistrationVerifySuccess,
    } = user;

    console.log("emp_id: ", emp_id);


    let errorMsh = "";
    if (UserLoginErrorMSG) {
      errorMsh = UserLoginErrorMSG;
    }


    console.log("formData: ", formData);
    console.log("formCheck: ", formCheck);

    return (
      <div className="bg-[#E3F2FD]">
        <HeaderTransprent
          handleSignInPage={this.handleSignInPage}
          logout={this.logout}
          gotoPage={this.gotoPage}
          isLogin={true}
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          cartCount={0}
        />
        <div className="grid grid-cols-1 p-4 py-6 mx-auto 2xl:w-10/12 lg:w-11/12 lg:grid-cols-2 lg:py-10 lg:px-0 ">
          {/* right section */}
          <div className=" bg-[#2196f3] w-full h-full lg:flex hidden flex-col justify-center  items-center   p-10">
            {/* <LogoHeader surveyScreenData={surveyScreenData} template={"v4"} /> */}
            <img
              src="/img/talent_plus_assessment.svg"
              className="w-11/12 mx-auto"
            />
            {/* <p className="text-xl font-normal text-white capitalize ">
              Powered by <b className="font-semibold">Happyplus</b>
            </p> */}
          </div>

          <div className="flex items-center justify-center w-full h-full bg-white ">
            {this.state.showLogin ? (
              <>
                <div className="p-4 space-y-10 xl:px-14 md:p-6 ">
                  {/* <img
                    src="/img/talent_plus_logo.svg"
                    className="w-40 bg-cover md:w-52"
                  /> */}
                  <div className="space-y-4 text-[#3D405B] pt-4">
                    <h1 className="text-2xl font-semibold md:text-4xl ">
                      Sign Up
                    </h1>
                    <p className="text-base md:text-xl ">
                      Sign up by entering information below
                    </p>
                  </div>

                  <div className="space-y-3 ">
                    <div className="space-y-2">
                      <label className="font-medium text-[#3D405B]/70 text-base before:content-['*'] before:text-[#F14678]">
                        Full Name
                      </label>
                      <br />
                      <input
                        autocomplete="off"
                        type="text"
                        value={formData.name}
                        name="name"
                        onChange={(e) =>
                          this.handleInput(e.target.value, "name")
                        }
                        placeholder="Name"
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                      />
                      {this.state.errorslogin["name"] ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorslogin["name"]}
                        </div>
                      ) : null}
                    </div>

                    <div className="space-y-2">
                      <label className=" font-medium text-[#3D405B]/70 text-base before:content-['*'] before:text-[#F14678]">
                        Email
                      </label>
                      <br />
                      <input
                        autocomplete="off"
                        type="email"
                        value={formData.email}
                        name="email"
                        onChange={(e) =>
                          this.handleInput(e.target.value, "email")
                        }
                        placeholder="email@gmail.com"
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                      />
                      {this.state.errorslogin["email"] ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorslogin["email"]}
                        </div>
                      ) : null}
                    </div>

                    <div className="space-y-2">
                      <label className=" font-medium text-[#3D405B]/70 text-base">
                        Mobile
                      </label>
                      <br />
                      <input
                        autocomplete="off"
                        type="number"
                        name="mobile"
                        onChange={(e) =>
                          this.handleInput(e.target.value, "mobile")
                        }
                        placeholder="+91"
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 [-moz-appearance:_textfield] [&::-webkit-inner-spin-button] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                      />
                      {/* {this.state.errorslogin["mobile"] ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorslogin["mobile"]}
                        </div>
                      ) : null} */}
                    </div>

                    <div className="space-y-2">
                      <label className=" font-medium text-[#3D405B]/70 text-base">
                        Country
                      </label>
                      <br />
                      <input
                        autocomplete="off"
                        type="text"
                        name="country"
                        onChange={(e) =>
                          this.handleInput(e.target.value, "country")
                        }
                        placeholder="India"
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                      />
                      {/* {this.state.errorslogin["country"] ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorslogin["country"]}
                        </div>
                      ) : null} */}
                    </div>

                    <div className="space-y-2">
                      <label className=" font-medium text-[#3D405B]/70 text-base">
                        Post Code
                      </label>
                      <br />
                      <input
                        autocomplete="off"
                        type="postcode"
                        name="postcode"
                        onChange={(e) =>
                          this.handleInput(e.target.value, "postcode")
                        }
                        placeholder=""
                        className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                      />
                      {/* {this.state.errorslogin["postcode"] ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorslogin["postcode"]}
                        </div>
                      ) : null} */}
                    </div>

                    <label className="flex items-start pt-4 space-x-2 cursor-pointer">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={formCheck["check1"]}
                        onChange={() =>
                          this.handleCheckInput(!formCheck["check1"], "check1")
                        }
                      />
                      <p className="text-sm text-[#3D405B]">
                        I have read and agree to the <span
                          onClick={() => this.props.history.push(`/assessment/terms-and-conditions`)}
                          className="underline text-[#2196F3]">
                          {" "}
                          Terms and Conditions*
                        </span>
                        {""}
                        <span className="px-1">and</span>
                        {""}
                        <span
                          onClick={() => this.openPrivacyPolicy(true)}
                          className="underline text-[#2196F3]"
                        >
                          Privacy Policy*.
                        </span>
                        {" "}
                      </p>
                    </label>
                    {this.state.errorslogin["check1"] ? (
                      <div className="invalid-feedback">
                        <p className="text-xs text-red-600">
                          {this.state.errorslogin["check1"]}
                        </p>
                      </div>
                    ) : null}

                    <label className="flex items-start space-x-2 cursor-pointer">
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={formCheck["check2"]}
                        onChange={() =>
                          this.handleCheckInput(!formCheck["check2"], "check2")
                        }
                      />
                      <p className="text-sm text-[#3D405B]">
                        I’d also like to be among the first to know about new
                        TalentPlusStrengths offerings and services. I understand that I
                        may unsubscribe from these offerings at any time.
                      </p>
                    </label>
                  </div>

                  {/* <div className="space-y-2">
                    <label className="text-lg font-semibold text-slate-500">
                   Register
                    </label>
                    <br />

                    <input
                      autocomplete="off"
                      type="text"
                      value={formData.name}
                      name="name"
                      onChange={(e) => this.handleInput(e.target.value, "name")}
                      placeholder="Name"
                      className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                    />

                    <input
                      autocomplete="off"
                      type="email"
                      value={formData.email}
                      name="email"
                      onChange={(e) =>
                        this.handleInput(e.target.value, "email")
                      }
                      placeholder="Email"
                      className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                    />
                  </div> */}

                  {errorMsh ? (
                    <>
                      <p className="pt-2 text-red-500">{errorMsh}</p>
                      <button
                        onClick={() => this.submitRegistration()}
                        className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                      >
                        <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-full opacity-10"></span>
                        Register
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => this.submitRegistration()}
                        className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                      >
                        <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-full opacity-10"></span>
                        Register
                      </button>
                    </>
                  )}

                  <div
                    onClick={() => this.handleSignInPage()}
                    className="text-[#3D405B] md:text-base text-sm pb-4"
                  >
                    Already have an account?
                    <span className="text-[#2196F3] pl-2 cursor-pointer">Sign In</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className=" w-[30rem]  md:mx-auto mx-6 md:my-6 my-4 space-y-10  ">
                  {/* <img
                    src="/img/talent_plus_logo.svg"
                    className="bg-cover w-52"
                  /> */}
                  <div className="space-y-4 text-[#3D405B] ">
                    <h1 className="text-2xl font-semibold md:text-4xl">
                      Verification
                    </h1>
                    <div className="flex flex-col items-start justify-between md:flex-row md:items-end">
                      <div className="space-y-2">
                        <p className="text-base md:text-xl ">
                          We have sent 6 digit OTP to:
                        </p>
                        <p className="text-base font-semibold md:text-xl ">
                          {formData["email"]}
                        </p>
                      </div>
                      <button
                        onClick={() => this.changeUsername()}
                        className="text-lg font-medium text-[#2196f3] cursor-pointer "
                      >
                        Change
                      </button>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label className="text-lg font-semibold text-slate-500">
                      Verify OTP:
                    </label>{" "}
                    <br />
                    <input
                      autocomplete="off"
                      type="text"
                      value={otp_code}
                      name="otp_code"
                      onChange={this.inputChangeOTP}
                      placeholder="Enter OTP"
                      className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                    />
                    {this.state.failureMSG ? (
                      <div className="pl-2 text-sm text-red-600 invalid-feedback">
                        {this.state.failureMSG}
                      </div>
                    ) : (
                      <>
                        {userAssessmentRegistrationVerifyFailure ? (
                          <div className="pl-2 text-sm text-red-600 invalid-feedback">
                            {"Please enter Valid OTP"}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>

                  <div className="flex items-center justify-between ">
                    <p className="text-base md:text-xl">Didn’t receive OTP?</p>
                    <button
                      onClick={() => this.reSendOtp()}
                      className="border border-[#2196F3] text-[#2196F3] px-4 py-2 rounded "
                    >
                      Resend OTP
                    </button>
                  </div>
                  {notFoundText === "Employee not found!" ? (
                    <>
                      <p className="pt-2 text-red-500">{employeeMessage}</p>
                      <button
                        onClick={() => this.OTPSubmit()}
                        className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                      >
                        Log In
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => this.OTPSubmit()}
                        className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                      >
                        <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-full opacity-10"></span>
                        Verify
                      </button>

                      <div>
                        {!userAssessmentRegistrationVerifyFailure ? (
                          <>
                            {userAssessmentRegistrationVerifySuccess ? (
                              <button
                                type="button"
                                className="w-full flex space-x-4 items-center    py-4 px-4 text-base  tracking-wide text-[#4CAF50]  rounded bg-[#4CAF5012]"
                              >
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-circle-check"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  {" "}
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />{" "}
                                  <circle cx="12" cy="12" r="9" />
                                  <path d="M9 12l2 2l4 -4" />
                                </svg>
                                <span>OTP has been verified!</span>
                              </button>
                            ) : null}
                          </>
                        ) : null}

                        {userAssessmentRegistrationSuccess &&
                          showEmailSuccessful ? (
                          <>
                            <button
                              type="button"
                              className="w-full flex space-x-4 items-center    py-4 px-4 text-base  tracking-wide text-[#4CAF50]  rounded bg-[#4CAF5012]"
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-circle-check"
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                {" "}
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />{" "}
                                <circle cx="12" cy="12" r="9" />
                                <path d="M9 12l2 2l4 -4" />
                              </svg>
                              <span>OTP has been successfully sent!</span>
                            </button>
                          </>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="p-4 bg-white">
          <p className="md:text-base text-sm text-[#3D405B] text-center">
            All Rights Reserved | Copyright © TalentPlusStrengths.
          </p>
        </div>
      </div>
    );
  }
}
Registration.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(Registration);
