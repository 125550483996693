// src/App.js
import React from 'react';
import { useTranslation } from 'react-i18next';

function Test() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6 App">
      <div className='text-center'>
        <h1>{t('welcome')}</h1>
        <p>{t('greeting', { name: 'John' })}</p>
      </div>
     <div className='flex items-center'>
     <button onClick={() => changeLanguage('en')} className='p-2 text-white bg-blue-500'>English</button>
      <button onClick={() => changeLanguage('fr')} className='p-2 text-white bg-purple-500'>Français</button>
     </div>
    </div>
  );
}

export default Test;
