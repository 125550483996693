
import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";

class SurveyLogin extends Component {
  constructor(props) {
    super(props);

    this.inputChangeSurveyOTP = this.inputChangeSurveyOTP.bind(this);
    this.inputChangeOTP = this.inputChangeOTP.bind(this);

    this.state = {
      surveyotp: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
    };
  }

  componentDidMount() { }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.UserLoginEmailSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  // handleEmpInput=(e)=>{
  //   this.setState({surveyotp: e.target.value});
  // }

  inputChangeSurveyOTP(e) {
    this.setState({ surveyotp: e.target.value });
  }

  inputChangeOTP(e) {
    this.setState({ otp_code: e.target.value });
  }

  submitSurveyOTP = () => {
    let { surveyotp } = this.state;
    if (surveyotp) {
      this.props.dispatch(
        assessmentActions.userAssessmentSurveyLogin({ surveyotp: surveyotp }, this.props)
      );
      this.setState({ failureMSG: "" });
    }
  };


  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitSurveyOTP();
    }
  };

  changeUsername = () => {
    this.setState({ showLogin: true, failureMSG: '', otp_code: '', email: '' });
  }


  gotoPage = (field) => {
    this.props.history.push(`/assessment/` + field);
  };

  render() {
    let { questions, user } = this.props;
    let {
      getEmployeeStatusData,
      employeeMessage,
      getSurveyDetailsBySurveyCodeData,
    } = questions;
    let { emp_id, notFoundText, otp_code } = this.state;
    let { UserLoginErrorMSG } = user;

    console.log("emp_id: ", emp_id);
    console.log("otp_code: ", otp_code);

    let surveyScreenData = getSurveyDetailsBySurveyCodeData;

    let errorMsh = "";
    if (UserLoginErrorMSG) {
      errorMsh = UserLoginErrorMSG;
    }

    // let surveyScreenData = {
    //   AlreadyText: "Survey Is Already Submitted !",
    //   ContinueButtonText: "Continue",
    //   ExpiryText: "This Survey is Expired!",
    //   OptionColor1: "#ffffff",
    //   OptionColor2: "#000000",
    //   OptionColor3: "#0770e9",
    //   StartButtonText: "Start Survey",
    //   SubmitButtonText: "Submit",
    //   WriteHereText: "Write here",
    //   bgColor: "#ffffff",
    //   bgImage: "",
    //   bgType: 1,
    //   buttonColor1: "#0069e4",
    //   buttonColor2: "#ffffff",
    //   designTemplate: "v4",
    //   langList:[],
    //   name: "Employee Engagement Survey",
    //   okButtonText: "OK",
    //   textColor: "#262626",
    //   transl: {}
    // }

    const isMobile = window.innerWidth <= 700;

    // if(isMobile){
    //   surveyScreenData["logo2"] = "img/companylogo/kotaklogo2.png";
    // }
    // else{
    //   surveyScreenData["logo2"] = "img/companylogo/kotakbanner.png";
    // }
    // surveyScreenData["logo"] = "img/companylogo/umatters.png";

    // console.log("getSurveyDetailsBySurveyCodeData--------->",getSurveyDetailsBySurveyCodeData);

    console.log(
      "getSurveyDetailsBySurveyCodeData--------->",
      getSurveyDetailsBySurveyCodeData
    );


    return (
      <>
        <div className="flex h-screen">
          {/* right section */}
          <div
            className=" bg-[#2196f3] w-5/12 h-full lg:flex hidden flex-col justify-center  items-center space "
          >
            {/* <LogoHeader surveyScreenData={surveyScreenData} template={"v4"} /> */}
            <img
              src="/img/talent_plus_assessment.svg"
              className="w-11/12 mx-auto"
            />
            <p className="text-xl font-normal text-white capitalize ">
              Powered by <b className="font-semibold">Happyplus</b>
            </p>
          </div>

          <div className="flex items-center justify-center w-full h-full lg:w-7/12 bg-slate-100">
            <div className=" w-[30rem]  md:mx-auto mx-6 md:my-6 my-4 space-y-10  ">
              <img
                src="/img/TalentPlusStrengths_logo.svg"
                className="w-40 bg-cover md:w-60"
              />
              <div className="space-y-4 text-[#3D405B] ">
                <h1 className="text-2xl font-semibold md:text-4xl ">
                  Welcome to TalentPlusStrengths  Assessment
                </h1>
                <p className="text-lg md:text-2xl ">
                  Sign in by assessment code to take assessment or view the report
                </p>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-slate-500">
                  Survey Assessment Code
                </label>
                <br />
                <input
                  onKeyDown={this._handleKeyDown}
                  autocomplete="off"
                  type="text"
                  value={emp_id}
                  name="surveyotp"
                  onChange={this.inputChangeSurveyOTP}
                  placeholder="Survey Assessment Code"
                  className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                />


                <div
                  className="text-[#3D405B] md:text-base text-sm mx-1 pt-4"
                >
                  Don't have survey assessment code?
                  <span onClick={() => this.gotoPage("sign-up")} className="text-[#2196F3] pl-2 cursor-pointer">Sign up</span>
                </div>


              </div>


              {errorMsh ? (
                <>
                  <p className="pt-2 text-red-500">{errorMsh}</p>
                  <button
                    onClick={() => this.submitSurveyOTP()}
                    className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => this.submitSurveyOTP()}
                    className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                    START
                  </button>


                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
SurveyLogin.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user } = state;
  return {
    loggingIn,
    questions,
    user,
  };
}

export default connect(mapStateToProps)(SurveyLogin);
