import React, { Component } from 'react';
import { connect } from 'react-redux';
class HeatMapBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],

    }
  }
  componentDidMount() {

  }


  render() {

    let { scoreList, handleStrength, item } = this.props;
    let ParaNameList = []
    let insertData = []
    let max = 0;
    let list = []
    if (item && item.dimensions && item.dimensions.length > 0) {
      item.dimensions.forEach((ele) => {

        if (max < ele.finalSum) {
          max = ele.finalSum
        }

        list.push(ele)
        ParaNameList.push(ele.name)
        insertData.push({
          value: ele.finalSum,
          itemStyle: {
            color: ele.colorCode
          }
        })

      })
    }

    if (list && list.length > 0) {
      list.sort((b, a) => (a.finalSum - b.finalSum))
    }


    function getValue(dimension) {
      let temp = {
        "rank": "",
        "count": 0,
        "color": '#757575'
      }
      if (list && list.length > 0) {
        let index = list.findIndex(prev => prev.name === dimension);
        if (index !== -1) {
          temp["rank"] = list[index]["rank"];
          temp["count"] = list[index]["count"];
          temp["color"] = list[index]["colorCode"];
        }
      }

      return temp
    }
    console.log("list----->", list)

    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }


    function getValueX(ele, check2) {

      let blackColor = "#3D406B";
      let whiteColor = "#3D406B";

      if (item.bucket === "THINKING TALENTS") {
        blackColor = "#3D406B";
        whiteColor = "#3D406B"
      } else {
        whiteColor = "#ffffff"
      }

      let tempXP = {
        "background": "#757575",
        "color": whiteColor
      }
      if (check2 === 0) {
        tempXP["background"] = hexToRgbA(ele.colorCode, '0.20')
        tempXP["color"] = blackColor
      } else if (check2 === 1) {
        tempXP["background"] = hexToRgbA(ele.colorCode, '0.20')
        tempXP["color"] = blackColor
      } else if (check2 === 2) {
        tempXP["background"] = hexToRgbA(ele.colorCode, '1')
        tempXP["color"] = whiteColor
      } else if (check2 === 3) {
        tempXP["background"] = hexToRgbA(ele.colorCode, '1')
        tempXP["color"] = whiteColor
      } else if (check2 === 4) {
        tempXP["background"] = hexToRgbA(ele.colorCode, '1')
        tempXP["color"] = whiteColor
      } else {
        tempXP["background"] = hexToRgbA(ele.colorCode, '0.20')
        tempXP["color"] = blackColor
      }
      return tempXP
    }



    return (
      <>

        <div className="grid grid-cols-2 gap-2">
          {list && list.length > 0 ?
            list.map((ele) =>
              <>
                <div className='px-4 font-medium text-center text-white py-7' style={{ background: getValueX(ele, ele.check).background, color: getValueX(ele, ele.check).color }}>
                  <h1 className='text-2xl'>{ele.rank}</h1>
                  <h1 className='text-lg'>{ele.name}</h1>
                </div>
              </>
            ) : null}
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMapBlock);
