import React from 'react'
import moment from "moment";

export default function AssessmentTop5(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, EditButtonFn } = props;

  const cirleColor = (element) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD")
      }}
      className={"w-5 h-5 rounded-full "}
    />)
  }


  const barWidthColor = (element, max) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD"),
      }}
      className={
        "w-5 h-5 rounded-full "
      }
    />)
  }



  return (
    <>
      <main className="p-10 px-20 space-y-10 bg-white page-break">
        <div className="flex items-center justify-between w-full py-4 border-b">
          <h1 className="text-3xl font-medium text-slate-900">{EditButtonFn('TalentAssessmentReport', 'TalentPlusStrengths  Assessment Report')}</h1>
          <p className="text-base font-medium">{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}</p>
        </div>
        <div className="space-y-2">
          <h1 className="text-3xl font-medium">{EditButtonFn("Part2", "Part 2")}</h1>
        </div>
        <div className="w-full ">

          {/* right */}
          <div className="w-full space-y-4 ">
            <h1 className="text-3xl font-light text-[#3D405B]">
              <b>{EditButtonFn("DeepDive", "Deep Dive into your TalentPlusStrengths  Themes:")}</b>
            </h1>
          </div>
          <br />
          <ul className="space-y-4 text-base list-num">
            <li>
              1.	{EditButtonFn("top5list1", "In this section each theme will be explained in detail. Typical behaviour observed in successful people with this theme has been mentioned. ")}
            </li><li>
              2.	{EditButtonFn("top5list2", "In the top heading you will see the talent with chronological number and specific colour. This colour signifies whether the theme is from Leading, Acting, Relating or Thinking bucket.")}
            </li><li>

              3.	{EditButtonFn("top5list3", "Explanation of themes will give you a clear picture of the talent and how it manifests itself. You may want to add more to it, if you think something is missing. Broadly it will cover many dimensions but still you may want to explore further basis your own observations.")}
            </li><li>
              4.	{EditButtonFn("top5list4", "Knowing your talent is one thing, but putting it into use is different. In this section, we have given you ideas and situation whether the talents can be utilised. These ideas are a good starting point for you think of other ways in which you can put your talents to use.")}
            </li><li>

              5.	{EditButtonFn("top5list5", "Every talent can have a blind spot. While you enjoy putting your talent to work, sometime overuse of this can be construed as a weakness from others perspective. It will be important for you to understand it and be aware of the same. ")}
            </li><li>
              6.	{EditButtonFn("top5list6", "The next section of the report gives you an understanding of how to deal with someone who has a particular theme. It will be important for us to understand the person and ensure, we help them bring their best out by helping them leverage their TalentPlusStrengths theme. ")}
            </li><li>
              7.	{EditButtonFn("top5list7", "Remember, You are unique and your uniqueness is manifested by your talents. It is important for us to reflect on our work, our relationships and our successes but even more important is to reflect on what has made us be successful. Connecting the events back to your talent is a good starting point. This is called Reflective learning. You may start from here. ")}
            </li><li>
              8.	{EditButtonFn("top5list8", "Once you are confident of your reflective learning, you can move on to your proactive learning, where you define your goals and decide which talent theme to use to achieve those goals. It will also give you an idea on how to leverage others who bring a talent which you may not have in sufficient quantity. That will create huge synergy. ")}
            </li><li>
              9.	{EditButtonFn("top5list9", "When you know your talents and your team members know theirs, time has come to reflect on the team talents. Understanding team talents will allow you to lead your team with confidence and help engage them tremendously.")}
            </li><li>
              10.	{EditButtonFn("top5list10", "Remember, living your life with talent is a journey and all journeys takes time. Be patient and keep working on the natural gifts of your life. You will experience thriving life for sure. ")}
            </li>
          </ul>


        </div>

        {/* <p>Copyright © 2023, HappyPlus, Inc. All rights reserved</p> */}
      </main>

    </>
  )

}
