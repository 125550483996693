import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import Top5Talents from './Top5Talents';
import Resources from './Resources'; 


class BackSummary extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      showMainPage: true,
      showFilter: false,
      showValue: '',
      showTabView: 2,
      activeTab: 1,
      isTest: true

    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }


  handleStrength = (params) => {
    let { handleThemeStrenght } = this.props;
    let { FilterValues } = this.state;
    let name = ''
    if (params && params.name) {
      name = params.name;
    }
    handleThemeStrenght(name, FilterValues);
    this.setState({ showMainPage: false })
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {

    let { handlAssessmentDashboard } = this.props;
    this.setState({
      FilterValues: finalFilter,
      ListName: name,
      showMainPage: true
    });

    handlAssessmentDashboard(finalFilter, true);

  }

  handleGOTOTheme = () => {
    this.setState({ showMainPage: true })
  }

  handleTabView = (value) => {
    this.setState({ showTabView: value })
  }

  setActiveTab = (tabNumber) => {
    this.setState({
      activeTab: tabNumber,
    });
  };




  render() {

    let { isSchool, crrCodeUserDetails, handleShowMainSummaryModal, EditDetailsFn, getAssessmentReportByEmpIDData, crrDetail, getAssessmentDimensionsData, EditButtonFn, handleShowMainSummaryModalBack, contactExpertSection, assessment } = this.props;
    let { showTabView } = this.state;
    let { getExpertDetailsListData } = assessment;

    let AssessmentList = []
    if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
      getAssessmentDimensionsData.forEach((element) => {
        AssessmentList.push({
          ...element,
          finalSum: (Number(element.count) * Number(element.rankSum))
        })
      })
      AssessmentList.sort((a, b) => (a.finalSum - b.finalSumSum))
    }


    let newList = []
    if (AssessmentList && AssessmentList.length > 0) {
      AssessmentList.forEach((element, index) => {

        let rank = (AssessmentList.length - index);

        let check = 0;
        if (showTabView === 1) {
          check = 1
        } else if (showTabView === 2 && (rank <= 5)) {
          check = 2
        } else if (showTabView === 3 && (rank <= 7)) {
          check = 3
        } else if (showTabView === 4 && (rank <= 10)) {
          check = 4
        }
        console.log("check--1->", check)

        let getIndex = newList.findIndex(prev => prev.bucket === element.bucket)
        if (getIndex === -1) {
          let temp = {
            "showCount": 0,
            "bucket": element.bucket,
            "colorCode": element.colorCode,
            "dimensions": [{
              "rank": rank,
              "check": check,
              "name": element.dimension,
              "count": element.count,
              "finalSum": element.finalSum,
              "colorCode": element.colorCode,
            }],
          }
          newList.push(temp)
        } else {
          newList[getIndex]["showCount"] += 1

          newList[getIndex]["dimensions"].push({
            "rank": rank,
            "check": check,
            "name": element.dimension,
            "count": element.count,
            "finalSum": element.finalSum,
            "colorCode": element.colorCode,
          })
        }



      });
    }


    let newV2List = [];
    if (newList && newList.length > 0) {
      for (var ix = 0; ix < newList.length; ix++) {
        let details = newList[ix];
        let temp = details;
        if (details && details.dimensions && details.dimensions.length > 0) {
          temp["dimensions"] = details.dimensions.sort((a, b) => (a.rank - b.rank));
        }
        newV2List.push(temp)
      }
    }






    function getModifiedContent(text) {
      let text2 = "";
      if (text) {
        text2 = text
          .replace(/<\/?li[^>]*>/g, "@")
          .replace(/<\/?ul[^>]*>/g, "")
          .replace(/<\/?span[^>]*>/g, "")
          .replace(/<\/?p[^>]*>/g, "")
          .replace(/&nbsp;/g, "")
          .replace(/<\/?br[^>]*>/g, "")
          .replace(/<\/?b[^>]*>/g, "");
      }
      return text2 ? text2 : text;
    }




    console.log("crrCodeUserDetails--------.", crrCodeUserDetails)



    return (
      <>

        <div className="w-full" >

          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

            <div className="space-y-6">

              <Top5Talents
                getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                EditDetailsFn={EditDetailsFn}
                EditButtonFn={EditButtonFn}
                crrDetail={crrDetail}
                handleShowMainSummaryModal={handleShowMainSummaryModal}
                handleShowMainSummaryModalBack={handleShowMainSummaryModalBack}
                isSchool={isSchool}
              />


              <Resources
              />

            </div>

          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, assessment } = state;
  return {
    loggingIn,
    users,
    dashboard,
    assessment
  };
}
export default connect(mapStateToProps)(BackSummary);
