import 'react-app-polyfill/ie9';

import React from 'react';

function NotFoundPage() {
  return (
    <>
      <div className="h-[calc(100vh-8rem)] flex items-center justify-center">
        <section className="flex items-start w-full px-6 py-10 mx-auto 2xl:w-8/12 xl:w-10/12 xl:px-0 md:space-x-10 ">
          <div className="w-full mx-auto space-y-10 text-center lg:w-4/6 md:px-10">
            <div className="space-y-4 text-base font-normal text-white/80 ">
              <div className="flex flex-col items-center justify-center space-y-6 md:space-y-10">
                <div className="md:w-[12rem] w-[10rem] md:h-[12rem] h-[10rem] rounded-full bg-[#2196f3]/10 flex justify-center items-center">
                  <div className="md:w-[10rem] w-[8rem] h-[8rem] md:h-[10rem] rounded-full bg-[#2196f3]/10 flex justify-center items-center">
                    <img src='/img/icon/page_not_found.svg' className='w-20' />
                  </div>
                </div>
                <h1 className="lg:text-5xl md:text-4xl text-3xl font-bold text-[#2196f3]">
                  Page not Found!
                </h1>
              </div>

              <h1 className="  py-2 font-normal md:text-3xl text-xl text-[#2196f3]  ">
                Sorry we Couldn't find the Survey, please check URL.
              </h1>
            </div>


            <div className="fixed bottom-0 left-0 w-full">
              <div className="w-full px-4 py-4 mx-auto xl:w-11/12 xl:px-0 md:py-6 md:bg-transparent bg-white/10">
                <div className="flex justify-end w-full">
                  <div className="flex flex-col items-center w-full space-y-4 md:justify-center 2xl:space-y-10 xl:space-y-10 ">
                    <div className="flex justify-center w-full md:justify-end">
                      <p className="text-sm text-slate-900/80 ">
                        © Powered by
                        <b className="ml-1 font-medium text-slate-900/100">
                          HappyPlus
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
        </section>
      </div>
    </>
  );
}

export default NotFoundPage;