import React from "react";
import { useHistory } from "react-router-dom";

function Footer() {
  let history = useHistory();
  return (
    <footer className="bg-white border-t fixed w-full   bottom-0  ">
      <div className="flex md:flex-row flex-col gap-4 justify-between items-center py-6  text-sm text-center text-[#3D405B] capitalize container px-4   mx-auto sm:px-6 lg:px-8">
        <div className="flex items-center space-x-2">
          <span
            className="underline"
            onClick={() => history.push("/assessment/terms-and-conditions")}
          >
            Terms & conditions
          </span>{" "}
          <span>|</span>
          <span
            className="underline"
            onClick={() => history.push("/assessment/privacy-policy")}
          >
            {" "}
            Privacy Policy
          </span>
        </div>
        <p className="">All Rights Reserved | Copyright © TalentPlusStrengths.</p>
      </div>
    </footer>
  );
}
export default Footer;
