import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SingleChoice from "./ComponentV3/SingleChoice";
import MultipleChoice from "./ComponentV3/MultipleChoice";
import TextBox from "./ComponentV3/TextBox";
import MultiLineText from "./ComponentV3/MultiLineText";
import RatingScale from "./ComponentV3/RatingScale";
import NPS from "./ComponentV3/NPS";
import Email from "./ComponentV3/Email";
import Number from "./ComponentV3/Number";
import Dropdown from "./ComponentV3/Dropdown";
import YesNo from "./ComponentV3/YesNo";
import Date from "./ComponentV3/Date";

import { questionsActions } from '../../../_actions';


class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {

      SurveyCode: this.props.codeValue,



      radioButton: false,
      radioButton2: false,
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces: false,

      surveyLanguageName: 'English',
      surveyLanguageCode: '',
      ShowSubmitButton: false,


      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn: false,
      afterSubmitData: {
        "thankyouText": '',
        "bgColor": '',
        "bgImage": "",
        "textColor": "",
        "logo": ""
      },
      alreadySubmitted: false,
      clicked: false,

      showDropdownQues: false,
      dropdownInput: ""
    }
  }

  componentDidMount() {
    this.props.dispatch(questionsActions.emptyReducer());

    this.props.dispatch(questionsActions.getQuestionList({ code: this.state.SurveyCode }, this.props));

    this.props.dispatch(questionsActions.getLanguageLabel());

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted: true
      }
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  prevQues = () => {
    this.setState({ changeType: "fade1" })
    this.setState({ ShowSubmitButton: false });

    let { questions } = this.props;
    let { questionsList } = questions;


    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length) {
      if (CurrQuesIndex > 1 && CurrQuesIndex < questionsList.length + 1) {
        CurrQuesIndex = CurrQuesIndex - 1;

        this.setState({ CurrQuesIndex }, () => {
          // this.handleSubmitAppear();
        });
      }
    }

    // console.log("answersList: ", answersList)

  }

  nextQues = () => {
    this.setState({ changeType: "fade", clicked: '' })

    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {



      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex }, () => {
          this.handleSubmitAppear();
        });

      }
    }

    let flag = 0
    let unattemptList = [];
    answersList.forEach((item, index) => {
      if (item.isMandatory) {


        if (!item.answer) {
          flag = flag + 1;
          unattemptList.push(index + 1)
        }


      }
    })

    if (flag) {
      this.setState({ unattemptList: unattemptList });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {


        if (this.state.unattemptList && this.state.unattemptList.length > 0) {
          this.setState({ CurrQuesIndex: unattemptList[0] }, () => {
            this.handleSubmitAppear();
          });

        }

      }

    }
    else {
      this.setState({ unattemptList: [] });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        this.setState({ CurrQuesIndex: questionsList.length }, () => {
          this.handleSubmitAppear();
        });
      }
    }




  }


  handleSubmitAppear = () => {
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;

    setTimeout(() => {
      if (questionsList && questionsList.length > 0 && (questionsList.length === CurrQuesIndex)) {
        this.setState({ ShowSubmitButton: true });
      }
      else {
        this.setState({ ShowSubmitButton: false });
      }
    }, 300)


  }

  handleRadio = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;


    if (this.state.radioButton === currentOption.id) {

      this.setState({ radioButton: null });
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, "", 0, surveyScreenData));
    }
    else {

      this.setState({ radioButton: currentOption.id })
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData));
    }

    if (!ques.parentId) {
      setTimeout(() => this.nextQues(), 300);
    }
  }



  handleCheckInput = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    if (true) {

      this.setState({ radioButton: currentOption.id })
      // console.log("currentOption::", currentOption)
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption,
        currentOption.weightage, surveyScreenData));
    }

    // setTimeout(()=>this.nextQues(), 300);

  }

  goToInstructionPage = () => {
    let { questions } = this.props;
    let { surveyScreenData } = questions;

    if (surveyScreenData.isInstruction) {
      this.setState({ showWelcomeScreen: false, showQuestionScreen: false, showThankyouScreen: false, instructionPage: true });
    }
    else {
      this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    }
    window.scrollTo(0, 0)

  }

  goToQuestionPage = () => {
    this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    window.scrollTo(0, 0)

  }


  saveAnswers(surveyScreenData) {

    if (surveyScreenData) {
      this.setState({
        afterSubmitData: surveyScreenData
      });
    }


    let survey = {
      code: this.state.SurveyCode,
    }
    let { questions } = this.props;
    let { answersList } = questions;


    let flag = 0;
    let unattemptList = [];
    answersList.forEach((item, index) => {
      if (item.isMandatory) {


        if (!item.answer) {
          flag = flag + 1;
          unattemptList.push(index + 1)
        }


      }
    })

    if (flag) {
      this.setState({ saveMsg: "Please attempt all mandatory questions!" })
      // console.log("Please Attempt Mandatory Questions!");
      this.setState({ showRedFlag: true });
      this.setState({ unattemptList: unattemptList });

    }
    else {
      // console.log("Saving...");
      this.setState({ saveMsg: "" })
      this.setState({ unattemptList: [], submitSucces: true });


      this.props.dispatch(questionsActions.saveAnswers(answersList, survey, this.props));
    }

    // console.log("Final Answer: ", answersList)

  }

  ansTextBoxClick(ques) {
    this.setState({ currQues: ques });
  }

  ansTextBoxChange1 = (e, Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 1
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));
  }

  ansNPSInput = (val, Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: val,
      ans: 1
    }

    this.setState({ textAnswer: val })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));



    if (!Ques.parentId) {
      setTimeout(() => this.nextQues(), 300);
    }
  }

  ansTextBoxChange2 = (e, Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans: 2
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));
  }

  clickOK = () => {
    // setTimeout(()=>this.nextQues(), 300);
    this.nextQues()
  }

  clickOKLast = (Ques) => {

    // console.log("clickOKLast; ")

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: "",
      ans: 3
    }

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));



    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length > 0) {



      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex });

      }
    }

    let flag = 0
    let unattemptList = [];
    answersList.forEach((item, index) => {
      if (item.isMandatory) {

        if (!item.answer) {
          flag = flag + 1;
          unattemptList.push(index + 1)
        }


      }
    });

    if (flag) {
      this.setState({ unattemptList: unattemptList });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {


        if (this.state.unattemptList && this.state.unattemptList.length > 0) {
          // console.log("-----working x")
          this.setState({ CurrQuesIndex: unattemptList[0] });
          // console.log("-----working x 1")

        }

      }

    }
    else {
      this.setState({ unattemptList: [] });

      if (this.state.mandatoryOn && questionsList && questionsList.length > 0) {
        // console.log("All mandatory questions are attempted , now go to last question i.e. submit! ")
        this.setState({ CurrQuesIndex: questionsList.length });
      }
    }


    //nothing




  }


  onMandatoryAttempt = (elem) => {
    this.setState({ CurrQuesIndex: elem });
    this.setState({ mandatoryOn: true });

  }

  gotoSubmit = () => {
    let { questions } = this.props;
    let { questionsList } = questions;

    if (questionsList && questionsList.length > 0) {
      this.setState({ CurrQuesIndex: questionsList.length })
    }
  }




  handleLanguage = (e) => {
    if (e.target.value === 'en') {
      this.setState({ surveyLanguageCode: '', surveyLanguageName: '' });
    }
    else {
      this.setState({ surveyLanguageCode: e.target.value, surveyLanguageName: "" });
    }
  }




  openDropdownQuestion = (check) => {
    this.setState({ showDropdownQues: check })
  }

  handleDropdownSelect = (value) => {
    this.setState({ dropdownInput: value, showDropdownQues: false });
  }

  handleMatrixRadio = (ques, subQues, currentOption, items) => {

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;


    let currIndex2 = answersList.findIndex(element => element.questionId === subQues.id);

    console.log('------>items------>', answersList[currIndex2], subQues, items)

    this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData, subQues));


    // setTimeout(()=>this.nextQues(), 300);

  }


  render() {
    let { questions } = this.props;

    let { questionsList, answersList, surveyScreenData, surveyStatus } = questions;

    let { CurrQuesIndex, afterSubmitData, clicked, showDropdownQues } = this.state;

    let { surveyLanguageCode } = this.props;

    const isMobile = window.innerWidth <= 700;

    console.log("answersList:---------------------------------> ", answersList)
    // console.log("questionsList:---------------------------------> ", questionsList)
    // console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // console.log("surveyStatus:---------------------------------> ", surveyStatus)




    let bgImage = '';
    let bgColor = '';
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;

    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = []
    let instructionTextDislay = []


    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = '';
    let bgType = '';

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );



    let LabelObj = questions.getLanguageLabelData;
    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);


    //console.log("surveyStatus: ", surveyStatus);

    let LanguageList = questions.langList;
    //console.log("LanguageList: ", LanguageList);

    //console.log("surveyScreenData: ", surveyScreenData);


    let answeredLen = 0;
    let totalLen = 0

    if (answersList && answersList.length > 0) {
      answeredLen = answersList.filter(x => x.answer !== "").length;
      totalLen = answersList.length;
    }


    function showRadioMatrix(SubQues, option) {

      let getIndex1 = answersList.findIndex(prev => prev.questionId === SubQues.id);
      let getIndex4 = answersList[getIndex1]['multiAnswer'].findIndex(prev => prev.optionHead === option.heading)
      let getIndex3 = answersList[getIndex1]['multiAnswer'].findIndex(prev => prev.optionId === option.id)

      if (getIndex4 !== -1) {
        if (getIndex3 !== -1) {
          return true
        }
        else {
          return false
        }

      }
      else {
        return false
      }

    }

    function FormatMatriXOptionHeading(question, isOption) {
      let paraObjShow = {};
      if (question && question.optionsList && question.optionsList.length > 0) {
        question.optionsList.forEach((item) => {
          if (paraObjShow && paraObjShow[item.heading]) {
            paraObjShow[item.heading] += 1
          }
          else {
            paraObjShow[item.heading] = 1
          }
        });
      }

      let headingList = []
      let tempKey = Object.keys(paraObjShow);
      if (tempKey && tempKey.length > 0) {
        tempKey.forEach((item) => {
          headingList.push({
            name: item,
            span: paraObjShow[item]
          })
        });
      }

      if (isOption) {
        let OptionList = []
        if (headingList && headingList.length > 0) {
          headingList.forEach((heading) => {
            if (question && question.optionsList && question.optionsList.length > 0) {
              question.optionsList.forEach((option) => {
                if (heading.name === option.heading) {
                  OptionList.push(option)
                }
              });
            }
          });
        }
        return OptionList
      }
      else {
        return headingList
      }

    }

    function TranslateQuestion(surveyLanguageCode, question) {
      return (surveyLanguageCode !== '') ?
        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name) ?
          question.transl[surveyLanguageCode].name : question.name
        : question.name
    }

    function TranslationOptionMatrix(question, heading, surveyLanguageCode) {
      let optionId = "";
      if (question && question.optionsList && question.optionsList.length > 0) {
        let Index = question.optionsList.findIndex(prev => prev.heading === heading);
        optionId = question.optionsList[Index].id;
      }
      return (surveyLanguageCode !== '') ?
        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].headingList && question.transl[surveyLanguageCode].headingList[optionId]) ?
          question.transl[surveyLanguageCode].headingList[optionId] : heading : heading
    }

    let { getSurveyTemplateByCodeData } = this.props;
    function SurveyAttributes(name) {
      return (surveyLanguageCode !== '') ?
        (getSurveyTemplateByCodeData && getSurveyTemplateByCodeData.transl && getSurveyTemplateByCodeData.transl[surveyLanguageCode] && getSurveyTemplateByCodeData.transl[surveyLanguageCode][name]) ?
          getSurveyTemplateByCodeData.transl[surveyLanguageCode][name] : ""
        :
        getSurveyTemplateByCodeData && getSurveyTemplateByCodeData[name] ? getSurveyTemplateByCodeData[name] : ""
    }




    function NPSColor(question) {

      function NPSName(optionID, optionName, surveyLanguageCode, question) {

        return (surveyLanguageCode !== '') ?
          (question && question.transl &&
            question.transl[surveyLanguageCode] &&
            question.transl[surveyLanguageCode].optionsList &&
            question.transl[surveyLanguageCode].optionsList[optionID]) ?
            question.transl[surveyLanguageCode].optionsList[optionID] : optionName
          : optionName
      }


      let Color = "#a3a3a3";
      let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
      let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
      if (answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6) {
        Color = "#ed3e34";
        if (answer === 1 || answer === 2) {
          Name = NPSName("Extremelydissatisfied", "Extremely dissatisfied", surveyLanguageCode, question)
        }
        else if (answer === 3 || answer === 4) {
          Name = NPSName("Dissatisfied", "Dissatisfied", surveyLanguageCode, question)
          console.log('Dissatisfied Name: ', Name)
        }
        else if (answer === 5 || answer === 6) {
          Name = NPSName("Neither", "Neither", surveyLanguageCode, question)
        }
      }
      else if (answer === 7 || answer === 8) {
        Color = "#fcb817";
        Name = NPSName("Satisfied", "Satisfied", surveyLanguageCode, question)
      }
      else if (answer === 9 || answer === 10) {
        Color = "#0acc4b";
        Name = NPSName("Extremelysatisfied", "Extremely satisfied", surveyLanguageCode, question)
      }
      return {
        color: Color,
        name: Name
      }
    }


    let { surveyLanguageRTL } = this.props;

    return (
      <>
        <div dir="rtl" lang="ar" style={{
          backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#fff" : "#fff",
          backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
          minHeight: '800px',
          // backgroundRepeat: 'cover',
          backgroundSize: 'cover',
          overflowX: 'hidden',
          overflowY: 'hidden'
        }}>

          {surveyStatus !== 2 && surveyStatus !== 3 ?
            <>
              {(this.state.showWelcomeScreen && questionsList && questionsList.length > 0) ?
                <>
                  <div className="">
                    <section className="xl:w-3/6 lg:w-4/6  py-6 mx-auto lg:px-0 md:px-6 px-4 text-center overflow-y-auto overflow-y-768 " >
                      <div className="flex justify-center pb-4">
                        {/* <img src="pictures/logo.png" /> */}
                        {(surveyScreenData && surveyScreenData.logo) ?
                          <img alt="newlogo" style={{ height: "70px" }}
                            src={surveyScreenData.logo}
                          />
                          : (afterSubmitData.logo) ?
                            <img alt="newlogo" style={{ height: "70px" }}
                              src={afterSubmitData.logo} />
                            :
                            null
                        }
                      </div>

                      <div >
                        <div className="flex items-center justify-center md:text-4xl text-2xl font-bold text-white mb-4 cursor-default"><p style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>{
                          (surveyLanguageCode !== '') ?
                            (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].instructionText) ? surveyScreenData.transl[surveyLanguageCode].name : surveytitle
                            : surveytitle
                        }</p> </div>

                        {/* {LanguageList && LanguageList.length>0?
              <div className="relative inline-flex mb-4">
                <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero"/></svg>
                <select onChange={(e)=>this.handleLanguage(e)} className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                  <option value={"en"}>English</option>
                  {LanguageList && LanguageList.length>0?
                    LanguageList.map((item,index)=>
                      <option keys={index} value={item.shortName}>{item.name}</option>
                    )
                  :null}
                </select>
              </div>
              :null} */}



                        <div className="cursor-default" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                          dangerouslySetInnerHTML={{
                            __html:
                              (surveyLanguageCode !== '') ?
                                (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].welcomeText) ? surveyScreenData.transl[surveyLanguageCode].welcomeText : welcomeTextDislay
                                : welcomeTextDislay
                          }} />


                        <div className="pt-3 pb-20 bg-opacity-0">
                          <button
                            style={{
                              background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                              color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                            }}
                            onClick={() => this.goToInstructionPage()} className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100">
                            {SurveyAttributes("StartButtonText") ? SurveyAttributes("StartButtonText") : "Start Survey"}

                            {/* {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].WelcomePageButton?LabelObj[surveyLanguageCode].WelcomePageButton:"Start Survey"} */}
                          </button></div>
                      </div>
                    </section>
                    {/* powered by logo */}
                    <div className=" fixed flex bottom-0  lg:w-full w-full lg:border-none border-t bg-green-768 border-tran" style={{ height: '7%' }}>
                      <div
                        style={{

                          backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                          // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                        }}
                        className=" w-full lg:mx-0  lg:py-4 md:py-4 py-4 ">
                        <div className=" flex items-center"> <p className="text-white text-sm font-light mx-auto " style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} >© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
                      </div>
                    </div>
                  </div>
                </>
                : null}

              {this.state.instructionPage ?
                <>
                  <div className="">
                    <section className="xl:w-3/6 lg:w-4/6  md:py-6 py-4 mx-auto lg:px-0 md:px-6 px-4  overflow-y-auto overflow-y-768">
                      <div className="flex justify-center pb-4">
                        {/* <img src="pictures/logo.png" /> */}
                        {(surveyScreenData && surveyScreenData.logo) ?
                          <img alt="newlogo" style={{ height: "70px" }}
                            src={surveyScreenData.logo}
                          />
                          : (afterSubmitData.logo) ?
                            <img alt="newlogo" style={{ height: "70px" }}
                              src={afterSubmitData.logo} />
                            :
                            null
                        }
                      </div>
                      <div className>
                        <p className="md:text-4xl text-3xl text-2xl-320 text-white font-light tracking-wider md:py-8 py-6 pt-2  md:mt-4 text-center cursor-default" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>
                          {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].InstructionLabelText ? LabelObj[surveyLanguageCode].InstructionLabelText : "I n s t r u c t i o n s"}
                        </p>
                        {/* <h1 className="md:px-6 px-4 text-lg text-gray-100 font-medium ">Definition</h1> */}
                        <p >
                          <div className="cursor-default" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} dangerouslySetInnerHTML={{
                            __html: (surveyLanguageCode !== '') ? (surveyScreenData && surveyScreenData.transl && surveyScreenData.transl[surveyLanguageCode] && surveyScreenData.transl[surveyLanguageCode].instructionText) ?
                              surveyScreenData.transl[surveyLanguageCode].instructionText : instructionTextDislay : instructionTextDislay
                          }} />
                        </p>
                        <div className="pt-3 pb-20 flex justify-center">
                          <button
                            style={{
                              background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                              color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                            }}
                            onClick={() => this.goToQuestionPage()} className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100">
                            {/* {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].InstructionPageButton?LabelObj[surveyLanguageCode].InstructionPageButton:"Continue"} */}
                            {SurveyAttributes("ContinueButtonText") ? SurveyAttributes("ContinueButtonText") : "Continue"}

                          </button></div>
                      </div>
                    </section>
                    {/* powered by logo */}
                    <div className=" fixed flex bottom-0  lg:w-full w-full lg:border-none border-t bg-green-768 border-tran" style={{ height: '7%' }}>
                      <div
                        style={{

                          backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                          // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                        }}
                        className=" w-full lg:mx-0  lg:py-4 md:py-4 py-4 ">
                        <div className=" flex items-center"> <p className="text-white text-sm font-light mx-auto " style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} >© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
                      </div>
                    </div>
                  </div>
                </>
                : null}

              {this.state.showQuestionScreen && !this.state.submitSucces ?
                <section className="xl:w-3/6 lg:w-4/6 mx-auto  relative  bg-white ">
                  <div className="flex justify-between md:px-8 px-6   items-center w-full " style={{}}>
                    {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDpFZyC8kW-gUtRx_EZmfAUWj_G4uC19eB_A&usqp=CAU" className="w-24 py-2 " />  */}

                    {(surveyScreenData && surveyScreenData.logo) ?
                      <img alt="newlogo" style={{ height: "70px" }}
                        src={surveyScreenData.logo}
                      />
                      : (afterSubmitData.logo) ?
                        <img alt="newlogo" style={{ height: "70px" }}
                          src={afterSubmitData.logo} />
                        :
                        null
                    }

                    <div className> <div className="flex items-center text-sm text-white font-normal"> <p>{answeredLen}</p><p className="px-2">of</p> {totalLen}  <p className="px-2 md:block hidden">Answered</p></div> </div>
                  </div>



                  <div  >


                    {questionsList && questionsList.length > 0 ?
                      questionsList.map((question, questionindex) =>
                        <div className="rounded-lg m-6  shadow-lg p-6">
                          <p className="md:text-lg  text-sm font-normal py-4 text-left text-gray-800">
                            {(surveyLanguageCode !== '') ? (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].name) ?
                              question.transl[surveyLanguageCode].name : question.name : question.name}
                          </p>


                          {/* Single Choice */}
                          {question.templateId === 1 ?
                            <>
                              <SingleChoice
                                question={question}
                                clicked={clicked}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                handleRadio={this.handleRadio}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}


                          {/* Multiple Choice */}
                          {question.templateId === 2 ?
                            <>
                              <MultipleChoice
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                handleCheckInput={this.handleCheckInput}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}



                          {/* Multitext Box */}
                          {question.templateId === 3 ?
                            <>
                              <TextBox
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                SurveyAttributes={SurveyAttributes}
                                ansTextBoxChange1={this.ansTextBoxChange1}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}



                          {/* Multitext Box */}
                          {question.templateId === 4 ?
                            <>
                              <MultiLineText
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                SurveyAttributes={SurveyAttributes}
                                ansTextBoxChange1={this.ansTextBoxChange1}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}


                          {/* Rating Scale */}
                          {question.templateId === 5 ?
                            <>
                              <RatingScale
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                handleRadio={this.handleRadio}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}


                          {/* NPS Scale */}
                          {question.templateId === 6 ?
                            <>
                              <NPS
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                ansNPSInput={this.ansNPSInput}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}




                          {/* Email */}
                          {question.templateId === 7 ?
                            <>
                              <Email
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                ansTextBoxChange1={this.ansTextBoxChange1}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}




                          {/* Number */}
                          {question.templateId === 8 ?
                            <>
                              <Number
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                ansTextBoxChange1={this.ansTextBoxChange1}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}




                          {/* Dropdown */}
                          {question.templateId === 9 ?
                            <>
                              <Dropdown
                                question={question}
                                clicked={clicked}
                                answersList={answersList}
                                handleRadio={this.handleRadio}
                                handleDropdownSelect={this.handleDropdownSelect}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}





                          {/* Yes/No */}
                          {question.templateId === 11 ?
                            <>
                              <YesNo
                                question={question}
                                clicked={clicked}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                handleRadio={this.handleRadio}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}


                          {/* Date */}
                          {question.templateId === 12 ?
                            <>
                              <Date
                                question={question}
                                answersList={answersList}
                                surveyLanguageCode={surveyLanguageCode}
                                ansTextBoxChange1={this.ansTextBoxChange1}
                                surveyScreenData={surveyScreenData}
                              />
                            </>
                            : null}



                          {/* Matrix  */}
                          {question.templateId === 13 ?
                            <>
                              <div className="w-full mb-4 pt-4">

                                <div className="overflow-x-auto  whitespace-nowrap w-full   pr-6" >


                                  {isMobile ?
                                    <>
                                      {/* MOBILE VIEW */}
                                      {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                                        question.SubQuestionList.map((SubQues, index) =>
                                          <>
                                            <div className="pb-4" key={index}>
                                              <div className="whitespace-pre-line py-2">{TranslateQuestion(surveyLanguageCode, SubQues)}</div>
                                              <table className="text-center border border-gray-300 w-full mx-auto" style={{ background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff' }}>
                                                <thead >
                                                  <tr>

                                                    {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length ?
                                                      FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                                        <td
                                                          style={{
                                                            color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                            background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                          }}
                                                          key={optionindex} colSpan={heading.span} className="px-2 text-sm font-semibold text-center border border-gray-300">
                                                          {TranslationOptionMatrix(question, heading.name, surveyLanguageCode)}
                                                        </td>
                                                      ) : null}

                                                  </tr>
                                                  <tr>
                                                    {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length ?
                                                      FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                        <td
                                                          style={{
                                                            color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                            background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                          }}
                                                          className="text-center  border border-gray-300">{(surveyLanguageCode !== '') ?
                                                            (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?
                                                              question.transl[surveyLanguageCode].optionsList[option.id] : option.name

                                                            : option.name
                                                          }</td>
                                                      ) : null}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length ?
                                                      FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                        <td
                                                          style={{
                                                            color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                            background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                          }}
                                                          className="text-center  border border-gray-300 p-1">
                                                          {showRadioMatrix(SubQues, option) ?
                                                            <span style={{ color: (surveyScreenData && surveyScreenData.OptionColor3) ? surveyScreenData.OptionColor3 : 'black' }} onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">task_alt</span>
                                                            :
                                                            <span style={{ color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black' }} onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">radio_button_unchecked</span>
                                                          }
                                                        </td>
                                                      ) : null}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : null}

                                    </>




                                    :




                                    <>
                                      {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                                        <table className="min-w-full relative  " style={{ background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff' }}>
                                          <thead className="">

                                            <tr>
                                              <td
                                                style={{
                                                  color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                  background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                }}
                                                className="border border-gray-300  p-1 sticky top-0" rowSpan={2}></td>
                                              {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length ?
                                                FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                                  <td key={optionindex} colSpan={heading.span} className="sticky top-0 border border-gray-300 text-center p-2 font-semibold"
                                                    style={{
                                                      color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                      background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                    }}>
                                                    {TranslationOptionMatrix(question, heading.name, surveyLanguageCode)}
                                                  </td>
                                                ) : null}

                                            </tr>

                                            <tr>
                                              {/* <td className="w-40 text-left py-3 px-4 text-sm border border-gray-300 "> </td> */}

                                              {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length ?
                                                FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                  <td key={optionindex}
                                                    style={{
                                                      color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black',
                                                      background: (surveyScreenData && surveyScreenData.OptionColor1) ? surveyScreenData.OptionColor1 : '#fff'
                                                    }}
                                                    className="sticky top-10 text-left py-1 px-4 text-sm text-center border border-gray-300 ">{
                                                      (surveyLanguageCode !== '') ?
                                                        (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id]) ?
                                                          question.transl[surveyLanguageCode].optionsList[option.id] : option.name

                                                        : option.name
                                                    }</td>
                                                ) : null}
                                            </tr>



                                          </thead>
                                          <tbody className="">
                                            {question.SubQuestionList.map((SubQues, index) =>
                                              <tr key={index}>
                                                <td className="w-82 text-left py-1 px-4 text-sm border border-gray-300 whitespace-pre-line"
                                                  style={{ color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black' }}
                                                > {TranslateQuestion(surveyLanguageCode, SubQues)} </td>
                                                {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length ?
                                                  FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                                    <td key={optionindex} className="text-center py-1 px-4 border border-gray-300">

                                                      {showRadioMatrix(SubQues, option) ?
                                                        <span style={{ color: (surveyScreenData && surveyScreenData.OptionColor3) ? surveyScreenData.OptionColor3 : 'black' }} onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">task_alt</span>
                                                        :
                                                        <span style={{ color: (surveyScreenData && surveyScreenData.OptionColor2) ? surveyScreenData.OptionColor2 : 'black' }} onClick={() => this.handleMatrixRadio(question, SubQues, option)} className="material-icons">radio_button_unchecked</span>
                                                      }

                                                    </td>
                                                  ) : null}
                                              </tr>
                                            )}

                                          </tbody>
                                        </table>
                                        : null}

                                    </>

                                  }







                                </div>



                              </div>

                            </>
                            : null}


                          {/* Group  */}
                          {question.templateId === 14 ?
                            <>
                              {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                                question.SubQuestionList.map((SubQuestion, questionindex) =>
                                  <>
                                    <div className="py-4 ml-10">
                                      {SubQuestion.templateId === 10 ?
                                        <h1 className="md:text-2xl italic text-base font-bold text-gray-700">
                                          {(surveyLanguageCode !== '') ? (SubQuestion && SubQuestion.transl && SubQuestion.transl[surveyLanguageCode]
                                            && SubQuestion.transl[surveyLanguageCode].name) ?
                                            SubQuestion.transl[surveyLanguageCode].name : SubQuestion.name : SubQuestion.name}{SubQuestion && SubQuestion.isMandatory ? "*" : ""}</h1>
                                        :
                                        <h1 className="md:text-lg text-base font-medium text-gray-700">
                                          {(surveyLanguageCode !== '') ? (SubQuestion && SubQuestion.transl && SubQuestion.transl[surveyLanguageCode]
                                            && SubQuestion.transl[surveyLanguageCode].name) ?
                                            SubQuestion.transl[surveyLanguageCode].name : SubQuestion.name : SubQuestion.name}{SubQuestion && SubQuestion.isMandatory ? "*" : ""}</h1>
                                      }


                                      {/* Single Choice */}
                                      {SubQuestion.templateId === 1 ?
                                        <>
                                          <SingleChoice
                                            question={SubQuestion}
                                            clicked={clicked}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            handleRadio={this.handleRadio}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}


                                      {/* Multiple Choice */}
                                      {SubQuestion.templateId === 2 ?
                                        <>
                                          <MultipleChoice
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            handleCheckInput={this.handleCheckInput}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}



                                      {/* Multitext Box */}
                                      {SubQuestion.templateId === 3 ?
                                        <>
                                          <TextBox
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            SurveyAttributes={SurveyAttributes}
                                            ansTextBoxChange1={this.ansTextBoxChange1}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}



                                      {/* Multitext Box */}
                                      {SubQuestion.templateId === 4 ?
                                        <>
                                          <MultiLineText
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            SurveyAttributes={SurveyAttributes}
                                            ansTextBoxChange1={this.ansTextBoxChange1}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}


                                      {/* Rating Scale */}
                                      {SubQuestion.templateId === 5 ?
                                        <>
                                          <RatingScale
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            handleRadio={this.handleRadio}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}


                                      {/* NPS Scale */}
                                      {SubQuestion.templateId === 6 ?
                                        <>
                                          <NPS
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            ansNPSInput={this.ansNPSInput}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}




                                      {/* Email */}
                                      {SubQuestion.templateId === 7 ?
                                        <>
                                          <Email
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            ansTextBoxChange1={this.ansTextBoxChange1}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}




                                      {/* Number */}
                                      {SubQuestion.templateId === 8 ?
                                        <>
                                          <Number
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            ansTextBoxChange1={this.ansTextBoxChange1}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}




                                      {/* Dropdown */}
                                      {SubQuestion.templateId === 9 ?
                                        <>
                                          <Dropdown
                                            question={SubQuestion}
                                            clicked={clicked}
                                            answersList={answersList}
                                            handleRadio={this.handleRadio}
                                            handleDropdownSelect={this.handleDropdownSelect}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}





                                      {/* Yes/No */}
                                      {SubQuestion.templateId === 11 ?
                                        <>
                                          <YesNo
                                            question={SubQuestion}
                                            clicked={clicked}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            handleRadio={this.handleRadio}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}


                                      {/* Date */}
                                      {SubQuestion.templateId === 12 ?
                                        <>
                                          <Date
                                            question={SubQuestion}
                                            answersList={answersList}
                                            surveyLanguageCode={surveyLanguageCode}
                                            ansTextBoxChange1={this.ansTextBoxChange1}
                                            surveyScreenData={surveyScreenData}
                                          />
                                        </>
                                        : null}


                                    </div>
                                  </>
                                ) : null}

                            </>
                            : null}







                        </div>
                      ) : null}








                    <div className="px-2 pt-3 justify-center items-center">
                      {this.state.unattemptList && this.state.showRedFlag ?
                        this.state.unattemptList && this.state.unattemptList.length > 0 ?
                          <p className="md:px-6 px-4 md:text-md text-gray-100 font-light">
                            <span style={{ cursor: 'default', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }} className="font-semibold">{'Please attempt mandatory question no. '}</span>
                            {this.state.unattemptList.map((elem, index) =>
                              <span key={index} onClick={() => this.onMandatoryAttempt(elem)}
                                style={{ cursor: 'pointer', fontWeight: 'bold', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>
                                {(index + 1 === this.state.unattemptList.length) ?
                                  <span style={{ textDecoration: 'underline' }}>{' ' + elem}</span>
                                  :
                                  <span style={{ textDecoration: 'underline' }}>{elem + ' , '}</span>
                                }
                              </span>
                            )}

                          </p>
                          : null : null}
                    </div>

                    {/* {this.state.ShowSubmitButton ? */}
                    <div className="pt-3 pb-20 flex justify-center">
                      <button style={{
                        background: (surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1) ? afterSubmitData.buttonColor1 : "#757575",
                        color: (surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2) ? afterSubmitData.buttonColor2 : "#757575",
                      }}
                        onClick={() => this.saveAnswers(surveyScreenData)}
                        className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100">
                        {/* {LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].SubmitButton?LabelObj[surveyLanguageCode].SubmitButton:"Submit"} */}
                        {SurveyAttributes("SubmitButtonText") ? SurveyAttributes("SubmitButtonText") : "Submit"}
                      </button></div>
                    {/* :null} */}









                    <div className="py-4 ">
                      {/* <div className=" flex justify-center"> <button className="bg-green-500 text-white   py-3 px-12 rounded-lg shadow-lg">Done</button></div> */}
                      <div className="bg-white py-2 my-14 w-full">
                        <img src="img/newlogo.png" className="w-24 mx-auto pt-2" />
                        <p className="text-center py-2 text-sm font-light text-gray-700">
                          © Powered by HappyPlus</p>
                      </div>
                    </div>
                  </div>
                </section>

                : null}

              {this.state.showThankyouScreen ?





                <div className="">
                  <section className="xl:w-3/6 lg:w-4/6  py-6 mx-auto lg:px-0 md:px-6 px-6 ">
                    <div className="flex justify-center pb-16">
                      {(surveyScreenData && surveyScreenData.logo) ?
                        <img alt="newlogo" style={{ height: "70px" }}
                          src={surveyScreenData.logo}
                        />
                        : (afterSubmitData && afterSubmitData.logo) ?
                          <img alt="newlogo" style={{ height: "70px" }}
                            src={afterSubmitData.logo} />
                          :
                          null
                      }
                    </div>
                    <div className="flex items-center h-full justify-center">
                      <div className="text-center text-white">
                        <div className="flex justify-center pb-8"><img src="pictures/thank_you4.png" alt="pic" style={{ width: '500px' }} className="w-5/6 w-auto" /></div>

                        <h1 className="font-bold md:text-5xl text-4xl tracking-widest text-gray-800">

                        </h1>
                        <p className="text-xl opacity-80 font-light py-2 text-gray-600">

                          <div
                            style={{ textAlign: 'center', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : (afterSubmitData && afterSubmitData.textColor) ? afterSubmitData.textColor : 'black' }}
                            dangerouslySetInnerHTML={{
                              __html:
                                (surveyLanguageCode !== '') ? (afterSubmitData && afterSubmitData.transl && afterSubmitData.transl[surveyLanguageCode] && afterSubmitData.transl[surveyLanguageCode].thankyouText) ?
                                  afterSubmitData.transl[surveyLanguageCode].thankyouText : (afterSubmitData && afterSubmitData.thankyouText ? afterSubmitData.thankyouText : "<h3>Thank You for Participating !</h3>") : (afterSubmitData && afterSubmitData.thankyouText ? afterSubmitData.thankyouText : "<h3>Thank You for Participating !</h3>")
                            }} />

                        </p>
                      </div>
                    </div>
                  </section>
                  {/* powered by logo */}
                  {/* <div
        style={{
          height: '10%',
          backgroundColor:(bgType===1 || afterSubmitData.bgType===1)? (bgColor) ? bgColor : (afterSubmitData.bgColor)?afterSubmitData.bgColor: "#757575"    :"#f1f1f1",
          backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
        }}
        className="fixed flex lg:bottom-4 bottom-0 lg:right-4 lg:w-auto w-full lg:border-none border-t border-tran bg-green-768" style={{height: '10%'}}> 
          <div className="lg:mx-0 mx-auto lg:py-8 md:py-4 py-4 ">
            <div className="flex items-center"> <p className="text-gray-600 text-sm font-light" style={{color:(surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor:(afterSubmitData && afterSubmitData.textColor)?afterSubmitData.textColor:'black'}}>© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
          </div>
        </div> */}

                  <div className=" fixed flex bottom-0  lg:w-full w-full lg:border-none border-t bg-green-768 border-tran" style={{ height: '7%' }}>
                    <div
                      style={{

                        backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                        // backgroundImage:(bgType===2 || afterSubmitData.bgType===2)? (bgImage) ? bgImage : (afterSubmitData.bgImage)?afterSubmitData.bgImage: null   :null,
                      }}
                      className=" w-full lg:mx-0  lg:py-4 md:py-4 py-4 ">
                      <div className=" flex items-center"> <p className="text-white text-sm font-light mx-auto " style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : (afterSubmitData && afterSubmitData.textColor) ? afterSubmitData.textColor : 'black' }} >© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
                    </div>
                  </div>



                </div>












                : null}













            </>
            : null}



          {((surveyStatus === 2 && !(questionsList && questionsList.length > 0)) || this.state.alreadySubmitted) ? //already done




            <div className="">
              <section className="xl:w-3/6 lg:w-4/6  py-6 mx-auto lg:px-0 md:px-6 px-6 ">
                <div className="flex justify-center pb-16 ">
                  {(surveyScreenData && surveyScreenData.logo) ?
                    <img alt="newlogo" style={{ height: "70px" }}
                      src={surveyScreenData.logo}
                    />
                    : (afterSubmitData.logo) ?
                      <img alt="newlogo" style={{ height: "70px" }}
                        src={afterSubmitData.logo} />
                      :
                      null
                  }                            </div>
                <div className="flex items-center h-full justify-center">
                  <div className="text-center text-white">
                    <div className="flex justify-center pb-8"><img alt="pic" src="pictures/surveydone.png" className="md:w-auto w-6/6 " /></div>
                    <h1 className="font-bold text-gray-800 md:text-4xl text-3xl tracking-widest leading_lose leading-10" style={{}}>
                      <div
                        style={{ textAlign: 'center', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                        dangerouslySetInnerHTML={{
                          __html:
                            // LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].SurveyAlreadyDoneText?
                            // '<h1>'+LabelObj[surveyLanguageCode].SurveyAlreadyDoneText+'</h1>':"<h1>Survey Is Already Submitted !</h1>"
                            SurveyAttributes("AlreadyText") ? '<h1>' + SurveyAttributes("AlreadyText") + '</h1>' : "<h1>Survey Is Already Submitted !</h1>"

                        }} />
                    </h1>
                    <p className="text-xl opacity-80 font-light py-2">

                    </p>
                  </div>
                </div>
              </section>


              {/* {LanguageList && LanguageList.length>0?
                      <div className="flex items-center h-full justify-center">
                      <div className="relative inline-flex mb-4 ">
                          <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero"/></svg>
                          <select onChange={(e)=>this.handleLanguage(e)} className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                            <option value={"en"}>English</option>
                            {LanguageList && LanguageList.length>0?
                              LanguageList.map((item,index)=>
                                <option keys={index} value={item.shortName}>{item.name}</option>
                              )
                            :null}
                          </select>
                        </div> 
                      </div>
                      :null} */}



              {/* powered by logo */}
              <div
                style={{
                  height: '10%',
                  backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                  backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
                }}
                className="fixed flex lg:bottom-4 bottom-0 lg:right-4 lg:w-auto w-full lg:border-none border-t border-tran bg-green-768" >
                <div className="lg:mx-0 mx-auto lg:py-8 md:py-4 py-4 ">
                  <div className="flex items-center"> <p className="text-gray-600 text-sm font-light" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
                </div>
              </div>
            </div>






            : null}

          {/* survey expired page ----------------------------------------------------------------------------------------------- */}

          {surveyStatus === 3 && !(questionsList && questionsList.length > 0) ? //expired




            <div className="">
              <section className="xl:w-3/6 lg:w-4/6  py-6 mx-auto lg:px-0 md:px-6 px-6 ">
                <div className="flex justify-center pb-16">

                  {(surveyScreenData && surveyScreenData.logo) ?
                    <img alt="newlogo" style={{ height: "70px" }}
                      src={surveyScreenData.logo}
                    />
                    : (afterSubmitData.logo) ?
                      <img alt="newlogo" style={{ height: "70px" }}
                        src={afterSubmitData.logo} />
                      :
                      null
                  }

                </div>
                <div className="flex items-center h-full justify-center">
                  <div className="text-center text-white">
                    <div className="flex justify-center pb-8"><img alt="pic" src="pictures/surveyexpired.png" className="md:w-auto w-6/6" /></div>
                    <h1 className="font-bold text-gray-800 lg:text-5xl md:text-4xl text-3xl tracking-widest leading_lose leading-10" style={{}}>
                      <div
                        style={{ textAlign: 'center', color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}
                        dangerouslySetInnerHTML={{
                          __html:
                            // LabelObj && LabelObj[surveyLanguageCode] && LabelObj[surveyLanguageCode].SurveyExpiredText? '<h1>' + LabelObj[surveyLanguageCode].SurveyExpiredText+'</h1>':"<h1>This Survey is <br>Expired!</h1>"
                            SurveyAttributes("ExpiryText") ? '<h1>' + SurveyAttributes("ExpiryText") + '</h1>' : "<h1>This Survey is <br>Expired!</h1>"


                        }} />
                    </h1>
                  </div>
                </div>
              </section>



              {/* {LanguageList && LanguageList.length>0?
                                      <div className="flex items-center h-full justify-center">
                                      <div className="relative inline-flex mb-4 ">
                                          <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero"/></svg>
                                          <select onChange={(e)=>this.handleLanguage(e)} className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                                            <option value={"en"}>English</option>
                                            {LanguageList && LanguageList.length>0?
                                              LanguageList.map((item,index)=>
                                                <option keys={index} value={item.shortName}>{item.name}</option>
                                              )
                                            :null}
                                          </select>
                                        </div> 
                                      </div>
                                      :null} */}



              {/* powered by logo */}
              <div
                style={{
                  height: '10%',
                  backgroundColor: (bgType === 1 || afterSubmitData.bgType === 1) ? (bgColor) ? bgColor : (afterSubmitData.bgColor) ? afterSubmitData.bgColor : "#757575" : "#f1f1f1",
                  backgroundImage: (bgType === 2 || afterSubmitData.bgType === 2) ? (bgImage) ? bgImage : (afterSubmitData.bgImage) ? afterSubmitData.bgImage : null : null,
                }}
                className="fixed flex lg:bottom-4 bottom-0 lg:right-4 lg:w-auto w-full lg:border-none border-t border-tran bg-green-768" >
                <div className="lg:mx-auto mx-auto lg:py-8 md:py-4 py-4 ">
                  <div className="flex items-center"> <p className="text-gray-600 text-sm font-light" style={{ color: (surveyScreenData && surveyScreenData.textColor) ? surveyScreenData.textColor : 'black' }}>© Powered by <b className="font-semibold">HappyPlus</b></p> </div>
                </div>
              </div>
            </div>








            : null}




        </div>
      </>
    );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(QuestionPublish);
